define('ux-pcc/routes/customer/performance/channels', ['exports', 'ux-pcc/mixins/routes/customer/performance'], function (exports, _performance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_performance.default, {
    model: function model() {
      var customer = this.modelFor('customer');
      var settings = customer.get('settings').toArray() || [];
      // Get the customer setting for the rollup channels to pass to the channel fetch call
      var channelCodes = settings.findBy("settingCode", "ROLLUP_CHANNEL_LIST");
      // Get the customer setting for the root channel to pass to the channel fetch call
      var rootChannel = settings.findBy('settingCode', 'ROOT_CHANNEL');

      // Strip out any spaces
      if (channelCodes && channelCodes.get('settingValue').length > 0) {
        channelCodes = channelCodes.get('settingValue').replace(/\s/g, "");
      } else {
        // Set some defaults for now
        channelCodes = 'PMS,WEBSITE,CALL_CENTER,OTA,GDS';
      }
      // set root channel from setting
      if (rootChannel && rootChannel.get('settingValue')) {
        rootChannel = rootChannel.get('settingValue');
      } else {
        rootChannel = 'ELECTRONIC';
      }

      return this.get('store').query('channel', {
        customerID: customer.get('id'),
        parentChannelCode: rootChannel,
        scope: 'CUSTOMER',
        filter: channelCodes
      }).then(function (results) {
        var rollupCodes = channelCodes.split(",");

        return results.map(function (channel) {
          return {
            key: channel.get('channelCode'),
            label: channel.get('shortDescription'),
            orderBy: rollupCodes.indexOf(channel.get('channelCode'))
          };
        }).sortBy('orderBy');
      });
    },
    setupController: function setupController(controller, model) {

      controller.set('channels', model);

      this._super(controller, model);
    }
  });
});