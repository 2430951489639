define('ux-pcc/utils/stringify-multiselect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = stringifyMultiselect;
  function stringifyMultiselect(fullList, selectedlist, filterBy, returnField, defaultValue, nameCallback) {
    if (Ember.isEmpty(selectedlist) || selectedlist.length === 0) {
      return defaultValue;
    }
    var selectedParams = selectedlist.split("|");
    if (fullList && fullList._result) {
      fullList = fullList._result;
    }

    if (Ember.isArray(fullList)) {
      return fullList.filter(function (item) {
        return selectedParams.indexOf(Ember.get(item, filterBy)) >= 0;
      }).map(function (b) {
        if (nameCallback) {
          if (!returnField) {
            return nameCallback(b);
          }

          return nameCallback(Ember.get(b, returnField));
        }
        return Ember.get(b, returnField);
      }).join(", ");
    }
  }
});