define("ux-pcc/helpers/card-data-window-classes/screens", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var CardDataWindowScreens = function () {
        function CardDataWindowScreens() {
            _classCallCheck(this, CardDataWindowScreens);

            this.screens = [];
        }

        _createClass(CardDataWindowScreens, [{
            key: "addScreen",
            value: function addScreen(screen) {
                this.screens.push(screen);
            }
        }, {
            key: "get",
            value: function get() {
                return this.screens;
            }
        }, {
            key: "getScreen",
            value: function getScreen(index) {
                return this.screens[index];
            }
        }]);

        return CardDataWindowScreens;
    }();

    exports.default = CardDataWindowScreens;
});