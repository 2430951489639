define('ux-pcc/components/dashboard-widget/running-charts', ['exports', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _moment, _stringifyQueryParams, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),

    currentSlideIndex: 0,

    tz: null,
    showComments: true,

    aggregationType: 'HOURLY_BOOKING_AGGREGATOR',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
      Ember.run.once(this, 'queryDataMethod');
    },


    chartData: Ember.computed('data', function () {
      return (this.get('data') || []).rejectBy('subContentType', 'FULL_SPAN');
    }),

    fullSpan: Ember.computed('data', function () {
      return (this.get('data') || []).findBy('subContentType', 'FULL_SPAN');
    }),

    bookedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BOOK_DATE'),

    isToday: Ember.computed('data', function () {
      var today = (0, _moment.default)().startOf('day');
      if (this.get('data.lastObject.entryDate')) {
        return today.isSameOrBefore((0, _moment.default)(this.get('data.lastObject.entryDate')), 'day');
      }
      return false;
    }),

    currentHour: Ember.computed('tz', 'data', function () {
      // use the users times
      var currentHour = (0, _moment.default)().format('H');
      return this.get('data').find(function (ae) {
        return ae.groupBy === 'TRANSACTION_HOUR' && currentHour === ae.groupByValue;
      });
    }),

    title: Ember.computed('currentSlideIndex', 'chartFields[]', function () {
      var index = this.get('currentSlideIndex');
      var config = this.get('chartFields')[index];
      return this.i18n.t('yield-manager.booking-trends.by-hour.title', { dataType: this.i18n.t('yield-manager.booking-trends.by-hour.' + config.chartDataType, { currency: this.get('currencyCode') }) });
    }),

    currencyCode: Ember.computed.readOnly('data.firstObject.currencyCode'),

    sliceResolution: 'hour',

    scrollBarConfig: Ember.computed(function () {
      return {
        graph: 'left',
        oppositeAxis: false,
        offset: 50,
        scrollbarHeight: 40,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.2,
        selectedBackgroundColor: '#888888',
        graphFillAlpha: 0,
        graphLineAlpha: 0,
        graphLineColor: '#888888',
        selectedGraphFillAlpha: 0,
        selectedGraphLineAlpha: 0,
        selectedGraphLineColor: '#888888',
        dragIconHeight: 20,
        dragIconWidth: 20,
        graphType: 'line',
        gridCount: 24,
        color: '#AAAAAA',
        dragIcon: 'dragIconRoundSmall',
        updateOnReleaseOnly: true
      };
    }),

    showDaysVarActuals: false,
    showYieldVarActuals: false,
    showRevVarActuals: false,

    daysChanged: false,

    cp0: Ember.computed('daysChanged', function () {
      var translationString = 'yield-manager.booking-trends.by-hour.' + (this.get('daysChanged') ? 'selected_day' : 'today');
      return this.i18n.t(translationString);
    }),

    cp1: Ember.computed.alias('model.view.dashboardViewParameters.CP1_LABEL'),

    cp2: Ember.computed.alias('model.view.dashboardViewParameters.CP2_LABEL'),

    selectedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BEGIN_DATE'),

    selectedEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.END_DATE'),

    headerRowValues: Ember.computed('isToday', 'fullSpan', 'currentHour', function () {
      return this.get('isToday') ? this.get('currentHour') : this.get('fullSpan');
    }),

    days_var: Ember.computed('headerRowValues', 'isToday', function () {
      return this.get('isToday') ? this.get('headerRowValues.dataValues.stlyPctCumulativeVariance') : this.get('headerRowValues.dataValues.stlyPctChangeDuration');
    }),

    yield_actual: Ember.computed('model.gross', 'fullSpan', function () {
      return this.get('fullSpan.dataValues.yield');
    }),

    yield_var: Ember.computed('model.gross', 'headerRowValues', 'isToday', function () {
      var prefix = this.get('isToday') ? 'Cumulative' : 'Change';
      return this.get('headerRowValues.dataValues.stlyPct' + prefix + 'Yield');
    }),

    rev_var: Ember.computed('model.gross', 'headerRowValues', 'isToday', function () {
      var prefix = this.get('isToday') ? 'Cumulative' : 'Change';
      return this.get('headerRowValues.dataValues.stlyPct' + prefix + 'Amount');
    }),

    lor_average: Ember.computed('fullSpan', function () {
      return this.get('fullSpan.dataValues.avgArrivalDuration');
    }),

    cumulativeSuffix: Ember.computed('isToday', function () {
      return this.get('isToday') ? 'Cumulative' : '';
    }),

    days_actual_cy: Ember.computed('cumulativeSuffix', 'headerRowValues', function () {
      return this.get('headerRowValues.dataValues.duration' + this.get('cumulativeSuffix'));
    }),

    days_actual_py: Ember.computed('headerRowValues', 'cumulativeSuffix', function () {
      return this.get('headerRowValues.dataValues.stlyDuration' + this.get('cumulativeSuffix'));
    }),

    yield_actual_cy: Ember.computed('model.gross', 'headerRowValues', 'cumulativeSuffix', function () {
      return this.get('headerRowValues.dataValues.yield' + this.get('cumulativeSuffix'));
    }),

    yield_actual_py: Ember.computed('model.gross', 'headerRowValues', 'cumulativeSuffix', function () {
      return this.get('headerRowValues.dataValues.stlyYield' + this.get('cumulativeSuffix'));
    }),

    rev_actual_cy: Ember.computed('model.gross', 'headerRowValues', 'cumulativeSuffix', function () {
      return this.get('headerRowValues.dataValues.amount' + this.get('cumulativeSuffix'));
    }),

    rev_actual_py: Ember.computed('model.gross', 'headerRowValues', 'cumulativeSuffix', function () {
      return this.get('headerRowValues.dataValues.stlyAmount' + this.get('cumulativeSuffix'));
    }),

    daysDataFn: Ember.computed(function () {
      var settings = this.get('customer.settings');
      return function (data) {
        return data.map(function (d) {
          return {
            slice: (0, _moment.default)(d.groupByValue, 'h'),
            formattedDate: (0, _dateFormatter.dateFormatter)(d.dataValues.beginDate, { settings: settings }),
            formattedHour: (0, _moment.default)(d.groupByValue, 'h').format('HH:00'),
            currencyCode: d.currencyCode,
            cumCY: Number(d.dataValues.durationCumulative),
            cumLW: Number(d.dataValues.stlwDurationCumulative),
            cumLY: Number(d.dataValues.stlyDurationCumulative),
            addCY: Number(d.dataValues.duration),
            addLW: Number(d.dataValues.stlwDuration),
            addLY: Number(d.dataValues.stlyDuration)
          };
        });
      };
    }),

    yieldDataFn: Ember.computed(function () {
      var settings = this.get('customer.settings');
      return function (data) {
        return data.map(function (d) {
          return {
            slice: (0, _moment.default)(d.groupByValue, 'h'),
            formattedDate: (0, _dateFormatter.dateFormatter)(d.dataValues.beginDate, { settings: settings }),
            formattedHour: (0, _moment.default)(d.groupByValue, 'h').format('HH:00'),
            currencyCode: d.currencyCode,
            cumCY: Number(d.dataValues.yieldCumulative),
            cumLW: Number(d.dataValues.stlwYieldCumulative),
            cumLY: Number(d.dataValues.stlyYieldCumulative),
            addCY: Number(d.dataValues.yield),
            addLW: Number(d.dataValues.stlwYield),
            addLY: Number(d.dataValues.stlyYield)
          };
        });
      };
    }),

    revenueDataFn: Ember.computed(function () {
      var settings = this.get('customer.settings');
      return function (data) {
        return data.map(function (d) {
          return {
            slice: (0, _moment.default)(d.groupByValue, 'h'),
            formattedDate: (0, _dateFormatter.dateFormatter)(d.dataValues.beginDate, { settings: settings }),
            formattedHour: (0, _moment.default)(d.groupByValue, 'h').format('HH:00'),
            currencyCode: d.currencyCode,
            cumCY: Number(d.dataValues.amountCumulative),
            cumLW: Number(d.dataValues.stlwAmountCumulative),
            cumLY: Number(d.dataValues.stlyAmountCumulative),
            addCY: Number(d.dataValues.amount),
            addLW: Number(d.dataValues.stlwAmount),
            addLY: Number(d.dataValues.stlyAmount)
          };
        });
      };
    }),

    lorDataFn: Ember.computed(function () {
      var settings = this.get('customer.settings');
      return function (data) {
        return data.map(function (d) {
          return {
            slice: (0, _moment.default)(d.groupByValue, 'h'),
            formattedDate: (0, _dateFormatter.dateFormatter)(d.dataValues.beginDate, { settings: settings }),
            formattedHour: (0, _moment.default)(d.groupByValue, 'h').format('HH:00'),
            currencyCode: d.currencyCode,
            cumCY: Number(d.dataValues.avgArrivalDurationCumulative),
            cumLW: Number(d.dataValues.stlwAvgArrivalDurationCumulative),
            cumLY: Number(d.dataValues.stlyAvgArrivalDurationCumulative),
            addCY: Number(d.dataValues.avgArrivalDuration),
            addLW: Number(d.dataValues.stlwAvgArrivalDuration),
            addLY: Number(d.dataValues.stlyAvgArrivalDuration)
          };
        });
      };
    }),

    queryUrl: Ember.computed('aggregationType', function () {
      var params = {
        aggregationType: this.get('aggregationType'),
        rollupStatus: 'ROLLUP',
        aggregation: false
      };

      return '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);
    }),

    _fireRequeryObserver: Ember.observer('queryUrl', 'model.view', function () {
      Ember.run.once(this, 'queryDataMethod');
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var tz = this.get('tz');

      var view = this.get('model.view');

      this.set('loading', true);

      if (this.get('aggregationType') === 'HOURLY_BOOKING_AGGREGATOR') {
        view = JSON.parse(JSON.stringify(this.get('model.view')));

        // 'HOURLY_BOOKING_AGGREGATOR' requires 'LEVEL_HIERARCHY' to be 'LEVEL_EXACT'
        // let reservationTypeParam = view.dashboardViewParameters.LEVEL_HIERARCHY;
        // reservationTypeParam = 'LEVEL_EXACT';

        // set groupBy to BOOK_DATE
        Ember.set(view.dashboardViewParameters, 'GROUP_BY', 'BOOK_DATE');

        // set viewBy to HOUR
        Ember.set(view.dashboardViewParameters, 'VIEW_BY', 'HOUR');
      }

      return this.ajax.post(this.get('queryUrl'), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(view, tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;

        _this.send('resize');
        // Have to shift the hour by 1.
        collections.forEach(function (record) {
          if (record.groupBy === 'TRANSACTION_HOUR') {
            var hour = parseInt(record.groupByValue) + 1;
            record.groupByValue = '' + hour % 24;
          }
        });

        _this.set('data', collections);
      }).catch(function () {
        _this.set('data', []);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },
    toggleActuals: function toggleActuals(value) {
      this.toggleProperty('show' + value + 'Actuals');
    },


    actions: {
      resize: function resize() {
        Ember.run.later(function () {
          Ember.$(window).trigger('resize.menu-toggle');
          // Fire a resize event after the transition is complete

          if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
          } else {
            // Windows needs a little more time
            Ember.run.later(function () {
              // for IE and other old browsers
              // causes deprecation warning on modern browsers
              var evt = document.createEvent('UIEvents');
              evt.initUIEvent('resize', true, false, window, 0);
              window.dispatchEvent(evt);
            }, 100);
          }
        }, 400);
      }
    },

    chartFields: Ember.computed('chartData', function () {
      var _this2 = this;

      var data = this.get('chartData');
      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();

      return [{
        type: 'days',
        data: this.get('daysDataFn')(data)
      }, {
        type: 'yield',
        data: this.get('yieldDataFn')(data)
      }, {
        type: 'revenue',
        data: this.get('revenueDataFn')(data)
      }, {
        type: 'lor',
        data: this.get('lorDataFn')(data)
      }].map(function (d) {
        var showPrecision = d.type === 'yield' || d.type === 'revenue';
        return Ember.Object.create({
          leftAxisTitle: _this2.i18n.t('yield-manager.booking-trends.by-hour.' + d.type.toLowerCase() + '_axis', { currency: _this2.get('currencyCode') }),
          rightAxisTitle: _this2.i18n.t('yield-manager.booking-trends.by-hour.hourly_add'),
          showCurrency: false,
          chartDataType: d.type,
          data: d.data,
          exportConfig: {
            enabled: true,
            libs: { autoLoad: false },
            fileName: _this2.i18n.t('yield-manager.file-prefix.ysga.running-charts.title') + '_' + d.type + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
            exportFields: ['formattedDate', 'formattedHour', 'cumCY', 'cumLW', 'cumLY', 'addCY', 'addLW', 'addLY', 'currencyCode'],
            menu: [{
              class: 'export-main',
              menu: [{
                label: _this2.i18n.t('charts.save_as').toString(),
                menu: ['CSV']
              }]
            }]
          },
          values: [{
            key: 'addCY',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.add_label', { time: _this2.get('cp0') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type,
            chartType: 'column',
            legendClass: 'chart-color--' + d.type,
            enabled: true,
            category: 'right',
            valueClassname: 'addCYClass'
          }, {
            key: 'addLW',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.add_label', { time: _this2.get('cp1') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type + '-compare-lw',
            chartType: 'column',
            legendClass: 'chart-color--' + d.type + '-compare-lw',
            enabled: true,
            category: 'right',
            valueClassname: 'addLWClass'
          }, {
            key: 'addLY',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.add_label', { time: _this2.get('cp2') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type + '-compare',
            chartType: 'column',
            legendClass: 'chart-color--' + d.type + '-compare',
            enabled: true,
            category: 'right',
            valueClassname: 'addLYClass'
          }, {
            key: 'cumCY',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.' + d.type + '_label', { time: _this2.get('cp0') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type,
            chartType: 'line',
            bulletType: 'circle',
            legendClass: 'chart-color--' + d.type,
            enabled: true,
            shade: true,
            category: 'left',
            valueClassname: 'cumCYClass'
          }, {
            key: 'cumLW',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.' + d.type + '_label', { time: _this2.get('cp1') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type + '-compare-lw',
            chartType: 'line',
            bulletType: 'circle',
            legendClass: 'chart-color--' + d.type + '-compare-lw',
            enabled: true,
            shade: true,
            category: 'left',
            valueClassname: 'cumLWClass'
          }, {
            key: 'cumLY',
            label: _this2.i18n.t('yield-manager.booking-trends.by-hour.' + d.type + '_label', { time: _this2.get('cp2') }),
            format: showPrecision ? 'currency' : 'number',
            formatHash: d.type === 'revenue' ? _this2.get('formatRevenueOptions') : _this2.get('formatYieldOptions'),
            lineClasses: d.type + '-compare',
            chartType: 'line',
            bulletType: 'circle',
            legendClass: 'chart-color--' + d.type + '-compare',
            enabled: true,
            shade: true,
            category: 'left',
            valueClassname: 'cumLYClass'
          }]
        });
      });
    })
  });
});