define('ux-pcc/components/calendar-table/day-header', ['exports', 'ux-components/components/calendar-table/day-header'], function (exports, _dayHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dayHeader.default;
    }
  });
});