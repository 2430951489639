define('ux-pcc/components/rule/template-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    selectedTemplateRule: null,

    filteredTemplateRules: Ember.computed('templateRules', 'ruleType', function () {
      var templateRules = this.get('templateRules') || [];

      if (this.get('ruleType')) {
        templateRules = templateRules.filterBy('ruleType', this.get('ruleType'));
      }

      return templateRules;
    }),

    actions: {
      copyTemplate: function copyTemplate() {
        var selectedRule = this.get('selectedTemplateRule');
        this.get('copyTemplate')(selectedRule);
        this.get('close')();
      },
      cancelAndClose: function cancelAndClose() {
        this.set('selectedTemplateRule', null);
        this.get('close')();
      }
    }
  });
});