define('ux-pcc/components/portfolios/portfolio-panel-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    store: Ember.inject.service(),
    portfolioChildren: [],

    page: 1,
    resultsPerPage: 5,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isLoading', true);
      this.set('portfolioChildren', []);
      this.set('sort', []);

      // todo: US4123 Query Limit/ Max Row Size - standardize
      this.store.query('customer', {
        folderid: this.get('portfolio.id'),
        detaillevel: 'XREF',
        limit: 1500
        // fields: 'id,externalReference,name'
      }).then(function (results) {
        if (!_this.get('isDestroyed')) {
          _this.set('portfolioChildren', results);
        }
      }).catch(function () {
        if (!_this.get('isDestroyed')) {
          _this.set('portfolioChildren', []);
        }
      }).finally(function () {
        if (!_this.get('isDestroyed')) {
          _this.set('isLoading', false);
        }
      });
    },


    actions: {
      confirmUnlink: function confirmUnlink(parent, child) {
        this.confirmUnlink(parent, child);
      }
    }
  });
});