define('ux-pcc/components/hover-over', ['exports', 'ux-components/components/hover-over'], function (exports, _hoverOver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hoverOver.default;
    }
  });
});