define('ux-pcc/components/dashboard-widget/build-yield', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['build-yield'],

    data: null,

    addPY: true,
    addCY: true,
    cumPY: true,
    cumCY: true,

    showYieldVarActuals: false,
    showYstRoaActuals: false,
    showComments: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
    },


    chartData: Ember.computed('data', function () {
      return (this.get('data') || []).rejectBy('subContentType', 'FULL_SPAN');
    }),

    fullSpan: Ember.computed('data', function () {
      return (this.get('data') || []).findBy('subContentType', 'FULL_SPAN');
    }),

    thisWeek: Ember.computed('data', function () {
      var today = _moment.default.tz(this.get('tz.customer'));
      return this.get('chartData').find(function (ae) {
        return today.isSame(ae.entryDate, 'week');
      });
    }),

    yield_var: Ember.computed.readOnly('fullSpan.dataValues.pctChangeBaseYield'),
    cy_yield: Ember.computed.readOnly('fullSpan.dataValues.baseYield'),
    tw_yld_add: Ember.computed.readOnly('fullSpan.dataValues.twIncreaseYield'),
    py_tw_yld_add: Ember.computed.readOnly('fullSpan.dataValues.twStlyIncreaseYield'),
    tw_roa: Ember.computed.readOnly('fullSpan.dataValues.twRoaBaseYield'),
    yst_roa: Ember.computed.readOnly('fullSpan.dataValues.ystBaseYieldRoa'),
    full_span_add: Ember.computed.readOnly('fullSpan.dataValues.stlyBaseYield'),

    yield_actual_cy: Ember.computed.readOnly('fullSpan.dataValues.baseYield'),
    yield_actual_py: Ember.computed.readOnly('fullSpan.dataValues.eodYesterdayStlyBaseYield'),
    yield_actual_yst_cy: Ember.computed.readOnly('fullSpan.dataValues.ystBaseAddedYield'),
    yield_actual_yst_py: Ember.computed.readOnly('fullSpan.dataValues.ystStlyBaseAddedYield'),

    currencyCode: Ember.computed.readOnly('chartData.lastObject.currencyCode'),

    mappedData: Ember.computed('chartData', function () {
      var _this = this;

      var thisWeek = this.get('thisWeek');
      return this.get('chartData').map(function (ae) {
        var record = {
          slice: _moment.default.tz(ae.dataValues['endDate'], _this.get('tz.customer')),
          addedPriorYear: Number(ae.dataValues['stlyIncreaseBaseYield']),
          addedCurrentYear: Number(ae.dataValues['increaseBaseYield']),
          cumPriorYear: Number(ae.dataValues['stlyBaseYield']),
          cumCurrentYear: Number(ae.dataValues['baseYield']),
          cumPctVariance: Number(ae.dataValues['pctChangeBaseYield']),
          addPctVariance: Number(ae.dataValues['twRoaBaseYield'])
        };

        if (thisWeek && thisWeek.entryDate && ae.entryDate && ae.entryDate === thisWeek.entryDate) {
          record['cumPriorYear'] = Number(_this.get('yield_actual_py'));
          record['cumPriorYearToDate'] = Number(ae.dataValues['stlyBaseYield']);
          record['addPriorYearToDate'] = Number(ae.dataValues['eodYesterdayStlyIncreaseBaseYield']);
          record['cumPctVariance'] = Number(_this.get('yield_var'));
          record['addPctVariance'] = Number(_this.get('tw_roa'));
        }

        return record;
      });
    }),

    toggleActuals: function toggleActuals(value) {
      this.toggleProperty('show' + value + 'Actuals');
    }
  });
});