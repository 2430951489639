define('ux-pcc/routes/pacing/daily', ['exports', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fileSaver.default, {

    ajax: Ember.inject.service(),

    queryParams: {
      beginDate: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var tz = this.get('tz.customer');
      var beginDate = void 0;
      var startDate = void 0;
      var endDate = void 0;

      // If we have a begin date, use that as the starting point
      if (params.beginDate) {
        beginDate = _moment.default.tz(params.beginDate, tz).startOf('day');
      } else {
        beginDate = _moment.default.tz(tz).startOf('day');
      }

      if (this.get('deviceType.mobile')) {
        startDate = beginDate.clone();
        endDate = _moment.default.tz(beginDate, tz).endOf('day');
      } else {
        // Default to 7 days before, and 7 days after
        startDate = beginDate.clone().subtract(7, 'days');
        endDate = beginDate.clone().add(7, 'days').endOf('day');
      }

      var customerId = this.modelFor('customer').get('id');

      return Ember.RSVP.hash({
        currentPace: this.ajax.request('/rest/v1/pacingByDay', {
          data: {
            customerID: customerId,
            begindate: startDate.format('YYYY-MM-DD'),
            enddate: endDate.format('YYYY-MM-DD')
          }
        }).then(function (contents) {
          return contents;
        }, function () {
          return [];
        }),
        yieldable: this.ajax.request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: beginDate.format(),
            enddate: beginDate.format(),
            frequency: 'DAY',
            customerid: customerId,
            contenttype: 'INVENTORY',
            analytictype: 'COUNT',
            subcontenttype: 'PHYSICAL',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

          var analytic = (analyticEntries || []).findBy('subContentType', 'PHYSICAL');
          return analytic ? Ember.get(analytic, 'dataValue') : 0;
        }).catch(function () {
          return 0;
        }),
        beginDate: beginDate
      });
    },
    setupController: function setupController(controller, model) {
      // Set the controller begin and end dates to match those used in the Model
      controller.set('beginDate', model.beginDate.format());

      this._super(controller, model);
    },


    actions: {
      exportCsv: function exportCsv() {
        var _this = this;

        this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('pacing.message.download_requested'), 'success');
        var tz = this.get('tz.customer');
        var customerId = this.modelFor('customer').get('id');
        var beginDate = _moment.default.tz(this.controller.get('beginDate'), tz);
        var endDate = _moment.default.tz(this.controller.get('beginDate'), tz).add(90, 'days');
        var filename = this.i18n.t('pacing.per_day.file_prefix') + '_' + this.modelFor('customer').get('externalReference') + '_' + beginDate.format('YYYYMMDD') + '.csv';

        this.ajax.request('/rest/v1/pacingByDay', {
          headers: { accept: 'text/csv' },
          contentType: 'text/csv',
          dataType: 'blob',
          data: {
            customerID: customerId,
            begindate: beginDate.format('YYYY-MM-DD'),
            enddate: endDate.format('YYYY-MM-DD'),
            isRealTime: true
          }
        }).then(function (data) {
          return _this.saveFileAs(filename, data, 'text/csv');
        }, function () {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this.i18n.t('customer.performance.download_error'), 'danger');
        });
      }
    }
  });
});