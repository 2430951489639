define('ux-pcc/components/message-feed', ['exports', 'ux-components/components/message-feed'], function (exports, _messageFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _messageFeed.default;
    }
  });
});