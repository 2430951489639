define('ux-pcc/components/guarantee-panel/guarantee-form-travel-agent', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-travel-agent'], function (exports, _guaranteeFormTravelAgent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormTravelAgent.default;
    }
  });
});