define('ux-pcc/routes/customer/performance/auto-matrix-pricing', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: {
      startDate: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {

      // If this is a sister property, redirect to the dashboard
      var customer = this.controllerFor('customer').set('model', this.modelFor('customer'));
      if (this.controllerFor('customer').get('isSisterProperty')) {
        this.transitionTo('/customers/' + customer.get('id'));
      }
    },
    model: function model(params) {
      var _this = this;

      var tz = this.tz.get("customer");
      var startDate = (params.startDate && _moment.default.tz(params.startDate, tz).isValid() ? _moment.default.tz(params.startDate, tz) : _moment.default.tz(tz)).startOf('day');

      var customer = this.modelFor('customer');

      return this.get('store').query('rule', {
        customerID: customer.get('id'),
        ruleType: 'PRICE_AUTO_ALLOCATION'
      }).then(function (rules) {
        return Ember.RSVP.hash({
          data: _this._requestData(startDate),
          adjustmentRule: (rules || []).get('firstObject'),
          date: startDate
        });
      });
    },
    setupController: function setupController(controller, model) {
      var date = model.date;
      controller.set('startDate', date.clone().format());
      controller.set('adjustmentRule', model.adjustmentRule);
      var analytics = model.data || [];
      var yesterday = analytics.analytics.findBy('name', 'PreviousDate');
      var threeWeekAvg = analytics.analytics.findBy('name', 'ThreeWeekAverage');
      // yesterday should come first
      if (yesterday) {
        analytics.analytics = analytics.analytics.removeObject(yesterday);
        analytics.analytics = analytics.analytics.insertAt(0, yesterday);
      }
      // need unique date for 3 week average since base date is returned
      if (threeWeekAvg) {
        threeWeekAvg.baseDate = _moment.default.tz(threeWeekAvg.baseDate, this.tz.get('customer')).subtract(3, 'weeks');
        threeWeekAvg.comparisonDate = _moment.default.tz(threeWeekAvg.comparisonDate, this.tz.get('customer')).subtract(3, 'weeks');
      }

      this._super(controller, analytics);
    },
    _requestData: function _requestData(date) {
      var customer = this.modelFor('customer');
      var customerId = customer.get('id');

      var template = {
        channelcode: 'ALL',
        begindate: date.clone().format(),
        enddate: date.clone().format(),
        frequency: 'DAY',
        customerid: customerId
      };

      var promises = {
        calendars: this.get('store').query('rule-calendar', {
          begin: date.clone().format(),
          end: date.clone().format(),
          customerID: customer.get('id'),
          ruleType: 'PRICE_AUTO_ALLOCATION'
        }),
        inventory: this.get('ajax').request('/rest/v1/analyticEntries', {
          data: Ember.merge({
            contenttype: 'INVENTORY',
            analytictype: 'COUNT',
            subcontenttype: 'YIELDABLE',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
          }, template)
        }),
        analytics: this.get('ajax').request('/rest/v1/reservations/barAllocationAnalysis', {
          data: {
            customerID: customer.get('id'),
            baseDate: date.clone().format('YYYY-MM-DD')
          }
        })
      };

      return Ember.RSVP.hash(promises);
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});