define('ux-pcc/routes/customer/performance/stayover', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      startDate: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var tz = this.tz.get("customer");
      var startDate = (params.startDate && _moment.default.tz(params.startDate, tz).isValid() ? _moment.default.tz(params.startDate, tz) : _moment.default.tz(tz)).startOf('day');

      return Ember.RSVP.hash({
        data: this._requestData(startDate),
        date: startDate
      });
    },
    setupController: function setupController(controller, model) {
      var date = model.date;
      controller.set('startDate', date.clone().format());
      var analytics = model.data || [];
      var fourWeekAvg = analytics.analytics.findBy('name', 'RollingAverage');
      // need unique date for 4 week average since base date is returned
      if (fourWeekAvg) {
        fourWeekAvg.baseDate = _moment.default.tz(fourWeekAvg.baseDate, this.tz.get('customer')).subtract(4, 'weeks');
      }

      this._super(controller, analytics);
    },
    _requestData: function _requestData(date) {
      var customer = this.modelFor('customer');

      var promises = {
        analytics: this.get('ajax').request('/rest/v1/reservations/stayoverAnalysis', {
          data: {
            customerID: customer.get('id'),
            baseDate: date.clone().format('YYYY-MM-DD')
          }
        })
      };

      return Ember.RSVP.hash(promises);
    }
  });
});