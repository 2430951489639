define('ux-pcc/initializers/configuration', ['exports', 'ux-pcc/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    // The public configuration needs to be loaded before anything else occurs
    application.deferReadiness();

    var appendCSS = function appendCSS(href) {

      return new Ember.RSVP.Promise(function (resolve, reject) {

        var link = document.createElement('link');
        link.id = 'theme';
        link.href = href;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.media = 'screen,print';
        link.onload = function () {
          resolve('Successfully loaded the theme.', 'successfully loaded the theme in initializers/configuration/appendCSS()');
        };
        link.onerror = function () {
          reject('Failed to load the theme.', 'failed to load the theme in initializers/configuration/appendCSS()');
        };

        var oldTheme = window.document.getElementById('theme');
        if (oldTheme) {
          oldTheme.parentNode.replaceChild(link, oldTheme);
        } else {
          document.getElementsByTagName('head')[0].appendChild(link);
        }
      });
    };

    var attachTheme = function attachTheme(_ref) {
      var cssUrl = _ref.cssUrl,
          theme = _ref.theme;

      // Customer Specific Styling
      if (theme) {
        return appendCSS('assets/themes/' + theme + '.css');
      } else if (cssUrl) {
        return appendCSS(cssUrl);
      } else {
        return Ember.RSVP.resolve('no theme was configured', 'noop in initializers/configuration/attachTheme()');
      }
    };

    new Ember.RSVP.Promise(function (resolve /*, reject*/) {
      // eslint-disable-next-line ember/jquery-ember-run
      Ember.$.ajax({
        url: '/public/config/pcc',
        dataType: 'json'
      }).done(function (config) {
        resolve(config, 'successfully requested application settings in initializers/configuration/initialize()');
      }).fail(function () /*error*/{
        //noop: Don't actually care if this fails, as we don't necessarily have a customer cssURL
        resolve({}, 'failed to request application settings in initializers/configuration/initialize()');
      });
    }).then(function (config) {

      var defaultConfig = {
        theme: _environment.default.theme
      };

      var mergedConfig = Ember.assign(defaultConfig, config);

      application.register('config:main', mergedConfig, { instantiate: false });
      application.inject('route', 'config', 'config:main');
      application.inject('controller', 'config', 'config:main');
      application.inject('component', 'config', 'config:main');
      application.inject('service', 'config', 'config:main');

      return attachTheme(config);
    }).finally(function () {
      application.advanceReadiness();
    });
  }

  exports.default = {
    name: 'config',
    initialize: initialize
  };
});