define('ux-pcc/controllers/customer/monthly-booking-summary', ['exports', 'moment', 'ux-components/utils/to-boolean', 'ux-pcc/mixins/queue-controller'], function (exports, _moment, _toBoolean, _queueController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_queueController.default, {

    queryParams: ['aggregateVersion', 'dataType', 'enhanced', 'nocache'],

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ajax: Ember.inject.service(),
    menuService: Ember.inject.service('menu'),
    permission: Ember.inject.service(),
    tempStorage: Ember.inject.service(),

    enhanced: null,

    zoomStartDate: null,
    zoomEndDate: null,

    yearComparisonChannelsDefault: null,
    yearComparisonRateDefault: null,

    showComments: false,

    revenuePrecision: Ember.computed('customer', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'PCC.YIELD_MANAGEMENT_REVENUE_PRECISION');
      return model && model.get('settingValue') ? model.get('settingValue') : 0;
    }),

    formatRevenueOptions: Ember.computed('customer.settings', 'revenuePrecision', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: this.get('revenuePrecision'),
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatYieldOptions: Ember.computed('customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: 2,
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    locationTypes: Ember.computed('i18n.locale', function () {
      return [{
        value: 'ALL',
        translation: this.get('i18n').t('dashboard-views.form.all')
      }, {
        value: 'HOMECITY',
        translation: this.get('i18n').t('dashboard-views.form.home_city')
      }, {
        value: 'AIRPORT',
        translation: this.get('i18n').t('dashboard-views.form.airport')
      }];
    }),

    fulfilled: Ember.computed.equal('dataType', 'TICKETS'),

    fulfillmentType: Ember.computed('fulfilled', function () {
      return this.get('fulfilled') ? 'fulfilled' : 'unfulfilled';
    }),

    sourceLocationCode: Ember.computed('fulfilled', function () {
      return this.get('fulfilled') ? 'TICKET' : 'RESERVATION';
    }),

    enableQueuedLoading: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_QUEUE_FOR_MBS');
      return settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false;
    }),

    allowTabularViews: Ember.computed('customer.settings', 'hasTabularViews', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_FUTURE_TRENDS_TABULAR_VIEWS');
      return this.get('hasTabularViews') && (settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false);
    }),

    hasTabularViews: Ember.computed('menuService.menus.length', function () {
      var _this = this;

      var menuList = this.get('menuService.flattenedMenus');
      var hasTabularViews = false;

      if (menuList && menuList.length > 0) {
        var viewMenu = menuList.findBy('reference', 'pcc.menu.property.yield-manager');

        if (viewMenu && viewMenu.get('children')) {
          viewMenu.get('children').sortBy('orderBy').forEach(function (menu) {
            var parameters = _this.get('menuService').getQueryStringParams(menu);
            if (parameters['viewType'] === 'YMTD') {
              hasTabularViews = true;
            }
          });
        }
      }

      return hasTabularViews;
    }),

    hasYsgaAccess: Ember.computed('menuService.menus.length', function () {
      var menuList = this.get('menuService.flattenedMenus');

      if (menuList && menuList.length > 0) {
        var viewMenu = menuList.findBy('reference', 'property.yield-manager.view.ysga');

        if (viewMenu) {
          return true;
        }
      }

      return false;
    }),

    allowAnalyze: Ember.computed('customer.settings', function () {
      var isPermitted = this.get('permission').isPermitted('ym-analyze', 'view');

      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_FUTURE_TRENDS_ANALYZE');
      return isPermitted && (settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false);
    }),

    allowOpportunities: Ember.computed('allowAnalyze', 'level', function () {
      return this.get('allowAnalyze') && !Ember.isEmpty(this.get('level')) && this.get('level') !== 'LEVEL_STATION';
    }),

    showBookingTrends: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_BOOKING_TRENDS');
      if (settings && settings.get('settingValue')) {
        return (0, _toBoolean.default)(settings.get('settingValue'));
      }
      return false;
    }),

    showMBSFilters: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_MBS_FILTERS');
      if (settings && settings.get('settingValue')) {
        return (0, _toBoolean.default)(settings.get('settingValue'));
      }
      return false;
    }),

    exportEnabled: Ember.computed('enhanced', function () {
      return (0, _toBoolean.default)(this.get('enhanced'));
    }),

    mappedLocationType: Ember.computed('locationType', function () {
      return this.get('locationType') !== 'ALL' ? this.get('locationType') : null;
    }),

    _updateOnQueryChange: function _updateOnQueryChange() {
      this.resetQueue();
      this.get('tempStorage').setValue('dataType', this.get('dataType'));
      this.get('tempStorage').setValue('enhanced', this.get('enhanced'));
      this.get('tempStorage').setValue('reservationCategory', this.get('reservationCategory'));
      this.get('tempStorage').setValue('selectedDate', this.get('selectedDate'));

      if (this.get('level') !== 'LEVEL_STATION') {
        this.get('tempStorage').setValue('locationType', this.get('locationType'));
      }
    },


    queryParamObserver: Ember.observer('dataType', 'enhanced', 'locationType', 'reservationCategory', 'selectedDate', function () {
      Ember.run.once(this, '_updateOnQueryChange');
    }),

    selectedMoment: Ember.computed('selectedDate', 'tz.customer', 'i18n.locale', function () {
      return _moment.default.tz(this.selectedDate, this.tz.customer).startOf('day');
    }),

    // plannedEnhancement: handle portfolios/xrefs
    breadcrumbPermission: Ember.computed('userCustomer', 'breadcrumbCustomer', function () {
      var userCustomer = this.get('userCustomer');
      var breadcrumbCustomer = this.get('breadcrumbCustomer');

      var sameCustomer = userCustomer.get('id') === breadcrumbCustomer.get('id');
      var childCustomer = breadcrumbCustomer.get('parentTreePath').includes(userCustomer.get('id'));

      return sameCustomer || childCustomer;
    }),

    actions: {
      analyze: function analyze() {
        var queryParams = {
          view: 'MBS',
          selectedDate: this.get('zoomStartDate').format('YYYY-MM-DD'),
          selectedEndDate: this.get('zoomEndDate').format('YYYY-MM-DD')
        };

        this.transitionToRoute('yield-manager.analyze', { queryParams: queryParams });
      },
      opportunities: function opportunities() {
        var queryParams = {
          view: 'MBS',
          selectedDate: this.get('zoomStartDate').format('YYYY-MM-DD'),
          selectedEndDate: this.get('zoomEndDate').format('YYYY-MM-DD'),
          mode: 'tables',
          tab: 'OPPORTUNITIES'
        };

        this.transitionToRoute('yield-manager.analyze', { queryParams: queryParams });
      },
      gotoYsga: function gotoYsga(bookingDate) {
        var selectedDate = this.get('selectedMoment');
        var today = (0, _moment.default)();
        // For current month, arrival start day should be today, otherwise 1st of the selected month
        var beginDate = selectedDate.isSame(today, 'year') && selectedDate.isSame(today, 'month') ? today.format('YYYY-MM-DD') : selectedDate.clone().startOf('month').format('YYYY-MM-DD');
        var queryParams = {
          viewType: 'YSGA',
          viewBookDate: (0, _moment.default)(bookingDate).format('YYYY-MM-DD'),
          viewBeginDate: beginDate,
          viewEndDate: selectedDate.clone().endOf('month').format('YYYY-MM-DD')
        };

        this.transitionToRoute('yield-manager.view', { queryParams: queryParams });
      },
      gotoTabularViews: function gotoTabularViews() {
        var queryParams = {
          viewType: 'YMTD',
          viewBeginDate: this.get('zoomStartDate').format('YYYY-MM-DD'),
          viewEndDate: this.get('zoomEndDate').format('YYYY-MM-DD')
        };

        this.transitionToRoute('yield-manager.view', { queryParams: queryParams });
      },
      setZoomDates: function setZoomDates(inZoomStartDate, inZoomEndDate) {
        if (!inZoomStartDate.isSame(this.get('zoomStartDate'))) {
          this.set('zoomStartDate', inZoomStartDate);
        }
        if (!inZoomEndDate.isSame(this.get('zoomEndDate'))) {
          this.set('zoomEndDate', inZoomEndDate);
        }
      },
      onSelectedDateChange: function onSelectedDateChange(date) {
        if (!date.isSame(this.selectedDate, 'month')) {
          this.set('selectedDate', date.startOf('month').format('YYYY-MM-DD'));
        }
      }
    }
  });
});