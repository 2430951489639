define('ux-pcc/initializers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.Route.reopen({

      actions: {
        returnOrTransitionTo: function returnOrTransitionTo() /*args*/{
          // If there is a previous state, we can return to it. Otherwise, pass through the normal transitionTo args.
          if (history && history.length > 1) {
            history.back();
          } else {
            this.transitionTo.apply(this, arguments);
          }
        },
        returnOrReplaceWith: function returnOrReplaceWith() /*args*/{
          // If there is a previous state, we can return to it. Otherwise, pass through the normal replaceWith args.
          if (history && history.length > 1) {
            history.back();
          } else {
            this.replaceWith.apply(this, arguments);
          }
        },
        willTransition: function willTransition() {
          /*
          let hash = window.location.hash;
          // Not a super fan of global state, but I struggled to find a way to get the most recent hash
          // injected into the current route, without success.
          // Stores global hash location of where we just were.
          Route._prevHash = hash.substring(1, hash.length);
           return true;
          */
        },
        didTransition: function didTransition() {
          // record the route event with Intercom, Google Analytics, UserVoice, etc

          /*
           var metadata = {
           hash: window.location.hash,
           route: this.controllerFor("application").get("currentRouteName"),
           path: this.controllerFor("application").get("currentPath")
           };
            // Used for Intercom.io
           window.Intercom('trackEvent',this.controllerFor("application").get("currentRouteName"), metadata);
           */
          return true;
        }
      }
    });
  }

  exports.default = {
    name: 'route',
    initialize: initialize
  };
});