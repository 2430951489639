define("ux-pcc/routes/manage-competitors", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor("customer");
      return { customer: customer };
    }
  });
});