define('ux-pcc/components/portfolios/portfolio-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),

    canEdit: true,
    searchText: '',

    userTokenOptions: Ember.computed('users', function () {
      var users = this.get('users') || [];
      // constructs the suggestion engine
      var engine = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return Bloodhound.tokenizers.whitespace(d.label);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: users.map(function (user) {
          return {
            value: user.get('id'),
            label: user.get('fullName')
          };
        })
      });

      var typeahead = [{
        hint: true,
        minLength: 2,
        classNames: { menu: 'tt-dropdown-menu' }
      }, {
        name: 'users',
        displayKey: 'label',
        source: engine
      }];

      return {
        typeahead: typeahead,
        createTokensOnBlur: true,
        allowDuplicates: false
      };
    }),

    managersObject: Ember.computed('managers', function () {
      var json = this.get('managers');
      if (!json) {
        return Ember.Object.create({
          userTokens: []
        });
      }
      return Ember.Object.create(JSON.parse(json));
    }),

    managersChanged: Ember.observer('managersObject.userTokens.[]', function () {
      this.set('managers', JSON.stringify(this.get('managersObject')));
    }),

    actions: {
      close: function close() {
        this.get('close')();
      },
      showModal: function showModal(modal, model) {
        this.get('showModal')(modal, model);
      },
      savePortfolio: function savePortfolio() {
        var _this = this;

        var model = this.get('model');
        if (!model.get('affiliateCustomer.id')) {
          model.set('affiliateCustomer', this.customer);
        }

        model.save().then(function () {
          // Success!  Save manager setting
          var settings = model.get('settings');
          var setting = (settings || []).findBy('settingCode', "MANAGERS") || _this.store.createRecord('setting', {
            fkID: model.get('id'),
            fkReference: "CUSTOMER",
            settingCode: "MANAGERS",
            settingValue: _this.get('managers'),
            shortDescription: "Portfolio Managers"
          });

          setting.set('settingValue', _this.get('managers'));
          setting.save();

          // Show the success modal
          _this.dialog.showSuccess({
            titleText: _this.i18n.t('portfolio.success_modal.title'),
            messageText: _this.i18n.t('portfolio.success_modal.update_success_message', { portfolioName: model.get('name') }),
            confirmText: _this.i18n.t('portfolio.success_modal.button_label'),
            onConfirm: function onConfirm() {
              _this.get('close')();
            }
          });
        }).catch(function (error) {
          _this.get('flash')("<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      removeProperty: function removeProperty(property) {
        var _this2 = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('portfolios.list.index.messages.confirm_remove'),
          messageText: this.i18n.t('portfolios.list.index.messages.removal_warning_message', {
            propertyName: property.get('name'),
            portfolioName: this.get('model.name')
          }) + " " + this.i18n.t('portfolios.list.index.messages.removal_proceed'),
          confirmText: this.i18n.t('portfolios.list.index.messages.removal_continue'),
          cancelText: this.i18n.t('portfolios.list.index.messages.removal_cancel'),
          onConfirm: function onConfirm() {

            var parent = _this2.get('model');

            if (parent && property) {
              // Remove deleted xref
              var url = '/rest/v1/customerXrefs/customer/' + parent.get('id') + '/xrefcustomer/' + property.get('id') + '/type/LINK';
              _this2.ajax.delete(url).then(function () /* data */{

                // Show the success modal
                _this2.dialog.showSuccess({
                  titleText: _this2.i18n.t('portfolios.list.unlink_success_modal.title'),
                  messageText: _this2.i18n.t('portfolios.list.unlink_success_modal.success_message', { propertyName: property.get('name') }),
                  confirmText: _this2.i18n.t('portfolios.list.unlink_success_modal.button_label'),
                  onConfirm: function onConfirm() {
                    _this2.dialog.close();
                  }
                });
                // Remove deleted xref from list
                parent.get('children').removeObject(property);
                parent.get('xrefs').removeObject(property);
                _this2.set('searchText', "");
              }).catch(function (error) {
                _this2.get('flash')("<i class='fa fa-warning'></i> " + error.responseText, 'danger');
              });
            }
          },
          onCancel: function onCancel() {
            _this2.dialog.close();
          }
        });
      }
    }

  });
});