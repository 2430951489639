define('ux-pcc/controllers/rules/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['childRuleId'],
    childRuleId: null,
    saveLabel: 'rule._form.save_rule',
    successMessage: 'rule._form._success_modal.success_update_message',
    ruleNameLabel: 'rule._form.name',
    ruleDescriptionLabel: 'rule._form.description',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model')
  });
});