define('ux-pcc/components/charts/plot-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        /**
         The `tagName` attribute.
          @property tagName
         @type String
         @default "div"
         */
        tagName: 'div',

        /**
         The `classNames` attribute.
          @property classNames
         @type Array
         @default "plot-chart"
         */
        classNames: ['plot-chart'],

        /**
         The `data` the plot chart should use.
          @property data
         @type Array
         @default []
         */
        data: [],

        /**
         The `type` the plot chart should be. Options are pie, bar or line.
          @property type
         @type String
         @default "pie"
         */
        type: "pie",

        /**
         The css `width` the plot chart should be.
          @property type
         @type String
         @default "100%"
         */
        width: "100%",

        /**
         The css `height` the plot chart should be.
          @property height
         @type String
         @default "150px"
         */
        height: "150px",

        /**
         The `settings` the plot chart should use. If null it will default to the settings based on type.
          @property settings
         @type Object
         @default null
         */
        settings: null,

        didInsertElement: function didInsertElement() {
            this._setupPlotChart();
        },


        /**
         * Setup the plot chart with and add it to the current element
         */
        _setupPlotChart: function _setupPlotChart() {
            this.$().css({
                width: this.get('width'),
                height: this.get('height')
            });

            return this.$().plot(this.get('data'), this._getSettings(this.get('type')));
        },


        /**
         * Create base settings for pie, bar, and line charts based on ace theme
         */
        _getSettings: function _getSettings(type) {
            if (this.get('settings') != null) {
                return this.get('settings');
            }

            if (type === "pie") {
                return {
                    series: {
                        pie: {
                            show: true,
                            tilt: 0.8,
                            highlight: {
                                opacity: 0.25
                            },
                            stroke: {
                                color: "#fff",
                                width: 2
                            },
                            startAngle: 2
                        }
                    },
                    legend: {
                        show: true,
                        position: "ne",
                        labelBoxBorderColor: null,
                        margin: [-30, 15]
                    },
                    grid: {
                        hoverable: true,
                        clickable: true
                    }
                };
            } else if (type === "bar") {
                return {
                    hoverable: true,
                    shadowSize: 0,
                    series: {
                        bars: {
                            show: true
                        },
                        lines: {
                            show: false
                        },
                        points: {
                            show: false
                        }
                    },
                    xaxis: {
                        tickLength: 2,
                        ticks: 23,
                        min: 0,
                        max: 24
                    },
                    yaxis: {
                        ticks: 10,
                        min: 0,
                        max: 30,
                        tickDecimals: 0
                    },
                    grid: {
                        backgroundColor: {
                            colors: ["#fff", "#fff"]
                        },
                        borderWidth: 1,
                        borderColor: "#555"
                    }
                };
            } else if (type === "line") {
                return {
                    hoverable: true,
                    shadowSize: 0,
                    series: {
                        lines: {
                            show: true
                        },
                        points: {
                            show: true
                        }
                    },
                    xaxis: {
                        tickLength: 0,
                        tickDecimals: 0
                    },
                    yaxis: {
                        ticks: 10,
                        min: 0,
                        max: 100,
                        tickDecimals: 0
                    },
                    grid: {
                        backgroundColor: {
                            colors: ["#fff", "#fff"]
                        },
                        borderWidth: 1,
                        borderColor: "#555"
                    }
                };
            }
        }
    });
});