define('ux-pcc/components/rule/form-modal', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'ux-pcc/mixins/flashable', 'moment'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _flashable, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend(_flashable.default, {

    dialog: Ember.inject.service(),

    rule: Ember.computed.readOnly('model.rule'),
    ruleType: Ember.computed.readOnly('model.ruleType'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('childValidators', []);
    },


    headerMessage: Ember.computed('model.{beginDate,endDate}', function () {
      var beginDate = (0, _moment.default)(this.get('model.beginDate')).utc();
      if (this.get('model.endDate')) {
        var endDate = (0, _moment.default)(this.get('model.endDate')).utc();
        return this.i18n.t('rule._form_modal.condition_dates', {
          startDayOfWeek: beginDate.format("dddd"),
          startDate: beginDate.format("l"),
          endDayOfWeek: endDate.format("dddd"),
          endDate: endDate.format("l"),
          htmlSafe: true
        });
      } else {
        return this.i18n.t('rule._form_modal.condition_dates_ongoing', {
          startDayOfWeek: beginDate.format("dddd"),
          startDate: beginDate.format("l"),
          htmlSafe: true
        });
      }
    }),

    // TODO: Expand in the future as more ruleTypes need required groupings
    groupClassNames: Ember.computed('ruleType', function () {
      if (this.get('ruleType') === 'ALERT_COMP') {
        return 'rule-group--first-required';
      }

      return '';
    }),

    validations: Ember.computed('i18n.locale', function () {
      return {
        shortDescription: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.i18n.t('rule._form_modal.no_name').toString()
        })
      };
    }),

    changeset: Ember.computed('rule', function () {
      var validations = this.get('validations');
      var changeset = new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);

      var children = this.get('rule.children') || [];
      changeset.set('children', [].concat(_toConsumableArray(children)));
      return changeset;
    }),

    actions: {
      close: function close() {
        if (!this.get('rule.shortDescription')) {
          this.get('model.onRemoveCondition')(this.get('rule'));
        }

        this.get('onRemoveModal')();
      },
      destroy: function destroy() {
        this.get('model.onRemoveCondition')(this.get('rule'));
        this.get('onRemoveModal')();
      },
      save: function save() {
        var _this = this;

        var childChangesets = this.get('childValidators');

        var changeset = this.get('changeset');

        var promises = childChangesets.map(function (c) {
          return c.validate();
        });
        promises.push(changeset.validate());

        Ember.RSVP.all(promises, "Resolving rule validation promises").then(function () {

          var isValid = childChangesets.reduce(function (result, current) {
            return result && current.get('isValid');
          }, changeset.get('isValid'));

          if (isValid) {

            childChangesets.forEach(function (c) {
              return c.execute();
            });
            changeset.execute();

            var rule = _this.get('rule');

            var filterValue = Ember.get(rule, 'filterValue');
            // Convert contents to json object if necessary before trying to stringify it
            if (typeof filterValue === 'string' && filterValue.indexOf('{') > -1) {
              filterValue = Ember.Object.create(JSON.parse(filterValue));
            }
            // Only stringify objects
            if ((typeof filterValue === 'undefined' ? 'undefined' : _typeof(filterValue)) === 'object') {
              Ember.set(rule, 'filterValue', JSON.stringify(filterValue));
            }

            var successMsg = _this.get('model.successMessage');
            var returnLinkLabel = _this.get('model.successReturnLink');
            if (_this.get('ruleType') === 'CEILING') {
              // Closing this modal
              _this.get('onRemoveModal')();

              // Show the success modal
              _this.dialog.showSuccess({
                titleText: _this.i18n.t('rule._form._success_modal.title'),
                messageText: successMsg || _this.i18n.t('rule._form_modal._success_modal.success_message'),
                confirmText: returnLinkLabel || _this.i18n.t('rule._form_modal._success_modal.link_label'),
                onConfirm: function onConfirm() {
                  _this.dialog.close();
                }
              });
            } else {
              // Closing this modal
              _this.get('onRemoveModal')();

              var onNewCondition = _this.get('model.onNewCondition');

              // Show the success modal
              _this.dialog.showConfirmation({
                titleText: _this.i18n.t('rule._form._success_modal.title'),
                messageText: successMsg || _this.i18n.t('rule._form_modal._success_modal.success_message'),
                confirmText: _this.i18n.t('rule._form_modal._success_modal.button_label'),
                cancelText: returnLinkLabel || _this.i18n.t('rule._form_modal._success_modal.link_label'),
                onConfirm: function onConfirm() {
                  onNewCondition();
                  _this.dialog.close();
                }
              });
            }
          } else {
            _this.send('flash', _this.i18n.t('rule._form_modal.validation_failure'), 'danger');
          }
        });
      },
      or: function or(condition, rule) {
        var ruleIndex = this.get('changeset.children').indexOf(rule);

        var newRule = { filterType: "ALL", ruleMethod: "FILTER", children: [] };
        // If this is a Competitor alert, insert a Competitor dropdown condition
        if (this.get('ruleType') === 'ALERT_COMP') {
          var competitorRule = { filterField: 'COMPETITOR', filterType: 'FIELD_WHERE', ruleMethod: "FILTER" };
          newRule.children.addObject(competitorRule);
        }
        newRule.children.addObject(condition);

        this.get('changeset.children').insertAt(ruleIndex + 1, newRule);
      },
      remove: function remove(rule) {
        this.get('changeset.children').removeObject(rule);

        if (this.get('changeset.children.length') === 0) {
          var newRule = { filterType: "ALL", children: [{ filterType: "FIELD_WHERE", ruleMethod: "FILTER" }] };

          // If this is a Competitor alert, insert a Competitor dropdown condition
          if (this.get('ruleType') === 'ALERT_COMP') {
            var competitorRule = { filterField: 'COMPETITOR', filterType: 'ALL', ruleMethod: "FILTER" };
            newRule.children.insertAt(0, competitorRule);
          }

          this.get('changeset.children').addObject(newRule);
        }
      },
      registerValidation: function registerValidation(v) {
        this.get('childValidators').push(v);
      },
      unregisterValidation: function unregisterValidation(v) {
        this.get('childValidators').removeObject(v);
      },
      toggleDay: function toggleDay(index, value) {
        // day-switch-bank starts at 0, where as the rule starts at 1, so add one to the index passed from
        // the component
        Ember.set(this.get('changeset'), 'day' + (index + 1), value);
      }
    }

  });
});