define('ux-pcc/controllers/yield-manager/analyze/index', ['exports', 'moment', 'accounting/format-number', 'ux-pcc/utils/serialize-dashboard-view', 'ember-cli-file-saver/mixins/file-saver', 'ux-components/utils/format-currency', 'ux-components/utils/stringify-query-params', 'ux-components/utils/to-boolean'], function (exports, _moment, _formatNumber, _serializeDashboardView, _fileSaver, _formatCurrency, _stringifyQueryParams, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_fileSaver.default, {

    queryParams: ['aggregateVersion', {
      tab: {
        scope: 'controller'
      }
    }],

    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      return version !== '1' ? 'FACET_SEARCH_GROUPING_AGGREGATOR_V2' : 'FACET_SEARCH_COMBO_GROUPING_AGGREGATOR';
    }),

    showDays: true,
    showDaysCompare: true,
    showYield: true,
    showYieldCompare: true,
    showRevenue: true,
    showRevenueCompare: true,

    ajax: Ember.inject.service(),
    parentController: Ember.inject.controller('yield-manager.analyze'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    selectedDate: Ember.computed.alias('parentController.selectedDate'),
    view: Ember.computed.alias('parentController.model'),
    mode: Ember.computed.alias('parentController.mode'),
    tableModeOnly: Ember.computed.alias('parentController.tableModeOnly'),
    level: Ember.computed.alias('parentController.currentLevel'),
    selectedChartEndDate: null,

    levelTree: Ember.computed.readOnly('model'),
    resultsPerPage: 10,

    tab: null,
    selectedCategory: null,
    selectedCategoryDescription: '',
    selectedDataView: 'ACTUAL',
    _selectedDataViewObserver: Ember.observer('selectedDataView', function () {
      if (this.get('selectedDataView') === 'PERCENT') {
        this.set('dataViewParameter', 'PERCENT');
      } else {
        this.set('dataViewParameter', 'ACTUAL');
      }
    }),

    productBlueprintCountParam: Ember.computed.readOnly('view.dashboardViewParameters.PRODUCT_BLUEPRINT_COUNT'),

    showProductGroupBreakdown: Ember.computed('productBlueprintCountParam', 'showProducts', function () {
      return this.get('showProducts') && this.get('productBlueprintCountParam') === 1;
    }),

    showSubchannels: Ember.computed('customer.{id,settings.[]}', function () {
      var settings = this.get('customer.settings');
      var showAgreementsSetting = settings.findBy('settingCode', 'PCC.ADVANCED_ANALYTICS_ENABLE_SUBCHANNELS');
      return showAgreementsSetting && showAgreementsSetting.get('settingValue') ? (0, _toBoolean.default)(showAgreementsSetting.get('settingValue')) : false;
    }),

    showProducts: Ember.computed('customer.{id,settings.[]}', function () {
      var settings = this.get('customer.settings');
      var showProductSetting = settings.findBy('settingCode', 'PCC.ADVANCED_ANALYTICS_FILTER_ENABLE_PRODUCTS');
      return showProductSetting && showProductSetting.get('settingValue') ? (0, _toBoolean.default)(showProductSetting.get('settingValue')) : false;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('openedDrawers', []);
      this.set('currentTableData', []);
      this.set('chargeDaysData', []);
      this.set('chargeDaysTableData', []);
      this.set('ratePlanData', []);
      this.set('ratePlanTableData', []);
      this.set('channelData', []);
      this.set('channelTableData', []);
      this.set('subchannelData', []);
      this.set('subchannelTableData', []);
      this.set('brandData', []);
      this.set('brandTableData', []);
      this.set('lengthOfRentData', []);
      this.set('lengthOfRentTableData', []);
      this.set('bookSrcCountryData', []);
      this.set('bookSrcCountryTableData', []);
      this.set('contractAccountData', []);
      this.set('contractAccountTableData', []);
      this.set('bookSrcAccountData', []);
      this.set('bookSrcAccountTableData', []);
      this.set('productTypeData', []);
      this.set('productTypeTableData', []);
      this.set('productsubtypeTableData', []);
      this.set('opportunityData', []);
      this.set('baseSort', ['cy_revenue.sort:desc']);
      this.set('opportunitiesSort', ['change_revenue_pct.sort']);
    },


    // Need to save state when switching dataViews
    openedDrawers: null,

    dataViewParameter: Ember.computed.alias('view.dashboardViewParameters.DATA_VIEW'),

    displayPercentages: Ember.computed.equal('selectedDataView', 'PERCENT'),
    synchronizePanels: true,

    baseSort: null,
    baseList: null,
    currentTableData: null,
    currentTableLoading: false,

    opportunitiesSort: null,
    opportunitiesFetchKey: 'change_revenue_pct',
    opportunityList: null,

    chargeDaysLoading: false,
    chargeDaysData: null,
    chargeDaysTableData: null,
    organizationUrl: null,
    organizationWeekTracker: null,

    ratePlanLoading: false,
    ratePlanData: null,
    ratePlanTableData: null,
    ratesUrl: null,
    ratesWeekTracker: null,

    channelLoading: false,
    channelData: null,
    channelTableData: null,
    channelUrl: null,
    channelWeekTracker: null,

    subchannelLoading: false,
    subchannelData: null,
    subchannelTableData: null,
    subchannelUrl: null,
    subchannelWeekTracker: null,

    brandLoading: false,
    brandData: null,
    brandTableData: null,
    brandUrl: null,
    brandTracker: null,

    lengthOfRentLoading: false,
    lengthOfRentData: null,
    lengthOfRentTableData: null,
    lorUrl: null,
    lengthOfRentTracker: null,

    bookSrcCountryLoading: false,
    bookSrcCountryData: null,
    bookSrcCountryTableData: null,
    bookSrcCountryUrl: null,
    bookSrcCountryTracker: null,

    contractAccountLoading: false,
    contractAccountData: null,
    contractAccountTableData: null,
    contractAccountUrl: null,
    contractAccountTracker: null,

    bookSrcAccountLoading: false,
    bookSrcAccountData: null,
    bookSrcAccountTableData: null,
    bookSrcAccountUrl: null,
    bookSrcAccountTracker: null,

    productTypeLoading: false,
    productTypeData: null,
    productTypeTableData: null,
    productsubtypeTableData: null,
    typesUrl: null,
    productTypeWeekTracker: null,

    opportunitiesLoading: false,
    opportunityData: null,
    opportunitiesUrl: null,

    topLevelProducts: Ember.computed.alias('parentController.productGroups'),

    productTypeTopLevelList: Ember.computed('topLevelProducts.[]', function () {
      var topLevel = (this.get('topLevelProducts') || []).map(function (product) {
        return product.get('externalReference');
      });
      return topLevel;
    }),

    levelHierarchyParam: Ember.computed.readOnly('view.dashboardViewParameters.LEVEL_HIERARCHY'),
    levelHierarchyParamSuffix: Ember.computed('levelHierarchyParam', function () {
      return (this.levelHierarchyParam || '').replace('LEVEL_', '');
    }),

    isUdaLevel: Ember.computed.equal('levelHierarchyParam', 'LEVEL_UDA'),

    isNAForTabAndData: Ember.computed('isUdaLevel', 'tab', function () {
      return this.isUdaLevel && this.tab === 'ORGANIZATION';
    }),

    organizationCardNoData: Ember.computed('isUdaLevel', function () {
      return this.isUdaLevel ? this.i18n.t('yield-manager.analyze.cards.card_not_applicable', { level: this.get('levelHierarchyParamSuffix') }) : null;
    }),

    _allProducts: Ember.computed('topLevelProducts.[]', function () {
      var getParents = function getParents(list) {
        var fullParentList = [];

        list.forEach(function (product) {
          fullParentList.addObject(product);
          if (product.get('parent')) {
            fullParentList.addObjects(getParents([product.get('parent')]));
          }
        });

        return fullParentList;
      };

      var flattenProducts = function flattenProducts(list) {
        var fullProductList = [];

        list.forEach(function (product) {
          fullProductList.addObject(product);
          fullProductList.addObjects(flattenProducts(product.get('children') || []));
        });

        return fullProductList;
      };

      return flattenProducts(getParents(this.get('topLevelProducts')));
    }),

    loading: Ember.computed('chargeDaysLoading', 'ratePlanLoading', 'channelLoading', 'brandLoading', 'productTypeLoading', 'opportunitiesLoading', function () {
      return this.get('chargeDaysLoading') || this.get('ratePlanLoading') || this.get('channelLoading') || this.get('brandLoading') || this.get('subchannelLoading') || this.get('productTypeLoading') || this.get('opportunitiesLoading');
    }),

    loadingObserver: Ember.on('init', Ember.observer('loading', function () {
      this.set('parentController.childLoading', this.get('loading'));
    })),

    allowDrillIn: Ember.computed('mode', 'tab', function () {
      return this.get('tab') === 'ORGANIZATION';
    }),

    customerLevels: Ember.computed('parentController.customerLevels.length', function () {
      var customerLevels = this.get('parentController.customerLevels');
      // map just the codes to an array
      if (customerLevels) {
        return customerLevels.map(function (level) {
          return level.get('codeValue');
        });
      }
      return [];
    }),

    selectedEndDateParam: Ember.computed.alias('view.dashboardViewParameters.END_DATE'),

    asOfEndDate: Ember.computed('selectedEndDateParam', function () {
      // set the asOfDateBase to be the start of day today in the property TZ
      var tz = this.tz.get('customer');
      var today = _moment.default.tz(tz).startOf('day');
      var asOfEndDate = today.clone().subtract(1, 'day');
      var currentMonth = _moment.default.tz(tz).startOf('month');
      var selectedEndDate = _moment.default.tz(this.get('selectedEndDateParam'), tz);

      // If selected date is prior to current month, grab the selected month, and add 1 day
      // so that the subtraction below gives you the end of the selected month
      if (selectedEndDate.isBefore(currentMonth)) {
        asOfEndDate = selectedEndDate.clone().endOf('month');
      }

      return asOfEndDate;
    }),

    currentLevel: Ember.computed('view.dashboardViewParameters.LEVEL_HIERARCHY', 'parentController.requeryAnalytics', function () {
      var levelHierarchyParam = this.get('view.dashboardViewParameters.LEVEL_HIERARCHY');
      var levelHierarchy = Ember.isEmpty(levelHierarchyParam) ? null : levelHierarchyParam.toString();
      return levelHierarchy ? levelHierarchy.replace('LEVEL_', '') : null;
    }),

    levelParam: Ember.computed.alias('view.dashboardViewParameters.LEVEL'),

    opportunityLevel: Ember.computed('currentLevel', function () {
      var currentLevel = this.get('currentLevel');
      return currentLevel ? currentLevel.toLowerCase() : currentLevel;
    }),

    opportunityHeaderColumnCount: Ember.computed('parentController.{opportunitiesActualLevelColumns.length,opportunitiesActualFilterColumns.length}', function () {
      // Determine the column span for the top line of the opportunities table
      var fixedColumns = 9; // These will always be shown
      return fixedColumns + (this.get('parentController.opportunitiesActualLevelColumns.length') || 0) + (this.get('parentController.opportunitiesActualFilterColumns.length') || 0);
    }),

    // Show the opportunities tab if the level is not Station
    allowOpportunities: Ember.computed('view', 'parentController.requeryAnalytics', 'customerLevels', function () {

      var view = this.get('view');
      if (view) {
        var currentLevel = view.get('dashboardViewParameters.LEVEL_HIERARCHY');

        // Don't show opportunities for the station level
        return currentLevel !== 'LEVEL_STATION';
      }

      return false;
    }),

    _requestData: function _requestData() {
      if (this.get('view')) {
        // Close all children
        this.set('openedDrawers', []);
        // Requery the data, and set the appropriate value on the view
        this._fetchOrgData('chargeDaysLoading', 'chargeDaysData', 'chargeDaysTableData', 'CUSTOMER');
        this._fetchData('ratePlanLoading', 'ratePlanData', 'ratePlanTableData', 'PRICE_CODE', 'rates', this.i18n.t('yield-manager.analyze.cards.rate_plan'), null, '', 'LENGTH_OF_RENT');

        if (this.get('showSubchannels')) {
          this._fetchData('channelLoading', 'channelData', 'channelTableData', 'CHANNEL', 'channel', this.i18n.t('yield-manager.analyze.cards.channel'), this.get('parentController.yieldManager.channels'), 'channelCode', 'SUBCHANNEL');
          this._fetchData('subchannelLoading', 'subchannelData', 'subchannelTableData', 'SUB_CHANNEL', 'subchannel', this.i18n.t('yield-manager.analyze.cards.subchannel'), this.get('parentController.yieldManager.subchannels'), 'channelCode');
        } else {
          this._fetchData('channelLoading', 'channelData', 'channelTableData', 'CHANNEL', 'channel', this.i18n.t('yield-manager.analyze.cards.channel'), this.get('parentController.yieldManager.channels'), 'channelCode');
        }
        this._fetchData('brandLoading', 'brandData', 'brandTableData', 'BRAND_CODE', 'brand', this.i18n.t('yield-manager.analyze.cards.brand'), this.get('parentController.yieldManager.brands'), 'codeValue');
        this._fetchData('lengthOfRentLoading', 'lengthOfRentData', 'lengthOfRentTableData', 'LENGTH_OF_RENT', 'lor', this.i18n.t('yield-manager.analyze.cards.length_of_rent'), this.get('parentController.yieldManager.lengthOfRent'));
        //this._fetchData('bookSrcCountryLoading', 'bookSrcCountryData', 'bookSrcCountryTableData', 'BOOK_SRC_COUNTRY', 'bookSrcCountry', this.i18n.t('yield-manager.analyze.cards.book_src_country'), this.get('parentController.yieldManager.bookSrcCountry'));
        if (this.get('showProductGroupBreakdown')) {
          this._fetchData('productTypeLoading', 'productTypeData', 'productTypeTableData', 'PRODUCT_CODE', 'types', this.i18n.t('yield-manager.analyze.cards.products'), this.get('_allProducts'), 'externalReference', 'PRODUCT_CODE');
        } else if (this.get('tab') === 'TYPES') {
          this.set('tab', 'ORGANIZATION');
        }
        //this._fetchData('contractAccountLoading', 'contractAccountData', 'contractAccountTableData', 'CONTRACT_ACCOUNT', 'contractAccount', this.i18n.t('yield-manager.analyze.cards.contract_account'), this.get('parentController.yieldManager.contractAccount'));
        //this._fetchData('bookSrcAccountLoading', 'bookSrcAccountData', 'bookSrcAccountTableData', 'BOOK_SRC_ACCOUNT', 'bookSrcAccount', this.i18n.t('yield-manager.analyze.cards.book_src_account'), this.get('parentController.yieldManager.bookSrcAccount'));

        if (this.get('allowOpportunities')) {
          this._fetchOpportunityData('opportunitiesLoading', 'opportunityData');
        } else if (this.get('tab') === 'OPPORTUNITIES') {
          if (this.get('mode') === 'tables') {
            this.set('tab', 'ORGANIZATION');
          } else {
            this.set('tab', null);
          }
        }

        // Initialize the radio button for type of data to display in the chart
        this.set('selectedDataView', this.get('dataViewParameter'));
      }
    },


    viewObserver: Ember.observer('view', 'cardsList', 'parentController.requeryAnalytics', 'allowOpportunities', function () {
      Ember.run.once(this, '_requestData');
    }),

    // Place any logic here for parameters that may change prior to submission
    _getAdjustedView: function _getAdjustedView(view, group) {
      var modifiedView = Ember.Object.create(view ? JSON.parse(JSON.stringify(view.serialize())) : { dashboardViewParameters: {} });

      var destinationStatus = modifiedView.get('dashboardViewParameters.DESTINATION_STATUS');
      if (Ember.isEmpty(destinationStatus)) {
        modifiedView.set('dashboardViewParameters.DESTINATION_STATUS', 'BOOK|INHOUSE|DEPARTED');
      }

      if (group === 'OPPORTUNITY') {
        var grossFlag = modifiedView.get('dashboardViewParameters.TM_VS_GROSS') === 'GROSS';
        modifiedView.set('dashboardViewParameters.DISPLAY', 'OPPORTUNITY');
        //modifiedView.set('dashboardViewParameters.GROUP_BY', 'CHANNEL,SUB_CHANNEL,PRICE_CODE,BRAND_CODE,PRODUCT_CODE');

        // set the orderBy logic
        if (this.opportunitiesFetchKey === 'change_revenue_pct') {
          modifiedView.set('dashboardViewParameters.ORDER_BY', 'PCT_CHANGE_' + (grossFlag ? 'GROSS' : 'BASE'));
        } else if (this.opportunitiesFetchKey === 'change_yield_pct') {
          modifiedView.set('dashboardViewParameters.ORDER_BY', 'PCT_CHANGE_' + (grossFlag ? 'GROSS' : 'BASE') + '_YIELD');
        } else {
          modifiedView.set('dashboardViewParameters.ORDER_BY', 'PCT_CHANGE_USAGE');
        }
      }

      // If one level set (and not a station), then we need to change the view a bit to get all the child levels
      if (group === 'CUSTOMER') {
        var levels = (this.get('levelParam') || '').split('|');
        if (levels.length === 1 && modifiedView.get('dashboardViewParameters.LEVEL_HIERARCHY') !== 'LEVEL_STATION') {
          modifiedView.set('dashboardViewParameters.LEVEL_HIERARCHY', 'LEVEL_CHILDREN');
          modifiedView.set('dashboardViewParameters.LEVEL', this.get('levelParam') || this.get('customer.id'));
        } else {
          // Send LEVEL_EXACT in the LEVEL_HIERARCHY so the backend just returns the level of the ids passed in
          modifiedView.set('dashboardViewParameters.LEVEL_HIERARCHY', 'LEVEL_EXACT');
        }
      }

      // if looking for channel data, and no channels selected, add all the top level channels
      if (group === 'CHANNEL') {
        var channels = modifiedView.get('dashboardViewParameters.CHANNEL');
        var groupNames = this.get('parentController.yieldManager.channels');
        if (Ember.isEmpty(channels) && groupNames && groupNames.length > 0) {
          var channelList = [];
          groupNames.forEach(function (channel) {
            channelList.push(channel.get('channelCode'));
          });

          modifiedView.set('dashboardViewParameters.CHANNEL', channelList.join('|'));
        }
      }

      // if looking for product data, and no products selected, add all the top level products
      if (group === 'PRODUCT_CODE') {
        var products = modifiedView.get('dashboardViewParameters.PRODUCT_CODE');
        var masterProducts = this.get('productTypeTopLevelList');
        if (Ember.isEmpty(products) && masterProducts.length > 0) {
          var productList = [];
          masterProducts.forEach(function (product) {
            productList.push(product);
          });

          modifiedView.set('dashboardViewParameters.PRODUCT_CODE', productList.join('|'));
        }
      }

      modifiedView.set('dashboardViewParameters.AS_OF_END_DATE', this.get('asOfEndDate').format('YYYY-MM-DD'));

      return modifiedView;
    },
    _fetchData: function _fetchData(loadingFlag, dataArray, dataTableArray, group, type, typeName, groupNames, reference, childGroup) {
      var _this = this;

      var tz = this.tz.get('customer');
      var view = this._getAdjustedView(this.get('view'), group);

      this.set(loadingFlag, true);
      this.set(type + 'WeekTracker', null);
      this.set(dataArray, []);
      this.set(dataTableArray, []);

      if (childGroup && this.get(childGroup.toLowerCase() + 'TableData')) {
        this.set(childGroup.toLowerCase() + 'TableData', []);
      }

      var params = {
        aggregationType: this.get('aggregationType'),
        rollupStatus: 'ROLLUP',
        accumulate: true,
        groupBy: group
      };

      var aggregationURL = '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);
      this.set(type + 'Url', aggregationURL);
      var requests = [this.ajax.post(aggregationURL, {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(view, tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;

        // Remap the group names to the displayed values
        if (groupNames) {
          return collections.map(function (ae) {
            if (ae.groupByValue !== 'TOTAL' && !Ember.isEmpty(childGroup)) {
              ae.childGroup = childGroup;
            }

            // Map the codes to the group name
            var group = groupNames.findBy(reference, ae.groupByValue);

            if (group) {
              ae.groupDescription = Ember.get(group, 'shortDescription');
              if (ae.groupBy.indexOf('SUB_CHANNEL') >= 0) {
                ae.parentGroupByValue = Ember.get(group, 'parent.' + reference);
              }
              if (ae.groupBy.indexOf('PRODUCT_CODE') >= 0) {
                // TODO: might need to add more to figure out level to display
                ae.parentGroupByValue = Ember.get(group, 'parent.' + reference);
              }
            }

            return ae;
          });
        } else {
          return collections.map(function (ae) {
            if (ae.groupByValue !== 'TOTAL') {
              ae.childGroup = childGroup;
            }
            return ae;
          });
        }
      })];

      Ember.RSVP.all(requests, 'Requesting data for _fetchData() in analyze/index').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        var results = _this.get(dataArray);
        var tableResults = _this.get(dataTableArray);
        data.forEach(function (record) {
          results.push(record);
          tableResults.push(record);
        });
        _this.notifyPropertyChange(dataArray);
      }).catch(function () {
        _this.send('flash', '<i class="fa fa-warning"></i> ' + _this.i18n.t('yield-manager.analyze.message.fetch_error', { type: typeName }), 'danger');
        _this.set(dataArray, []);
        _this.set(dataTableArray, []);
      }).finally(function () {
        if (_this.get(dataArray + '.length') > 0 && _this.get('dataCurrencyCode') !== _this.get(dataArray + '.firstObject.currencyCode')) {
          _this.set('dataCurrencyCode', _this.get(dataArray + '.firstObject.currencyCode'));
        }
        if (_this.get(loadingFlag) && !(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set(loadingFlag, false);
        }
      });
    },
    _fetchOrgData: function _fetchOrgData(loadingFlag, dataArray, dataTableArray, group) {
      var _this2 = this;

      this.set('organizationWeekTracker', null);
      this.set(dataArray, []);
      this.set(dataTableArray, []);

      // Don't run the query if looking at UDA level
      if (this.isUdaLevel) {
        return;
      }

      var tz = this.tz.get('customer');
      var view = this._getAdjustedView(this.get('view'), group);
      this.set(loadingFlag, true);

      var params = {
        aggregationType: this.get('aggregationType'),
        rollupStatus: 'ROLLUP',
        accumulate: true,
        groupBy: group
      };

      var aggregationURL = '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);
      this.set('organizationUrl', aggregationURL);
      var requests = [this.ajax.post(aggregationURL, {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(view, tz)
      }).then(function (_ref4) {
        var _ref4$collections = _ref4.collections,
            collections = _ref4$collections === undefined ? [] : _ref4$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'Requesting data for _fetchOrgData() in analyze/index').then(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 1),
            _ref6$ = _ref6[0],
            data = _ref6$ === undefined ? [] : _ref6$;

        var results = _this2.get(dataArray);
        var tableResults = _this2.get(dataTableArray);
        data.forEach(function (record) {
          results.push(record);
          tableResults.push(record);
        });
      }).catch(function () {
        _this2.send('flash', '<i class="fa fa-warning"></i> ' + _this2.i18n.t('yield-manager.analyze.message.fetch_level_error', { type: _this2.i18n.t('yield-manager.analyze.cards.charge_days') }), 'danger');
        _this2.set(dataArray, []);
        _this2.set(dataTableArray, []);
      }).finally(function () {
        if (_this2.get(dataArray + '.length') > 0) {
          _this2.set('dataCurrencyCode', _this2.get(dataArray + '.firstObject.currencyCode'));
        }
        if (_this2.get(loadingFlag) && !(_this2.get('isDestroyed') || _this2.get('isDestroying'))) {
          _this2.set(loadingFlag, false);
        }
      });
    },
    _fetchOpportunityData: function _fetchOpportunityData(loadingFlag, dataArray) {
      var _this3 = this;

      var tz = this.tz.get('customer');
      // let levelTree = this.get('levelTree');
      var view = this._getAdjustedView(this.get('view'), 'OPPORTUNITY');

      // const channelList = this.get('parentController.yieldManager.channels') || [];
      // const subChannelList = this.get('parentController.yieldManager.subchannels') || [];
      // const priceList = this.get('parentController.yieldManager.prices') || [];
      // const productList = this.get('parentController.yieldManager.productGroups') || [];
      // const brandList = this.get('parentController.yieldManager.brands') || [];

      this.set(loadingFlag, true);
      this.set(dataArray, []);

      var params = {
        aggregationType: 'OPPORTUNITIES_AGGREGATOR',
        rollupStatus: 'ROLLUP',
        accumulate: true
      };

      var aggregationURL = '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);
      var requests = [this.ajax.post(aggregationURL, {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(view, tz)
      }).then(function (_ref7) {
        var _ref7$collections = _ref7.collections,
            collections = _ref7$collections === undefined ? [] : _ref7$collections;

        return collections.map(function (ae) {
          //const allLabel = this.i18n.t('yield-manager.analyze.opportunities.all');

          // Map the various codes to their names

          var groupBy = [];
          var groupByValue = [];

          // 'CHANNEL,SUB_CHANNEL,PRICE_CODE,BRAND_CODE,PRODUCT_CODE'

          // if (ae.dataValues.channelCode) {
          //   groupBy.push('CHANNEL');
          //   groupByValue.push(ae.dataValues.channelCode);
          //
          //   // Map the codes to the channel name
          //   let channel = channelList.findBy('channelCode', ae.dataValues.channelCode);
          //
          //   if (channel) {
          //     ae.dataValues['CHANNEL'] = channel.get('shortDescription');
          //   } else {
          //     ae.dataValues['CHANNEL'] = ae.dataValues.channelCode;
          //   }
          // } else {
          //   ae.dataValues['CHANNEL'] = allLabel;
          // }
          //
          // if (ae.dataValues.subChannelCode) {
          //   groupBy.push('SUB_CHANNEL');
          //   groupByValue.push(ae.dataValues.subChannelCode);
          //
          //   // Map the codes to the channel name
          //   let channel = subChannelList.findBy('channelCode', ae.dataValues.subChannelCode);
          //
          //   if (channel) {
          //     ae.dataValues['SUB_CHANNEL'] = channel.get('shortDescription');
          //   } else {
          //     ae.dataValues['SUB_CHANNEL'] = ae.dataValues.subChannelCode;
          //   }
          // } else {
          //   ae.dataValues['SUB_CHANNEL'] = allLabel;
          // }
          //
          // if (ae.dataValues.priceCode) {
          //   groupBy.push('PRICE_CODE');
          //   groupByValue.push(ae.dataValues.priceCode);
          //
          //   // Map the codes to the price name
          //   let price = priceList.findBy('externalReference', ae.dataValues.priceCode);
          //   if (price) {
          //     ae.dataValues['PRICE_CODE'] = price.get('shortDescription');
          //   } else {
          //     ae.dataValues['PRICE_CODE'] = ae.dataValues.priceCode;
          //   }
          // } else {
          //   ae.dataValues['PRICE_CODE'] = allLabel;
          // }
          //
          // if (ae.dataValues.brandCode) {
          //   groupBy.push('BRAND_CODE');
          //   groupByValue.push(ae.dataValues.brandCode);
          //
          //   // Map the codes to the brand name
          //   let brand = brandList.findBy('codeValue', ae.dataValues.brandCode);
          //   if (brand) {
          //     ae.dataValues['BRAND_CODE'] = brand.get('shortDescription');
          //   } else {
          //     ae.dataValues['BRAND_CODE'] = ae.dataValues.brandCode;
          //   }
          // } else {
          //   ae.dataValues['BRAND_CODE'] = allLabel;
          // }
          //
          // if (ae.dataValues.productCode) {
          //   groupBy.push('PRODUCT_CODE');
          //   groupByValue.push(ae.dataValues.productCode);
          //
          //   // Map the codes to the product name
          //   let product = productList.findBy('externalReference', ae.dataValues.productCode);
          //   if (product) {
          //     ae.dataValues['PRODUCT_CODE'] = product.get('shortDescription');
          //   } else {
          //     ae.dataValues['PRODUCT_CODE'] = ae.dataValues.productCode;
          //   }
          // } else {
          //   ae.dataValues['PRODUCT_CODE'] = allLabel;
          // }

          if (ae.dataValues['extRef_country']) {
            ae.dataValues['LEVEL_COUNTRY'] = ae.dataValues['extRef_country'];

            groupBy.push('LEVEL_COUNTRY');
            groupByValue.push(ae.dataValues['extRef_country']);
          }

          if (ae.dataValues['extRef_svp']) {
            ae.dataValues['LEVEL_SVP'] = ae.dataValues['extRef_svp'];

            groupBy.push('LEVEL_SVP');
            groupByValue.push(ae.dataValues['extRef_svp']);
          }

          if (ae.dataValues['extRef_svpregion']) {
            ae.dataValues['LEVEL_SVPREGION'] = ae.dataValues['extRef_svpregion'];

            groupBy.push('LEVEL_SVPREGION');
            groupByValue.push(ae.dataValues['extRef_svpregion']);
          }

          if (ae.dataValues['extRef_group']) {
            ae.dataValues['LEVEL_GROUP'] = ae.dataValues['extRef_group'];

            groupBy.push('LEVEL_GROUP');
            groupByValue.push(ae.dataValues['extRef_group']);
          }

          if (ae.dataValues['extRef_area']) {
            ae.dataValues['LEVEL_AREA'] = ae.dataValues['extRef_area'];

            groupBy.push('LEVEL_AREA');
            groupByValue.push(ae.dataValues['extRef_area']);
          }

          if (ae.dataValues['extRef_city']) {
            ae.dataValues['LEVEL_CITY'] = ae.dataValues['extRef_city'];

            groupBy.push('LEVEL_CITY');
            groupByValue.push(ae.dataValues['extRef_city']);
          }

          if (ae.dataValues['extRef_region']) {
            ae.dataValues['LEVEL_REGION'] = ae.dataValues['extRef_region'];

            groupBy.push('LEVEL_REGION');
            groupByValue.push(ae.dataValues['extRef_region']);
          }

          if (ae.dataValues['extRef_subregion']) {
            ae.dataValues['LEVEL_SUBREGION'] = ae.dataValues['extRef_subregion'];

            groupBy.push('LEVEL_SUBREGION');
            groupByValue.push(ae.dataValues['extRef_subregion']);
          }

          if (ae.dataValues.externalReference) {
            // external reference is the station
            ae.dataValues['LEVEL_STATION'] = ae.dataValues.externalReference;

            groupBy.push('LEVEL_STATION');
            groupByValue.push(ae.dataValues.externalReference);
          }

          ae.groupBy = groupBy.join(',');
          ae.groupByValue = groupByValue.join(',');

          return ae;
        }).filter(function (result) {
          return result != null;
        });
      })];

      Ember.RSVP.all(requests, 'Requesting data for _fetchOpportunityData() in analyze/index').then(function (_ref8) {
        var _ref9 = _slicedToArray(_ref8, 1),
            _ref9$ = _ref9[0],
            opportunityData = _ref9$ === undefined ? [] : _ref9$;

        return _this3.set(dataArray, opportunityData);
      }).catch(function () {
        _this3.send('flash', '<i class="fa fa-warning"></i> ' + _this3.i18n.t('yield-manager.analyze.message.fetch_error', { type: _this3.i18n.t('yield-manager.analyze.tabs.opportunities') }), 'danger');
        _this3.set(dataArray, []);
      }).finally(function () {
        if (_this3.get(loadingFlag) && !(_this3.get('isDestroyed') || _this3.get('isDestroying'))) {
          _this3.set(loadingFlag, false);
        }
      });
    },
    _fetchWeeklyData: function _fetchWeeklyData(tableDataArray, type, tab, populateCurrentView, childTable, childTracker, group) {
      var _this4 = this;

      if (this.get(type + 'Url') && !this.isNAForTabAndData) {
        var view = this._getAdjustedView(this.get('view'), group);

        if (populateCurrentView) {
          this.set('chartLoading', true);
          this.set('chartData', null);
          this.set('selectedCategory', null);
          this.set('selectedCategoryDescription', '');
        }

        // Hold onto the tableDataArray for the threads to push to
        var results = this.get(tableDataArray);
        var tz = this.tz.get('customer');
        var requests = [this.ajax.post(this.get(type + 'Url') + '&dateAsOfType=WEEK_0,WEEK_1,WEEK_2,WEEK_3', {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref10) {
          var collections = _ref10.collections;

          if (collections) {
            var records = collections;
            records.forEach(function (record) {
              record['reference'] = record.subContentType;
              record['slice'] = record.subContentType;

              results.push(record);
            });
            _this4._populateTableData(tab, populateCurrentView);
          }
        })];

        // Set status to PENDING
        this.set(type + 'WeekTracker', 'PENDING');

        return Ember.RSVP.all(requests, 'Requesting data for _fetchWeeklyData() in analyze/index').then(function () {
          // Set status to done
          _this4.set(type + 'WeekTracker', 'DONE');
          if (childTracker) {
            _this4.set(childTracker, 'DONE');
          }

          return Ember.RSVP.resolve(function () {
            // Set status to done
            _this4.set(type + 'WeekTracker', 'DONE');
            if (childTracker) {
              _this4.set(childTracker, 'DONE');
            }
          });
        }).catch(function () {
          _this4.send('flash', '<i class="fa fa-warning"></i> ' + _this4.i18n.t('yield-manager.analyze.message.fetch_weekly_error', { type: type }), 'danger');
          _this4.set(type + 'WeekTracker', null);
          if (childTable) {
            _this4.set(childTable.toLowerCase() + 'WeekTracker', null);
          }
          return Ember.RSVP.resolve(false);
        }).finally(function () {
          if (populateCurrentView) {
            _this4.set('chartLoading', false);
            _this4.set('selectedCategory', 'TOTAL');
            _this4.set('selectedCategoryDescription', _this4.i18n.t('yield-manager.analyze.total'));
          }
        });
      }
    },
    _fetchChildData: function _fetchChildData(group, childGroup, groupName) {
      var _this5 = this;

      var tz = this.tz.get('customer');

      var view = this._getAdjustedView(this.get('view'), group);

      // Use this to look up the shortDescriptions, rather than the groupByValue returned
      var groupItems = null;

      // Replace the value for the group with the groupName to filter the values
      var groupParam = view.dashboardViewParameters;
      if (groupParam) {
        if (group === 'PRODUCT_CODE') {
          // For products, need to change the list to be all of the children of the main product
          var product = this.get('_allProducts').findBy('externalReference', groupName);
          var childReferences = product.get('children') ? product.get('children').map(function (child) {
            return child.get('externalReference');
          }) : [];

          Ember.set(groupParam, group, childReferences.join('|'));
        } else {
          Ember.set(groupParam, group, groupName);
        }

        groupItems = this.get('_allProducts');
      }

      var tableArray = [];

      var getName = function getName(options, groupByValue) {
        // Map the codes to the group name
        if (groupItems) {
          var item = groupItems.findBy('externalReference', groupByValue);
          return item ? Ember.get(item, 'shortDescription') : groupByValue;
        } else {
          return groupByValue;
        }
      };

      var params = {
        aggregationType: this.get('aggregationType'),
        rollupStatus: 'ROLLUP',
        accumulate: true,
        groupBy: childGroup
      };

      var mainUrl = '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);

      params.dateAsOfType = 'WEEK_0,WEEK_1,WEEK_2,WEEK_3';

      var weeklyUrl = '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);

      var requests = {
        main: this.ajax.post(mainUrl, {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref11) {
          var _ref11$collections = _ref11.collections,
              collections = _ref11$collections === undefined ? [] : _ref11$collections;

          // Remap the group names to the displayed values
          if (groupName) {
            return collections.map(function (ae) {

              // Split the groupByValue to adjust for consumption as child records
              var groupByValues = ae.groupByValue.split(',');

              if (groupByValues.length > 1) {
                ae.parentGroupByValue = groupByValues[0];
                // Populate the groupDescription field with the child record name
                ae.groupDescription = getName(groupItems, groupByValues[1]);
              } else {
                ae.parentGroupByValue = groupName;
                // Populate the groupDescription field with the child record name
                ae.groupDescription = getName(groupItems, ae.groupByValue);
              }
              return ae;
            });
          } else {
            return collections.map(function (ae) {
              if (ae.groupByValue !== 'TOTAL') {
                ae.childGroup = childGroup;
              }
              // Split the groupByValue to adjust for consumption as child records
              var groupByValues = ae.groupByValue.split(',');

              if (groupByValues.length > 1) {
                ae.parentGroupByValue = groupByValues[0];
                // Populate the groupDescription field with the child record name
                ae.groupDescription = groupByValues[1];
              }

              return ae;
            });
          }
        }),
        weekly: this.ajax.post(weeklyUrl, {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref12) {
          var _ref12$collections = _ref12.collections,
              collections = _ref12$collections === undefined ? [] : _ref12$collections;

          if (collections) {
            collections.forEach(function (record) {
              record['reference'] = record.subContentType;
              record['slice'] = record.subContentType;
              // Split the groupByValue to adjust for consumption as child records
              var groupByValues = record.groupByValue.split(',');

              if (groupByValues.length > 1) {
                record.parentGroupByValue = groupByValues[0];
                // Populate the groupDescription field with the child record name
                record.groupDescription = groupByValues[1];
              } else {
                record.parentGroupByValue = groupName;
                // Hack for wrong level coming back for children
                if (record.groupByValue === 'SSG__CORE') {
                  record.groupByValue = 'SG__CORE';
                }
                record.groupDescription = record.groupByValue;
              }
            });

            return collections;
          }

          return [];
        })
      };

      if (typeof Ember.get(groupParam, group) === 'undefined') {
        return Ember.RSVP.resolve([]);
      } else {
        return Ember.RSVP.hash(requests).then(function (data) {
          tableArray = data.main;
          tableArray = tableArray.concat(data.weekly);
          return tableArray;
        }).catch(function () {
          _this5.send('flash', '<i class="fa fa-warning"></i> ' + _this5.i18n.t('yield-manager.analyze.message.fetch_error', { type: childGroup }), 'danger');
          return tableArray = [];
        });
      }
    },


    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    defaultCurrencyCode: Ember.computed('customer.settings.[]', function () {
      var customerSettings = this.get('customer.settings').findBy('settingCode', 'DEFAULT_CURRENCY');
      if (customerSettings && customerSettings.get('settingValue')) {
        return customerSettings.get('settingValue');
      } else {
        return 'USD';
      }
    }),

    dataCurrencyCode: null,

    currencyCode: Ember.computed('dataCurrencyCode', 'defaultCurrencyCode', function () {
      return this.get('dataCurrencyCode') || this.get('defaultCurrencyCode');
    }),

    revenuePrecision: Ember.computed('customer', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'PCC.YIELD_MANAGEMENT_REVENUE_PRECISION');
      return model && model.get('settingValue') ? model.get('settingValue') : 0;
    }),

    percentFlag: Ember.computed.equal('selectedDataView', 'PERCENT'),

    formatRevenueOptions: Ember.computed('currencyCode', 'revenuePrecision', 'customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: this.get('revenuePrecision'),
        decimal: '.',
        thousand: ',',
        hideSymbol: true,
        currencyCode: this.get('currencyCode')
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatRevenuePercentOptions: Ember.computed('formatRevenueOptions', function () {
      var formatOptions = JSON.parse(JSON.stringify(this.get('formatRevenueOptions')));
      formatOptions.suffix = '%';
      formatOptions.precision = 1;

      return formatOptions;
    }),

    formatYieldOptions: Ember.computed('currencyCode', 'customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: 2,
        decimal: '.',
        thousand: ',',
        hideSymbol: true,
        currencyCode: this.get('currencyCode')
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatYieldPercentOptions: Ember.computed('formatYieldOptions', function () {
      var formatOptions = JSON.parse(JSON.stringify(this.get('formatYieldOptions')));
      formatOptions.suffix = '%';
      formatOptions.precision = 1;

      return formatOptions;
    }),

    isMobile: Ember.computed.bool('deviceType.mobile'),

    isBaseTable: null,

    _setIsBaseTable: function _setIsBaseTable() {
      var tab = this.get('tab');
      if (tab === 'OPPORTUNITIES' && !this.get('allowOpportunities')) {
        tab = 'ORGANIZATION';
        this.set('tab', tab);
      }
      this.set('isBaseTable', tab !== 'OPPORTUNITIES');
    },


    isBaseTableObserver: Ember.observer('tab', 'allowOpportunities', function () {
      Ember.run.once(this, '_setIsBaseTable');
    }),

    formatField: function formatField(format, value, currencyCode, currencyHash) {
      if (Ember.isEmpty(value)) {
        return Ember.String.htmlSafe('&mdash;');
      } else if (format === 'currency') {
        return (0, _formatCurrency.default)(value, currencyCode, { hash: currencyHash });
      } else if (format === 'percent') {
        return (0, _formatNumber.default)(value, 1) + '%';
      } else if (format === 'truncatedNumber') {
        return '' + (0, _formatNumber.default)(value, 2);
      } else if (format === 'number') {
        return '' + (0, _formatNumber.default)(value, '');
      } else {
        return value;
      }
    },
    _formatTableData: function _formatTableData(data, columns, type) {
      var _this6 = this;

      var generateCellClassNames = function generateCellClassNames(cellValue, reference) {
        var classList = [];
        if (cellValue < 0.00) {
          classList.push('negative');
        }

        if (reference === 'WEEK_0') {
          classList.push('value_highlight');
        }

        return classList.toString().replace(',', ' ');
      };

      var rows = {};
      var defaultCurrency = this.get('defaultCurrencyCode');
      if (data && data.length > 0 && Ember.get(data[0], 'currencyCode') !== this.get('dataCurrencyCode')) {
        this.set('dataCurrencyCode', Ember.get(data[0], 'currencyCode'));
      }
      var showActuals = this.get('selectedDataView') === 'ACTUAL';

      // Loop over each row, and format the fields using the format from the column definition
      (data || []).forEach(function (record) {
        var row = {};
        var key = type === 'OPPORTUNITIES' ? record.groupByValue : record.customerID || record.groupByValue;
        if (!rows[key]) {
          rows[key] = { customerID: record.customerID, groupBy: record.groupBy, groupByValue: record.groupByValue, isExpanded: _this6.get('openedDrawers').indexOf(record.groupByValue) >= 0 };
        }
        row = rows[key];

        if (record.childGroup) {
          row['childGroup'] = record.childGroup;
        }

        if (record.parentGroupByValue) {
          row['parentGroupByValue'] = record.parentGroupByValue;
        }

        columns.forEach(function (col) {
          if (!col.reference && 'REQUESTED' === record.reference || col.reference === record.reference) {
            if (col.key === 'group') {
              row[col.key] = {
                value: Ember.isEmpty(record[col.field] && col.alternate) ? record[col.alternate] : record[col.field],
                rowClassNames: ''
              };
              row['category'] = row[col.key].value;
              if (showActuals) {
                row['titleSuffix'] = '(' + _this6.get('currencyCode') + ')';
              }
            } else {
              if (record.dataValues) {
                row[col.key] = {
                  value: col.format ? _this6.formatField(col.format, record.dataValues[col.field] || record[col.field], col.formatHash ? col.formatHash.currencyCode : defaultCurrency, col.formatHash) : record.dataValues[col.field] || record[col.field],
                  classNames: generateCellClassNames(record.dataValues[col.field] || record[col.field], record.reference),
                  sort: parseFloat(record.dataValues[col.field] || record[col.field])
                };
              }
            }
          }
        });
        if (row['group'] && row['group'].value === 'TOTAL') {
          row['rowClassNames'] = 'total-row';
        }
      });

      return Object.keys(rows).map(function (key) {
        return rows[key];
      }).sortBy('group.value');
    },


    tabTitle: Ember.computed('tab', function () {
      var tabTitle = this.i18n.t('yield-manager.analyze.tabs.organization');
      switch (this.get('tab')) {
        case 'RATES':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.rate_plan');
          break;
        case 'CHANNEL':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.channel');
          break;
        case 'SUBCHANNEL':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.subchannel');
          break;
        case 'TYPES':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.product_types');
          break;
        case 'BRAND':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.brand');
          break;
        case 'LOR':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.length_of_rent');
          break;
        case 'BOOK_SRC_COUNTRY':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.book_src_country');
          break;
        case 'CONTRACT_ACCOUNT':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.contract_account');
          break;
        case 'BOOK_SRC_ACCOUNT':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.book_src_account');
          break;
        case 'OPPORTUNITIES':
          tabTitle = this.i18n.t('yield-manager.analyze.tabs.opportunities');
          break;
      }

      return tabTitle;
    }),

    graphedGroupingLabel: Ember.computed(function () {
      return this.i18n.t('yield-manager.analyze.total');
    }),

    baseColumns: function baseColumns(view) {
      var grossFlag = view.get('dashboardViewParameters.TM_VS_GROSS') === 'GROSS';
      var percent = this.get('selectedDataView') === 'PERCENT';

      var columns = [{ key: 'group', field: 'groupDescription', alternate: 'groupByValue' }, { key: 'cy_cd', field: 'usage', format: 'number' }, { key: 'cy_yield', field: grossFlag ? 'grossYield' : 'baseYield', format: 'currency', formatHash: this.get('formatYieldOptions') }, { key: 'cy_revenue', field: grossFlag ? 'gross' : 'base', format: 'currency', formatHash: this.get('formatRevenueOptions') }, { key: 'py_cd', field: 'stlyUsage', format: 'number' }, { key: 'py_yield', field: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions') }, { key: 'py_revenue', field: grossFlag ? 'stlyGross' : 'stlyBase', format: 'currency', formatHash: this.get('formatRevenueOptions') }];

      if (percent) {
        columns.push({ key: 'change_cd', field: 'pctChangeUsage', format: 'percent' });
        columns.push({
          key: 'change_yield',
          field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
          format: 'percent'
        });
        columns.push({ key: 'change_revenue', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent' });
        columns.push({ key: 'cy_cd_week_0', field: 'pctChangeUsage', format: 'percent', reference: 'WEEK_0' });
        columns.push({ key: 'cy_cd_week_1', field: 'pctChangeUsage', format: 'percent', reference: 'WEEK_1' });
        columns.push({ key: 'cy_cd_week_2', field: 'pctChangeUsage', format: 'percent', reference: 'WEEK_2' });
        columns.push({ key: 'cy_cd_week_3', field: 'pctChangeUsage', format: 'percent', reference: 'WEEK_3' });
        columns.push({ key: 'cy_yield_week_0', field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield', format: 'percent', reference: 'WEEK_0' });
        columns.push({ key: 'cy_yield_week_1', field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield', format: 'percent', reference: 'WEEK_1' });
        columns.push({ key: 'cy_yield_week_2', field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield', format: 'percent', reference: 'WEEK_2' });
        columns.push({ key: 'cy_yield_week_3', field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield', format: 'percent', reference: 'WEEK_3' });
        columns.push({ key: 'cy_revenue_week_0', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent', reference: 'WEEK_0' });
        columns.push({ key: 'cy_revenue_week_1', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent', reference: 'WEEK_1' });
        columns.push({ key: 'cy_revenue_week_2', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent', reference: 'WEEK_2' });
        columns.push({ key: 'cy_revenue_week_3', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent', reference: 'WEEK_3' });
      } else {
        columns.push({ key: 'change_cd', field: 'deltaUsage', format: 'number' });
        columns.push({ key: 'change_yield', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions') });
        columns.push({ key: 'change_revenue', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions') });
        columns.push({ key: 'cy_cd_week_0', field: 'deltaUsage', format: 'number', reference: 'WEEK_0' });
        columns.push({ key: 'cy_cd_week_1', field: 'deltaUsage', format: 'number', reference: 'WEEK_1' });
        columns.push({ key: 'cy_cd_week_2', field: 'deltaUsage', format: 'number', reference: 'WEEK_2' });
        columns.push({ key: 'cy_cd_week_3', field: 'deltaUsage', format: 'number', reference: 'WEEK_3' });
        columns.push({ key: 'cy_yield_week_0', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions'), reference: 'WEEK_0' });
        columns.push({ key: 'cy_yield_week_1', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions'), reference: 'WEEK_1' });
        columns.push({ key: 'cy_yield_week_2', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions'), reference: 'WEEK_2' });
        columns.push({ key: 'cy_yield_week_3', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions'), reference: 'WEEK_3' });
        columns.push({ key: 'cy_revenue_week_0', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions'), reference: 'WEEK_0' });
        columns.push({ key: 'cy_revenue_week_1', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions'), reference: 'WEEK_1' });
        columns.push({ key: 'cy_revenue_week_2', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions'), reference: 'WEEK_2' });
        columns.push({ key: 'cy_revenue_week_3', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions'), reference: 'WEEK_3' });
      }
      return columns;
    },
    opportunityColumns: function opportunityColumns(view) {
      var grossFlag = view.get('dashboardViewParameters.TM_VS_GROSS') === 'GROSS';

      return [{ key: 'country', field: 'LEVEL_COUNTRY' }, { key: 'svp', field: 'LEVEL_SVP' }, { key: 'groupLevel', field: 'LEVEL_GROUP' }, { key: 'region', field: 'LEVEL_REGION' }, { key: 'subRegion', field: 'LEVEL_SUBREGION' }, { key: 'city', field: 'LEVEL_CITY' }, { key: 'area', field: 'LEVEL_AREA' },
      //{key: 'station', field: 'groupByValue'},
      { key: 'station', field: 'LEVEL_STATION' },
      // {key: 'channel', field: 'CHANNEL'},
      // {key: 'subChannel', field: 'SUB_CHANNEL'},
      // {key: 'ratePlan', field: 'PRICE_CODE'},
      // {key: 'brand', field: 'BRAND_CODE'},
      // {key: 'productType', field: 'PRODUCT_CODE'},
      { key: 'cy_cd', field: 'usage', format: 'number' }, { key: 'cy_yield', field: grossFlag ? 'grossYield' : 'baseYield', format: 'currency', formatHash: this.get('formatYieldOptions') }, { key: 'cy_revenue', field: grossFlag ? 'cyGross' : 'cyBase', format: 'currency', formatHash: this.get('formatRevenueOptions') }, { key: 'change_cd', field: 'deltaUsage', format: 'number' }, { key: 'change_yield', field: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield', format: 'currency', formatHash: this.get('formatYieldOptions') }, { key: 'change_revenue', field: grossFlag ? 'deltaGross' : 'deltaBase', format: 'currency', formatHash: this.get('formatRevenueOptions') }, { key: 'change_cd_pct', field: 'pctChangeUsage', format: 'percent' }, { key: 'change_yield_pct', field: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield', format: 'percent' }, { key: 'change_revenue_pct', field: grossFlag ? 'pctChangeGross' : 'pctChangeBase', format: 'percent' }];
    },
    _populateTableData: function _populateTableData(table, populateCurrentView) {
      var _this7 = this;

      var tab = table;
      var tableData = [];
      var tableLoader = '';
      var tableDataArray = null;
      var childTableDataArray = null;
      var childTracker = null;
      var columns = null;
      var group = null;
      switch (tab) {

        case 'ORGANIZATION':
          tableLoader = 'chargeDaysLoading';
          tableDataArray = 'chargeDaysTableData';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.organization'));
          columns = this.baseColumns(this.get('view'));
          group = 'CUSTOMER';
          break;
        case 'RATES':
          tableLoader = 'ratePlanLoading';
          tableDataArray = 'ratePlanTableData';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.rate_plan'));
          columns = this.baseColumns(this.get('view'));
          group = 'PRICE_CODE';
          break;
        case 'CHANNEL':
          tableLoader = 'channelLoading';
          tableDataArray = 'channelTableData';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.channel'));
          columns = this.baseColumns(this.get('view'));
          group = 'CHANNEL';
          break;
        case 'SUBCHANNEL':
          tableLoader = 'subchannelLoading';
          tableDataArray = 'subchannelTableData';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.subchannel'));
          columns = this.baseColumns(this.get('view'));
          group = 'SUB_CHANNEL';
          break;
        case 'BRAND':
          tableLoader = 'brandLoading';
          tableDataArray = 'brandTableData';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.brand'));
          columns = this.baseColumns(this.get('view'));
          group = 'BRAND_CODE';
          break;
        case 'TYPES':
          tableLoader = 'productTypeLoading';
          tableDataArray = 'productTypeTableData';
          // TODO: Uncomment when child car types working
          // childTableDataArray = 'productSubtypeTableData';
          // childTracker = 'productSubtypeWeekTracker';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.product_types'));
          columns = this.baseColumns(this.get('view'));
          group = 'PRODUCT_CODE';
          break;
        case 'LOR':
          tableLoader = 'lengthOfRentLoading';
          tableDataArray = 'lengthOfRentTableData';
          //childTableDataArray = 'productSubtypeTableData';
          //childTracker = 'productSubtypeWeekTracker';
          this.set('leadColumnTitle', this.i18n.t('yield-manager.analyze.tabs.length_of_rent'));
          columns = this.baseColumns(this.get('view'));
          group = 'LENGTH_OF_RENT';
          break;
        case 'productSubType':
          tableLoader = 'productSubTypeLoading';
          tableDataArray = 'productSubTypeTableData';
          childTracker = 'productSubtypeWeekTracker';
          columns = this.baseColumns(this.get('view'));
          break;
        case 'OPPORTUNITIES':
          tableLoader = 'opportunitiesLoading';
          tableDataArray = 'opportunityData';
          columns = this.opportunityColumns(this.get('view'));
          break;
      }
      tableData = this._formatTableData(this.get(tableDataArray), columns, tab);

      // Kick off the weekly queries if in table mode
      if (this.get('mode') === 'tables') {
        var weeklyDataQueue = this.get(tab.toLowerCase() + 'WeekTracker');
        if (!weeklyDataQueue && tab !== 'OPPORTUNITIES') {
          this._fetchWeeklyData(tableDataArray, tab.toLowerCase(), tab, populateCurrentView, childTableDataArray, childTracker, group);
        } else if (tab === 'OPPORTUNITIES') {
          tableData = tableData.rejectBy('station.value', 'TOTAL');
        }
      }
      if (populateCurrentView) {
        this.set('currentDataArray', tableDataArray);
        this.set('currentTableLoading', this.get(tableLoader));
        if (tableData) {
          this.set('currentTableData', tableData.rejectBy('group.value', 'TOTAL'));
          this.set('currentTotalRow', tableData.findBy('group.value', 'TOTAL'));
          if (this.get(tab.toLowerCase() + 'WeekTracker')) {
            this.notifyPropertyChange('selectedCategory');
          }

          // Loop though open drawers and populate them
          this.get('openedDrawers').forEach(function (groupByValue) {
            var record = tableData.findBy('groupByValue', groupByValue);
            if (record) {
              _this7.send('selectChildren', record);
            }
          });
        } else {
          this.set('currentTableData', tableData);
          this.set('currentTotalRow', null);
        }
      }
    },
    _populateCurrentTableData: function _populateCurrentTableData() {
      var tab = this.get('tab');
      if (!tab) {
        return;
      }
      this._populateTableData(tab, true);
    },


    currentTableDataObserver: Ember.observer('tab', 'mode', 'chargeDaysLoading', 'ratePlanLoading', 'channelLoading', 'subchannelLoading', 'productTypeLoading', 'opportunitiesLoading', 'selectedDataView', 'parentController.opportunitiesActualLevelColumns.[]', function () {
      Ember.run.once(this, '_populateCurrentTableData');
    }),

    _tabObserver: Ember.on('init', Ember.observer('tab', 'mode', function () {
      if (this.get('mode') === 'tables' && this.get('tab') === 'OPPORTUNITIES') {
        this.get('parentController').set('isOpportunities', true);
      } else {
        this.set('selectedCategory', 'TOTAL');
        this.set('selectedCategoryDescription', this.i18n.t('yield-manager.analyze.total'));
        this.get('parentController').set('isOpportunities', false);
      }

      if (this.get('mode') === 'tables' && this.get('tab') === 'ORGANIZATION') {
        this.get('parentController').set('allowDownload', false);
      } else {
        this.get('parentController').set('allowDownload', false);
      }

      this.set('openedDrawers', []);
      this.set('baseSort', ['cy_revenue.sort:desc']);
      this.set('opportunitiesSort', ['change_revenue_pct.sort']);
      this.set('opportunitiesFetchKey', 'change_revenue_pct');
    })),

    _downloadObserver: Ember.observer('parentController.triggerDownload', function () {
      this.send('exportCsv', this.get('tab'));
    }),

    /***
     * Chart section
     */

    chartStartDate: null,

    _selectedCategoryObserver: Ember.observer('selectedCategory', function () {
      var selectedCategory = this.get('selectedCategory');
      if (selectedCategory) {
        var chartData = selectedCategory === 'TOTAL' ? this.get('currentTotalRow') : this.get('currentTableData').findBy('groupByValue', selectedCategory);
        if (chartData) {
          this.set('selectedCategoryDescription', chartData.category);
        }
        this.set('chartData', chartData);
      }
    }),

    dataFields: Ember.computed('view.dashboardViewParameters.[]', 'selectedDataView', 'showDays', 'showDaysCompare', 'showYield', 'showYieldCompare', 'showRevenue', 'showRevenueCompare', 'tab', function () {
      var grossFlag = this.get('view.dashboardViewParameters.TM_VS_GROSS') === 'GROSS';
      var percentFlag = this.get('percentFlag');

      var dataFields = [];
      if (this.get('selectedDataView') === 'ACTUAL') {
        if (this.get('showDays') || this.get('showDaysCompare')) {
          if (this.get('showDays')) {
            dataFields.push({
              key: 'usage',
              category: 'count',
              label: this.i18n.t('yield-manager.charge_days'),
              format: 'number',
              formatHash: { precision: 0 },
              lineClasses: 'days',
              chartType: 'line',
              bulletType: 'round',
              valueClassname: 'usageClasses'
            });
          }

          if (this.get('showDaysCompare')) {
            dataFields.push({
              key: 'stlyUsage',
              category: 'count',
              label: 'PY ' + this.i18n.t('yield-manager.charge_days'),
              format: 'number',
              formatHash: { precision: 0 },
              lineClasses: 'days-compare',
              chartType: 'line',
              bulletType: 'round',
              valueClassname: 'stlyUsageClasses'
            });
          }
        }

        if (this.get('showYield') || this.get('showYieldCompare')) {
          if (this.get('showYield')) {
            dataFields.push({
              key: grossFlag ? 'grossYield' : 'baseYield',
              category: 'yield',
              label: this.i18n.t('yield-manager.yield'),
              format: 'currency',
              formatHash: this.get('formatYieldOptions'),
              lineClasses: 'yield',
              chartType: 'line',
              dashLength: 3,
              bulletType: 'triangleUp',
              valueClassname: 'yieldClasses'
            });
          }

          if (this.get('showYieldCompare')) {
            dataFields.push({
              key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
              category: 'yield',
              label: 'PY ' + this.i18n.t('yield-manager.yield'),
              format: 'currency',
              formatHash: this.get('formatYieldOptions'),
              lineClasses: 'yield-compare',
              chartType: 'line',
              dashLength: 3,
              bulletType: 'triangleUp',
              valueClassname: 'stlyYieldClasses'
            });
          }
        }

        if (this.get('showRevenue') || this.get('showRevenueCompare')) {
          if (this.get('showRevenue')) {
            dataFields.push({
              key: grossFlag ? 'gross' : 'base',
              category: 'revenue',
              label: this.i18n.t('yield-manager.revenue'),
              format: 'currency',
              formatHash: this.get('formatRevenueOptions'),
              lineClasses: 'revenue',
              chartType: 'line',
              bulletType: 'square',
              valueClassname: 'revenueClasses'
            });
          }

          if (this.get('showRevenueCompare')) {
            dataFields.push({
              key: grossFlag ? 'stlyGross' : 'stlyBase',
              category: 'revenue',
              label: 'PY ' + this.i18n.t('yield-manager.revenue'),
              format: 'currency',
              formatHash: this.get('formatRevenueOptions'),
              lineClasses: 'revenue-compare',
              chartType: 'line',
              bulletType: 'square',
              valueClassname: 'stlyRevenueClasses'
            });
          }
        }
      } else {
        if (this.get('showDays')) {
          dataFields.push({
            key: 'cy_cd',
            category: 'count',
            label: this.i18n.t('yield-manager.charge_days'),
            format: percentFlag ? 'percent' : 'number',
            formatHash: percentFlag ? { suffix: '%', precision: 1 } : { precision: 0 },
            lineClasses: 'days',
            chartType: 'line',
            bulletType: 'round',
            valueClassname: 'usageClasses'
          });
        }

        if (this.get('showYield')) {
          dataFields.push({
            key: 'cy_yield',
            category: 'yield',
            label: this.i18n.t('yield-manager.yield'),
            format: percentFlag ? 'percent' : 'currency',
            formatHash: this.get('formatYield' + (percentFlag ? 'Percent' : '') + 'Options'),
            lineClasses: 'yield',
            chartType: 'line',
            dashLength: 3,
            bulletType: 'triangleUp',
            valueClassname: 'yieldClasses'
          });
        }

        if (this.get('showRevenue')) {
          dataFields.push({
            key: 'cy_revenue',
            category: 'revenue',
            label: this.i18n.t('yield-manager.revenue'),
            format: percentFlag ? 'percent' : 'currency',
            formatHash: this.get('formatRevenue' + (percentFlag ? 'Percent' : '') + 'Options'),
            lineClasses: 'revenue',
            chartType: 'line',
            bulletType: 'square',
            valueClassname: 'revenueClasses'
          });
        }
      }

      return dataFields;
    }),

    graphBubbleOrder: Ember.computed('view.dashboardViewParameters.[]', 'selectedDataView', 'showDays', 'showDaysCompare', 'showYield', 'showYieldCompare', 'showRevenue', 'showRevenueCompare', 'tab', function () {
      var grossFlag = this.get('view.dashboardViewParameters.TM_VS_GROSS') === 'GROSS';

      var bubbleOrder = [];
      if (this.get('selectedDataView') === 'ACTUAL') {
        if (this.get('showDays')) {
          bubbleOrder.push({ key: 'usage' });
        }

        if (this.get('showDaysCompare')) {
          bubbleOrder.push({ key: 'stlyUsage' });
        }

        if (this.get('showYield')) {
          bubbleOrder.push({ key: grossFlag ? 'grossYield' : 'baseYield' });
        }

        if (this.get('showYieldCompare')) {
          bubbleOrder.push({ key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield' });
        }

        if (this.get('showRevenue')) {
          bubbleOrder.push({ key: grossFlag ? 'gross' : 'base' });
        }

        if (this.get('showRevenueCompare')) {
          bubbleOrder.push({ key: grossFlag ? 'stlyGross' : 'stlyBase' });
        }
      } else {
        if (this.get('showDays')) {
          bubbleOrder.push({ key: 'cy_cd' });
        }

        if (this.get('showYield')) {
          bubbleOrder.push({ key: 'cy_yield' });
        }

        if (this.get('showRevenue')) {
          bubbleOrder.push({ key: 'cy_revenue' });
        }
      }

      return bubbleOrder;
    }),

    chartDataProvider: Ember.computed('chartData', function () {
      var results = [];
      var currencyCode = this.get('dataCurrencyCode');

      if (this.get('selectedDataView') === 'ACTUAL') {
        var mapped = (this.get(this.get('currentDataArray')) || []).filterBy('groupByValue', this.get('selectedCategory'));

        var assignWeekValues = function assignWeekValues(record, weekValues) {
          return function (dataValueKey) {
            record[dataValueKey] = weekValues.dataValues[dataValueKey];
          };
        };

        for (var i = 3; i >= 0; i--) {
          var record = { slice: this.i18n.t('yield-manager.analyze.base.header.week' + i), currencyCode: currencyCode };
          var weekValues = mapped.findBy('slice', 'WEEK_' + i);
          if (weekValues) {
            Object.keys(weekValues.dataValues).forEach(assignWeekValues(record, weekValues));
            results.push(record);
          }
        }
      } else {
        var _mapped = this.get('chartData');
        if (!_mapped) {
          return null;
        }

        var rowKeyWeekMatch = function rowKeyWeekMatch(i) {
          return function (rowKey) {
            return rowKey.endsWith('week_' + i);
          };
        };

        var assignRecordValue = function assignRecordValue(record, mapped, i) {
          return function (key) {
            record[key.replace('_week_' + i, '')] = mapped[key].sort;
          };
        };

        for (var _i = 3; _i >= 0; _i--) {
          var _record = { slice: this.i18n.t('yield-manager.analyze.base.header.week' + _i), currencyCode: currencyCode };
          var keys = Object.keys(_mapped).filter(rowKeyWeekMatch(_i));

          keys.forEach(assignRecordValue(_record, _mapped, _i));

          results.push(_record);
        }
      }
      return results;
    }),

    actions: {
      syncPanels: function syncPanels(panel) {
        this.set('syncedPanel', panel);
      },
      toggleSync: function toggleSync(panel) {
        this.toggleProperty('synchronizePanels');
        if (this.get('synchronizePanels')) {
          this.send('syncPanels', panel);
        }
      },
      updateFilter: function updateFilter(data) {
        if (this.get('mode') === 'tables') {
          return;
        }
        this.get('parentController').send('updateFilter', data);
      },
      updateCategory: function updateCategory(data) {
        this.set('selectedCategory', data.groupByValue);
      },
      hideChildren: function hideChildren(groupByValue) {
        this.set('openedDrawers', this.get('openedDrawers').filter(function (value) {
          return value !== groupByValue;
        }));
      },
      changeMode: function changeMode(mode, tab) {
        this.get('parentController').send('changeMode', mode);
        this.set('tab', tab);
      },
      changeTab: function changeTab(tab) {
        if (this.get('chartLoading') || this.get('currentTableLoading')) {
          return;
        }
        this.set('tab', tab);
      },
      selectChildren: function selectChildren(record) {
        var _this8 = this;

        var childGroup = record.childGroup;
        var currentTableData = this.get('currentTableData');
        this.get('openedDrawers').push(record.groupByValue);
        // Grab the corresponding table data for the child group, and set it as the child of the record
        var row = currentTableData.findBy('category', record.category);
        // If there are no children, and the row is not currently loading, look them up
        if (!row['children'] && !Ember.get(row, 'childrenPending')) {
          // For SUBCHANNELS, use the data from the existing table
          if (childGroup === 'SUBCHANNEL') {
            var weeklyDataQueue = this.get(childGroup.toLowerCase() + 'WeekTracker');
            var populateChildren = weeklyDataQueue ? Ember.RSVP.resolve() : this._fetchWeeklyData(childGroup.toLowerCase() + 'TableData', childGroup.toLowerCase(), childGroup, false, null, null, 'SUB_CHANNEL');
            Ember.set(row, 'childrenPending', true);
            populateChildren.then(function () {
              _this8._populateTableData(record.childGroup);
              var childData = _this8._formatTableData(_this8.get(childGroup.toLowerCase() + 'TableData'), _this8.baseColumns(_this8.get('view')), record.childGroup);
              row['children'] = childData.rejectBy('group.value', 'TOTAL').filterBy('parentGroupByValue', record.groupByValue);
            }).finally(function () {
              Ember.set(row, 'childrenPending', false);
            });
          } else {
            Ember.set(row, 'childrenPending', true);
            // Retrieve the standard and weekly data for the drawer records
            this._fetchChildData(record.groupBy, record.childGroup, record.groupByValue).then(function (childTableData) {
              // Format child data into tableData format
              var childData = (_this8._formatTableData(childTableData, _this8.baseColumns(_this8.get('view')), '') || []).rejectBy('group.value', 'TOTAL');

              // Populate the child rows for each of the current table
              currentTableData.forEach(function (row) {
                var children = childData.filterBy('parentGroupByValue', row.groupByValue);
                if (children.length > 0 || row.groupByValue === record.groupByValue) {
                  row['children'] = children;
                }
              });
            }).finally(function () {
              Ember.set(row, 'childrenPending', false);
            });
          }
        }
      },
      opportunitiesSort: function opportunitiesSort(key, ascending) {
        // if the sort has changed, and it is one of the requery keys, re-query the data
        var fetchKey = key.replace('.sort', '');
        if (['change_revenue_pct', 'change_yield_pct', 'change_cd_pct'].indexOf(fetchKey) > -1 && fetchKey !== this.opportunitiesFetchKey) {
          this.set('opportunitiesFetchKey', fetchKey);
          this._fetchOpportunityData('opportunitiesLoading', 'opportunityData');
        }
        if (ascending) {
          this.set('opportunitiesSort', [key]);
        } else {
          this.set('opportunitiesSort', [key + ':desc']);
        }
      },
      showDateModal: function showDateModal(displayDiv) {
        var _this9 = this;

        this.send('showModal', 'yield-manager/-adjust-filter-dates-modal', { displayOutlet: displayDiv, dashboardViewParameters: this.get('view.dashboardViewParameters'), onSave: function onSave() {
            _this9.get('parentController').send('saveParamUpdates', true);
          } });
      },
      navigateToLevel: function navigateToLevel(level, value) {
        var mode = this.get('mode');
        if (this.get('tab') === 'OPPORTUNITIES' && !this.get('tableModeOnly')) {
          mode = 'stacked';
        }
        this.send('changeMode', mode, 'ORGANIZATION');
        this.get('parentController').send('updateFilter', {
          filterCategory: 'LEVEL',
          categoryValue: value
        });
      },
      exportCsv: function exportCsv(type) {
        var _this10 = this;

        var customerID = this.get('customer.id');
        var tz = this.tz.get('customer');

        var modified = false;
        var view = this.get('view');

        var levels = (this.get('levelParam') || '').split('|');

        // If one level set, then we need to change the view a bit to get all the child levels
        if (levels.length === 1) {
          var modifiedView = Ember.Object.create(JSON.parse(JSON.stringify(view.serialize())));

          modifiedView.set('dashboardViewParameters.LEVEL_HIERARCHY', 'LEVEL_CHILDREN');
          modifiedView.set('dashboardViewParameters.LEVEL', this.get('levelParam') || customerID);
          view = modifiedView;
          modified = true;
        }

        // let level = view.get('dashboardViewParameters.LEVEL');
        // let currentLevel = this.get('parentController.selectedLevels').objectAt((this.get('parentController.selectedLevels.length')-1));
        //
        // // If no levels set, then we need to change the view a bit to get all the child levels
        // if (isEmpty(level) || (currentLevel && currentLevel.value === level)) {
        //   let modifiedView = EmberObject.create(JSON.parse(JSON.stringify(view.serialize())));
        //
        //   modifiedView.get('dashboardViewParameters.LEVEL_HIERARCHY') = 'LEVEL_CHILDREN';
        //   modifiedView.get('dashboardViewParameters.LEVEL') = currentLevel.value || customerID;
        //   view = modifiedView;
        //   modified = true;
        // }

        var url = void 0;
        switch (type) {
          case 'ORGANIZATION':
            url = this.get('organizationUrl');
            break;
          case 'RATES':
          case 'CHANNEL':
          case 'TYPES':
          case 'LOR':
            url = this.get(type.toLowerCase() + 'Url');
            break;

          // TODO: implement the other downloads
        }

        var filename = this.i18n.t('yield-manager.analyze.file.prefix') + '_' + this.i18n.t('yield-manager.analyze.file.' + type.toLowerCase()) + '_' + _moment.default.tz(this.get('beginDate'), tz).format('YYYYMMDD') + '_' + _moment.default.tz(this.get('endDate'), tz).format('YYYYMMDD') + '_' + _moment.default.tz(this.get('stlyBeginDate'), tz).format('YYYYMMDD') + '_' + _moment.default.tz(this.get('stlyEndnDate'), tz).format('YYYYMMDD') + '.csv';
        this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + this.i18n.t('yield-manager.message.download_requested'), 'success');

        this.ajax.post(url, {
          headers: { accept: 'text/csv' },
          contentType: 'application/json',
          dataType: 'blob',
          data: (0, _serializeDashboardView.default)(modified ? view : view, tz)
        }).then(function (data) {
          return _this10.saveFileAs(filename, data, 'text/csv');
        }, function () {
          return _this10.send('flash', '<i class="fa fa-warning"></i> ' + _this10.i18n.t('yield-manager.message.download_error'), 'danger');
        });
      }
    }
  });
});