define('ux-pcc/controllers/customer/performance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    isProperty: Ember.computed.equal('customer.customerType', 'CUSTOMER'),
    isPortfolio: Ember.computed.equal('customer.customerType', 'CUSTOMERS')
  });
});