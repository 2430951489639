define('ux-pcc/controllers/yield-manager/dashboard/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    parentController: Ember.inject.controller('yield-manager.dashboard'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    dateRange: Ember.computed.alias('parentController.dateRange'),
    selectedDate: Ember.computed.alias('parentController.selectedDate'),
    view: Ember.computed.alias('model'),
    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    _weekOverWeekObserver: Ember.observer('model.weekOverWeek', function () {
      var parentController = this.get('parentController');
      parentController.set('weekOverWeek', this.get('model.weekOverWeek'));
    }),
    actions: {
      editView: function editView(view) {
        this.send('showModal', 'dashboard-views', { views: this.get('parentController.model'), selectedView: view });
      },
      viewInfo: function viewInfo(view) {
        this.send('showModal', 'dashboard-views', { views: this.get('parentController.model'), selectedView: view, viewOnly: true });
      },
      flashMessage: function flashMessage(message, type) {
        this.send('flash', message, type);
      }
    }
  });
});