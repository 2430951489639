define('ux-pcc/validations/user', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    emailAddress: [(0, _validators.validatePresence)({ presence: true }), (0, _validators.validateFormat)({ type: 'email' })],
    firstName: (0, _validators.validatePresence)({ presence: true }),
    lastName: (0, _validators.validatePresence)({ presence: true }),
    contactMobile: (0, _validators.validateFormat)({ allowBlank: true, type: 'phone' }),
    userName: (0, _validators.validateFormat)({ allowBlank: true, regex: /^\S*$/ })
  };
});