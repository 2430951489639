define('ux-pcc/components/dashboard-widget/build-days', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['build-days'],

    data: null,

    addPY: true,
    addCY: true,
    cumPY: true,
    cumCY: true,

    showDaysVarActuals: false,
    showLwRoaActuals: false,
    showTwRoaActuals: false,
    showYstRoaActuals: false,
    showPercentBookedActuals: false,
    showComments: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
    },


    chartData: Ember.computed('data', function () {
      return (this.get('data') || []).rejectBy('subContentType', 'FULL_SPAN');
    }),

    fullSpan: Ember.computed('data', function () {
      return (this.get('data') || []).findBy('subContentType', 'FULL_SPAN');
    }),

    thisWeek: Ember.computed('chartData', function () {
      var today = _moment.default.tz(this.get('tz.customer'));
      return this.get('chartData').find(function (ae) {
        return today.isSame(ae.entryDate, 'week');
      });
    }),

    days_var: Ember.computed.readOnly('fullSpan.dataValues.pctChangeUsageMinusNoShows'),
    cy_days: Ember.computed.readOnly('fullSpan.dataValues.usageMinusNoShows'),
    tw_roa: Ember.computed.readOnly('fullSpan.dataValues.twRoaUsage'),
    lw_roa: Ember.computed.readOnly('fullSpan.dataValues.lwRoaUsage'),
    yst_roa: Ember.computed.readOnly('fullSpan.dataValues.ystUsageMinusNoShowsRoa'),
    pct_booked: Ember.computed.readOnly('fullSpan.dataValues.pctBookedUsage'),

    days_actual_cy: Ember.computed.readOnly('fullSpan.dataValues.usageMinusNoShows'),
    days_actual_py: Ember.computed.readOnly('fullSpan.dataValues.stlyUsageMinusNoShows'),
    days_actual_tw_cy: Ember.computed.readOnly('fullSpan.dataValues.twIncreaseUsageMinusNoShows'),
    days_actual_tw_py: Ember.computed.readOnly('fullSpan.dataValues.twStlyIncreaseUsageMinusNoShows'),
    days_actual_lw_cy: Ember.computed.readOnly('fullSpan.dataValues.lwIncreaseUsageMinusNoShows'),
    days_actual_lw_py: Ember.computed.readOnly('fullSpan.dataValues.lwStlyIncreaseUsageMinusNoShows'),
    days_actual_yst_cy: Ember.computed.readOnly('fullSpan.dataValues.ystUsageMinusNoShowsAdded'),
    days_actual_yst_py: Ember.computed.readOnly('fullSpan.dataValues.ystStlyUsageMinusNoShowsAdded'),
    days_actual_booked_pytd: Ember.computed.readOnly('fullSpan.dataValues.eodYesterdayStlyUsageMinusNoShows'),
    days_actual_booked_py: Ember.computed.readOnly('fullSpan.dataValues.stlyBookedTotalUsageMinusNoShows'),

    mappedData: Ember.computed('chartData', function () {
      var _this = this;

      var thisWeek = this.get('thisWeek');
      return this.get('chartData').map(function (ae) {
        var record = {
          slice: _moment.default.tz(ae.dataValues['endDate'], _this.get('tz.customer')),
          addedPriorYear: Number(ae.dataValues['stlyIncreaseUsageMinusNoShows']),
          addedCurrentYear: Number(ae.dataValues['increaseUsageMinusNoShows']),
          cumPriorYear: Number(ae.dataValues['stlyUsageMinusNoShows']),
          cumCurrentYear: Number(ae.dataValues['usageMinusNoShows']),
          cumPctVariance: Number(ae.dataValues['pctChangeUsageMinusNoShows']),
          addPctVariance: Number(ae.dataValues['twRoaUsage'])
        };

        if (thisWeek && thisWeek.entryDate && ae.entryDate && ae.entryDate === thisWeek.entryDate) {
          record['cumPriorYear'] = Number(_this.get('days_actual_py'));
          record['cumPriorYearToDate'] = Number(ae.dataValues['stlyUsageMinusNoShows']);
          record['addPriorYearToDate'] = Number(ae.dataValues['eodYesterdayStlyIncreaseUsage']);
          record['cumPctVariance'] = Number(_this.get('days_var'));
          record['addPctVariance'] = Number(_this.get('tw_roa'));
        }

        return record;
      });
    }),

    toggleActuals: function toggleActuals(value) {
      this.toggleProperty('show' + value + 'Actuals');
    }
  });
});