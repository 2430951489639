define('ux-pcc/adapters/payment', ['exports', 'ux-data/adapters/payment'], function (exports, _payment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _payment.default;
    }
  });
});