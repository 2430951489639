define('ux-pcc/routes/competitive-sets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    store: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');

      return this.store.query('customer', {
        folderid: customer.get('id'),
        customertype: 'COMPETITORS',
        include: 'xref'
      }).then(function (data) {
        return data.toArray();
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});