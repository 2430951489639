define('ux-pcc/components/json-viewer/string-node', ['exports', 'ux-components/components/json-viewer/string-node'], function (exports, _stringNode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stringNode.default;
    }
  });
});