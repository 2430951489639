define('ux-pcc/helpers/field-parser', ['exports', 'ux-components/helpers/field-parser'], function (exports, _fieldParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fieldParser.default;
    }
  });
  Object.defineProperty(exports, 'fieldParser', {
    enumerable: true,
    get: function () {
      return _fieldParser.fieldParser;
    }
  });
});