define('ux-pcc/controllers/channel-restatus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    channels: Ember.computed.alias('model.channels'),

    channel: null,
    selectedChannelId: null,

    isProperty: Ember.computed.equal('customer.customerType', 'CUSTOMER'),

    propertyCustomer: null,

    _channelIdObserver: Ember.observer('selectedChannelId', 'channels', function () {
      var channels = this.get('channels');
      if (channels && channels.length > 0) {
        this.set('channel', channels.findBy('id', this.get('selectedChannelId')));
      }
    }),

    _init: Ember.on('init', Ember.observer('isProperty', 'customer.customerType', function () {
      if (this.get('isProperty')) {
        this.set('propertyCustomer', this.get('customer'));
      } else {
        this.set('propertyCustomer', null);
      }
    })),

    actions: {
      itemSelected: function itemSelected(item) {
        var _this = this;

        if (item && item.id) {
          this.store.findRecord('customer', item.id).then(function (record) {
            _this.set('propertyCustomer', record);
          });
        } else {
          this.set('propertyCustomer', null);
        }
      }
    }
  });
});