define('ux-pcc/components/dashboard-widget/dow-occupancy-master-contribution', ['exports', 'moment', 'accounting'], function (exports, _moment, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    classNames: ['widget-body', 'no-padding'],
    loading: true,
    rollupStatus: 'DETAIL',

    mobileIndex: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.beginDate).weekday();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchData();
    },


    _fetchDataObserver: Ember.observer('customerID', 'beginDate', 'endDate', function () {
      Ember.run.once(this, '_fetchData');
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.customerID;
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.beginDate, tz).startOf('day');
      var endDate = _moment.default.tz(this.endDate, tz).endOf('day');

      if (endDate.isBefore(selectedDate)) {
        return;
      }

      // Make sure we have at least 7 days of data coming back
      if (_moment.default.duration(endDate.diff(selectedDate)).asDays() < 7) {
        var startDOW = selectedDate.format('d');
        var endDOW = endDate.format('d');

        // If the end date > startDate, make the start date the beginning of the week and add 6 days
        if (endDOW >= startDOW) {
          selectedDate = selectedDate.startOf('week');
          endDate = selectedDate.clone().add(6, 'days').endOf('day');
        } else {
          endDate = endDate.endOf('week');
        }
      }

      this.set('displayBeginDate', selectedDate);
      this.set('displayEndDate', endDate);

      this.set('chartData', null);
      this.set('loading', true);

      var requests = [this.ajax.request('/rest/v1/analyticEntryAggregation/process', {
        data: {
          customerid: customerID,
          begindate: selectedDate.format(),
          enddate: endDate.format(),
          aggregationType: 'ROOM_TYPE_DAY_OF_WEEK',
          rollupStatus: this.get('rollupStatus')
        }
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'DOW Master Occupancy Contribution').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        // if no data than return
        if (data.length === 0) {
          _this.set('data', null);
          _this.set('loading', false);
          return;
        }

        var roomTypeMasters = {};
        var dataFields = [];
        data.forEach(function (analyticEntry) {
          return Ember.merge(roomTypeMasters, analyticEntry.dataValues);
        });

        for (var key in roomTypeMasters) {
          if (roomTypeMasters.hasOwnProperty(key)) {
            roomTypeMasters[key] = '0.0';
            dataFields.push({ key: key, label: key });
          }
        }

        _this.set('dataFields', dataFields.sortBy('key'));

        // Grab the locales start of week so we can order the dataset correctly
        var localeStartDayOfWeek = (0, _moment.default)().startOf('week').format('d');

        var createBaseData = function createBaseData(dow, orderBy) {
          return Ember.assign({}, roomTypeMasters, {
            dow: dow,
            dateClass: '',
            orderBy: orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy
          });
        };

        var order = 0;
        var dataSet = [createBaseData(('' + _this.i18n.t('charts.day_of_week.sun')).toUpperCase(), order), createBaseData(('' + _this.i18n.t('charts.day_of_week.mon')).toUpperCase(), ++order), createBaseData(('' + _this.i18n.t('charts.day_of_week.tue')).toUpperCase(), ++order), createBaseData(('' + _this.i18n.t('charts.day_of_week.wed')).toUpperCase(), ++order), createBaseData(('' + _this.i18n.t('charts.day_of_week.thu')).toUpperCase(), ++order), createBaseData(('' + _this.i18n.t('charts.day_of_week.fri')).toUpperCase(), ++order), createBaseData(('' + _this.i18n.t('charts.day_of_week.sat')).toUpperCase(), ++order)];

        var dowIndexes = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        var valuesExist = false;
        var today = new Date();
        var todayDay = today.getDay();

        for (var x = 0; x < data.length; x++) {
          var dowData = data[x];
          var dowIndex = dowIndexes.indexOf(dowData.groupByValue);

          if (dowData.dataValues) {
            valuesExist = true;
          }

          for (var _key in dowData.dataValues) {
            valuesExist = true;
            if (dowIndex >= 0) {
              dataSet[dowIndex][_key] = _accounting.default.formatNumber(dowData.dataValues[_key], 1);
            }
          }

          if (todayDay === dowIndex) {
            dataSet[dowIndex]['dateClass'] = 'today';
          }
        }

        if (valuesExist) {
          _this.set('chartData', dataSet.sortBy('orderBy'));
        } else {
          _this.set('chartData', null);
        }
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    data: Ember.computed('chartData', 'mobileIndex', 'deviceType.mobile', function () {
      if (!this.chartData) {
        return;
      }

      if (this.get('deviceType.mobile')) {
        return [this.chartData[this.mobileIndex]];
      } else {
        return this.chartData;
      }
    }),

    mobileTitle: Ember.computed('mobileIndex', function () {
      if (!this.chartData) {
        return;
      }

      return this.chartData[this.mobileIndex].dow;
    }),

    actions: {
      changePage: function changePage(amount) {
        var mobileIndex = this.mobileIndex + amount;
        var maxIndex = this.get('chartData.length') - 1;

        if (mobileIndex > maxIndex) {
          mobileIndex = 0;
        } else if (mobileIndex < 0) {
          mobileIndex = maxIndex;
        }

        this.set('mobileIndex', mobileIndex);
      }
    }
  });
});