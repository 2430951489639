define('ux-pcc/controllers/yield-manager/-adjust-filter-dates-modal', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    model: null,
    beginDate: null,
    endDate: null,
    stlyBeginDate: null,
    stlyEndDate: null,

    modalPosition: Ember.computed('model.displayOutlet', function () {
      return this.get('model.displayOutlet') || 'wormhole-outlet';
    }),

    dashboardViewParameters: Ember.computed.alias('model.dashboardViewParameters'),

    beginDateParam: Ember.computed.alias('dashboardViewParameters.BEGIN_DATE'),

    endDateParam: Ember.computed.alias('dashboardViewParameters.END_DATE'),

    stlyBeginDateParam: Ember.computed.alias('dashboardViewParameters.STLY_BEGIN_DATE'),

    stlyEndDateParam: Ember.computed.alias('dashboardViewParameters.STLY_END_DATE'),

    setValues: Ember.on('didInsertElement', Ember.observer('model', function () {
      this.set('beginDate', (0, _moment.default)(this.get('beginDateParam')));
      this.set('endDate', (0, _moment.default)(this.get('endDateParam')));
      this.set('stlyBeginDate', (0, _moment.default)(this.get('stlyBeginDateParam')));
      this.set('stlyEndDate', (0, _moment.default)(this.get('stlyEndDateParam')));
    })),

    actions: {
      save: function save() {
        this.set('beginDateParam', (0, _moment.default)(this.get('beginDate')));
        this.set('endDateParam', (0, _moment.default)(this.get('endDate')));
        this.set('stlyBeginDateParam', (0, _moment.default)(this.get('stlyBeginDate')));
        this.set('stlyEndDateParam', (0, _moment.default)(this.get('stlyEndDate')));
        this.send('removeModal');
        if (this.get('model.onSave')) {
          this.get('model.onSave')();
        }
      },
      cancel: function cancel() {
        this.send('removeModal');
      }
    }
  });
});