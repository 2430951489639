define('ux-pcc/helpers/toggle-list-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toggleListValue = toggleListValue;
  function toggleListValue(params) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


    var current = params[0];
    var value = params[1];
    var list = params[2] || [];

    // Temp solution for the fake promises we create for lists
    if (list._result) {
      list = list._result;
    }

    (false && !(!hash.seperator) && Ember.assert("You specified 'seperator' instead of 'separator' for 'toggle-list-value'.", !hash.seperator));


    var defaults = {
      separator: ",",
      resetWhenAllSelected: true
    };

    var _Ember$assign = Ember.assign({}, defaults, hash),
        separator = _Ember$assign.separator,
        resetWhenAllSelected = _Ember$assign.resetWhenAllSelected;

    var array = Ember.isArray(current) ? current.slice(0) : current ? current.split(separator) : [];
    array.removeObject("");

    var includes = false;

    // IE doesn't support String includes
    if (!String.prototype.includes) {
      String.prototype.includes = function () {
        'use strict';

        includes = this.indexOf(array, value) !== -1;
      };
    } else {
      includes = array.includes(value);
    }

    if (includes) {
      // remove object from array
      var index = array.indexOf(value);
      array.splice(index, 1);
    } else {
      // add object to array
      array.addObject(value);
    }

    if (resetWhenAllSelected && Ember.get(list, 'length') === Ember.get(array, 'length')) {
      array = [];
    }

    if (hash.separator || !Ember.isArray(current)) {
      return array.join(separator);
    } else {
      return array;
    }
  }

  exports.default = Ember.Helper.helper(toggleListValue);
});