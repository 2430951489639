define('ux-pcc/controllers/data-monitoring/-comment-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    dialog: Ember.inject.service(),
    ajax: Ember.inject.service(),

    actions: {
      save: function save() {
        var _this = this;

        var data = {
          comment: this.get('model.comment'),
          commentID: this.get('model.commentID')
        };

        this.ajax.post("/rest/v1/dataMonitoring/comment", {
          contentType: 'application/json',
          dataType: 'text',
          data: JSON.stringify(data)
        }).then(function () {
          _this.send('removeModal');
          // Show the success modal
          _this.dialog.showSuccess({
            titleText: _this.i18n.t('customer.performance.data-monitoring.comments.success_title'),
            messageText: _this.i18n.t('customer.performance.data-monitoring.comments.success'),
            confirmText: _this.i18n.t('customer.performance.data-monitoring.comments.success_button'),
            onConfirm: function onConfirm() {
              _this.send('refresh');
              _this.get('dialog').close();
            }
          });
        }).catch(function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      cancel: function cancel() {
        this.set('model.comment', '');
        this.send('removeModal');
      }
    }
  });
});