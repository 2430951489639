define('ux-pcc/components/rules/conditions/competitor-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values', 'ember-changeset-validations/validators'], function (exports, _ruleConditionValues, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    type: 'async-list',

    descriptionField: 'name',

    store: Ember.inject.service(),

    validations: Ember.computed('i18n.locale', function () {
      return {
        filterValue: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.i18n.t('rule._form_modal.no_competitor').toString()
        })
      };
    }),

    filterValues: Ember.computed('customer.id', function () {
      var _this = this;

      return this.store.query('customer', {
        folderid: this.get('customer.id'),
        folderyn: 'n',
        customertype: 'COMPETITORS'
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];


        // Grab the Travelclick folder
        var folders = results.filter(function (competitor) {
          return competitor.get('externalReference').indexOf('TCLK') >= 0;
        });

        if (folders.length > 0) {

          return _this.store.query('customer', {
            folderid: folders[0].get('id'),
            detaillevel: 'XREF'
          }).then(function () {
            var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            return results;
          });
        } else {
          return Ember.RSVP.resolve([], 'noop');
        }
      });
    }),

    filterConditions: Ember.computed('i18n.locale', function () {
      return [{ value: 'EQ', label: this.i18n.t('pcc-components.rules.conditions.if_competitor_is') }];
    })
  });
});