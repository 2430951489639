define('ux-pcc/controllers/customer/performance/auto-matrix-pricing', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['startDate'],
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.computed.readOnly('session.data.authenticated'),
    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),
    startDate: null,
    includePY: false,
    includeYesterday: false,
    include3Weeks: false,
    showPriorAndFollowing: true,
    show3Weeks: true,
    adjustmentRule: null,
    hasOngoing: Ember.computed.notEmpty('adjustmentRule.children'),
    hasCalendar: Ember.computed.notEmpty('model.calendars.firstObject.children.length'),
    _ruleObserver: Ember.on('init', Ember.observer('adjustmentRule', 'model.calendars.[]', function () {
      var ruleFilters = [];
      // check for a calendar override for the day
      // otherwise use the base rule (if ongoing)
      if (this.get('model.calendars.length') > 0 && this.get('model.calendars.firstObject.children.length') > 0) {
        var calendarChildren = this.get('model.calendars.firstObject.children');
        ruleFilters = calendarChildren.get('firstObject.children.firstObject.children.firstObject.children') || [];
      } else if (this.get('adjustmentRule') && !this.get('adjustmentRule.endDate') && this.get('adjustmentRule.children.length') > 0) {
        var _calendarChildren = this.get('adjustmentRule.children');
        ruleFilters = _calendarChildren.get('firstObject.children.firstObject.children') || [];
      }
      if (ruleFilters.toArray().length > 0) {
        this.set('includePY', !!ruleFilters.findBy('filterField2', 'HOUR_SOLD_PRIOR_YEAR'));
        this.set('includeYesterday', !!ruleFilters.findBy('filterField2', 'HOUR_SOLD_YESTERDAY'));
        this.set('include3Weeks', !!ruleFilters.findBy('filterField2', 'HOUR_SOLD_3WEEKAVG'));
      } else {
        this.set('includePY', false);
        this.set('includeYesterday', false);
        this.set('include3Weeks', false);
      }
    })),

    groupings: Ember.computed(function () {
      return [{ start: 0, end: 4, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.12am') }, { start: 5, end: 11, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.5am') }, { start: 12, end: 14, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.12pm') }, { start: 15, end: 17, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.3pm') }, { start: 18, end: 20, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.6pm') }, { start: 21, end: 23, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.9pm') }, { start: 24, end: 28, label: this.get('i18n').t('customer.performance.auto-matrix-pricing.12_plus') }];
    }),
    // allow show/hide of columns
    _triggerResize: Ember.observer('show3Weeks', 'showPriorAndFollowing', function () {
      Ember.run.schedule("afterRender", function () {
        Ember.$(window).trigger('resize.fixed-column');
      });
    }),

    _hours: function _hours() {
      var hours = [];
      var formattedTime = void 0;
      for (var i = 0; i < 28; i++) {
        //fill in all of the hours
        formattedTime = (0, _moment.default)().startOf('day').add(i, "hours").format("hA"); //give the time in format X AM/PM
        if (i > 23) {
          formattedTime = formattedTime + "+";
        }
        hours.push(formattedTime);
      }
      return hours;
    },


    yieldableCount: Ember.computed('model.inventory.analyticEntries.[]', function () {
      return (this.get('model.inventory.analyticEntries') || []).findBy('subContentType', 'YIELDABLE');
    }),

    disableSave: Ember.computed('includePY', 'includeYesterday', 'include3Weeks', 'startDate', function () {
      // disable save if no rule parameters chosen or selected date is prior to yesterday
      return !this.get('includePY') && !this.get('includeYesterday') && !this.get('include3Weeks') || _moment.default.tz(this.get('startDate'), this.tz.get('customer')).startOf('day').isBefore(_moment.default.tz(this.tz.get('customer')).subtract(1, 'days').startOf('day'));
    }),

    changedData: Ember.observer('model', function () {
      var _this = this;

      var entries = this.get('model');
      var tz = this.tz.get('customer');
      var dateToday = _moment.default.tz(tz).startOf("day");

      var headerCellHTML = function headerCellHTML(data) {
        return '<div class="performance-inventory-header ' + (data.today ? 'performance-inventory-header--today' : '') + ' ' + (data.isSelectedDate ? 'performance-inventory-header--selected-day' : '') + '">\n          <div class="performance-inventory-header__date-section">\n            <div class="performance-inventory-header__long-date">\n              <span class="performance-inventory-header__dow">' + data.baseDate.format('dddd') + '</span>\n            </div>\n            <div class="performance-inventory-header__short-date">\n              <span class="performance-inventory-header__dow">' + data.comparisonDate.format('ddd') + '</span>\n            </div>\n          </div>\n          <div class="performance-inventory-cell">\n            <div class="performance-inventory-cell__value">\n              <div>' + data.baseDate.format('MM/DD/YYYY') + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              ' + data.comparisonDate.format('MM/DD/YYYY') + '\n            </div>\n          </div>\n        </div>';
      };

      var headerDividedCellHTML = function headerDividedCellHTML(data) {
        return '<div class="performance-inventory-header auto-matrix-pricing__wrapper ' + (data.today ? 'performance-inventory-header--today' : '') + '">\n          <div class="performance-inventory-header__date-section auto-matrix-pricing__header">\n           <div class="performance-inventory-header__long-date date-section auto-matrix-pricing__header--labels">\n             <div class="auto-matrix-pricing__header--labels_item">' + data.label1 + '</div>\n             <div class="auto-matrix-pricing__header--labels_item">' + data.label2 + '</div>\n           </div>\n          </div>\n          <div class="performance-inventory-cell">\n            <div class="performance-inventory-cell__value">\n              <div>' + data.baseDate + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              ' + data.comparisonDate + '\n            </div>\n          </div>\n        </div>';
      };

      var tableCellHTML = function tableCellHTML(data) {
        return '<div class="performance-inventory-cell">\n          <div class="performance-inventory-cell__value">\n            <div>' + (data.baseCount ? data.baseCount : '&mdash;') + '</div>\n          </div>\n          <div class="performance-inventory-cell__value">\n            <div>' + (data.comparisonCount ? data.comparisonCount : '&mdash;') + '</div>\n          </div>\n        </div>';
      };

      var createRow = function createRow(label, name, className) {
        var classNames = "";
        var values = {};
        entries.analytics.forEach(function (data, index) {
          var date = _moment.default.tz(data.baseDate, tz);
          values[date.format()] = { value: tableCellHTML({}), classNames: ['col-' + index] };
        });
        if (className) {
          classNames = className;
        }

        return { label: label, name: name, classNames: classNames, values: values, children: [] };
      };

      var createGroup = function createGroup(data) {
        // create total group/row
        var totalGroup = {
          label: _this.get('i18n').t('customer.performance.auto-matrix-pricing.total'),
          labelAsCell: true,
          classNames: ['active'],
          rows: [createRow('', _this.get('i18n').t('customer.performance.auto-matrix-pricing.total'))]
        };

        var groups = [];

        var totalValues = {};

        data.forEach(function (item) {
          var date = _moment.default.tz(item.baseDate, tz);
          totalValues[date.format()] = {
            baseCount: 0,
            comparisonCount: 0
          };
        });
        // create start of day group/row
        var startOfDayRow = createRow('', _this.get('i18n').t('customer.performance.auto-matrix-pricing.start_of_day'));
        var startOfDayGroup = {
          label: _this.get('i18n').t('customer.performance.auto-matrix-pricing.start_of_day'),
          labelAsCell: true,
          classNames: ["hidden"],
          rows: [startOfDayRow]
        };
        groups.push(startOfDayGroup);
        var groupings = _this.get('groupings');
        // create group for current hour (added to table later)
        var currentRow = createRow('', _this.get('i18n').t('customer.performance.auto-matrix-pricing.ahead'), 'auto-matrix-pricing-table__current');
        var currentHourGroup = {
          label: _this.get('i18n').t('customer.performance.auto-matrix-pricing.ahead'),
          labelAsCell: true,
          classNames: ["hidden"],
          rows: [currentRow]
        };
        // evaluate each grouping
        groupings.forEach(function (grouping) {
          var group = {
            label: grouping.label,
            labelAsCell: true,
            classNames: ["hidden"],
            rows: []
          };
          var groupRow = createRow(grouping.label, grouping.label);
          group.rows.push(groupRow);
          groups.push(group);
          var groupValues = {};
          var currentHour = _moment.default.tz(_this.tz.get('customer')).hours();
          // create group/row for each hour
          _this._hours().forEach(function (hour, index) {
            // only process if hour is within the correct grouping
            if (index >= grouping.start && index <= grouping.end) {
              if (currentHour === index) {
                //add group for current hour
                groups.push(currentHourGroup);
              }
              // create row for hour and add to group
              var dataRow = createRow(hour, hour);
              groupRow.children.push(dataRow);

              data.forEach(function (item, analyticIndex) {
                var date = _moment.default.tz(item.baseDate, tz);
                var hourData = (item.allocations || []).findBy('hour', hour);
                if (!hourData) {
                  hourData = {
                    baseCount: 0,
                    comparisonCount: 0
                  };
                }
                var formattedDate = date.format();
                if (!groupValues[date.format()]) {
                  groupValues[date.format()] = {
                    baseCount: 0,
                    comparisonCount: 0
                  };
                }
                // add start of day values to total values (only once)
                if (index === 0) {
                  var startOfDay = item.allocations.findBy('hour', 'StartOfDay');
                  if (!startOfDay) {
                    startOfDay = {
                      baseCount: 0,
                      comparisonCount: 0
                    };
                  }
                  startOfDayGroup.rows[0].values[formattedDate] = {
                    value: tableCellHTML({
                      baseCount: startOfDay.baseCount,
                      comparisonCount: startOfDay.comparisonCount
                    }),
                    classNames: ['col-' + analyticIndex]
                  };
                  totalValues[formattedDate].baseCount += parseInt(startOfDay.baseCount);
                  totalValues[formattedDate].comparisonCount += parseInt(startOfDay.comparisonCount);
                }
                dataRow.values[formattedDate] = { value: tableCellHTML(hourData), classNames: ['col-' + analyticIndex] };
                // add hour values to grouping values
                if (groupValues[formattedDate]) {
                  groupValues[formattedDate].baseCount += parseInt(hourData.baseCount);
                  groupValues[formattedDate].comparisonCount += parseInt(hourData.comparisonCount);
                  group.rows[0].values[formattedDate] = {
                    value: tableCellHTML(groupValues[formattedDate]),
                    classNames: ['col-' + analyticIndex]
                  };
                }
                // add hour values to total values
                if (totalValues[formattedDate]) {
                  totalValues[formattedDate].baseCount += parseInt(hourData.baseCount);
                  totalValues[formattedDate].comparisonCount += parseInt(hourData.comparisonCount);
                  totalGroup.rows[0].values[formattedDate] = {
                    value: tableCellHTML(totalValues[formattedDate]),
                    classNames: ['col-' + analyticIndex]
                  };
                }
                // set values for current hour column
                if (currentHour === index) {
                  currentHourGroup.rows[0].values[formattedDate] = {
                    value: tableCellHTML(totalValues[formattedDate]),
                    classNames: ['col-' + analyticIndex]
                  };
                }
              });
            }
          });
        });
        // add total group
        groups.push(totalGroup);

        // add alternating color class to rows
        groups.forEach(function (row, index) {
          if (index % 2 === 1) {
            groups[index].rows.forEach(function (row) {
              row.classNames += ' performance-inventory-row--alternate';
            });
          }
        });

        return groups;
      };

      var columns = [];

      // create columns
      entries.analytics.forEach(function (data, index) {
        var date = _moment.default.tz(data.baseDate, tz);
        var comparisonDate = _moment.default.tz(data.comparisonDate, tz);
        var isToday = date.valueOf() === dateToday.valueOf();
        var isSelectedDate = date.valueOf() === _moment.default.tz(_this.get('startDate'), tz).valueOf();
        var label1 = index === 3 ? _this.get('i18n').t('customer.performance.auto-matrix-pricing.prior', { dow: date.clone().format('ddd') }) : _this.get('i18n').t('customer.performance.auto-matrix-pricing.cy');
        var label2 = index === 3 ? _this.get('i18n').t('customer.performance.auto-matrix-pricing.following', { dow: date.clone().format('ddd') }) : _this.get('i18n').t('customer.performance.auto-matrix-pricing.py');
        var dow3Weeks = "3 " + date.clone().format('dddd');
        // for first 3 columns, show dow header; after that show custom date columns
        var column = {
          key: date.format(),
          classNames: 'col-' + index,
          label: index < 3 ? headerCellHTML({
            baseDate: date.clone(),
            comparisonDate: comparisonDate,
            today: isToday,
            isSelectedDate: isSelectedDate
          }) : headerDividedCellHTML({
            baseDate: index === 3 ? date.clone().format('MM/DD/YYYY') : dow3Weeks,
            comparisonDate: index === 3 ? comparisonDate.clone().format('MM/DD/YYYY') : dow3Weeks,
            today: isToday,
            isSelectedDate: isSelectedDate,
            label1: label1,
            label2: label2
          }) };

        columns.push(column);
      });

      this.set('tableData', {
        groups: createGroup(entries.analytics),
        columns: columns
      });
    }),
    _openDrawers: function _openDrawers(row) {
      var linkedRow = (this.get('tableData.groups') || []).findBy('label', row.name);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', true);
      }
    },
    _closeDrawers: function _closeDrawers(row) {
      var linkedRow = (this.get('tableData.groups') || []).findBy('label', row.name);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', false);
      }
    },
    _saveRule: function _saveRule(ruleID, startDate) {
      var _this2 = this;

      var mainRules = [];
      // add children rules for rule conditions
      var conditionRules = [];
      var parameters = [];
      if (this.get('includePY')) {
        var pyRule = {
          filterCondition: 'GT',
          filterType: 'FIELD_WHERE',
          ruleMethod: 'FILTER',
          filterField: 'HOUR_SOLD_TODAY',
          filterField2: 'HOUR_SOLD_PRIOR_YEAR'
        };
        conditionRules.push(pyRule);
        parameters.push(this.get('i18n').t('customer.performance.auto-matrix-pricing.greater_than_py'));
      }
      if (this.get('includeYesterday')) {
        conditionRules.push({
          filterCondition: 'GT',
          filterType: 'FIELD_WHERE',
          ruleMethod: 'FILTER',
          filterField: 'HOUR_SOLD_TODAY',
          filterField2: 'HOUR_SOLD_YESTERDAY'
        });
        parameters.push(this.get('i18n').t('customer.performance.auto-matrix-pricing.greater_than_yesterday'));
      }
      if (this.get('include3Weeks')) {
        conditionRules.push({
          filterCondition: 'GT',
          filterType: 'FIELD_WHERE',
          ruleMethod: 'FILTER',
          filterField: 'HOUR_SOLD_TODAY',
          filterField2: 'HOUR_SOLD_3WEEKAVG'
        });
        parameters.push(this.get('i18n').t('customer.performance.auto-matrix-pricing.greater_than_3_weeks'));
      }
      // create ALL (WHEN) filter rule
      var filterRule = {
        filterType: 'ALL',
        ruleMethod: 'FILTER',
        children: conditionRules
      };
      // create ANY (THEN) filter rule
      var filterWrapperRule = {
        filterType: 'ANY',
        ruleMethod: 'FILTER',
        children: [filterRule]
      };
      // create main rule
      var mainRule = {
        amountRule: 'AUTO_ALLOCATION',
        ruleMethod: 'RULE',
        ruleType: "PRICE_AUTO_ALLOCATION",
        shortDescription: 'BAR Auto Allocations',
        children: [filterWrapperRule]
      };
      mainRules.push(mainRule);

      // save rule calendar request
      var requestTemplate = {
        customerID: this.get('customer.id'),
        day1: "Y",
        day2: "Y",
        day3: "Y",
        day4: "Y",
        day5: "Y",
        day6: "Y",
        day7: "Y",
        ruleID: ruleID,
        ruleType: "PRICE_AUTO_ALLOCATION",
        beginDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        endDate: startDate.clone().endOf("day").format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        children: mainRules
      };
      return this.get('ajax').post('/rest/v1/ruleCalendarRequests', {
        data: JSON.stringify(requestTemplate),
        contentType: "application/json"
      }).then(function () /*responses*/{
        // save rule modification alert
        var payload = _this2.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_alert', {
          userFirstName: _this2.get('user.firstName'),
          userLastName: _this2.get('user.lastName'),
          startDate: startDate.clone().startOf('day').format('LL'),
          parameters: parameters.join(", ")
        });
        var alert = _this2.get('store').createRecord('alert', {
          customer: _this2.get('customer'),
          alertMethod: 'API',
          actionCode: 'GET',
          payloadType: 'TEXT',
          status: 'OK',
          alertType: 'NOTIFY_RULE_MODIFICATION',
          payload: payload,
          fkReference: 'RULE',
          fkID: ruleID,
          alertRequestedDate: (0, _moment.default)(),
          alertDate: (0, _moment.default)(),
          retryCount: 0,
          responseRequiredYN: false
        });
        alert.save();
        _this2.get('dialog').showSuccess({
          titleText: _this2.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_title'),
          messageText: _this2.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success', { date: startDate.clone().format('dddd, L') }),
          confirmText: _this2.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_button'),
          onConfirm: function onConfirm() {
            _this2.get('dialog').close();
            _this2.send('refresh');
          }
        });
      }, function () {
        _this2.get('dialog').showError();
      });
    },


    actions: {
      applyRule: function applyRule() {
        var _this3 = this;

        var tz = this.tz.get('customer');
        var startDate = _moment.default.tz(this.get('startDate'), tz);
        // create allocation rule if there isn't one
        var ruleTemplate = {
          customer: this.get('customer'),
          day1: "Y",
          day2: "Y",
          day3: "Y",
          day4: "Y",
          day5: "Y",
          day6: "Y",
          day7: "Y"
        };
        if (!this.get('adjustmentRule')) {
          var adjustmentRule = this.get('store').createRecord('rule', Ember.merge({
            amountRule: 'AUTO_ALLOCATION',
            calculationRule: 'CONDITION_REQUIRED',
            ruleMethod: 'RULE',
            ruleType: 'PRICE_AUTO_ALLOCATION',
            shortDescription: 'BAR Auto Allocations'
          }, ruleTemplate));
          adjustmentRule.save().then(function () {
            _this3.set('adjustmentRule', adjustmentRule);
            _this3._saveRule(adjustmentRule.get('id'), startDate);
          }, function () {
            _this3.get('dialog').showError();
          });
        } else {
          this._saveRule(this.get('adjustmentRule.id'), startDate);
        }
      },
      copyRule: function copyRule() {
        this.send('showModal', 'customer/performance/-auto-matrix-pricing-copy-modal', {
          startDate: _moment.default.tz(this.get('startDate'), this.tz.get('customer')),
          endDate: _moment.default.tz(this.get('startDate'), this.tz.get('customer')),
          adjustmentRule: this.get('adjustmentRule'),
          includePY: this.get('includePY'),
          includeYesterday: this.get('includeYesterday'),
          include3Weeks: this.get('include3Weeks'),
          customer: this.get('customer'),
          user: this.get('user')
        });
      },
      selectChildren: function selectChildren(row) {
        this._openDrawers(row);
      },
      hideChildren: function hideChildren(row) {
        this._closeDrawers(row);
      },
      removeOngoingRule: function removeOngoingRule() {
        var _this4 = this;

        var rule = this.get('adjustmentRule');
        rule.set('children', []);
        rule.set('beginDate', null);
        rule.set('endDate', null);
        rule.save().then(function () {
          // save rule modification alert
          var payload = _this4.get('i18n').t('customer.performance.auto-matrix-pricing.apply.remove_ongoing_alert', {
            userFirstName: _this4.get('user.firstName'),
            userLastName: _this4.get('user.lastName')
          });
          var alert = _this4.get('store').createRecord('alert', {
            customer: _this4.get('customer'),
            alertMethod: 'API',
            actionCode: 'GET',
            payloadType: 'TEXT',
            status: 'OK',
            alertType: 'NOTIFY_RULE_MODIFICATION',
            payload: payload,
            fkReference: 'RULE',
            fkID: rule.get('id'),
            alertRequestedDate: (0, _moment.default)(),
            alertDate: (0, _moment.default)(),
            retryCount: 0,
            responseRequiredYN: false
          });
          alert.save();
          _this4.get('dialog').showSuccess({
            titleText: _this4.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_title'),
            messageText: _this4.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_remove_ongoing'),
            confirmText: _this4.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_button')
          });
          _this4.send('refresh');
        }, function () {
          _this4.get('dialog').showError();
        });
      },
      removeRuleCalendar: function removeRuleCalendar() {
        var _this5 = this;

        var startDate = _moment.default.tz(this.get('startDate'), this.tz.get('customer'));
        this.get('ajax').post('/rest/v1/ruleCalendarRequests', {
          data: JSON.stringify({
            customerID: this.get('customer.id'),
            day1: "Y",
            day2: "Y",
            day3: "Y",
            day4: "Y",
            day5: "Y",
            day6: "Y",
            day7: "Y",
            ruleID: this.get('adjustmentRule.id'),
            ruleType: "PRICE_AUTO_ALLOCATION",
            beginDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            endDate: startDate.clone().add(365, 'days').endOf("day").format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            deleteYN: "Y"
          }),
          contentType: "application/json"
        }).then(function () {
          // save rule modification alert
          var payload = _this5.get('i18n').t('customer.performance.auto-matrix-pricing.apply.remove_rule_alert', {
            userFirstName: _this5.get('user.firstName'),
            userLastName: _this5.get('user.lastName'),
            startDate: startDate.clone().startOf('day').format('LL')
          });
          var alert = _this5.get('store').createRecord('alert', {
            customer: _this5.get('customer'),
            alertMethod: 'API',
            actionCode: 'GET',
            payloadType: 'TEXT',
            status: 'OK',
            alertType: 'NOTIFY_RULE_MODIFICATION',
            payload: payload,
            fkReference: 'RULE',
            fkID: _this5.get('adjustmentRule.id'),
            alertRequestedDate: (0, _moment.default)(),
            alertDate: (0, _moment.default)(),
            retryCount: 0,
            responseRequiredYN: false
          });
          alert.save();
          _this5.get('dialog').showSuccess({
            titleText: _this5.get('i18n').t('customer.performance.auto-matrix-pricing.copy.success_title'),
            messageText: _this5.get('i18n').t('customer.performance.auto-matrix-pricing.apply.success_remove_rule', {
              startDate: startDate.clone().format('dddd, L')
            }),
            confirmText: _this5.get('i18n').t('customer.performance.auto-matrix-pricing.copy.success_button'),
            onConfirm: function onConfirm() {
              _this5.setProperties({
                day0: true,
                day1: true,
                day2: true,
                day3: true,
                day4: true,
                day5: true,
                day6: true,
                removeExistingRuleCalendars: false,
                isOngoing: false
              });
              _this5.get('dialog').close();
              _this5.send('refresh');
            }
          });
        }, function () {
          _this5.get('dialog').showError();
        });
      }
    }
  });
});