define('ux-pcc/components/yield-manager/analyze/analyze-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    record: null,
    isExpanded: Ember.computed.alias('record.isExpanded'),
    allowSelect: false,
    wrapperClass: '',

    sortedChildren: Ember.computed.sort('record.children', 'sort'),
    mouseOverClass: '',
    hoverClass: '',

    actions: {
      toggleDrawer: function toggleDrawer() {
        this.toggleProperty('isExpanded');
        if (this.get('isExpanded')) {
          this.get('requestChildren')();
        } else {
          this.get('drawerClosed')();
        }
      },
      mouseOver: function mouseOver(overRow) {
        if (overRow) {
          this.set('hoverClass', this.get('mouseOverClass'));
        } else {
          this.set('hoverClass', '');
        }
      }
    }
  });
});