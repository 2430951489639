define('ux-pcc/routes/application', ['exports', 'ux-components/utils/load-extra-moment-locales'], function (exports, _loadExtraMomentLocales) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    changeLocale: Ember.inject.service(),

    beforeModel: function beforeModel() {
      (0, _loadExtraMomentLocales.default)();

      //TODO: lazy-load translations here
    },
    model: function model() {
      return this.changeLocale.changeLocale();
    },
    title: function title(tokens) {
      return tokens.join(' | ');
    },


    // Clear/close the currently open modal
    // https://github.com/emberjs/ember-inspector/issues/625
    _clearModal: function _clearModal() {
      this.disconnectOutlet({
        outlet: 'modal',
        parentView: 'application'
      });

      this.render('modals/nothing', {
        outlet: 'modal',
        into: 'application',
        controller: 'application'
      });
    },


    actions: {
      showModal: function showModal(name, model) {
        this._clearModal();

        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },


      // Temporary way to pass 'openModal' into newer components
      openModal: function openModal(name, model) {
        this._clearModal();

        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },
      removeModal: function removeModal() {
        this._clearModal();
      },
      closeModal: function closeModal() {
        this._clearModal();
      },
      logout: function logout() {
        this.transitionTo('logout');
      }
    }
  });
});