define('ux-pcc/components/portfolios/portfolio-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,

    tagName: "div",
    classNames: ['portfolio-feed'],

    isMobile: Ember.computed.bool('deviceType.mobile'),

    init: function init() {
      // our custom code
      this._super.apply(this, arguments);

      this.set('sort', ['name']);
    },


    actions: {
      flash: function flash(message, type) {
        this.flash(message, type);
      },
      confirmDelete: function confirmDelete(object) {
        this.confirmDelete(object);
      },
      confirmUnlink: function confirmUnlink(parent, child) {
        this.confirmUnlink(parent, child);
      }
    }
  });
});