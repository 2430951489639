define('ux-pcc/controllers/customer/performance/-matrix-pricing-copy-modal', ['exports', 'moment', 'ux-pcc/controllers/availability/-base-modal', 'ux-components/utils/format-currency'], function (exports, _moment, _baseModal, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModal.default.extend({
    matrixPricingController: Ember.inject.controller('customer/performance/matrix-pricing'),
    date: Ember.computed.oneWay('model.selectedSlice'),
    model: null,
    isCopying: false,
    previousPricing: null,
    hasPreviousPricingSave: false,
    isMobile: Ember.computed.oneWay('matrixPricingController.isMobile'),
    isNotMobile: Ember.computed.not('isMobile'),
    isError: false,
    isSaving: false,
    errorText: '',
    preventBlur: false,
    copyEventTime: false,
    hasEventTime: Ember.computed.notEmpty('model.timeRules'),
    timeRule: Ember.computed.alias('model.timeRules.firstObject'),
    timeRuleFilters: Ember.computed.alias('timeRule.children.firstObject.children.firstObject.children'), //fragile, assumes time of day rule is constructed correctly
    timeRuleOccupancy: Ember.computed('timeRuleFilters', function () {
      var occupancyFilter = (this.get('timeRuleFilters') || []).findBy('filterField', 'OCC_PCT');
      if (occupancyFilter) {
        var percent = Ember.get(occupancyFilter, 'filterValue');
        var equality = Ember.get(occupancyFilter, 'filterCondition');
        return this._equalityLabel(equality) + " " + percent;
      }
    }),
    timeRuleRate: Ember.computed('timeRule.amount', function () {
      return (0, _formatCurrency.default)(this.get('timeRule.amount'), this.get('timeRule.currencyCode') || "USD");
    }),
    timeRuleTime: Ember.computed('timeRule.shortDescription', function () {
      var timeFilter = (this.get('timeRuleFilters') || []).findBy('filterField', 'EVENT_TIME');
      if (timeFilter) {
        var time = Ember.get(timeFilter, 'filterValue');
        var equality = Ember.get(timeFilter, 'filterCondition');
        return this._timeEqualityLabel(equality) + " " + this._formatTimeDescription(time);
      }
    }),
    eventTimeLabel: Ember.computed('timeRuleRate', 'timeRuleOccupancy', 'timeRuleTime', function () {
      return this.i18n.t("customer.performance.matrix-pricing.copy.event_time", { amount: this.get('timeRuleRate'), occupancy: this.get('timeRuleOccupancy'), time: this.get('timeRuleTime') });
    }),

    day0Active: false,
    day1Active: false,
    day2Active: false,
    day3Active: false,
    day4Active: false,
    day5Active: false,
    day6Active: false,

    modalTitle: Ember.computed('model.isCopy', function () {
      if (this.get('model.isCopy')) {
        return "customer.performance.matrix-pricing.copy.title";
      } else {
        return "customer.performance.matrix-pricing.add.title";
      }
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: (0, _moment.default)().startOf('day').subtract(1, 'day')
      };
    }),

    copiedPricePoints: Ember.computed('model.{pricePoints,selectedSlice}', function () {
      var _this = this;

      if (!this.get('model.selectedSlice')) {
        return;
      }

      var tz = this.tz.get("customer");
      var selectedSlice = this.get('model.selectedSlice').tz(tz).format();
      return this.get('model.pricePoints').map(function (pricePoint) {
        var pricePointProxy = Ember.ObjectProxy.create({ content: pricePoint });

        if (_this.get('model.isCopy') && pricePoint.values[selectedSlice]) {
          var allocatedValues = pricePoint.values[selectedSlice].values;

          pricePointProxy.set('value', allocatedValues.val);
          pricePointProxy.set('lead', allocatedValues.lead);
        } else {
          pricePointProxy.set('value', null);
          pricePointProxy.set('lead', null);
        }

        return pricePointProxy;
      });
    }),

    isCopyingChanged: Ember.on('didInsertElement', Ember.observer('isCopying', function () {
      var _this2 = this;

      var context = Ember.$('.price-points');
      Ember.run.schedule("afterRender", function () {
        var $input = context.find('input');

        $input.focus();

        var previousColumn = function previousColumn() {
          $input.parent().prev('div').trigger('click');
        };

        var nextColumn = function nextColumn() {
          $input.parent().next('div').trigger('click');
        };

        var next = function next() {
          $input.parent().parent().parent().next('.price-points-row').find('.matrix-pricing-copy-cell__price-allocation').trigger('click');
          $input.parent().parent().parent().find('label').trigger('click');
        };

        var previous = function previous() {
          $input.parent().parent().parent().prev('.price-points-row').find('.matrix-pricing-copy-cell__price-allocation').trigger('click');
          $input.parent().parent().parent().find('label').trigger('click');
        };

        var advanceCursor = function advanceCursor(key, shiftKey) {
          switch (key) {
            case 9:
              // TAB
              if (shiftKey) {
                //if allocation, go to previous row
                if ($input.hasClass('matrix-pricing-copy-cell__input-allocation')) {
                  previous();
                } else {
                  _this2.set('preventBlur', true);
                  previousColumn();
                }
              } else {
                //if lead time, go to next row
                if ($input.hasClass('matrix-pricing-copy-cell__input-allocation')) {
                  _this2.set('preventBlur', true);
                  nextColumn();
                } else {
                  next();
                }
              }
              break;
            case 13:
              // ENTER
              next();
              break;
            case 40:
              // DOWN
              next();
              break;
            case 38:
              // UP
              previous();
              break;
          }
        };

        $input.on('keydown', function (evt) {

          if ($input.val().length >= 3 && !/^[\b]+$/.test(String.fromCharCode(evt.keyCode))) {
            evt.preventDefault();
          }

          switch (evt.which) {
            case 27:
              // ESC
              evt.preventDefault();
              $input.parent().parent().find('label').trigger('click');
              break;
            case 9: // TAB
            case 13: // ENTER
            case 40: // DOWN
            case 38:
              // UP
              evt.preventDefault();
              advanceCursor(evt.which, evt.shiftKey);

              break;
          }
        });

        $input.bind("paste", function () /* evt */{
          //check to ensure pasted text is number only and is not greater than 3 characters in length
          Ember.run.later("afterRender", function () {
            if (!/^[\d]+$/.test($input.val()) || $input.val().length > 3) {
              $input.val("");
            }
          }, 10);
        });

        $input.on('blur', function (evt) {
          if (!_this2.get('preventBlur')) {
            evt.preventDefault();
            $input.parent().parent().find('label').trigger('click');
          } else {
            _this2.set('preventBlur', false);
          }
        });
      });
    })),

    _serialize: function _serialize() {
      return Ember.$.extend({
        date: this.get('model.selectedSlice').format() || void 0,
        pricePoints: this.get('copiedPricePoints')
      }, this._super());
    },
    _formatTimeDescription: function _formatTimeDescription(time) {
      return (0, _moment.default)(time, "HH:mm").format("hh:mmA"); // format 24 hour time into 12 hour AM/PM
    },
    _equalityLabel: function _equalityLabel(equality) {
      switch (equality) {
        case 'LTEQ':
        case 'LT':
          return this.i18n.t("customer.performance.matrix-pricing.copy.less_than");
        case 'GTEQ':
        case 'GT':
          return this.i18n.t("customer.performance.matrix-pricing.copy.greater_than");
      }
    },
    _timeEqualityLabel: function _timeEqualityLabel(equality) {
      switch (equality) {
        case 'LTEQ':
        case 'LT':
          return this.i18n.t("customer.performance.matrix-pricing.copy.before");
        case 'GTEQ':
        case 'GT':
          return this.i18n.t("customer.performance.matrix-pricing.copy.after");
      }
    },
    _hasIncompleteWeekSelected: function _hasIncompleteWeekSelected(data) {
      var hasIncompleteWeek = false;
      var dayCount = (0, _moment.default)(data.endSlice).diff((0, _moment.default)(data.beginSlice), 'days') + 1;

      var currentDate = _moment.default.tz(data.beginSlice, this.get('tz').customer);
      this.setProperties({
        day0Active: false,
        day1Active: false,
        day2Active: false,
        day3Active: false,
        day4Active: false,
        day5Active: false,
        day6Active: false
      });

      for (var i = dayCount; i > 0; i--) {
        // Use mod-7 here to make Sunday = 0, Monday = 1, etc.
        var dayOfWeek = currentDate.format('E') % 7;
        var dayActive = !currentDate.isBefore((0, _moment.default)().startOf('day'));

        switch (dayOfWeek) {
          case 0:
            this.set('day0Active', dayActive);
            break;
          case 1:
            this.set('day1Active', dayActive);
            break;
          case 2:
            this.set('day2Active', dayActive);
            break;
          case 3:
            this.set('day3Active', dayActive);
            break;
          case 4:
            this.set('day4Active', dayActive);
            break;
          case 5:
            this.set('day5Active', dayActive);
            break;
          case 6:
            this.set('day6Active', dayActive);
            break;
        }

        currentDate.add(1, 'day');
      }

      if (this.get('day0Active') && !data.day0) {
        hasIncompleteWeek = true;
      }
      if (this.get('day1Active') && !data.day1) {
        hasIncompleteWeek = true;
      }
      if (this.get('day2Active') && !data.day2) {
        hasIncompleteWeek = true;
      }
      if (this.get('day3Active') && !data.day3) {
        hasIncompleteWeek = true;
      }
      if (this.get('day4Active') && !data.day4) {
        hasIncompleteWeek = true;
      }
      if (this.get('day5Active') && !data.day5) {
        hasIncompleteWeek = true;
      }
      if (this.get('day6Active') && !data.day6) {
        hasIncompleteWeek = true;
      }
      this.set('hasIncompleteWeek', hasIncompleteWeek);
    },


    actions: {
      save: function save() {
        var _this3 = this;

        this.set('isSaving', true);
        var data = this._serialize();

        // If copying, use the copy-from date to find the price points, otherwise use the begin date.
        if (!this.get('model.isCopy')) {
          if (data.date !== data.beginSlice) {
            data.date = data.beginSlice;
          }
        }

        //check to ensure at least one day is selected
        if (!data.day0 && !data.day1 && !data.day2 && !data.day3 && !data.day4 && !data.day5 && !data.day6) {
          this.set('isError', true);
          this.set('errorText', this.i18n.t('components.day_switch_bank.no_day_selected_error'));
          return;
        } else {
          this.set('isError', false);
          this.set('errorText', "");
        }

        var tz = this.tz.get("customer");
        var date = (0, _moment.default)(data.date).tz(tz);

        var parentController = this.get('matrixPricingController');
        var calendar = parentController.get('model').calendars.find(function (c) {
          return (0, _moment.default)(Ember.get(c, 'ruleDate')).tz(tz).valueOf() === date.valueOf();
        });

        if (!calendar) {
          var ruleSet = parentController.get('ruleSet').get('children');

          calendar = {
            ruleDate: data.date,
            children: ruleSet,
            isDirty: false
          };

          parentController.get('model').calendars.addObject(calendar);
        }

        data.pricePoints.forEach(function (pricePoint) {
          var allocation = {
            value: pricePoint.get('value') ? parseInt(pricePoint.get('value')) : 0,
            leadTime: pricePoint.get('lead') || pricePoint.get('lead') === 0 ? parseInt(pricePoint.get('lead')) : void 0
          };
          var rules = parentController._composeRule(data.date, pricePoint.get('id'), allocation);

          parentController._updateCalendar(rules, data.date);
        });

        var rules = void 0;
        if (calendar) {
          rules = Ember.get(calendar, 'children');
        } else {
          rules = parentController.get('ruleSet').get('children');
        }

        var complete = function complete(success) {
          parentController.get('model').calendars.forEach(function (c) {
            Ember.set(c, 'isDirty', false);
          });
          _this3.set('isSaving', false);
          if (success) {
            _this3.send('showModal', 'customer/performance/-matrix-pricing-copy-success-modal', {
              isCopy: _this3.get('model.isCopy'),
              pricing: data,
              hasIncompleteWeek: _this3.get('hasIncompleteWeek'),
              pricePoints: _this3.get('model.pricePoints'),
              hasPreviousPricingSave: _this3.get('hasPreviousPricingSave')
            });
          } else {
            _this3.send('cancelPricing');
          }
        };

        this.set('previousPricing', data);
        if (this.get('hasPreviousPricingSave')) {
          this.set('hasPreviousPricingSave', false);
        } else {
          this.set('hasPreviousPricingSave', true);
        }
        this._hasIncompleteWeekSelected(data);

        // filter out freeze rules
        rules = rules.rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

        // filter out event time rule if copy option unchecked
        if (!this.get('copyEventTime')) {
          rules = rules.rejectBy('externalReference', 'EVENT_TIME');
        }

        this.send('requestCopy', data, rules, complete);
        this.set('copyEventTime', true);
      },
      cancelPricing: function cancelPricing() {
        this.set('previousPricing', null);
        this.set('hasPreviousPricingSave', false);
        this.set('isCopying', false);
        this.set('copyEventTime', false);
        this.set('isSaving', false);
        this.send('removeModal');
      },
      toggleCopyEventTime: function toggleCopyEventTime() {
        this.toggleProperty('copyEventTime');
      }
    }
  });
});