define('ux-pcc/controllers/rule/-modal', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_flashable.default, {

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model')

  });
});