define('ux-pcc/components/charts/stacked-chart', ['exports', 'ux-pcc/config/environment', 'ux-components/utils/abbreviate-number', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/helpers/wrap-negative', 'accounting/format-number'], function (exports, _environment, _abbreviateNumber, _chartBasic, _wrapNegative, _formatNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    showLegend: false,
    showValueAxisLabels: false,
    showGuide: true,
    showBalloon: true,
    wrapLabel: null,

    enableZoom: true,
    zoomIndexStart: 0,
    zoomIndexEnd: 10,
    valueLabelSuffix: '',

    _formatValue: function _formatValue(value, format, formatHash) {
      if (format === 'currency' || format === 'percent') {
        return (0, _formatNumber.default)(value, formatHash.precision, '');
      } else {
        return value;
      }
    },
    _config: function _config() {
      var _this = this;

      var data = this.get('data');

      if (!data) {
        return;
      }

      this.set('data', data);

      var formatLabel = function formatLabel(dataValue, precision, suffix, useAbbreviation, format, formatHash) {
        var value = dataValue;

        if (useAbbreviation) {
          value = (0, _abbreviateNumber.default)(value, precision);
        } else {
          value = _this._formatValue(value, format, formatHash);
        }

        return (0, _wrapNegative.wrapNegative)(value, useAbbreviation ? {} : formatHash);
      };

      var bubbleContent = function bubbleContent(title, values, titleSuffix) {
        var balloonData = '<div class=\'center\'>\n                           <span class=\'bubble-title\'>\n                             ' + title + ' ' + titleSuffix + '\n                           </span>\n                         </div>';

        values.forEach(function (row) {
          var displayValue = row.value;

          if (row.useAbbreviation) {
            displayValue = (0, _abbreviateNumber.default)(displayValue, row.formatHash.precision);
          } else {
            displayValue = _this._formatValue(displayValue, row.format, row.formatHash);
          }

          displayValue = (0, _wrapNegative.wrapNegative)(displayValue, row.useAbbreviation ? {} : row.formatHash);

          var classStyle = row.value < 0 ? 'negative-value-class' : '';
          balloonData += '<div>\n                          <span class=\'bubble-label\'>\n                            ' + row.label + ':\n                          </span>\n                          <span class=\'bubble-value ' + classStyle + '\'>\n                            ' + displayValue + '\n                          </span>\n                        </div>';
        });

        return balloonData;
      };

      var config = {
        type: 'serial',
        rotate: 'true',
        theme: 'none',
        marginBottom: 30,
        marginRight: 15,
        titles: [],
        listeners: [{
          event: 'clickGraphItem',
          method: function method(graphItem) {
            if (_this.get('onGraphClick')) {
              _this.get('onGraphClick')(graphItem.item.dataContext);
            }
          }
        }],
        startDuration: 0,
        graphs: [{
          id: 'g1 ' + this.get('pyColorClass'),
          fillAlphas: 1,
          lineAlpha: 1,
          type: 'column',
          valueField: 'PY',
          title: 'PY',
          labelText: '[[value]]',
          classNameField: 'valueClass',
          showHandOnHover: !Ember.isEmpty(this.get('onGraphClick')),
          clustered: false,
          labelFunction: function labelFunction(item) {
            if (item.dataContext.PY >= 0) {
              return '';
            }

            return formatLabel(item.dataContext.PY, item.dataContext.precision, item.dataContext.suffix, item.dataContext.useAbbreviation, item.dataContext.format, item.dataContext.formatHash);
          },
          balloonFunction: function balloonFunction(item) {
            if (item.dataContext.PY < 0) {
              return bubbleContent(item.category, item.dataContext.bubbleConfig, item.dataContext.titleSuffix);
            }
          }
        }, {
          id: 'g2 ' + this.get('cyColorClass'),
          fillAlphas: 1,
          lineAlpha: 1,
          type: 'column',
          valueField: 'CY',
          title: 'CY',
          labelText: '[[value]]',
          classNameField: '',
          showHandOnHover: !Ember.isEmpty(this.get('onGraphClick')),
          clustered: false,
          labelFunction: function labelFunction(item) {
            if (item.dataContext.CY === 0 && item.dataContext.PY < 0) {
              return '';
            }

            return formatLabel(item.dataContext.CY, item.dataContext.precision, item.dataContext.suffix, item.dataContext.useAbbreviation, item.dataContext.format, item.dataContext.formatHash);
          },
          balloonFunction: function balloonFunction(item) {
            if (item.dataContext.CY > 0 || item.dataContext.PY === 0 && item.dataContext.CY === 0) {
              return bubbleContent(item.category, item.dataContext.bubbleConfig, item.dataContext.titleSuffix, item.dataContext.useAbbreviation);
            }
          }
        }],
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryField: 'category',
        categoryAxis: {
          gridPosition: 'start',
          gridAlpha: 0.2,
          axisAlpha: 0,
          labelOffset: 5,
          autoWrap: true,
          listeners: [{
            event: 'clickItem',
            method: function method(value) {
              if (_this.get('onGraphClick')) {
                _this.get('onGraphClick')(value.serialDataItem.dataContext);
              }
            }
          }]
        },
        valueAxes: [{
          gridAlpha: 0,
          minMaxMultiplier: 1.33,
          ignoreAxisWidth: true,
          usePrefixes: true,
          guides: [{
            id: 'zero-marker',
            value: 0,
            lineAlpha: 1,
            lineThickness: 3,
            above: true,
            inside: true,
            fillColor: '#000000',
            lineColor: '#000000'
          }]
        }],
        legend: {
          enabled: false,
          position: 'bottom',
          markerSize: 10,
          markerType: 'square',
          valueWidth: 0,
          align: 'center',
          spacing: 50,
          data: [{ title: 'CY', color: '#FF6600', markerType: 'square' }, { title: 'PY', color: '#FCD202', markerType: 'square' }]
        },
        balloon: {
          fillAlpha: 1,
          fixedPosition: true
        },
        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: true,
          enabled: true,
          cursorAlpha: 0
        },
        dataProvider: data
      };

      if (this.get('title')) {
        config.titles.push({ text: this.get('title') });
      }

      if (data.length > this.get('zoomIndexEnd')) {
        this.set('scrollBarExists', true);
        config.pathToImages = _environment.default.rootURL + 'assets/amcharts/images/'; // required for grips

        config.chartScrollbar = {
          graph: 'g1',
          oppositeAxis: true,
          offset: 5,
          scrollbarHeight: 12,
          backgroundAlpha: 0.2,
          selectedBackgroundAlpha: 0.3,
          selectedBackgroundColor: '#888888',
          graphFillAlpha: 0,
          graphLineAlpha: 0,
          graphLineColor: '#888888',
          selectedGraphFillAlpha: 0,
          selectedGraphLineAlpha: 0,
          selectedGraphLineColor: '#888888',
          dragIconHeight: 20,
          dragIconWidth: 20,
          graphType: 'line',
          gridCount: 4,
          gridAlpha: 0.7,
          gridColor: '#888888',
          color: 'transparent',
          dragIcon: 'dragIconRoundSmall'
        };
      } else {
        this.set('scrollBarExists', false);
      }

      if (this.get('wrapLabel') > 0) {
        config.categoryAxis.ignoreAxisWidth = true;
        // Squish the margin a little more if the scroll bar is showing
        config.marginLeft = this.get('scrollBarExists') ? this.get('wrapLabel') - 7 : this.get('wrapLabel');
      }

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      // If we have changed the size from mobile to desktop or back, need to
      // re-create the chart to remove or show the scrollbar
      if (this.get('enableZoom') && !this.get('scrollBarExists') || !this.get('enableZoom') && this.get('scrollBarExists')) {
        this._createChart();
      }
    },
    zoomChart: function zoomChart() {
      var chart = this.get('_chart');
      var indexEnd = this.get('zoomIndexEnd');
      if (chart.dataProvider && chart.dataProvider.length > indexEnd) {
        chart.zoomToIndexes(this.get('zoomIndexStart'), indexEnd < chart.dataProvider.length - 1 ? indexEnd : chart.dataProvider.length - 1);
      }

      Ember.$('.amcharts-scrollbar-grip-left').parent().css('pointer-events', 'none');
      Ember.$('.amcharts-scrollbar-grip-right').parent().css('pointer-events', 'none');
    },
    _createChart: function _createChart() {
      var _this2 = this;

      var chart = this._super();

      var data = this.get('data');
      if (!data || data.length <= 0) {
        return;
      }

      if (chart) {
        if (this.get('enableZoom')) {
          this.zoomChart();
        }

        // Hack to style the negative labels as red and bold
        var element = this.get('element');
        this._styleNegativeLabels(element);
        chart.addListener('zoomed', function () {
          return _this2._styleNegativeLabels(element);
        });
      }
    },


    dataFieldsChanged: Ember.observer('dataFields.[]', function () {
      this._createChart();
    }),

    _styleNegativeLabels: function _styleNegativeLabels(element) {
      var $labels = Ember.$(element).find('.amcharts-chart-div .amcharts-graph-label tspan');
      $labels.each(function (i, label) {
        if (label.textContent.includes('(') && label.textContent.includes(')')) {
          Ember.$(label).css({
            'font-weight': 'bold',
            'fill': '#f84d27'
          });
        }
      });
    }
  });
});