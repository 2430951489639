define('ux-pcc/controllers/customer/performance/market', ['exports', 'lodash', 'accounting', 'moment', 'ux-components/utils/format-currency', 'ux-pcc/mixins/customer/performance/span-selectable'], function (exports, _lodash, _accounting, _moment, _formatCurrency, _spanSelectable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_spanSelectable.default, {
    needs: ['customer'],

    endDate: Ember.computed('beginDate', function () {
      var tz = this.tz.get('customer');
      return _moment.default.tz(this.get('beginDate'), tz).add(6, 'days');
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    }),

    changedMarketData: Ember.observer('model', 'i18n.locale', function () {
      var _this = this;

      var currencyFormatOptions = { hash: { hideSymbol: true } };
      var tz = this.tz.get('customer');
      var entries = this.model || [];

      var currentWeekData = entries.filter(function (item) {
        return Ember.get(item, 'groupByValue').indexOf('Current Week') === 0;
      });

      var fourWeekData = entries.filter(function (item) {
        return Ember.get(item, 'groupByValue').indexOf('Running 4 Week') === 0;
      });

      var tableCellHTML = (0, _lodash.template)('<div class=\'market-benchmarks-table-cell\'>\n        <div class=\'market-benchmarks-table-cell__value\'>\n          <%= (data.value) ? data.value : \'&mdash;\' %>\n        </div>\n        <div class=\'market-benchmarks-table-cell__change <%= (data.change < 0.00) ? \'market-benchmarks-table-cell__change--negative\' : \'\'%>\'>\n          <%= (data.change) ? data.change : \'&mdash;\' %>\n        </div>\n      </div>', { 'variable': 'data' });

      var createRow = function createRow(label) {
        return {
          label: label,
          values: {
            total: { value: tableCellHTML({}) },
            sunday: { value: tableCellHTML({}) },
            monday: { value: tableCellHTML({}) },
            tuesday: { value: tableCellHTML({}) },
            wednesday: { value: tableCellHTML({}) },
            thursday: { value: tableCellHTML({}) },
            friday: { value: tableCellHTML({}) },
            saturday: { value: tableCellHTML({}) }
          }
        };
      };

      var createGroup = function createGroup(data) {
        var occGroup = {
          label: _this.i18n.t('customer.performance.market.options.occupancy'),
          labelAsCell: true,
          rows: [createRow(_this.i18n.t('customer.performance.market.options.property') + ' (%)'), createRow(_this.i18n.t('customer.performance.market.options.comp_set') + ' (%)'), createRow(_this.i18n.t('customer.performance.market.options.index_mpi'))]
        };
        var adrGroup = {
          label: _this.i18n.t('customer.performance.market.options.adr'),
          labelAsCell: true,
          classNames: 'hidden',
          rows: [createRow(_this.i18n.t('customer.performance.market.options.property') + ' ($)'), createRow(_this.i18n.t('customer.performance.market.options.comp_set') + ' ($)'), createRow(_this.i18n.t('customer.performance.market.options.index_ari'))]
        };

        var revParGroup = {
          label: _this.i18n.t('customer.performance.market.options.revpar'),
          labelAsCell: true,
          classNames: 'hidden',
          rows: [createRow(_this.i18n.t('customer.performance.market.options.property') + ' ($)'), createRow(_this.i18n.t('customer.performance.market.options.comp_set') + ' ($)'), createRow(_this.i18n.t('customer.performance.market.options.index_ari'))]
        };

        var currentWeek = [occGroup, adrGroup, revParGroup];

        data.forEach(function (e) {
          var data = Ember.get(e, 'dataValues');

          var groupByValue = Ember.get(e, 'groupByValue').replace(/\+/g, ' ');
          var groupByValues = groupByValue.split(',');

          var analyticDow = '';

          if (groupByValues.length > 1) {
            analyticDow = groupByValues[1].toLowerCase();
          } else {
            return;
          }

          var dataGroup = void 0;

          switch (Ember.get(e, 'subContentType')) {
            case 'OCCUPANCY':
              dataGroup = occGroup;
              break;
            case 'ADR':
              dataGroup = adrGroup;
              break;
            case 'REVPAR':
              dataGroup = revParGroup;
              break;
          }

          data.forEach(function (c) {
            // ignore unnecessary data
            if (c.key.indexOf('_change') > 0 || c.key.indexOf('_ty') > 0 || c.key.indexOf('_ly') > 0) {
              return;
            }

            var rowIndex = 0;
            switch (c.key) {
              case 'my_property':
                rowIndex = 0;
                break;
              case 'competitor':
                rowIndex = 1;
                break;
              case 'index':
                rowIndex = 2;
                break;
            }

            var changeIndex = (0, _lodash.findIndex)(data, { key: c.key + '_change' });
            var change = data[changeIndex].value;

            var value = void 0;
            if (isNaN(c.value)) {
              value = _this.i18n.t('customer.performance.market.table.not_available');
              change = _this.i18n.t('customer.performance.market.table.not_available');
            } else if ((Ember.get(e, 'subContentType') === 'ADR' || Ember.get(e, 'subContentType') === 'REVPAR') && (c.key === 'my_property' || c.key === 'competitor')) {
              value = (0, _formatCurrency.default)(c.value, 'USD', currencyFormatOptions);
              change = _accounting.default.toFixed(change, 2);
            } else {
              value = _accounting.default.toFixed(c.value, 1);
              change = _accounting.default.toFixed(change, 1);
            }

            dataGroup.rows[rowIndex].values[analyticDow].value = tableCellHTML({ value: value, change: change });
          });
        });

        return currentWeek;
      };

      var columnHeaderLabel = function columnHeaderLabel(increment) {
        var date = _moment.default.tz(_this.get('beginDate'), tz).add(increment, 'days');
        var formattedDate = date.format('L');
        formattedDate = formattedDate.substring(0, formattedDate.length - 4) + formattedDate.substring(formattedDate.length - 2);
        return '<span class=\'market-benchmarks-table-header__dow\'>' + date.format('ddd') + '</span>, ' + formattedDate + '\n                <div class=\'market-benchmarks-table-header__change\'>% Chg</div>';
      };

      var columns = [{ key: 'total', label: 'Total <div class=\'market-benchmarks-table-header__change\'>% Chg</div>' }, { key: 'sunday', label: columnHeaderLabel(0), classNames: ['market-benchmarks-table-header'] }, { key: 'monday', label: columnHeaderLabel(1), classNames: ['market-benchmarks-table-header'] }, { key: 'tuesday', label: columnHeaderLabel(2), classNames: ['market-benchmarks-table-header'] }, { key: 'wednesday', label: columnHeaderLabel(3), classNames: ['market-benchmarks-table-header'] }, { key: 'thursday', label: columnHeaderLabel(4), classNames: ['market-benchmarks-table-header'] }, { key: 'friday', label: columnHeaderLabel(5), classNames: ['market-benchmarks-table-header'] }, { key: 'saturday', label: columnHeaderLabel(6), classNames: ['market-benchmarks-table-header'] }];

      this.set('marketCurrentTableData', {
        groups: createGroup(currentWeekData),
        columns: columns
      });

      this.set('marketFourWeekTableData', {
        groups: createGroup(fourWeekData),
        columns: columns
      });
    })
  });
});