define('ux-pcc/components/menus/base-menu-item', ['exports', 'ux-components/components/menus/base-menu-item'], function (exports, _baseMenuItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _baseMenuItem.default;
    }
  });
});