define('ux-pcc/components/dashboard-widget/booking-trends-arrival-day', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_fileSaver.default, {

    classNames: ['widget-box', 'booking-trends-arrival-day'],

    ajax: Ember.inject.service(),

    childStartIndex: -1,
    childEndIndex: -1,
    data: null,
    isMobile: Ember.computed.bool('deviceType.mobile'),
    isTablet: Ember.computed.bool('deviceType.tablet'),
    isDesktop: Ember.computed.bool('deviceType.desktop'),
    loading: false,
    model: null,
    selectedDatesChanged: false,
    showComments: true,
    showCancels: false,
    sliceResolution: 'day',
    tz: null,
    zoomInfo: null,

    tableColumnLimit: Ember.computed('isMobile', 'isTablet', 'isDesktop', function () {
      if (this.get('isDesktop')) {
        return 14;
      }
      if (this.get('isTablet')) {
        return 7;
      }
      return 0;
    }),

    zoomIndexEnd: Ember.computed('isMobile', function () {
      return this.get('isMobile') ? 2 : 6;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
      Ember.run.once(this, 'queryDataMethod');
    },


    currencyCode: Ember.computed.readOnly('dataProvider.firstObject.currencyCode'),

    bookedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BOOK_DATE'),

    selectedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BEGIN_DATE'),

    selectedEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.END_DATE'),

    selectedStlyDateParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_BEGIN_DATE'),

    selectedStlyEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_END_DATE'),

    // Booking trend by arrival day
    dataProvider: Ember.computed('data', function () {
      var tz = this.get('tz');
      var mapped = this.get('data');
      if (!mapped) {
        return null;
      }

      var dateSort = function dateSort(o1, o2) {
        var date1 = _moment.default.tz(o1, tz);
        var date2 = _moment.default.tz(o2, tz);
        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
      };

      return Object.keys(mapped).sort(dateSort).map(function (key) {
        return mapped[key];
      });
    }),

    // if including a table with chart, styling is needed to keep columns in sync
    tableConfig: Ember.computed('dataProvider.length', function () {
      return {
        columnWidth: 0.5, // width of columns relative to space allotted per data point
        autoMargins: false,
        marginLeft: 120, // must match margin-left on the table (yield-manager.less)
        marginRight: 80, // must match padding-right on the table (yield-manager.less)
        marginBottom: this.get('dataProvider.length') > 7 ? 10 : 50
      };
    }),

    scrollBarConfig: Ember.computed('isMobile', 'dataProvider.length', function () {
      var dataLength = this.get('dataProvider.length');
      var gridCount = dataLength < 20 ? dataLength : dataLength / 2 < 20 ? dataLength / 2 : 20;
      return {
        oppositeAxis: false,
        offset: 50,
        scrollbarHeight: 40,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.2,
        selectedBackgroundColor: '#888888',
        dragIconHeight: 20,
        dragIconWidth: 20,
        autoGridCount: false,
        gridCount: this.get('isMobile') ? 3 : gridCount,
        color: '#AAAAAA',
        dragIcon: 'dragIconRoundSmall',
        resizeEnabled: !this.get('isMobile') // Allow adjustment of the graph display period if not mobile
      };
    }),

    dataFields: Ember.computed('currencyCode', function () {
      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();
      return [Ember.Object.create({
        leftAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.net_reservations'),
        rightAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.net_days'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-arrival-day.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-arrival-day.res_count_run_days') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'currencyCode', 'count', 'duration', 'amount', 'arrivalYield', 'yield', 'stlwPctTotalVariance', 'stlyPctTotalVariance', 'stlwPctCountVariance', 'stlyPctCountVariance'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'count',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.res_count'),
          format: 'number',
          lineClasses: 'reservations',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'duration',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_days'),
          format: 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }, {
          key: 'amount',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_revenue'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          enabled: true,
          category: ''
        }, {
          key: 'arrivalYield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true,
          category: ''
        }, {
          key: 'yield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true,
          category: ''
        }]
      }), Ember.Object.create({
        leftAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.var_reservations'),
        rightAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.var_days'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-arrival-day.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-arrival-day.var') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'currencyCode', 'count', 'duration', 'amount', 'arrivalYield', 'yield', 'stlwPctTotalVariance', 'stlyPctTotalVariance', 'stlwPctCountVariance', 'stlyPctCountVariance'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'stlwPctTotalVariance', // usage numbers
          label: this.i18n.t('yield-manager.booking-trends.var_days_pw', { timeframe: this.get("model.view.dashboardViewParameters.CP1_LABEL") }),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: 'stlyPctTotalVariance',
          label: this.i18n.t('yield-manager.booking-trends.var_days_py', { timeframe: this.get("model.view.dashboardViewParameters.CP2_LABEL") }),
          format: 'percent',
          lineClasses: 'days-compare',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'right'
        }, {
          key: 'stlwPctCountVariance', //count numbers
          label: this.i18n.t('yield-manager.booking-trends.var_res_pw', { timeframe: this.get("model.view.dashboardViewParameters.CP1_LABEL") }),
          format: 'percent',
          lineClasses: 'reservations',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'stlyPctCountVariance',
          label: this.i18n.t('yield-manager.booking-trends.var_res_py', { timeframe: this.get("model.view.dashboardViewParameters.CP2_LABEL") }),
          format: 'percent',
          lineClasses: 'reservations-compare',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--reservations-compare',
          enabled: true,
          category: 'right'
        }]
      })];
    }),

    isWeekSlice: Ember.computed.equal('sliceResolution', 'week'),

    _fireRequeryObserver: Ember.observer('model.view', 'sliceResolution', function () {
      Ember.run.once(this, 'queryDataMethod');
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var tz = this.get('tz');
      var data = JSON.parse(JSON.stringify(this.get('model.view').serialize()));
      var startDate = _moment.default.tz(this.get('selectedDateParam'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz).startOf('day');
      this.set('startDate', startDate);
      this.set('endDate', endDate);
      this.set('data', null);
      this.set('childStartIndex', -1);
      this.set('childEndIndex', -1);
      this.set('zoomInfo', null);

      // set groupBy to BEGIN_DATE
      Ember.set(data.dashboardViewParameters, 'GROUP_BY', 'BEGIN_DATE');

      // set viewBy to either WEEK or DAY depending on toggle value
      Ember.set(data.dashboardViewParameters, 'VIEW_BY', this.get('isWeekSlice') ? 'WEEK' : 'DAY');

      var params = {
        aggregationType: 'BOOKING_ARRIVAL_AGGREGATOR',
        aggregate: false,
        rollupStatus: 'ROLLUP'
      };

      var requests = [this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(data, tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      this.set('loading', true);
      return Ember.RSVP.all(requests, 'YSGA Data').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            entries = _ref3$ === undefined ? [] : _ref3$;

        var mapped = {};
        if (!entries || entries.length < 1) {
          _this.set('data', []);
          return;
        }

        var settings = _this.get('customer.settings');
        entries.forEach(function (e, index) {
          if (e.subContentType !== 'FULL_SPAN') {
            var variable = e.dataValues['date'];
            var date = (0, _moment.default)(variable);
            var key = date.format();

            mapped[key] = e.dataValues;
            //add the slice
            mapped[key]['slice'] = key;
            mapped[key]['formattedDate'] = (0, _dateFormatter.dateFormatter)(date, { settings: settings });
            mapped[key]['currencyCode'] = e.currencyCode;
            mapped[key]['dow'] = date.format('ddd');
            if (_this.get('isWeekSlice')) {
              mapped[key]['sliceFormat'] = (0, _dateFormatter.dateFormatter)(date, { year: false, returnFormat: true, settings: settings }) + '[<br> WEEK ' + (index + 1) + ']';
            }
          }
        });

        _this.set('data', mapped);
        if (entries.length <= 8) {
          var _tz = _this.get('tz');
          _this.set('childStartIndex', 0);
          _this.set('childEndIndex', entries.length - 1);
          _this.send('syncCharts', _moment.default.tz(entries[0].dataValues['date'], _tz).startOf(_this.get('sliceResolution')), _moment.default.tz(entries[entries.length - 1].dataValues['date'], _tz).endOf(_this.get('sliceResolution')));
        }
      }).catch(function () {
        _this.set('data', []);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    tableRows: Ember.computed('i18n.locale', 'model.gross', 'currencyCode', function () {
      return [{
        valueField: 'count',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.res'),
        format: 'number'
      }, {
        valueField: 'duration',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.days'),
        format: 'number'
      }, {
        valueField: 'arrivalYield',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        valueField: 'yield',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }];
    }),

    _zoomedDateObserver: Ember.observer('zoomInfo.startIndex', 'zoomInfo.endIndex', function () {
      var zoomInfo = this.get('zoomInfo');
      if (zoomInfo) {
        if (this.get('childStartIndex') < 0) {
          this.send('syncCharts');
        } else if (this.get('childStartIndex') !== this.get('zoomInfo.startIndex') || this.get('childEndIndex') !== this.get('zoomInfo.endIndex')) {
          this.set('selectedDatesChanged', true);
        }
      }
    }),

    _busyObserver: Ember.observer('ajax.busy', function () {
      this.set('childLoading', this.get('ajax.busy'));
    }),

    actions: {
      setZoomInfo: function setZoomInfo(zoomInfo) {
        var _this2 = this;

        Ember.run.scheduleOnce('afterRender', this, function () {
          return _this2.set('zoomInfo', zoomInfo);
        });
      },
      syncCharts: function syncCharts(startDate, endDate) {
        this.set('childLoading', true);
        // Set the dates for the child charts to go off of
        this.set('selectedDatesChanged', false);
        this.get('updateSlice')(this.get('sliceResolution'));
        var tz = this.get('tz');

        if (!startDate && !endDate) {
          // Default to the selected start and end dates
          startDate = _moment.default.tz(this.get('selectedDateParam'), tz);
          endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz);

          var zoomInfo = this.get('zoomInfo');
          if (zoomInfo) {
            this.set('childStartIndex', zoomInfo.startIndex);
            this.set('childEndIndex', zoomInfo.endIndex);
            startDate = _moment.default.tz(zoomInfo.chart.chartData[zoomInfo.startIndex].dataContext['date'], tz).startOf(this.get('sliceResolution'));
            endDate = _moment.default.tz(zoomInfo.chart.chartData[zoomInfo.endIndex].dataContext['date'], tz).endOf(this.get('sliceResolution'));
          } else if (this.get('isWeekSlice')) {
            // set the dates to the beginning and ending of the weeks
            startDate.startOf(this.get('sliceResolution'));
            endDate.endOf(this.get('sliceResolution'));
          }
        }

        // startDate can't be before the currently selected arrival start date
        if (startDate.isBefore(_moment.default.tz(this.get('selectedDateParam'), tz), 'days')) {
          startDate = _moment.default.tz(this.get('selectedDateParam'), tz).startOf('day');
        }
        // endDate can't be after than the currently selected arrival end date
        if (endDate.isAfter(_moment.default.tz(this.get('selectedEndDateParam'), tz), 'days')) {
          endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz).endOf('day');
        }

        this.get('updateZoom')(startDate, endDate);
      }
    }
  });
});