define('ux-pcc/controllers/rules-advanced/edit', ['exports', 'moment', 'ux-pcc/mixins/rules-advanced-form-controller'], function (exports, _moment, _rulesAdvancedFormController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_rulesAdvancedFormController.default, {

    queryParams: ['childRuleId'],
    childRuleId: null,
    successMessage: 'rule._form._success_modal.success_update_message',
    ruleNameLabel: 'rule._form.name',
    ruleDescriptionLabel: 'rule._form.description',
    disableTypeSelection: true,

    isEdit: false,

    ruleHeaderMessage: Ember.computed('i18n.locale', 'model.{isNew,shortDescription}', function () {
      if (this.get('model.isNew')) {
        return this.i18n.t('rule._form_modal.rule_header_message', {
          ruleType: this.get('model.shortDescription'),
          htmlSafe: true
        });
      } else {
        return this.i18n.t('rule._form_modal.edit_rule_header_message', {
          ruleType: this.get('model.shortDescription'),
          htmlSafe: true
        });
      }
    }),

    headerMessage: Ember.computed('model.{beginDate,endDate}', function () {
      var tz = this.tz.get("customer");
      var beginDate = _moment.default.tz(this.get('model.beginDate'), tz);
      if (this.get('model.endDate')) {
        var endDate = _moment.default.tz(this.get('model.endDate'), tz);
        return this.i18n.t('rule._form_modal.condition_dates', {
          startDayOfWeek: beginDate.format("dddd"),
          startDate: beginDate.format("l"),
          endDayOfWeek: endDate.format("dddd"),
          endDate: endDate.format("l")
        });
      } else {
        return this.i18n.t('rule._form_modal.condition_dates_ongoing', {
          startDayOfWeek: beginDate.format("dddd"),
          startDate: beginDate.format("l")
        });
      }
    }),

    childRuleChanged: Ember.on('init', Ember.observer('childRuleId', function () {
      var _this = this;

      if (this.get('childRuleId')) {
        this.store.findRecord('rule', this.get('childRuleId')).then(function (rule) {
          if (rule) {
            _this.send('editRule', rule);
          }
        });
      }
    }))
  });
});