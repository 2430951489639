define('ux-pcc/initializers/auth', ['exports', 'ember-simple-auth/configuration', 'ux-data/authenticators/token', 'ux-data/authenticators/saml2', 'ux-data/authenticators/callback', 'ux-data/authenticators/firebase'], function (exports, _configuration, _token, _saml, _callback, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    application.register('authenticator:saml2', _saml.default);

    application.register('authenticator:callback', _callback.default);

    application.register('authenticator:firebase', _firebase.default);

    // the token authenticator currently must be registered second to be used as a default
    application.register('authenticator:token', _token.default);

    _configuration.default.localStorageKey = "session-simple-above";
  }

  exports.default = {
    name: 'auth',
    initialize: initialize
  };
});