define('ux-pcc/components/yield-manager/views/yicd-view', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/components/yield-manager/views/base-view', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _baseView, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _baseView.default.extend(_fileSaver.default, {

    layoutName: 'components/yield-manager/views/base-view',

    leadColumnTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.book_date_column');
    }),

    sliceResolution: 'day',

    // define the charts to be displayed
    dataFields: Ember.computed('data', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');

      return [Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.days'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.analyze.cards.charge_days') + (displayPercentages ? ' %' : ''),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-days.added_title') + (displayPercentages ? ' %' : ''),
        values: [{
          key: displayPercentages ? 'pctTotalIncreaseUsageMinusNoShows' : 'increaseUsageMinusNoShows',
          label: '' + this.get('i18n').t('yield-manager.view.add_current_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? 'pctTotalStlyIncreaseUsageMinusNoShows' : 'stlyIncreaseUsageMinusNoShows',
          label: '' + this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? 'pctTotalUsageMinusNoShows' : 'usageMinusNoShows',
          label: '' + this.get('i18n').t('yield-manager.view.current_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left',
          shade: true
        }, {
          key: displayPercentages ? 'pctTotalStlyUsageMinusNoShows' : 'stlyUsageMinusNoShows',
          label: '' + this.get('i18n').t('yield-manager.view.prior_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'left',
          shade: true
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.yield'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.yield'),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-yield.added_title'),
        values: [{
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.view.add_current_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield-compare',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield-compare',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: '' + this.get('i18n').t('yield-manager.view.current_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left',
          shade: true
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: '' + this.get('i18n').t('yield-manager.view.prior_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield-compare',
          enabled: true,
          category: 'left',
          shade: true
        }]
      }), Ember.Object.create({
        label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') && !displayPercentages ? '(' + this.get('currencyCode') + ')' : ''),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.revenue') + (displayPercentages ? ' %' : ''),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-revenue.added_title') + (displayPercentages ? ' %' : ''),
        values: [{
          key: displayPercentages ? grossFlag ? 'pctTotalIncreaseGross' : 'pctTotalIncreaseBase' : grossFlag ? 'increaseGross' : 'increaseBase',
          label: '' + this.get('i18n').t('yield-manager.view.add_current_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalStlyIncreaseGross' : 'pctTotalStlyIncreaseBase' : grossFlag ? 'stlyIncreaseGross' : 'stlyIncreaseBase',
          label: '' + this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue-compare',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalGross' : 'pctTotalBase' : grossFlag ? 'gross' : 'base',
          label: '' + this.get('i18n').t('yield-manager.view.current_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left',
          shade: true
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalStlyGross' : 'pctTotalStlyBase' : grossFlag ? 'stlyGross' : 'stlyBase',
          label: '' + this.get('i18n').t('yield-manager.view.prior_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue-compare',
          enabled: true,
          category: 'left',
          shade: true
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        values: [{
          key: 'pctChangeUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.days_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.days_variance'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.yield_variance'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
          label: this.get('i18n').t('yield-manager.view.revenue_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.revenue_variance'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.rate_of_add'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.rate_of_add'),
        values: [{
          key: 'pctChangeRoaUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.days_roa'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeRoaGrossYield' : 'pctChangeRoaBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_roa'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeRoaGross' : 'pctChangeRoaBase',
          label: this.get('i18n').t('yield-manager.view.revenue_roa'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }]
      })];
    }),

    currentColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');
      if (displayPercentages) {
        return [{
          key: 'pctTotalUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'pctTotalGross' : 'pctTotalBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' %',
          format: 'percent'
        }, {
          key: 'pctTotalIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      } else {
        return [{
          key: 'usageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'gross' : 'base',
          label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions')
        }, {
          key: 'increaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      }
    }),

    stlyColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');
      if (displayPercentages) {
        return [{
          key: 'pctTotalStlyUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'pctTotalStlyGross' : 'pctTotalStlyBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' %',
          format: 'percent'
        }, {
          key: 'pctTotalStlyIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + ' %</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      } else {
        return [{
          key: 'stlyUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'stlyGross' : 'stlyBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions')
        }, {
          key: 'stlyIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      }
    }),

    percentDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'pctChangeUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'percent',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>% ' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'percent'
      }, {
        key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue'),
        format: 'percent'
      }];
    }),

    actualDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'deltaUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        key: grossFlag ? 'deltaGross' : 'deltaBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
        format: 'currency',
        formatHash: this.get('formatRevenueOptions')
      }];
    }),

    roaDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'pctChangeRoaUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'percent',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>% ' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'pctChangeRoaGrossYield' : 'pctChangeRoaBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'percent'
      }, {
        key: grossFlag ? 'pctChangeRoaGross' : 'pctChangeRoaBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue'),
        format: 'percent'
      }];
    }),

    currentRunDate: Ember.computed('tableData', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      var startDate = (0, _dateFormatter.dateFormatter)(this.get('selectedDateParam'), { tz: tz, settings: settings });
      return this.get('i18n').t('yield-manager.run_date', { runDate: startDate });
    }),

    stlyRunDate: Ember.computed('stlyData', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      var startDate = (0, _dateFormatter.dateFormatter)(this.get('selectedStlyDateParam'), { tz: tz, settings: settings });
      return this.get('i18n').t('yield-manager.run_date', { runDate: startDate });
    }),

    percentDeltaTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.date_span') + ' % ' + this.get('i18n').t('yield-manager.change');
    }),

    actualDeltaTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.actual') + ' ' + this.get('i18n').t('yield-manager.change');
    }),

    roaDeltaTitle: Ember.computed(function () {
      return '' + this.get('i18n').t('yield-manager.rate_of_add');
    }),

    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      // TODO: swap to default to V2 when that service is ready
      return version === '2' ? 'DAILY_PACING_ACCUMULATION_COMPARISON_V2' : 'DAILY_PACING_ACCUMULATION_COMPARISON';
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customerID');
      var tz = this.get('tz');
      var today = _moment.default.tz((0, _moment.default)(), tz);
      var selectedDate = _moment.default.tz(this.get('selectedDateParam'), tz).startOf('day');
      var selectedSTLYDate = _moment.default.tz(this.get('selectedStlyDateParam'), tz).startOf('day');

      var endDate = selectedDate.clone();
      var startDate = endDate.clone().subtract(30, 'days');

      // hard code asOf date to 120 days prior to selected date
      var asOfStartDate = selectedDate.clone().subtract(30, 'days');

      this.set('startDate', startDate);
      this.set('endDate', endDate);
      this.set('asOfStartDate', asOfStartDate);

      this.set('data', null);

      // Need to switch the dates around without modifying the values in the filter
      // so copy the filter, and swap the begin and end dates
      var modifiedView = JSON.parse(JSON.stringify(this.get('model.view').serialize()));

      var dateParam = modifiedView.dashboardViewParameters['BEGIN_DATE'];
      if (dateParam) {
        Ember.set(modifiedView.dashboardViewParameters, 'BEGIN_DATE', startDate);
      }

      dateParam = modifiedView.dashboardViewParameters['END_DATE'];
      if (dateParam) {
        Ember.set(modifiedView.dashboardViewParameters, 'END_DATE', endDate);
      }

      dateParam = modifiedView.dashboardViewParameters['STLY_BEGIN_DATE'];
      if (dateParam) {
        Ember.set(modifiedView.dashboardViewParameters, 'STLY_BEGIN_DATE', selectedSTLYDate.clone().subtract(30, 'days'));
      }

      dateParam = modifiedView.dashboardViewParameters['STLY_END_DATE'];
      if (dateParam) {
        Ember.set(modifiedView.dashboardViewParameters, 'STLY_END_DATE', selectedSTLYDate);
      }

      this.set('modifiedView', modifiedView);

      var params = {
        customerid: customerID,
        begindateStr: startDate.format('YYYY-MM-DD'),
        enddateStr: endDate.format('YYYY-MM-DD'),
        asOfDateBeginStr: asOfStartDate.format('YYYY-MM-DD'),
        asOfDateEndStr: endDate.format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        aggregate: false,
        groupBy: 'ALL'
      };

      var requests = [this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(modifiedView, tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'YICD Data').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            entries = _ref3$ === undefined ? [] : _ref3$;

        var mapped = {};
        var dataRows = [];
        var stlyDataRows = [];

        if (!entries || entries.length < 1) {
          _this.set('data', []);
          _this.set('tableData', []);
          _this.set('stlyData', []);
          return;
        }

        entries = entries.rejectBy('subContentType', 'FULL_SPAN');

        var settings = _this.get('customer.settings');
        entries.forEach(function (e) {
          var date = (0, _moment.default)(e.dataValues['date']);
          var key = date.format();

          mapped[key] = e.dataValues;
          //add the slice
          mapped[key]['slice'] = key;
          mapped[key]['currencyCode'] = e.currencyCode;
          mapped[key]['dow'] = date.format('ddd');

          // Need 'copies' so we can set amounts
          var rowValues = Ember.merge({}, mapped[key]);
          var stlyValues = Ember.merge({}, mapped[key]);
          stlyValues['dow'] = (0, _moment.default)(e.dataValues['stlyDate']).format('ddd');

          // Populate the rows for each table.  Will reuse dataRows for the delta values
          dataRows.push({ label: (0, _dateFormatter.dateFormatter)(date, { settings: settings }), values: rowValues });
          stlyDataRows.push({ label: (0, _dateFormatter.dateFormatter)(e.dataValues['stlyDate'], { settings: settings }), values: stlyValues });

          // Strip out the CY values from the graph if this row is in the future
          if (date.isAfter(today, 'day')) {
            delete mapped[key].usageMinusNoShows;
            delete mapped[key].increaseUsageMinusNoShows;
            delete mapped[key].baseYield;
            delete mapped[key].increaseBaseYield;
            delete mapped[key].grossYield;
            delete mapped[key].increaseGrossYield;
            delete mapped[key].base;
            delete mapped[key].increaseBase;
            delete mapped[key].gross;
            delete mapped[key].increaseGross;

            // remove the variance and roa values as well
            delete mapped[key].pctChangeUsageMinusNoShows;
            delete mapped[key].pctChangeGrossYield;
            delete mapped[key].pctChangeBaseYield;
            delete mapped[key].pctChangeGross;
            delete mapped[key].pctChangeBase;
            delete mapped[key].pctChangeRoaUsageMinusNoShows;
            delete mapped[key].pctChangeRoaGrossYield;
            delete mapped[key].pctChangeRoaBaseYield;
            delete mapped[key].pctChangeRoaGross;
            delete mapped[key].pctChangeRoaBase;
          }
        });

        var reverseDateSort = function reverseDateSort(o1, o2) {
          var date1 = (0, _moment.default)(o1.values.date);
          var date2 = (0, _moment.default)(o2.values.date);

          return date1.isBefore(date2) ? 1 : date1.isAfter(date2) ? -1 : 0;
        };

        dataRows.sort(reverseDateSort);
        stlyDataRows.sort(reverseDateSort);

        _this.set('data', mapped);
        _this.set('tableData', dataRows);
        _this.set('stlyData', stlyDataRows);
      }).finally(function () {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('loading', false);
        }
      });
    },


    actions: {
      exportCsv: function exportCsv() {
        var _this2 = this;

        this.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('yield-manager.message.download_requested'), 'success');
        var tz = this.get('tz');
        var customerID = this.get('customerID');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var asOfStartDate = this.get('asOfStartDate');

        var settings = this.get('customer.settings');
        var filename = this.get('i18n').t('yield-manager.file-prefix.yicd') + '_' + this.get('customerExternalReference') + '_' + (0, _dateFormatter.dateFormatter)(startDate, { settings: settings }) + '_' + (0, _dateFormatter.dateFormatter)(endDate, { settings: settings }) + '.csv';

        var params = {
          customerid: customerID,
          begindateStr: startDate.format('YYYY-MM-DD'),
          enddateStr: endDate.format('YYYY-MM-DD'),
          asOfDateBeginStr: asOfStartDate.format('YYYY-MM-DD'),
          asOfDateEndStr: endDate.format('YYYY-MM-DD'),
          aggregationType: this.get('aggregationType'),
          rollupStatus: this.get('rollupStatus'),
          aggregate: false,
          groupBy: 'ALL'
        };

        this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          headers: { accept: 'text/csv' },
          contentType: 'application/json',
          dataType: 'blob',
          data: (0, _serializeDashboardView.default)(this.get('modifiedView'), tz)
        }).then(function (data) {
          return _this2.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          return _this2.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this2.get('i18n').t('yield-manager.message.download_error'), 'danger');
        });
      }
    }
  });
});