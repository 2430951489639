define('ux-pcc/components/dashboard-widget/overview-detail', ['exports', 'accounting', 'moment', 'ux-components/utils/stringify-query-params'], function (exports, _accounting, _moment, _stringifyQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    isMobile: Ember.computed.bool('deviceType.mobile'),
    isDesktop: Ember.computed.bool('deviceType.desktop'),
    isNonDesktop: Ember.computed.not('isDesktop'),
    isNonMobile: Ember.computed.not('isMobile'),

    currencyCode: 'USD',
    rollupStatus: 'DETAIL',
    forecastModelsSetting: '',

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchData();
    },


    _fetchData: Ember.observer('customer.id', 'selectedDate', 'showInventorySegments', 'rollupProductIds.[]', function () {
      var _this = this;

      var customerID = this.customer.id;
      var tz = this.tz.customer;
      var selectedDate = _moment.default.tz(this.selectedDate, tz).startOf('day');
      var adrEndDate = selectedDate.clone().endOf('day').format();
      var stly = selectedDate.clone().subtract(52, 'weeks');
      var forecastModelsSetting = this.forecastModelsSetting;

      this.set('loading', true);

      var promises = {};

      if (this.showInventorySegments) {
        promises.overviewCollection = this.get('ajax').request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'REVENUE', // Only need the revenue analytics, as we will use the sold count for OCCUPANCY numbers
            analytictype: 'COUNT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'ROLLUP',
            groupby: 'PRODUCT',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;
          return analyticEntries;
        });

        promises.overviewCollectionSTLY = this.get('ajax').request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: stly.format(),
            enddate: stly.format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'REVENUE', // Only need the revenue analytics, as we will use the sold count for OCCUPANCY numbers
            analytictype: 'COUNT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'ROLLUP',
            groupby: 'PRODUCT',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
          }
        }).then(function (_ref2) {
          var _ref2$analyticEntries = _ref2.analyticEntries,
              analyticEntries = _ref2$analyticEntries === undefined ? [] : _ref2$analyticEntries;
          return analyticEntries;
        });

        promises.rollup = this.get('ajax').request('/rest/v1/pms/dailyMetrics?ids[]=' + Ember.get(this, 'rollupProductIds').join('&ids[]='), {
          data: {
            customerID: customerID,
            begindate: selectedDate.format('YYYY-MM-DD'),
            enddate: selectedDate.format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref3) {
          var _ref3$pMSDailyMetrics = _ref3.pMSDailyMetrics,
              pMSDailyMetrics = _ref3$pMSDailyMetrics === undefined ? [] : _ref3$pMSDailyMetrics;
          return pMSDailyMetrics;
        });

        promises.rollupStly = this.get('ajax').request('/rest/v1/pms/dailyMetrics?ids[]=' + Ember.get(this, 'rollupProductIds').join('&ids[]='), {
          data: {
            customerID: customerID,
            begindate: stly.format('YYYY-MM-DD'),
            enddate: stly.format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref4) {
          var _ref4$pMSDailyMetrics = _ref4.pMSDailyMetrics,
              pMSDailyMetrics = _ref4$pMSDailyMetrics === undefined ? [] : _ref4$pMSDailyMetrics;
          return pMSDailyMetrics;
        });
      } else {
        promises.overviewCollection = this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'ARRIVAL,OCCUPANCY,ADR,REVPAR,DEPARTURE,REVENUE,INVENTORY' + (forecastModelsSetting ? ',OCCUPANCY_FORECAST' : ''),
            analytictype: 'COUNT,AMOUNT,PERCENT',
            subcontenttype: 'SOCIAL,PHYSICAL,YIELDABLE,OVERSELL,DO_NOT_SELL,CONSUMED,MANAGER_HOLD,OUT_OF_ORDER' + (forecastModelsSetting ? ',' + forecastModelsSetting.get('settingValue') : ''),
            action: 'STATISTIC',
            datatype: 'VALUE,REVENUE',
            rollupstatus: this.rollupStatus
          }
        }).then(function (_ref5) {
          var _ref5$analyticEntries = _ref5.analyticEntries,
              analyticEntries = _ref5$analyticEntries === undefined ? [] : _ref5$analyticEntries;
          return analyticEntries;
        });

        promises.overviewCollectionSTLY = this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: stly.format(),
            enddate: stly.format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'OCCUPANCY,ADR,REVPAR,REVENUE',
            analytictype: 'COUNT,AMOUNT,PERCENT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE,REVENUE',
            rollupstatus: this.rollupStatus
          }
        }).then(function (_ref6) {
          var _ref6$analyticEntries = _ref6.analyticEntries,
              analyticEntries = _ref6$analyticEntries === undefined ? [] : _ref6$analyticEntries;
          return analyticEntries;
        });
      }

      var adrParams = {
        customerID: customerID,
        beginDate: selectedDate.format(),
        endDate: adrEndDate
      };

      promises.adrForecast = this.get('ajax').post('/rest/Forecast/ADR' + (0, _stringifyQueryParams.default)(adrParams)).then(function (_ref7) {
        var _ref7$adrForecasts = _ref7.adrForecasts,
            adrForecasts = _ref7$adrForecasts === undefined ? [] : _ref7$adrForecasts;
        return adrForecasts;
      });

      Ember.RSVP.hash(promises).then(function (contents) {
        return _this._computeData(contents);
      }).finally(function () {
        return _this.set('loading', false);
      });
    }),

    filteredProducts: Ember.computed('rollupProductIds.[]', 'products.[]', function () {
      var _this2 = this;

      // filter the product list down where the rollup product is in the path
      return (this.products || []).filter(function (p) {
        return (p.get('ancestors') || []).filter(function (a) {
          return _this2.rollupProductIds.includes(a.get('id'));
        }).length > 0;
      });
    }),

    _calcOccupancy: function _calcOccupancy(yieldable, sold) {
      return {
        currencyCode: this.currencyCode,
        dataValue: (0, _accounting.formatNumber)(yieldable !== 0 ? sold / yieldable * 100 : 0, 1)
      };
    },
    _calcAdr: function _calcAdr(sold, revenue) {
      return {
        currencyCode: this.currencyCode,
        dataValue: sold !== 0 ? revenue / sold : 0
      };
    },
    _calcRevPar: function _calcRevPar(yieldable, revenue) {
      return {
        currencyCode: this.currencyCode,
        dataValue: yieldable !== 0 ? revenue / yieldable : 0
      };
    },
    _calcRevenue: function _calcRevenue(analytics) {
      return {
        currencyCode: this.currencyCode,
        dataValue: analytics.map(function (d) {
          return parseFloat(d.dataValue);
        }).reduce(function (a, b) {
          return a + b;
        }, 0)
      };
    },
    _calcInventoryData: function _calcInventoryData(inventoryData, suffix) {
      return function (d) {
        inventoryData['sold' + suffix] += (d.scheduledArrivals || []).length + (d.stayOvers || []).length;
        inventoryData['inventory_yieldable' + suffix] += d.yieldable || 0;
      };
    },
    _computeData: function _computeData(model) {
      var _this3 = this;

      var stats = {};
      var tz = this.tz.customer;

      var overview = model.overviewCollection.toArray();
      var overviewStly = model.overviewCollectionSTLY.toArray();

      if (this.showInventorySegments) {
        var filteredProducts = Ember.get(this, 'filteredProducts');

        var inventoryData = {
          sold: 0,
          sold_stly: 0,
          inventory_yieldable: 0,
          inventory_yieldable_stly: 0
        };

        (model.rollup || []).forEach(this._calcInventoryData(inventoryData, ''));
        (model.rollupStly || []).forEach(this._calcInventoryData(inventoryData, '_stly'));

        // Current section
        var filteredAnalytics = overview.filter(function (a) {
          return filteredProducts.findBy('id', a.groupByValue);
        });
        stats.revenue = this._calcRevenue(filteredAnalytics);
        stats.revpar = this._calcRevPar(inventoryData.inventory_yieldable, stats.revenue.dataValue);
        stats.adr = this._calcAdr(inventoryData.sold, stats.revenue.dataValue);
        stats.occupancy = this._calcOccupancy(inventoryData.inventory_yieldable, inventoryData.sold);

        // STLY section
        var filteredAnalyticsStly = overviewStly.filter(function (a) {
          return filteredProducts.findBy('id', a.groupByValue);
        });
        stats.revenue_stly = this._calcRevenue(filteredAnalyticsStly);
        stats.revpar_stly = this._calcRevPar(inventoryData.inventory_yieldable, stats.revenue_stly.dataValue);
        stats.adr_stly = this._calcAdr(inventoryData.sold_stly, stats.revenue_stly.dataValue);
        stats.occupancy_stly = this._calcOccupancy(inventoryData.inventory_yieldable_stly, inventoryData.sold_stly);
      } else {
        overview.forEach(function (stat) {
          if (['OCCUPANCY', 'OCCUPANCY_FORECAST', 'INVENTORY', 'SELL_RATE', 'ADR', 'REVPAR'].includes(stat.contentType)) {
            stat.dataValue = parseFloat(stat.dataValue);
          }

          switch (stat.contentType) {
            case 'OCCUPANCY':
              if (stat.subContentType && stat.subContentType.toUpperCase() === _this3.occupancySubcontentType.toUpperCase()) {
                var entryDate = _moment.default.tz(stat.entryDate, tz);
                var selectedDate = _this3.selectedDate;
                if (entryDate.valueOf() === selectedDate.valueOf()) {
                  stats.occupancy = stat;
                  if (stats.occupancy) {
                    stats.occupancy.dataValue = (0, _accounting.formatNumber)(parseFloat(stats.occupancy.dataValue), 1);
                  }
                }
              }
              break;
            // multiple forecasting models, so use the correct one
            case 'OCCUPANCY_FORECAST':
              // Make sure this matches the forecast model selected
              if (stat.subContentType === _this3.occupancyForecastSubcontentType) {
                stats[stat.contentType.toLowerCase()] = stat;
              }
              break;
            default:
              if (stat.subContentType) {
                stats[(stat.contentType + '_' + stat.subContentType).toLowerCase()] = stat;
              } else {
                stats[stat.contentType.toLowerCase()] = stat;
              }
              break;
          }
        });

        var occupancy_stlys = overviewStly.filterBy('contentType', 'OCCUPANCY');
        if (occupancy_stlys) {
          stats.occupancy_stly = occupancy_stlys.findBy('subContentType', this.occupancySubcontentType);
          if (stats.occupancy_stly) {
            stats.occupancy_stly.dataValue = (0, _accounting.formatNumber)(parseFloat(stats.occupancy_stly.dataValue), 1);
          }
        }

        stats.adr_stly = overviewStly.filterBy('contentType', 'ADR').findBy('dataType', 'VALUE');
        stats.revpar_stly = overviewStly.filterBy('contentType', 'REVPAR').findBy('dataType', 'VALUE');
        stats.revenue_stly = overviewStly.findBy('contentType', 'REVENUE');

        if (stats.occupancy_forecast) {
          stats.occupancy_forecast.dataValue = (0, _accounting.formatNumber)(parseFloat(stats.occupancy_forecast.dataValue), 1);
        }

        if (stats.adr && stats.adr_stly) {
          var adr_change = (parseFloat(stats.adr.dataValue) - parseFloat(stats.adr_stly.dataValue)) / parseFloat(stats.adr.dataValue) * 100;
          stats.adr_change = { dataValue: Math.abs(Math.round(adr_change)) };
        }

        if (stats.revpar && stats.revpar_stly) {
          var revpar_change = (parseFloat(stats.revpar.dataValue) - parseFloat(stats.revpar_stly.dataValue)) / parseFloat(stats.revpar.dataValue) * 100;
          stats.revpar_change = { dataValue: Math.abs(Math.round(revpar_change)) };
        }

        if (stats.revenue) {
          stats.revenue.dataValue = decodeURIComponent(stats.revenue.dataValue);
        }

        // ADR Forecast
        if (model.adrForecast) {
          var forecasts = model.adrForecast;
          // Since this is a single day, there should be only one element
          if (forecasts && forecasts.length === 1) {
            var forecast = parseFloat(forecasts[0].forecast);
            stats.adr_forecast = { dataValue: forecast };
          }

          stats.adr_forecast_currencyCode = stats.adr && stats.adr.currencyCode ? stats.adr.currencyCode : this.currencyCode;
        }

        if (this.competitiveRates) {
          var competitiveRates = this.competitiveRates.map(function (competitor) {
            var comp = competitor.get('description');
            if (comp) {
              competitor.set('description', decodeURIComponent(comp.replace(/\+/g, '%20')));
            }
            competitor.set('dataValue', parseFloat(competitor.get('dataValue')));
            competitor.set('currencyCode', _this3.get('currencyCode'));
            return competitor;
          });

          var sellableRate = competitiveRates.filterBy('description', this.get('controllers.customer.name')).get('firstObject') || competitiveRates.filterBy('subContentType', 'PUBLIC').get('firstObject');

          if (sellableRate) {
            stats.sell_rate = sellableRate;

            competitiveRates = competitiveRates.map(function (competitor) {
              competitor.set('isLower', sellableRate.get('dataValue') > competitor.get('dataValue'));
              return competitor;
            });
          }
          competitiveRates = competitiveRates.rejectBy('description', undefined);
          stats.competitors = competitiveRates.rejectBy('description', this.get('controllers.customer.name')).sortBy('dataValue');
        }
      }

      this.set('overviewStats', stats);
    }
  });
});