define('ux-pcc/components/yield-manager/views/ysga-view', ['exports', 'ux-components/utils/to-boolean', 'moment'], function (exports, _toBoolean, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['ysga'],
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    rollupStatus: 'DETAIL',
    model: null,
    sliceResolution: 'day',
    exportEnabled: true,
    locked: false,
    selectedPeriod: 0,
    lengthOfRents: null,

    filterChange: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_LENGTH_OF_RENT'
      }).then(function (results) {
        _this.set('lengthOfRents', results.reject(function (code) {
          var shortDescription = code.get('shortDescription').toUpperCase();
          return shortDescription.indexOf('EXCLUDE') === 0;
        }).sortBy('orderBy'));
      });
    },


    destinationStatusParam: Ember.computed.alias('model.view.dashboardViewParameters.DESTINATION_STATUS'),

    productBlueprintCountParam: Ember.computed.alias('model.view.dashboardViewParameters.PRODUCT_BLUEPRINT_COUNT'),

    showBooks: Ember.computed('destinationStatusParam', function () {
      return (this.get('destinationStatusParam') || "").includes("BOOK");
    }),

    showCancels: Ember.computed('destinationStatusParam', function () {
      return (this.get('destinationStatusParam') || "") === "CANCEL" || (this.get('destinationStatusParam') || "") === "BOOK|INHOUSE|DEPARTED|CANCEL|NET";
    }),
    includeCancels: Ember.computed.equal('destinationStatusParam', 'BOOK|INHOUSE|DEPARTED|CANCEL'), //If "Reservations"("BOOK|INHOUSE|DEPARTED|CANCEL") is selected, then show the numbers without adjusting for cancellations
    //If "Net"("BOOK|INHOUSE|DEPARTED") is selected, then show the numbers after adjusting for cancellations.
    showComments: false,

    disableViewport: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.DISABLE_VIEWPORT_FOR_YSGA');
      return settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false;
    }),

    levelHierarchyParam: Ember.computed.readOnly('model.view.dashboardViewParameters.LEVEL_HIERARCHY'),

    levelParam: Ember.computed.alias('model.view.dashboardViewParameters.LEVEL'),

    previousLevelParam: Ember.computed.alias('model.view.dashboardViewParameters.PREVIOUS_LEVEL'),

    showReservationList: true,
    showProductGroupBreakdown: Ember.computed('levelHierarchyParam', 'productBlueprintCountParam', function () {
      return this.get('levelHierarchyParam') !== 'LEVEL_CORPORATE' && this.get('productBlueprintCountParam') === 1;
    }),

    showBookingTrendsPreviousWeeks: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_YSGA_BOOKING_TRENDS_PREVIOUS_WEEKS');
      return settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false;
    }),

    revenuePrecision: Ember.computed('customer', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'PCC.YIELD_MANAGEMENT_REVENUE_PRECISION');
      return model && model.get('settingValue') ? model.get('settingValue') : 0;
    }),

    formatRevenueOptions: Ember.computed('revenuePrecision', 'customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: this.get('revenuePrecision'),
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatYieldOptions: Ember.computed('customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: 2,
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    _grabProductGroups: function _grabProductGroups() {
      var _this2 = this;

      var selectedLevels = this.get('levelParam');

      if (selectedLevels) {
        // skip if we've done this before
        if (selectedLevels === this.get('previousLevelParam')) {
          return;
        }
        this.set('previousLevelParam', selectedLevels);
        this.get('ajax').request('/rest/v1/dashboardViews/products', { data: {
            customers: selectedLevels,
            fields: 'id,shortDescription,externalReference,customerID'
          } }).then(function (_ref) {
          var lists = _ref.lists;

          // Check the uniqueness of the customer ids coming back, as they are the blueprints
          // Only proceed if using a single blueprint
          var customerIds = (lists || []).mapBy('customerID').uniq();
          if (customerIds.length === 1) {
            _this2.get('store').query('product', { ids: lists.map(function (product) {
                return product.id;
              }), include: 'children,children.children' }).then(function (products) {
              _this2.set('productGroups', products.sortBy('shortDescription'));
            });
          } else {
            if (!(_this2.get('isDestroyed') || _this2.get('isDestroying'))) {
              _this2.set('productGroups', []);
            }
          }
        });
      } else {
        if (this.get('showProductGroupBreakdown')) {
          if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
            this.set('productGroups', []);
          }
        }
      }
    },


    _levelParamValueObserver: Ember.observer('levelParam', 'showProductGroupBreakdown', function () {
      Ember.run.once(this, '_grabProductGroups');
    }),

    allProducts: Ember.computed('productGroups', function () {
      var flattenProducts = function flattenProducts() {
        var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var fullProductList = [];

        (list || []).forEach(function (product) {
          fullProductList.addObject(product);
          fullProductList.addObjects(flattenProducts(product.get('children') || []));
        });

        return fullProductList;
      };
      return flattenProducts(this.get('productGroups'));
    }),

    _getOffsetDate: function _getOffsetDate(date, dateCompare) {
      var offsetDate = void 0;
      if (dateCompare === 'YEAR') {
        offsetDate = date.clone().subtract(52, 'weeks');
      } else if (dateCompare === 'WEEK') {
        offsetDate = date.clone().subtract(1, 'week');
      }

      return offsetDate;
    },


    childModel: null,

    _populateChildModel: function _populateChildModel() {
      var tz = this.get('tz');
      var childModel = JSON.parse(JSON.stringify(this.get('model')));

      // Grab the originally selected start and end dates for STLW and STLY
      var arrivalBeginDate = _moment.default.tz(Ember.get(childModel.view.dashboardViewParameters, 'BEGIN_DATE'), tz).startOf('day');
      var stlwBeginDate = _moment.default.tz(Ember.get(childModel.view.dashboardViewParameters, 'STLW_BEGIN_DATE'), tz).startOf('day');
      var stlyBeginDate = _moment.default.tz(Ember.get(childModel.view.dashboardViewParameters, 'STLY_BEGIN_DATE'), tz).startOf('day');
      var stlwEndDate = _moment.default.tz(Ember.get(childModel.view.dashboardViewParameters, 'STLW_END_DATE'), tz).startOf('day');
      var stlyEndDate = _moment.default.tz(Ember.get(childModel.view.dashboardViewParameters, 'STLY_END_DATE'), tz).startOf('day');

      var newStlwBeginDate = stlwBeginDate.clone();
      var newStlyBeginDate = stlyBeginDate.clone();

      var newBeginDate = _moment.default.tz(this.get('zoomStartDate'), tz).startOf('day');

      // Set the dates to the zoomed dates
      if (this.get('zoomStartDate')) {

        // Adjust the CP1 and CP2 begin dates
        // Take the zoom begin date, and subtract the difference between the original selected date, and the original STLW dates
        newStlwBeginDate = newBeginDate.clone().subtract(arrivalBeginDate.diff(stlwBeginDate, 'days'), 'days');

        // if the newly adjusted startDate is after the originally selected end date range, set an error flag
        if (newStlwBeginDate.isAfter(stlwEndDate)) {
          // Set error flag for STLW
          Ember.set(childModel.view.dashboardViewParameters, 'STLW_DATE_ERROR', true);
        } else if (newStlwBeginDate.isBefore(stlwBeginDate)) {
          // else if it is before the start date, use the originally selected start date
          newStlwBeginDate = stlwBeginDate;
        }

        newStlyBeginDate = newBeginDate.clone().subtract(arrivalBeginDate.diff(stlyBeginDate, 'days'), 'days');
        // if the newly adjusted range is after the originally selected end date range, set an error flag
        if (newStlyBeginDate.isAfter(stlyEndDate)) {
          // Set error flag for STLY
          Ember.set(childModel.view.dashboardViewParameters, 'STLY_DATE_ERROR', true);
        } else if (newStlyBeginDate.isBefore(stlyBeginDate)) {
          // else if it is before the start date, use the originally selected start date
          newStlyBeginDate = stlyBeginDate;
        }

        Ember.set(childModel.view.dashboardViewParameters, 'STLW_BEGIN_DATE', newStlwBeginDate);
        Ember.set(childModel.view.dashboardViewParameters, 'STLY_BEGIN_DATE', newStlyBeginDate);
        Ember.set(childModel.view.dashboardViewParameters, 'BEGIN_DATE', newBeginDate);
      }

      if (this.get('zoomEndDate')) {
        var newEndDate = _moment.default.tz(this.get('zoomEndDate'), tz).startOf('day');

        // Adjust the CP1 and CP2 end dates, using the relative change in days
        // Take the zoom begin date, and subtract the difference between the original selected date, and the original STLW dates
        var newStlwEndDate = newStlwBeginDate.clone().add(newEndDate.diff(newBeginDate, 'days'), 'days');

        // if the newly adjusted range is before new begin date, set an error flag
        if (newStlwEndDate.isBefore(newStlwBeginDate)) {
          // Set error flag for STLW
          Ember.set(childModel.view.dashboardViewParameters, 'STLW_DATE_ERROR', true);
        } else if (newStlwEndDate.isAfter(stlwEndDate)) {
          // if the new calculated end date is after the original selected end date, check to see if the new begin date is still within the
          // original selected range.
          if (newStlwBeginDate.isSameOrAfter(stlwBeginDate) && newStlwBeginDate.isSameOrBefore(stlwEndDate)) {
            // Still some dates in the range, so set the end to the original selected date
            newStlwEndDate = stlwEndDate;
          } else {
            // No dates left in the range, so flag the date
            Ember.set(childModel.view.dashboardViewParameters, 'STLW_DATE_ERROR', true);
          }
        }

        // Take the zoom begin date, and subtract the difference between the original selected date, and the original STLW dates
        var newStlyEndDate = newStlyBeginDate.clone().add(newEndDate.diff(newBeginDate, 'days'), 'days');

        // if the newly adjusted range is before new begin date, set an error flag
        if (newStlyEndDate.isBefore(newStlyBeginDate)) {
          // Set error flag for STLY
          Ember.set(childModel.view.dashboardViewParameters, 'STLY_DATE_ERROR', true);
        } else if (newStlyEndDate.isAfter(stlyEndDate)) {
          // if the new calculated end date is after the original selected end date, check to see if the new begin date is still within the
          // original selected range.
          if (newStlyBeginDate.isSameOrAfter(stlyBeginDate) && newStlyBeginDate.isSameOrBefore(stlyEndDate)) {
            // Still some dates in the range, so set the end to the original selected date
            newStlyEndDate = stlyEndDate;
          } else {
            // No dates left in the range, so flag the date
            Ember.set(childModel.view.dashboardViewParameters, 'STLY_DATE_ERROR', true);
          }
        }

        Ember.set(childModel.view.dashboardViewParameters, 'STLW_END_DATE', newStlwEndDate);
        Ember.set(childModel.view.dashboardViewParameters, 'STLY_END_DATE', newStlyEndDate);
        Ember.set(childModel.view.dashboardViewParameters, 'END_DATE', newEndDate);
      }

      this.set('childModel', childModel);
    },


    childModelObserver: Ember.observer('model', 'filterChange', 'zoomStartDate', 'zoomEndDate', function () {
      Ember.run.once(this, '_populateChildModel');
    }),

    actions: {
      toggleLocked: function toggleLocked(selectedPeriod) {
        if (!this.get('locked')) {
          this.set('selectedPeriod', selectedPeriod);
        }

        this.toggleProperty('locked');
      },
      slideChanged: function slideChanged(selectedPeriod) {
        this.set('selectedPeriod', selectedPeriod);
      },
      updateFilter: function updateFilter(data) {
        this.updateFilter(data);
      },
      setSliceResolution: function setSliceResolution(slice) {
        this.set('sliceResolution', slice);
      },
      setZoomDates: function setZoomDates(inZoomStartDate, inZoomEndDate) {
        if (!Ember.isEmpty(this.get('zoomStartDate')) && inZoomStartDate.isSame(this.get('zoomStartDate'), 'day') && !Ember.isEmpty(this.get('zoomEndDate')) && inZoomEndDate.isSame(this.get('zoomEndDate'), 'day')) {
          // toggle the filterChange property to force a requery, since notifyPropertyChange didn't work
          this.toggleProperty('filterChange');
        } else {
          if (Ember.isEmpty(this.get('zoomStartDate')) || !inZoomStartDate.isSame(this.get('zoomStartDate'), 'day')) {
            this.set('zoomStartDate', inZoomStartDate);
          }
          if (Ember.isEmpty(this.get('zoomEndDate')) || !inZoomEndDate.isSame(this.get('zoomEndDate'), 'day')) {
            this.set('zoomEndDate', inZoomEndDate);
          }
        }
      },
      openModal: function openModal(template, model) {
        this.openModal(template, model);
      }
    }
  });
});