define('ux-pcc/components/yield-manager/view-card-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['view-card-feed'],
    screens: null,
    screen: 0,
    customerID: null,
    selectedDate: null,
    range: null,
    tz: null,
    rollupStatus: 'DETAIL',
    model: null,
    currencyCode: 'USD',

    init: function init() {
      this._super.apply(this, arguments);
    },
    _getSubTitle: function _getSubTitle(type) {

      switch (type) {
        case 'YMCD':
          return this.get('i18n').t('dashboard-views.form.ymcd');
        case 'YICD':
          return this.get('i18n').t('dashboard-views.form.yicd');
        case 'YMTD':
          return this.get('i18n').t('dashboard-views.form.ymtd');
        case 'PRODUCT':
          return this.get('i18n').t('dashboard-views.form.product_types');
        case 'CHANNEL':
          return this.get('i18n').t('dashboard-views.form.channels');
        case 'PRICE':
          return this.get('i18n').t('dashboard-views.form.prices');
        default:
          return this.get('i18n').t('dashboard-views.form.ymcd');
      }
    },
    _getBrand: function _getBrand(brand) {
      if (!brand || brand.indexOf('|') > -1) {
        return 'ehi';
      }
      return brand;
    },
    _getViewParamValue: function _getViewParamValue(viewParams, name) {
      var param = viewParams.findBy('name', name);
      return param ? param.value : null;
    },


    views: Ember.computed('screens', 'screen', function () {
      var _this = this;

      var views = this.get('screens')['page' + this.get('screen')] || [];
      var viewObjects = [];

      views.filterBy('dashboardViewParameters').forEach(function (view) {
        var viewParams = view.get('dashboardViewParameters');
        var type = _this._getViewParamValue(viewParams, 'DATA_TYPE');

        var newView = {
          title: view.get('name'),
          cardType: _this.get('cardType') || type,
          type: type,
          view: view,
          gross: _this._getViewParamValue(viewParams, 'TM_VS_GROSS') === 'GROSS',
          weekOverWeek: _this._getViewParamValue(viewParams, 'DATE_COMPARE') === 'WEEK',
          range: parseInt(_this._getViewParamValue(viewParams, 'DATE_RANGE') || "0"),
          reportingTimePeriod: _this._getViewParamValue(viewParams, 'REPORTING_TIME_PERIOD'),
          brand: _this._getViewParamValue(viewParams, 'BRAND_CODE') || "",
          subTitle: _this._getSubTitle(type)
        };
        viewObjects.push(Ember.Object.create(newView));
      });

      return viewObjects.filterBy('cardType');
    }),

    actions: {
      editView: function editView(view) {
        this.showModal('dashboard-views', { views: this.get('model.views'), selectedView: view.view });
      },
      viewInfo: function viewInfo(view) {
        this.showModal('dashboard-views', { views: this.get('model.views'), selectedView: view.view, viewOnly: true });
      }
    }
  });
});