define('ux-pcc/components/yield-manager/cards/ymcd-card', ['exports', 'moment', 'accounting/format-number', 'ux-pcc/utils/serialize-dashboard-view', 'ember-cli-file-saver/mixins/file-saver', 'ux-components/utils/format-currency', 'ux-components/utils/stringify-query-params', 'ux-pcc/components/yield-manager/cards/base-card', 'ux-pcc/helpers/card-data-window-classes/row', 'ux-pcc/helpers/card-data-window-classes/screen', 'ux-pcc/helpers/card-data-window-classes/screens', 'ux-pcc/helpers/card-data-window-classes/value'], function (exports, _moment, _formatNumber, _serializeDashboardView, _fileSaver, _formatCurrency, _stringifyQueryParams, _baseCard, _row, _screen, _screens, _value) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _baseCard.default.extend(_fileSaver.default, {

    layoutName: 'components/yield-manager/cards/base-card',

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customerID');
      var tz = this.get('tz');

      var startDate = _moment.default.tz(this.get('selectedDate'), tz).startOf('day');
      var endDate = startDate.clone().add(30, 'days').startOf('day');

      this.set('startDate', startDate);
      this.set('endDate', endDate);

      this.set('loading', true);
      this.set('data', null);

      var params = {
        customerid: customerID,
        begindate: startDate.format(),
        enddate: endDate.format(),
        aggregationType: 'DAILY_ACCUMULATION_COMPARISON',
        rollupStatus: this.get('rollupStatus'),
        accumulate: true,
        _: (0, _moment.default)().valueOf()
      };

      var requests = [this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'YMCD Data').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            entries = _ref3$ === undefined ? [] : _ref3$;

        var mapped = {};

        if (!entries || entries.length < 1) {
          return;
        }

        var tz = _this.get('tz');

        entries.forEach(function (e) {
          var date = _moment.default.tz(e.beginDate, tz);
          var key = date.format();

          mapped[key] = e.dataValues;
          //add the slice
          mapped[key]['slice'] = key;
          mapped[key]['currencyCode'] = e.currencyCode;
        });

        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('data', mapped);
        }
      }).finally(function () {
        if (_this.get('loading') && !(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('loading', false);
        }
      });
    },


    cardData: null,

    _setCardData: function _setCardData() {
      var mapped = this.get('data');
      if (!mapped) {
        this.set('cardData', null);
      }

      var grossFlag = this.get('model.gross');
      var tz = this.get('tz');

      var dateSort = function dateSort(o1, o2) {
        var date1 = _moment.default.tz(o1, tz);
        var date2 = _moment.default.tz(o2, tz);

        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
      };

      // sort the keys
      var keys = Object.keys(mapped).sort(dateSort);

      var vals = mapped[keys[0]];

      if (!vals) {
        this.set('cardData', null);
      }

      var getValueClass = function getValueClass(value) {
        return isNaN(value) || parseFloat(value) < 0 ? 'negative' : '';
      };

      var screens = new _screens.default();
      var currencyCode = vals['currencyCode'] || this.get('currencyCode');

      // Create the dual column screen
      var screen = new _screen.default();
      var row = new _row.default();
      row.graphKey = 'usage';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.actuals'), (0, _formatNumber.default)(vals['usage'], '')));
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.actuals'), (0, _formatNumber.default)(vals['stlyUsage'], ''), 'muted'));
      screen.addRow(row);
      // Graph these two columns
      this.set('currentChartRow', row);

      row = new _row.default();
      row.graphKey = 'noshowusage';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.net'), (0, _formatNumber.default)(vals['noshowusage'], '')));
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.net'), (0, _formatNumber.default)(vals['stlyNoshowusage'], ''), 'muted'));
      screen.addRow(row);

      row = new _row.default();
      row.graphKey = 'yield';
      var key = grossFlag ? 'grossYield' : 'baseYield';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.yield'), vals[key]));
      key = grossFlag ? 'stlyGrossYield' : 'stlyBaseYield';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.yield'), vals[key], 'muted'));
      screen.addRow(row);

      row = new _row.default();
      row.graphKey = 'revenue';
      key = grossFlag ? 'gross' : 'base';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.revenue'), (0, _formatCurrency.default)(vals[key], currencyCode, { hash: { precision: 0 } })));
      key = grossFlag ? 'stlyGross' : 'stlyBase';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.revenue'), (0, _formatCurrency.default)(vals[key], currencyCode, { hash: { precision: 0 } }), 'muted'));
      screen.addRow(row);
      screens.addScreen(screen);

      // Create the delta screen
      screen = new _screen.default();
      row = new _row.default();
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.actuals'), (0, _formatNumber.default)(vals['deltaUsage'], ''), '', getValueClass(vals['deltaUsage'])));
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.actuals'), (0, _formatNumber.default)(vals['pctChangeUsage'], 1) + '%', '', getValueClass(vals['pctChangeUsage'])));
      screen.addRow(row);

      row = new _row.default();
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.net'), (0, _formatNumber.default)(vals['deltaNoShowUsage'], ''), '', getValueClass(vals['deltaNoShowUsage'])));
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.net'), (0, _formatNumber.default)(vals['pctChangeNoShowUsage'], 1) + '%', '', getValueClass(vals['pctChangeNoShowUsage'])));
      screen.addRow(row);

      row = new _row.default();
      key = grossFlag ? 'deltaGrossYield' : 'deltaBaseYield';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.yield'), vals[key], '', getValueClass(vals[key])));
      key = grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.yield'), (0, _formatNumber.default)(vals[key], 1) + '%', '', getValueClass(vals[key])));
      screen.addRow(row);

      row = new _row.default();
      key = grossFlag ? 'deltaGross' : 'deltaBase';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.revenue'), (0, _formatCurrency.default)(vals[key], currencyCode, { hash: { precision: 0 } }), '', getValueClass(vals[key])));
      key = grossFlag ? 'pctChangeGross' : 'pctChangeBase';
      row.addValue(new _value.default(this.get('i18n').t('yield-manager.revenue'), (0, _formatNumber.default)(vals[key], 1) + '%', '', getValueClass(vals[key])));
      screen.addRow(row);
      screens.addScreen(screen);

      this.set('cardData', screens);
    },


    cardDataObserver: Ember.observer('data', 'model.gross', function () {
      Ember.run.once(this, '_setCardData');
    }),

    actions: {
      exportCsv: function exportCsv() {
        var _this2 = this;

        this.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('yield-manager.message.download_requested'), 'success');
        var tz = this.get('tz');
        var customerID = this.get('customerID');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var filename = this.get('i18n').t('yield-manager.file-prefix.ymcd') + '_' + this.get('customerExternalReference') + '_' + startDate.format('YYYYMMDD') + '_' + endDate.format('YYYYMMDD') + '.csv';

        var params = {
          customerid: customerID,
          begindate: startDate.format(),
          enddate: endDate.format(),
          aggregationType: 'DAILY_ACCUMULATION_COMPARISON',
          rollupStatus: this.get('rollupStatus'),
          accumulate: true
        };

        this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          headers: { accept: 'text/csv' },
          contentType: 'application/json',
          dataType: 'blob',
          data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
        }).then(function (data) {
          return _this2.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          return _this2.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this2.get('i18n').t('yield-manager.message.download_error'), 'danger');
        });
      }
    }
  });
});