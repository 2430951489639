define('ux-pcc/components/rule-rates/rule-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rule: null,

    tagName: '',

    isCurrent: true,
    isNotCurrent: Ember.computed.not('isCurrent'),
    customer: null,

    isExpanded: false,

    type: "pricing",

    isPricingType: Ember.computed.equal('type', 'pricing'),

    rateCodeLabel: Ember.computed('rule.{price.externalReference,ruleType}', function () {
      if (this.get('rule.ruleType') === 'CEILING') {
        return this.i18n.t("pcc-components.rule-rates.rule-panel.bar");
      } else {
        return this.get('rule.price.externalReference');
      }
    }),

    ruleTypeLabel: Ember.computed('rule.ruleType', function () {
      var ruleType = this.get('rule.ruleType');
      switch (ruleType) {
        case "BASED_ON_ADJUSTMENT":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.discount");
        case "RESTRICTION":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.restriction");
        case "CEILING":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.ceiling");
        case "ALERT_COMP":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.competition");
        case "ALERT_OCC":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.occupancy");
        case "ALERT_INV":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.inventory");
        case "ALERT_PERF":
          return this.i18n.t("pcc-components.rule-rates.rule-panel.performance");
        default:
          return ruleType;
      }
    }),

    actions: {
      confirmDelete: function confirmDelete(object) {
        this.get('confirmDelete')(object);
      },
      openRuleSummary: function openRuleSummary(rule) {
        var _this = this;

        if (this.get('isExpanded')) {
          this.send('closeRuleSummary');
        } else {
          this.set('isExpanded', false);
          Ember.run.later(function () {
            _this.set('model', rule);
            _this.set('isExpanded', true);
          }, 1);
        }
      },
      closeRuleSummary: function closeRuleSummary() {
        this.set('isExpanded', false);
      }
    }
  });
});