define('ux-pcc/routes/yield-manager/dashboard/view', ['exports', 'ux-components/mixins/routes/full-screen'], function (exports, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fullScreen.default, {
    _getSubTitle: function _getSubTitle(type) {
      switch (type) {
        case 'YMCD':
          return this.get('i18n').t('dashboard-views.form.ymcd');
        case 'YICD':
          return this.get('i18n').t('dashboard-views.form.yicd');
        case 'YMTD':
          return this.get('i18n').t('dashboard-views.form.ymtd');
        case 'PRODUCT':
          return this.get('i18n').t('dashboard-views.form.product_types');
        case 'CHANNEL':
          return this.get('i18n').t('dashboard-views.form.channels');
        case 'PRICE':
          return this.get('i18n').t('dashboard-views.form.prices');
        default:
          return this.get('i18n').t('dashboard-views.form.ymcd');
      }
    },
    _getBrand: function _getBrand(brand) {
      if (!brand) {
        return 'ehi';
      } else if (brand.indexOf('|') > -1) {
        return '';
      }
      return brand;
    },
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller, model) {
      var viewId = model.id;
      // Use the model from the controller, so we don't reload all the views and code values
      var yieldManagerModel = this.controllerFor('yield-manager.dashboard').get('model');
      var view = yieldManagerModel.findBy('id', viewId);
      var viewParams = view.get('dashboardViewParameters');
      var type = viewParams.findBy('name', 'DATA_TYPE').value;
      var newView = {
        title: view.get('name'),
        type: type,
        view: view,
        gross: viewParams.findBy('name', 'TM_VS_GROSS').value === 'GROSS',
        weekOverWeek: viewParams.findBy('name', 'DATE_COMPARE').value === 'WEEK',
        range: parseInt(viewParams.findBy('name', 'DATE_RANGE').value),
        reportingTimePeriod: viewParams.findBy('name', 'REPORTING_TIME_PERIOD').value,
        brand: viewParams.findBy('name', 'BRAND_CODE') ? this._getBrand(viewParams.findBy('name', 'BRAND_CODE').value) : '',
        subTitle: this._getSubTitle(type)
      };
      // Set the model on the controller
      this._super(controller, Ember.Object.create(newView));
    },


    actions: {
      refreshChildRoute: function refreshChildRoute() {
        this.refresh();
      }
    }
  });
});