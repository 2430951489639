define('ux-pcc/components/rules/condition-values/list', ['exports', 'ux-pcc/components/rules/condition-values/base', 'ember-changeset-validations/validators'], function (exports, _base, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (!this.get('filterValues.length')) {
        // If there are no filter values, clear it out.
        changeset.set(this.field, '');
      } else if (!this.get('filterValues').findBy('value', changeset.get(this.field))) {
        // If the current filter value doesn't exist in the list, default it
        changeset.set(this.field, this.get('filterValues.firstObject.value'));
      }
    },


    validations: Ember.computed('field', 'i18n.locale', function () {

      var validations = {};

      validations[this.field] = (0, _validators.validatePresence)({
        presence: true,
        message: this.i18n.t('rule._form_modal.no_value').toString()
      });

      return validations;
    }),

    // Currently expects an array of objects with key and value attributes
    filterValues: []
  });
});