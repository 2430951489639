define('ux-pcc/components/rate-code-performance-detail-table', ['exports', 'moment', 'lodash', 'accounting', 'ux-components/utils/format-currency'], function (exports, _moment, _lodash, _accounting, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    data: null,
    loading: true,
    enableSlider: false,
    columnsPerScreen: null,
    rollupStatus: 'DETAIL',
    isAtStart: Ember.computed.equal('_screen', 1),
    isAtEnd: Ember.computed('_screen', 'columnsPerScreen', 'columns.length', function () {
      return this.get('_screen') + this.get('columnsPerScreen') > this.get('columns.length');
    }),

    columns: Ember.computed(function () {
      return [{ key: "sold", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.rooms_sold') }, { key: "soldVar", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.rooms_sold_var') }, { key: "adr", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.booked_adr') }, { key: "adrVar", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.adr_var') }, { key: "occPct", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.occ_contrib') }, { key: "revPct", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.rev_contrib') }, { key: "totalRev", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.total_rev') }, { key: "revVar", label: this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.rev_var') }];
    }),

    showableColumns: Ember.computed('columns', '_screen', function () {
      var origColumns = this.get('columns');

      if (this.get('enableSlider') && this.get('columnsPerScreen')) {
        // Always grab the first column
        var rtnColumns = origColumns.slice(0, 1);

        var offset = this.get('_screen');

        return rtnColumns.concat(origColumns.slice(offset, offset + (this.get('columnsPerScreen') - 1)));
      } else {
        return origColumns;
      }
    }),

    _screen: 1,
    _fetchData: Ember.on('init', Ember.observer('customerID', 'beginDate', 'endDate', function () {
      var _this = this;

      var selectedDate = _moment.default.tz(this.get('beginDate'), this.get('tz')).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), this.get('tz')).endOf('day');

      if (endDate.isBefore(selectedDate)) {
        return;
      }

      this.set('loading', true);

      var params = {
        customerid: this.get('customerID'),
        begindate: selectedDate.format(),
        enddate: endDate.format(),
        aggregationType: 'RATE_CODE_DETAIL_BY_RATE_CODE_SEGMENT',
        rollupStatus: this.get('rollupStatus')
      };

      if (this.get('filter')) {
        params.filterValues = this.get('filter');
      }

      var requests = [this.ajax.request('/rest/v1/analyticEntryAggregation/process', { data: params }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'RateCodePerformanceDetailTable: Getting segment details').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        return _this.set('data', { analytics: data });
      });
    })),

    parseData: Ember.observer('data', 'showableColumns', function () {
      var _this2 = this;

      var entries = this.get('data.analytics');
      var currencyCode = this.get('context.price.defaultCurrencyCode') || "USD";

      var tableCellHTML = (0, _lodash.template)('\n              <%= (data.value) ? data.value : \'&mdash;\' %>\n          ', { 'variable': "data" });

      var createRow = function createRow(label) {
        return {
          label: label,
          values: {
            sold: { value: tableCellHTML({}) },
            soldVar: { value: tableCellHTML({}) },
            adr: { value: tableCellHTML({}) },
            adrVar: { value: tableCellHTML({}) },
            occPct: { value: tableCellHTML({}) },
            revPct: { value: tableCellHTML({}) },
            totalRev: { value: tableCellHTML({}) },
            revVar: { value: tableCellHTML({}) }
          }
        };
      };

      var createGroup = function createGroup(data) {

        var masterMap = {};

        data.forEach(function (rate) {
          var groupByValues = rate.groupByValue.split(',');
          var classNames = groupByValues.indexOf('TOTAL') > -1 ? "total-row" : "hidden";
          var marketSegment = groupByValues.length > 1 ? groupByValues[1] : groupByValues[0];
          var rateCode = groupByValues[0];

          if (!(marketSegment in masterMap)) {
            masterMap[marketSegment] = {
              group: marketSegment,
              label: marketSegment === 'TOTAL' ? '' : marketSegment,
              labelAsCell: false,
              rows: [],
              classNames: classNames
            };
          }

          var roomTypeRow = createRow(marketSegment === 'TOTAL' ? marketSegment : marketSegment + '<br>' + rateCode);
          masterMap[marketSegment].rows.push(roomTypeRow);

          var data = rate.dataValues;

          for (var code in data) {

            var value = data[code];

            switch (code) {
              case 'adr':
              case 'adrVar':
              case 'totalRev':
              case 'revVar':
                value = (0, _formatCurrency.default)(data[code], currencyCode);
                break;
              case 'occPct':
              case 'revPct':
                value = (data[code] >= 100 ? _accounting.default.formatNumber(data[code]) : _accounting.default.formatNumber(data[code], 1)) + '%';
                break;
              default:
                value = data[code];
            }
            roomTypeRow.values[code].value = tableCellHTML({ value: value });
          }
        });

        var currentWeek = [];

        for (var group in masterMap) {
          var rec = masterMap[group];
          if (rec.group === 'TOTAL') {
            rec.rows[0].classNames = 'total-row';
          } else {
            if (rec.rows.length > 1) {
              rec.rows = rec.rows.sortBy('label');
            }
          }
          currentWeek.push(rec);
        }

        // Add ordering and grouping logic
        currentWeek = currentWeek.sort(function (a, b) {
          if (a.group === 'TOTAL') {
            return -1;
          }
          if (b.group === 'TOTAL') {
            return 1;
          }
          if (a.group < b.group) {
            return -1;
          }
          if (a.group > b.group) {
            return 1;
          }
          return 0;
        });

        // Sort the groups in the same order they came in the filter
        var filterOrder = _this2.get('filter').split(',');
        currentWeek = currentWeek.sort(function (a, b) {
          if (a.group === 'TOTAL') {
            return -1;
          }
          if (b.group === 'TOTAL') {
            return 1;
          }
          if (filterOrder.indexOf(a.group.toUpperCase()) < filterOrder.indexOf(b.group.toUpperCase())) {
            return -1;
          }
          if (filterOrder.indexOf(a.group.toUpperCase()) > filterOrder.indexOf(b.group.toUpperCase())) {
            return 1;
          }
          return 0;
        });

        // add striping, and sort the rows
        var prevSegment = "";
        var segmentCount = 0;
        currentWeek.forEach(function (seg) {
          if (prevSegment.length === 0) {
            prevSegment = seg.group;
          } else if (prevSegment !== seg.group) {
            prevSegment = seg.group;
            segmentCount++;
          }
          if (segmentCount % 2 > 0) {
            seg.rows.forEach(function (row) {
              row.classNames = 'guide';
            });
          }

          // Sort each sub-item alphabetically
          if (seg.rows.length > 1) {
            seg.rows = seg.rows.sortBy('label');
          }
        });

        return currentWeek;
      };

      var columns = this.get('showableColumns');

      var groupRows = createGroup(entries);
      if (groupRows && groupRows.length > 0) {
        groupRows[0].label = this.get('i18n').t('customer.performance.pricing.table.rate-code-performance.segment');
      }

      this.set('segmentTableData', {
        groups: groupRows,
        columns: columns
      });

      this.set('loading', false);
    }),

    actions: {
      left: function left() {
        var screen = this.get('_screen');
        this.set('_screen', --screen);
      },
      right: function right() {
        var screen = this.get('_screen');
        this.set('_screen', ++screen);
      }
    }

  });
});