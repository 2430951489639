define('ux-pcc/controllers/competitive-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    store: Ember.inject.service(),

    customer: Ember.inject.controller(),
    competitiveSets: Ember.inject.controller(),

    actions: {
      saveCompetitiveSet: function saveCompetitiveSet(model) {
        var _this = this;

        var children = model.get('xrefs');

        model.save().then(function () {
          // Success!
          return _this.get('store').query('customer-xref', { customerID: model.get('id') }).then(function (xrefs) {

            var promises = Ember.A();

            // Remove deleted xrefs
            xrefs.forEach(function (xref) {
              if (!children.findBy('id', xref.get('xrefCustomer.id'))) {
                promises.push(xref.destroyRecord());
              }
            });

            // Add new xrefs
            children.forEach(function (child) {
              if (!xrefs.findBy('xrefCustomer.id', child.get('id'))) {
                var xref = _this.get('store').createRecord('customer-xref', {
                  customer: model,
                  xrefCustomer: child,
                  xrefType: 'LINK'
                });
                promises.push(xref.save());
              }
            });

            return Ember.RSVP.all(promises);
          });
        }).then(function () {
          var list = _this.get('competitiveSets.model');
          var newModel = _this.get('model');
          var oldModel = list.findBy('id', newModel.get('id'));
          var index = list.indexOf(oldModel);
          list.replace(index, 1, [newModel]);
          _this.transitionToRoute('competitive-sets.list.detail', newModel.get('id'));
          _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.get('i18n').t('competitive-set.-form.success', { competitor: newModel.get('name') }), 'success');
        }).catch(function (error) {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      },
      close: function close() {
        this.transitionToRoute('competitive-sets.list.detail', this.get('model.id'));
      },
      modal: function modal(modalName, model) {
        this.send('showModal', modalName, model);
      }
    }
  });
});