define('ux-pcc/routes/customer/performance/market', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      beginDate: {
        refreshModel: true
      }
    },

    ajax: Ember.inject.service(),

    model: function model(params) {
      var tz = this.tz.get('customer');
      var startDate = params.beginDate;

      if (!startDate) {
        // Look for the the date of the last record loaded, in an attempt to land on a page with data
        if (this.modelFor('customer') && this.modelFor('customer').get('udfValues') && this.modelFor('customer').get('udfValues').hasOwnProperty('STR_LATEST_RECORD_DATE')) {
          startDate = this.modelFor('customer').get('udfValues')['STR_LATEST_RECORD_DATE'];
        }
      }

      this.set('startDate', _moment.default.tz(startDate, tz).startOf('week').format());

      var beginDate = _moment.default.tz(startDate, tz).startOf('week').startOf('day').format('YYYY-MM-DD');
      var endDate = _moment.default.tz(startDate, tz).endOf('week').endOf('day').format('YYYY-MM-DD');
      var customerId = this.modelFor('customer').get('id');

      return this.ajax.request('/rest/v1/marketBenchmarks', {
        data: {
          customerid: customerId,
          begindate: beginDate,
          enddate: endDate
        }
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

        return analyticEntries;
      });
    },
    setupController: function setupController(controller, model) {
      if (this.startDate) {
        controller.set('beginDate', this.startDate);
      }
      this._super(controller, model);
    },


    actions: {
      changeReport: function changeReport(direction) {
        var controller = this.get('controller');
        var tz = this.tz.get('customer');

        controller.set('beginDate', _moment.default.tz(controller.get('beginDate'), tz).add(direction, 'week').format('YYYY-MM-DD'));
      }
    }
  });
});