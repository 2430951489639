define('ux-pcc/routes/login/callback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel: function beforeModel() /*transition*/{
      this.session.prohibitAuthentication('index');
    },
    model: function model(params) {
      var authType = this.config.authType.toLowerCase() || 'callback';
      return this.session.authenticate('authenticator:' + authType, params.sessionToken);
    }
  });
});