define('ux-pcc/components/header-actions', ['exports', 'ux-components/components/header-actions'], function (exports, _headerActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _headerActions.default;
    }
  });
});