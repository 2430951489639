define('ux-pcc/services/ajax', ['exports', 'ember-ajax/services/ajax', 'ember-uuid'], function (exports, _ajax, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({

    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    contentType: 'application/json',
    busy: Ember.computed.gt('registry.length', 0),
    trackedRequests: Ember.computed.readOnly('registry.length'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('registry', []);
    },
    request: function request(url) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


      var temp = this._super.apply(this, arguments);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.later(function () {
          var id = void 0;
          if (options.track) {
            id = (0, _emberUuid.v4)();
            _this.get('registry').addObject(id);
          }

          return temp.then(function (results) {
            resolve(results);
          }).catch(function (error) {
            reject(error);
          }).finally(function () {
            Ember.run.later(function () {
              if (options.track) {
                _this.get('registry').removeObject(id);
              }
            });
          });
        });
      });
    },

    headers: Ember.computed('session.data.authenticated.token', 'i18n.locale', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.data.authenticated.token');
        if (authToken) {
          headers["X-Auth-Token"] = authToken;
          headers["Authorization"] = "Bearer " + authToken;
        }

        var locale = this.get('i18n.locale');
        if (locale) {
          headers['Accept-Language'] = locale + ", en-US;q=0.4, en;q=0.2";
        }

        return headers;
      }
    })
  });
});