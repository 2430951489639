define('ux-pcc/components/sort-amount', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'i',
    classNames: ['fa', 'sort-amount'],
    classNameBindings: ['isAscending:fa-sort-amount-asc:fa-sort-amount-desc', 'multiplierClass'],

    isAscending: true,

    multiplier: 1,

    multiplierClass: Ember.computed('multiplier', function () {

      if (this.get('multiplier') === 'lg') {
        return 'fa-' + this.get('multiplier');
      } else if (this.get('multiplier') >= 2 && this.get('multiplier') <= 5) {
        return 'fa-' + this.get('multiplier') + 'x';
      } else {
        return '';
      }
    }),

    click: function click() {
      this.toggleProperty('isAscending');
      this.get('updateSort')(this.get('field'), this.get('isAscending'));
    }
  });
});