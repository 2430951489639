define('ux-pcc/components/menus/menu-items', ['exports', 'ux-components/components/menus/menu-items'], function (exports, _menuItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _menuItems.default;
    }
  });
});