define('ux-pcc/controllers/customer/performance/-matrix-pricing-copy-success-modal', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    copyModal: Ember.inject.controller('customer/performance/-matrix-pricing-copy-modal'),
    allowUpdates: Ember.computed('model.{hasPreviousPricingSave,hasIncompleteWeek}', function () {
      return this.get('model.hasPreviousPricingSave') && this.get('model.hasIncompleteWeek');
    }),

    actions: {
      continuePricing: function continuePricing() {
        this.send('showModal', 'customer/performance/-matrix-pricing-copy-modal', {
          isCopy: true,
          selectedSlice: _moment.default.tz(this.get('model.pricing.beginSlice'), this.get('tz').customer),
          date: _moment.default.tz(this.get('model.pricing.beginSlice'), this.get('tz').customer),
          previousPricing: this.get('model.pricing'),
          pricePoints: this.get('model.pricePoints')
        });
      },
      closeSuccess: function closeSuccess() {
        var parentController = this.get('copyModal');
        parentController.set('previousPricing', null);
        parentController.set('hasPreviousPricingSave', false);
        this.send('removeModal');
      }
    }
  });
});