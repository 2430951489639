define('ux-pcc/controllers/customer/performance/abstract-matrix', ['exports', 'moment', 'ux-components/utils/moment-range'], function (exports, _moment, _momentRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    needs: ["customer"],

    table: Ember.inject.service(),

    _repaintMatrixHeader: function _repaintMatrixHeader() {
      var $headers = Ember.$(document).find('table:not(.fixed-column) thead:not([hidden])');

      $headers.each(function (i, header) {
        if (i === 0) {
          Ember.$(header).addClass("active");
        } else {
          Ember.$(header).removeClass("active");
        }
      });

      this.get('table').trigger('tableInvalidate');
    },
    _columnsForRange: function _columnsForRange(beginDate, endDate, selectedDate) {
      var padding = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

      var tz = this.tz.get("customer");
      var today = _moment.default.tz(tz).startOf("day");
      var columns = [];

      if (!selectedDate) {
        selectedDate = today;
      }
      var paddedBeginDate = (0, _moment.default)(beginDate).tz(tz).subtract(padding, 'days');
      var paddedEndDate = (0, _moment.default)(endDate).tz(tz).add(padding, 'days');
      var compareBegin = (0, _moment.default)(beginDate).tz(tz).subtract(1, 'days');
      var compareEnd = (0, _moment.default)(endDate).tz(tz).add(1, 'days');

      (0, _momentRange.default)(paddedBeginDate, paddedEndDate).forEach(function (m) {
        var date = m.tz(tz);
        var subDateFormat = date.format("DD") === "01" ? "MMM D" : "D";
        var column = Ember.Object.create();
        column.set('key', date.format());
        column.set('label', "<div style='text-align: center;'><span class='day-of-week'>" + date.format("dd") + "</span></div>" + "<div style='text-align: center;'><span class='day-of-month'>" + date.format(subDateFormat) + "</span></div>");
        column.set('actionable', date.valueOf() >= today.valueOf());
        column.set('isPending', !date.isBetween(compareBegin, compareEnd));
        var names = [];

        if (date.valueOf() === today.valueOf()) {
          names.push("active");
        }

        if (date.valueOf() === selectedDate.valueOf()) {
          names.push("selected");
        }

        // Alternate the weekly backgrounds.  Taking into account that
        // most years have 53 weeks in them.
        if ((0, _moment.default)(date).startOf('week').week() % 2 === 0) {
          names.push("guide");
        }

        if (column.get('actionable')) {
          names.push("actionable");
        } else if (date.valueOf() < today.valueOf()) {
          names.push("non-actionable");
        }

        column.set('classNames', names.join(" "));

        columns.push(column);
      });

      return columns;
    },


    actions: {
      tableReady: function tableReady(target) {
        var $element = target.element;
        if ($element) {
          var $table = $element.querySelector('table');
          var $selected = $table.querySelector('th.selected');
          var $fixedColumn = Ember.$('.fixed-column');
          var fixedColumnWidth = $fixedColumn && $fixedColumn.length > 0 ? $fixedColumn.width() : 0;

          if ($selected) {
            var midPointElement = ($element.offsetWidth - $element.querySelector("table th").offsetWidth) * 0.5;
            $element.scrollLeft = $selected.offsetLeft + $selected.offsetWidth * 0.5 - midPointElement - fixedColumnWidth;

            this._repaintMatrixHeader();
          }
        }
      },
      toggle: function toggle(property) {
        this.toggleProperty(property);
      }
    }
  });
});