define('ux-pcc/components/customer/performance/matrix-edit', ['exports', 'ux-pcc/templates/components/customer/performance/matrix-edit'], function (exports, _matrixEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _matrixEdit.default,
    isEditingAllocation: true,
    isMobile: Ember.computed.bool('deviceType.mobile'),

    allocationInputClasses: Ember.computed('isEditingAllocation', function () {
      var classes = "matrix-pricing-cell__input-allocation ";
      classes += this.get('isEditingAllocation') ? "" : "matrix-pricing-cell--hidden";
      return classes;
    }),

    leadTimeInputClasses: Ember.computed('isEditingAllocation', function () {
      var classes = "matrix-pricing-cell__input-lead-time ";
      classes += this.get('isEditingAllocation') ? "matrix-pricing-cell--hidden" : "";
      return classes;
    }),

    isEditingAllocationChanged: Ember.on('didInsertElement', Ember.observer('isEditingAllocation', function () {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        var index = _this.get('isEditingAllocation') ? 0 : 1;
        var $input = Ember.$(Ember.$(_this.get('element')).find('input')[index]);

        $input.focus().select();

        var update = _this.get('update');

        var advanceCursor = function advanceCursor(key, shiftKey) {

          switch (key) {
            case 9:
              // TAB

              // Treat the mobile keypad next as an enter
              if (_this.get('isMobile')) {
                if (!_this.get('isEditingAllocation')) {
                  update(_this.get('allocation'), _this.get('leadTime'));
                  _this.get('next')();
                } else {
                  _this.set('isEditingAllocation', false);
                }
              } else {
                if (shiftKey) {
                  _this.get('previousColumn')();
                } else {
                  _this.get('nextColumn')();
                }

                update(_this.get('allocation'), _this.get('leadTime'));
              }
              break;
            case 13:
              // ENTER
              if (!_this.get('isEditingAllocation')) {
                update(_this.get('allocation'), _this.get('leadTime'));
                _this.get('next')();
              } else {
                _this.set('isEditingAllocation', false);
              }
              break;
            case 40:
              // DOWN
              update(_this.get('allocation'), _this.get('leadTime'));
              _this.get('next')();
              break;
            case 38:
              // UP
              if (_this.get('isEditingAllocation')) {
                update(_this.get('allocation'), _this.get('leadTime'));
                _this.get('previous')();
              } else {
                _this.set('isEditingAllocation', true);
              }
              break;
          }
        };

        $input.on('keydown', function (evt) {

          if ($input.val().length >= 3 && !/^[\b]+$/.test(String.fromCharCode(evt.keyCode))) {
            evt.preventDefault();
          }

          switch (evt.which) {
            case 27:
              // ESC
              evt.preventDefault();
              if (!_this.get('isMobile')) {
                $input.trigger('blur');
              }
              break;
            case 9: // TAB
            case 13: // ENTER
            case 40: // DOWN
            case 38:
              // UP
              if (!_this.get('isMobile') || !_this.get('isEditingAllocation')) {
                evt.preventDefault();
              }

              var values = $input.val() || "0";

              if (!values) {
                console.warn('Invalid value "' + $input.val() + '" requested.');
                // TODO validation highlight
                break;
              } else {
                if (!_this.get('isEditingAllocation')) {
                  update(_this.get('allocation') || "0", _this.get('leadTime'));
                }
              }

              // TODO shift right on TAB, down on ENTER
              advanceCursor(evt.which, evt.shiftKey);
              $input.trigger('blur');

              break;
          }
        });

        $input.bind("paste", function () /* evt */{
          //check to ensure pasted text is number only and is not greater than 3 characters in length
          Ember.run.later("afterRender", function () {
            if (!/^[\d]+$/.test($input.val()) || $input.val().length > 3) {
              $input.val("");
            }
          }, 10);
        });

        $input.on('blur', function (evt) {

          if (_this.get('isMobile') && ($input.attr('class').indexOf('lead-time') >= 0 || $input.attr('class').indexOf('allocation') >= 0 && evt.relatedTarget && Ember.$(evt.relatedTarget).attr('class').indexOf('lead-time') >= 0)) {
            advanceCursor(9, '');
          }

          update(_this.get('allocation') || "0", _this.get('leadTime'));

          $input.off('keydown');
          $input.off('blur');

          Ember.run.schedule("afterRender", function () {
            var activeInputExists = false;
            _this.$().find('input').each(function (index, input) {
              if (Ember.$(input).is(':focus')) {
                activeInputExists = true;
              }
            });

            if (!activeInputExists) {
              _this.get('cancel')();
            }
          });
        });
      });
    }))
  });
});