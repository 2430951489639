define('ux-pcc/components/notification-message', ['exports', 'ember-cli-notifications/components/notification-message', 'ember-get-config'], function (exports, _notificationMessage, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object

  exports.default = _notificationMessage.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.icons = globals.icons || 'svg';
      this.svgs = {
        'success': 'success',
        'warning': 'warning',
        'info': 'info',
        'error': 'error'
      };
    }
  });
});