define('ux-pcc/routes/customer/performance/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),

    titleToken: function titleToken() {
      return this.i18n.t('customer.performance.title');
    },
    model: function model() {
      var customerId = this.modelFor('customer').id;

      return Ember.RSVP.hash({
        rollupProducts: this.store.query('product', {
          customerID: customerId,
          productUsageType: 'INVENTORY_ROLLUP',
          fields: 'externalReference,id,orderBy,productTemplateID,shortDescription'
        }).then(function (results) {
          return results.sortBy('orderBy');
        }),
        products: this.store.query('product', {
          customerID: customerId,
          productUsageType: 'AVAILABILITY',
          productTemplateID: '10a00000-0000-0000-0000-000000000000',
          include: 'ancestors'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var beginDate = controller.beginDate ? _moment.default.tz(controller.beginDate, this.tz.customer) : _moment.default.tz(this.tz.customer);
      var endDate = void 0;

      // Minimum of a week required
      var minEndDate = beginDate.clone().add(6, 'days');
      // Maximum of a year allowed
      var maxEndDate = beginDate.clone().add(52, 'weeks');

      if (controller.endDate) {
        endDate = _moment.default.tz(controller.endDate, this.tz.customer);
        // Check to make sure the endDate is > the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = minEndDate.clone();
        }
      } else {
        endDate = minEndDate.clone();
      }

      if (this.deviceType.mobile) {
        // limit end date for mobile
        endDate = minEndDate;
      } else {
        // Check to make sure the endDate is > the beginDate
        if (endDate.isBefore(minEndDate)) {
          endDate = minEndDate;
        } else if (endDate.isAfter(maxEndDate)) {
          endDate = maxEndDate;
        }
      }

      controller.set('beginDate', beginDate.startOf('day').format());
      controller.set('endDate', endDate.startOf('day').format());
    }
  });
});