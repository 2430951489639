define("ux-pcc/components/tables/table-infinite", ["exports", "ux-pcc/components/tables/table-basic", "ux-pcc/mixins/components/infinitely-scrollable"], function (exports, _tableBasic, _infinitelyScrollable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tableBasic.default.extend(_infinitelyScrollable.default, {
    infiniteScroll: true,
    layoutName: "components/tables/table-basic"

  });
});