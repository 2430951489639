define('ux-pcc/components/dashboard-widget/target-period-bookings', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),

    aggregationType: 'HOURLY_BOOKING_AGGREGATOR',
    data: null,
    hourType: 'BOOK_DATE',
    loading: false,
    model: null,
    sliceResolution: 'day',
    showComments: true,

    tz: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    },


    chartData: Ember.computed('data', function () {
      return (this.get('data') || []).rejectBy('subContentType', 'FULL_SPAN');
    }),

    fullSpan: Ember.computed('data', function () {
      return (this.get('data') || []).findBy('subContentType', 'FULL_SPAN');
    }),

    dataProvider: Ember.computed('data', function () {
      var mapped = this.get('data');
      if (!mapped) {
        return null;
      }

      return Object.keys(mapped).map(function (key) {
        return mapped[key];
      });
    }),

    currencyCode: Ember.computed.readOnly('dataProvider.firstObject.currencyCode'),

    stlwDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLW_DATE_ERROR'),
    stlyDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_DATE_ERROR'),

    // if including a table with chart, styling is needed to keep columns in sync
    tableConfig: Ember.computed('data.length', function () {
      return {
        columnWidth: 0.3, // width of columns relative to space allotted per data point
        autoMargins: false,
        marginLeft: 120, // must match margin-left on the table (yield-manager.less)
        marginRight: 80, // must match padding-right on the table (yield-manager.less)
        marginBottom: this.get('data.length') > 7 ? 10 : 50
      };
    }),

    scrollBarConfig: Ember.computed(function () {
      return {
        graph: 'left',
        oppositeAxis: false,
        offset: 50,
        scrollbarHeight: 40,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.2,
        selectedBackgroundColor: '#888888',
        graphFillAlpha: 0,
        graphLineAlpha: 0,
        graphLineColor: '#888888',
        selectedGraphFillAlpha: 0,
        selectedGraphLineAlpha: 0,
        selectedGraphLineColor: '#888888',
        dragIconHeight: 20,
        dragIconWidth: 20,
        graphType: 'line',
        gridCount: 24,
        color: '#AAAAAA',
        dragIcon: 'dragIconRoundSmall',
        updateOnReleaseOnly: true
      };
    }),

    dataFields: Ember.computed('model.gross', 'currencyCode', 'stlwDateErrorParam', 'stlyDateErrorParam', function () {
      var stlwDateError = this.get('stlwDateErrorParam');
      var stlyDateError = this.get('stlyDateErrorParam');
      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();
      return [Ember.Object.create({
        label: '' + this.i18n.t('yield-manager.target-period-bookings.hourly', { type: this.i18n.t('yield-manager.target-period-bookings.running_days') }),
        leftAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.hourly_add_axis'),
        tableRows: this.get('tableRows'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.days') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'formattedHour', 'duration', 'arrivalDuration', 'arrivalYield', 'yield', 'stlwDuration', 'stlyDuration', 'amountCumulative', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'duration',
          label: this.i18n.t('yield-manager.target-period-bookings.todays_add'),
          format: 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwDuration',
          label: this.i18n.t('yield-manager.target-period-bookings.pw_add', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          format: 'number',
          lineClasses: 'days-compare-lw',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare-lw',
          enabled: !stlwDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlyDuration',
          label: this.i18n.t('yield-manager.target-period-bookings.py_add', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          format: 'number',
          lineClasses: 'days-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: !stlyDateError,
          category: 'left',
          excludeFromHover: true
        },
        // These are the fields for the hoverovers
        {
          key: 'count',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.res_count'),
          format: 'number',
          enabled: true
        }, {
          key: 'duration',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_days'),
          format: 'number',
          enabled: true
        }, {
          key: 'amount',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_revenue'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          enabled: true
        }, {
          key: 'arrivalYield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }, {
          key: 'yield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }]
      }), Ember.Object.create({
        label: '' + this.i18n.t('yield-manager.target-period-bookings.cumulative', { type: this.i18n.t('yield-manager.target-period-bookings.running_days') }),
        leftAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.cumulative_add_axis'),
        rightAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.pct_variance'),
        tableRows: this.get('tableRows'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.days_var') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'formattedHour', 'count', 'arrivalDuration', 'amountCumulative', 'arrivalYield', 'yield', 'durationCumulative', 'stlwDurationCumulative', 'stlyDurationCumulative', 'stlwPctTotalVariance', 'stlyPctTotalVariance', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'durationCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.i18n.t('yield-manager.target-period-bookings.today') }),
          format: 'number',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwDurationCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          format: 'number',
          lineClasses: 'days-compare-lw',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare-lw',
          enabled: !stlwDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlyDurationCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          lineClasses: 'days-compare',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          format: 'number',
          enabled: !stlyDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwPctTotalVariance',
          label: this.i18n.t('yield-manager.target-period-bookings.pw_var', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          lineClasses: 'days-compare-lw',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare-lw',
          format: 'percent',
          enabled: !stlwDateError,
          category: 'right',
          excludeFromHover: true
        }, {
          key: 'stlyPctTotalVariance',
          label: this.i18n.t('yield-manager.target-period-bookings.py_var', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          lineClasses: 'days-compare',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          format: 'percent',
          enabled: !stlyDateError,
          category: 'right',
          excludeFromHover: true
        },
        // These are the fields for the hoverovers
        {
          key: 'countCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.res_count'),
          format: 'number',
          enabled: true
        }, {
          key: 'durationCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_days'),
          format: 'number',
          enabled: true
        }, {
          key: 'amountCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_revenue'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          enabled: true
        }, {
          key: 'arrivalYieldCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }, {
          key: 'yieldCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }]
      }), Ember.Object.create({
        label: '' + this.i18n.t('yield-manager.target-period-bookings.hourly', { type: this.i18n.t('yield-manager.target-period-bookings.reservation_count') }),
        leftAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.hourly_add_axis'),
        tableRows: this.get('resTableRows'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.res_count') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'formattedHour', 'count', 'stlwCount', 'stlyCount', 'duration', 'amountCumulative', 'arrivalYield', 'yield', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'count',
          label: this.i18n.t('yield-manager.target-period-bookings.todays_add'),
          format: 'number',
          lineClasses: 'reservations',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwCount',
          label: this.i18n.t('yield-manager.target-period-bookings.pw_add', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          format: 'number',
          lineClasses: 'reservations-compare-lw',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare-lw',
          enabled: !stlwDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlyCount',
          label: this.i18n.t('yield-manager.target-period-bookings.py_add', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          lineClasses: 'reservations-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare',
          format: 'number',
          enabled: !stlyDateError,
          category: 'left',
          excludeFromHover: true
        },
        // These are the fields for the hoverovers
        {
          key: 'count',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.res_count'),
          format: 'number',
          enabled: true
        }, {
          key: 'duration',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_days'),
          format: 'number',
          enabled: true
        }, {
          key: 'amount',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_revenue'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          enabled: true
        }, {
          key: 'arrivalYield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }, {
          key: 'yield',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }]
      }), Ember.Object.create({
        label: '' + this.i18n.t('yield-manager.target-period-bookings.cumulative', { type: this.i18n.t('yield-manager.target-period-bookings.reservation_count') }),
        leftAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.cumulative_add_axis'),
        rightAxisTitle: '' + this.i18n.t('yield-manager.target-period-bookings.pct_variance'),
        tableRows: this.get('resTableRows'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.target-period-bookings.res_count_var') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'formattedHour', 'countCumulative', 'stlwCountCumulative', 'stlyCountCumulative', 'stlwPctCountVariance', 'stlyPctCountVariance', 'count', 'duration', 'amountCumulative', 'arrivalYield', 'yield', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'countCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.i18n.t('yield-manager.target-period-bookings.today') }),
          format: 'number',
          lineClasses: 'reservations',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwCountCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          format: 'number',
          lineClasses: 'reservations-compare-lw',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare-lw',
          enabled: !stlwDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlyCountCumulative',
          label: this.i18n.t('yield-manager.target-period-bookings.cumulative_timeframe', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          lineClasses: 'reservations-compare',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare',
          enabled: !stlyDateError,
          category: 'left',
          excludeFromHover: true
        }, {
          key: 'stlwPctCountVariance',
          label: this.i18n.t('yield-manager.target-period-bookings.pw_var', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
          format: 'percent',
          lineClasses: 'reservations-compare-lw',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare-lw',
          enabled: !stlwDateError,
          category: 'right',
          excludeFromHover: true
        }, {
          key: 'stlyPctCountVariance',
          label: this.i18n.t('yield-manager.target-period-bookings.py_var', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
          format: 'percent',
          lineClasses: 'reservations-compare',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare',
          enabled: !stlyDateError,
          category: 'right',
          excludeFromHover: true
        },
        // These are the fields for the hoverovers
        {
          key: 'countCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.res_count'),
          format: 'number',
          enabled: true
        }, {
          key: 'durationCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_days'),
          format: 'number',
          enabled: true
        }, {
          key: 'amountCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.run_revenue'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          enabled: true
        }, {
          key: 'arrivalYieldCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }, {
          key: 'yieldCumulative',
          label: this.i18n.t('yield-manager.booking-trends.arrival-days.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          enabled: true
        }]
      })];
    }),

    queryUrl: Ember.computed('aggregationType', function () {
      var params = {
        aggregationType: this.get('aggregationType'),
        rollupStatus: 'ROLLUP',
        aggregate: false,
        fulfillmentType: 'unfulfilled',
        groupBy: 'ALL'
      };

      return '/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params);
    }),

    _fireRequeryObserver: Ember.observer('hourType', 'zoomStartDate', 'zoomEndDate', 'filterChange', function () {
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var tz = this.get('tz');

      var view = JSON.parse(JSON.stringify(this.get('model.view')));

      if (this.get('aggregationType') === 'HOURLY_BOOKING_AGGREGATOR') {
        // 'HOURLY_BOOKING_AGGREGATOR' requires 'LEVEL_HIERARCHY' to be 'LEVEL_EXACT'
        Ember.set(view.dashboardViewParameters, 'LEVEL_HIERARCHY', 'LEVEL_EXACT');

        // set groupBy to BOOK_DATE
        Ember.set(view.dashboardViewParameters, 'GROUP_BY', this.get('hourType'));

        // set viewBy to HOUR
        Ember.set(view.dashboardViewParameters, 'VIEW_BY', 'HOUR');
      }

      // Set the dates to the zoomed dates
      Ember.set(view.dashboardViewParameters, 'BEGIN_DATE', _moment.default.tz(this.get('zoomStartDate'), tz));
      Ember.set(view.dashboardViewParameters, 'END_DATE', _moment.default.tz(this.get('zoomEndDate'), tz));

      if (this.get('stlwDateErrorParam')) {
        Ember.set(view.dashboardViewParameters, 'STLW_BEGIN_DATE', null);
        Ember.set(view.dashboardViewParameters, 'STLW_END_DATE', null);
      }

      if (this.get('stlyDateErrorParam')) {
        Ember.set(view.dashboardViewParameters, 'STLW_BEGIN_DATE', null);
        Ember.set(view.dashboardViewParameters, 'STLW_END_DATE', null);
      }

      this.set('loading', true);
      return this.ajax.post(this.get('queryUrl'), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(view, tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;

        if (collections.length < 1) {
          _this.set('data', []);
          return;
        }

        var mapped = {};
        var settings = _this.get('customer.settings');
        collections.rejectBy('subContentType', 'FULL_SPAN').forEach(function (e) {
          // need to shift hour by 1
          var hour = parseInt(e.groupByValue) + 1;
          e.groupByValue = '' + hour % 24;

          var key = e.beginDate;

          mapped[key] = e.dataValues;
          //add the slice
          mapped[key]['formattedDate'] = (0, _dateFormatter.dateFormatter)(e.dataValues.beginDate, { settings: settings });
          mapped[key]['formattedHour'] = (0, _moment.default)(e.groupByValue, 'h').format('HH:00');
          mapped[key]['slice'] = (0, _moment.default)(e.groupByValue, 'h');
          mapped[key]['currencyCode'] = e.currencyCode;
        });

        _this.set('data', mapped);
      }).catch(function () {
        _this.set('data', []);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    resTableRows: Ember.computed('i18n.locale', 'model.gross', 'currencyCode', function () {
      return [{
        valueField: 'count',
        label: this.i18n.t('yield-manager.target-period-bookings.res', { timeframe: this.i18n.t('yield-manager.target-period-bookings.today') }),
        format: 'number'
      }, {
        valueField: 'stlwCount',
        label: this.i18n.t('yield-manager.target-period-bookings.res', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
        format: 'number'
      }, {
        valueField: 'stlyCount',
        label: this.i18n.t('yield-manager.target-period-bookings.res', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
        format: 'number'
      }, {
        valueField: 'arrivalYield',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        valueField: 'yield',
        label: this.i18n.t('yield-manager.target-period-bookings.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }];
    }),

    tableRows: Ember.computed('i18n.locale', 'currencyCode', function () {
      return [{
        valueField: 'duration',
        label: this.i18n.t('yield-manager.target-period-bookings.days', { timeframe: this.i18n.t('yield-manager.target-period-bookings.today') }),
        format: 'number'
      }, {
        valueField: 'stlwDuration',
        label: this.i18n.t('yield-manager.target-period-bookings.days', { timeframe: this.get('model.view.dashboardViewParameters.CP1_LABEL') }),
        format: 'number'
      }, {
        valueField: 'stlyDuration',
        label: this.i18n.t('yield-manager.target-period-bookings.days', { timeframe: this.get('model.view.dashboardViewParameters.CP2_LABEL') }),
        format: 'number'
      }, {
        valueField: 'arrivalYield',
        label: this.i18n.t('yield-manager.booking-trends.arrival-days.arrival_yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        valueField: 'yield',
        label: this.i18n.t('yield-manager.target-period-bookings.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }];
    }),

    actions: {
      resize: function resize() {
        Ember.run.later(function () {
          Ember.$(window).trigger('resize.menu-toggle');
          // Fire a resize event after the transition is complete

          if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
          } else {
            // Windows needs a little more time
            Ember.run.later(function () {
              // for IE and other old browsers
              // causes deprecation warning on modern browsers
              var evt = document.createEvent('UIEvents');
              evt.initUIEvent('resize', true, false, window, 0);
              window.dispatchEvent(evt);
            }, 100);
          }
        }, 400);
      }
    }
  });
});