define('ux-pcc/components/rules/conditions/ceiling-occ-pct-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values'], function (exports, _ruleConditionValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    type: 'percent',

    filterConditions: Ember.computed('i18n.locale', function () {
      return [{ value: 'GTEQ', label: ' >= ' }];
    })
  });
});