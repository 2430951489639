define('ux-pcc/serializers/audit-calendar-request', ['exports', 'ux-data/serializers/audit-calendar-request'], function (exports, _auditCalendarRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _auditCalendarRequest.default;
    }
  });
});