define('ux-pcc/components/charts/chart-performance-summary', ['exports', 'moment', 'ux-pcc/components/charts/chart-basic', 'ux-components/utils/format-currency', 'ux-pcc/utils/charts/mark-today'], function (exports, _moment, _chartBasic, _formatCurrency, _markToday) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({
    _labelPeriod: function _labelPeriod(valueText, tz) {
      var m = (0, _moment.default)(valueText).tz(tz);
      return m.format('ddd') + '\n\r' + m.format('L').substring(0, 5);
    },
    _config: function _config() {
      var _this = this;

      var tz = this.get('tz.customer');
      var currencyCode = this.get('context.price.defaultCurrencyCode') || 'USD';

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this.get('data') && _this.get('data').length > 0) {

          // Actual Occupancy
          graphs.push({
            title: 'OTB',
            balloonFunction: function balloonFunction() {
              return '';
            },
            fillAlphas: 0.5,
            fillColors: '#4FACF8',
            lineAlpha: 0.5,
            lineColor: '#4FACF8',
            type: 'column',
            clustered: false,
            id: 'otb chart-color--0',
            valueField: 'otb',
            valueAxis: 'percentOccupancy'
          });

          // Average Daily Rate
          graphs.push({
            title: 'ADR',
            balloonFunction: function balloonFunction(item) {
              return '<div>\n                                      <span class=\'bubble-label\'>' + _this.get('i18n').t('customer._overview.week_chart.bar') + ':</span>\n                                      <span class=\'bubble-value\'> ' + (0, _formatCurrency.default)(item.dataContext['bar'], currencyCode) + '</span>\n                                    </div>\n                                    <div>\n                                      <span class=\'bubble-label\'>' + _this.get('i18n').t('customer._overview.week_chart.adr') + ':</span>\n                                      <span class=\'bubble-value\'> ' + (0, _formatCurrency.default)(item.dataContext['adr'], currencyCode) + '</span>\n                                    </div>\n                                    <div>\n                                      <span class=\'bubble-label\'>' + _this.get('i18n').t('customer._overview.week_chart.otb') + ':</span>\n                                      <span class=\'bubble-value percent\'> ' + item.dataContext['otb'] + '</span>\n                                    </div>';
            },
            fillAlphas: 0,
            lineColor: '#11C964',
            type: 'line',
            bullet: 'round',
            bulletSize: 12,
            bulletBorderAlpha: 1,
            bulletAlpha: 1,
            bulletColor: '#11C964',
            classNameField: 'bulletClass',
            useLineColorForBulletBorder: true,
            lineThickness: 2,
            id: 'adr chart-color--1',
            valueField: 'adr',
            valueAxis: 'adrAmount',
            clustered: true
          });
        }

        return graphs;
      };

      var calendars = this.get('data');
      var config = {
        type: 'serial',
        addClassNames: true,
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass',
          labelFunction: function labelFunction(valueText, serialDataItem) {
            return _this._labelPeriod(valueText, tz, calendars.indexOf(serialDataItem.dataContext));
          }
        },
        chartCursor: {
          balloonPointerOrientation: 'vertical',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: true,
          cursorAlpha: 0,
          color: '#F3FAFF',
          categoryBalloonEnabled: false
        },
        legend: {
          enabled: false
        },
        trendLines: [],
        graphs: generateGraphs(),
        valueAxes: [{
          id: 'percentOccupancy',
          autoGridCount: false,
          color: '#005B9F',
          gridAlpha: 0.1,
          gridCount: 0,
          guides: [{ value: 0, label: '0%' }, { value: 25, label: '25%' }, { value: 50, label: '50%' }, { value: 75, label: '75%' }, { value: 100, label: '100%' }, { value: 125, label: '125%' }],
          axisAlpha: 0,
          position: 'left',
          minimum: 0,
          maximum: 125,
          strictMinMax: true,
          unit: '%'
        }, {
          id: 'adrAmount',
          autoGridCount: false,
          color: '#11C964',
          gridAlpha: 0,
          gridCount: 6,
          axisAlpha: 0,
          position: 'right',
          minimum: 0.00,
          labelFunction: function labelFunction(value, valueText) {
            return (0, _formatCurrency.default)(valueText, currencyCode);
          }
        }],
        allLabels: [],
        balloon: {
          adjustBorderColor: true,
          borderAlpha: 0,
          shadowAlpha: 0,
          color: '#FFFFFF',
          fillColor: '#0B8CF5',
          textAlign: 'middle',
          verticalPadding: 15,
          horizontalPadding: 20
        },
        titles: [],
        dataProvider: calendars
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },
    _createChart: function _createChart() {
      var chart = this._super();
      var element = this.get('element');

      if (chart) {
        (0, _markToday.chartsMarkToday)(element, 3);
        chart.addListener('drawn', function () {
          return (0, _markToday.chartsMarkToday)(element, 3);
        });
      }
    }
  });
});