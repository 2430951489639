define('ux-pcc/controllers/rules-advanced/new', ['exports', 'ux-pcc/mixins/rules-advanced-form-controller'], function (exports, _rulesAdvancedFormController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_rulesAdvancedFormController.default, {

    templateRules: Ember.computed.alias('rulesAdvanced.model.templateRules'),
    successMessage: 'rule._form._success_modal.success_new_message',
    ruleNameLabel: 'rule._form.name',
    ruleDescriptionLabel: 'rule._form.description',

    isEdit: false,

    filteredTemplateRules: Ember.computed('templateRules', 'filteredTypes', function () {
      var types = this.filteredTypes.mapBy('type');
      return this.templateRules.filter(function (r) {
        return types.includes(r.ruleType);
      });
    }),

    actions: {
      copyTemplate: function copyTemplate(rule) {
        var _this = this;

        rule.copy().then(function (copy) {
          copy.set('id', null);
          copy.set('customer', _this.get('customer'));
          _this.set('model', copy);
        }).catch(function () {
          _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this.i18n.t('rule._form.failed_to_copy_rule_message'), 'danger');
        }).finally(function () {
          _this.set('copyMode', false);
        });
      }
    }
  });
});