define('ux-pcc/routes/rules-simple/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      policyType: {
        refreshModel: true
      }
    },

    notifications: Ember.inject.service(),

    model: function model(params) {
      var customer = this.modelFor('customer');
      return this.store.createRecord('rule', {
        customer: customer,
        ruleMethod: 'SET',
        ruleType: params.policyType,
        currencyCode: 'USD',
        filterValueRule: 'SIMPLE',
        children: [{
          ruleMethod: 'RULE',
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          children: []
        }]
      });
    },


    actions: {
      saveRuleSet: function saveRuleSet() {
        var _this = this;

        var model = this.get('controller.model');
        var beginDate = (0, _moment.default)(model.get('beginDate')).utc().format('YYYY-MM-DD');
        var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
        model.set('beginDate', (0, _moment.default)(beginDate).startOf('day'));
        model.set('endDate', endDate ? (0, _moment.default)(endDate).startOf('day') : null);
        model.save().then(function () {
          // Success!
          _this.controllerFor('rules-simple').send('refresh');
          _this.replaceWith('rules-simple');
          _this.notifications.success(_this.i18n.t('rules.index.success_create_message', { ruleName: model.get('shortDescription') }));
        }).catch(function (error) {
          return _this.notifications.error(error);
        });
      }
    }
  });
});