define('ux-pcc/controllers/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    defaultIcon: 'fa fa-exclamation-triangle',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('iconMappings', {
        0: 'fa fa-bolt',
        400: 'fa fa-exclamation-triangle',
        401: 'fa fa-login',
        403: 'fa fa-ban',
        404: 'fa fa-unlink',
        500: 'fa fa-fire',
        502: 'fa fa-bolt',
        503: 'fa fa-exclamation-triangle',
        504: 'fa fa-clock'
      });
    },


    icon: Ember.computed('code', 'status', 'iconMappings', function () {
      return this.get('iconMappings')[this.status] || this.get('defaultIcon');
    }),

    title: Ember.computed('status', 'i18n.locale', function () {
      return this.i18n.t('application.errors.' + this.status);
    }),

    defaultMessage: Ember.computed('status', 'i18n.locale', function () {
      return this.i18n.t('application.errors.' + this.status + '_message');
    }),

    message: Ember.computed.or('defaultMessage'),

    linkText: Ember.computed('status', 'i18n.locale', function () {
      return this.i18n.t('application.errors.' + this.status + '_action');
    }),

    description: Ember.computed('status', 'i18n.locale', function () {
      return this.i18n.t('application.errors.' + this.status + '_desc');
    }),

    solution: Ember.computed('status', 'i18n.locale', function () {
      return this.i18n.t('application.errors.' + this.status + '_solution');
    })

  });
});