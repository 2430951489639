define('ux-pcc/components/dashboard-widget/inventory-overview', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    loading: false,

    data: null,
    restriction: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('restriction', {});
      Ember.run.once(this, 'fetchData');
    },


    inventoryFreeze: Ember.computed('restriction', function () {
      return this.restriction && this.restriction.close;
    }),

    inventoryTitle: Ember.computed('inventoryFreeze', function () {
      if (Ember.get(this, 'inventoryFreeze')) {
        return this.i18n.t('customer._overview.inventory_freeze');
      }

      return this.i18n.t('customer._overview.inventory');
    }),

    fetchData: Ember.observer('customer.id', 'selectedDate', 'selectedProduct', function () {
      var _this = this;

      this.set('loading', true);
      Ember.RSVP.all([this._fetchRestrictions(), this._fetchInventory()]).finally(function () {
        return _this.set('loading', false);
      });
    }),

    _fetchRestrictions: function _fetchRestrictions() {
      var _this2 = this;

      var customerID = this.customer.id;
      var selectedDate = _moment.default.tz(this.selectedDate, this.tz.customer).startOf('day');

      this.set('restriction', null);

      if (Ember.get(this, 'showInventorySegments') && this.selectedProduct !== 'ALL') {
        return this.get('ajax').request('/rest/v1/inventoryRestrictions', {
          data: {
            customerID: customerID,
            productID: this.rollupProductIds[0],
            begin: selectedDate.format(),
            end: selectedDate.clone().add(1, 'day').startOf('day').format()
          }
        }).then(function (_ref) {
          var _ref$inventoryRestric = _ref.inventoryRestrictions,
              inventoryRestrictions = _ref$inventoryRestric === undefined ? [] : _ref$inventoryRestric;
          return _this2.set('restriction', inventoryRestrictions.length > 0 ? inventoryRestrictions[0] : {});
        });
      } else {
        return this.get('store').query('product', {
          customerID: customerID,
          parentID: 'f99999a0-0000-0000-0000-000000000000'
        }).then(function () {
          var products = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          if (products.length === 0) {
            _this2.set('restriction', {});
          }

          return _this2.get('ajax').request('/rest/v1/inventoryRestrictions', {
            data: {
              customerID: customerID,
              productID: Ember.get(products, 'firstObject.id'),
              begin: selectedDate.format(),
              end: selectedDate.clone().add(1, 'day').startOf('day').format()
            }
          }).then(function (_ref2) {
            var _ref2$inventoryRestri = _ref2.inventoryRestrictions,
                inventoryRestrictions = _ref2$inventoryRestri === undefined ? [] : _ref2$inventoryRestri;
            return _this2.set('restriction', inventoryRestrictions.length > 0 ? inventoryRestrictions[0] : {});
          });
        }).catch(function () {
          return _this2.set('restriction', {});
        });
      }
    },
    _fetchInventory: function _fetchInventory() {
      var _this3 = this;

      var customerID = this.customer.id;
      var selectedDate = _moment.default.tz(this.selectedDate, this.tz.customer).startOf('day');

      this.set('data', null);

      if (Ember.get(this, 'showInventorySegments')) {
        return this.get('ajax').request('/rest/v1/pms/dailyMetrics?ids[]=' + Ember.get(this, 'rollupProductIds').join('&ids[]='), {
          data: {
            customerID: this.customer.id,
            begindate: selectedDate.format('YYYY-MM-DD'),
            enddate: selectedDate.format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref3) {
          var _ref3$pMSDailyMetrics = _ref3.pMSDailyMetrics,
              pMSDailyMetrics = _ref3$pMSDailyMetrics === undefined ? [] : _ref3$pMSDailyMetrics;

          var consumed = 0;
          var oversell = 0;
          var ooo = 0;
          var remaining = 0;
          var managerHold = 0;
          var physical = 0;
          var yieldable = 0;

          pMSDailyMetrics.forEach(function (m) {
            consumed += (m.yieldable || 0) - (m.remaining || 0);
            oversell += m.inventoryMap.OVERSELL || 0;
            ooo += m.inventoryMap.OOO || 0;
            remaining += m.remaining || 0;
            managerHold += m.inventoryMap.MANAGERHOLD || 0;
            physical += m.physical || 0;
            yieldable += m.yieldable || 0;
          });

          _this3.set('data', _this3.createChartData(physical, yieldable, consumed, oversell, ooo, managerHold, remaining));
        });
      } else {
        return this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'INVENTORY',
            analytictype: 'COUNT',
            subcontenttype: 'PHYSICAL,YIELDABLE,OVERSELL,DO_NOT_SELL,CONSUMED,MANAGER_HOLD,OUT_OF_ORDER,BLOCKED',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: this.rollupStatus
          }
        }).then(function (_ref4) {
          var _ref4$analyticEntries = _ref4.analyticEntries,
              analyticEntries = _ref4$analyticEntries === undefined ? [] : _ref4$analyticEntries;

          // if no data, then return
          if (analyticEntries.length === 0) {
            return;
          }

          var stats = {};
          analyticEntries.forEach(function (stat) {
            var value = stat.dataValue ? parseFloat(stat.dataValue) : 0;

            if (stat.subContentType) {
              stats[(stat.contentType + '_' + stat.subContentType).toLowerCase()] = value;
            } else {
              stats[('' + stat.contentType).toLowerCase()] = value;
            }
          });

          if (!(stats.inventory_consumed || stats.inventory_consumed === 0)) {
            stats.inventory_consumed = 0;
          }

          if (stats.inventory_yieldable || stats.inventory_yieldable === 0) {
            stats.inventory_yield = stats.inventory_yieldable;
          } else {
            stats.inventory_yield = 0;
          }

          if ((stats.inventory_yield || stats.inventory_yield === 0) && (stats.inventory_consumed || stats.inventory_consumed === 0)) {
            stats.inventory_remaining = stats.inventory_yield - stats.inventory_consumed;
          }

          _this3.set('data', _this3.createChartData(stats.inventory_physical, stats.inventory_yield, stats.inventory_consumed, stats.inventory_oversell, stats.inventory_out_of_order, stats.inventory_manager_hold, stats.inventory_remaining));
        });
      }
    },
    createChartData: function createChartData(physical, yieldable, consumed, oversell, ooo, managerHold, remaining) {
      return {
        yieldable: yieldable,
        consumed: consumed,
        inventory: [{
          label: this.i18n.t('customer._overview.inventory_chart.sold'),
          value: consumed,
          pie_color_class: 'sold-content chart-color--0',
          label_classes: 'sold-content chart-color--0',
          value_classes: 'sold-content chart-color--0'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.overbooking'),
          value: oversell,
          pie_color_class: 'overbooking-content chart-color--1',
          label_classes: 'overbooking-content chart-color--1',
          value_classes: 'overbooking-content chart-color--1'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.out_of_order'),
          value: ooo,
          pie_color_class: 'ooo-content chart-color--2',
          label_classes: 'ooo-content chart-color--2',
          value_classes: 'ooo-content chart-color--2'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.rooms_remaining'),
          value: remaining,
          pie_color_class: 'remaining-content chart-color--3',
          label_classes: 'remaining-content chart-color--3',
          value_classes: 'remaining-content chart-color--3'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.manager_hold'),
          value: managerHold,
          pie_color_class: 'mh-content',
          label_classes: 'mh-content',
          value_classes: 'mh-content'
        }],
        legend: [{
          label: this.i18n.t('customer._overview.inventory_chart.physical_count'),
          value: physical,
          label_classes: 'physical-content chart-color--0',
          value_classes: 'physical-content chart-color--0'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.rooms_remaining'),
          value: remaining,
          label_classes: 'remaining-content chart-color--1',
          value_classes: 'remaining-content chart-color--1'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.out_of_order'),
          value: ooo,
          label_classes: 'ooo-content chart-color--2',
          value_classes: 'ooo-content chart-color--2'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.overbooking'),
          value: oversell,
          label_classes: 'overbooking-content chart-color--3',
          value_classes: 'overbooking-content chart-color--3'
        }, {
          label: this.i18n.t('customer._overview.inventory_chart.manager_hold'),
          value: managerHold,
          label_classes: 'mh-content',
          value_classes: 'mh-content'
        }]
      };
    }
  });
});