define('ux-pcc/components/flash-messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    flashMessagesObserver: Ember.observer('flashMessages.[]', function () {
      var container = this.get('containerElement');
      if (container) {
        Ember.$('#' + container).animate({ scrollTop: this.$().offset().top });
      }
    })
  });
});