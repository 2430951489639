define('ux-pcc/routes/rule-rates/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor('customer');
      return this.get('store').createRecord('rule', {
        customer: customer,
        ruleMethod: "SET"
      });
    },
    setupController: function setupController(controller, model) {
      // re-initialize controller so that validations don't fire on initial page load
      // when navigating back
      controller.init();

      this._super(controller, model);
      model.set('currencyCode', controller.get('currencyCode'));
    },


    actions: {
      ruleSaved: function ruleSaved() {
        // Go back to the rule rates list
        this.transitionTo('rule-rates');
      }
    }
  });
});