define('ux-pcc/components/dashboard-widget/facet-chart', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),

    data: null,
    loading: false,
    model: null,
    showComments: true,
    showReservationList: false,
    sliceResolution: 'day',
    tz: null,
    includeCancels: false,
    filterCategory: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    },


    currentSlide: Ember.computed('selectedPeriod', 'locked', {
      get: function get() {
        if (this.get('locked')) {
          return this.get('selectedPeriod') || 0;
        } else {
          return this.get('_currentSlide') || 0;
        }
      },
      set: function set(key, value) {
        this.set('_currentSlide', value);
        return value;
      }
    }),

    onToggleLocked: function onToggleLocked() {},
    onSlideChanged: function onSlideChanged() {},
    onGraphClick: function onGraphClick() {},

    axisTitle: Ember.computed('type', function () {
      return this.get('type') === 'reservation' ? this.i18n.t('yield-manager.facet-chart.reservations') : this.i18n.t('yield-manager.facet-chart.running_days');
    }),

    type: 'reservation',

    title: '',
    reservationPct: '',
    daysPct: '',
    yieldPct: '',
    revenuePct: '',
    reservationPy: '',
    daysPy: '',
    yieldPy: '',
    revenuePy: '',
    reservation: '',
    days: '',
    yield: '',
    revenue: '',
    showReservationActuals: false,
    showDaysActuals: false,
    showYieldActuals: false,
    showRevenueActuals: false,

    stlwDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLW_DATE_ERROR'),
    stlyDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_DATE_ERROR'),

    hideTopBar: Ember.computed('currentSlide', 'stlwDateErrorParam', 'stlyDateErrorParam', function () {
      var currentSlide = this.get('currentSlide');
      return currentSlide === 1 && this.get('stlwDateErrorParam') || currentSlide === 2 && this.get('stlyDateErrorParam');
    }),

    currencyCode: Ember.computed.readOnly('currentDataProvider.firstObject.currencyCode'),

    dateDisplay: Ember.computed('zoomStartDate', 'zoomEndDate', 'beginDate', 'endDate', 'currentSlide', function () {
      var currentSlide = this.get('currentSlide');
      var settings = this.get('customer.settings');
      var startDate = (currentSlide === 0 ? this.get('zoomStartDate') : this.get('beginDate')) || (0, _moment.default)();
      var endDate = (currentSlide === 0 ? this.get('zoomEndDate') : this.get('endDate')) || (0, _moment.default)();
      var formattedDate = this.get('hideTopBar') ? this.i18n.t('yield-manager.analyze.arrival_dates_error') : (0, _dateFormatter.dateFormatter)(startDate, { settings: settings }) + ' \u2014 ' + (0, _dateFormatter.dateFormatter)(endDate, { settings: settings });
      return this.i18n.t('yield-manager.analyze.arrival_dates') + ': ' + formattedDate;
    }),

    beginDate: Ember.computed('currentSlide', 'model.view.dashboardViewParameters', function () {
      var params = this.get('model.view.dashboardViewParameters') || {};
      var currentSlide = this.get('currentSlide');
      switch (currentSlide) {
        case 0:
          return Ember.get(params, 'BEGIN_DATE');
        case 1:
          return Ember.get(params, 'STLW_BEGIN_DATE');
        case 2:
          return Ember.get(params, 'STLY_BEGIN_DATE');
        default:
          return Ember.get(params, 'BEGIN_DATE');
      }
    }),

    endDate: Ember.computed('currentSlide', 'model.view.dashboardViewParameters', function () {
      var params = this.get('model.view.dashboardViewParameters') || {};
      var currentSlide = this.get('currentSlide');
      switch (currentSlide) {
        case 0:
          return Ember.get(params, 'END_DATE');
        case 1:
          return Ember.get(params, 'STLW_END_DATE');
        case 2:
          return Ember.get(params, 'STLY_END_DATE');
        default:
          return Ember.get(params, 'END_DATE');
      }
    }),

    dataFields: Ember.computed('title', 'i18n.locale', 'data', 'type', 'currencyCode', 'stlwDateErrorParam', 'stlyDateErrorParam', function () {
      var title = this.get('title');
      var data = this.get('data');

      return [
      // base
      Ember.Object.create({
        key: this.get('groupBy'),
        label: title,
        active: true,
        name: 'cy',
        exportConfig: this._generateExportConfig(data, 0),
        values: this._generateChartData(data, 0),
        balloonValues: this._generateBalloonData(data, 0)
      }),
      // compare period 1
      Ember.Object.create({
        key: this.get('groupBy'),
        label: title,
        active: true,
        name: 'cp1',
        noData: this.get('stlwDateErrorParam'),
        exportConfig: this._generateExportConfig(data, 1),
        values: this._generateChartData(data, 1),
        balloonValues: this._generateBalloonData(data, 1)
      }),
      // compare period 2
      Ember.Object.create({
        key: this.get('groupBy'),
        label: title,
        active: true,
        name: 'cp2',
        noData: this.get('stlyDateErrorParam'),
        exportConfig: this._generateExportConfig(data, 2),
        values: this._generateChartData(data, 2),
        balloonValues: this._generateBalloonData(data, 2)
      })];
    }),

    _generateExportConfig: function _generateExportConfig(data, slideIndex) {
      var _this = this;

      if (!data || data.length === 0) {
        return {};
      }

      var isReservationType = this.get('type') === 'reservation';

      var config = {
        enabled: true,
        libs: { autoLoad: false },
        exportFields: [],
        exportTitles: true,
        columnNames: {},
        menu: [{
          class: 'export-main',
          menu: [{
            label: this.i18n.t('charts.save_as').toString(),
            menu: ['CSV']
          }]
        }]
      };

      config.exportFields.push('formattedBeginDateCP' + slideIndex);
      config.exportFields.push('formattedEndDateCP' + slideIndex);
      config.columnNames['formattedBeginDateCP' + slideIndex] = 'formattedBeginDate';
      config.columnNames['formattedEndDateCP' + slideIndex] = 'formattedEndDate';

      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();

      data.forEach(function (item) {
        var baseCountField = isReservationType ? 'count_' + item.groupByValue : 'duration_' + item.groupByValue;
        var baseYieldField = 'yield_' + item.groupByValue;
        var basePercentField = isReservationType ? 'countPercent_' + item.groupByValue : 'durationPercent_' + item.groupByValue;

        var baseName = _this.i18n.t('yield-manager.file-prefix.ysga.facet-chart.' + _this.get('groupBy').toLowerCase()).toString();

        if (slideIndex === 0) {
          config.exportFields.push(baseCountField);
          config.columnNames[baseCountField] = baseCountField;

          config.exportFields.push(baseYieldField);
          config.columnNames[baseYieldField] = baseYieldField;

          config.exportFields.push(basePercentField);
          config.columnNames[basePercentField] = basePercentField;

          config.fileName = baseName + '_' + _this.i18n.t('yield-manager.file-prefix.ysga.cp' + slideIndex) + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss');
        } else if (slideIndex === 1) {
          var countField = isReservationType ? 'cp1Count_' + item.groupByValue : 'cp1Duration_' + item.groupByValue;
          config.exportFields.push(countField);
          config.columnNames[countField] = baseCountField;

          var yieldField = 'cp1Yield_' + item.groupByValue;
          config.exportFields.push(yieldField);
          config.columnNames[yieldField] = baseYieldField;

          var percentField = isReservationType ? 'cp1CountPercent_' + item.groupByValue : 'cp1DurationPercent_' + item.groupByValue;
          config.exportFields.push(percentField);
          config.columnNames[percentField] = basePercentField;

          config.fileName = baseName + '_' + _this.i18n.t('yield-manager.file-prefix.ysga.cp' + slideIndex) + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss');
        } else if (slideIndex === 2) {
          var _countField = isReservationType ? 'cp2Count_' + item.groupByValue : 'cp2Duration_' + item.groupByValue;
          config.exportFields.push(_countField);
          config.columnNames[_countField] = baseCountField;

          var _yieldField = 'cp2Yield_' + item.groupByValue;
          config.exportFields.push(_yieldField);
          config.columnNames[_yieldField] = baseYieldField;

          var _percentField = isReservationType ? 'cp2CountPercent_' + item.groupByValue : 'cp2DurationPercent_' + item.groupByValue;
          config.exportFields.push(_percentField);
          config.columnNames[_percentField] = basePercentField;

          config.fileName = baseName + '_' + _this.i18n.t('yield-manager.file-prefix.ysga.cp2') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss');
        }
      });

      config.exportFields.push('currencyCode');
      return config;
    },
    _generateChartData: function _generateChartData(data, slideIndex) {
      var _this2 = this;

      if (!data || data.length === 0) {
        return [];
      }

      var chartData = [];
      var isReservationType = this.get('type') === 'reservation';

      data.forEach(function (item, index) {
        var object = {
          label: item.label,
          format: 'number',
          bulletClass: 'bulletClass_' + item.groupByValue,
          enabled: true,
          category: 'left',
          groupByValue: item.groupByValue,
          filterCategory: _this2.get('filterCategory'),
          categoryValue: item.groupByValue
        };

        if (item.groupBy === 'BRAND' || item.groupBy === 'PRICE' || item.groupBy === 'DURATION' || item.groupBy === 'CHANNEL') {
          object.legendClass = 'facet-chart-color--' + item.groupBy.toLowerCase() + '_' + item.groupByValue.toLowerCase();
        } else {
          object.legendClass = 'facet-chart-color--' + index;
        }

        if (slideIndex === 0) {
          object.xField = isReservationType ? 'count_' + item.groupByValue : 'duration_' + item.groupByValue;
          object.yField = 'yield_' + item.groupByValue;
          object.valueField = isReservationType ? 'countPercent_' + item.groupByValue : 'durationPercent_' + item.groupByValue;
        } else if (slideIndex === 1) {
          object.xField = isReservationType ? 'cp1Count_' + item.groupByValue : 'cp1Duration_' + item.groupByValue;
          object.yField = 'cp1Yield_' + item.groupByValue;
          object.valueField = isReservationType ? 'cp1CountPercent_' + item.groupByValue : 'cp1DurationPercent_' + item.groupByValue;
        } else if (slideIndex === 2) {
          object.xField = isReservationType ? 'cp2Count_' + item.groupByValue : 'cp2Duration_' + item.groupByValue;
          object.yField = 'cp2Yield_' + item.groupByValue;
          object.valueField = isReservationType ? 'cp2CountPercent_' + item.groupByValue : 'cp2DurationPercent_' + item.groupByValue;
        }

        chartData.push(object);
      });

      return chartData;
    },
    _generateBalloonData: function _generateBalloonData(data, currentSlide) {
      var _this3 = this;

      if (!data || data.length === 0) {
        return [];
      }

      var chartData = [];
      var isReservationType = this.get('type') === 'reservation';

      data.forEach(function (item) {
        // CY vs STLY
        if (currentSlide === 0) {
          chartData.push({
            item: item.groupByValue,
            key: isReservationType ? 'countPercent_' + item.groupByValue : 'durationPercent_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.percent_of_total'),
            format: 'percent'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'count_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'stlyCount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_py'),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'pctChangeCount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'duration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'stlyDuration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_py'),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'pctChangeDuration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'yield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_cy'),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'stlyYield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_py'),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'pctChangeYield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'amount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_cy'),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'stlyAmount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_py'),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'pctChangeAmount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__revenue'
          });
        } else if (currentSlide === 1) {
          // CY vs CP1 (STLW)
          chartData.push({
            item: item.groupByValue,
            key: isReservationType ? 'cp1CountPercent_' + item.groupByValue : 'cp1DurationPercent_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.percent_of_total'),
            format: 'percent'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'count_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1Count_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_cp1', { timeframe: _this3.get('model.view.dashboardViewParameters.CP1_LABEL') }),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1PctChangeCount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'duration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1Duration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_cp1', { timeframe: _this3.get('model.view.dashboardViewParameters.CP1_LABEL') }),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1PctChangeDuration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'yield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_cy'),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1Yield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_cp1', { timeframe: _this3.get('model.view.dashboardViewParameters.CP1_LABEL') }),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1PctChangeYield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'amount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_cy'),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1Amount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_cp1', { timeframe: _this3.get('model.view.dashboardViewParameters.CP1_LABEL') }),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp1PctChangeAmount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__revenue'
          });
        } else if (currentSlide === 2) {
          // CY vs CP2 (STLY)
          chartData.push({
            item: item.groupByValue,
            key: isReservationType ? 'cp2CountPercent_' + item.groupByValue : 'cp2DurationPercent_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.percent_of_total'),
            format: 'percent'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'count_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2Count_' + item.groupByValue,
            label: currentSlide === 0 ? '' + _this3.i18n.t('yield-manager.facet-chart.reservation_py') : '' + _this3.i18n.t('yield-manager.facet-chart.reservation_cp2', { timeframe: _this3.get('model.view.dashboardViewParameters.CP2_LABEL') }),
            format: 'number',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2PctChangeCount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.reservation_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__reservations'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'duration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_cy'),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2Duration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_cp2', { timeframe: _this3.get('model.view.dashboardViewParameters.CP2_LABEL') }),
            format: 'number',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2PctChangeDuration_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.days_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__days'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'yield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_cy'),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2Yield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_cp2', { timeframe: _this3.get('model.view.dashboardViewParameters.CP2_LABEL') }),
            format: 'currency',
            formatHash: _this3.get('formatYieldOptions'),
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2PctChangeYield_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.yield_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__yield'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'amount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_cy'),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2Amount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_cp2', { timeframe: _this3.get('model.view.dashboardViewParameters.CP2_LABEL') }),
            format: 'currency',
            formatHash: _this3.get('formatRevenueOptions'),
            divClass: 'bubble-div bubble-div__revenue'
          });
          chartData.push({
            item: item.groupByValue,
            key: 'cp2PctChangeAmount_' + item.groupByValue,
            label: '' + _this3.i18n.t('yield-manager.facet-chart.revenue_pct'),
            format: 'percent',
            divClass: 'bubble-div bubble-div__revenue'
          });
        }
      });

      return chartData;
    },
    _getAdjustedView: function _getAdjustedView(view, group) {
      var modifiedView = Ember.Object.create(JSON.parse(JSON.stringify(view)));

      // if looking for channel data, and no channels selected, add all the top level channels
      if (group === 'CHANNEL') {
        var channels = modifiedView.get('dashboardViewParameters.CHANNEL');
        var groupNames = this.get('modelList');
        if (Ember.isEmpty(channels) && groupNames.length > 0) {
          var channelList = [];
          groupNames.forEach(function (channel) {
            return channelList.push(channel.get('channelCode'));
          });
          modifiedView.set('dashboardViewParameters.CHANNEL', channelList.join('|'));
        }
      }

      // if looking for product data, and no products selected, add all the top level products
      if (group === 'PRODUCT') {
        var products = modifiedView.get('dashboardViewParameters.PRODUCT_CODE');
        var masterProducts = this.get('modelList');
        if (Ember.isEmpty(products) && masterProducts.length > 0) {
          var productList = [];
          masterProducts.forEach(function (product) {
            return productList.push(product.get('externalReference'));
          });
          modifiedView.set('dashboardViewParameters.PRODUCT_CODE', productList.join('|'));
        }
      }

      return modifiedView;
    },
    _configureDataResponse: function _configureDataResponse(_ref, list, field, descriptionField) {
      var _ref$baseRequest = _ref.baseRequest,
          baseRequest = _ref$baseRequest === undefined ? [] : _ref$baseRequest,
          _ref$stlyRequest = _ref.stlyRequest,
          stlyRequest = _ref$stlyRequest === undefined ? [] : _ref$stlyRequest;

      var dataProvider = {};

      if (!baseRequest || baseRequest.length < 1) {
        this.set('data', []);
        this.set('currentTotalData', null);
        return;
      }

      var totalData = {};
      var totalEntry = baseRequest.findBy('groupByValue', 'TOTAL');

      if (totalEntry) {
        totalData.reservation = totalEntry.dataValues.count;
        totalData.days = totalEntry.dataValues.duration;
        totalData.yield = totalEntry.dataValues.yield;
        totalData.revenue = totalEntry.dataValues.amount;

        totalData.reservationPct = totalEntry.dataValues.stlyPctChangeCount;
        totalData.daysPct = totalEntry.dataValues.stlyPctChangeDuration;
        totalData.yieldPct = totalEntry.dataValues.stlyPctChangeYield;
        totalData.revenuePct = totalEntry.dataValues.stlyPctChangeAmount;

        totalData.reservationStly = totalEntry.dataValues.stlyCount;
        totalData.daysStly = totalEntry.dataValues.stlyDuration;
        totalData.yieldStly = totalEntry.dataValues.stlyYield;
        totalData.revenueStly = totalEntry.dataValues.stlyAmount;

        totalData.reservationCP1 = totalEntry.dataValues.stlwCount;
        totalData.daysCP1 = totalEntry.dataValues.stlwDuration;
        totalData.yieldCP1 = totalEntry.dataValues.stlwYield;
        totalData.revenueCP1 = totalEntry.dataValues.stlwAmount;

        totalData.reservationPctCP1 = totalEntry.dataValues.stlwPctChangeCount;
        totalData.daysPctCP1 = totalEntry.dataValues.stlwPctChangeDuration;
        totalData.yieldPctCP1 = totalEntry.dataValues.stlwPctChangeYield;
        totalData.revenuePctCP1 = totalEntry.dataValues.stlwPctChangeAmount;

        totalData.reservationCP2 = totalEntry.dataValues.stlyCount;
        totalData.daysCP2 = totalEntry.dataValues.stlyDuration;
        totalData.yieldCP2 = totalEntry.dataValues.stlyYield;
        totalData.revenueCP2 = totalEntry.dataValues.stlyAmount;

        totalData.reservationPctCP2 = totalEntry.dataValues.stlyPctChangeCount;
        totalData.daysPctCP2 = totalEntry.dataValues.stlyPctChangeDuration;
        totalData.yieldPctCP2 = totalEntry.dataValues.stlyPctChangeYield;
        totalData.revenuePctCP2 = totalEntry.dataValues.stlyPctChangeAmount;

        totalData.currencyCode = totalEntry.currencyCode;
      }

      var entries = baseRequest.rejectBy('groupByValue', 'TOTAL');
      if (this.get('groupBy') === 'DURATION') {
        entries.forEach(function (e) {
          return e.orderBy = list.findBy(field, e.groupByValue) ? list.findBy(field, e.groupByValue).get('orderBy') : 1000;
        });
        entries = entries.sortBy('orderBy');
      } else if (this.get('groupBy') === 'PRICE') {
        entries = entries.filter(function (e) {
          return list.findBy('codeValue', e.groupByValue);
        });
      }

      var settings = this.get('customer.settings');
      var viewParams = this.get('model.view.dashboardViewParameters') || [];
      dataProvider.formattedBeginDateCP0 = (0, _dateFormatter.dateFormatter)(this.get('zoomStartDate'), { settings: settings });
      dataProvider.formattedBeginDateCP1 = (0, _dateFormatter.dateFormatter)(Ember.get(viewParams, 'STLW_BEGIN_DATE'), { settings: settings });
      dataProvider.formattedBeginDateCP2 = (0, _dateFormatter.dateFormatter)(Ember.get(viewParams, 'STLY_BEGIN_DATE'), { settings: settings });

      dataProvider.formattedEndDateCP0 = (0, _dateFormatter.dateFormatter)(this.get('zoomEndDate'), { settings: settings });
      dataProvider.formattedEndDateCP1 = (0, _dateFormatter.dateFormatter)(Ember.get(viewParams, 'STLW_END_DATE'), { settings: settings });
      dataProvider.formattedEndDateCP2 = (0, _dateFormatter.dateFormatter)(Ember.get(viewParams, 'STLY_END_DATE'), { settings: settings });

      var pctChange = function pctChange(current, previous) {
        var parsedCurrent = parseFloat(current);
        var parsedPrevious = parseFloat(previous);
        return parsedPrevious === 0 ? 0 : (parsedCurrent - parsedPrevious) / parsedPrevious * 100;
      };

      entries.forEach(function (e, index) {
        if (e.groupByValue) {
          dataProvider.currencyCode = e.currencyCode;

          if (e.groupBy === 'BRAND' || e.groupBy === 'PRICE' || e.groupBy === 'DURATION' || e.groupBy === 'CHANNEL') {
            dataProvider['bulletClass_' + e.groupByValue] = 'facet-chart-color--' + e.groupBy.toLowerCase() + '_' + e.groupByValue.toLowerCase();
          } else {
            dataProvider['bulletClass_' + e.groupByValue] = 'facet-chart-color--' + index;
          }

          var count = e.dataValues.count;
          var cp1Count = e.dataValues.stlwCount;
          var cp2Count = e.dataValues.stlyCount;

          var yieldVal = e.dataValues.yield;
          var cp1Yield = e.dataValues.stlwYield;
          var cp2Yield = e.dataValues.stlyYield;

          var amount = e.dataValues.amount;
          var cp1Amount = e.dataValues.stlwAmount;
          var cp2Amount = e.dataValues.stlyAmount;

          var duration = e.dataValues.duration;
          var cp1Duration = e.dataValues.stlwDuration;
          var cp2Duration = e.dataValues.stlyDuration;

          // CY
          dataProvider['count_' + e.groupByValue] = count;
          dataProvider['yield_' + e.groupByValue] = yieldVal;
          dataProvider['amount_' + e.groupByValue] = amount;
          dataProvider['duration_' + e.groupByValue] = duration;
          dataProvider['countPercent_' + e.groupByValue] = e.dataValues.pctCountOfTotal;
          dataProvider['durationPercent_' + e.groupByValue] = e.dataValues.pctDurationOfTotal;

          // STLY
          dataProvider['stlyCount_' + e.groupByValue] = cp2Count;
          dataProvider['stlyYield_' + e.groupByValue] = cp2Yield;
          dataProvider['stlyAmount_' + e.groupByValue] = cp2Amount;
          dataProvider['stlyDuration_' + e.groupByValue] = cp2Duration;
          dataProvider['stlyCountPercent_' + e.groupByValue] = e.dataValues.stlyPctCountOfTotal;
          dataProvider['stlyDurationPercent_' + e.groupByValue] = e.dataValues.stlyPctDurationOfTotal;

          // CP1
          dataProvider['cp1Count_' + e.groupByValue] = cp1Count;
          dataProvider['cp1Yield_' + e.groupByValue] = cp1Yield;
          dataProvider['cp1Amount_' + e.groupByValue] = cp1Amount;
          dataProvider['cp1Duration_' + e.groupByValue] = cp1Duration;
          dataProvider['cp1CountPercent_' + e.groupByValue] = e.dataValues.stlwPctCountOfTotal;
          dataProvider['cp1DurationPercent_' + e.groupByValue] = e.dataValues.stlwPctDurationOfTotal;

          // CP2
          dataProvider['cp2Count_' + e.groupByValue] = cp2Count;
          dataProvider['cp2Yield_' + e.groupByValue] = cp2Yield;
          dataProvider['cp2Amount_' + e.groupByValue] = cp2Amount;
          dataProvider['cp2Duration_' + e.groupByValue] = cp2Duration;
          dataProvider['cp2CountPercent_' + e.groupByValue] = e.dataValues.stlyPctCountOfTotal;
          dataProvider['cp2DurationPercent_' + e.groupByValue] = e.dataValues.stlyPctDurationOfTotal;

          // CY change
          dataProvider['pctChangeCount_' + e.groupByValue] = pctChange(count, cp2Count);
          dataProvider['pctChangeDuration_' + e.groupByValue] = pctChange(duration, cp2Duration);
          dataProvider['pctChangeYield_' + e.groupByValue] = pctChange(yieldVal, cp2Yield);
          dataProvider['pctChangeAmount_' + e.groupByValue] = pctChange(amount, cp2Amount);

          // CP1 change
          dataProvider['cp1PctChangeCount_' + e.groupByValue] = pctChange(count, cp1Count);
          dataProvider['cp1PctChangeDuration_' + e.groupByValue] = pctChange(duration, cp1Duration);
          dataProvider['cp1PctChangeYield_' + e.groupByValue] = pctChange(yieldVal, cp1Yield);
          dataProvider['cp1PctChangeAmount_' + e.groupByValue] = pctChange(amount, cp1Amount);

          // CP2 change
          dataProvider['cp2PctChangeCount_' + e.groupByValue] = pctChange(count, cp2Count);
          dataProvider['cp2PctChangeDuration_' + e.groupByValue] = pctChange(duration, cp2Duration);
          dataProvider['cp2PctChangeYield_' + e.groupByValue] = pctChange(yieldVal, cp2Yield);
          dataProvider['cp2PctChangeAmount_' + e.groupByValue] = pctChange(amount, cp2Amount);

          if (stlyRequest.length !== 0) {
            var realStlyData = stlyRequest.findBy('groupByValue', e.groupByValue);
            var realStlyTotalData = stlyRequest.findBy('groupByValue', 'TOTAL');

            if (realStlyData) {
              dataProvider['stlyCount_' + e.groupByValue] = realStlyData.dataValues.stlyCount;
              dataProvider['stlyYield_' + e.groupByValue] = realStlyData.dataValues.stlyYield;
              dataProvider['stlyAmount_' + e.groupByValue] = realStlyData.dataValues.stlyAmount;
              dataProvider['stlyDuration_' + e.groupByValue] = realStlyData.dataValues.stlyDuration;
              dataProvider['stlyCountPercent_' + e.groupByValue] = realStlyData.dataValues.stlyPctCountOfTotal;
              dataProvider['stlyDurationPercent_' + e.groupByValue] = realStlyData.dataValues.stlyPctDurationOfTotal;

              dataProvider['pctChangeCount_' + e.groupByValue] = pctChange(count, realStlyData.dataValues.stlyCount);
              dataProvider['pctChangeDuration_' + e.groupByValue] = pctChange(duration, realStlyData.dataValues.stlyDuration);
              dataProvider['pctChangeYield_' + e.groupByValue] = pctChange(yieldVal, realStlyData.dataValues.stlyYield);
              dataProvider['pctChangeAmount_' + e.groupByValue] = pctChange(amount, realStlyData.dataValues.stlyAmount);
            }

            if (realStlyTotalData) {
              totalData.reservationPct = realStlyTotalData.dataValues.stlyPctChangeCount;
              totalData.daysPct = realStlyTotalData.dataValues.stlyPctChangeDuration;
              totalData.yieldPct = realStlyTotalData.dataValues.stlyPctChangeYield;
              totalData.revenuePct = realStlyTotalData.dataValues.stlyPctChangeAmount;

              totalData.reservationStly = realStlyTotalData.dataValues.stlyCount;
              totalData.daysStly = realStlyTotalData.dataValues.stlyDuration;
              totalData.yieldStly = realStlyTotalData.dataValues.stlyYield;
              totalData.revenueStly = realStlyTotalData.dataValues.stlyAmount;
            }
          }
        }
      });

      this.set('data', entries.map(function (d) {
        var newData = {};
        var model = list.findBy(field, d.groupByValue);
        newData.label = model ? model.get(descriptionField) : d.groupByValue;
        newData.groupBy = d.groupBy;
        newData.groupByValue = d.groupByValue;
        newData.groupByValue = d.groupByValue;
        return newData;
      }));
      this.set('currentTotalData', totalData);
      this.set('currentDataProvider', [dataProvider]);
    },


    _fireRequeryObserver: Ember.observer('zoomStartDate', 'zoomEndDate', 'filterChange', function () {
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    }),

    queryDataMethod: function queryDataMethod() {
      var _this4 = this;

      var tz = this.get('tz');
      var view = JSON.parse(JSON.stringify(this._getAdjustedView(this.get('model.view'), this.get('groupBy'))));

      // Set the dates to the zoomed dates
      var beginDate = _moment.default.tz(this.get('zoomStartDate'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('zoomEndDate'), tz).startOf('day');
      // Set the dates on the dashboardViewParameters
      Ember.set(view.dashboardViewParameters, 'BEGIN_DATE', beginDate);
      Ember.set(view.dashboardViewParameters, 'END_DATE', endDate);

      var queryParams = {
        begindateStr: beginDate.format('YYYY-MM-DD'),
        enddateStr: endDate.format('YYYY-MM-DD'),
        aggregationType: 'BOOKING_GROUPING_AGGREGATOR',
        rollupStatus: 'ROLLUP',
        accumulate: false,
        groupBy: this.get('groupBy'),
        locationType: '',
        returnType: ''
      };

      var requests = {
        baseRequest: this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(queryParams), {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref2) {
          var _ref2$collections = _ref2.collections,
              collections = _ref2$collections === undefined ? [] : _ref2$collections;
          return collections;
        })
      };

      var bookDate = _moment.default.tz(view.dashboardViewParameters.BOOK_DATE, tz).startOf('day');

      var stlyBookDate = bookDate.clone().subtract(52, 'weeks');
      var stlyBeginDate = beginDate.clone().subtract(52, 'weeks');
      var stlyEndDate = endDate.clone().subtract(52, 'weeks');

      var cp2BookDate = _moment.default.tz(view.dashboardViewParameters.STLY_BOOK_DATE, tz).startOf('day');
      var cp2BeginDate = _moment.default.tz(view.dashboardViewParameters.STLY_BEGIN_DATE, tz).startOf('day');
      var cp2EndDate = _moment.default.tz(view.dashboardViewParameters.STLY_END_DATE, tz).startOf('day');

      var cp2DoesNotMatchStly = !stlyBookDate.isSame(cp2BookDate, 'day') || !stlyBeginDate.isSame(cp2BeginDate, 'day') || !stlyEndDate.isSame(cp2EndDate, 'day');

      if (cp2DoesNotMatchStly) {
        Ember.set(view.dashboardViewParameters, 'STLY_BOOK_DATE', stlyBookDate);
        Ember.set(view.dashboardViewParameters, 'STLY_BEGIN_DATE', stlyBeginDate);
        Ember.set(view.dashboardViewParameters, 'STLY_END_DATE', stlyEndDate);

        requests.stlyRequest = this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(queryParams), {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref3) {
          var _ref3$collections = _ref3.collections,
              collections = _ref3$collections === undefined ? [] : _ref3$collections;
          return collections;
        });
      }

      this.set('loading', true);
      this.set('data', null);
      return Ember.RSVP.hash(requests, 'YSGA facet chart data').then(function (data) {
        return _this4._configureDataResponse(data, _this4.get('modelList') || [], _this4.get('modelField'), _this4.get('modelDescriptionField') || 'shortDescription');
      }).catch(function () {
        return _this4.set('data', []);
      }).finally(function () {
        return _this4.set('loading', false);
      });
    },


    actions: {
      toggleLocked: function toggleLocked() {
        this.get('onToggleLocked')(this.get('currentSlide'));
      },
      onSlideChanged: function onSlideChanged() {
        if (this.get('locked')) {
          this.get('onSlideChanged')(this.get('currentSlide'));
        }
      },
      updateFilter: function updateFilter(data, categoryField, categoryValue) {
        var value = categoryValue;
        data.filterCategory = categoryField;
        if (value && value.indexOf('_') >= 0) {
          var splitIndex = value.indexOf('_') + 1;
          if (splitIndex < value.length) {
            value = value.substr(splitIndex);
          }
        }

        data.categoryValue = value;
        this.updateFilter(data);
      },
      toggleActuals: function toggleActuals(value) {
        this.toggleProperty('show' + value + 'Actuals');
      },
      viewReservationList: function viewReservationList() {
        var view = JSON.parse(JSON.stringify(this._getAdjustedView(this.get('model.view'), this.get('groupBy'))));
        // set limit of 1000 results
        Ember.set(view.dashboardViewParameters, 'DISPLAY_MAXIMUM', '1000');

        this.openModal('yield-manager/reservation-list', {
          title: this.get('title'),
          beginDate: this.get('zoomStartDate'),
          endDate: this.get('zoomEndDate'),
          tz: this.get('tz'),
          customerID: this.get('customer.id'),
          view: view,
          customer: this.get('customer'),
          formatRevenueOptions: this.get('formatRevenueOptions')
        });
      }
    }
  });
});