define('ux-pcc/utils/override-availability-result', ['exports', 'ux-components/utils/override-availability-result'], function (exports, _overrideAvailabilityResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _overrideAvailabilityResult.default;
    }
  });
});