define('ux-pcc/components/charts/bar-chart', ['exports', 'moment', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/helpers/wrap-negative', 'ux-pcc/utils/date-formatter', 'ux-components/utils/format-currency'], function (exports, _moment, _chartBasic, _wrapNegative, _dateFormatter, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    classNames: ['bar-chart'],

    rotate: true,

    valueSuffix: '',
    exportPosition: 'top-right',
    enableExport: false,

    _formatValue: function _formatValue(value, precisionsHash) {
      if (precisionsHash.hasOwnProperty('currencyCode')) {
        return (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(value, precisionsHash.currencyCode, { hash: precisionsHash }));
      } else {
        return (0, _wrapNegative.wrapNegative)(value, precisionsHash);
      }
    },
    _generateBalloonText: function _generateBalloonText(item, graphConfig) {
      var _generateHTML = function _generateHTML(label, classStyle, value) {
        return '<div>\n                <span class=\'bubble-label\'>' + label + ': </span>\n                <span class=\'bubble-value ' + classStyle + '\'>' + value + '</span>\n              </div>';
      };

      var displayHTML = '';
      if (graphConfig.fields) {
        for (var i = 0; i < graphConfig.fields.length; i++) {
          var label = item.dataContext[graphConfig.labels[i]];
          var value = item.dataContext[graphConfig.fields[i]];
          var style = value < 0 ? 'negative-value-class' : '';
          value = this._formatValue(value, Ember.assign({}, graphConfig.precisions[i], graphConfig.suffixes[i]));
          displayHTML += _generateHTML(label, style, value);
        }
      }

      return displayHTML;
    },
    _config: function _config() {
      var _this = this;

      var data = this.get('_data');
      var config = this.get('graphConfig');

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this.get('data.length') > 0) {

          data.forEach(function (e) {
            return config.get('graphs').forEach(function (g) {
              return e[g.valueField + 'Class'] = e[g.valueField] < 0 ? 'negative-value-class' : '';
            });
          });

          var createGraphObject = function createGraphObject(graphConfig) {
            var suffix = !Ember.isEmpty(graphConfig.valueSuffix) ? graphConfig.valueSuffix : '';
            var options = graphConfig.precisionHash;
            options['suffix'] = suffix;

            return {
              title: graphConfig.title,
              fillAlphas: 1,
              lineAlpha: 1,
              type: 'column',
              clustered: true,
              id: 'segment-values ' + graphConfig.colorClass,
              valueField: '' + graphConfig.valueField,
              classNameField: graphConfig.valueField + 'Class',
              valueAxis: 'segment',
              labelText: graphConfig.hideChartLabel ? '' : '[[' + graphConfig.valueField + ']]',
              labelFunction: function labelFunction(item) {
                return _this._formatValue(item.dataContext[graphConfig.valueField], options);
              },
              balloonFunction: function balloonFunction(item) {
                return config.showBalloon ? _this._generateBalloonText(item, graphConfig) : '';
              },
              balloon: { fillAlpha: 1 }
            };
          };

          config.get('graphs').forEach(function (graph) {
            return graphs.push(createGraphObject(graph));
          });
        }

        return graphs;
      };

      var valueAxes = [{
        id: 'segment',
        autoGridCount: true,
        gridAlpha: 0.1,
        gridCount: 8,
        axisAlpha: 0,
        position: 'left',
        minimum: 0,
        title: this.get('segmentTitle'),
        usePrefixes: true,
        guides: [{
          id: 'zero-marker',
          value: 0,
          lineAlpha: 1,
          lineThickness: 3,
          above: true,
          inside: true,
          fillColor: '#000000',
          lineColor: '#000000'
        }]
      }];

      var guides = valueAxes[0].guides;

      if (this.get('daysOverall')) {
        guides.push({
          id: 'overall-days',
          value: this.get('daysOverall'),
          lineAlpha: 0.7,
          lineThickness: 2,
          above: false,
          inside: true,
          fillColor: '#21578A',
          lineColor: '#21578A'
        });
      }

      if (this.get('yieldOverall')) {
        guides.push({
          id: 'overall-yield',
          value: this.get('yieldOverall'),
          lineAlpha: 0.7,
          lineThickness: 2,
          above: false,
          inside: true,
          fillColor: '#E37222',
          lineColor: '#E37222'
        });
      }

      if (this.get('revOverall')) {
        guides.push({
          id: 'overall-rev',
          value: this.get('revOverall'),
          lineAlpha: 0.7,
          lineThickness: 2,
          above: false,
          inside: true,
          fillColor: '#565A5C',
          lineColor: '#565A5C'
        });
      }

      var _config = {
        hideCredits: true,
        type: 'serial',
        addClassNames: true,
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        rotate: this.get('rotate'),

        synchronizeGrid: true,

        trendLines: [],
        allLabels: [],
        balloon: {},
        titles: [],

        graphs: generateGraphs(),

        valueAxes: valueAxes,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass'
        },

        legend: {
          enabled: false
        },

        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          enabled: config.showBalloon,
          cursorAlpha: 0,
          cursorColor: '#4D96CB',
          categoryBalloonEnabled: false
        },

        language: this.get('i18n.locale'),

        dataProvider: data
      };

      if (this.get('enableExport')) {
        var settings = this.get('customer.settings');
        var now = (0, _moment.default)();
        _config.export = {
          enabled: true,
          libs: { autoLoad: false },
          position: this.get('exportPosition'),
          fileName: this.get('filePrefix') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.get('i18n').t('charts.save_as').toString(),
              menu: ['JSON']
            }]
          }]
        };
      }

      if (this.get('title')) {
        _config['titles'].push({ text: this.get('title') });
      }

      return _config;
    },
    _createChart: function _createChart() {
      var _this2 = this;

      var data = this.get('data');
      if (!data || data.length <= 0) {
        return;
      }

      var chart = this._super();
      var element = this.get('element');

      if (chart) {
        chart.addListener('drawn', function () {
          return _this2._styleNegativeLabels(element);
        });

        if (this.get('enableCategoryBubble')) {
          var config = this.get('graphConfig');

          if (!chart.categoryAxis.listeners) {
            chart.categoryAxis.listeners = [];
          }

          chart.categoryAxis.listeners.push({
            event: 'rollOverItem',
            method: function method(event) {
              event.target.setAttr('cursor', 'default');
              event.chart.balloon.followCursor(true);
              event.chart.balloon.showBalloon(event.serialDataItem.dataContext[config['categoryTitleField']]);
            }
          });

          chart.categoryAxis.listeners.push({
            event: 'rollOutItem',
            method: function method(event) {
              return event.chart.balloon.hide();
            }
          });
        }

        Ember.run.scheduleOnce('afterRender', this, function () {
          return chart.validateData();
        }, 0);
      }
    },
    _styleNegativeLabels: function _styleNegativeLabels(element) {
      var $labels = Ember.$(element).find('.amcharts-chart-div .amcharts-graph-label tspan');
      $labels.each(function (i, label) {
        if (label.textContent.includes('(') && label.textContent.includes(')')) {
          Ember.$(label).css({
            'font-weight': 'bold',
            'fill': '#f84d27'
          });
        }
      });
    }
  });
});