define('ux-pcc/controllers/customer/performance/matrix-pricing-target', ['exports', 'moment', 'ux-components/utils/moment-range', 'lodash', 'accounting', 'ux-components/utils/format-currency'], function (exports, _moment, _momentRange, _lodash, _accounting, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['startDate'],

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.computed.readOnly('session.data.authenticated'),
    hasSetupError: false,
    autoAllocationRule: null,

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),
    startDate: null,

    dirtyCalendars: Ember.computed.filterBy('model.calendars', 'isDirty'),

    setupMessage: Ember.computed('i18n.locale', function () {
      return this.i18n.t('customer.performance.matrix-pricing.no_rule') + ' - ' + this.i18n.t('customer.performance.matrix-pricing.messages.no_rule');
    }),

    _mapValues: function _mapValues(model) {
      var _this = this;

      var tz = this.tz.get("customer");
      var today = _moment.default.tz(tz).startOf("day");
      var mapped = {};

      // Create map keys for all dates searched
      if (model.searchedDates && model.searchedDates.startDate && model.searchedDates.endDate) {
        (0, _momentRange.default)(model.searchedDates.startDate, model.searchedDates.endDate).forEach(function (m) {
          var key = m.tz(tz).format();
          if (!mapped[key]) {
            mapped[key] = {};
          }
        });
      }

      var mapInventoryValue = function mapInventoryValue(e) {
        var m = Ember.get(e, 'beginDate');
        var key = _moment.default.tz(m, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        if (!mapped[key]['inventory']) {
          mapped[key]['inventory'] = {};
        }

        mapped[key]['inventory'][Ember.get(e, 'subContentType').toLowerCase()] = parseFloat(Ember.get(e, 'dataValue'));
      };

      // Munge inventory
      model.inventory.forEach(mapInventoryValue);

      var mapCalendarValue = function mapCalendarValue(e) {
        var m = Ember.get(e, 'ruleDate');
        var key = _moment.default.tz(m, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        var children = Ember.get(e, 'children') || [];
        var times = children.filterBy('externalReference', 'EVENT_TIME');
        var freezes = children.filterBy('externalReference', 'FREEZE').concat(children.filterBy('externalReference', 'FUTURE_FREEZE')).concat(children.filterBy('externalReference', 'CEILING_RULE'));

        var calendars = children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

        mapped[key]["eventTimes"] = times.length > 0 ? times.get('firstObject') : null;
        mapped[key]["freezes"] = freezes.length > 0 ? freezes.get('firstObject') : null;
        mapped[key]["calendars"] = calendars;
      };

      // Munge calendars
      var rules = this.get('ruleSet.children');
      model.calendars.forEach(mapCalendarValue);

      var mapCalculations = function mapCalculations(e) {
        var m = Ember.get(e, 'entryDate');
        var key = _moment.default.tz(m, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        mapped[key].avgOccupancy = _accounting.default.formatNumber(Ember.get(e, "dataValues.average4WkOccupancy"), 0);
        mapped[key].avgPyOccupancy = _accounting.default.formatNumber(Ember.get(e, "dataValues.stlyOccupancy"), 0);
        mapped[key].targetAvgOccupancy = mapped[key].avgOccupancy;

        mapped[key].avgDilution = _accounting.default.formatNumber(Ember.get(e, "dataValues.dilution"), 0);
        mapped[key].targetAvgDilution = mapped[key].avgDilution;

        mapped[key].itemsRemaining = Ember.get(e, "dataValues.remaining");
        mapped[key].targetItemsRemaining = Ember.get(e, "dataValues.remaining");

        mapped[key].currentOtbOccupancy = _accounting.default.formatNumber(Ember.get(e, "dataValues.cyOccupancy"), 0);
        mapped[key].targetOtbOccupancy = parseInt(mapped[key].currentOtbOccupancy);

        mapped[key].otbAdr = (0, _formatCurrency.default)(parseFloat(Ember.get(e, "dataValues.adr")), Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } });
        mapped[key].otbRevenue = (0, _formatCurrency.default)(parseFloat(Ember.get(e, "dataValues.actualRevenue")), Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } });
        mapped[key].targetOtbRevenue = (0, _formatCurrency.default)(parseFloat(Ember.get(e, "dataValues.actualRevenue")), Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } });
        mapped[key].avgAdr = (0, _formatCurrency.default)(parseFloat(Ember.get(e, "dataValues.adr")), Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } });
        mapped[key].pyAvgAdr = (0, _formatCurrency.default)(parseFloat(Ember.get(e, "dataValues.pyAdr")), Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } });
        mapped[key].targetAvgAdr = parseFloat(mapped[key].avgAdr);

        mapped[key].pyVar = parseFloat(_accounting.default.formatNumber(mapped[key].avgOccupancy - mapped[key].avgPyOccupancy, 1));
        mapped[key].targetPyVar = parseFloat(mapped[key].pyVar);

        mapped[key].pyAdrVar = parseFloat((0, _formatCurrency.default)(mapped[key].avgAdr - mapped[key].pyAvgAdr, Ember.get(e, 'currencyCode') || "USD", { hash: { hideSymbol: true } }));
        mapped[key].targetPyAdrVar = parseFloat(mapped[key].pyAdrVar);
      };

      // Munge calculations
      if (model.calculations) {
        model.calculations.forEach(mapCalculations);
      }

      // Finalize data

      // --> Price Points
      var pricePoints = {};
      // let yieldableVals = {};
      // let allocationVals = {};
      // let consumedVals = {};
      // let freezes = {};
      // let autoAdjustmentRules = {};
      var maxAllocatedPricePoints = 0;

      var mapAutoAdjustmentRules = function mapAutoAdjustmentRules(e) {
        var m = Ember.get(e, 'ruleDate');
        var key = _moment.default.tz(m, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        mapped[key]['autoAdjustmentRules'] = e;
      };

      // Munge auto allocation rules
      if (model.autoAllocationCalendars) {
        model.autoAllocationCalendars.forEach(mapAutoAdjustmentRules);
      }

      rules = (rules || []).rejectBy('externalReference', 'EVENT_TIME');
      rules.forEach(function (rule /*, i*/) {
        pricePoints['rule_' + Ember.get(rule, 'amount')] = {
          id: 'rule_' + Ember.get(rule, 'amount'),
          label: Ember.get(rule, 'shortDescription'),
          amount: Ember.get(rule, 'amount'),
          values: {},
          labelClassNames: "matrix-pricing-label__price-point"
        };
      });

      Object.keys(mapped).forEach(function (key) {
        // Remove non daily calendars
        var date = _moment.default.tz(key, tz);
        if (!date.isSame((0, _moment.default)(date).startOf('day'))) {
          delete mapped[key];
          return;
        }

        var map = mapped[key];
        map['classNames'] = [];
        var calendars = map.calendars || rules;

        map['columnTitle'] = _this.get('i18n').t("customer.performance.matrix-pricing-target.copy");

        if (date.isBefore(today)) {
          map['viewOnly'] = true;
          map['columnTitle'] = _this.get('i18n').t("customer.performance.matrix-pricing-target.date_in_the_past");
        }

        if (date.isSame(today, 'day')) {
          map['classNames'].push('matrix-pricing-target__header--active');
        }

        map['rules'] = rules;

        map['allPricePoints'] = [];
        map['pricePoints'] = [];
        map['targetPricePoints'] = [];

        // --> Inventory
        mapped[key].yieldable = {
          count: _accounting.default.formatNumber(Ember.get(map, 'inventory.yieldable'), 0),
          hideCalculated: _this.get('hideCalculated')
        };

        mapped[key].allocations = {
          count: 0,
          percent: 0,
          hideCalculated: _this.get('hideCalculated')
        };

        // Consumed
        var remainingConsumed = Ember.get(map, 'inventory.consumed');
        var consumedPct = Ember.get(map, 'inventory.yieldable') ? Math.floor(Ember.get(map, 'inventory.consumed') / Ember.get(map, 'inventory.yieldable') * 100) : 0;

        mapped[key].consumed = {
          count: Ember.get(map, 'inventory.consumed'),
          targetCount: Ember.get(map, 'inventory.consumed'),
          percent: consumedPct,
          targetPercent: consumedPct,
          hideCalculated: _this.get('hideCalculated')
        };

        // let activeIndex = void(0);
        // let activeRule = void(0);

        if (!map['viewOnly'] && mapped[key]['freezes']) {
          map['classNames'].push('matrix-pricing-target__header--freeze');
          map['demoMode'] = true;
          map['redirectRoute'] = 'customer.performance.matrix-pricing';
          map['columnTitle'] = _this.get('i18n').t("customer.performance.matrix-pricing-target.freeze");
        }

        if (!map['viewOnly'] && !map['demoMode'] && mapped[key]['eventTimes']) {
          map['classNames'].push('matrix-pricing-target__header--event');
          map['viewOnly'] = true;
          map['demoMode'] = true;
          map['redirectRoute'] = 'customer.performance.matrix-pricing';
          map['columnTitle'] = _this.get('i18n').t("customer.performance.matrix-pricing-target.event");
        }

        // Freeze Rules
        // freezes[key] = {
        //   value: {freeze: void(0), date: key}
        // };
        //
        // if (mapped[key]['freezes']) {
        //   let amount = mapped[key]['freezes'].get('amount');
        //   if (amount) {
        //     freezes[key] = {
        //       value: {
        //         freeze: "true",
        //         amount: formatCurrency(amount, mapped[key]['freezes'].get('currencyCode') || "USD"),
        //         date: key
        //       }
        //     };
        //   } else {
        //     freezes[key] = {
        //       value: {freeze: "true", date: key}
        //     };
        //   }
        // }

        // Auto Allocation Rules
        // autoAdjustmentRules[key] = {
        //   value: {autoAllocation: void(0), date: key}
        // };

        if (!map['viewOnly'] && !map['demoMode'] && mapped[key]['autoAdjustmentRules'] || _this.get('hasOngoingAutoAllocationRule') && _moment.default.tz(key, _this.tz.get('customer')).startOf('day').isSameOrAfter(_moment.default.tz(_this.get('autoAllocationRule.beginDate'), _this.tz.get('customer')).startOf('day'))) {
          // autoAdjustmentRules[key] = {
          //   value: {
          //     autoAllocation: "true",
          //     date: key
          //   }
          // };
          map['classNames'].push('matrix-pricing-target__header--auto');
          map['demoMode'] = true;
          map['redirectRoute'] = 'customer.performance.auto-matrix-pricing';
          map['columnTitle'] = _this.get('i18n').t("customer.performance.matrix-pricing-target.auto");
        }

        // --> Price Points

        var accumulatedActualPercent = 0;
        // let remainingSinceActive = 0;
        var ruleIds = [];

        calendars = calendars.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

        calendars.forEach(function (rule) {
          var ruleId = 'rule_' + Ember.get(rule, 'amount');
          ruleIds.push(ruleId);
          var allocation = _this._computeAllocation(rule);

          accumulatedActualPercent += allocation.value;

          var consumed = 0;

          var allocatedSoFar = Math.floor(accumulatedActualPercent / 100 * Ember.get(map, 'inventory.yieldable'));
          var allocated = allocatedSoFar - mapped[key].allocations.count;

          mapped[key].allocations.count += allocated;

          // Consume all available inventory to fill up to the active point
          if (remainingConsumed > 0) {
            consumed = Math.min(allocated, remainingConsumed);
            remainingConsumed -= consumed;
          }

          var remaining = allocated - consumed;

          // if (activeIndex) {
          //   remainingSinceActive += remaining;
          // }

          // if (activeIndex === void(0) &&
          //   (consumed < allocated || remainingConsumed === 0) &&
          //   (allocated > 0 || i === calendars.get('length') - 1) &&
          //   (remaining > 0)) {
          //   if (!activeIndex) {
          //     remainingSinceActive += remaining;
          //   }
          //
          //   activeIndex = ruleId;
          //   activeRule = formatCurrency(rule.get('amount'), rule.get('currencyCode') || "USD");
          //   remaining = remainingSinceActive;
          // }

          // Set active for lead time if it is present
          // if (allocation.leadTime !== void(0) &&
          //   today.isAfter(moment(date).subtract(allocation.leadTime + 1, 'days')) &&
          //   (remaining > 0 || remainingSinceActive > 0)) {
          //   if (!activeIndex) {
          //     remainingSinceActive += remaining;
          //   }
          //
          //   if (activeIndex) {
          //     for (let i = 0; i < ruleIds.length - 1; i++) {
          //       if (pricePoints[ruleIds[i]]) {
          //         pricePoints[ruleIds[i]].values[key].values.remaining = 0;
          //       }
          //     }
          //   }
          //
          //   activeIndex = ruleId;
          //   activeRule = formatCurrency(rule.get('amount'), rule.get('currencyCode') || "USD");
          //   remaining = remainingSinceActive;
          // }

          // Setup price point's styling and data values
          if (pricePoints[ruleId]) {
            var leadTime = allocation.leadTime;
            var leadLabel = leadTime && leadTime !== 1 ? _this.get('i18n').t('customer.performance.matrix-pricing.many') : _this.get('i18n').t('customer.performance.matrix-pricing.one');

            var pricePoint = JSON.parse(JSON.stringify(pricePoints[ruleId]));
            pricePoint.values = {
              val: allocation.value,
              consumed: consumed,
              allocated: allocated,
              allocatedPercent: allocation.value,
              remaining: remaining,
              lead: leadTime,
              leadLabel: leadLabel
            };
            pricePoint.alternateRow = mapped[key].pricePoints.length % 2 === 0;
            mapped[key].allPricePoints.push(pricePoint);
            if (pricePoint.values.allocatedPercent > 0) {
              mapped[key].pricePoints.push(pricePoint);
              mapped[key].targetPricePoints.push({
                id: pricePoint.id,
                values: JSON.parse(JSON.stringify(pricePoint.values))
              });
            }

            pricePoints[ruleId].values[key] = {
              values: {
                val: allocation.value,
                consumed: consumed,
                allocated: allocated,
                remaining: remaining,
                lead: leadTime,
                leadLabel: leadLabel
              }
            };
          }
        });

        mapped[key].allocations.percent = Math.floor(mapped[key].allocations.count / Ember.get(map, 'inventory.yieldable') * 100);
        mapped[key].allocations.testAllocPct = Math.floor(mapped[key].allocations.count / Ember.get(map, 'inventory.yieldable') * 100);

        maxAllocatedPricePoints = Math.max(maxAllocatedPricePoints, mapped[key].targetPricePoints.length);
        return map;
      });

      return {
        pricePoints: pricePoints,
        // yieldableVals: yieldableVals,
        // allocationVals: allocationVals,
        // consumedVals: consumedVals,
        mappedArray: Object.keys(mapped).map(function (key) {
          mapped[key].classNames = mapped[key].classNames.join(' ');
          // Ensure all days have the same number of targetPricePoints, minimum of 6, not more than total number of price points,
          // and two open if possible
          var blankTargetPricePoints = Math.max(maxAllocatedPricePoints, 4) - mapped[key].targetPricePoints.length + Math.min(mapped[key].allPricePoints.length - maxAllocatedPricePoints, 2);

          for (var i = 0; i < blankTargetPricePoints; i++) {
            mapped[key].targetPricePoints.push({
              id: "",
              values: {
                val: 0,
                consumed: 0,
                allocated: 0,
                remaining: 0,
                lead: null
              } });
          }
          return { key: key, values: mapped[key] };
        })
      };
    },


    _processData: Ember.observer('model', function () {

      var model = this.get('model');
      var mapped = this._mapValues(model);

      // All days will have the same number of price points, so just grab the first set
      this.set('targetPricePoints', Ember.get(mapped.mappedArray, 'firstObject.values.targetPricePoints'));

      this.set('targetData', mapped.mappedArray);

      this.set('pricePoints', (0, _lodash.values)(mapped.pricePoints));
    }),

    _overlayModel: function _overlayModel(data, key) {
      var mapped = this._mapValues(data);

      this.get('pricePoints').forEach(function (pp) {
        var cell = pp.values[key];
        if (pp.id.match(/^rule_/)) {
          Ember.setProperties(cell, ((0, _lodash.values)(mapped.pricePoints).find(function (p) {
            return p.id === pp.id;
          }) || { values: {} }).values[key]);
        } else {
          Ember.setProperties(cell, mapped[pp.id][key]);
        }
      });
    },
    _composeRule: function _composeRule(key, rowId, allocation) {
      var _this2 = this;

      // allocation rule
      var boundedTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterCondition":"<%= data.condition %>",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.lower %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          },\n          {\n            "filterCondition":"LTEQ",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.upper %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          }\n        ],\n        "filterType":"ALL",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // lead time rule
      var leadTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterCondition":"LTEQ",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.upper %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          },\n          {\n            "filterCondition":"LT",\n            "filterField":"ARRIVAL_DATE",\n            "filterField2":"BOOKING_DATE",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.leadTime %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          }\n        ],\n        "filterType":"ALL",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // any filter rule
      var anyTemplate = (0, _lodash.template)('\n      {\n        "children":[<%= data.children %>],\n        "filterType":"ANY",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // base rule
      var ruleTemplate = (0, _lodash.template)('\n      {\n        "amount":<%= data.amount %>,\n        "amountRule":"VALUE",\n        "calculationRule":"AMOUNT_NOOP",\n        "day1":"Y",\n        "day2":"Y",\n        "day3":"Y",\n        "day4":"Y",\n        "day5":"Y",\n        "day6":"Y",\n        "day7":"Y",\n        "ruleMethod":"RULE",\n        "shortDescription":"<%= data.title %>",\n        "children":[<%= data.children %>]\n      }', { variable: "data" });

      var tz = this.tz.get('customer');
      var calendar = this.get('model').calendars.find(function (c) {
        var m = (0, _moment.default)(Ember.get(c, 'ruleDate'));
        return m.tz(tz).format() === key;
      });

      var children = calendar ? Ember.get(calendar, 'children') || [] : [];
      var rules = children.get('length') > 0 && children.get('firstObject') && children.get('firstObject.amount') ? children : this.get('ruleSet.children');
      var newRules = [];
      var timeRules = rules.filterBy('externalReference', 'EVENT_TIME') || [];
      var freezeRules = rules.filterBy('externalReference', 'FREEZE') || [];
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'FUTURE_FREEZE'));
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'CEILING_RULE'));
      rules = rules.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE') || [];
      // compose pricepoint rules
      var aggregateOcc = 0;
      rules.forEach(function (rule /*, i*/) {
        var ruleRowId = 'rule_' + Ember.get(rule, 'amount');

        var newRuleContent = ruleTemplate({
          title: Ember.get(rule, 'shortDescription'),
          amount: Ember.get(rule, 'amount')
        });

        var currentAllocation = _this2._computeAllocation(rule);

        var isPassThrough = (currentAllocation.value > 0 || currentAllocation.leadTime !== void 0) && ruleRowId !== rowId;
        var isAllocated = (allocation.value > 0 || allocation.leadTime !== void 0) && ruleRowId === rowId;

        if (isAllocated || isPassThrough) {
          var allChildren = [];
          var data = isAllocated ? allocation : currentAllocation;

          allChildren.push(boundedTemplate({
            condition: aggregateOcc > 0 ? "GT" : "GTEQ",
            lower: aggregateOcc,
            upper: aggregateOcc + data.value
          }));

          if (data.leadTime !== void 0) {
            allChildren.push(leadTemplate({
              leadTime: data.leadTime,
              upper: aggregateOcc + data.value
            }));
          }

          aggregateOcc += data.value;
          newRuleContent = ruleTemplate({
            title: Ember.get(rule, 'shortDescription'),
            amount: Ember.get(rule, 'amount'),
            children: anyTemplate({ children: allChildren.toString() })
          });
        }

        newRules.push(JSON.parse(newRuleContent));
      });
      // compose event time rule
      if (timeRules.length > 0) {
        newRules.push(timeRules[0].serialize());
      }
      // compose freeze rule
      if (freezeRules.length > 0) {
        newRules.push(freezeRules[0].serialize());
      }
      return newRules;
    },
    _computeAllocation: function _computeAllocation(rule) {
      var allocation = {
        value: 0,
        leadTime: void 0
      };

      var filterCollection = Ember.get(rule, 'children.firstObject.children') || [];
      var isEmpty = filterCollection === void 0 || filterCollection.get("length") === 0;
      if (isEmpty) {
        return allocation;
      }

      var occFilters = filterCollection.get('firstObject.children') || [];
      allocation.value = occFilters.filterBy('filterField', 'OCC_PCT').mapBy('filterValue').reduce(function (a, b) {
        return Math.abs(a - b);
      }, 0);

      if (!parseInt(allocation.value)) {
        allocation.value = 0;
      }

      var hasLeadTime = filterCollection.get('length') > 1;
      if (hasLeadTime) {

        var leadTimeChildren = Ember.get(filterCollection.objectAt(1), 'children');

        if (!leadTimeChildren) {
          console.error("Malformed lead time rule - missing children");
        } else {
          var leadTime = leadTimeChildren.filterBy('filterField', "ARRIVAL_DATE").filterBy('filterField2', "BOOKING_DATE").get('firstObject.filterValue');

          allocation.leadTime = parseInt(leadTime) || parseInt(leadTime) === 0 ? parseInt(leadTime) : void 0;
        }
      }

      return allocation;
    },
    _updateCalendar: function _updateCalendar(rules, key) {
      var dirty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      var tz = this.tz.get("customer");
      var date = (0, _moment.default)(key).tz(tz);

      var calendar = this.get('model').calendars.find(function (c) {
        return (0, _moment.default)(Ember.get(c, 'ruleDate')).tz(tz).valueOf() === date.valueOf();
      });

      if (calendar) {
        Ember.set(calendar, 'children', rules);
        Ember.set(calendar, 'isDirty', dirty);
      } else {
        calendar = {
          ruleDate: date.format(),
          children: rules,
          isDirty: dirty
        };

        this.get('model').calendars.addObject(calendar);
      }

      var results = {
        inventory: this.get('model').inventory.filter(function (i) {
          return (0, _moment.default)(Ember.get(i, 'beginDate')).tz(tz).valueOf() === date.valueOf();
        }),
        calendars: [calendar]
      };

      if (this.get('model.rateAnalytics')) {
        results.rateAnalytics = this.get('model.rateAnalytics');
      }

      this._overlayModel(results, key);
    },
    _buildPricePoints: function _buildPricePoints(key) {
      var pricePoints = JSON.parse(JSON.stringify(this.get('pricePoints')));
      var day = this.get('targetData').findBy('key', key);

      // Loop through the price points, and set the allocation/lead information
      pricePoints.forEach(function (pricePoint) {
        var pricePointKeyItem = pricePoint.values[key];
        var targetPricePoint = day.values.targetPricePoints.findBy('id', pricePoint.id);

        if (targetPricePoint) {
          // copy the targetValues to the keyValues
          Ember.set(pricePointKeyItem, 'values.val', targetPricePoint.values.allocatedPercent);
          Ember.set(pricePointKeyItem, 'values.allocated', targetPricePoint.values.allocated);
          Ember.set(pricePointKeyItem, 'values.consumed', targetPricePoint.values.consumed);
          Ember.set(pricePointKeyItem, 'values.remaining', targetPricePoint.values.remaining);
          Ember.set(pricePointKeyItem, 'values.lead', targetPricePoint.values.lead);
        } else {
          // blank out the values for the keyValues
          Ember.set(pricePointKeyItem, 'values.allocated', 0);
          Ember.set(pricePointKeyItem, 'values.consumed', 0);
          Ember.set(pricePointKeyItem, 'values.remaining', 0);
          Ember.set(pricePointKeyItem, 'values.val', 0);
          Ember.set(pricePointKeyItem, 'values.lead', null);
        }
      });

      return pricePoints;
    },


    actions: {
      applyDay: function applyDay(day) {
        var _this3 = this;

        this.set('loading', true);
        var pricePoints = this._buildPricePoints(day.key);

        pricePoints.forEach(function (pricePoint) {
          var pricePointDay = pricePoint.values[day.key];
          var allocation = {
            value: Ember.get(pricePointDay, 'values.val') ? parseInt(Ember.get(pricePointDay, 'values.val')) : 0,
            leadTime: Ember.get(pricePointDay, 'values.lead') || Ember.get(pricePointDay, 'values.lead') === 0 ? parseInt(Ember.get(pricePointDay, 'values.lead')) : void 0
          };
          var rules = _this3._composeRule(day.key, Ember.get(pricePoint, 'id'), allocation);

          _this3._updateCalendar(rules, day.key);
        });

        this.send('requestUpdate', this.get('dirtyCalendars'));
      },
      applyWeek: function applyWeek() {
        var _this4 = this;

        this.set('loading', true);
        // loop through all the days of the week
        var days = this.get('targetData');

        days.forEach(function (day) {
          if (!day.values.viewOnly && !day.values.demoMode) {
            var pricePoints = _this4._buildPricePoints(day.key);

            pricePoints.forEach(function (pricePoint) {
              var pricePointDay = pricePoint.values[day.key];
              var allocation = {
                value: Ember.get(pricePointDay, 'values.val') ? parseInt(Ember.get(pricePointDay, 'values.val')) : 0,
                leadTime: Ember.get(pricePointDay, 'values.lead') || Ember.get(pricePointDay, 'values.lead') === 0 ? parseInt(Ember.get(pricePointDay, 'values.lead')) : void 0
              };
              var rules = _this4._composeRule(day.key, Ember.get(pricePoint, 'id'), allocation);

              _this4._updateCalendar(rules, day.key);
            });
          }
        });

        this.send('requestUpdate', this.get('dirtyCalendars'));
      },
      ruleCopyRequested: function ruleCopyRequested(day) {
        if (!day.values.viewOnly && !day.values.demoMode) {
          var columnKey = day.key;
          // Reassemble the price points with the updated values
          var pricePoints = this._buildPricePoints(columnKey);

          // Reassemble the rules to match the pricePoints selected
          this.send('showModal', 'customer/performance/-matrix-pricing-target-copy-modal', {
            isCopy: true,
            selectedSlice: (0, _moment.default)(columnKey).tz(this.get('tz').customer),
            date: (0, _moment.default)(columnKey).tz(this.get('tz').customer),
            pricePoints: pricePoints,
            isMobile: this.get('isMobile'),
            hasPreviousPricingSave: false,
            isPreviousPricingSave: false
          });
        } else if (day.values.redirectRoute) {
          this.transitionToRoute(day.values.redirectRoute);
        }
      }
    }
  });
});