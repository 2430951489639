define('ux-pcc/routes/yield-manager/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      refreshChildRoute: function refreshChildRoute() {
        this.controllerFor('yield-manager.dashboard.index').notifyPropertyChange('views');
      }
    }
  });
});