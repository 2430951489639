define('ux-pcc/components/competitive-set/form', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_flashable.default, {

    formModel: null,

    canEdit: Ember.computed(function () {
      return true;
    }),

    filteredCompetitors: Ember.computed.oneWay('formModel.xrefs'),

    markers: Ember.computed('filteredCompetitors', function () {
      return this.get('filteredCompetitors').map(function (property) {
        property = property.hasOwnProperty('object') ? property.object : property;
        var address = (property.get('addresses') || []).findBy('addressType', 'BUSINESS') || (property.get('addresses') || []).findBy('addressType', 'MAIN');
        if (address) {
          return {
            id: property.get('id'),
            lat: address.get('geolocationLatitude'),
            lng: address.get('geolocationLongitude'),
            infoWindow: {
              content: '<div class=\'available-property__map-marker\'>\n                        <div class=\'available-property__map-marker--name\'>\n                          ' + property.get('name') + '\n                        </div>\n                        <div>\n                          <div class=\'available-property__map-marker--address\'>\n                            <div>\n                              ' + address.get('address1') + '\n                            </div>\n                            <div>\n                              ' + address.get('city') + ', ' + address.get('state') + ' ' + address.get('postalCode') + '\n                            </div>\n                          </div>\n                        </div>\n                      </div>'
            }
          };
        } else {
          return { id: null, name: null, lat: null, long: null };
        }
      });
    }),

    primaryMarker: Ember.computed('customer.model.addresses.[]', function () {
      var address = (this.get('customer.model.addresses') || []).findBy('addressType', 'BUSINESS') || (this.get('customer.model.addresses') || []).findBy('addressType', 'MAIN');
      if (address) {
        return {
          lat: address.get('geolocationLatitude'),
          long: address.get('geolocationLongitude')
        };
      } else {
        return { id: null, lat: null, long: null };
      }
    }),

    actions: {
      submit: function submit() {
        this.get('onSubmit')(this.get('formModel'));
      },
      removeCompetitor: function removeCompetitor(property) {
        var model = this.get('formModel');
        model.get('xrefs').removeObject(property);
        this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('competitive-set.-form.remove_success', { competitor: property.get('name') }), 'success');
      },
      showModal: function showModal() {
        this.get('onShowModal')('competitive-set/-add-competitors-modal', this.get('formModel'));
      },
      close: function close() {
        this.get('onClose')();
      }
    }
  });
});