define('ux-pcc/routes/customer/performance/personal-dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    model: function model() /*params*/{
      var customer = this.modelFor('customer');
      var settings = customer.get('settings') || [];
      var myInsightsGroup = 4;
      var myInsightsGroupSetting = settings.findBy('settingCode', 'MY_INSIGHTS_GROUP');
      if (myInsightsGroupSetting) {
        myInsightsGroup = myInsightsGroupSetting.get('settingValue');
      }

      var myInsightsUrl = '/embed/dashboards/1';
      var myInsightsUrlSetting = settings.findBy('settingCode', 'MY_INSIGHTS_URL');
      if (myInsightsUrlSetting) {
        myInsightsUrl = myInsightsUrlSetting.get('settingValue');
      }

      return Ember.RSVP.hash({
        dashboard: this.ajax.request('/rest/v1/looker/embedsso', {
          data: {
            customerID: customer.get('id'),
            embed: myInsightsUrl,
            group_ids: [myInsightsGroup]
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});