define('ux-pcc/routes/rules/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('rule', params.id, { include: 'children' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      model.set('customer', controller.get('customer'));
      model.set('currencyCode', controller.get('currencyCode'));
    },


    actions: {
      ruleSaved: function ruleSaved() {
        // Go back to the rule list
        this.transitionTo('rules');
      }
    }
  });
});