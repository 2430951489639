define('ux-pcc/components/rules/rule-filter/month-day', ['exports', 'ux-components/utils/pad-number'], function (exports, _padNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    value: null,

    parseValue: function parseValue(value) {
      // IE 11 doesn't support named capture groups
      // const regexp = /(?:(?<year>\d+)?-?)-(?<month>\d+)-(?<day>\d+)/;
      //
      // const match = value ? value.match(regexp) || {} : {};
      //
      // return match.groups || {};

      var regexp = /(?:(\d+)?-?)-(\d+)-(\d+)/;

      var match = value ? value.match(regexp) || {} : {};
      return {
        years: match[1],
        months: match[2],
        days: match[3]
      };
    },
    formatValue: function formatValue(obj) {
      var _obj$years = obj.years,
          years = _obj$years === undefined ? '-' : _obj$years,
          _obj$months = obj.months,
          months = _obj$months === undefined ? '1' : _obj$months,
          _obj$days = obj.days,
          days = _obj$days === undefined ? '1' : _obj$days;


      return years + '-' + (0, _padNumber.default)(months, 2) + '-' + (0, _padNumber.default)(days, 2);
    },
    handleSet: function handleSet(field, value) {
      var obj = this.parseValue(this.get('value'));
      obj[field] = value;
      this.set('value', this.formatValue(obj));

      return parseInt(value);
    },


    months: Ember.computed('value', {
      get: function get() {
        return parseInt(this.parseValue(this.get('value')).months || 1);
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    }),

    days: Ember.computed('value', {
      get: function get() {
        return parseInt(this.parseValue(this.get('value')).days || 1);
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    })
  });
});