define('ux-pcc/components/dashboard-widget/booking-trends', ['exports', 'moment', 'ux-pcc/mixins/query-data-in-viewport'], function (exports, _moment, _queryDataInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queryDataInViewport.default, {

    tagName: 'div',
    classNames: ['booking-trends'],

    ajax: Ember.inject.service(),

    isMobile: Ember.computed.bool('deviceType.mobile'),

    currentSlide: 0,

    _trendData: null,

    // Define required items for query-data-in-viewport mixin
    queryDataWatchProperties: null,

    init: function init() {
      this.set('_trendData', []);
      this.set('queryDataWatchProperties', ['customer.id', 'selectedDate', 'dataType', 'locationType', 'reservationCategory', 'aggregationType']);

      this._super.apply(this, arguments);
    },


    // 'DETAIL' if property, or 'ROLLUP'
    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    _addTrend: function _addTrend(key, title, fullTitle, data, trends) {
      var trendData = data.findBy('subContentType', key);
      if (trendData) {
        trends.push({
          key: key,
          title: title,
          fullTitle: fullTitle,
          data: trendData,
          dataDate: this.get('selectedDate')
        });
      }
    },


    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      return version !== '1' ? 'BOOKING_TREND_AGGREGATOR_V2' : 'BOOKING_TREND_AGGREGATOR';
    }),

    trends: Ember.computed('_trendData.length', function () {
      var data = this.get('_trendData');
      var trends = [];
      if (data && data.length > 0) {
        this._addTrend('TYFS', this.i18n.t('charts.booking-trends.month_abrv'), this.i18n.t('charts.booking-trends.month'), data, trends);
        this._addTrend('MTD', this.i18n.t('charts.booking-trends.month_to_date_abrv'), this.i18n.t('charts.booking-trends.month_to_date'), data, trends);
        this._addTrend('REM', this.i18n.t('charts.booking-trends.remainder_abrv'), this.i18n.t('charts.booking-trends.remainder'), data, trends);
        this._addTrend('TW', this.i18n.t('charts.booking-trends.this_week_abrv'), this.i18n.t('charts.booking-trends.this_week'), data, trends);
        this._addTrend('YST', this.i18n.t('charts.booking-trends.yesterday_abrv'), this.i18n.t('charts.booking-trends.yesterday'), data, trends);
      }

      return trends;
    }),

    yesterday: Ember.computed(function () {
      return (0, _moment.default)().startOf('day').subtract(1, 'day');
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      this.set('_trendData', []);

      var customerID = this.get('customer.id');
      var selectedDate = this.get('selectedDate');

      var queryParameters = {
        customerid: customerID,
        begindateStr: selectedDate.clone().startOf('month').format('YYYY-MM-DD'),
        enddateStr: selectedDate.clone().endOf('month').format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        groupBy: 'ALL',
        sourceLocationCode: this.get('dataType'),
        locationType: this.get('locationType'),
        returnType: this.get('reservationCategory'),
        nocache: this.get('nocache')
      };

      return this.ajax.request('rest/v1/analyticEntryAggregation/process', {
        track: true,
        data: queryParameters
      }).then(function (_ref) {
        var collections = _ref.collections;
        return _this.set('_trendData', collections);
      });
    },


    actions: {
      onSlideChanged: function onSlideChanged(element) {
        // Hack so the charts 'show' on the copies of the first and last elements for the loop
        if (Ember.$(element).attr('class').includes('swiper-slide-duplicate') > 0) {
          var width = Ember.$(element).width();
          var currentSlide = parseInt(this.get('currentSlide')) + 1;
          Ember.$(element).parent().css('transform', 'translate(-' + currentSlide * width + 'px, 0)');
        }
      }
    }
  });
});