define('ux-pcc/controllers/customer/performance/bookings', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    queryParams: ['beginDate', 'endDate'],

    beginDate: (0, _moment.default)().format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().format("YYYY-MM-DD"),
    channelMobileIndex: 0,
    segmentMobileIndex: 0,
    dowMobileIndex: 0,

    rollupStatus: Ember.computed('customer.customerType', function () {
      return 'CUSTOMER' === this.get('customer.customerType') ? 'DETAIL' : 'ROLLUP';
    }),

    segments: Ember.computed(function () {
      return [{ key: "0", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.day_of_arrival') }, { key: "1-3", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.one_day') }, { key: "4-7", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.four_days') }, { key: "8-14", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.eight_days') }, { key: "15+", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.fourteen_days') }, { key: "Stayover", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.stayover') }];
    }),

    bookingWindowTableSegments: Ember.computed('segments', function () {
      var segments = this.get('segments');

      return segments.concat([{ key: "Total", format: "formatNumber", label: this.get('i18n').t('customer.performance.bookings.table.total') }]);
    }),

    dowTableColumns: Ember.computed('segments', function () {
      // Need a place holder column for the day of the week
      var columns = [{ key: "dow", format: "", label: this.get('i18n').t('customer.performance.bookings.table.dow') }];
      var segments = this.get('segments');

      return columns.concat(segments);
    }),

    dowBeginDate: null,
    dowEndDate: null,

    dowDateObserver: Ember.on('init', Ember.observer('beginDate', 'endDate', function () {
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('beginDate'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), tz).endOf('day');

      // Make sure we have at least 7 days of data coming back
      if (_moment.default.duration(endDate.diff(selectedDate)).asDays() < 7) {
        var startDOW = selectedDate.format('d');
        var endDOW = endDate.format('d');

        // If the end date > startDate, make the start date the beginning of the week and add 6 days
        if (endDOW >= startDOW) {
          this.set('dowBeginDate', selectedDate.startOf('week'));
          this.set('dowEndDate', selectedDate.clone().add(6, 'days').endOf('day').format('YYYY-MM-DD'));
        } else {
          this.set('dowBeginDate', selectedDate);
          this.set('dowEndDate', endDate.endOf('week').format('YYYY-MM-DD'));
        }
      } else {
        this.set('dowBeginDate', selectedDate);
        this.set('dowEndDate', endDate.format('YYYY-MM-DD'));
      }
    })),

    daysOfWeek: Ember.computed('moment.locale', function () {
      var orderBy = 0;
      // Grab the locales start of week so we can order the dataset correctly
      var localeStartDayOfWeek = (0, _moment.default)().startOf('week').format('d');

      var daysOfWeek = [{ key: "SUNDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.sunday'), orderBy: orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "MONDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.monday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "TUESDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.tuesday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "WEDNESDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.wednesday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "THURSDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.thursday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "FRIDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.friday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }, { key: "SATURDAY", format: "formatNumber", label: this.get('i18n').t('charts.day_of_week.saturday'), orderBy: ++orderBy < localeStartDayOfWeek ? orderBy + 7 : orderBy }];

      return daysOfWeek.sortBy('orderBy');
    }),

    bookingDetailColumns: Ember.computed(function () {
      return [{ key: "", label: this.get('i18n').t('customer.performance.bookings.table.booking_window') }, { key: "adr", format: "formatCurrency", label: this.get('i18n').t('customer.performance.bookings.table.adr') }, { key: "revenue", format: "formatCurrency", label: this.get('i18n').t('customer.performance.bookings.table.total_revenue') }, { key: "sold", format: "", label: this.get('i18n').t('customer.performance.bookings.table.rooms_sold') }, { key: "alos", format: "", label: this.get('i18n').t('customer.performance.bookings.table.avglos') }];
    }),

    bookingWindowByChannelColumns: Ember.computed(function () {
      var rtnValue = [{ key: "", label: this.get('i18n').t('customer.performance.bookings.table.channel') }];
      rtnValue = rtnValue.concat(this.get('segments'));
      return rtnValue;
    }),

    bookingWindowBySegmentColumns: Ember.computed(function () {
      var rtnValue = [{ key: "", label: this.get('i18n').t('customer.performance.bookings.table.segment') }];
      return rtnValue.concat(this.get('model.marketSegments'));
    }),

    isDesktop: Ember.computed.bool('deviceType.desktop'),
    isMobile: Ember.computed.bool('deviceType.mobile'),

    minEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate'));
    }),

    maxEndDate: Ember.computed('beginDate', function () {
      var today = (0, _moment.default)();
      var beginDate = (0, _moment.default)(this.get('beginDate'));

      // Set max end date to today, if beginDate is < today
      // Set max date range to 180 days
      if (beginDate.isBefore(today)) {
        if (today.diff(beginDate, 'days') > 180) {
          return beginDate.clone().add(180, 'days');
        } else {
          return today;
        }
      } else {
        return beginDate.clone().add(180, 'days');
      }
    }),

    maxEndDateObserver: Ember.observer('maxEndDate', function () {
      var maxEndDate = this.get('maxEndDate');
      if (maxEndDate) {
        var endDate = (0, _moment.default)(this.get('endDate'));
        if (endDate.isAfter(maxEndDate)) {
          this.set('endDate', (0, _moment.default)(maxEndDate).format('YYYY-MM-DD'));
        }
      }
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', 'maxEndDate', function () {
      var options = {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate')
      };

      if (this.get('maxEndDate')) {
        options.maxDate = this.get('maxEndDate');
      }

      return options;
    })
  });
});