define('ux-pcc/components/reservations/reservation-history', ['exports', 'ux-components/components/reservations/reservation-history'], function (exports, _reservationHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservationHistory.default;
    }
  });
});