define('ux-pcc/components/rules-simple/cancel-rule-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'moment'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['rules-simple-condition'],
    classNameBindings: ['isReadOnly:rules-simple-condition__read-only'],

    onDelete: function onDelete() {},
    registerChangeset: function registerChangeset() {},

    rule: null,
    ruleCondition: null,
    index: null,
    cancelMetric: 'HOURS',
    amountRule: null,

    isReadOnly: Ember.computed.equal('mode', 'READONLY'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.mode === 'NEW') {
        this.set('cancelMetric', 'HOURS');
        this.set('amountRule', this.index === 0 ? 'FIRST_SLICE' : 'NO_CHARGE');
        // Set values for cancel penalty amount
        this.set('ruleCondition.amountRule', this.index === 0 ? 'FIRST_SLICE' : 'VALUE');
        this.set('ruleCondition.calculationRule', this.index === 0 ? 'AMOUNT_ADD' : 'AMOUNT_NOOP');
        this.set('ruleCondition.amount', 0);

        // Push the rule wrapper
        Ember.get(this.ruleCondition, 'children').push({
          'ruleMethod': 'FILTER',
          'filterType': 'ALL',
          'orderBy': null,
          'filterField': 'ARRIVAL_DATE',
          'children': [{
            'ruleMethod': 'FILTER',
            'filterType': 'FIELD_WHERE',
            'children': [],
            'orderBy': null,
            'filterField': 'HOURS_PRIOR_TO_ARRIVAL',
            'filterCondition': this.index === 0 ? 'LTEQ' : 'GT',
            'filterRangeLow': null,
            'filterRangeHigh': null,
            'filterValueRule': null,
            'filterField2': null,
            'filterValue': '24'
          }, {
            'ruleMethod': 'FILTER',
            'filterType': 'FIELD_WHERE',
            'children': [],
            'orderBy': null,
            'filterField': 'EVENT_TIME',
            'filterCondition': 'LTEQ',
            'filterRangeLow': null,
            'filterRangeHigh': null,
            'filterValueRule': null,
            'filterField2': null,
            'filterValue': '18:00'
          }]
        });
      } else {
        // Set the penalty calculation drop list
        if (this.ruleCondition.calculationRule === 'AMOUNT_NOOP') {
          if (this.ruleCondition.amountRule === 'VALUE' && this.ruleCondition.amount === 0) {
            this.set('amountRule', 'NO_CHARGE');
          } else {
            this.set('amountRule', 'AMOUNT_NOOP');
          }
        } else {
          this.set('amountRule', this.ruleCondition.amountRule);
        }

        // Update the cancelMetric to Days if appropriate
        if (this.cancelDayCondition.filterField && this.cancelDayCondition.filterField.indexOf('DAYS') === 0) {
          this.set('cancelMetric', 'DAYS');
        }
      }

      // Register additional changesets
      this.registerChangeset('cancelRuleConditionChangeset' + this.index, this.ruleConditionChangeset);
      this.registerChangeset('cancelDayConditionChangeset' + this.index, this.cancelDayConditionChangeset);
    },


    ruleConditionFilter: Ember.computed.alias('ruleCondition.children.firstObject'),

    ruleConditionFilterFieldOptions: Ember.computed('isReadOnly', 'cancelMetric', 'ruleConditionFilter.filterField', function () {
      var options = [{
        value: 'ARRIVAL_DATE',
        label: this.i18n.t('rules-simple.time-options.prior_to_arrival')
      }];

      // TODO: Remove this when HOURS_AFTER_BOOKING_DATE is implemented (DE2243
      if (!(this.cancelMetric === 'HOURS' && this.ruleConditionFilter.filterField === 'ARRIVAL_DATE')) {
        options.pushObject({
          value: 'BOOKING_DATE',
          label: this.i18n.t('rules-simple.time-options.booking_date')
        });
      }

      return this.isReadOnly ? options.filterBy('value', this.ruleConditionFilter.filterField) : options;
    }),

    cancelDayCondition: Ember.computed('ruleConditionFilter.children.@each.filterField', function () {
      return this.ruleConditionFilter ? this.get('ruleConditionFilter.children.firstObject') : {};
    }),

    cancelDayConditionChangeset: Ember.computed('cancelDayCondition.filterCondition', function () {
      var validations = void 0;
      if (this.cancelDayCondition) {
        if (this.cancelDayCondition.filterCondition === 'BETWEEN') {
          validations = {
            filterRangeLow: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') }),
            filterRangeHigh: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') })
          };
        } else {
          validations = {
            filterValue: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') })
          };
        }
      }

      return new _emberChangeset.default(this.cancelDayCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    cancelMetricOptions: Ember.computed('isReadOnly', function () {
      var options = [{
        value: 'HOURS',
        label: this.i18n.t('rules-simple.time-options.hours')
      }, {
        value: 'DAYS',
        label: this.i18n.t('rules-simple.time-options.days')
      }];

      return this.isReadOnly ? options.filterBy('value', this.cancelMetric) : options;
    }),

    cancelDayFilterConditions: Ember.computed('isReadOnly', function () {
      var options = [{
        value: 'LTEQ',
        label: this.i18n.t('rules-simple.condition-options.within')
      }, {
        value: 'GT',
        label: this.i18n.t('rules-simple.condition-options.more_than')
      }, {
        value: 'BETWEEN',
        label: this.i18n.t('rules-simple.condition-options.between')
      }];

      return this.isReadOnly ? options.filterBy('value', this.cancelDayCondition.filterCondition) : options;
    }),

    amountRuleConditions: Ember.computed('isReadOnly', function () {
      var options = [{
        value: 'FIRST_SLICE',
        label: this.i18n.t('rules-simple.penalty-options.first_day')
      }, {
        value: 'FIRST_SLICE_INCL_TAX',
        label: this.i18n.t('rules-simple.penalty-options.first_day_incl_tax')
      }, {
        value: 'FIRST_SLICE_INCL_TAX_FEE',
        label: this.i18n.t('rules-simple.penalty-options.first_day_incl_tax_fee')
      }, {
        value: 'TOTAL_REMAINING',
        label: this.i18n.t('rules-simple.penalty-options.total_remaining')
      }, {
        value: 'TOTAL_REMAINING_INCL_FEE',
        label: this.i18n.t('rules-simple.penalty-options.total_remaining_incl_fee')
      }, {
        value: 'TOTAL',
        label: this.i18n.t('rules-simple.penalty-options.total')
      }, {
        value: 'TOTAL_INCL_TAX',
        label: this.i18n.t('rules-simple.penalty-options.total_incl_tax')
      }, {
        value: 'TOTAL_INCL_TAX_FEE',
        label: this.i18n.t('rules-simple.penalty-options.total_incl_tax_fee')
      }, {
        value: 'AMOUNT_NOOP',
        label: this.i18n.t('rules-simple.penalty-options.flat_price')
      }, {
        value: 'NO_CHARGE',
        label: this.i18n.t('rules-simple.penalty-options.no_charge')
      }];

      return this.isReadOnly ? options.filterBy('value', this.amountRule) : options;
    }),

    timeCondition: Ember.computed('ruleConditionFilter.children.@each.filterField', function () {
      return this.ruleConditionFilter ? this.ruleConditionFilter.children.findBy('filterField', 'EVENT_TIME') : {};
    }),

    timeConditionValue: Ember.computed('timeCondition.filterValue', function () {
      var filterValue = this.timeCondition.filterValue;

      var parsedTime = filterValue.split(':');
      var today = (0, _moment.default)();
      today.hour(parsedTime[0]);
      today.minute(parsedTime[1]);
      return today;
    }),

    ruleConditionChangeset: Ember.computed('ruleCondition', function () {
      var validations = {
        amount: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') })
      };

      return new _emberChangeset.default(this.ruleCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    cancelMetricObserver: Ember.observer('ruleConditionFilter.filterField', 'cancelMetric', function () {
      if (this.ruleConditionFilter) {
        if (this.cancelMetric === 'HOURS') {
          if (this.ruleConditionFilter.filterField === 'ARRIVAL_DATE') {
            this.set('cancelDayCondition.filterField', 'HOURS_PRIOR_TO_ARRIVAL');
          } else {

            // TODO: Enable this when it is an actual rule to process, and remove the set on the next line
            // when DE2243 is implemented
            // this.set('cancelDayCondition.filterField', 'HOURS_AFTER_BOOKING_DATE');
            this.set('ruleConditionFilter.filterField', 'ARRIVAL_DATE');
          }
        } else if (this.cancelMetric === 'DAYS') {
          if (this.ruleConditionFilter.filterField === 'ARRIVAL_DATE') {
            this.set('cancelDayCondition.filterField', 'DAYS_PRIOR_TO_ARRIVAL');
          } else {
            this.set('cancelDayCondition.filterField', 'DAYS_AFTER_BOOKING_DATE');
          }
        }
      }
    }),

    cancelDayConditionFilterConditionObserver: Ember.observer('cancelDayCondition.filterCondition', function () {
      if (this.cancelDayCondition) {
        if (this.cancelDayCondition.filterCondition === 'BETWEEN') {
          this.set('cancelDayCondition.filterValue', null);
        } else {
          this.set('cancelDayCondition.filterRangeLow', null);
          this.set('cancelDayCondition.filterRangeHigh', null);
        }
        // Changeset would have changed, so re-register the changeset
        this.registerChangeset('cancelDayConditionChangeset' + this.index, this.cancelDayConditionChangeset);
      }
    }),

    amountRuleObserver: Ember.observer('amountRule', function () {
      if (this.ruleCondition) {
        if (this.amountRule === 'NO_CHARGE' || this.amountRule === 'AMOUNT_NOOP') {
          this.set('ruleCondition.amountRule', 'VALUE');
          this.set('ruleCondition.calculationRule', 'AMOUNT_NOOP');
          this.set('ruleCondition.amount', 0);
        } else {
          this.set('ruleCondition.amountRule', this.amountRule);
          this.set('ruleCondition.calculationRule', 'AMOUNT_ADD');
          this.set('ruleCondition.amount', 0);
        }
      }
    }),

    actions: {
      deleteCondition: function deleteCondition() {
        this.onDelete(this.index);
      }
    }
  });
});