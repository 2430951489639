define('ux-pcc/utils/dates-from-span', ['exports', 'ux-components/utils/dates-from-span'], function (exports, _datesFromSpan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _datesFromSpan.default;
    }
  });
});