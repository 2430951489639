define('ux-pcc/components/filter-list', ['exports', 'ux-components/components/filter-list'], function (exports, _filterList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filterList.default;
    }
  });
});