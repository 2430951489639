define('ux-pcc/controllers/customer/performance/pricing', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    queryParams: ['beginDate', 'endDate'],

    beginDate: (0, _moment.default)().format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().format("YYYY-MM-DD"),
    segments: [],
    detailSegments: [],
    enableSlider: Ember.computed.bool('deviceType.tablet'),
    nbrDetailColumns: 6,

    rollupStatus: Ember.computed('customer.customerType', function () {
      return 'CUSTOMER' === this.get('customer.customerType') ? 'DETAIL' : 'ROLLUP';
    }),

    performanceDetailFilter: Ember.computed('segments', function () {
      var returnList = [];
      var marketSegments = this.get('segments') || [];

      marketSegments.forEach(function (m) {
        returnList.push(m.key);
      });

      return returnList.toString();
    }),

    minEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate'));
    }),

    maxEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate')).clone().add(180, 'days');
    }),

    datesObserver: Ember.observer('beginDate', 'endDate', function () {
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('beginDate'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), tz).endOf('day');
      //restrict dates in case of url param modifications
      if (_moment.default.duration(endDate.diff(selectedDate)).asDays() > 180) {
        this.set('endDate', (0, _moment.default)(this.get('maxEndDate')).format('YYYY-MM-DD'));
      }
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', 'maxEndDate', function () {
      var options = {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate')
      };
      if (this.get('maxEndDate')) {
        options.maxDate = this.get('maxEndDate');
      }
      return options;
    })

  });
});