define('ux-pcc/validations/conditional', ['exports', 'ux-data/validations/conditional'], function (exports, _conditional) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _conditional.default;
    }
  });
  Object.keys(_conditional).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _conditional[key];
      }
    });
  });
});