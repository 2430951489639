define('ux-pcc/routes/customer/monthly-booking-summary', ['exports', 'moment', 'ux-components/utils/to-boolean'], function (exports, _moment, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    tempStorage: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');

      var channelRequest = null;
      var channels = {};
      channelRequest = this.get('store').query('code', {
        customerID: customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_CHANNEL'
      }).then(function (results) {
        results.reject(function (code) {
          var shortDescription = code.get('shortDescription').toUpperCase();
          return shortDescription.indexOf('EXCLUDE') === 0;
        }).forEach(function (c) {
          return channels[c.get('codeValue')] = c.get('shortDescription');
        });
        return channels;
      });

      return Ember.RSVP.hash({
        channels: channelRequest,
        userCustomer: this.get('store').findRecord('customer', this.get('session.data.authenticated.customerID')),
        breadcrumbCustomer: this.get('store').findRecord('customer', customer.belongsTo('parent').id())
      });
    },
    setupController: function setupController(controller, model) {
      var customer = this.modelFor('customer');
      var settings = customer.get('settings');
      var preserveParametersSetting = settings.findBy('settingCode', 'PCC.ENABLE_MBS_PRESERVE_PARAMETERS');
      var useStoredValues = preserveParametersSetting && preserveParametersSetting.get('settingValue') ? (0, _toBoolean.default)(preserveParametersSetting.get('settingValue')) : false;

      var defaultDataType = 'RESERVATIONS';
      var defaultSelectedDate = (0, _moment.default)().startOf('month').format('YYYY-MM-DD');

      var defaultLocationTypeSetting = settings.findBy('settingCode', 'PCC.MBS_DEFAULT_LOCATION_TYPE');
      var defaultLocationType = defaultLocationTypeSetting ? defaultLocationTypeSetting.get('settingValue') : 'ALL';

      if (useStoredValues) {
        controller.set('dataType', this.get('tempStorage').getValue('dataType') || defaultDataType);
        controller.set('enhanced', this.get('tempStorage').getValue('enhanced') || null);
        controller.set('locationType', this.get('tempStorage').getValue('locationType') || defaultLocationType);
        controller.set('reservationCategory', this.get('tempStorage').getValue('reservationCategory') || null);
        controller.set('selectedDate', this.get('tempStorage').getValue('selectedDate') || defaultSelectedDate);
      } else {
        controller.set('dataType', defaultDataType);
        controller.set('enhanced', null);
        controller.set('locationType', defaultLocationType);
        controller.set('reservationCategory', null);
        controller.set('selectedDate', defaultSelectedDate);
      }

      var customerLevel = this.level(customer);
      if (customerLevel === 'LEVEL_STATION') {
        controller.set('locationType', 'ALL');
      }

      controller.set('level', customerLevel);
      controller.set('channels', model.channels);
      controller.set('userCustomer', model.userCustomer);
      controller.set('breadcrumbCustomer', model.breadcrumbCustomer);
      controller.resetQueue();

      if (!controller.zoomStartDate) {
        var startDate = _moment.default.tz(this.tz.customer);
        controller.set('zoomStartDate', startDate);
        controller.set('zoomEndDate', startDate.clone().endOf('month').startOf('day'));
      }

      var defaultChannelComparisonSetting = settings.findBy('settingCode', 'PCC.MBS_DEFAULT_CHANNEL_COMPARISON');
      var defaultChannelComparisonSettingValue = defaultChannelComparisonSetting ? defaultChannelComparisonSetting.get('settingValue').split(',') : ['CHANENTR', 'CHANCOMM', 'CHANINTL', 'CHANAFFY', 'CHANTRAV', 'CHANCONS'];
      controller.set('yearComparisonChannelsDefault', defaultChannelComparisonSettingValue);

      var defaultRateComparisonSetting = settings.findBy('settingCode', 'PCC.MBS_DEFAULT_RATE_COMPARISON');
      var defaultRateComparisonSettingValue = defaultRateComparisonSetting ? defaultRateComparisonSetting.get('settingValue').split(',') : ['DAILY', 'WEEKLY', 'WEEKEND', 'MONTHLY'];
      controller.set('yearComparisonRateDefault', defaultRateComparisonSettingValue);

      this._super(controller, model);
    },
    level: function level(customer) {
      var fallbackLevel = customer.get('customerType') === customer.Type.PROPERTY ? 'LEVEL_STATION' : '';
      var tagList = (customer.get('tags') || []).filter(function (tag) {
        return tag.startsWith('LEVEL_');
      });
      return tagList.length > 0 ? tagList[0] : fallbackLevel;
    },


    actions: {
      willTransition: function willTransition(transition) {
        var settings = this.modelFor('customer').get('settings');

        var skipLocationTypeResetSetting = settings.findBy('settingCode', 'PCC.MBS_SKIP_LOCATION_TYPE_TRANSITION_RESET');
        var skipReset = (0, _toBoolean.default)(skipLocationTypeResetSetting ? skipLocationTypeResetSetting.get('settingValue') : null);

        if (!transition.queryParams.skipWillTransition && !skipReset) {
          this.get('tempStorage').setValue('locationType', null);
        }
      }
    }
  });
});