define('ux-pcc/components/marketing-campaigns/marketing-campaign-list', ['exports', 'ux-components/components/marketing-campaigns/marketing-campaign-list'], function (exports, _marketingCampaignList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marketingCampaignList.default;
    }
  });
});