define('ux-pcc/components/dashboard-widget/build-charts', ['exports', 'moment', 'ux-pcc/mixins/queued-query-data'], function (exports, _moment, _queuedQueryData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queuedQueryData.default, {

    tagName: 'div',
    classNames: ['build-charts'],

    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    menuService: Ember.inject.service('menu'),
    showComments: true,

    data: null,
    //Define required items for query-data-in-viewport mixin
    queryDataWatchProperties: null,

    init: function init() {
      this.set('data', []);
      this.set('queryDataWatchProperties', ['customer.id', 'selectedDate', 'dataType', 'locationType', 'reservationCategory', 'aggregateVersion']);
      this._super.apply(this, arguments);
    },


    // 'DETAIL' if property, or 'ROLLUP'
    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    aggregate: Ember.computed.equal('rollupStatus', 'ROLLUP'),

    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      return version !== '1' ? 'WEEKLY_ACCUMULATION_COMPARISON_V2' : 'WEEKLY_ACCUMULATION_COMPARISON';
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var customerID = this.get('customer.id');
      var tz = this.get('tz.customer');
      var selectedDate = this.get('selectedDate');

      // set the asOfDateBase to be the start of day today in the property TZ
      var asOfDateBase = _moment.default.tz(tz).startOf('day');

      var currentMonth = _moment.default.tz(tz).startOf('month');

      // If selected date is prior to current month, grab the selected month, and add 1 day
      // so that the subtraction below gives you the end of the selected month
      if (selectedDate.isBefore(currentMonth, 'month') || selectedDate.isAfter(currentMonth, 'month')) {
        asOfDateBase = selectedDate.clone().endOf('month').add(1, 'day');
      }

      var queryParameters = {
        customerid: customerID,
        begindateStr: selectedDate.clone().startOf('month').format('YYYY-MM-DD'),
        enddateStr: selectedDate.clone().endOf('month').format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        aggregate: false,
        asOfDateBeginStr: asOfDateBase.clone().subtract(1, 'day').subtract(13, 'weeks').format('YYYY-MM-DD'),
        asOfDateEndStr: asOfDateBase.clone().subtract(1, 'day').format('YYYY-MM-DD'),
        sourceLocationCode: this.get('dataType'),
        locationType: this.get('locationType'),
        returnType: this.get('reservationCategory'),
        groupBy: 'ALL',
        nocache: this.get('nocache')
      };

      var latencyStart = (0, _moment.default)();
      return this.ajax.request('rest/v1/analyticEntryAggregation/process', {
        track: true,
        data: queryParameters
      }).then(function (_ref) {
        var collections = _ref.collections;

        var latency = (0, _moment.default)().diff(latencyStart);
        _this.set('data', collections);
        _this.send('resize');
        _this.send('logSessionHistory', latency);
      }).catch(function () {
        _this.set('data', null);
      });
    },


    actions: {
      resize: function resize() {
        Ember.run.later(function () {
          Ember.$(window).trigger('resize.menu-toggle');
          // Fire a resize event after the transition is complete

          if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
          } else {
            // Windows needs a little more time
            Ember.run.later(function () {
              // for IE and other old browsers
              // causes deprecation warning on modern browsers
              var evt = document.createEvent('UIEvents');
              evt.initUIEvent('resize', true, false, window, 0);
              window.dispatchEvent(evt);
            }, 100);
          }
        }, 400);
      },
      logSessionHistory: function logSessionHistory(latency) {
        var _this2 = this;

        // Log the important information
        var queryParameters = {
          'dataType': this.get('dataType'),
          'externalReference': this.get('customer.externalReference'),
          'latency': latency,
          'locationType': this.get('locationType'),
          'reservationCategory': this.get('reservationCategory'),
          'selectedDate': (0, _moment.default)(this.get('selectedDate')).format('YYYY-MMM')
        };

        // Grab the optional ones
        if (this.get('nocache')) {
          queryParameters.nocache = this.get('nocache');
        }
        if (this.get('aggregateVersion')) {
          queryParameters.aggregateVersion = this.get('aggregateVersion');
        }

        this.store.findRecord('session', this.get('session.data.authenticated.sessionID')).then(function (session) {
          var sessionHistory = {
            session: session,
            fkReference: 'CUSTOMER',
            fkID: _this2.get('customer.affiliateCustomer.id'),
            comments: JSON.stringify(queryParameters),
            commentType: 'JSON',
            eventCode: 'WEBSERVICE',
            referrerUrl: _this2.get('menuService.router.router.location.location.href'),
            currentProgram: 'PCC.MBS'
          };
          var history = _this2.store.createRecord('session-history', sessionHistory);
          history.save().then(function () {
            return;
          }, function () {
            console.error('Could not log session-history');
          });
        });
      }
    }
  });
});