define('ux-pcc/controllers/competitive-sets/list/detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    canEdit: Ember.computed('model', function () {
      // do not allow editing of competitive set if tags are present or TravelClick/STR competitor
      return (!this.get('model.tags') || this.get('model.tags') && this.get('model.tags').length === 0) && !(this.get('model.name').includes('TCLK') || this.get('model.name').includes('(STR') || this.get('model.name').includes('(Smith Travel') || this.get('model.name').includes('(TravelClick'));
    })
  });
});