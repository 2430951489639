define('ux-pcc/components/folios/policy-review-signature', ['exports', 'ux-components/components/folios/policy-review-signature'], function (exports, _policyReviewSignature) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _policyReviewSignature.default;
    }
  });
});