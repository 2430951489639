define('ux-pcc/controllers/competitor-pricing/competitor-shopping-form', ['exports', 'moment', 'lodash', 'ux-components/utils/format-currency'], function (exports, _moment, _lodash, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    dialog: Ember.inject.service(),
    ajax: Ember.inject.service(),

    currencyCode: Ember.computed.alias('model.currencyCode'),
    competitorName: Ember.computed.alias('model.competitor.name'),
    data: null,
    formData: {},
    rateMap: {},
    modifiedRates: [],

    init: function init() {
      this._super();
      var tz = this.tz.get("customer");
      this.set('formData.collectedDate', (0, _moment.default)().tz(tz).startOf('day').clone());

      this._newEntry();
      this.set('modifiedRates', []);
    },


    _analyticMapObserver: Ember.observer('model.competitor.rates', function () {

      var rateMap = {};
      var rates = this.get('model.competitor.rates') || [];

      rates.forEach(function (r) {
        var rate = JSON.parse(decodeURI(r.value));
        if (rate.amount) {
          rateMap[r.key] = r.value;
        }
      });

      this.set('rateMap', rateMap);
    }),

    _competitorObserver: Ember.observer('model.competitor', function () {
      var competitor = this.get('model.competitor');
      this.set('data.description', competitor.name);
      this.set('data.groupByValue', competitor.key);
    }),

    _maxAmountLengthObserver: Ember.observer('formData.dataValue', function () {
      var stringValue = this.get('formData.dataValue');
      if (stringValue && stringValue.length > 10) {
        this.set('formData.dataValue', stringValue.substring(0, 10));
      }
    }),

    _newEntry: function _newEntry() {
      this.set('data', Ember.Object.create({
        customerID: this.get('customer.id'),
        analyticType: 'AMOUNT',
        contentType: 'UNPUBLISHED_RATE',
        channelCode: 'ALL',
        counter: 1,
        dataValue: '',
        dataType: 'VALUE',
        rollupStatus: 'DETAIL',
        groupBy: 'ID',
        currencyCode: this.get('currencyCode'),
        subContentType: 'COMPETITOR'
      }));

      this.set('formData.los', 1);
      this.set('formData.counter', 1);
      this.set('formData.dataValue', '');
    },


    losOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 15);
    }),

    adultOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 5);
    }),

    formHeader: Ember.computed('i18n.locale', 'competitorName', function () {
      return this.get('i18n').t('competitive-sets.add-pricing.enter_pricing') + ' ' + this.get('competitorName');
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    currencySymbol: Ember.computed('currencyCode', function () {
      return (0, _formatCurrency.default)(0, this.get('currencyCode'), { hash: { symbolOnly: true } });
    }),

    valuesChanged: Ember.observer('formData.collectedDate', 'formData.los', 'formData.counter', function () {
      var _this = this;

      Ember.run.once(function () {
        _this.send('add');
      });
    }),

    rates: Ember.computed('rateMap', function () {
      var _this2 = this;

      var rates = this.get('rateMap');

      if (!rates) {
        return null;
      }

      var rtnList = [];

      Object.keys(rates).forEach(function (r) {
        var rate = JSON.parse(decodeURI(_this2.get('rateMap')[r]));
        if (rate.amount) {
          rtnList.push({ key: r, value: rate, modified: _this2.get('modifiedRates').indexOf(r) >= 0 });
        }
      });

      return rtnList.sortBy('key');
    }),

    actions: {
      clearAndClose: function clearAndClose() {
        this.send('close');
      },
      close: function close() {
        if (this.get('model.onClose')) {
          this.get('model.onClose')();
        }
        this.set('modifiedRates', []);
        this.send('closeModal');
      },
      add: function add() {

        // Return if there is not a price in the data value
        if (!this.get('formData.dataValue')) {
          return;
        }
        var generateKey = function generateKey(beginDate, los, nbrAdults) {
          return beginDate + '-' + los + '-' + nbrAdults;
        };

        var tz = this.tz.get("customer");

        var key = generateKey((0, _moment.default)(this.get('model.timestamp')).tz(tz).startOf('day').format(), this.get('formData.los'), this.get('formData.counter'));

        this.get('rateMap')[key] = encodeURI(JSON.stringify({
          collectedDate: (0, _moment.default)(this.get('formData.collectedDate')).tz(tz).startOf('day'),
          arrivalDate: (0, _moment.default)(this.get('model.timestamp')).tz(tz).startOf('day'),
          los: this.get('formData.los'),
          adults: this.get('formData.counter'),
          amount: this.get('formData.dataValue')
        }));

        // Keep track of the added/modified rates
        if (this.get('modifiedRates').indexOf(key) < 0) {
          this.get('modifiedRates').push(key);
        }

        this.notifyPropertyChange('rateMap');
        this.send('clear');
      },
      clear: function clear() {
        this.set('formData.dataValue', '');
        this.set('formData.counter', 1);
        this.set('formData.los', 1);
      },
      removeRecord: function removeRecord(key) {
        if (this.get('rateMap')[key]) {
          delete this.get('rateMap')[key];

          // If rate was added/modified, remove it from the tracking list
          var modifiedIndex = this.get('modifiedRates').indexOf(key);
          if (modifiedIndex >= 0) {
            this.get('modifiedRates').splice(modifiedIndex, 1);
          }

          this.notifyPropertyChange('rateMap');
          this.send('clear');
        }
      },
      save: function save() {
        var _this3 = this;

        var entry = this.get('data');
        var tz = this.tz.get("customer");

        this.set('loading', true);

        this.get('ajax').request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: (0, _moment.default)(this.get('model.timestamp')).tz(tz).startOf('day').format(),
            enddate: (0, _moment.default)(this.get('model.timestamp')).add(1, 'days').tz(tz).startOf('day').format(),
            frequency: 'DAY',
            customerid: this.get('customer.id'),
            contenttype: 'UNPUBLISHED_RATE',
            analytictype: 'AMOUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;


          if (analyticEntries.get('length') > 0 && analyticEntries.filterBy('groupByValue', _this3.get('model.competitor.key')).length > 0) {
            entry = Ember.Object.create(analyticEntries.filterBy('groupByValue', _this3.get('model.competitor.key'))[0]);
            entry.set('description', _this3.get('competitorName'));
          } else {
            entry.set('entryDate', (0, _moment.default)(_this3.get('model.timestamp')).tz(tz).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
            entry.set('beginDate', (0, _moment.default)(_this3.get('model.timestamp')).tz(tz).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
            entry.set('endDate', (0, _moment.default)(_this3.get('model.timestamp')).tz(tz).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
          }

          var rates = _this3.get('rates');
          if (!rates || rates.length <= 0) {
            entry.set('inactivated', true);
            entry.set('dataValues', null);
          } else {
            entry.set('dataValues', _this3.get('rateMap'));
          }

          return _this3.get('ajax').post('/rest/v1/analyticEntries', {
            data: JSON.stringify(entry)
          }).then(function (_ref2) {
            var analyticEntry = _ref2.analyticEntry;

            var entry = Ember.Object.create(analyticEntry);

            _this3.set('data', entry);

            if (_this3.get('model.onSave')) {
              _this3.get('model.onSave')(entry);
            }
            _this3.send('close');
            _this3.get('dialog').showSuccess({
              titleText: _this3.get('i18n').t('pcc-components.competitor-shopping.dialogs.success.title'),
              messageText: _this3.get('i18n').t('pcc-components.competitor-shopping.dialogs.success.success-message', { customerName: _this3.get('competitorName') }),
              confirmText: _this3.get('i18n').t('pcc-components.competitor-shopping.dialogs.success.button_label')
            });
          });
        }).catch(function () {
          _this3.get('dialog').showError();
        }).finally(function () {
          _this3.set('loading', false);
        });
      }
    }
  });
});