define('ux-pcc/components/yield-manager/display-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    menuService: Ember.inject.service('menu'),
    store: Ember.inject.service(),

    classNames: ['parameter-panel', 'display-panel'],

    tmVsGrossParam: Ember.computed.alias('dashboardViewParameters.TM_VS_GROSS'),

    dataViewParam: Ember.computed.alias('dashboardViewParameters.DATA_VIEW'),

    displayParam: Ember.computed.alias('dashboardViewParameters.DISPLAY'),

    viewTypeParam: Ember.computed.alias('dashboardViewParameters.SHOW_VIEW_TYPE'),

    typeParam: Ember.computed.alias('dashboardViewParameters.DATA_TYPE'),

    sourceLocationCodeParam: Ember.computed.alias('dashboardViewParameters.SOURCE_LOCATION_CODE'),

    viewByParam: Ember.computed.alias('dashboardViewParameters.VIEW_BY'),

    sourceLocationCodes: null,

    init: function init() {
      if (!this || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      this._super.apply(this, arguments);
      this._setSourceLocationCodes();
      this._setDisplayViewsList();
    },
    _setSourceLocationCodes: function _setSourceLocationCodes() {
      var _this = this;

      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_SOURCE_LOCATION'
      }).then(function (results) {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('sourceLocationCodes', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },
    _setDisplayViewsList: function _setDisplayViewsList() {
      var _this2 = this;

      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        // May need to expand this list
        if (this.get('typeParam') === 'MBS') {
          this.get('store').query('code', {
            customerID: this.get('customer.id'),
            codeType: 'ADVANCED_ANALYTICS_FILTER_ANALYZE_DISPLAY_VIEW'
          }).then(function (results) {
            _this2.set('displayViewsList', results.reject(function (code) {
              var shortDescription = code.get('shortDescription').toUpperCase();
              return shortDescription.indexOf('EXCLUDE') === 0;
            }).sortBy('orderBy'));
            _this2._resetDisplayView();
          });
        } else {
          this.get('store').query('code', {
            customerID: this.get('customer.id'),
            codeType: 'ADVANCED_ANALYTICS_FILTER_BASE_DISPLAY_VIEW'
          }).then(function (results) {
            _this2.set('displayViewsList', results.reject(function (code) {
              var shortDescription = code.get('shortDescription').toUpperCase();
              return shortDescription.indexOf('EXCLUDE') === 0;
            }).sortBy('orderBy'));
            _this2._resetDisplayView();
          });
        }
      }
    },
    _resetDisplayView: function _resetDisplayView() {
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        if (!Ember.isEmpty(this.get('displayParam'))) {
          // Make sure DisplayView is contained within the list of displayViews if it was set
          var displayViews = this.get('displayViewsList');
          if (displayViews && displayViews.length > 0 && !displayViews.findBy('codeValue', this.get('displayParam'))) {
            this.set('displayParam', Ember.get(displayViews, 'firstObject.codeValue'));
          }
        }
      }
    },


    viewTypes: Ember.computed('menuService.menus.length', function () {
      var _this3 = this;

      var menuList = this.get('menuService.flattenedMenus');
      var viewTypes = [];
      if (menuList && menuList.length > 0) {
        var viewMenu = menuList.findBy('reference', 'pcc.menu.property.yield-manager');

        if (viewMenu && viewMenu.get('children')) {
          viewMenu.get('children').sortBy('orderBy').forEach(function (menu) {
            var parameters = _this3.get('menuService').getQueryStringParams(menu);
            if (parameters['viewType']) {
              viewTypes.pushObject({ value: parameters['viewType'], description: menu.get('shortDescription') });
            }
          });
        }
      }

      return viewTypes;
    })

  });
});