define('ux-pcc/components/charts/radar-chart', ['exports', 'ux-components/utils/format-currency'], function (exports, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['am-chart-div'],

    /**
     The `height` the chart should be.
      @property height
     @type String
     @default '300'
     */
    height: '300',

    chartData: undefined,
    legendDiv: '',

    dataType: 'percentage',

    chartDataChanged: Ember.observer('chartData', 'selected', function () {
      if (this.get('chart')) {
        this.get('chart').dataProvider = this.get('chartData');
        this.get('chart').validateData();
      }
    }),

    didInsertElement: function didInsertElement() {
      this._setupRadarChart();
    },
    _setupRadarChart: function _setupRadarChart() {
      var _this = this;

      this.$().css({
        height: this.get('height')
      });

      var formatData = function formatData(value) {
        var label = '';
        switch (_this.get('dataType')) {
          case 'percentage':
            label += value.toFixed(2) + '%';
            break;
          case 'currency':
            label += (0, _formatCurrency.default)(value, 'USD');
            break;
          default:
            label += value;
            break;
        }

        return label;
      };

      function propertyBalloon(data) {
        return formatData(data.values.value) + ' My Property';
      }

      function competitiveBalloon(data) {
        return formatData(data.values.value) + ' Competitive Set';
      }

      function indexBalloon(data) {
        return formatData(data.values.value) + ' Index';
      }

      var chart = AmCharts.makeChart(this.get('elementId'), {
        hideCredits: true,
        'type': 'radar',
        'dataProvider': this.get('chartData'),
        'valueAxes': [{
          'axisTitleOffset': 10,
          'axisAlpha': 0.15
        }],
        'graphs': [{
          'balloonFunction': propertyBalloon,
          'bullet': 'round',
          'valueField': 'my_property',
          'fillAlphas': 0.15,
          'fillColors': '#1b7837',
          'lineColor': '#1b7837',
          'title': this.i18n.t('pcc-components.radar-chart.my_property')
        }, {
          'balloonFunction': competitiveBalloon,
          'bullet': 'square',
          'valueField': 'competitor',
          'fillAlphas': 0,
          'fillColors': '#762a83',
          'lineColor': '#762a83',
          'title': this.i18n.t('pcc-components.radar-chart.competitor')
        }, {
          'balloonFunction': indexBalloon,
          'bullet': 'diamond',
          'valueField': 'index',
          'fillAlphas': 0,
          'fillColors': '#404040',
          'lineColor': '#404040',
          'title': this.i18n.t('pcc-components.radar-chart.index')
        }],
        'categoryField': 'label'
      });

      if (this.get('legendDiv')) {
        chart.addLegend({
          'align': 'center',
          'markerType': 'circle',
          'spacing': 20,
          'fontSize': 14,
          'valueText': '',
          'divId': this.get('legendDiv')
        }, this.get('legendDiv'));

        chart.validateData();
      }

      // WRITE
      chart.write(this.get('elementId'));

      this.set('chart', chart);
    }
  });
});