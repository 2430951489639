define('ux-pcc/components/nav-tabs', ['exports', 'ux-components/components/nav-tabs'], function (exports, _navTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navTabs.default;
    }
  });
});