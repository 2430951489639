define('ux-pcc/components/competitive-set/competitor-sets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    competitors: null,
    loading: false,
    customer: null,
    useXref: true,
    dialog: Ember.inject.service(),
    currencyCode: 'USD',

    init: function init() {
      this._super.apply(this, arguments);

      this.loadData();
    },


    competitiveSetsObserver: Ember.observer('competitiveSets', function () {
      this.loadData();
    }),

    loadData: function loadData() {
      var _this = this;

      var promises = [];
      var competitiveSets = this.get('competitiveSets');

      this.set('loading', true);

      competitiveSets.forEach(function (competitiveSet) {

        if (_this.get('useXref')) {

          promises.push(_this.store.query('customer', {
            folderid: competitiveSet.get('id'),
            detaillevel: 'XREF',
            include: 'address,contactInformation',
            limit: -1
          }).then(function (results) {
            competitiveSet.set('children', results);
          }).catch(function () /*error*/{
            _this.dialog.showError({
              messageText: _this.i18n.t('pcc-components.customer-list.messages.generic')
            });
          }));
        } else {

          promises.push(_this.store.query('customer', {
            folderid: competitiveSet.get('id'),
            customertype: _this.get('customerType').toUpperCase(),
            include: "address,contactinformation"
          }).then(function (data) {
            _this.set('list', data);
            competitiveSet.set('children', data);
          }).catch(function () /*error*/{
            _this.dialog.showError({
              messageText: _this.i18n.t('pcc-components.customer-list.messages.generic')
            });
          }));
        }
      });

      Ember.RSVP.allSettled(promises).then(function () {
        var competitors = Ember.A();
        var competitorMap = {};

        competitiveSets.forEach(function (competitiveSet) {
          competitiveSet.get('children').forEach(function (setCompetitor) {
            var competitor = void 0;

            if (!competitorMap[setCompetitor.get('id')]) {
              competitorMap[setCompetitor.get('id')] = competitors.length;

              competitor = setCompetitor;
              competitor.sets = [];

              competitors.push(competitor);
            } else {
              competitor = competitors[competitorMap[setCompetitor.get('id')]];
            }

            if (_this.get('useXref')) {
              competitor.sets.push(competitiveSet.get('name'));
            }
          });
        });

        _this.set('competitors', competitors.sortBy('name'));
      }).catch(function () /* error */{
        _this.dialog.showError({
          messageText: _this.i18n.t('pcc-components.customer-list.messages.generic')
        });
      }).finally(function () {
        _this.set('loading', false);
      });
    }
  });
});