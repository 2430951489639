define('ux-pcc/components/yield-manager/dashboard-view-filter', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['dashboard-view-filter'],
    classNameBindings: ['readOnly:dashboard-view-filter--read-only'],

    store: Ember.inject.service(),

    readOnly: false,
    busy: false,
    processingDefaultDates: false,
    initDestinationStatuses: true,

    levelParam: Ember.computed.alias('internalDashboardParameters.LEVEL'),

    selectedBookDateParam: Ember.computed.alias('internalDashboardParameters.BOOK_DATE'),

    selectedDateParam: Ember.computed.alias('internalDashboardParameters.BEGIN_DATE'),

    selectedEndDateParam: Ember.computed.alias('internalDashboardParameters.END_DATE'),

    selectedStlyBookDateParam: Ember.computed.alias('internalDashboardParameters.STLY_BOOK_DATE'),

    selectedStlyDateParam: Ember.computed.alias('internalDashboardParameters.STLY_BEGIN_DATE'),

    selectedStlyEndDateParam: Ember.computed.alias('internalDashboardParameters.STLY_END_DATE'),

    selectedStlwBookDateParam: Ember.computed.alias('internalDashboardParameters.STLW_BOOK_DATE'),

    selectedStlwDateParam: Ember.computed.alias('internalDashboardParameters.STLW_BEGIN_DATE'),

    selectedStlwEndDateParam: Ember.computed.alias('internalDashboardParameters.STLW_END_DATE'),

    externalDataTypeParameter: Ember.computed.alias('dashboardView.dashboardViewParameters.DATA_TYPE'),

    dataTypeParameter: Ember.computed.alias('internalDashboardParameters.DATA_TYPE'),

    missingLevelParam: Ember.computed('levelParam', function () {
      return Ember.isEmpty(this.get('levelParam'));
    }),

    internalDashboardParameters: null,

    timezone: Ember.computed('dataTypeParameter', function () {
      return ['YSGA'].indexOf(this.get('dataTypeParameter')) > -1 ? _moment.default.tz.guess(true) : this.tz.get('customer');
    }),

    _initInternalDashboardParameters: function _initInternalDashboardParameters(params, resetParams) {
      var _this = this;

      // Make a copy of the dashboardViewParameters to manipulate in the filters
      var incomingParameters = JSON.parse(JSON.stringify(params));

      // Check to see if we need to configure the dataType
      var initializeDataTypeValues = this.get('dataTypeParameter') !== Ember.get(incomingParameters, 'DATA_TYPE');
      // If going from YSGA to something else, set a flag to wipe the end dates
      var wipeEndDates = initializeDataTypeValues && this.get('dataTypeParameter') === 'YSGA';

      var idp = this.get('internalDashboardParameters');

      if (idp && !resetParams) {
        // only copy the values that are different so observers fire correctly
        Object.keys(incomingParameters).forEach(function (paramKey) {
          // if the paramKey has _DATE in it, compare using moment comparison
          if (paramKey.indexOf('_DATE') >= 0) {
            if (!(0, _moment.default)(Ember.get(idp, paramKey)).isSame(Ember.get(incomingParameters, paramKey), 'day')) {
              Ember.set(idp, paramKey, Ember.get(incomingParameters, paramKey));
            }
          } else if (Ember.get(idp, paramKey) !== Ember.get(incomingParameters, paramKey)) {
            Ember.set(idp, paramKey, Ember.get(incomingParameters, paramKey));
          }
        });
      } else {
        this.set('internalDashboardParameters', Ember.Object.create(incomingParameters));
      }

      // Fire the dataType initialization
      if (initializeDataTypeValues || resetParams) {
        this._configureParametersForDataType(wipeEndDates, true);
        this.set('processingDefaultDates', true);
        this._setDefaultDates();
      }

      this.set('dashboardView.dashboardViewParameters', Ember.Object.create(this.get('internalDashboardParameters')));

      // Give the reset a chance to take effect (ie skip the auto date processing)
      Ember.run.later(function () {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('processingDefaultDates', false);
          if (initializeDataTypeValues || resetParams) {
            _this.set('manualResetParams', JSON.parse(JSON.stringify(_this.get('dashboardView.dashboardViewParameters'))));
          }
        }
      }, 500);
    },
    _initDashboardParameters: function _initDashboardParameters() {
      var _this2 = this;

      if (this.get('externalDataTypeParameter') && this.get('dataTypeParameter') !== this.get('externalDataTypeParameter')) {

        if (!Ember.isEmpty(this.get('dataTypeParameter'))) {
          // Don't reuse the dates if modified externally, as we are coming from the menus, and not the filter panel
          var dateParams = ['BEGIN_DATE', 'END_DATE', 'STLY_BEGIN_DATE', 'STLY_END_DATE', 'STLW_BEGIN_DATE', 'STLW_END_DATE', 'BOOK_DATE', 'STLY_BOOK_DATE', 'STLW_BOOK_DATE'];
          dateParams.forEach(function (key) {
            if (!Ember.isEmpty(_this2.get('dashboardView.dashboardViewParameters.' + key))) {
              _this2.set('dashboardView.dashboardViewParameters.' + key, null);
            }
          });
        }

        // Make a copy of the dashboardViewParameters to manipulate in the filters
        this._initInternalDashboardParameters(this.get('dashboardView.dashboardViewParameters'));
      }
    },
    init: function init() {
      var _this3 = this;

      this._super.apply(this, arguments);

      if (this.get('initDestinationStatuses')) {
        this.set('isLoading', true);
        this._getDestinationStatus().then(function (result) {
          _this3.set('destinationStatuses', result);
        }).finally(function () {
          _this3.set('isLoading', false);
        });
      }

      this._initDashboardParameters();
      this._previousWeekObserver();
      this._previousYearObserver();
    },
    _getDestinationStatus: function _getDestinationStatus() {
      return Ember.RSVP.hash({
        ysga: this.get('store').query('code', {
          customerID: this.get('customer.id'),
          codeType: 'ADVANCED_ANALYTICS_FILTER_YSGA_RESERVATION_TYPE'
        }).then(function (results) {
          return results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy');
        }),
        tickets: this.get('store').query('code', {
          customerID: this.get('customer.id'),
          codeType: 'ADVANCED_ANALYTICS_FILTER_TICKET_RESERVATION_TYPE'
        }).then(function (results) {
          return results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy');
        }),
        base: this.get('store').query('code', {
          customerID: this.get('customer.id'),
          codeType: 'ADVANCED_ANALYTICS_FILTER_RESERVATION_TYPE'
        }).then(function (results) {
          return results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy');
        })
      });
    },


    // In the case where the dataType is set outside of the dashboard filter (ie via menu item)
    _externalDataTypeParameterObserver: Ember.observer('externalDataTypeParameter', function () {
      this._initDashboardParameters();
    }),

    _dataTypeObserver: Ember.observer('dataTypeParameter', function () {
      this._configureParametersForDataType();
    }),

    _reloadParametersObserver: Ember.observer('reloadParameters', function () {
      this._initInternalDashboardParameters(this.get('dashboardView.dashboardViewParameters'));
    }),

    // Watch the dataType and swap out dashboard parameters that aren't needed
    _configureParametersForDataType: function _configureParametersForDataType(wipeEndDates, resetBeginDates) {
      // Strip out the unnecessary parameters based on view type
      var viewType = this.get('dataTypeParameter');
      var dashboardParameters = this.get('internalDashboardParameters');
      var dateCompare = dashboardParameters.get('DATE_COMPARE');

      if (!this.get('readOnly')) {
        if (wipeEndDates) {
          dashboardParameters.set('END_DATE', null);
          dashboardParameters.set('STLY_END_DATE', null);
          dashboardParameters.set('STLW_END_DATE', null);
        } else if (dashboardParameters && (0, _moment.default)(dashboardParameters.get('BEGIN_DATE')).isAfter((0, _moment.default)(dashboardParameters.get('END_DATE')))) {

          // Since YICD doesn't have end dates, they are not reset when switching, so they could end up BEFORE the start
          // so set them here
          var syncType = this.get('selectedSync') || 'MONTH';
          var beginDate = (0, _moment.default)(dashboardParameters.get('BEGIN_DATE'));
          var endDate = syncType === 'NONE' ? beginDate.clone().endOf('month') : beginDate.clone().endOf(syncType.toLowerCase());

          dashboardParameters.set('END_DATE', endDate);
          dashboardParameters.set('STLY_END_DATE', this._getOffsetDate(endDate.clone(), 'YEAR'));
          dashboardParameters.set('STLW_END_DATE', this._getOffsetDate(endDate.clone(), 'WEEK'));
        }
      }

      switch (viewType) {
        case 'YMTD':
          // Set defaults for missing parameters
          if (dateCompare === 'CUSTOM' || !dateCompare) {
            dashboardParameters.set('DATE_COMPARE', 'YEAR');
          }
          if (!dashboardParameters.get('DATE_SYNC')) {
            dashboardParameters.set('DATE_SYNC', 'MONTH');
          }
          if (!dashboardParameters.get('DISPLAY')) {
            dashboardParameters.set('DISPLAY', 'WEEK_TO_DATE');
          }
          if (!dashboardParameters.get('DATA_VIEW')) {
            dashboardParameters.set('DATA_VIEW', 'ACTUAL');
          }
          if (!dashboardParameters.get('SOURCE_LOCATION_CODE')) {
            dashboardParameters.set('SOURCE_LOCATION_CODE', 'RESERVATION');
          }
          if (!dashboardParameters.get('SHOW_VIEW_TYPE')) {
            dashboardParameters.set('SHOW_VIEW_TYPE', viewType);
          }

          // Clear the unneeded parameters
          dashboardParameters.set('RATE_OF_ADD', null);
          dashboardParameters.set('DAY_OF_WEEK', null);
          dashboardParameters.set('DATE_RANGE', null);
          dashboardParameters.set('VIEW_BY', null);
          dashboardParameters.set('RATE_OF_ADD_TYPE', null);
          // dashboardParameters.set('FULFILLMENT_TYPE', null);

          if (!this.get('readOnly')) {
            if (!resetBeginDates) {
              var selectedDate = (0, _moment.default)(dashboardParameters.get('BEGIN_DATE'));
              var today = (0, _moment.default)();
              // When switching between Y screens, for current month, arrival start day should be first of the month. Otherwise, leave it as it was
              if (today.isSame(selectedDate, 'year') && today.isSame(selectedDate, 'month')) {
                dashboardParameters.set('BEGIN_DATE', today.startOf('month').format('YYYY-MM-DD'));
              }
            }
          }

          break;
        case 'YMCD':
        case 'YMCD-RES':

          // Set defaults for missing parameters
          if (dateCompare === 'CUSTOM' || !dateCompare) {
            dashboardParameters.set('DATE_COMPARE', 'YEAR');
          }
          if (!dashboardParameters.get('DATE_SYNC')) {
            dashboardParameters.set('DATE_SYNC', 'MONTH');
          }
          if (!dashboardParameters.get('RATE_OF_ADD_TYPE')) {
            dashboardParameters.set('RATE_OF_ADD_TYPE', 'ROA');
          }
          if (!dashboardParameters.get('VIEW_BY')) {
            dashboardParameters.set('VIEW_BY', 'DAY');
          }
          if (!dashboardParameters.get('RATE_OF_ADD')) {
            dashboardParameters.set('RATE_OF_ADD', '-1');
          }
          if (!dashboardParameters.get('DAY_OF_WEEK')) {
            dashboardParameters.set('DAY_OF_WEEK', 'ALL');
          }
          if (!dashboardParameters.get('DISPLAY')) {
            dashboardParameters.set('DISPLAY', 'WEEK_TO_DATE');
          }

          // Basically the same except for here
          if (viewType === 'YMCD') {
            if (!dashboardParameters.get('SOURCE_LOCATION_CODE')) {
              dashboardParameters.set('SOURCE_LOCATION_CODE', 'RESERVATION');
            }
          } else {
            dashboardParameters.set('SOURCE_LOCATION_CODE', null);
          }

          if (!dashboardParameters.get('SHOW_VIEW_TYPE')) {
            dashboardParameters.set('SHOW_VIEW_TYPE', viewType);
          }
          // Clear the unneeded parameters
          dashboardParameters.set('DATA_VIEW', null);
          dashboardParameters.set('DATE_RANGE', null);
          //  dashboardParameters.set('FULFILLMENT_TYPE', null);

          if (!this.get('readOnly')) {
            if (resetBeginDates) {
              dashboardParameters.set('BEGIN_DATE', (0, _moment.default)().startOf('day').format('YYYY-MM-DD'));
            } else {
              var _selectedDate = (0, _moment.default)(dashboardParameters.get('BEGIN_DATE')).format('YYYY-MM-DD');
              var _today = (0, _moment.default)();
              // For current month, arrival start day should be today. Otherwise, leave it as it was
              if (_today.isSame(_selectedDate, 'year') && _today.isSame(_selectedDate, 'month')) {
                dashboardParameters.set('BEGIN_DATE', _today.startOf('day').format('YYYY-MM-DD'));
              }
            }
          }

          break;
        case 'YICD':
          // Set defaults for missing parameters
          if (!dateCompare) {
            dashboardParameters.set('DATE_COMPARE', 'YEAR');
          }

          // Always reset the sync for the end dates for when it is switched
          dashboardParameters.set('DATE_SYNC', 'MONTH');

          if (!dashboardParameters.get('DATA_VIEW')) {
            dashboardParameters.set('DATA_VIEW', 'ACTUAL');
          }
          if (!dashboardParameters.get('SOURCE_LOCATION_CODE')) {
            dashboardParameters.set('SOURCE_LOCATION_CODE', 'RESERVATION');
          }
          if (!dashboardParameters.get('SHOW_VIEW_TYPE')) {
            dashboardParameters.set('SHOW_VIEW_TYPE', viewType);
          }
          if (!dashboardParameters.get('DISPLAY')) {
            dashboardParameters.set('DISPLAY', 'FULL');
          }

          // Clear the unneeded parameters
          dashboardParameters.set('DATE_RANGE', null);
          dashboardParameters.set('RATE_OF_ADD', null);
          dashboardParameters.set('DAY_OF_WEEK', null);
          dashboardParameters.set('DATE_RANGE', null);
          dashboardParameters.set('VIEW_BY', null);
          dashboardParameters.set('RATE_OF_ADD_TYPE', null);
          // dashboardParameters = dashboardParameters.rejectBy('name', 'FULFILLMENT_TYPE');

          if (!this.get('readOnly')) {
            if (resetBeginDates) {
              dashboardParameters.set('BEGIN_DATE', (0, _moment.default)().startOf('day').format('YYYY-MM-DD'));
            } else {
              var _selectedDate2 = (0, _moment.default)(dashboardParameters.get('BEGIN_DATE'));
              var _today2 = (0, _moment.default)();
              // For current month, arrival start day should be today. Otherwise, leave it as it was
              if (_today2.isSame(_selectedDate2, 'year') && _today2.isSame(_selectedDate2, 'month')) {
                dashboardParameters.set('BEGIN_DATE', _today2.startOf('day').format('YYYY-MM-DD'));
              }
            }
          }

          break;
        case 'YSGA':
          // Clear the unneeded parameters
          dashboardParameters.set('DISPLAY', null);
          dashboardParameters.set('DATE_SYNC', null);
          dashboardParameters.set('DATE_COMPARE', null);
          dashboardParameters.set('DATA_VIEW', null);
          dashboardParameters.set('SOURCE_LOCATION_CODE', null);
          dashboardParameters.set('SHOW_VIEW_TYPE', null);
          dashboardParameters.set('RATE_OF_ADD', null);
          dashboardParameters.set('DAY_OF_WEEK', null);
          dashboardParameters.set('DATE_RANGE', null);
          dashboardParameters.set('RATE_OF_ADD_TYPE', null);
          dashboardParameters.set('VIEW_BY', null);
      }
    },
    _updateParameter: function _updateParameter(name, value) {
      this.set('internalDashboardParameters.' + name, value);
    },


    isSingleDayAnalysis: Ember.computed('dataTypeParameter', function () {
      var type = this.get('dataTypeParameter');

      return type && ['YICD'].indexOf(type) > -1;
    }),

    isMultiComparisonAnalysis: Ember.computed('dataTypeParameter', function () {
      var type = this.get('dataTypeParameter');

      return type && ['YSGA'].indexOf(type) > -1;
    }),

    selectedSync: Ember.computed.alias('internalDashboardParameters.DATE_SYNC'),

    dateCompareParam: Ember.computed.alias('internalDashboardParameters.DATE_COMPARE'),

    _getOffsetDate: function _getOffsetDate(date, dateCompare) {
      var offsetDate = void 0;
      if (dateCompare === 'YEAR') {
        offsetDate = date.clone().subtract(52, 'weeks');
      } else if (dateCompare === 'WEEK') {
        offsetDate = date.clone().subtract(1, 'week');
      } else {
        offsetDate = date.clone().subtract(12, 'months');
      }

      return offsetDate;
    },
    _setDefaultDates: function _setDefaultDates() {
      var _this4 = this;

      // set default dates
      var tz = this.get('timezone');

      var date = this.get('selectedDateParam') ? _moment.default.tz(this.get('selectedDateParam'), tz) : _moment.default.tz(tz).startOf('month');

      if (this.get('internalDashboardParameters')) {

        var isMultiComparisonAnalysis = this.get('isMultiComparisonAnalysis');

        var isSingleDayAnalysis = this.get('isSingleDayAnalysis');

        var dateCompare = this.get('dateCompareParam') || 'YEAR';

        var stly = this.get('selectedStlyDateParam') ? _moment.default.tz(this.get('selectedStlyDateParam'), tz) : null;
        if (!stly) {
          stly = this._getOffsetDate(date.clone(), isMultiComparisonAnalysis ? 'YEAR' : dateCompare);
        }

        var stlw = this.get('selectedStlwDateParam') ? _moment.default.tz(this.get('selectedStlwDateParam'), tz) : null;
        if (!stlw) {
          stlw = this._getOffsetDate(date.clone(), 'WEEK');
        }

        if (isSingleDayAnalysis) {
          // only use begin dates for single day analysis
          var paramsToClear = [
          /*'END_DATE', 'STLY_END_DATE',*/'STLW_END_DATE', 'BOOK_DATE', 'STLY_BOOK_DATE', 'STLW_BOOK_DATE'];

          paramsToClear.forEach(function (param) {
            _this4._updateParameter(param, null);
          });

          // For some reason YICD fails without end dates
          var endDate = this.get('selectedEndDateParam') ? _moment.default.tz(this.get('selectedEndDateParam'), tz) : null;
          if (!endDate) {
            endDate = date.clone().endOf('month');
          }
          this._updateParameter('END_DATE', endDate.clone());

          var stlyEndDate = this.get('selectedStlyEndDateParam') ? _moment.default.tz(this.get('selectedStlyEndDateParam'), tz) : null;
          if (!stlyEndDate) {
            stlyEndDate = this._getOffsetDate(endDate.clone(), 'YEAR');
          }
          this._updateParameter('STLY_END_DATE', stlyEndDate.clone());
        } else {

          var syncType = this.get('selectedSync') || 'MONTH';

          var _endDate = this.get('selectedEndDateParam') ? _moment.default.tz(this.get('selectedEndDateParam'), tz) : null;
          if (!_endDate) {
            if (syncType !== 'NONE') {
              _endDate = date.clone().endOf(syncType.toLowerCase());
            } else {
              _endDate = date.clone().endOf('month');
            }
          }

          var _stlyEndDate = this.get('selectedStlyEndDateParam') ? _moment.default.tz(this.get('selectedStlyEndDateParam'), tz) : null;
          if (!_stlyEndDate) {
            _stlyEndDate = this._getOffsetDate(_endDate.clone(), isMultiComparisonAnalysis ? 'YEAR' : dateCompare);
          }

          var stlwEndDate = this.get('selectedStlwEndDateParam') ? _moment.default.tz(this.get('selectedStlwEndDateParam'), tz) : null;
          if (!stlwEndDate) {
            stlwEndDate = this._getOffsetDate(_endDate.clone(), 'WEEK');
          }

          this._updateParameter('END_DATE', _endDate.clone());
          this._updateParameter('STLY_END_DATE', _stlyEndDate.clone());
          this._updateParameter('STLW_END_DATE', stlwEndDate.clone());

          if (isMultiComparisonAnalysis) {
            var bookDate = this.get('selectedBookDateParam') ? _moment.default.tz(this.get('selectedBookDateParam'), tz) : null;
            if (!bookDate) {
              bookDate = _moment.default.tz(tz).startOf('day');
              // if bookDate was empty, need to adjust the arrivalDates
              date = bookDate.clone();
              _endDate = date.clone().add(1, 'YEAR');
              this._updateParameter('END_DATE', _endDate.clone());
            }

            var stlyBookDate = this.get('selectedStlyBookDateParam') ? _moment.default.tz(this.get('selectedStlyBookDateParam'), tz) : null;
            if (!stlyBookDate) {
              stlyBookDate = this._getOffsetDate(bookDate.clone(), 'YEAR');
              stly = this._getOffsetDate(date.clone(), 'YEAR');
              this._updateParameter('STLY_END_DATE', this._getOffsetDate(_endDate.clone(), 'YEAR'));
            }

            var stlwBookDate = this.get('selectedStlwBookDateParam') ? _moment.default.tz(this.get('selectedStlwBookDateParam'), tz) : null;
            if (!stlwBookDate) {
              stlwBookDate = this._getOffsetDate(bookDate.clone(), 'WEEK');
              stlw = this._getOffsetDate(date.clone(), 'WEEK');
              this._updateParameter('STLW_END_DATE', this._getOffsetDate(_endDate.clone(), 'WEEK'));
            }

            this._updateParameter('BOOK_DATE', bookDate);
            this._updateParameter('STLY_BOOK_DATE', stlyBookDate);
            this._updateParameter('STLW_BOOK_DATE', stlwBookDate);
          } else {
            this._updateParameter('BOOK_DATE', null);
            this._updateParameter('STLY_BOOK_DATE', null);
            this._updateParameter('STLW_BOOK_DATE', null);
          }
        }

        this._updateParameter('BEGIN_DATE', date.clone());
        this._updateParameter('STLY_BEGIN_DATE', stly.clone());
        this._updateParameter('STLW_BEGIN_DATE', stlw.clone());
      }
    },


    _busyObserver: Ember.observer('busy', function () {
      this.set('childLoading', this.get('busy'));
    }),

    _setCP1Label: function _setCP1Label() {
      var params = this.get('dashboardView.dashboardViewParameters');
      params.set('CP1_LABEL', this.get('i18n').t('dashboard-widget.pw').toString());

      // Check if any of the PW dates are not a week from the driving date, and set the label accordingly
      if ((0, _moment.default)(params.get('BOOK_DATE')).diff((0, _moment.default)(params.get('STLW_BOOK_DATE')), 'DAYS') !== 7 || (0, _moment.default)(params.get('BEGIN_DATE')).diff((0, _moment.default)(params.get('STLW_BEGIN_DATE')), 'DAYS') !== 7 || (0, _moment.default)(params.get('END_DATE')).diff((0, _moment.default)(params.get('STLW_END_DATE')), 'DAYS') !== 7) {
        params.set('CP1_LABEL', this.get('i18n').t('dashboard-widget.cp1').toString());
      }
    },


    _previousWeekObserver: Ember.observer('i18n.locale', 'dashboardView.dashboardViewParameters.{DATA_TYPE,STLW_BOOK_DATE,STLW_BEGIN_DATE,STLW_END_DATE,BOOK_DATE,BEGIN_DATE,END_DATE}', function () {
      // for YSGA screen only
      if (this.get('dashboardView.dashboardViewParameters.DATA_TYPE') === 'YSGA') {
        Ember.run.once(this, '_setCP1Label');
      }
    }),

    _setCP2Label: function _setCP2Label() {
      var params = this.get('dashboardView.dashboardViewParameters');
      params.set('CP2_LABEL', this.get('i18n').t('dashboard-widget.py').toString());

      // Check if any of the PY dates are not 364 days (52 weeks) from the driving date, and set the label accordingly
      if ((0, _moment.default)(params.get('BOOK_DATE')).diff((0, _moment.default)(params.get('STLY_BOOK_DATE')), 'days') !== 364 || (0, _moment.default)(params.get('BEGIN_DATE')).diff((0, _moment.default)(params.get('STLY_BEGIN_DATE')), 'days') !== 364 || (0, _moment.default)(params.get('END_DATE')).diff((0, _moment.default)(params.get('STLY_END_DATE')), 'days') !== 364) {
        params.set('CP2_LABEL', this.get('i18n').t('dashboard-widget.cp2').toString());
      }
    },


    _previousYearObserver: Ember.observer('i18n.locale', 'dashboardView.dashboardViewParameters.{DATA_TYPE,STLY_BOOK_DATE,STLY_BEGIN_DATE,STLY_END_DATE,BOOK_DATE,BEGIN_DATE,END_DATE}', function () {
      // for YSGA screen only
      if (this.get('dashboardView.dashboardViewParameters.DATA_TYPE') === 'YSGA') {
        Ember.run.once(this, '_setCP2Label');
      }
    }),

    actions: {
      saveParamUpdates: function saveParamUpdates() {
        // Ensure that at least one level was selected
        if (Ember.isEmpty(this.get('levelParam'))) {
          return;
        }

        if (this.get('onParamUpdates')) {
          this.set('childLoading', true);
          this.set('dashboardView.dashboardViewParameters', Ember.Object.create(this.get('internalDashboardParameters')));
          this.get('onParamUpdates')();
        }
      },
      reset: function reset() {
        var idp = this.get('internalDashboardParameters');

        if (idp) {
          // Not all views are in the DB (ex MBS), so manually reset the fields if we have a set of values to use
          if (this.get('manualResetParams')) {
            this._initInternalDashboardParameters(this.get('manualResetParams'), true);
          }
        }
      }
    }

  });
});