define('ux-pcc/controllers/customer/-sister-property-modal', ['exports', 'ux-components/utils/format-currency', 'ux-pcc/currencies', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _formatCurrency, _currencies, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    needs: ['customer/sister-properties', 'customer'],
    model: null,
    isMobile: Ember.computed.bool('deviceType.mobile'),
    isNotMobile: Ember.computed.not('isMobile'),
    selectedProperty: null,
    excludedIds: null,

    session: Ember.inject.service('session'),

    currencySymbol: Ember.computed('model.currencyCode', function () {
      var currency = _currencies.default.currencyFor(this.get('model.currencyCode'));
      var format = currency.format;
      return format.symbol || '$';
    }),

    modalTitle: Ember.computed('model.isEdit', function () {
      if (this.get('model.isEdit')) {
        return this.get('i18n').t('customer.sister-properties.modal.edit.title');
      } else {
        return this.get('i18n').t('customer.sister-properties.modal.add.title');
      }
    }),

    validations: Ember.computed('i18n.locale', function () {
      return {
        customer: (0, _validators.validatePresence)({
          presence: true,
          message: this.get('i18n').t('customer.sister-properties.modal.message.missing_property').toString()
        }),
        adjustment: (0, _validators.validateNumber)()
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    typeaheadOptions: {
      highlight: true,
      autoselect: true
    },

    typeaheadData: Ember.computed('session.data.authenticated.token', 'model.excludedIds', function () {
      var _this = this;

      var customersBloodhound = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/rest/v1/customers?q=%QUERY*&limit=10&fields=customers,id,name,customerType,externalReference&customertype=CUSTOMER',
          filter: function filter(_ref) {
            var _ref$customers = _ref.customers,
                customers = _ref$customers === undefined ? [] : _ref$customers;

            // Don't show any of the existing customers in the search results
            var excludedIds = _this.get('model.excludedIds');
            return excludedIds ? customers.reject(function (customer) {
              return excludedIds.includes(customer.id);
            }) : customers;
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'customers',
        displayKey: 'name',
        source: customersBloodhound,
        templates: {
          empty: '<div class="menu-header__search-no-results">' + this.get('i18n').t('customer.sister-properties.modal.no_properties') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class="menu-header__search-item-section">' + model.externalReference + ' - ' + model.name + '</div>';
          }
        }
      };
    }),

    calculatedAdjustment: Ember.computed('changeset.adjustment', function () {
      return !this.get('changeset.adjustment') || this.get('changeset.adjustment') === '' || this.get('changeset.adjustment') === '-' ? 0 : parseFloat(this.get('changeset.adjustment'));
    }),

    bar: Ember.computed('calculatedAdjustment', function () {
      var adjustment = this.get('calculatedAdjustment');
      var bar = parseFloat(this.get('model.bar'));
      if (isNaN(adjustment) || isNaN(bar)) {
        return null;
      } else {
        return (0, _formatCurrency.default)(bar + adjustment, this.get('model.currencyCode'));
      }
    }),

    actions: {
      save: function save(changeset) {
        var _this2 = this;

        changeset.validate().then(function () {

          if (changeset.get('isValid')) {
            changeset.execute();

            // Close this modal
            _this2.send('removeModal');
            _this2.send('saveSisterProperty', _this2.get('model'));
          }
        });
      },
      cancel: function cancel() {
        this.send('removeModal');
      },
      itemSelected: function itemSelected(item /*, name, element*/) {
        this.set('changeset.customerId', item.id);
        //this.set('model.name', item.name);
        this.set('changeset.customer', Ember.Object.create(item));
      }
    }
  });
});