define('ux-pcc/routes/customer/performance/pricing', ['exports', 'ux-pcc/mixins/routes/customer/performance'], function (exports, _performance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_performance.default, {
    model: function model() {
      var _this = this;

      var customer = this.modelFor('customer');
      var promises = [];

      promises.push(this.get('store').query('code', {
        customerID: customer.get('id'),
        codeType: 'MARKET_SEGMENT'
      }));

      return Ember.RSVP.all(promises).then(function (results) {
        var segments = results[0].map(function (segment) {
          return {
            key: segment.get('externalReference').toUpperCase(),
            label: segment.get('shortDescription'),
            orderBy: segment.get("orderBy")
          };
        });

        // Add General to the list
        segments.push({
          key: 'GENERAL',
          label: _this.get('i18n').t('customer.performance.general_segment'),
          orderBy: '9999'
        });

        return {
          segments: segments.sortBy('orderBy')
        };
      });
    },
    setupController: function setupController(controller, model) {

      controller.set('segments', model.segments);

      var detailSegments = [];
      var totalSegment = { key: 'TOTAL', label: this.get('i18n').t('customer._overview.segmentation_chart.total') };
      detailSegments.push(totalSegment);

      detailSegments = detailSegments.concat(model);
      controller.set('detailSegments', detailSegments);

      this._super(controller, model);
    }
  });
});