define('ux-pcc/utils/span-from-dates', ['exports', 'ux-components/utils/span-from-dates'], function (exports, _spanFromDates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _spanFromDates.default;
    }
  });
});