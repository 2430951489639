define('ux-pcc/helpers/array-contains', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayContains = arrayContains;
  function arrayContains(params /*, hash*/) {
    var array = params[0] || [];
    var value = params[1];

    if (Ember.isArray(value) && !Ember.isArray(array)) {
      var swap = array;
      array = value;
      value = swap;
    }

    if (Ember.isNone(array)) {
      array = [];
    }

    var contained = false;
    // IE doesn't support String includes
    if (!String.prototype.includes) {
      String.prototype.includes = function () {
        'use strict';

        contained = this.indexOf(array, value) !== -1;
      };
    } else {
      contained = array.includes(value);
    }

    return contained;
  }

  exports.default = Ember.Helper.helper(arrayContains);
});