define('ux-pcc/components/guarantee-panel/guarantee-form-24-hour-doa', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-24-hour-doa'], function (exports, _guaranteeForm24HourDoa) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeForm24HourDoa.default;
    }
  });
});