define('ux-pcc/controllers/customer-reference', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    // CRS Pricing depends on these query parameters when linking to a route within PCC
    queryParams: ['code', 'location']
  });
});