define('ux-pcc/components/customer/setup/customer-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isExpanded: false,
    dialog: Ember.inject.service(),
    onSave: null,

    actions: {
      editLink: function editLink(customer) {
        if (!(customer.get('addresses') || []).findBy('addressType', 'BUSINESS') && !(customer.get('addresses') || []).findBy('addressType', 'MAIN')) {
          customer.get('addresses').push(this.store.createRecord('address', {
            fkID: customer.get('id'),
            fkReference: 'CUSTOMER',
            addressType: 'BUSINESS',
            rawAddressText: ''
          }));
        }

        if (!(customer.get('contactInformations') || []).findBy('informationType', 'MAIN_PHONE')) {
          customer.get('contactInformations').push(this.store.createRecord('contact-information', {
            fkID: customer.get('id'),
            fkReference: 'CUSTOMER',
            informationType: 'MAIN_PHONE',
            informationValue: ''
          }));
        }

        this.openEditModal('customer/setup/customer-form', customer);
      },
      confirmUnlink: function confirmUnlink(customer) {
        var _this = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('pcc-components.customer-list.dialogs.confirm-delete-title'),
          messageText: this.i18n.t('pcc-components.customer-list.dialogs.warning-message'),
          confirmMessage: this.i18n.t('pcc-components.customer-list.dialogs.proceed'),
          confirmText: this.i18n.t('pcc-components.customer-list.dialogs.confirm-delete'),
          cancelText: this.i18n.t('pcc-components.customer-list.dialogs.decline'),
          onConfirm: function onConfirm() {
            var customerName = customer.get('name');
            customer.destroyRecord().then(function () {
              if (_this.get('onSave')) {
                _this.get('onSave')();
              }
              _this.dialog.close();
              _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.i18n.t('pcc-components.customer-list.dialogs.success-delete-message', { customerName: customerName }), 'success');
            }, function () {
              _this.dialog.close();
              _this.dialog.showError();
            });
          },
          onCancel: function onCancel() {
            _this.dialog.close();
          }
        });
      },
      flash: function flash(message, type) {
        this.flash(message, type);
      },
      toggleSummary: function toggleSummary() {
        var _this2 = this;

        if (this.get('isExpanded')) {
          this.set('isExpanded', false);
        } else {
          this.set('isExpanded', false);
          Ember.run.later(function () {
            _this2.set('isExpanded', true);
          }, 1);
        }
      }
    }
  });
});