define('ux-pcc/templates/modals/nothing', ['exports', 'ux-components/templates/modals/nothing'], function (exports, _nothing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _nothing.default;
    }
  });
});