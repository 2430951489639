define('ux-pcc/components/charts/revenue-chart', ['exports', 'moment', 'ux-components/utils/format-currency'], function (exports, _moment, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this._createChart);
    },
    _createChart: function _createChart() {
      var _this = this;

      var currencyCode = this.currencyCode;

      var dataProvider = this.dataProvider;
      var dataFields = this.dataFields;

      var bubbleContent = function bubbleContent(item) {
        var m = _moment.default.tz(item.category, _this.tz.customer);
        var displayData = ['<div class="center">\n           <span class="bubble-value">' + m.format('dddd') + ', ' + m.format('L') + '</span>\n         </div>'];

        dataFields.forEach(function (dataField) {
          displayData.push('<div>\n             <span class="bubble-label">' + dataField.label + ':</span>\n             <span class="bubble-value">' + (dataField.key in item.dataContext ? (0, _formatCurrency.default)(item.dataContext[dataField.key], currencyCode) : 'NA') + '</span>\n           </div>');
        });

        return displayData.join('');
      };

      // Metrics Chart
      var lineThickness = 1.5;
      var chart = AmCharts.makeChart('revenue-chart', {
        hideCredits: true,
        addClassNames: true,
        type: 'serial',
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryAxis: {
          color: '#666666',
          axisColor: '#666666',
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          parseDates: false,
          autoGridCount: !this.deviceType.mobile,
          gridCount: 3,
          labelFunction: function labelFunction(valueText) {
            var m = _moment.default.tz(valueText, _this.tz.customer);
            return m.format('ddd') + '\n\r' + m.format('L').substring(0, 5);
          }
        },
        chartCursor: {
          zoomable: false,
          selectWithoutZooming: true,
          cursorColor: '0', //'#4d96cb',
          color: '#f3faff',
          categoryBalloonEnabled: false,
          cursorAlpha: 0,
          balloonPointerOrientation: 'vertical'
        },
        trendLines: [],
        graphs: [{
          title: this.i18n.t('customer.performance.overview.chart.adr'),
          balloonFunction: function balloonFunction(item) {
            return 'adr' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          lineColor: '#9acbc7',
          lineColorField: 'adr_color',
          type: 'line',
          lineThickness: lineThickness,
          id: 'adr chart-color--0',
          valueField: 'adr',
          valueAxis: 'amount',
          clustered: true,
          connect: false
        }, {
          title: this.i18n.t('customer.performance.overview.chart.revpar'),
          balloonFunction: function balloonFunction(item) {
            return !('adr' in item.dataContext) && 'revpar' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          lineColor: '#aa9882',
          lineColorField: 'revpar_color',
          type: 'line',
          lineThickness: lineThickness,
          id: 'revpar chart-color--1',
          valueField: 'revpar',
          valueAxis: 'amount',
          clustered: true,
          connect: false
        }, {
          title: this.i18n.t('customer.performance.overview.chart.adr_stly'),
          balloonFunction: function balloonFunction(item) {
            return !('adr' in item.dataContext) && !('revpar' in item.dataContext) && 'adr_stly' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          lineColor: '#9acbc7',
          type: 'line',
          lineThickness: lineThickness,
          id: 'adrStly chart-color--0 muted',
          valueField: 'adr_stly',
          valueAxis: 'amount',
          clustered: true,
          connect: false
        }, {
          title: this.i18n.t('customer.performance.overview.chart.revpar_stly'),
          balloonFunction: function balloonFunction(item) {
            return !('adr' in item.dataContext) && !('revpar' in item.dataContext) && !('adr_stly' in item.dataContext) && 'revpar_stly' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          lineColor: '#aa9882',
          type: 'line',
          lineThickness: lineThickness,
          id: 'revparStly chart-color--1 muted',
          valueField: 'revpar_stly',
          valueAxis: 'amount',
          clustered: true,
          connect: false
        }],
        valueAxes: [{
          id: 'amount',
          color: '#999999',
          gridAlpha: 0.1,
          axisAlpha: 0,
          zeroGridAlpha: 0,
          minimum: 0,
          position: 'left',
          labelFunction: function labelFunction(valueText) {
            return (0, _formatCurrency.default)(valueText, currencyCode, { hash: { precision: 0 } });
          },
          showFirstLabel: true
        }],
        guides: [{
          id: 'today',
          category: _moment.default.tz(this.tz.customer).startOf('day').format(),
          lineColor: '#666666',
          color: '#999999',
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        }],
        legend: {
          enabled: true,
          position: 'top',
          markerSize: 10,
          markerType: 'square',
          valueWidth: 0
        },
        allLabels: [],
        balloon: {
          adjustBorderColor: true,
          borderAlpha: 0,
          shadowAlpha: 0,
          color: '#ffffff',
          fillColor: '#0B8CF5',
          fillAlpha: 0.9,
          textAlign: 'middle',
          verticalPadding: 15,
          horizontalPadding: 20
        },
        titles: [],
        zoomOutOnDataUpdate: true,
        dataProvider: dataProvider
      }, 1);

      this.set('_chart', chart);
    }
  });
});