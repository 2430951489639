define('ux-pcc/components/team-users', ['exports', 'ux-components/components/team-users'], function (exports, _teamUsers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _teamUsers.default;
    }
  });
});