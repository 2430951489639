define('ux-pcc/components/rules/rule-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['rule-condition'],
    classNameBindings: ['isCeiling:rule-condition__ceiling'],

    onOr: function onOr() {},
    onRemove: function onRemove() {},
    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    ruleType: null,
    isCeiling: Ember.computed.equal('ruleType', 'CEILING'),
    isDisplayEventTime: false,

    init: function init() {
      this._super.apply(this, arguments);

      this._defaultFilterField();

      var changeset = this.get('changeset');

      this.onRegisterValidation(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.onUnregisterValidation(this.get('changeset'));
    },


    filterFieldsObserver: Ember.observer('filterFields', function () {
      this._defaultFilterField();
    }),

    _defaultFilterField: function _defaultFilterField() {
      var changeset = this.get('changeset');

      // flatten the list of filter fields
      var filterFields = this.get('filterFields').reduce(function (result, current) {
        if (current.options && current.options.length) {
          result.push.apply(result, _toConsumableArray(current.options));
        } else {
          result.push(current);
        }
        return result;
      }, []);

      if (!filterFields.get('length')) {
        // If there are no filter fields, clear it out.
        changeset.set('filterField', '');
      } else if (!filterFields.findBy('value', changeset.get('filterField'))) {
        // If the current filter fields doesn't exist in the list, default it
        changeset.set('filterField', filterFields.get('firstObject.value'));
      }
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        filterField: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form_modal.no_type').toString()
        })
      };
    }),

    changeset: Ember.computed('condition', function () {
      var validations = this.get('validations');
      var changeset = new _emberChangeset.default(this.get('condition'), (0, _emberChangesetValidations.default)(validations), validations);

      var children = this.get('condition.children') || [];
      changeset.set('children', [].concat(_toConsumableArray(children)));
      return changeset;
    }),

    showGroupedFilterFields: Ember.computed('ruleType', function () {
      return this.get('ruleType') === 'ALERT_INV' || this.get('ruleType') === 'ALERT_OCC' || this.get('ruleType') === 'ALERT_COMP' || this.get('ruleType') === 'ALERT_PERF';
    }),

    filterFields: Ember.computed('i18n.locale', 'ruleType', function () {

      var fields = null;
      switch (this.get('ruleType')) {
        // allow grouped fields for notification types
        case 'ALERT_INV':
        case 'ALERT_OCC':
        case 'ALERT_COMP':
        case 'ALERT_PERF':
          {
            // General/Shared
            var generalFields = [{ value: "EVENT_DATE", label: this.i18n.t('pcc-components.rules.conditions.filters.event_date') }];
            // Inventory
            var inventoryFields = [{ value: "CRS_FREEZE_INV", label: this.i18n.t('pcc-components.rules.conditions.filters.crs_freeze') }, { value: "EVENT_DATE", label: this.i18n.t('pcc-components.rules.conditions.filters.event_date') },
            // todo: enable when AP is system of record for group releases
            //{value: "GROUP_REL_INV", label: this.i18n.t('pcc-components.rules.conditions.filters.group_inv_release')},
            { value: "MGR_HOLD_INV", label: this.i18n.t('pcc-components.rules.conditions.filters.manager_hold_inv') }, { value: "OOO_INV", label: this.i18n.t('pcc-components.rules.conditions.filters.out_of_order_inv') }];
            // Occupancy
            var occupancyFields = [{ value: "FORECAST_OCC", label: this.i18n.t('pcc-components.rules.conditions.filters.forecast_occ') }, { value: "OCC_PCT", label: this.i18n.t('pcc-components.rules.conditions.filters.property_otb_occ') }, { value: "STLY_OCC", label: this.i18n.t('pcc-components.rules.conditions.filters.property_stly_occ') }];
            // Performance
            var performanceFields = [{ value: "ADR_VS_STLY", label: this.i18n.t('pcc-components.rules.conditions.filters.adr_vs_stly') }, { value: "ADR_VS_STLY_PCT", label: this.i18n.t('pcc-components.rules.conditions.filters.adr_vs_stly_pct') }, {
              value: "RATE_CODE_OCC_CONTRIB",
              label: this.i18n.t('pcc-components.rules.conditions.filters.rate_code_occ_contrib')
            }, {
              value: "CHANNEL_OCC_CONTRIB",
              label: this.i18n.t('pcc-components.rules.conditions.filters.channel_occ_contrib')
            }];
            // if Competition rule type, display competition fields, otherwise don't
            if (this.get('ruleType') === 'ALERT_COMP') {
              var competitionFields = [{ value: "COMP_STATUS", label: this.i18n.t('pcc-components.rules.conditions.filters.competitor_status') }, { value: "SELL_RATE", label: this.i18n.t('pcc-components.rules.conditions.filters.sellable_rate') }];
              fields = [{ options: generalFields, label: this.i18n.t('pcc-components.rules.conditions.filters.general') }, { options: competitionFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.competition') }, { options: inventoryFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.inventory') }, { options: occupancyFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.occupancy') }, { options: performanceFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.performance') }];
            } else {
              fields = [{ options: generalFields, label: this.i18n.t('pcc-components.rules.conditions.filters.general') }, { options: inventoryFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.inventory') }, { options: occupancyFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.occupancy') }, { options: performanceFields, label: this.i18n.t('pcc-components.rule-rates.rule-panel.performance') }];
            }
            break;
          }
        case 'BASED_ON_ADJUSTMENT':
        case 'RESTRICTION':
          {
            fields = [{ value: "", label: this.i18n.t('pcc-components.rules.conditions.filters.select') }, { value: "SOLD_BY_PRICE", label: this.i18n.t('pcc-components.rules.conditions.filters.sold_by_price') }, { value: "OCC_PCT", label: this.i18n.t('pcc-components.rules.conditions.filters.property_occ') }, { value: "EVENT_DATE", label: this.i18n.t('pcc-components.rules.conditions.filters.prior_to_arrival') }];
            if (this.get('isDisplayEventTime')) {
              fields.push({
                value: "EVENT_TIME",
                label: this.i18n.t('pcc-components.rules.conditions.filters.time_of_day')
              });
            }
            break;
          }
        case 'CEILING':
          {
            fields = [{ value: "OCC_PCT", label: this.i18n.t('pcc-components.rules.conditions.filters.property_occ') }];
            break;
          }
        default:
          {
            fields = [{ value: "", label: this.i18n.t('pcc-components.rules.conditions.filters.select') }, { value: "SOLD_BY_PRICE", label: this.i18n.t('pcc-components.rules.conditions.filters.sold_by_price') }, { value: "OCC_PCT", label: this.i18n.t('pcc-components.rules.conditions.filters.property_occ') }, { value: "EVENT_DATE", label: this.i18n.t('pcc-components.rules.conditions.filters.prior_to_arrival') }];
            break;
          }
      }

      return fields;
    }),

    useLargerSelect: Ember.computed('ruleType', function () {

      var rtnVal = true;

      switch (this.get('ruleType')) {

        case 'ALERT_COMP':
        case 'ALERT_PERF':
          rtnVal = true;
          break;
      }
      return rtnVal;
    }),

    showInitialIf: Ember.computed('changeset.filterField', function () {

      var rtnVal = true;

      switch (this.get('changeset.filterField')) {

        case 'COMPETITOR':
          rtnVal = false;
          break;
      }

      return rtnVal;
    }),

    showIs: Ember.computed('changeset.filterField', function () {

      var rtnVal = true;

      if (!this.get('changeset.filterField')) {
        return false;
      } else {
        switch (this.get('changeset.filterField')) {

          case 'CRS_FREEZE_INV':
          case 'RATE_CODE_OCC_CONTRIB':
          case 'CHANNEL_OCC_CONTRIB':
          case 'COMPETITOR':
            rtnVal = false;
            break;
        }
      }
      return rtnVal;
    }),

    isCompetitorFilter: Ember.computed.equal('changeset.filterField', 'COMPETITOR'),

    allowDelete: Ember.computed.not('isCeiling'),

    ruleConditionComponent: Ember.computed('changeset.filterField', function () {
      if (this.get('ruleType') === 'CEILING') {
        return 'rules/conditions/ceiling-occ-pct-condition';
      } else if ((this.get('ruleType') === 'BASED_ON_ADJUSTMENT' || this.get('ruleType') === 'RESTRICTION') && this.get('changeset.filterField') === 'EVENT_DATE') {
        return 'rules/conditions/prior-to-arrival-condition';
      } else {
        var filterField = this.get('changeset.filterField') || this.get('filterFields.firstObject.value');

        if (filterField) {
          return 'rules/conditions/' + filterField.toLowerCase() + '-condition';
        }
      }
    }),

    actions: {
      or: function or() {
        this.onOr(this.get('condition'));
        this.onUnregisterValidation(this.get('changeset'));
      },
      remove: function remove() {
        this.onRemove(this.get('condition'));
        this.onUnregisterValidation(this.get('changeset'));
      },
      registerValidation: function registerValidation(e) {
        this.onRegisterValidation(e);
      },
      unregisterValidation: function unregisterValidation(e) {
        this.onUnregisterValidation(e);
      }
    }
  });
});