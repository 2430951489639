define('ux-pcc/routes/login/index', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashable.default, {

    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.session.prohibitAuthentication('index');

      if (this.config.authUrl) {
        console.log('auth URL was configured.  Redirecting to ' + this.config.authUrl);
        window.location.replace(this.config.authUrl);
      }
    }
  });
});