define('ux-pcc/components/charts/chart-basic', ['exports', 'ux-pcc/templates/components/charts/chart-basic'], function (exports, _chartBasic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _chartBasic.default,
    tagName: "div",

    i18n: Ember.inject.service(),
    showTable: false,

    didInsertElement: function didInsertElement() {
      this._createChart();
    },
    _config: function _config() {

      var config = {
        hideCredits: true,
        type: "serial",
        language: this.get('i18n.locale'),
        categoryField: "slice",
        addClassNames: true,
        sequencedAnimation: false,
        panEventsEnabled: false,
        startDuration: 0,
        legend: {
          useGraphSettings: true,
          valueWidth: 0
        },
        categoryAxis: {
          color: "#666666",
          axisColor: "#666666",
          axisThickness: 2,
          gridAlpha: 0,
          gridPosition: "start",
          parseDates: false
        },
        chartCursor: {
          zoomable: false,
          selectWithoutZooming: true,
          cursorColor: "0", //"#4d96cb",
          color: "#f3faff",
          categoryBalloonEnabled: false
        },
        dataProvider: this.get('_data')
      };

      // TODO: merge with Ember instead of jQuery
      return Ember.$.extend(true, {}, config, this.get('config'));
    },


    _data: Ember.computed('data', 'dataTransform', function () {
      var data = this.get('data');

      // todo: may want to copy 'data' so mutation doesn't occur
      return this.get('dataTransform')(data || []);
    }),

    _createChart: function _createChart() {
      var data = this.get('data');
      if (!data || data.length <= 0) {
        return;
      }

      var $chartContainer = Ember.$(this.element).find('.am-chart-container');

      var chart = AmCharts.makeChart($chartContainer[0], this._config());

      this.set('_chart', chart);

      return chart;
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      // Hook for rebuilding guides, etc.

    },
    _afterRedrawOnDataChanged: function _afterRedrawOnDataChanged() {
      // Hook for post render events, etc.

    },


    dataTransform: function dataTransform(data) {
      return data;
    },

    dataChanged: Ember.observer('_data', function () {
      var _this = this;

      var chart = this.get('_chart');

      if (!chart) {
        return;
      }

      chart.dataProvider = this.get('_data');

      this._beforeRedrawOnDataChanged();

      Ember.run.scheduleOnce('afterRender', this, function () {
        chart.validateData();
        _this._afterRedrawOnDataChanged();
      }, 0);
    })
  });
});