define('ux-pcc/components/panels/price-point-allocations-panel', ['exports', 'ux-components/components/panels/price-point-allocations-panel'], function (exports, _pricePointAllocationsPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pricePointAllocationsPanel.default;
    }
  });
});