define('ux-pcc/routes/data-monitoring/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      page: {
        refreshModel: true
      },
      filterCustomerID: {
        refreshModel: true
      },
      sortField: {
        refreshModel: true
      },
      sortAscending: {
        refreshModel: true
      },
      shortTermFilter: {
        refreshModel: true
      },
      longTermFilter: {
        refreshModel: true
      }
    },

    ajax: Ember.inject.service(),

    model: function model(params) {
      var tz = this.tz.get('customer');
      var customerID = this.modelFor('customer').get('id');

      var summaryDate = _moment.default.tz(tz).startOf('day');

      var page = params.page || 1;

      var queryParams = {
        summaryDate: summaryDate.format('YYYY-MM-DD'),
        page: page,
        limit: 50,
        sortAscending: params.sortAscending
      };

      if (params.filterCustomerID) {
        queryParams.filterCustomerID = params.filterCustomerID;
      }

      if (params.sortField) {
        queryParams.sortField = params.sortField;
        this.set('querySortField', null);
      }

      if (params.shortTermFilter) {
        queryParams.shortTermFilter = params.sortField;
      }

      if (params.longTermFilter) {
        queryParams.longTermFilter = params.longTermFilter;
      }

      return this.ajax.request('/rest/v1/dataMonitoring/summary/' + customerID, { data: queryParams }).then(function (content) {
        return content || { customerSummaryMetrics: [] };
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.customerSummaryMetrics || []);
      controller.set('loading', false);
      controller.set('resultsFound', model.meta.pagination.resultsFound);
      controller.set('latestExtractDate', (0, _moment.default)(model.meta.latestExtractDate));
      if (this.get('querySortField')) {
        controller.set('sortedList.sortProperties', [this.get('querySortField')]);
      }
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      queryParamsDidChange: function queryParamsDidChange() {
        this._super.apply(this, arguments);
        if (this.get('controller')) {
          this.get('controller').set('loading', true);
        }
      }
    }

  });
});