define('ux-pcc/routes/rule-rates/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('rule', params.id, { include: 'children' });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      model.set('customer', controller.get('customer'));
      model.set('currencyCode', controller.get('currencyCode'));
      if (model.get('price')) {
        controller.set('origPrice', model.get('price'));
      }

      // Loop through the children and see if the notify flag was set
      var children = model.get('children');
      var selectedConditions = children.filterBy('filterValue').filter(function (child) {
        var json = Ember.get(child, 'filterValue');
        return JSON.parse(json).notify;
      });

      controller.set('selectedConditions', selectedConditions);
    },


    actions: {
      ruleSaved: function ruleSaved() {
        // Go back to the rule rates list
        this.transitionTo('rule-rates');
      }
    }
  });
});