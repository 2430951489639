define('ux-pcc/components/rule-advanced/rule-filter-value-comparison', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      changeset.setProperties({
        filterRangeLow: null,
        filterRangeHigh: null,
        filterField2: null,
        filterValueRule: null
      });

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', 'isNumberLikeType', function () {

      if (this.get('isNumberLikeType')) {
        return {
          filterValue: (0, _validators.validateNumber)({
            presence: true,
            message: this.i18n.t('rule._form_modal.no_value').toString()
          })
        };
      } else {
        return {
          filterValue: (0, _validators.validatePresence)({
            presence: true,
            message: this.i18n.t('rule._form_modal.no_value').toString()
          })
        };
      }
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    }),

    isNumberLikeType: Ember.computed('type', function () {
      return ['percent', 'number', 'numerical'].includes(this.get('type'));
    })

  });
});