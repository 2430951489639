define('ux-pcc/controllers/customer/performance/matrix', ['exports', 'lodash', 'accounting', 'moment', 'ux-components/utils/format-currency', 'ux-components/utils/moment-range', 'ux-components/utils/to-boolean', 'ux-pcc/controllers/customer/performance/abstract-matrix'], function (exports, _lodash, _accounting, _moment, _formatCurrency, _momentRange, _toBoolean, _abstractMatrix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractMatrix.default.extend(Ember.Evented, {
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    permission: Ember.inject.service(),
    queryParams: ['startDate', 'viewBy'],
    startDate: null,

    viewBy: null,
    inventoryRollup: null,
    rollupProducts: [],

    filteredRollupProductIds: Ember.computed('rollupProducts.[]', 'inventoryRollup', function () {
      return this.get('inventoryRollup') ? [this.get('inventoryRollup')] : this.rollupProducts.mapBy('id');
    }),

    filteredProducts: Ember.computed('filteredRollupProductIds.[]', 'products', function () {
      var filteredRollupProductIds = this.get('filteredRollupProductIds');
      // filter the product list down where the rollup product is in the path
      return (this.get('products') || []).filter(function (p) {
        return (p.get('ancestors') || []).filter(function (a) {
          return filteredRollupProductIds.indexOf(a.get('id')) > -1;
        }).length > 0;
      });
    }),

    table: Ember.inject.service(),

    occupancyForecastSubcontentType: Ember.computed(function () {
      var model = this.get('customer.settings').findBy('settingCode', 'forecasting.occupancy.forecasting_models');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'HOLT_WINTERS_MULTIPLICATIVE';
      }
    }),

    occupancySubcontentType: Ember.computed(function () {
      var model = this.get('customer.settings').findBy('settingCode', 'INVENTORY_CALCULATION_METHOD_PROPERTY_PERFORMANCE');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'YIELDABLE';
      }
    }),

    showComparisonPeriodPicker: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_MATRIX_COMPARISON_PERIOD_SELECTOR');
      return settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false;
    }),

    showInventoryRollup: Ember.computed('customer.settings', function () {
      var settings = this.get('customer.settings').findBy('settingCode', 'PCC.ENABLE_MATRIX_INVENTORY_ROLLUP_SELECTOR');
      return settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false;
    }),

    isProperty: Ember.computed('customer.customerType', function () {
      return this.get('customer.customerType') === 'CUSTOMER';
    }),

    _calculateComparisonDate: function _calculateComparisonDate(date, viewBy) {
      switch (viewBy) {
        case 'day':
          return date.add(1, 'day');
        case 'month':
          return date.add(1, 'month');
        default:
          return date.add(52, 'weeks');
      }
    },


    hasWeather: Ember.computed.notEmpty('model.weather'),

    hasEvents: Ember.computed.notEmpty('model.events'),

    isMobile: false,
    isDesktop: false,
    isNonMobile: Ember.computed.not('isMobile'),
    isNonDesktop: Ember.computed.not('isDesktop'),
    isTablet: Ember.computed.and('isNonMobile', 'isNonDesktop'),
    fixedColumnCount: Ember.computed('isMobile', function () {
      return this.get('isMobile') ? 2 : 1;
    }),

    showRates: Ember.computed('session', function () {
      return this.permission.isPermitted('performance_matrix', 'view', 'rates');
    }),

    showGroupBlockPickups: Ember.computed('session', function () {
      return this.permission.isPermitted('performance_matrix', 'view', 'group_block_pickup');
    }),

    showAdrForecast: Ember.computed('session', 'customer.permissionType', function () {
      return this.permission.isPermitted(this.get('customer.permissionType') + 'forecast', 'view', 'adr');
    }),

    showOccForecast: Ember.computed('session', 'customer.permissionType', function () {
      return this.permission.isPermitted(this.get('customer.permissionType') + 'forecast', 'view', 'occ');
    }),

    showInfoStationRows: Ember.computed('session', 'customer.permissionType', function () {
      return this.permission.isPermitted(this.get('customer.permissionType') + 'kpi', 'view', 'info_station');
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    }),

    competitorLoadDate: Ember.computed('customer.udfValues', function () {
      if (this.get('customer.udfValues') && this.get('customer.udfValues').hasOwnProperty('TCLK_LOAD_DATE')) {
        var tz = this.tz.get('customer');
        return (0, _moment.default)(this.get('customer.udfValues')['TCLK_LOAD_DATE']).tz(tz).format('lll');
      } else {
        return null;
      }
    }),

    events: [],

    _repaintMatrixHeader: function _repaintMatrixHeader() {
      var $headers = Ember.$('table:not([class*=fixed]) thead:not([hidden])');
      $headers.each(function (i, header) {
        if (i === 0) {
          Ember.$(header).addClass('active');
        } else {
          Ember.$(header).removeClass('active');
        }
      });
      this.table.trigger('tableInvalidate');
    },
    _mapValues: function _mapValues(model) {
      var _this = this;

      var isProperty = this.get('isProperty');
      var hasWeather = this.get('hasWeather');
      var hasEvents = this.get('hasEvents');
      var tz = this.tz.get('customer');
      var today = _moment.default.tz(tz).startOf('day');
      var mapped = {};

      var getPropName = function getPropName(contentType, subContentType, isStly) {
        var parts = [contentType];
        if (parts && parts.indexOf('OCCUPANCY') < 0) {
          parts.push(subContentType);
        }
        parts.push(isStly ? 'stly' : void 0);

        return parts.filter(function (p) {
          return p;
        }).join('_').toLowerCase();
      };

      var seenCompetitors = {};
      var competitorProp = getPropName('SELL_RATE', 'COMPETITOR');
      var getCompetitorPropName = function getCompetitorPropName(competitor) {
        return competitorProp + '_' + competitor.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      };

      var mapValue = function mapValue() {
        var isStly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        return function (e) {
          var m = _moment.default.tz(Ember.get(e, 'beginDate'), tz);

          if (isStly) {
            m = _this._calculateComparisonDate(m.clone(), _this.viewBy);
          }

          var key = m.format();
          if (!mapped[key]) {
            mapped[key] = {};
          }

          var prop = getPropName(Ember.get(e, 'contentType'), Ember.get(e, 'subContentType'), isStly);

          switch (Ember.get(e, 'contentType')) {
            case 'SELL_RATE':
              {
                // Extract out competitors and inversely map
                if (Ember.get(e, 'subContentType') === 'COMPETITOR') {
                  var name = decodeURIComponent(Ember.get(e, 'description').replace(/\+/g, ' '));
                  seenCompetitors[name] = { label: name, values: {}, classNames: 'matrix-row__competitor', labelClassNames: 'matrix-label__sub' };
                  prop = (0, _lodash.camelCase)(getCompetitorPropName(name));
                }
                mapped[key][prop] = parseFloat(decodeURIComponent(Ember.get(e, 'dataValue')).replace(',', ''));
                break;
              }
            case 'OCCUPANCY_FORECAST':
              {
                // Make sure this matches the forecast model selected, and user is allowed
                if (Ember.get(e, 'subContentType') === _this.get('occupancyForecastSubcontentType')) {
                  mapped[key]['occupancy_forecast'] = parseFloat(decodeURIComponent(Ember.get(e, 'dataValue')).replace(',', ''));
                }
                break;
              }
            case 'OCCUPANCY':
              {
                if (Ember.get(e, 'subContentType') && _this.get('occupancySubcontentType') && Ember.get(e, 'subContentType').toUpperCase() === _this.get('occupancySubcontentType').toUpperCase()) {
                  mapped[key][prop] = parseFloat(decodeURIComponent(Ember.get(e, 'dataValue')).replace(',', ''));
                }
                break;
              }
            case 'EXTERNAL_SUMMARY':
              {
                var dataValues = Ember.get(e, 'dataValues');
                dataValues.forEach(function (es) {
                  mapped[key]['ES_' + es.key] = parseFloat(decodeURIComponent(es.value).replace(',', ''));
                });
                break;
              }
            default:
              {
                mapped[key][prop] = parseFloat(decodeURIComponent(Ember.get(e, 'dataValue')).replace(',', ''));
                break;
              }
          }
        };
      };

      var mapForecastValue = function mapForecastValue(e) {
        var m = Ember.get(e, 'entryDate');
        if (m) {
          var key = _moment.default.tz(m, tz).format();
          if (!mapped[key]) {
            mapped[key] = {};
          }

          mapped[key]['adr_forecast'] = parseFloat(Ember.get(e, 'forecast'));
        }
      };

      var mapWeatherValue = function mapWeatherValue(e) {
        var m = Ember.get(e, 'entryDate');
        if (m) {
          var key = _moment.default.tz(m, tz).format();
          if (!mapped[key]) {
            mapped[key] = {};
          }

          var weatherData = Ember.get(e, 'dataValue').split('%7C');

          mapped[key]['weather'] = weatherData[0];
          mapped[key]['weatherHigh'] = weatherData[1];
          mapped[key]['weatherLow'] = weatherData[2];
        }
      };

      var mapEventValue = function mapEventValue(e) {
        (0, _momentRange.default)((0, _moment.default)(e.get('beginDate')).tz(tz), (0, _moment.default)(e.get('endDate')).tz(tz)).forEach(function (m) {
          var key = m.tz(tz).format();
          if (mapped[key]) {
            var eventsList = mapped[key]['events'] || [];
            eventsList.push(e);
            mapped[key]['events'] = eventsList;
            mapped[key]['eventType'] = e.get('campaignType');
            mapped[key]['eventText'] = eventText(eventsList);
          }
        });
      };

      var eventText = function eventText(events) {
        var text = '';
        if (events && events.get('length') > 0) {
          events.forEach(function (event) {
            text += event.get('shortDescription') + '\n' + event.get('longDescription') + '\n';
          });
        }
        return text;
      };

      var mapDailyMetricsValue = function mapDailyMetricsValue() {
        var isStly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        return function (e) {
          var m = _moment.default.tz(Ember.get(e, 'rpcDate'), tz);
          var suffix = '';

          if (isStly) {
            m = _this._calculateComparisonDate(m.clone(), _this.viewBy);
            suffix = '_stly';
          }

          var key = m.format();
          if (!mapped[key]) {
            mapped[key] = { sold: 0, arrivals: 0, inhouse: 0, departures: 0, sold_stly: 0, arrivals_stly: 0, inhouse_stly: 0, departures_stly: 0, inventory_physical: 0, inventory_physical_stly: 0, inventory_yieldable: 0, inventory_yieldable_stly: 0, inventory_remaining: 0, inventory_remaining_stly: 0 };
          } else if (!mapped[key].hasOwnProperty('sold')) {
            Ember.merge(mapped[key], { sold: 0, arrivals: 0, inhouse: 0, departures: 0, sold_stly: 0, arrivals_stly: 0, inhouse_stly: 0, departures_stly: 0, inventory_physical: 0, inventory_physical_stly: 0, inventory_yieldable: 0, inventory_yieldable_stly: 0, inventory_remaining: 0, inventory_remaining_stly: 0 });
          }

          mapped[key]['arrivals' + suffix] += Ember.get(e, 'scheduledArrivals.length') || 0;
          mapped[key]['inhouse' + suffix] += (Ember.get(e, 'scheduledArrivals.length') || 0) + (Ember.get(e, 'stayOvers.length') || 0);
          mapped[key]['departures' + suffix] += Ember.get(e, 'scheduledDepartures.length') || 0;
          mapped[key]['sold' + suffix] += (Ember.get(e, 'scheduledArrivals.length') || 0) + (Ember.get(e, 'stayOvers.length') || 0);
          mapped[key]['inventory_physical' + suffix] += Ember.get(e, 'physical') || 0;
          mapped[key]['inventory_yieldable' + suffix] += Ember.get(e, 'yieldable') || 0;
          mapped[key]['inventory_remaining' + suffix] += Ember.get(e, 'remaining') || 0;
        };
      };

      var mapDailyMetricsPerformanceValue = function mapDailyMetricsPerformanceValue() {
        var isStly = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        return function (e) {
          var m = _moment.default.tz(Ember.get(e, 'beginDate'), tz);

          if (isStly) {
            m = _this._calculateComparisonDate(m.clone(), _this.viewBy);
          }

          var key = m.format();
          if (!mapped[key]) {
            mapped[key] = { occupancy: 0, revenue: 0, occupancy_stly: 0, revenue_stly: 0 };
          } else if (!mapped[key].hasOwnProperty('occupancy')) {
            Ember.merge(mapped[key], { occupancy: 0, revenue: 0, occupancy_stly: 0, revenue_stly: 0 });
          }

          var prop = getPropName(Ember.get(e, 'contentType'), Ember.get(e, 'subContentType'), isStly);
          mapped[key][prop] += parseFloat(decodeURIComponent(Ember.get(e, 'dataValue')).replace(',', ''));
        };
      };

      if (isProperty) {

        if (!this.showInventoryRollup) {
          // Munge inventory
          model.inventory.forEach(mapValue());
          model.inventorySTLY.forEach(mapValue(true));
          if (this.showRates) {
            // Munge rates
            model.rates.forEach(mapValue());

            // Munge Competitors
            (0, _lodash.values)(seenCompetitors).forEach(function (competitor) {
              competitor.id = '' + (0, _lodash.camelCase)(getCompetitorPropName(competitor.label));
            });
          }

          if (this.showInfoStationRows && model.infoStation) {
            // Munge station info results
            model.infoStation.forEach(mapValue());
          }
        }
      }

      if (hasWeather) {
        model.weather.forEach(mapWeatherValue);
      }

      if (hasEvents) {
        model.events.forEach(mapEventValue);
      }

      if (this.showInventoryRollup) {
        model.dailyMetrics.forEach(mapDailyMetricsValue());
        model.dailyMetricsStly.forEach(mapDailyMetricsValue(true));

        var productList = this.get('filteredProducts');
        model.dailyMetricsPerformance.filter(function (a) {
          return productList.findBy('id', Ember.get(a, 'groupByValue'));
        }).forEach(mapDailyMetricsPerformanceValue());
        model.dailyMetricsPerformanceStly.filter(function (a) {
          return productList.findBy('id', Ember.get(a, 'groupByValue'));
        }).forEach(mapDailyMetricsPerformanceValue(true));
      } else {
        // Munge revenue data
        model.performance.forEach(mapValue());
        model.performanceSTLY.forEach(mapValue(true));
        if (model.adrForecast) {
          model.adrForecast.forEach(mapForecastValue);
        }
      }

      // Finalize data

      // --> PMS Counts
      var soldVals = {};
      var soldDeltaVals = {};
      var arrivalVals = {};
      var arrivalDeltaVals = {};
      var inhouseVals = {};
      var inhouseDeltaVals = {};
      var departureVals = {};
      var departureDeltaVals = {};

      // --> Revenue
      var occupancyVals = {};
      var occupancyDeltaVals = {};
      var forecastVals = {};
      var adrVals = {};
      var adrDeltaVals = {};
      var adrForecastVals = {};
      var revparVals = {};
      var revparDeltaVals = {};
      var revenueVals = {};
      var revenueDeltaVals = {};

      // Adjusted KPIs
      var adjOccupancyVals = {};
      var adjOccupancyDeltaVals = {};
      var adjAdrVals = {};
      var adjAdrDeltaVals = {};
      var adjRevparVals = {};
      var adjRevparDeltaVals = {};

      // --> Inventory
      var physicalVals = {};
      var yieldableVals = {};
      var remainingVals = {};
      var blockedVals = {};
      var pickedUpVals = {};

      // --> Sellable Rate
      var publicRateVals = {};

      // --> Details
      var detailsLinks = {};

      // --> Weather
      var weatherVals = {};

      // --> Events
      var eventVals = {};

      var generateDefaultClassNames = function generateDefaultClassNames(date) {
        var names = [];

        // Alternate the weekly backgrounds.  Taking into account that
        // most years have 53 weeks in them.
        if ((0, _moment.default)(date).startOf('week').week() % 2 === 0) {
          names.push('guide');
        }

        if (date.valueOf() < today.valueOf()) {
          names.push('non-actionable');
        }

        return names;
      };

      var generateCellClassNames = function generateCellClassNames(cellValue, date) {

        var classList = generateDefaultClassNames(date);

        if (cellValue > 0) {
          classList.push('text-success');
        } else if (cellValue < 0.00) {
          classList.push('text-danger');
        }

        return classList;
      };

      var generateEventCellClassNames = function generateEventCellClassNames(date) {

        var classList = generateDefaultClassNames(date);

        classList.push('event-cell');

        return classList;
      };

      var truncFloat = function truncFloat(inNumber, inSigDigits) {
        var numPower = Math.pow(10, inSigDigits);
        return parseInt(inNumber * numPower) / numPower;
      };

      Object.keys(mapped).forEach(function (key) {
        var map = mapped[key];

        var date = _moment.default.tz(key, tz);
        var isBeforeToday = date.isBefore(today);
        var currencyFormatOptions = { emptyValue: '&mdash;', split: true, hideSymbol: true };
        var currencyCode = 'USD';

        if (_this.showInventoryRollup) {
          // Sold
          soldVals[key] = {
            value: _accounting.default.formatNumber(map.sold, 0),
            classNames: generateDefaultClassNames(date)
          };
          // Show delta's for the past only
          var soldDelta = isBeforeToday ? map.sold - map.sold_stly : '';
          soldDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(soldDelta, 0) : '&mdash;',
            classNames: generateCellClassNames(soldDelta, date)
          };

          // Arrivals
          arrivalVals[key] = {
            value: _accounting.default.formatNumber(map.arrivals, 0),
            classNames: generateDefaultClassNames(date)
          };
          // Show delta's for the past only
          var arrivalDelta = isBeforeToday ? map.arrivals - map.arrivals_stly : '';
          arrivalDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(arrivalDelta, 0) : '&mdash;',
            classNames: generateCellClassNames(arrivalDelta, date)
          };

          // inhouse
          inhouseVals[key] = {
            value: _accounting.default.formatNumber(map.inhouse, 0),
            classNames: generateDefaultClassNames(date)
          };
          // Show delta's for the past only
          var inhouseDelta = isBeforeToday ? map.inhouse - map.inhouse_stly : '';
          inhouseDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(inhouseDelta, 0) : '&mdash;',
            classNames: generateCellClassNames(inhouseDelta, date)
          };

          // Departure
          departureVals[key] = {
            value: _accounting.default.formatNumber(map.departures, 0),
            classNames: generateDefaultClassNames(date)
          };
          // Show delta's for the past only
          var departureDelta = isBeforeToday ? map.departures - map.departures_stly : '';
          departureDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(departureDelta, 0) : '&mdash;',
            classNames: generateCellClassNames(departureDelta, date)
          };

          // Occupancy - Use the sold count instead of the occupancy counts to calculate the occupancy percent
          var calcOccPct = map.inventory_yieldable !== 0 ? map.sold / map.inventory_yieldable * 100 : 0;
          var calcOccPctStly = map.inventory_yieldable_stly !== 0 ? map.sold_stly / map.inventory_yieldable_stly * 100 : 0;
          occupancyVals[key] = {
            value: _accounting.default.formatNumber(calcOccPct, 1),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          var occDelta = isBeforeToday ? calcOccPct - calcOccPctStly : '';
          occupancyDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(occDelta, 1) : '&mdash;',
            classNames: generateCellClassNames(occDelta, date)
          };

          // ADR
          var calcAdr = map.sold !== 0 ? map.revenue / map.sold : 0;
          var calcAdrStly = map.sold_stly !== 0 ? map.revenue_stly / map.sold_stly : 0;
          adrVals[key] = {
            value: (0, _formatCurrency.default)(calcAdr, currencyCode, currencyFormatOptions),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          var adrDelta = isBeforeToday ? calcAdr - calcAdrStly : '';
          adrDeltaVals[key] = {
            value: (0, _formatCurrency.default)(adrDelta, currencyCode, currencyFormatOptions),
            classNames: generateCellClassNames(adrDelta, date)
          };

          // RevPAR
          var calcRevPAR = map.inventory_yieldable !== 0 ? map.revenue / map.inventory_yieldable : 0;
          var calcRevPARStly = map.inventory_yieldable_stly !== 0 ? map.revenue_stly / map.inventory_yieldable_stly : 0;
          revparVals[key] = {
            value: (0, _formatCurrency.default)(calcRevPAR, currencyCode, currencyFormatOptions),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          var revparDelta = isBeforeToday ? calcRevPAR - calcRevPARStly : '';
          revparDeltaVals[key] = {
            value: (0, _formatCurrency.default)(revparDelta, currencyCode, currencyFormatOptions),
            classNames: generateCellClassNames(revparDelta, date)
          };
        } else {
          // Occupancy
          occupancyVals[key] = {
            value: _accounting.default.formatNumber(map['occupancy'], 1),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          var _occDelta = isBeforeToday ? map['occupancy'] - map['occupancy_stly'] : '';
          occupancyDeltaVals[key] = {
            value: isBeforeToday ? _accounting.default.formatNumber(_occDelta, 1) : '&mdash;',
            classNames: generateCellClassNames(_occDelta, date)
          };
          forecastVals[key] = {
            // plannedEnhancement: multiple forecasting models
            value: map['occupancy_forecast'] && map['occupancy_forecast'] >= 0 ? _accounting.default.formatNumber(map['occupancy_forecast'], 1) : '&mdash;',
            classNames: generateDefaultClassNames(date)
          };

          // ADR
          adrVals[key] = {
            value: (0, _formatCurrency.default)(map['adr'], currencyCode, currencyFormatOptions),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          var _adrDelta = isBeforeToday ? map['adr'] - map['adr_stly'] : '';
          adrDeltaVals[key] = {
            value: (0, _formatCurrency.default)(_adrDelta, currencyCode, currencyFormatOptions),
            classNames: generateCellClassNames(_adrDelta, date)
          };

          adrForecastVals[key] = {
            value: map['adr_forecast'] && map['adr_forecast'] >= 0 ? (0, _formatCurrency.default)(map['adr_forecast'], currencyCode, currencyFormatOptions) : '&mdash;',
            classNames: generateDefaultClassNames(date)
          };

          var revPar = map['revpar'];
          var _revparDelta = isBeforeToday ? map['revpar'] - map['revpar_stly'] : '';
          // Check how we are calculating occupancy PHYSICAL or YIELDABLE
          if (_this.get('occupancySubcontentType') === 'PHYSICAL' && isProperty) {
            // Use Revenue/Physical to calculate revPAR when Occupancy is calculated by inventory.  Note: Truncating to 2 decimal places to match backend
            revPar = map.inventory_physical !== 0 ? truncFloat(map['revenue'] / map.inventory_physical, 2) : 0;
            var revParStly = map.inventory_physical_stly !== 0 ? truncFloat(map['revenue_stly'] / map.inventory_physical_stly, 2) : 0;
            _revparDelta = isBeforeToday ? revPar - revParStly : '';
          }

          // RevPAR
          revparVals[key] = {
            value: (0, _formatCurrency.default)(revPar, currencyCode, currencyFormatOptions),
            classNames: generateDefaultClassNames(date)
          };

          // Show delta's for the past only
          revparDeltaVals[key] = {
            value: (0, _formatCurrency.default)(_revparDelta, currencyCode, currencyFormatOptions),
            classNames: generateCellClassNames(_revparDelta, date)
          };
        }

        // Revenue
        var revenueVal = map['revenue'];
        revenueVals[key] = {
          value: (0, _formatCurrency.default)(revenueVal, currencyCode, currencyFormatOptions),
          classNames: generateDefaultClassNames(date)
        };

        // Show delta's for the past only
        var revenueDelta = isBeforeToday ? map['revenue'] - map['revenue_stly'] : '';
        revenueDeltaVals[key] = {
          value: (0, _formatCurrency.default)(revenueDelta, currencyCode, currencyFormatOptions),
          classNames: generateCellClassNames(revenueDelta, date)
        };

        if (_this.showInfoStationRows && !_this.showInventoryRollup) {
          var esRoomCount = map['ES_ROOM_AVAIL'];
          var esRoomCountLY = map['ES_ROOM_AVAIL_LY'];
          var esSoldCount = map['ES_SOLD'];
          var esSoldCountLY = map['ES_SOLD_LY'];
          var esRevenue = map['ES_REVENUE'];
          var esRevenueLY = map['ES_REVENUE_LY'];

          // Occupancy
          var adjOccupancy = esRoomCount && esSoldCount ? esSoldCount * 1.0 / esRoomCount : null;
          adjOccupancyVals[key] = {
            value: esRoomCount && esSoldCount ? _accounting.default.formatNumber(adjOccupancy * 100, 1) : '&mdash;',
            classNames: generateDefaultClassNames(date)
          };

          var adjOccupancySTLY = esRoomCountLY && esSoldCountLY ? esSoldCountLY * 1.0 / esRoomCountLY : null;
          var adjOccupancyDelta = null;

          if (adjOccupancySTLY !== null && adjOccupancy !== null) {
            adjOccupancyDelta = adjOccupancy - adjOccupancySTLY;
          }

          adjOccupancyDeltaVals[key] = {
            value: adjOccupancyDelta !== null ? _accounting.default.formatNumber(adjOccupancyDelta * 100, 1) : '&mdash;',
            classNames: generateCellClassNames(adjOccupancyDelta, date)
          };

          // ADR
          var adr = esSoldCount && esRevenue ? esRevenue * 1.0 / esSoldCount : null;
          adjAdrVals[key] = {
            value: adr !== null ? (0, _formatCurrency.default)(adr, currencyCode, currencyFormatOptions) : '&mdash;',
            classNames: generateDefaultClassNames(date)
          };

          var adrLY = esSoldCountLY && esRevenueLY ? esRevenueLY * 1.0 / esSoldCountLY : null;
          var adjAdrDelta = adr && adrLY ? adr - adrLY : null;
          adjAdrDeltaVals[key] = {
            value: adjAdrDelta !== null ? (0, _formatCurrency.default)(adjAdrDelta, currencyCode, currencyFormatOptions) : '&mdash;',
            classNames: generateCellClassNames(adjAdrDelta, date)
          };

          // RevPAR
          var _revPar = esRoomCount && esRevenue ? esRevenue * 1.0 / esRoomCount : null;
          adjRevparVals[key] = {
            value: _revPar !== null ? (0, _formatCurrency.default)(_revPar, currencyCode, currencyFormatOptions) : '&mdash;',
            classNames: generateDefaultClassNames(date)
          };

          var revParLY = esRoomCountLY && esRevenueLY ? esRevenueLY * 1.0 / esRoomCountLY : null;
          var revParDelta = _revPar && revParLY ? _revPar - revParLY : null;
          adjRevparDeltaVals[key] = {
            value: revParDelta !== null ? (0, _formatCurrency.default)(revParDelta, currencyCode, currencyFormatOptions) : '&mdash;',
            classNames: generateCellClassNames(revParDelta, date)
          };
        }

        if (isProperty) {

          // --> Inventory

          // Physical
          physicalVals[key] = {
            value: _accounting.default.formatNumber(map.inventory_physical, 0),
            classNames: generateDefaultClassNames(date)
          };

          // Yieldable
          if (!map.hasOwnProperty('inventory_yieldable')) {
            map['inventory_yieldable'] = (parseInt(map.inventory_physical) || 0) + (parseInt(map.inventory_oversell) || 0) - (parseInt(map.inventory_do_not_sell) || 0) - (parseInt(map.inventory_out_of_order) || 0);
          }

          yieldableVals[key] = {
            value: _accounting.default.formatNumber(map.inventory_yieldable, 0),
            classNames: generateDefaultClassNames(date)
          };

          // Remaining
          if (!map.hasOwnProperty('inventory_remaining')) {
            map['inventory_remaining'] = map.inventory_yieldable - (parseInt(map.inventory_consumed) || 0);
          }
          remainingVals[key] = {
            value: _accounting.default.formatNumber(map.inventory_remaining, 0),
            classNames: generateDefaultClassNames(date)
          };

          if (_this.showGroupBlockPickups && !_this.showInventoryRollup) {

            // Group blocked
            blockedVals[key] = {
              value: _accounting.default.formatNumber(map.inventory_blocked, 0),
              classNames: generateDefaultClassNames(date)
            };

            // Group picked up
            pickedUpVals[key] = {
              value: _accounting.default.formatNumber(map.inventory_picked_up, 0),
              classNames: generateDefaultClassNames(date)
            };
          }

          // --> Sellable Rate
          if (_this.showRates && !_this.showInventoryRollup) {

            // Rate
            publicRateVals[key] = {
              value: (0, _formatCurrency.default)(map.sell_rate_public, currencyCode, currencyFormatOptions),
              classNames: generateDefaultClassNames(date)
            };

            // --> Competitors
            (0, _lodash.values)(seenCompetitors).forEach(function (competitor) {
              competitor.values[key] = {
                value: (0, _formatCurrency.default)(map[competitor.id], currencyCode, currencyFormatOptions),
                classNames: generateDefaultClassNames(date)
              };
            });
          }

          // --> Details
          var compiledLink = (0, _lodash.template)('<a href=\'<%= link.href %>\' title=\'<%= link.title %>\' target=\'_blank\'><i class=\'fa fa-external-link\'></i></a>', { 'variable': 'link' });

          var formatted = date.format('YYYY-MM-DD');
          var href = _this.linkFor('customer.performance.pricing', { date: formatted, timespan: 1 });

          detailsLinks[key] = {
            value: compiledLink({ title: formatted, href: href }),
            classNames: generateDefaultClassNames(date)
          };
        }

        if (hasWeather) {
          var compiledWeather = (0, _lodash.template)('<i style="font-size:1.5em;" class="fa\n            <% if (weather.type === \'R\' ) { %> fa-tint <% } %>\n            <% if (weather.type === \'S\' ) { %> fa-sun-o <% } %>\n            <% if (weather.type === \'C\' ) { %> fa-cloud <% } %>\n            " title="Hi: <%=weather.high%> | Lo: <%=weather.low%>"></i>', { 'variable': 'weather' });

          weatherVals[key] = {
            value: map['weather'] ? compiledWeather({
              type: map['weather'],
              high: map['weatherHigh'],
              low: map['weatherLow']
            }) : '&nbsp;',
            classNames: generateDefaultClassNames(date)
          };
        }

        if (hasEvents) {
          var compiledEvent = (0, _lodash.template)('<i style=\'font-size:1.5em;cursor:pointer;\' class="fa\n            <% if (event.type === \'SPECIAL_EVENT\' ) { %> fa-star <% } %>\n            <% if (event.type === \'FAMILY\' ) { %> fa-child <% } %>\n            <% if (event.type === \'FESTIVAL\' ) { %> fa-users <% } %>\n            <% if (event.type === \'MUSIC\' ) { %> fa-music <% } %>\n            <% if (event.type === \'SPORT\' ) { %> fa-soccer-ball-o <% } %>\n            <% if (event.type === \'FUNDRAISER\' ) { %> fa-money <% } %>\n            <% if (event.type === \'FILM\' ) { %> fa-film <% } %>\n            " ></i>', { 'variable': 'event' });

          eventVals[key] = {
            value: map['events'] ? compiledEvent({
              type: map['events'][0].get('campaignType'),
              text: map['eventText']
            }) : '&nbsp;',
            actionable: true,
            items: map['events'],
            classNames: generateEventCellClassNames(date)
          };
        }

        return map;
      });

      var base = {};

      // If show PMS rows
      if (this.showInventoryRollup) {
        Ember.merge(base, {
          soldVals: soldVals,
          soldDeltaVals: soldDeltaVals,
          arrivalVals: arrivalVals,
          arrivalDeltaVals: arrivalDeltaVals,
          inhouseVals: inhouseVals,
          inhouseDeltaVals: inhouseDeltaVals,
          departureVals: departureVals,
          departureDeltaVals: departureDeltaVals
        });
      }

      // Add main rows
      Ember.merge(base, {
        occupancyVals: occupancyVals,
        occupancyDeltaVals: occupancyDeltaVals,
        forecastVals: forecastVals,
        adrVals: adrVals,
        adrDeltaVals: adrDeltaVals,
        adrForecastVals: adrForecastVals,
        revparVals: revparVals,
        revparDeltaVals: revparDeltaVals,
        revenueVals: revenueVals,
        revenueDeltaVals: revenueDeltaVals
      });

      if (this.showInfoStationRows && !this.showInventoryRollup) {
        Ember.merge(base, {
          adjOccupancyVals: adjOccupancyVals,
          adjOccupancyDeltaVals: adjOccupancyDeltaVals,
          adjAdrVals: adjAdrVals,
          adjAdrDeltaVals: adjAdrDeltaVals,
          adjRevparVals: adjRevparVals,
          adjRevparDeltaVals: adjRevparDeltaVals
        });
      }

      if (isProperty) {
        Ember.merge(base, {
          physicalVals: physicalVals,
          yieldableVals: yieldableVals,
          remainingVals: remainingVals,
          blockedVals: blockedVals,
          pickedUpVals: pickedUpVals,
          publicRateVals: publicRateVals,
          detailsLinks: detailsLinks,
          seenCompetitors: (0, _lodash.values)(seenCompetitors)
        });
      }

      if (hasWeather) {
        Ember.merge(base, {
          weatherVals: weatherVals
        });
      }

      if (hasEvents) {
        Ember.merge(base, {
          eventVals: eventVals
        });
      }

      return base;
    },


    _processData: Ember.observer('model', function () {
      var _this2 = this;

      var isProperty = this.get('isProperty');
      var hasWeather = this.get('hasWeather');
      var hasEvents = this.get('hasEvents');
      var t = function t(partial, obj) {
        return _this2.i18n.t('customer.performance.matrix' + partial, obj);
      };
      var model = this.get('model');
      var mapped = this._mapValues(model);
      var columns = this._columnsForRange(this.get('beginDate'), this.get('endDate'), model.selectedDate, 7);

      var revenueRows = [];

      // if pms rows
      if (this.showInventoryRollup) {
        revenueRows = revenueRows.concat([{
          id: 'soldVals',
          label: t('.revenue.sold'),
          values: mapped.soldVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'soldDeltaVals',
          label: t('.revenue.sold_change'),
          values: mapped.soldDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'arrivalVals',
          label: t('.revenue.arrival'),
          values: mapped.arrivalVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'arrivalDeltaVals',
          label: t('.revenue.arrival_change'),
          values: mapped.arrivalDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'inhouseVals',
          label: t('.revenue.inhouse'),
          values: mapped.inhouseVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'inhouseDeltaVals',
          label: t('.revenue.inhouse_change'),
          values: mapped.inhouseDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'departureVals',
          label: t('.revenue.departure'),
          values: mapped.departureVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'departureDeltaVals',
          label: t('.revenue.departure_change'),
          values: mapped.departureDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }]);
      }

      revenueRows = revenueRows.concat([{
        id: 'occupancyVals',
        label: t('.revenue.occupancy'),
        values: mapped.occupancyVals,
        labelClassNames: 'matrix-label__sub'
      }, {
        id: 'occupancyDeltaVals',
        label: t('.revenue.occupancy_change'),
        values: mapped.occupancyDeltaVals,
        classNames: 'text-muted',
        labelClassNames: 'matrix-label__sub'
      }]);

      if (this.showOccForecast) {
        revenueRows.push({
          id: 'forecastVals',
          label: t('.revenue.forecast'),
          values: mapped.forecastVals,
          labelClassNames: 'matrix-label__sub'
        });
      }

      revenueRows.push({
        id: 'adrVals',
        label: t('.revenue.adr'),
        values: mapped.adrVals,
        labelClassNames: 'matrix-label__sub'
      });
      revenueRows.push({
        id: 'adrDeltaVals',
        label: t('.revenue.adr_change'),
        values: mapped.adrDeltaVals,
        classNames: 'text-muted',
        labelClassNames: 'matrix-label__sub'
      });

      if (this.get('showAdrForecast')) {
        revenueRows.push({
          id: 'adrForecastVals',
          label: t('.revenue.adr_forecast'),
          values: mapped.adrForecastVals,
          labelClassNames: 'matrix-label__sub'
        });
      }

      revenueRows = revenueRows.concat([{
        id: 'revparVals',
        label: t('.revenue.revpar'),
        values: mapped.revparVals,
        labelClassNames: 'matrix-label__sub'
      }, {
        id: 'revparDeltaVals',
        label: t('.revenue.revpar_change'),
        values: mapped.revparDeltaVals,
        classNames: 'text-muted',
        labelClassNames: 'matrix-label__sub'
      }, {
        id: 'revenueVals',
        label: t('.revenue.revenue'),
        values: mapped.revenueVals,
        labelClassNames: 'matrix-label__sub'
      }, {
        id: 'revenueDeltaVals',
        label: t('.revenue.revenue_change'),
        values: mapped.revenueDeltaVals,
        classNames: 'text-muted',
        labelClassNames: 'matrix-label__sub'
      }]);

      var groups = [{
        id: 'header',
        label: '',
        rows: [{ id: 'headerVals', label: '', values: [] }],
        classNames: 'matrix-label__header-bar'
      }];

      if (hasWeather || hasEvents) {
        var rows = [];
        if (hasWeather) {
          rows.push({
            id: 'weatherVals',
            label: t('.weather.title'),
            values: mapped.weatherVals,
            labelClassNames: 'matrix-label__sub'
          });
        }
        if (hasEvents) {
          rows.push({
            id: 'eventVals',
            label: t('.events.title'),
            values: mapped.eventVals,
            labelClassNames: 'matrix-label__sub'
          });
        }
        groups = groups.concat([{
          id: 'weather',
          label: '',
          rows: rows,
          classNames: 'matrix-label__header'
        }, {
          id: 'revenue',
          label: t('.revenue.title'),
          toggleable: true,
          rows: Ember.A(),
          classNames: 'matrix-label__header'
        }]);
      } else {
        groups = groups.concat([{
          id: 'revenue',
          label: t('.revenue.title'),
          toggleable: true,
          rows: Ember.A(),
          classNames: 'matrix-label__header'
        }]);
      }

      var adjRevenueRows = [];
      if (this.showInfoStationRows && !this.showInventoryRollup) {
        adjRevenueRows = [{
          id: 'adjOccupancyVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.occupancy') }),
          values: mapped.adjOccupancyVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'adjOccupancyDeltaVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.occupancy_change') }),
          values: mapped.adjOccupancyDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'adjAdrVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.adr') }),
          values: mapped.adjAdrVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'adjAdrDeltaVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.adr_change') }),
          values: mapped.adjAdrDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'adjRevparVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.revpar') }),
          values: mapped.adjRevparVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'adjRevparDeltaVals',
          label: t('.revenue.adj_prefix', { suffix: t('.revenue.revpar_change') }),
          values: mapped.adjRevparDeltaVals,
          classNames: 'text-muted',
          labelClassNames: 'matrix-label__sub'
        }];

        groups = groups.concat([{
          id: 'adjustedKpi',
          label: t('.revenue.adjusted_title'),
          toggleable: true,
          rows: Ember.A(),
          classNames: 'matrix-label__header'
        }]);
      }

      var inventoryRows = [];
      var rateRows = [];

      if (isProperty) {

        inventoryRows = [{
          id: 'physicalVals',
          label: t('.inventory.physical'),
          values: mapped.physicalVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'yieldableVals',
          label: t('.inventory.yieldable'),
          values: mapped.yieldableVals,
          labelClassNames: 'matrix-label__sub'
        }, {
          id: 'remainingVals',
          label: t('.inventory.remaining'),
          values: mapped.remainingVals,
          labelClassNames: 'matrix-label__sub'
        }];

        if (this.showGroupBlockPickups) {
          inventoryRows.push({
            id: 'blockedVals',
            label: t('.inventory.group_block'),
            values: mapped.blockedVals,
            labelClassNames: 'matrix-label__sub'
          });
          inventoryRows.push({
            id: 'pickedUpVals',
            label: t('.inventory.group_pickup'),
            values: mapped.pickedUpVals,
            labelClassNames: 'matrix-label__sub'
          });
        }

        if (this.showRates) {
          rateRows = [{
            id: 'publicRateVals',
            label: t('.rates.current_price'),
            values: mapped.publicRateVals,
            labelClassNames: 'matrix-label__sub'
          }].concat(mapped.seenCompetitors);
        }

        groups.push({
          id: 'inventory',
          toggleable: true,
          label: t('.inventory.title'),
          rows: Ember.A(),
          classNames: 'matrix-label__header'
        });

        if (this.showRates) {
          groups.push({
            id: 'rates',
            toggleable: true,
            label: t('.rates.title'),
            rows: Ember.A(),
            classNames: 'matrix-label__header'
          });
        }
      }

      groups.findBy('id', 'revenue').rows.addObjects(revenueRows.splice(0, 10));

      Ember.run.later(function () {
        Ember.run.schedule('afterRender', function () {
          if (_this2.showInfoStationRows && !_this2.showInventoryRollup) {
            groups.findBy('id', 'adjustedKpi').rows.addObjects(adjRevenueRows.splice(0, 100));
          }
          groups.findBy('id', 'revenue').rows.addObjects(revenueRows.splice(0, 100));
          if (isProperty) {
            groups.findBy('id', 'inventory').rows.addObjects(inventoryRows.splice(0, 100));
            if (_this2.showRates) {
              groups.findBy('id', 'rates').rows.addObjects(rateRows.splice(0, 100));
            }
          }
          _this2.table.trigger('tableInvalidate');
        });
      }, 200);

      // Set up initial table
      this.set('performanceTableData', {
        groups: groups,
        columns: columns
      });

      if (this.get('isNonMobile')) {
        Ember.run.later(function () {
          _this2.send('loadPrevious', 6, true);
        }, 300);

        Ember.run.later(function () {
          _this2.send('loadNext', 6, true);
        }, 600);
      }
    }),

    _hijackModel: function _hijackModel(data, beginDate, timespan) {
      var unshift = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var firstRun = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      var mapped = this._mapValues(data);
      var columns = this.get('performanceTableData.columns');

      if (!firstRun) {
        var newCols = this._columnsForRange(beginDate, beginDate.clone().add(timespan, 'days'));

        if (unshift) {
          columns.unshiftObjects(newCols);
        } else {
          columns.pushObjects(newCols);
        }
      } else {
        var tz = this.tz.get('customer');
        var date = (0, _moment.default)(beginDate).tz(tz);
        var compareBegin = date.clone().subtract(1, 'days');
        var compareEnd = date.clone().add(timespan + 2, 'days');

        columns.forEach(function (column) {
          var keyDate = (0, _moment.default)(column.key).tz(tz);
          if (keyDate.isBetween(compareBegin, compareEnd)) {
            column.set('isPending', false);
          }
        });
      }

      var tableData = this.get('performanceTableData');
      var groupKeys = [];
      if (this.get('hasWeather') || this.get('hasEvents')) {
        groupKeys = ['weather', 'revenue', 'inventory'];
      } else {
        groupKeys = ['revenue', 'inventory'];
      }
      if (this.showInfoStationRows && !this.showInventoryRollup) {
        groupKeys.push('adjustedKpi');
      }
      if (this.showRates && !this.showInventoryRollup) {
        groupKeys.push('rates');
      }
      groupKeys.forEach(function (id) {
        var group = tableData.groups.find(function (g) {
          return g.id === id;
        });
        var rows = group ? group.rows : [];
        rows.forEach(function (row) {
          // XXX Need to map to competitor based on id
          if (row.id.match(/^sellRateCompetitor/)) {
            Ember.merge(row.values, (mapped.seenCompetitors.find(function (c) {
              return c.id === row.id;
            }) || {}).values);
          } else {
            // We get back one extra day, which causes issues with overlap on the merge, so we need to remove
            if (Object.keys(mapped[row.id]).length > timespan + 1) {
              // Delete the last one as it overlaps with first on in the row.values list
              delete mapped[row.id][Object.keys(mapped[row.id])[timespan + 1]];
            }
            Ember.merge(row.values, mapped[row.id]);
          }
        });
      });

      this.set('scrollingPaused', false);
      this.table.trigger('tableInvalidate');
      Ember.run.schedule('afterRender', function () {
        Ember.$('#main-content').trigger('scroll');
      });
    },

    actions: {
      setViewBy: function setViewBy(viewBy) {
        this.set('viewBy', viewBy);
      },
      popCell: function popCell(context, columnKey) {
        var tz = this.tz.get('customer');
        var clearPopout = function clearPopout() {
          var popout = Ember.$('.tooltip');
          if (popout) {
            popout.remove();
            var _cell = Ember.$('.popped');
            _cell.removeClass('popped');
          }
        };

        var cell = Ember.$(context.element);
        if (Ember.$(cell).hasClass('popped')) {
          clearPopout();
        } else {
          // Remove any current popout window if it exists for another cell
          clearPopout();
          var events = context.model.items;
          if (events && events.length > 0) {
            var date = (0, _moment.default)(columnKey).tz(tz).startOf('day');

            var owner = Ember.getOwner(this);

            var ComponentFactory = owner.factoryFor('component:customer/performance/matrix/event-popout');

            var component = ComponentFactory.create({
              date: date,
              events: events,
              onHide: function onHide() {
                clearPopout();
              }
            });

            context.renderer.appendTo(component, document.querySelector('#' + context.elementId));
          }
        }
      }
    }
  });
});