define('ux-pcc/components/reservation-list', ['exports', 'ux-components/components/reservation-list'], function (exports, _reservationList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservationList.default;
    }
  });
});