define('ux-pcc/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),

    _registerPushNotifications: function _registerPushNotifications() {
      var pushChannel = 'PUSH_NOTIFICATION';
      var ApnBindingType = 'APN';
      var FcmBindingType = 'FCM';
      var platform = window.localStorage.getItem('devicePlatform');
      var deviceToken = window.localStorage.getItem('deviceToken');
      if (platform != null && deviceToken != null) {
        var bindingType = Ember.isEqual(platform, 'iOS') ? ApnBindingType : FcmBindingType;
        this.ajax.request('/rest/v1/pushNotifications/register', {
          data: {
            channelCode: pushChannel,
            deviceToken: deviceToken,
            bindingType: bindingType
          }
        });
      }
    },


    /**
     * Read the redirectTarget from localStorage and perform a transition
     * if it is present.  If there is a value, it would have been set by
     * authenticated-route-mixin when authentication has been
     * performed by an external service, such as Okta.
     *
     * If no localStorage entry is found, it continues with the
     * normal ember-simple-auth flow of logic.
     */
    handleAuthentication: function handleAuthentication() /*routeAfterAuthentication*/{

      this._registerPushNotifications();

      var redirectTarget = localStorage.getItem('redirectTarget');

      if (!Ember.isEmpty(redirectTarget) && redirectTarget !== 'undefined' && redirectTarget !== 'null') {
        console.log('Stored redirect target found \'' + redirectTarget + '\'');
        localStorage.removeItem('redirectTarget');

        // If the target is a deep-link, we can't transitionTo it
        if (redirectTarget.indexOf('deep-link') >= 0) {
          console.log('Redirecting into deep link ' + redirectTarget);
          window.location = redirectTarget;
        } else {
          console.log('Transitioning to ' + redirectTarget);
          this.router.transitionTo(redirectTarget);
        }
      } else {
        console.log('Performing normal auth flow');
        this._super.apply(this, arguments);
      }
    },
    requireAuthentication: function requireAuthentication(transition) {

      if (!this.session.isAuthenticated && this.config.authUrl) {

        if (transition) {
          console.log('Storing redirect target \'' + transition.intent.url + '\'');
          localStorage.setItem('redirectTarget', transition.intent.url);
        }

        console.log('auth URL was configured.  Redirecting to ' + this.config.authUrl);
        window.location.replace(this.config.authUrl);
      }

      this._super.apply(this, arguments);
    }
  });
});