define('ux-pcc/models/agreement', ['exports', 'ux-data/models/agreement'], function (exports, _agreement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _agreement.default;
    }
  });
});