define('ux-pcc/validators/array-length', ['exports', 'ux-components/validators/array-length'], function (exports, _arrayLength) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _arrayLength.default;
    }
  });
});