define('ux-pcc/routes/customer/performance/rates', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      beginDate: {
        refreshModel: true
      }
    },

    ajax: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var tz = this.tz.get("customer");
      var date = (params.beginDate ? _moment.default.tz(params.beginDate, tz) : _moment.default.tz(tz)).startOf('day');

      var timespan = this.get('deviceType.mobile') ? 1 : 14;
      var isProperty = this.modelFor('customer').get('customerType') === 'CUSTOMER';

      var customer = this.modelFor('customer');

      return this.get('store').query('customer', {
        folderid: customer.get('id'),
        folderyn: 'y',
        customertype: 'MANUAL_COMPETITORS'
      }).then(function (results) {
        var folderId = results.get('firstObject.id');

        var propertyAnalytics = _this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: date.format(),
            enddate: date.clone().add(timespan - 1, 'days').endOf('day').format(),
            frequency: 'DAY',
            customerid: customer.get('id'),
            contenttype: 'SELL_RATE',
            analytictype: 'AMOUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: isProperty ? 'DETAIL' : 'ROLLUP',
            fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

          return analyticEntries;
        });

        var competitorsAnalytics = _this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: date.format(),
            enddate: date.clone().add(timespan - 1, 'days').endOf('day').format(),
            frequency: 'DAY',
            customerid: customer.get('id'),
            contenttype: 'UNPUBLISHED_RATE',
            analytictype: 'AMOUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: isProperty ? 'DETAIL' : 'ROLLUP',
            fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
          }
        }).then(function (_ref2) {
          var _ref2$analyticEntries = _ref2.analyticEntries,
              analyticEntries = _ref2$analyticEntries === undefined ? [] : _ref2$analyticEntries;

          return analyticEntries.sortBy('description');
        });

        var competitorList = [];

        if (folderId) {
          var queryParams = {
            folderid: folderId,
            customertype: 'MANUAL_COMPETITOR'
          };

          competitorList = _this.get('store').query('customer', queryParams).then(function (data) {
            if (data && data.get('length') > 1) {
              data = data.sortBy('name');
            }
            return data;
          });
        }

        var promises = {
          propertyAnalytics: propertyAnalytics,
          competitorsAnalytics: competitorsAnalytics,
          manualCompetitors: competitorList
        };

        return Ember.RSVP.hash(promises, "CustomerPerformanceRates: Gather required info").then(function (_ref3) {
          var propertyAnalytics = _ref3.propertyAnalytics,
              competitorsAnalytics = _ref3.competitorsAnalytics,
              manualCompetitors = _ref3.manualCompetitors;

          return {
            analytics: propertyAnalytics.concat(competitorsAnalytics),
            manualCompetitors: manualCompetitors.sortBy('name'),
            timespan: timespan
          };
        }).catch(function () {
          return {};
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('timespan', model.timespan);
      this._super(controller, model);
    }
  });
});