define('ux-pcc/currencies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Currencies = Ember.Object.extend({

    current: undefined,

    availableCurrencies: Ember.computed('currencies', function () {
      return ['USD', 'GBP', 'EUR', 'CAD', 'INR', 'MXN'];
    }),

    currencyFor: function currencyFor(code) {
      var defaultFormat = {
        decimal: '.',
        thousand: ',',
        precision: 2,
        format: '%s%v'
      };

      switch (code) {
        case 'USD':
          return {
            id: 'USD',
            title: 'US Dollar (USD)',
            format: Ember.merge({ symbol: '$' }, defaultFormat)
          };
        case 'GBP':
          return {
            id: 'GBP',
            title: 'British Pound (GBP)',
            format: Ember.merge({ symbol: '£' }, defaultFormat)
          };
        case 'EUR':
          return {
            id: 'EUR',
            title: 'Euro (EUR)',
            format: Ember.merge({ symbol: '€' }, defaultFormat)
          };
        case 'CAD':
          return {
            id: 'CAD',
            title: 'Canadian Dollar (CAD)',
            format: Ember.merge({ symbol: '$' }, defaultFormat)
          };
        case 'INR':
          return {
            id: 'INR',
            title: 'Indian Rupee (INR)',
            format: Ember.merge({ symbol: '₹' }, defaultFormat)
          };
        case 'MXN':
          return {
            id: 'MXN',
            title: 'Mexican Peso (MXN)',
            format: Ember.merge({ symbol: '$' }, defaultFormat)
          };
      }
    }
  }).create();

  Currencies.set('current', Currencies.currencyFor('USD'));

  exports.default = Currencies;
});