define('ux-pcc/routes/rule-rates/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({

    dialog: Ember.inject.service(),

    model: function model() /* params */{
      var customer = this.modelFor('customer');
      var ruleRates = this.modelFor('rule-rates');
      var settings = customer.get('settings');
      var ruleSetting = settings.findBy('settingCode', 'MASTER_PRICING_RULE');
      var masterRuleId = '';

      if (ruleSetting && ruleSetting.get('settingValue')) {
        masterRuleId = ruleSetting.get('settingValue');
      }
      var ceilingSetting = settings.findBy('settingCode', 'DISPLAY_CEILING');
      var isDisplayCeiling = ceilingSetting && (ceilingSetting.get('settingValue').toUpperCase() === 'Y' || ceilingSetting.get('settingValue').toUpperCase() === 'YES' || ceilingSetting.get('settingValue').toUpperCase() === 'TRUE');

      var requests = [this.store.query('rule', { customerID: customer.get('id'), include: 'children' })];

      return Ember.RSVP.all(requests, 'RuleRatesRoute: Resolving rules structure').then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            rules = _ref2[0];

        return Ember.Object.create({
          prices: ruleRates.prices,
          rules: rules.rejectBy('id', masterRuleId).filter(function (rule) {
            if (isDisplayCeiling) {
              return rule.get('ruleType') === 'BASED_ON_ADJUSTMENT' || rule.get('ruleType') === 'RESTRICTION' || rule.get('ruleType') === 'CEILING';
            } else {
              return rule.get('ruleType') === 'BASED_ON_ADJUSTMENT' || rule.get('ruleType') === 'RESTRICTION';
            }
          })
        });
      });
    },


    actions: {
      confirmDelete: function confirmDelete(rule) {
        var _this = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('rules.index.discard_rule'),
          messageText: this.i18n.t('rules.index.confirm_delete'),
          confirmText: this.i18n.t('rules.index.continue'),
          cancelText: this.i18n.t('rules.index.cancel'),
          onConfirm: function onConfirm() {

            if (rule) {
              rule.destroyRecord().then(function () /* results */{
                _this.refresh();
                _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.i18n.t('rules.index.success_delete_message', {
                  ruleName: rule.get('shortDescription')
                }), 'success');
              }).catch(function (error) {
                _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
              });
            } else {
              _this.set('ruleToDelete', null);
            }
          },
          onCancel: function onCancel() {
            _this.dialog.close();
          }
        });
      },
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});