define('ux-pcc/components/inputs/model-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [],
    modelType: null,
    displayField: 'name',
    orderByField: null,
    disabled: false,
    allowEmptySearch: false,
    alternateFetchCriteria: null,

    _hasFetchedObjects: false,

    store: Ember.inject.service(),

    ddau: false,

    onChange: function onChange() {},

    init: function init() {
      var _this = this;

      this._fetchObject();

      var objectBloodhound = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace
      });

      this.set('objectBloodhound', objectBloodhound);

      if (this.get('allowEmptySearch')) {
        this._fetchObjects();
      }

      this.tokenizedObjectsObserver();

      Ember.run.schedule('afterRender', function () {
        var element = Ember.$(_this.get('element')).find("input.search-ahead__search-input.tt-input");
        Ember.$(element).typeahead('val', _this.get('displayValue'));
      });

      return this._super.apply(this, arguments);
    },


    typeaheadOptions: Ember.computed('allowEmptySearch', function () {
      var options = {
        highlight: true,
        autoselect: true
      };

      if (this.get('allowEmptySearch')) {
        options.minLength = 0;
      }

      return options;
    }),

    typeaheadData: Ember.computed('valueField', 'objectBloodhound', 'allowEmptySearch', 'i18n.locale', 'objects.[]', function () {
      var objectBloodhound = this.get('objectBloodhound');

      var dataObject = {
        displayKey: 'value',
        source: objectBloodhound,
        templates: {
          empty: '<div class="item-section__search-no-results">' + this.get('i18n').t('pcc-components.inputs.model_search.no_entries') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class="item-section">' + model.value + '</div>';
          }
        }
      };

      if (this.get('allowEmptySearch')) {
        dataObject.limit = (this.get('objects.length') || 0) + 1;
        dataObject.source = function (q, sync) {
          if (q === '') {
            sync(objectBloodhound.all());
          } else {
            objectBloodhound.search(q, function (suggestions) {
              return sync(suggestions);
            });
          }
        };
      }

      return dataObject;
    }),

    displayValue: Ember.computed('object', function () {
      var object = this.get('object');

      if (object) {
        return this._formatDisplayValue(object);
      }
    }),

    _fetchObject: Ember.observer('objectType', 'value', function () {
      var _this2 = this;

      var promise = void 0;
      if (this.get('value')) {
        if (Ember.typeOf(this.get('objectType')) === 'string') {
          promise = this.get('store').findRecord(this.get('objectType'), this.get('value'));
        } else {
          promise = this.get('objectType').fetch(this.get('value'));
        }

        if (this.get('value') !== this.get('object.id')) {
          promise.then(function (model) {
            _this2.set('object', model);
            _this2.set('selectedObject', model);
            Ember.run.scheduleOnce('afterRender', function () {
              Ember.$("input.search-ahead__search-input.tt-input", _this2.get('element')).typeahead('val', _this2.get('displayValue'));
            });
          });
        }
      }
    }),

    _fetchObjects: function _fetchObjects() {
      var _this3 = this;

      var promise = void 0;

      if (Ember.typeOf(this.get('objectType')) === 'string') {
        promise = this.get('store').query(this.get('objectType'), this.get('alternateFetchCriteria') || { customerID: this.get('customerId') });
      } else {
        promise = this.get('objectType').fetch(this.get('alternateFetchCriteria') || { customerID: this.get('customerId') });
      }

      promise.then(function (results) {
        _this3.set('objects', results);

        if (!_this3.get('isDestroyed')) {
          _this3.set('_hasFetchedObjects', true);
        }
      });
    },


    filteredObjects: Ember.computed('objects.[]', 'reject', 'filter', 'filterField', 'filterFieldValue', function () {
      var _this4 = this;

      var objects = this.get('objects') || [];

      if (this.get('reject')) {
        objects = objects.reject(this.get('reject'));
      }

      if (this.get('filter')) {
        objects = objects.filter(this.get('filter'));
      }

      // allow filtering of a dynamic field (i.e. productTemplate.id = 10a000...)
      if (this.get('filterField') && this.get('filterFieldValue')) {
        objects = objects.filter(function (e) {
          return e.get(_this4.get('filterField')) === _this4.get('filterFieldValue');
        });
      }

      return objects;
    }),

    sortedObjects: Ember.computed('filteredObjects', 'orderByField', function () {
      var objects = this.get('filteredObjects') || [];

      if (this.get('orderByField')) {
        return objects.sortBy(this.get('orderByField'));
      }

      return objects;
    }),

    tokenizedObjects: Ember.computed('sortedObjects', function () {
      var _this5 = this;

      return (this.get('sortedObjects') || []).map(function (obj) {
        var tokens = [];
        tokens = tokens.concat(window.Bloodhound.tokenizers.whitespace(obj.get(_this5.get('displayField'))));

        if (_this5.get('referenceField')) {
          tokens = tokens.concat(window.Bloodhound.tokenizers.whitespace(obj.get(_this5.get('referenceField'))));
        }

        return {
          id: obj.get('id'),
          value: _this5._formatDisplayValue(obj),
          tokens: tokens,
          object: obj
        };
      });
    }),

    tokenizedObjectsObserver: Ember.observer('tokenizedObjects', function () {
      var tokenizedObjects = this.get('tokenizedObjects');

      var objectBloodhound = this.get('objectBloodhound');
      objectBloodhound.clear();
      objectBloodhound.add(tokenizedObjects);
    }),

    _objectTypeChanged: Ember.observer('objectType', function () {
      this.set('_hasFetchedObjects', false);
      this.set('object', null);
      this.set('selectedObject', null);
      if (!this.get('ddau')) {
        this.set('value', '');
      }
      Ember.run.once(this, "_fetchObjects");
    }),

    _objectChanged: Ember.observer('object', function () {
      if (!this.get('ddau')) {
        this.set('value', this.get('object.id'));
      }
    }),

    _formatDisplayValue: function _formatDisplayValue(object) {
      if (this.get('referenceField')) {
        return object.get(this.get('referenceField')) + ' - ' + object.get(this.get('displayField'));
      } else {
        return object.get(this.get('displayField'));
      }
    },


    actions: {
      closed: function closed(element) {
        if (Ember.$(element).typeahead('val') === '') {
          this.set('object', null);
          this.set('selectedObject', null);
          if (!this.get('ddau')) {
            this.set('value', '');
          }
        }
      },
      selected: function selected(item) {
        this.set('object', item.object);
        this.set('selectedObject', item.object);
        this.get('onChange')(item.object);
      },
      opened: function opened() {
        if (!this.get('_hasFetchedObjects')) {
          Ember.run.schedule('afterRender', this, this._fetchObjects);
        }
      }
    }
  });
});