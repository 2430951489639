define('ux-pcc/components/rules-simple/rule-form', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: '',

    store: Ember.inject.service(),
    notifications: Ember.inject.service(),

    onSave: function onSave() {},

    childChangesets: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('childChangesets', []);
    },


    ruleType: Ember.computed.readOnly('baseChangeset.ruleType'),
    ruleConditionPrefix: Ember.computed('ruleType', function () {
      switch (this.ruleType) {
        case 'CANCEL':
          return 'cancel-';
        case 'DEPOSIT':
          return 'deposit-';
        case 'BASED_ON_ADJUSTMENT':
          return 'discount-';
        case 'TAX':
          return 'tax-';
        case 'FEE':
          return 'fee-';
        default:
          return '';
      }
    }),

    isFeeType: Ember.computed.equal('ruleType', 'FEE'),
    isDepositType: Ember.computed.equal('ruleType', 'DEPOSIT'),
    isTaxType: Ember.computed.equal('ruleType', 'TAX'),
    isCancelType: Ember.computed.equal('ruleType', 'CANCEL'),
    isGuaranteeType: Ember.computed.equal('ruleType', 'GUARANTEE'),
    isNotGuaranteeType: Ember.computed.not('isGuaranteeType'),
    isRestrictionType: Ember.computed.equal('ruleType', 'RESTRICTION'),
    isDiscountType: Ember.computed.equal('ruleType', 'BASED_ON_ADJUSTMENT'),

    baseValidations: Ember.computed('i18n.locale', function () {
      return {
        ruleType: (0, _validators.validatePresence)(true),
        shortDescription: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rule_name').toString()
        }),
        beginDate: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_begin_date').toString()
        })
      };
    }),

    variableValidations: Ember.computed('i18n.locale', 'isDiscountType', 'isRestrictionType', function () {

      var validations = {};

      if (!this.isDiscountType && !this.isRestrictionType) {
        validations.externalCode = (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_external_reference').toString()
        });
      } else {
        validations.price = (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rate_code').toString()
        });
      }

      return validations;
    }),

    baseChangeset: Ember.computed('rule', 'baseValidations', function () {
      var validations = this.get('baseValidations');
      return new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    variableChangeset: Ember.computed('rule', 'variableValidations', function () {
      var validations = this.get('variableValidations');
      var changeset = new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);

      changeset.set('children', [].concat(_toConsumableArray(this.get('rule.children'))));
      return changeset;
    }),

    transactionCodeOptions: Ember.computed('customer.id', function () {
      return this.store.query('code', { codeType: 'TRANSACTION_CODE', customerID: this.customer.id }).then(function (codes) {
        return codes.reject(function (code) {
          return code.shortDescription.includes('EXCLUDE:');
        }).sortBy('shortDescription');
      });
    }),

    transactionCodeMatcher: function transactionCodeMatcher(option, searchTerm) {
      var shortDescription = option.shortDescription.toLowerCase();
      var codeValue = option.codeValue.toLowerCase();
      var lowerCaseSearchTerm = searchTerm.toLowerCase();
      return shortDescription.includes(lowerCaseSearchTerm) || codeValue.includes(lowerCaseSearchTerm) ? 1 : -1;
    },


    actions: {
      addCondition: function addCondition() {
        this.variableChangeset.get('children').pushObject({
          ruleMethod: 'RULE',
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          children: []
        });
      },
      removeCondition: function removeCondition(index) {
        this.variableChangeset.get('children').removeAt(index);
      },
      setPrice: function setPrice(price) {
        this.set('variableChangeset.price', price);
      },
      save: function save() {
        var _this = this;

        var baseChangeset = this.get('baseChangeset');
        var variableChangeset = this.get('variableChangeset');

        var childChangesets = this.childChangesets.mapBy('changeset');

        var allChangesets = [baseChangeset, variableChangeset].concat(childChangesets);

        Ember.RSVP.all(allChangesets.map(function (c) {
          return c.validate();
        }), 'validating changesets').then(function () {
          // all validations pass
          var allChangesetsValid = allChangesets.mapBy('isValid').reduce(function (a, b) {
            return a && b;
          }, true);
          if (allChangesetsValid) {
            allChangesets.forEach(function (c) {
              return c.execute();
            });
            _this.onSave();
          }
        }).catch(function () /* e */{
          _this.notifications.error(_this.i18n.t('rule._form.save_invalid'));
        }).finally(function () {});
      },
      updateExternalCode: function updateExternalCode(code) {
        this.set('variableChangeset.externalCode', code.codeValue);
      },
      registerChangeset: function registerChangeset(stepName, changeset) {
        var existingChangeset = this.childChangesets.findBy('step', stepName);
        if (existingChangeset) {
          existingChangeset.changeset = changeset;
        } else {
          this.childChangesets.push({ step: stepName, changeset: changeset });
        }
      }
    }
  });
});