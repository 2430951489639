define('ux-pcc/routes/affiliates/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var parent = this.modelFor('customer');

      return this.store.createRecord('customer', {
        parent: parent,
        customerType: 'AFFILIATE'
      });
    },


    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.get('controller.model');

        model.save().then(function () {
          // Success!
          _this.send('returnOrReplaceWith', 'affiliates', { refresh: true });
          _this.send('flash', "<i class='fa fa-thumbs-up'></i> " + "Successfully created affiliate '" + model.get('name') + "'", 'success');
          _this.controllerFor('affiliates').send('refresh');
        }, function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      close: function close() {
        this.send('returnOrTransitionTo', 'affiliates');
      }
    }
  });
});