define('ux-pcc/components/rules/rule-child', ['exports', 'ember-drag-drop/components/draggable-object'], function (exports, _draggableObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _draggableObject.default.extend({
    tagName: 'tr',

    classNames: ['rule-condition'],

    // ember-drag-drop configuration start
    // overrideClass: 'sortObject',
    dragHandle: '.rule-condition__sort',
    isSortable: true,

    content: Ember.computed.alias('rule'),
    // ember-drag-drop configuration end

    isSelected: Ember.computed('selectedConditions.[]', 'rule', function () {
      return this.get('selectedConditions').includes(this.rule);
    }),

    actions: {
      editRule: function editRule(rule) {
        this.editRule(rule);
      },
      select: function select() {
        this.updateSelection(this.rule, !this.get('isSelected'));
      }
    }
  });
});