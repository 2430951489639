define('ux-pcc/routes/affiliate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('customer', params.id, { include: 'address,contactInformations' });
    },


    actions: {
      submit: function submit() {
        var _this = this;

        var model = this.get('controller.model');
        model.save().then(function () {
          // Success!
          _this.send('returnOrReplaceWith', 'affiliates');
          _this.send('flash', "<i class='fa fa-thumbs-up'></i> " + "Successfully updated affiliate '" + model.get('name') + "'", 'success');
          _this.send('refresh');
        }, function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      close: function close() {
        this.send('returnOrTransitionTo', 'affiliates');
      }
    }
  });
});