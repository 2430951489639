define('ux-pcc/components/widget/donut-graph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['donut-graph', 'row'],
    data: null,
    legend: null,
    legendColumns: 1,

    legendItemClass: Ember.computed('legendColumns', function () {
      if (this.get('legendColumns')) {
        var colSize = 12 / this.get('legendColumns');
        return 'col-xs-' + colSize;
      }

      return "";
    }),

    optionChanged: Ember.observer('data', 'legend', function () {
      this._rebuildChart();
    }),

    _createChart: function _createChart() {
      var data = this.get('data');
      var innerRadius = this.get('isPie') ? '0' : '70%';

      return AmCharts.makeChart('donut-graph-' + this.get('elementId'), {
        hideCredits: true,
        addClassNames: true,
        classNameField: 'pie_color_class',
        type: 'pie',
        innerRadius: innerRadius,
        theme: 'none',
        titleField: 'label',
        valueField: 'value',
        labelsEnabled: false,
        autoMargins: false,
        marginTop: 1,
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        pullOutRadius: 0,
        startDuration: 0,
        outlineAlpha: 1, //TODO: Might need to be a flag
        dataProvider: data,
        balloon: { enabled: false }
      });
    },
    _rebuildChart: function _rebuildChart() {
      var chart = this.get("_chart");
      if (!chart) {
        return;
      }

      chart.dataProvider = this.get('data');

      chart.validateData();
    },
    didInsertElement: function didInsertElement() {
      Ember.$(this.get('element')).find('> .chart-div').attr('id', "donut-graph-" + this.get('elementId'));
      this.set("_chart", this._createChart());
    }
  });
});