define('ux-pcc/adapters/custom-host', ['exports', 'ux-data/adapters/custom-host'], function (exports, _customHost) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _customHost.default;
    }
  });
});