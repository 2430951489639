define('ux-pcc/utils/charts/mark-today', ['exports', 'ux-pcc/utils/which-browser'], function (exports, _whichBrowser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.chartsMarkToday = chartsMarkToday;


  var am3LabelSelector = '.amcharts-category-axis text.today';
  var am3MarkerSelector = '.amcharts-category-axis .today-marker';
  var am4LabelSelector = '.amcharts-AxisRendererX .amcharts-today';
  var am4MarkerSelector = '.amcharts-AxisRendererX .today-marker';

  function nativeDom(chartDom, amchartsVersion, x, y, height, width) {
    if (!chartDom) {
      return;
    }

    var $todayLabel = chartDom.querySelectorAll(amchartsVersion === 4 ? am4LabelSelector : am3LabelSelector);

    if ($todayLabel.length === 0) {
      return;
    }

    var $todayMarker = chartDom.querySelector(amchartsVersion === 4 ? am4MarkerSelector : am3MarkerSelector);

    if ($todayMarker === null || $todayMarker.length === 0) {
      var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('class', 'today-marker');
      rect.setAttribute('transform', $todayLabel[0].getAttribute('transform'));
      $todayLabel[0].insertAdjacentElement('afterend', rect);
    } else {
      $todayMarker.setAttribute('transform', $todayLabel[0].getAttribute('transform'));
      if ($todayLabel[0].getAttribute('display') === 'none') {
        $todayMarker.setAttribute('display', 'none');
      } else {
        $todayMarker.setAttribute('display', 'inline');
      }
    }

    // Some browsers do not support setting x, y, height, and width on rect objects from CSS
    Ember.run.scheduleOnce('afterRender', this, function () {
      var svgRect = chartDom.querySelector(amchartsVersion === 4 ? am4MarkerSelector : am3MarkerSelector);
      if (svgRect) {
        var computedStyles = getComputedStyle(svgRect);
        svgRect.setAttribute('x', x);
        svgRect.setAttribute('y', y);
        svgRect.setAttribute('height', ('' + (computedStyles['height'] || height)).replace('px', ''));
        svgRect.setAttribute('width', ('' + (computedStyles['width'] || width)).replace('px', ''));
      }
    }, 50);
  }

  // IE is not a fan of the insertAdjacentElement function in the native JS version
  function jquery(chartDom, amchartsVersion, x, y, height, width) {
    var $chartDiv = Ember.$(chartDom);
    var $todayLabel = $chartDiv.find(amchartsVersion === 4 ? am4LabelSelector : am3LabelSelector);

    if ($todayLabel.length === 0) {
      return;
    }

    var $todayMarker = $chartDiv.find(amchartsVersion === 4 ? am4MarkerSelector : am3MarkerSelector);

    if ($todayMarker.length === 0) {
      var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      Ember.$(rect).attr({ class: 'today-marker', transform: $todayLabel.attr('transform') });
      $todayLabel.after(rect);
    } else {
      $todayMarker.attr({ transform: $todayLabel.attr('transform') });
      if ($todayLabel.attr('display') === 'none') {
        $todayMarker.attr({ display: 'none' });
      } else {
        $todayMarker.attr({ display: 'inline' });
      }
    }

    // Some browsers do not support setting x, y, height, and width on rect objects from CSS
    Ember.run.scheduleOnce('afterRender', this, function () {
      var svgRect = $chartDiv.find(amchartsVersion === 4 ? am4MarkerSelector : am3MarkerSelector);
      if (svgRect) {
        svgRect.attr('x', x);
        svgRect.attr('y', y);
        // IE seems to have trouble pulling the correct height/width from the computed styles
        // plannedEnhancement: find a way to pull in the styled sizes and set them here for IE
        svgRect.attr('height', svgRect.height() !== 0 ? svgRect.height() : height);
        svgRect.attr('width', svgRect.width() !== 0 ? svgRect.width() : width);
      }
    }, 50);
  }

  function chartsMarkToday(chartDom, amchartsVersion) {
    var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref$x = _ref.x,
        x = _ref$x === undefined ? -20 : _ref$x,
        _ref$y = _ref.y,
        y = _ref$y === undefined ? 25 : _ref$y,
        _ref$h = _ref.h,
        h = _ref$h === undefined ? 4 : _ref$h,
        _ref$w = _ref.w,
        w = _ref$w === undefined ? 40 : _ref$w;

    if (!((0, _whichBrowser.isChrome)() || (0, _whichBrowser.isFirefox)())) {
      jquery(chartDom, amchartsVersion, x, y, h, w);
    } else {
      nativeDom(chartDom, amchartsVersion, x, y, h, w);
    }
  }
});