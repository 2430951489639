define('ux-pcc/components/guarantee-panel/guarantee-form-direct-bill', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-direct-bill'], function (exports, _guaranteeFormDirectBill) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormDirectBill.default;
    }
  });
});