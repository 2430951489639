define('ux-pcc/mixins/inbound-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ActionProxy = Ember.Object.extend(Ember.ActionHandler);

  /**
   * @deprecated
   */
  exports.default = Ember.Mixin.create({
    _inboundActionsSetup: Ember.on('init', function () {
      var proxy = ActionProxy.create({
        target: this
      });

      this.set('actionReceiver', proxy);
    })
  });
});