define('ux-pcc/components/modals/request-audits-modal', ['exports', 'ux-components/components/modals/request-audits-modal'], function (exports, _requestAuditsModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requestAuditsModal.default;
    }
  });
});