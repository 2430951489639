define('ux-pcc/models/sandbox', ['exports', 'ux-data/models/sandbox'], function (exports, _sandbox) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sandbox.default;
    }
  });
});