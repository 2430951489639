define('ux-pcc/components/editable-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isEditing: false,
    placeholder: "",

    _blur: Ember.on('didInsertElement', Ember.observer('isEditing', function () {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        if (_this.get('isEditing')) {
          var $input = Ember.$(_this.$().find('input')[0]);
          $input.on('blur', function () {
            _this.send('completeEdit');
          });
        }
      });
    })),

    actions: {
      startEdit: function startEdit() {
        var _this2 = this;

        this.set('isEditing', true);

        Ember.run.later(this, function () {
          var $input = Ember.$(_this2.$().find('input')[0]);
          $input.focus().select();
        }, 300);
      },
      completeEdit: function completeEdit() {
        this.set('isEditing', false);
      }
    }
  });
});