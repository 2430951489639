define('ux-pcc/components/search-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    icon: 'fa-cube',
    searchPlaceholderTranslation: 'pcc-components.search_list.search_placeholder',
    noEntriesTranslation: 'pcc-components.search_list.no_entries',
    typeTranslation: 'pcc-components.search_list.type',

    objects: Ember.A(),

    selectedObjects: null,

    selectable: false,
    removeFiltered: true,

    page: 1,

    searchText: "",

    externalReference: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('sort', []);
      this.set('filteredResults', []);

      var numberPattern = /\d+/g;

      // constructs the suggestion engine
      this.set('bloodhound', new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          var tokens = [].concat(Bloodhound.tokenizers.whitespace(d.name));
          if (_this.get('externalReference')) {
            var reference = d.get(_this.get('externalReference')) || '';
            var numericTokens = Bloodhound.tokenizers.nonword(reference.match(numberPattern));
            tokens = tokens.concat(Bloodhound.tokenizers.whitespace(reference)).concat(numericTokens);
          }

          return tokens;
        },

        // Allow for the override of bloodhound suggestion limit
        limit: this.get('searchResultsLimit'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: []
      }));

      Ember.run.later(function () {
        _this.initializeBloodhound();
      });
    },


    filteredObjects: Ember.computed('objects.@each.id', 'filterObjects.@each.id', function () {
      var objects = (this.get('objects') || Ember.A()).compact();
      var filterObjectIds = (this.get('filterObjects') || Ember.A()).mapBy('id').compact().uniq();

      return objects.reject(function (object) {
        return filterObjectIds.includes(object.get('id'));
      });
    }),

    totalResults: Ember.computed.alias('filteredResults.length'),

    filteredObjectsObserver: Ember.observer('filteredObjects.[]', function () {
      this.initializeBloodhound();
    }),

    initializeBloodhound: function initializeBloodhound() {
      var _this2 = this;

      var objects = this.get('filteredObjects') || [];
      this.set('filteredResults', objects);

      var bloodhound = this.get('bloodhound');
      bloodhound.clear();
      bloodhound.local = objects;

      this.set('loading', true);
      Ember.run.later(function () {
        bloodhound.initialize(true).always(function () {
          _this2.set('loading', false);
        });
      });
    },


    actions: {
      processSuggestions: function processSuggestions() {
        var _this3 = this;

        if (this.get('searchText') !== '') {
          var bloodhound = this.get('bloodhound');
          bloodhound.search(this.get('searchText'), function (suggestions) {
            _this3.set('filteredResults', suggestions);
          });
        } else {
          this.set('filteredResults', this.get('filteredObjects'));
        }
      },
      selectObject: function selectObject(object) {

        var select = !object.get('selected');
        object.set('selected', select);

        if (select) {
          this.get('selectedObjects').pushObject(object);
        } else {
          this.set('selectedObjects', this.get('selectedObjects').rejectBy('id', object.get('id')));
        }
      }
    }
  });
});