define('ux-pcc/components/rule-rates/rule-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    customer: null,

    model: null,
    hideFilters: true,

    type: 'pricing',

    isPricingType: Ember.computed.equal('type', 'pricing'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('rulesSort', ['shortDescription']);
    },


    ruleTypeList: Ember.computed('i18n.locale', function () {
      return [{
        value: '',
        label: this.i18n.t('pcc-components.rule-rates.rule-feed.all')
      }, {
        value: 'ALERT_COMP',
        label: this.i18n.t('pcc-components.rule-rates.rule-panel.competition')
      }, {
        value: 'ALERT_INV',
        label: this.i18n.t('pcc-components.rule-rates.rule-panel.inventory')
      }, {
        value: 'ALERT_OCC',
        label: this.i18n.t('pcc-components.rule-rates.rule-panel.occupancy')
      }, {
        value: 'ALERT_PERF',
        label: this.i18n.t('pcc-components.rule-rates.rule-panel.performance')
      }];
    }),

    isAll: Ember.computed.equal('ruleType', ''),

    isCurrent: Ember.computed.equal('ruleStatus', 'current'),

    isShowCeiling: Ember.computed('customer.settings', function () {
      var ceilingSetting = (this.get('customer.settings') || []).findBy('settingCode', 'DISPLAY_CEILING');
      if (ceilingSetting) {
        return ceilingSetting.get('settingValue').toUpperCase() === 'Y' || ceilingSetting.get('settingValue').toUpperCase() === 'YES' || ceilingSetting.get('settingValue').toUpperCase() === 'TRUE';
      }
      return false;
    }),

    actions: {
      viewFilters: function viewFilters() {
        this.toggleProperty('hideFilters');
      },
      flash: function flash(message, type) {
        this.flash(message, type);
      },
      confirmDelete: function confirmDelete(object) {
        this.confirmDelete(object);
      }
    }
  });
});