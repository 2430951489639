define('ux-pcc/routes/deep-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),
    router: Ember.inject.service('-routing'),

    model: function model(params) {
      if (this.router.hasRoute(params.targetRoute)) {
        return Ember.RSVP.hash({
          customerID: params.customerID || this.session.data.authenticated.customerID,
          targetRoute: params.targetRoute,
          modelID: params.modelID,
          qp: params.qp
        });
      } else {
        return Ember.RSVP.reject({
          status: 404,
          details: {
            params: params
          }
        });
      }
    },
    afterModel: function afterModel(_ref) {
      var targetRoute = _ref.targetRoute,
          customerID = _ref.customerID,
          modelID = _ref.modelID,
          qp = _ref.qp;

      if (modelID) {
        this.replaceWith(targetRoute, customerID, modelID);
      } else if (qp) {
        this.replaceWith(targetRoute, customerID, { queryParams: JSON.parse(decodeURIComponent(qp)) });
      } else {
        this.replaceWith(targetRoute, customerID);
      }
    }
  });
});