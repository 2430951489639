define('ux-pcc/utils/abbreviate-number', ['exports', 'ux-components/utils/abbreviate-number'], function (exports, _abbreviateNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _abbreviateNumber.default;
    }
  });
});