define('ux-pcc/initializers/ember-faker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {}

  exports.default = {
    name: 'ember-faker',
    initialize: initialize
  };
});