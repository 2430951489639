define('ux-pcc/components/panels/request-audits-panel/inventory-restriction-request', ['exports', 'ux-components/components/panels/request-audits-panel/inventory-restriction-request'], function (exports, _inventoryRestrictionRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inventoryRestrictionRequest.default;
    }
  });
});