define('ux-pcc/helpers/objectify', ['exports', 'ux-components/helpers/objectify'], function (exports, _objectify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _objectify.default;
    }
  });
  Object.defineProperty(exports, 'objectify', {
    enumerable: true,
    get: function () {
      return _objectify.objectify;
    }
  });
});