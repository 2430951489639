define('ux-pcc/components/rules/condition-values/base', ['exports', 'ember-changeset', 'ember-changeset-validations'], function (exports, _emberChangeset, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    field: 'filterValue',

    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (this.field === 'filterValue') {
        changeset.set('filterRangeLow', null);
        changeset.set('filterRangeHigh', null);
      }

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {};
    }),

    changeset: Ember.computed('condition', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('condition'), (0, _emberChangesetValidations.default)(validations), validations);
    })

  });
});