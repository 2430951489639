define('ux-pcc/components/rules/form-basic', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    noTypeSelected: Ember.computed.empty('changeset.ruleType'),

    nameMaxLength: 75,
    descriptionMaxLength: 500,

    nameCountDown: Ember.computed('changeset.shortDescription', function () {
      return this.nameMaxLength - (this.get('changeset.shortDescription.length') || 0);
    }),

    descriptionCountDown: Ember.computed('changeset.longDescription', function () {
      return this.descriptionMaxLength - (this.get('changeset.longDescription.length') || 0);
    }),

    isOngoing: Ember.computed.empty('changeset.endDate'),

    daysInWindow: Ember.computed('customer.settings.@each.{settingCode,settingValue}', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'NOTIFICATION_MAX_DAYS');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return -1;
      }
    }),

    ongoingLabel: Ember.computed('i18n.locale', function () {
      var daysInWindow = this.get('daysInWindow');

      if (daysInWindow > 0) {
        return this.i18n.t('rule._form.ongoing_rolling', { days: daysInWindow });
      } else {
        return this.i18n.t('rule._form.ongoing');
      }
    }),

    minEndDate: Ember.computed('changeset.beginDate', function () {
      var beginDate = this.get('changeset.beginDate');
      if (typeof beginDate === 'string') {
        var tz = this.tz.get('customer');
        beginDate = _moment.default.tz(beginDate, tz).startOf('day');
      } else {
        beginDate = (0, _moment.default)(this.tz).startOf('day');
      }
      return beginDate;
    }),

    startDateDisabled: Ember.computed('changeset.beginDate', function () {
      var tz = this.tz.get('customer');
      var today = _moment.default.tz((0, _moment.default)(), tz).startOf('day');
      var beginDate = _moment.default.tz(this.get('changeset.beginDate'), tz);
      if (!beginDate.isValid()) {
        return false;
      }

      return beginDate.isBefore(today);
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };

      //TODO: re-add this if necessary
      // if (!this.get('startDateDisabled')) {
      //   options.minDate = moment(this.tz).startOf('day');
      // }
      //
      // return options;
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate')
      };
    }),

    actions: {
      toggleOngoing: function toggleOngoing() {

        if (this.get('isOngoing')) {
          // Set to one day past the minEndDate
          var tz = this.tz.get('customer');
          this.set('changeset.endDate', _moment.default.tz(this.get('minEndDate'), tz).startOf('day').add(1, 'days').format());
        } else {
          this.set('changeset.endDate', null);
        }
      }
    }
  });
});