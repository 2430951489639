define('ux-pcc/initializers/deprecation-handler', ['exports', 'ux-components/initializers/deprecation-handler'], function (exports, _deprecationHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _deprecationHandler.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _deprecationHandler.initialize;
    }
  });
});