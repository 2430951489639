define('ux-pcc/routes/pacing/intraday', ['exports', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fileSaver.default, {

    ajax: Ember.inject.service(),

    queryParams: {
      endDate: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var tz = this.tz.get('customer');
      var beginDate = void 0;
      var endDate = void 0;

      // If we have a date, use that as the ending point
      if (params.endDate) {
        endDate = _moment.default.tz(params.endDate, tz).startOf('day');
      } else {
        endDate = _moment.default.tz(tz).startOf('day');
      }

      if (this.get('deviceType.mobile')) {
        beginDate = endDate.clone().endOf('day');
      } else {
        beginDate = endDate.clone().subtract(7, 'days').endOf('day');
      }

      var customerId = this.modelFor('customer').get('id');

      return this.ajax.request('/rest/v1/pacingByHour', {
        data: {
          customerID: customerId,
          begindate: beginDate.format('YYYY-MM-DD'),
          enddate: endDate.format('YYYY-MM-DD')
        }
      }).then(function (contents) {
        return contents && contents.length > 0 ? contents : [];
      });
    },
    setupController: function setupController(controller, model) {
      var tz = this.tz.get('customer');
      var endDate = void 0;

      controller.set('isMobile', this.get('deviceType.mobile'));
      controller.set('isDesktop', this.get('deviceType.desktop'));

      // Set the controller end date to match date used in the Model
      if (controller.get('endDate')) {
        endDate = _moment.default.tz(controller.get('endDate'), tz).startOf('day');
      } else {
        endDate = _moment.default.tz(tz).startOf('day');
      }
      controller.set('endDate', endDate.format());

      this._super(controller, model);
    },


    actions: {
      exportCsv: function exportCsv() {
        var _this = this;

        this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('pacing.message.download_requested'), 'success');
        var tz = this.get('tz.customer');
        var customerId = this.modelFor('customer').get('id');
        var endDate = _moment.default.tz(this.controller.get('endDate'), tz);
        var beginDate = endDate.clone().subtract(7, 'days');
        var filename = this.get('i18n').t('pacing.intra_day.file_prefix') + '_' + this.modelFor('customer').get('externalReference') + '_' + endDate.format('YYYYMMDD') + '.csv';

        this.get('ajax').request('/rest/v1/pacingByHour', {
          headers: { accept: 'text/csv' },
          contentType: 'text/csv',
          dataType: 'blob',
          data: {
            customerID: customerId,
            begindate: beginDate.format('YYYY-MM-DD'),
            enddate: endDate.format('YYYY-MM-DD')
          }
        }).then(function (data) {
          return _this.saveFileAs(filename, data, 'text/csv');
        }, function () {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this.get('i18n').t('customer.performance.download_error'), 'danger');
        });
      }
    }
  });
});