define('ux-pcc/components/rules/conditions/crs-freeze-inv-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values'], function (exports, _ruleConditionValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    type: 'noop',

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');
      changeset.set('filterValue', 0);
    },


    validations: Ember.computed(function () {
      return {};
    }),

    filterConditions: Ember.computed('i18n.locale', function () {
      return [{ value: 'GT', label: this.i18n.t('pcc-components.rules.conditions.is_in_effect') }];
    })
  });
});