define('ux-pcc/models/reservation-product-calendar', ['exports', 'ux-data/models/reservation-product-calendar'], function (exports, _reservationProductCalendar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservationProductCalendar.default;
    }
  });
});