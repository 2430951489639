define('ux-pcc/controllers/competitive-set/-add-competitors-modal', ['exports', 'ux-pcc/mixins/controllers/places-searchable'], function (exports, _placesSearchable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_placesSearchable.default, {
    ajax: Ember.inject.service(),

    customer: Ember.inject.controller(),
    addedCompetitors: Ember.A(),
    filteredCompetitors: Ember.computed.oneWay('model.xrefs'),

    distanceUnits: 'MI',

    modalSearchOptions: Ember.computed('customer.model.addresses.[]', function () {
      var address = (this.get('customer.model.addresses') || []).findBy('addressType', 'BUSINESS') || (this.get('customer.model.addresses') || []).findBy('addressType', 'MAIN');
      if (address) {
        return {
          latitude: address.get('geolocationLatitude'),
          longitude: address.get('geolocationLongitude'),
          include: 'address',
          customertype: 'CUSTOMER'
        };
      } else {
        return {
          include: 'address',
          customertype: 'CUSTOMER'
        };
      }
    }),

    markers: Ember.computed('filteredCompetitors', function () {
      return this.get('filteredCompetitors').rejectBy('disabled').map(function (property) {
        property = property.hasOwnProperty('object') ? property.object : property;
        var address = (property.get('addresses') || []).findBy('addressType', 'BUSINESS') || (property.get('addresses') || []).findBy('addressType', 'MAIN');
        if (address) {
          return {
            id: property.get('id'),
            lat: address.get('geolocationLatitude'),
            lng: address.get('geolocationLongitude'),
            infoWindow: {
              content: '<div class=\'available-property__map-marker\'>\n                        <div class=\'available-property__map-marker--name\'>\n                          ' + property.get('name') + '\n                        </div>\n                        <div>\n                          <div class=\'available-property__map-marker--address\'>\n                            <div>\n                              ' + address.get('address1') + '\n                            </div>\n                            <div>\n                              ' + address.get('city') + ', ' + address.get('state') + ' ' + address.get('postalCode') + '\n                            </div>\n                          </div>\n                        </div>\n                      </div>'
            }
          };
        } else {
          return { id: null, content: null, lat: null, long: null };
        }
      });
    }),

    disabledMarkers: Ember.computed('filteredCompetitors', function () {
      return this.get('filteredCompetitors').filterBy('disabled', true).map(function (property) {
        property = property.hasOwnProperty('object') ? property.object : property;
        var address = (property.get('addresses') || []).findBy('addressType', 'BUSINESS') || (property.get('addresses') || []).findBy('addressType', 'MAIN');
        if (address) {
          return {
            id: property.get('id'),
            lat: address.get('geolocationLatitude'),
            lng: address.get('geolocationLongitude'),
            infoWindow: {
              content: '<div class=\'available-property__map-marker\'>\n                        <div class=\'available-property__map-marker--name\'>\n                          ' + property.get('name') + '\n                        </div>\n                        <div>\n                          <div class=\'available-property__map-marker--address\'>\n                            <div>\n                              ' + address.get('address1') + '\n                            </div>\n                            <div>\n                              ' + address.get('city') + ', ' + address.get('state') + ' ' + address.get('postalCode') + '\n                            </div>\n                          </div>\n                        </div>\n                      </div>'
            }
          };
        } else {
          return { id: null, name: null, lat: null, long: null };
        }
      });
    }),

    selectedMarkers: Ember.computed('filteredCompetitors.@each.{selected,object.selected}', function () {
      return this.get('filteredCompetitors').map(function (property) {
        property = property.hasOwnProperty('object') ? property.object : property;
        if (property.get('selected')) {
          var address = (property.get('addresses') || []).findBy('addressType', 'BUSINESS') || (property.get('addresses') || []).findBy('addressType', 'MAIN');
          if (address) {
            return {
              id: property.get('id'),
              lat: address.get('geolocationLatitude'),
              lng: address.get('geolocationLongitude'),
              infoWindow: {
                content: '<div class=\'available-property__map-marker\'>\n                          <div class=\'available-property__map-marker--name\'>\n                            ' + property.get('name') + '\n                          </div>\n                          <div>\n                            <div class=\'available-property__map-marker--address\'>\n                              <div>\n                                ' + address.get('address1') + '\n                              </div>\n                              <div>\n                                ' + address.get('city') + ', ' + address.get('state') + ' ' + address.get('postalCode') + '\n                              </div>\n                            </div>\n                          </div>\n                        </div>'
              }
            };
          } else {
            return { id: null, name: null, lat: null, long: null };
          }
        }
      });
    }),

    primaryMarker: Ember.computed('customer.model.addresses.[]', function () {
      var addresses = this.get('customer.model.addresses') || [];
      var address = addresses.findBy('addressType', 'BUSINESS') || addresses.findBy('addressType', 'MAIN');
      if (address) {
        return {
          lat: address.get('geolocationLatitude'),
          long: address.get('geolocationLongitude')
        };
      } else {
        return { id: null, content: null, lat: null, long: null };
      }
    }),

    _addCompetitors: function _addCompetitors(competitors) {
      var model = this.get('model');
      model.get('xrefs').addObjects(competitors);

      if (competitors.length > 1) {
        this.set('flash', {
          type: 'success',
          message: '<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('competitive-set.-form.add_multiple_success', { length: competitors.length })
        });
      } else if (competitors.length === 1) {
        this.set('flash', {
          type: 'success',
          message: '<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('competitive-set.-form.add_success', { competitor: competitors.get('firstObject.name') })
        });
      }
    },


    actions: {
      addManual: function addManual() {
        this.set('filteredCompetitors', Ember.A());
        this.set('addressLookup', null);
        this.set('isManualAdd', true);

        var customer = this.get('model');

        var newCustomer = this.store.createRecord('customer', {
          name: '',
          customerType: 'COMPETITOR'
        });

        newCustomer.get('contactInformations').addObject(this.get('store').createRecord('contact-information', {
          informationType: 'MAIN_PHONE',
          informationValue: ''
        }));

        newCustomer.get('addresses').addObject(this.get('store').createRecord('address', {
          addressType: 'BUSINESS',
          rawAddressText: ''
        }));

        newCustomer.set('parent', customer);

        this.set('newCustomer', newCustomer);
      },
      clearManual: function clearManual() {
        this.set('filteredCompetitors', Ember.A());
        this.set('isManualAdd', false);
        this.set('addressEditable', false);
        this.set('addressLookup', null);
        this.set('newCustomer', null);
        this.set('flash', null);
      },
      createManual: function createManual() {
        var _this = this;

        var competitor = this.get('newCustomer');
        this.set('flash', null);

        competitor.save().then(function () /*result*/{
          // handle success
          _this.get('addedCompetitors').push(competitor);
          _this.send('clearManual');
        }).catch(function (response) {
          return _this.set('flash', {
            type: 'danger',
            message: '<i class=\'fa fa-warning\'></i> ' + response.message
          });
        });
      },
      highlightSelection: function highlightSelection(lat, long, item) {
        var _this2 = this;

        if (item && item.customerID) {
          this.store.findRecord('customer', item.customerID, { include: 'address,contactInformations' }).then(function (record) {
            return _this2._populateExistingCustomer(_this2.get('newCustomer'), record);
          }).then(function (customer) {
            return _this2.set('filteredCompetitors', Ember.A([customer]));
          });
        } else if (item && item.placeID) {
          this.ajax.request('/rest/v1/places/' + item.placeID, { dataType: 'json' }).then(function (record) {
            return _this2._populateCustomer(_this2.get('newCustomer'), record);
          }).then(function (customer) {
            return _this2.set('filteredCompetitors', Ember.A([customer]));
          });
        }
      },
      save: function save() {
        this._addCompetitors(this.get('addedCompetitors'));
        this.set('addedCompetitors', Ember.A());
        this.send('close');
      },
      closeAndClear: function closeAndClear() {
        this.set('addedCompetitors', Ember.A());
        this.send('close');
        this.send('removeModal');
      },
      close: function close() {
        this.send('clearManual');
        this.send('removeModal');
      },
      setLoading: function setLoading() {
        // noop
      }
    }
  });
});