define('ux-pcc/components/card-view/card-supplemental-action', ['exports', 'ux-components/components/card-view/card-supplemental-action'], function (exports, _cardSupplementalAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cardSupplementalAction.default;
    }
  });
});