define('ux-pcc/helpers/wrap-negative', ['exports', 'accounting'], function (exports, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.wrapNegative = wrapNegative;
  function wrapNegative(value) {
    var hash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


    if (hash && Object.keys(hash).length > 0) {
      value = (0, _accounting.formatNumber)(value, hash);
      if (hash.zeroPrecision && hash.zeroPrecision !== hash.precision && (0, _accounting.toFixed)(value, 2) === "0.00") {
        value = (0, _accounting.formatNumber)(value, { precision: hash.zeroPrecision });
      }
    }

    return Ember.String.htmlSafe(('' + value + (hash && hash.suffix ? hash.suffix : '')).replace(/-(.*)/, '($1)'));
  }

  exports.default = Ember.Helper.helper(wrapNegative);
});