define('ux-pcc/utils/date-sort', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateSort = dateSort;
  exports.reverseDateSort = reverseDateSort;
  function dateSort(tz, field) {
    return function (t1, t2) {
      var date1 = field ? _moment.default.tz(t1.get(field), tz) : _moment.default.tz(t1, tz);
      var date2 = field ? _moment.default.tz(t2.get(field), tz) : _moment.default.tz(t2, tz);
      return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
    };
  }

  function reverseDateSort(tz, field) {
    return function (t1, t2) {
      var date1 = field ? _moment.default.tz(t1.get(field), tz) : _moment.default.tz(t1, tz);
      var date2 = field ? _moment.default.tz(t2.get(field), tz) : _moment.default.tz(t2, tz);
      return date1.isBefore(date2) ? 1 : date1.isAfter(date2) ? -1 : 0;
    };
  }
});