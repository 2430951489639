define('ux-pcc/components/rules/condition-values/text', ['exports', 'ux-pcc/components/rules/condition-values/base', 'ember-changeset-validations/validators'], function (exports, _base, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    validations: Ember.computed('field', 'i18n.locale', function () {

      var validations = {};

      validations[this.field] = (0, _validators.validatePresence)({
        presence: true,
        message: this.i18n.t('rule._form_modal.no_value').toString()
      });

      return validations;
    })

  });
});