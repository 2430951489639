define('ux-pcc/components/card-data-window', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    screen: null,
    rowClick: null,

    columnLayoutClasses: Ember.computed('screen', function () {
      var screen = this.get('screen');
      var columnCount = screen.getRows()[0].getValues().length;

      if (columnCount > 0 && 12 % columnCount === 0) {
        return 'col-xs-' + 12 / columnCount;
      }

      return 'col-xs-12';
    }),

    dataValues: Ember.computed.alias('screen'),

    actions: {
      rowClick: function rowClick(row) {
        if (this.get('rowClick')) {
          this.get('rowClick')(row);
        }
      }
    }

  });
});