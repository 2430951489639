define('ux-pcc/components/panels/daily-policies-panel', ['exports', 'ux-components/components/panels/daily-policies-panel'], function (exports, _dailyPoliciesPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dailyPoliciesPanel.default;
    }
  });
});