define('ux-pcc/models/multimedia-resource', ['exports', 'ux-data/models/multimedia-resource'], function (exports, _multimediaResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _multimediaResource.default;
    }
  });
});