define('ux-pcc/components/sister-properties/property-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: [],

    sort: ['modifier'],

    actions: {
      flash: function flash(message, type) {
        this.get('onFlash')(message, type);
      },
      confirmDelete: function confirmDelete(object) {
        this.get('onConfirmDelete')(object);
      },
      edit: function edit(object) {
        this.get('onEdit')(object);
      }
    }
  });
});