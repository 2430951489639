define('ux-pcc/routes/http404', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      return Ember.RSVP.reject({
        status: 404,
        details: {
          url: transition.intent.url
        }
      });
    }
  });
});