define('ux-pcc/components/inputs/checkbox-list', ['exports', 'ux-components/components/inputs/checkbox-list'], function (exports, _checkboxList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _checkboxList.default;
    }
  });
});