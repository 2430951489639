define('ux-pcc/components/yield-manager/views/ymtd-view', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/components/yield-manager/views/base-view', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _baseView, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _baseView.default.extend(_fileSaver.default, {

    layoutName: 'components/yield-manager/views/ymtd-view',
    showComments: false,

    leadColumnTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.week_ending');
    }),

    displayParam: Ember.computed.alias('model.view.dashboardViewParameters.DISPLAY'),

    isWeekToDate: Ember.computed('displayParam', function () {
      return this.get('displayParam') === 'WEEK_TO_DATE';
    }),

    // define the charts to be displayed
    dataFields: Ember.computed('data', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');

      return [Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.days'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.analyze.cards.charge_days') + (displayPercentages ? ' %' : ''),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-days.added_title') + (displayPercentages ? ' %' : ''),
        values: [{
          key: displayPercentages ? 'pctTotalIncreaseUsageMinusNoShows' : 'increaseUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? 'pctTotalStlyIncreaseUsageMinusNoShows' : 'stlyIncreaseUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? 'pctTotalUsageMinusNoShows' : 'usageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.current_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          shade: true,
          category: 'left'

        }, {
          key: displayPercentages ? 'pctTotalStlyUsageMinusNoShows' : 'stlyUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.prior_year'),
          format: displayPercentages ? 'percent' : 'number',
          lineClasses: 'days-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          shade: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.yield'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.yield'),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-yield.added_title'),
        values: [{
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield-compare',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield-compare',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: this.get('i18n').t('yield-manager.view.current_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left',
          shade: true
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: this.get('i18n').t('yield-manager.view.prior_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield-compare',
          enabled: true,
          category: 'left',
          shade: true
        }]
      }), Ember.Object.create({
        label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') && !displayPercentages ? '(' + this.get('currencyCode') + ')' : ''),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.revenue') + (displayPercentages ? ' %' : ''),
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-revenue.added_title') + (displayPercentages ? ' %' : ''),
        values: [{
          key: displayPercentages ? grossFlag ? 'pctTotalIncreaseGross' : 'pctTotalIncreaseBase' : grossFlag ? 'increaseGross' : 'increaseBase',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalStlyIncreaseGross' : 'pctTotalStlyIncreaseBase' : grossFlag ? 'stlyIncreaseGross' : 'stlyIncreaseBase',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue-compare',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalGross' : 'pctTotalBase' : grossFlag ? 'gross' : 'base',
          label: this.get('i18n').t('yield-manager.view.current_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left',
          shade: true
        }, {
          key: displayPercentages ? grossFlag ? 'pctTotalStlyGross' : 'pctTotalStlyBase' : grossFlag ? 'stlyGross' : 'stlyBase',
          label: this.get('i18n').t('yield-manager.view.prior_year'),
          format: displayPercentages ? 'percent' : 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue-compare compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue-compare',
          enabled: true,
          category: 'left',
          shade: true
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        values: [{
          key: 'pctChangeUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.days_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.days_variance'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'pctVarDayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.yield_variance'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'pctVarYieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
          label: this.get('i18n').t('yield-manager.view.revenue_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.revenue_variance'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'pctVarRevenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '' + this.get('i18n').t('yield-manager.rate_of_add'),
        leftAxisTitle: this.get('i18n').t('yield-manager.rate_of_add') + ' %',
        values: [{
          key: 'twRoaUsage',
          label: this.get('i18n').t('yield-manager.view.days_roa'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'roaDayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'twRoaGrossYield' : 'twRoaBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_roa'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'roaYieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'twRoaGross' : 'twRoaBase',
          label: this.get('i18n').t('yield-manager.view.revenue_roa'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'roaRevenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }]
      })];
    }),

    currentColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');

      if (displayPercentages) {
        return [{
          key: 'pctTotalUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'pctTotalGross' : 'pctTotalBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' %',
          format: 'percent'
        }, {
          key: 'pctTotalIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      } else {
        return [{
          key: 'usageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'grossYield' : 'baseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'gross' : 'base',
          label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions')
        }, {
          key: 'increaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'increaseGrossYield' : 'increaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      }
    }),

    currentTableData: Ember.computed('tableData', 'currentColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('currentColumns', 'tableData', 2);
      }
    }),

    stlyColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      var displayPercentages = this.get('displayPercentages');

      if (displayPercentages) {
        return [{
          key: 'pctTotalStlyUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'pctTotalStlyGross' : 'pctTotalStlyBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' %',
          format: 'percent'
        }, {
          key: 'pctTotalStlyIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + ' %</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      } else {
        return [{
          key: 'stlyUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }, {
          key: grossFlag ? 'stlyGross' : 'stlyBase',
          label: this.get('i18n').t('yield-manager.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions')
        }, {
          key: 'stlyIncreaseUsageMinusNoShows',
          label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
          format: 'number',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.added') + '</div>',
          groupColumnHeaderSpan: 2
        }, {
          key: grossFlag ? 'stlyIncreaseGrossYield' : 'stlyIncreaseBaseYield',
          label: '' + this.get('i18n').t('yield-manager.yield'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions')
        }];
      }
    }),

    percentDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'pctChangeUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'percent',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>% ' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'percent'
      }, {
        key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue'),
        format: 'percent'
      }];
    }),

    actualDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'deltaUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        key: grossFlag ? 'deltaGross' : 'deltaBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
        format: 'currency',
        formatHash: this.get('formatRevenueOptions')
      }];
    }),

    roaDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'twRoaUsage',
        label: '<div>' + this.get('i18n').t('yield-manager.charge_days') + '</div>',
        format: 'percent',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>% ' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'twRoaGrossYield' : 'twRoaBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'percent'
      }, {
        key: grossFlag ? 'twRoaGross' : 'twRoaBase',
        label: this.get('i18n').t('yield-manager.accumulated.revenue'),
        format: 'percent'
      }];
    }),

    currentRunDate: Ember.computed('startDate', 'endDate', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      return this.get('i18n').t('yield-manager.run_dates', {
        fromDate: (0, _dateFormatter.dateFormatter)(this.get('selectedDateParam'), { tz: tz, settings: settings }),
        toDate: (0, _dateFormatter.dateFormatter)(this.get('selectedEndDateParam'), { tz: tz, settings: settings })
      });
    }),

    stlyRunDate: Ember.computed('startDate', 'endDate', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      return this.get('i18n').t('yield-manager.run_dates', {
        fromDate: (0, _dateFormatter.dateFormatter)(this.get('selectedStlyDateParam'), { tz: tz, settings: settings }),
        toDate: (0, _dateFormatter.dateFormatter)(this.get('selectedStlyEndDateParam'), { tz: tz, settings: settings })
      });
    }),

    percentDeltaTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.date_span') + ' % ' + this.get('i18n').t('yield-manager.change');
    }),

    actualDeltaTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.actual') + ' ' + this.get('i18n').t('yield-manager.change');
    }),

    roaDeltaTitle: Ember.computed(function () {
      return '' + this.get('i18n').t('yield-manager.rate_of_add');
    }),

    aggregationType: 'WEEKLY_ACCUMULATION_COMPARISON_V2',

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customerID');
      var tz = this.get('tz');
      var today = _moment.default.tz(tz);
      var startDate = _moment.default.tz(this.get('selectedDateParam'), tz);
      var endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz);

      // hard code asOf date to 90 days prior to selected date
      var asOfStartDate = startDate.clone().subtract(90, 'days');

      this.set('startDate', startDate);
      this.set('asOfStartDate', asOfStartDate);
      this.set('endDate', endDate);
      this.set('data', null);

      var params = {
        customerid: customerID,
        begindateStr: startDate.format('YYYY-MM-DD'),
        enddateStr: endDate.format('YYYY-MM-DD'),
        asOfDateBeginStr: asOfStartDate.format('YYYY-MM-DD'),
        asOfDateEndStr: endDate.format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        aggregate: false,
        groupBy: 'ALL'
      };

      var requests = [this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'YMTD Data').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            entries = _ref3$ === undefined ? [] : _ref3$;

        var mapped = {};
        var dataRows = [];
        var stlyDataRows = [];

        if (!entries || entries.length < 1) {
          _this.set('data', []);
          _this.set('tableData', []);
          _this.set('stlyData', []);
          return;
        }

        entries = entries.rejectBy('subContentType', 'FULL_SPAN');
        var weekToDateFlag = _this.get('isWeekToDate');

        var settings = _this.get('customer.settings');
        entries.forEach(function (e) {
          var date = (0, _moment.default)(e.dataValues['endDate']);
          var key = date.format();

          mapped[key] = e.dataValues;
          //add the slice
          mapped[key]['slice'] = key;
          mapped[key]['date'] = (0, _dateFormatter.dateFormatter)(date, { settings: settings });
          mapped[key]['currencyCode'] = e.currencyCode;

          // If week over week selected, put the correct values in the field being used
          if (weekToDateFlag) {
            if (e.dataValues['eodYesterdayStlyUsageMinusNoShows']) {
              e.dataValues['stlyUsageMinusNoShows'] = e.dataValues['eodYesterdayStlyUsageMinusNoShows'];
            }
            if (e.dataValues['eodYesterdayStlyIncreaseUsage']) {
              e.dataValues['stlyIncreaseUsageMinusNoShows'] = e.dataValues['eodYesterdayStlyIncreaseUsage'];
            }

            if (e.dataValues['eodYesterdayStlyBase']) {
              e.dataValues['stlyBase'] = e.dataValues['eodYesterdayStlyBase'];
            }
            if (e.dataValues['eodYesterdayStlyIncreaseBase']) {
              e.dataValues['stlyIncreaseBase'] = e.dataValues['eodYesterdayStlyIncreaseBase'];
            }

            if (e.dataValues['eodYesterdayStlyGross']) {
              e.dataValues['stlyGross'] = e.dataValues['eodYesterdayStlyGross'];
            }
            if (e.dataValues['eodYesterdayStlyIncreaseGross']) {
              e.dataValues['stlyIncreaseGross'] = e.dataValues['eodYesterdayStlyIncreaseGross'];
            }

            if (e.dataValues['eodYesterdayStlyBaseYield']) {
              e.dataValues['stlyBaseYield'] = e.dataValues['eodYesterdayStlyBaseYield'];
            }
            if (e.dataValues['eodYesterdayStlyIncreaseBaseYield']) {
              e.dataValues['stlyIncreaseBaseYield'] = e.dataValues['eodYesterdayStlyIncreaseBaseYield'];
            }

            if (e.dataValues['eodYesterdayStlyGrossYield']) {
              e.dataValues['stlyGrossYield'] = e.dataValues['eodYesterdayStlyGrossYield'];
            }
            if (e.dataValues['eodYesterdayStlyIncreaseGrossYield']) {
              e.dataValues['stlyIncreaseGrossYield'] = e.dataValues['eodYesterdayStlyIncreaseGrossYield'];
            }
          }

          // Need 'copies' so we can set amounts
          var rowValues = Ember.merge({}, mapped[key]);
          var stlyValues = Ember.merge({}, mapped[key]);

          // Populate the rows for each table.  Will reuse dataRows for the delta values
          dataRows.push({ label: (0, _dateFormatter.dateFormatter)(date, { settings: settings }), values: rowValues });
          stlyDataRows.push({ label: (0, _dateFormatter.dateFormatter)(e.dataValues['endDateStly'], { settings: settings }), values: stlyValues });

          // Strip out the CY values from the graph if this row is in the future
          if (date.isAfter(today, 'week')) {
            delete mapped[key].usageMinusNoShows;
            delete mapped[key].increaseUsageMinusNoShows;
            delete mapped[key].baseYield;
            delete mapped[key].increaseBaseYield;
            delete mapped[key].grossYield;
            delete mapped[key].increaseGrossYield;
            delete mapped[key].base;
            delete mapped[key].increaseBase;
            delete mapped[key].gross;
            delete mapped[key].increaseGross;

            // remove the variance and roa values as well
            delete mapped[key].pctChangeUsageMinusNoShows;
            delete mapped[key].pctChangeGrossYield;
            delete mapped[key].pctChangeBaseYield;
            delete mapped[key].pctChangeGross;
            delete mapped[key].pctChangeBase;
            delete mapped[key].twRoaUsage;
            delete mapped[key].twRoaGrossYield;
            delete mapped[key].twRoaBaseYield;
            delete mapped[key].twRoaGross;
            delete mapped[key].twRoaBase;
          }
        });

        var reverseDateSort = function reverseDateSort(o1, o2) {
          var date1 = (0, _moment.default)(o1.values.endDate);
          var date2 = (0, _moment.default)(o2.values.endDate);

          return date1.isBefore(date2) ? 1 : date1.isAfter(date2) ? -1 : 0;
        };

        dataRows.sort(reverseDateSort);
        stlyDataRows.sort(reverseDateSort);

        _this.set('data', mapped);
        _this.set('tableData', dataRows);
        _this.set('stlyData', stlyDataRows);
        _this.set('currentChartKey', 'usage');
      }).finally(function () {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('loading', false);
        }
      });
    },
    _openDrawers: function _openDrawers(row, pending) {
      // First set all the other table rows to expanded
      var linkedRow = (this.get('stlyTableData.groups.firstObject.rows') || []).findBy('values.endDate', row.values.endDate);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', true);
        Ember.set(linkedRow, 'childrenPending', pending);
      }

      this.notifyPropertyChange('tableData');
    },
    _finalizeChildLoad: function _finalizeChildLoad(row) {
      Ember.set(row, 'childrenPending', false);
      this.notifyPropertyChange('currentTableData');

      // Remove pending from child load
      var linkedRow = (this.get('stlyTableData.groups.firstObject.rows') || []).findBy('values.endDate', row.values.endDate);
      if (linkedRow) {
        Ember.set(linkedRow, 'childrenPending', false);
      }
      this.notifyPropertyChange('tableData');
    },
    _closeDrawers: function _closeDrawers(row) {
      // First set all the other table rows to expanded
      var linkedRow = (this.get('stlyTableData.groups.firstObject.rows') || []).findBy('values.endDate', row.values.endDate);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', false);
      }
      this.notifyPropertyChange('tableData');
    },
    _fetchChildData: function _fetchChildData(row) {
      var _this2 = this;

      // Expand the rows in all the tables
      if (row.children) {
        this._openDrawers(row, false);
        return;
      }

      var customerID = this.get('customerID');
      var tz = this.get('tz');
      var startDate = _moment.default.tz(this.get('selectedDateParam'), tz);
      var endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz);

      // grab the week for the selected row
      var rowDate = _moment.default.tz(row.values.endDate, tz);
      var asOfStartDate = rowDate.clone().startOf('week');
      var asOfEndDate = rowDate.clone();

      var modifiedView = JSON.parse(JSON.stringify(this.get('model.view')));

      Ember.set(modifiedView.dashboardViewParameters, 'DAILY_BREAKDOWN', true);

      var params = {
        customerid: customerID,
        begindateStr: startDate.format('YYYY-MM-DD'),
        enddateStr: endDate.format('YYYY-MM-DD'),
        asOfDateBeginStr: asOfStartDate.format('YYYY-MM-DD'),
        asOfDateEndStr: asOfEndDate.format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        aggregate: false,
        groupBy: 'ALL'
      };

      var requests = [this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(modifiedView, tz)
      }).then(function (_ref4) {
        var _ref4$collections = _ref4.collections,
            collections = _ref4$collections === undefined ? [] : _ref4$collections;
        return collections;
      })];

      Ember.set(row, 'childrenPending', true);
      this._openDrawers(row, true);
      this.notifyPropertyChange('tableData');

      Ember.RSVP.all(requests, 'YMTD Child Data').then(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 1),
            _ref6$ = _ref6[0],
            entries = _ref6$ === undefined ? [] : _ref6$;

        var mapped = {};
        var dataRows = [];
        var stlyDataRows = [];

        entries = entries.rejectBy('subContentType', 'FULL_SPAN');
        var weekToDateFlag = _this2.get('isWeekToDate');

        var settings = _this2.get('customer.settings');
        entries.forEach(function (e) {
          var date = (0, _moment.default)(e.dataValues['endDate']);

          if ((0, _moment.default)(date.format('YYYY-MM-DD')).isSameOrBefore((0, _moment.default)(asOfEndDate.format('YYYY-MM-DD')))) {
            var key = date.format();

            mapped[key] = e.dataValues;
            //add the slice
            mapped[key]['slice'] = key;
            mapped[key]['date'] = (0, _dateFormatter.dateFormatter)(date, { settings: settings });
            mapped[key]['currencyCode'] = e.currencyCode;

            // If week over week selected, put the correct values in the field being used
            if (weekToDateFlag) {
              if (e.dataValues['eodYesterdayStlyUsageMinusNoShows']) {
                e.dataValues['stlyUsageMinusNoShows'] = e.dataValues['eodYesterdayStlyUsageMinusNoShows'];
              }
              if (e.dataValues['eodYesterdayStlyIncreaseUsage']) {
                e.dataValues['stlyIncreaseUsageMinusNoShows'] = e.dataValues['eodYesterdayStlyIncreaseUsage'];
              }

              if (e.dataValues['eodYesterdayStlyBase']) {
                e.dataValues['stlyBase'] = e.dataValues['eodYesterdayStlyBase'];
              }
              if (e.dataValues['eodYesterdayStlyIncreaseBase']) {
                e.dataValues['stlyIncreaseBase'] = e.dataValues['eodYesterdayStlyIncreaseBase'];
              }

              if (e.dataValues['eodYesterdayStlyGross']) {
                e.dataValues['stlyGross'] = e.dataValues['eodYesterdayStlyGross'];
              }
              if (e.dataValues['eodYesterdayStlyIncreaseGross']) {
                e.dataValues['stlyIncreaseGross'] = e.dataValues['eodYesterdayStlyIncreaseGross'];
              }

              if (e.dataValues['eodYesterdayStlyBaseYield']) {
                e.dataValues['stlyBaseYield'] = e.dataValues['eodYesterdayStlyBaseYield'];
              }
              if (e.dataValues['eodYesterdayStlyIncreaseBaseYield']) {
                e.dataValues['stlyIncreaseBaseYield'] = e.dataValues['eodYesterdayStlyIncreaseBaseYield'];
              }

              if (e.dataValues['eodYesterdayStlyGrossYield']) {
                e.dataValues['stlyGrossYield'] = e.dataValues['eodYesterdayStlyGrossYield'];
              }
              if (e.dataValues['eodYesterdayStlyIncreaseGrossYield']) {
                e.dataValues['stlyIncreaseGrossYield'] = e.dataValues['eodYesterdayStlyIncreaseGrossYield'];
              }
            }

            // Need 'copies' so we can set amounts
            var rowValues = Ember.merge({}, mapped[key]);
            var stlyValues = Ember.merge({}, mapped[key]);

            // Populate the rows for each table.  Will reuse dataRows for the delta values
            dataRows.push({ label: (0, _dateFormatter.dateFormatter)(date, { settings: settings }), values: rowValues });
            stlyDataRows.push({ label: (0, _dateFormatter.dateFormatter)(e.dataValues['endDateStly'], { settings: settings }), values: stlyValues });
          }
        });

        var reverseDateSort = function reverseDateSort(o1, o2) {
          var date1 = (0, _moment.default)(o1.values.endDate);
          var date2 = (0, _moment.default)(o2.values.endDate);

          return date1.isBefore(date2) ? 1 : date1.isAfter(date2) ? -1 : 0;
        };

        dataRows.sort(reverseDateSort);
        stlyDataRows.sort(reverseDateSort);

        Ember.set(row, 'children', dataRows);

        var linkedRow = (_this2.get('stlyTableData.groups.firstObject.rows') || []).findBy('values.endDate', row.values.endDate);
        if (linkedRow) {
          Ember.set(linkedRow, 'children', stlyDataRows);
        }
      }).finally(function () {
        return _this2._finalizeChildLoad(row);
      });
    },


    _groups: Ember.computed('currentTableData', 'highlightedRowIndex', function () {
      return this.get('currentTableData.groups') || [];
    }),

    columns: Ember.computed('currentTableData', function () {
      return this.get('currentTableData.columns') || [];
    }),

    actions: {
      exportCsv: function exportCsv() {
        var _this3 = this;

        this.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('yield-manager.message.download_requested'), 'success');
        var tz = this.get('tz');
        var customerID = this.get('customerID');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var asOfStartDate = this.get('asOfStartDate');

        var settings = this.get('customer.settings');
        var filename = this.get('i18n').t('yield-manager.file-prefix.ymtd') + '_' + this.get('customerExternalReference') + '_' + (0, _dateFormatter.dateFormatter)(startDate, { settings: settings }) + '_' + (0, _dateFormatter.dateFormatter)(endDate, { settings: settings }) + '.csv';

        var params = {
          customerid: customerID,
          begindateStr: startDate.format('YYYY-MM-DD'),
          enddateStr: endDate.format('YYYY-MM-DD'),
          asOfDateBeginStr: asOfStartDate.format('YYYY-MM-DD'),
          asOfDateEndStr: endDate.format('YYYY-MM-DD'),
          aggregationType: this.get('aggregationType'),
          rollupStatus: this.get('rollupStatus'),
          aggregate: false,
          groupBy: 'ALL'
        };

        this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          headers: { accept: 'text/csv' },
          contentType: 'application/json',
          dataType: 'blob',
          data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
        }).then(function (data) {
          return _this3.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          return _this3.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this3.get('i18n').t('yield-manager.message.download_error'), 'danger');
        });
      },
      rowMouseOut: function rowMouseOut(index) {
        if (this.get('rowMouseOut')) {
          this.get('rowMouseOut')(index);
        }
      },
      selectChildren: function selectChildren(row) {
        this._fetchChildData(row);
      },
      hideChildren: function hideChildren(row) {
        this._closeDrawers(row);
      }
    }
  });
});