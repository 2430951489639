define('ux-pcc/routes/customer/performance/bookings', ['exports', 'ux-pcc/mixins/routes/customer/performance'], function (exports, _performance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_performance.default, {
    model: function model() {
      var _this = this;

      var customer = this.modelFor('customer');
      var settings = customer.get('settings').toArray() || [];
      // Get the customer setting for the rollup channels to pass to the channel fetch call
      var channelCodes = settings.findBy("settingCode", "ROLLUP_CHANNEL_LIST");
      // Get the customer setting for the root channel to pass to the channel fetch call
      var rootChannel = settings.findBy('settingCode', 'ROOT_CHANNEL');

      // Strip out any spaces
      if (channelCodes && channelCodes.get('settingValue').length > 0) {
        channelCodes = channelCodes.get('settingValue').replace(/\s/g, "");
      } else {
        // Set some defaults for now
        channelCodes = 'PMS,WEBSITE,CALL_CENTER,OTA,GDS';
      }
      // set root channel from setting
      if (rootChannel && rootChannel.get('settingValue')) {
        rootChannel = rootChannel.get('settingValue');
      } else {
        rootChannel = 'ELECTRONIC';
      }

      var promises = [];

      promises.push(this.get('store').query('channel', {
        customerID: customer.get('id'),
        parentChannelCode: rootChannel,
        scope: 'CUSTOMER',
        filter: channelCodes
      }), this.get('store').query('code', {
        customerID: customer.get('id'),
        codeType: 'MARKET_SEGMENT'
      }));

      return Ember.RSVP.all(promises).then(function (results) {
        var rollupCodes = channelCodes.split(",");

        var channels = [];
        var data = results[0];
        data.forEach(function (channel) {
          channels.push({
            key: channel.get('channelCode'),
            label: channel.get('shortDescription'),
            orderBy: rollupCodes.indexOf(channel.get('channelCode'))
          });
        });

        var segments = [];
        data = results[1];
        data.forEach(function (segment) {
          segments.push({
            key: segment.get('shortDescription'),
            format: "formatNumber",
            label: segment.get('shortDescription'),
            orderBy: segment.get("orderBy")
          });
        });

        // Add General to the list
        segments.push({
          key: 'GENERAL',
          format: "formatNumber",
          label: '' + _this.get('i18n').t('customer.performance.general_segment'),
          orderBy: '9999'
        });

        return {
          channels: channels.sortBy('orderBy'),
          marketSegments: segments.sortBy('orderBy')
        };
      });
    }
  });
});