define('ux-pcc/components/copy-to-success-dialog', ['exports', 'ux-components/components/copy-to-success-dialog'], function (exports, _copyToSuccessDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _copyToSuccessDialog.default;
    }
  });
});