define('ux-pcc/components/popover-context-menu/context-item-icon', ['exports', 'ux-components/components/popover-context-menu/context-item-icon'], function (exports, _contextItemIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _contextItemIcon.default;
    }
  });
});