define('ux-pcc/helpers/format-address', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatAddress = formatAddress;
  function formatAddress(params /*, hash*/) {
    var address = params[0];
    var options = params[1] || {};
    if (!address) {
      return "";
    }

    // TODO US1862 map international
    // TODO convert this to a handlebars template
    var compiled = (0, _lodash.template)('<div class=\'adr ' + (options.inline ? "adr-inline" : "") + '\'>\n    <div class=\'street-address\'><%= address.get(\'address1\') %></div>\n    <div class=\'extended-address\'><%= address.get(\'address2\') %></div>\n    <div class=\'post-office-box\'><%= address.get(\'address3\') %></div>\n    <div>\n      <span class=\'locality\'><%= address.get(\'city\') %></span>\n      <span class=\'region\'><%= address.get(\'city\') ? "," : "" %> <%= address.get(\'state\') %></span>\n      <span class=\'postal-code\'> <%= address.get(\'postalCode\') %></span>\n    </div>\n    <div class=\'country-name\'><%= address.get(\'country\') || address.get(\'countryCode\') %></div>\n  </div>');

    var temp = compiled({ 'address': address, 'options': options });

    return Ember.String.htmlSafe(temp);
  }

  exports.default = Ember.Helper.helper(formatAddress);
});