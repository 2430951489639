define('ux-pcc/models/marketing-campaign-response', ['exports', 'ux-data/models/marketing-campaign-response'], function (exports, _marketingCampaignResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marketingCampaignResponse.default;
    }
  });
});