define('ux-pcc/routes/yield-manager/analyze/index', ['exports', 'ux-components/mixins/routes/full-screen'], function (exports, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fullScreen.default, {

    permission: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      var fullView = this.get('permission').isPermitted('ym-analyze', 'view', 'edit');
      if (!fullView) {
        controller.set('tableModeOnly', true);
        controller.set('mode', 'tables');
        if (Ember.isEmpty(controller.get('tab'))) {
          controller.set('tab', 'ORGANIZATION');
        }
      }

      this._super(controller, model);
    }
  });
});