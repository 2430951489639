define('ux-pcc/controllers/rules/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['ruleStatus', 'ruleType'],

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ruleStatus: 'current',
    ruleType: '',

    filteredRules: Ember.computed('model.rules.[]', 'ruleStatus', 'ruleType', function () {
      var _this = this;

      var rules = this.model.rules || [];

      var today = _moment.default.tz(this.tz.customer).startOf('day');
      if (this.ruleStatus === 'completed') {
        // return rules with endDate before today
        rules = rules.filter(function (rule) {
          return today.isAfter(_moment.default.tz(Ember.get(rule, 'endDate') || undefined, _this.tz.customer));
        });
      } else {
        // return rules with endDate today or greater or null
        rules = rules.filter(function (rule) {
          return today.isSameOrBefore(_moment.default.tz(Ember.get(rule, 'endDate') || undefined, _this.tz.customer));
        });
      }

      if (this.ruleType) {
        rules = rules.filterBy('ruleType', this.ruleType);
      }

      return rules;
    })
  });
});