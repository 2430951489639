define('ux-pcc/models/price-calendar-price', ['exports', 'ux-data/models/price-calendar-price'], function (exports, _priceCalendarPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _priceCalendarPrice.default;
    }
  });
});