define('ux-pcc/helpers/format-duration', ['exports', 'ux-components/helpers/format-duration'], function (exports, _formatDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatDuration.default;
    }
  });
  Object.defineProperty(exports, 'formatDuration', {
    enumerable: true,
    get: function () {
      return _formatDuration.formatDuration;
    }
  });
});