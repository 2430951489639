define('ux-pcc/controllers/rule-rates/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    saveLabel: 'rule._form.save_rule_rate',
    successMessage: 'rule._form._success_modal.success_update_rate_rule_message',
    ruleNameLabel: 'rule._form.rule_rate_name',
    ruleDescriptionLabel: 'rule._form.rule_rate_description',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ruleRates: Ember.inject.controller('rule-rates'),
    defaultPriceCode: Ember.computed.readOnly('ruleRates.defaultPriceCode'),
    prices: Ember.computed.readOnly('ruleRates.model.prices')

  });
});