define('ux-pcc/components/rules/conditions/channel-occ-contrib-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values', 'ember-changeset-validations/validators'], function (exports, _ruleConditionValues, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    store: Ember.inject.service(),

    type: 'percent',

    label: Ember.computed('i18n.locale', function () {
      return this.i18n.t('pcc-components.rules.conditions.pct_of_total_occupancy');
    }),

    validations: Ember.computed('i18n.locale', function () {
      return {
        filterValueRule: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.i18n.t('rule._form_modal.no_channel').toString()
        })
      };
    }),

    filterValueRuleOptions: Ember.computed('customer.id', function () {
      var customer = this.get('customer');
      var settings = customer.get('settings').toArray() || [];
      // Get the customer setting for the rollup channels to pass to the channel fetch call
      var channelCodes = settings.findBy('settingCode', 'ROLLUP_CHANNEL_LIST');
      // Get the customer setting for the root channel to pass to the channel fetch call
      var rootChannel = settings.findBy('settingCode', 'ROOT_CHANNEL');

      // Strip out any spaces
      if (channelCodes && channelCodes.get('settingValue').length > 0) {
        channelCodes = channelCodes.get('settingValue').replace(/\s/g, '');
      } else {
        // Set some defaults for now
        channelCodes = 'PMS,WEBSITE,CALL_CENTER,OTA,GDS';
      }
      // set root channel from setting
      if (rootChannel && rootChannel.get('settingValue')) {
        rootChannel = rootChannel.get('settingValue');
      } else {
        rootChannel = 'ELECTRONIC';
      }

      var promises = [];

      promises.push(this.store.query('channel', {
        customerID: customer.get('id'),
        parentChannelCode: rootChannel,
        scope: 'CUSTOMER',
        filter: channelCodes
      }));

      var rollupCodes = channelCodes.split(',');

      return Ember.RSVP.all(promises).then(function (results) {
        var channels = [];
        results[0].forEach(function (channel) {
          channels.push({
            val: channel.get('channelCode'),
            label: channel.get('shortDescription'),
            orderBy: rollupCodes.indexOf(channel.get('channelCode'))
          });
        });

        // Sort them in the order they were in the customer setting
        channels = channels.sortBy('orderBy');

        return channels;
      });
    })
  });
});