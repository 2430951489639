define('ux-pcc/components/channel/restatus-history-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    alert: null,
    isExpanded: false,
    prices: null,
    products: null,

    childCount: Ember.computed('alert.{status,children.@each.status}', function () {
      var totalChildren = (this.alert.children || []).length;
      var matchedChildren = (this.alert.children || []).filterBy('status', 'OK').length;
      return this.i18n.t('channel-restatus.child_count', { processed: matchedChildren, total: totalChildren });
    }),

    children: Ember.computed('alert.children', function () {
      var _this = this;

      var children = this.get('alert.children');

      if (!children) {
        return null;
      }

      var prices = this.get('prices');
      var products = this.get('products');

      var getStatus = function getStatus(status) {
        switch (status) {
          case 'OK':
            return _this.i18n.t('channel-restatus.completed');
          case 'ERROR':
          case 'FAILED':
            return _this.i18n.t('channel-restatus.failed');
          default:
            return _this.i18n.t('channel-restatus.pending');
        }
      };

      return children.map(function (child) {
        var payloadObj = JSON.parse(child.payload);

        child['txStatus'] = getStatus(child.status);
        if (child.status === 'ERROR' || child.status === 'FAILED') {
          child['failed'] = true;
          child['errorMessage'] = child.responseStatus;
          child['errorTxnID'] = '[ID: ' + child.responseValue + ']';
        }

        switch (child.referenceValue) {
          case 'INVENTORY':
            {
              var product = products.findBy('id', payloadObj.productID);
              if (product) {
                child['productDesc'] = product.externalReference + ' - ' + product.channelShortDescription;
              }
              break;
            }
          case 'PRICE_AMOUNT':
          case 'RESTRICTION':
            {
              var price = prices.findBy('id', payloadObj.priceID);
              if (price) {
                child['priceDesc'] = '' + price.externalReference;
              }
              var priceProduct = products.findBy('id', payloadObj.productID);
              if (priceProduct) {
                child['productDesc'] = '' + priceProduct.externalReference;
              }
              break;
            }
          case 'PRODUCT':
            {
              var _product = products.findBy('id', payloadObj.productID);
              if (_product) {
                child['productDesc'] = '' + _product.externalReference;
              }
              break;
            }
          case 'PRICE_HEADER':
            {
              var _price = prices.findBy('id', payloadObj.priceID);
              if (_price) {
                child['priceDesc'] = '' + _price.externalReference;
              }
              break;
            }
          case 'PRICE_PRODUCT':
            {
              var _price2 = prices.findBy('id', payloadObj.priceID);
              if (_price2) {
                child['priceDesc'] = '' + _price2.externalReference;
              }
              var _product2 = products.findBy('id', payloadObj.productID);
              if (_product2) {
                child['productDesc'] = '' + _product2.externalReference;
              }
              break;
            }
        }
        child['payloadObj'] = payloadObj;
        return child;
      });
    }),

    didRender: function didRender() {
      if (this.get('isExpanded')) {
        //expand all the textareas to fit the size of the content
        Ember.$('textarea').each(function () {
          Ember.$(this).height(Ember.$(this).prop('scrollHeight'));
        });
      }
    },


    actions: {
      openSummary: function openSummary() {
        this.toggleProperty('isExpanded');
      }
    }
  });
});