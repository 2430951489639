define('ux-pcc/components/rule-rates/rule-form', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'ux-components/utils/to-boolean', 'moment'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _toBoolean, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({

    tagName: '',

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    i18n: Ember.inject.service(),

    origPrice: null,

    isMobile: false,

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.selectedConditions) {
        this.set('selectedConditions', []);
      }
    },


    baseValidations: Ember.computed('i18n.locale', function () {

      return {
        ruleType: (0, _validators.validatePresence)(true),
        shortDescription: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rule_name').toString()
        }),
        beginDate: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_begin_date').toString()
        })
      };
    }),

    // At some point, when ember-changeset handles hasMany relationships correctly, the child rules
    // should be thrown out when isCeiling changes.
    variableValidations: Ember.computed('isCeiling', 'i18n.locale', function () {

      var validations = {
        children: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.i18n.t('rule._form.missing_condition').toString()
        })
      };

      if (!this.get('isCeiling')) {
        validations.price = (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rate_code').toString()
        });
      }

      return validations;
    }),

    baseChangeset: Ember.computed('model', 'baseValidations', function () {
      var validations = this.get('baseValidations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    variableChangeset: Ember.computed('model', 'variableValidations', function () {
      var validations = this.get('variableValidations');
      var changeset = new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);

      changeset.set('children', [].concat(_toConsumableArray(this.get('model.children'))));

      var json = this.get('model.filterValue');

      if (!json) {
        changeset.set('filterValue', Ember.Object.create({
          userTokens: [],
          emailAlert: false,
          textAlert: false,
          level: 'NOTIFY_INFO'
        }));
      } else {
        changeset.set('filterValue', Ember.Object.create(JSON.parse(json)));
      }

      return changeset;
    }),

    ongoingLabel: Ember.computed('i18n.locale', function () {
      return this.i18n.t('rule._form.ongoing');
    }),

    isShowCeiling: Ember.computed('customer.settings', function () {
      var ceilingSetting = (this.get('customer.settings') || []).findBy('settingCode', 'DISPLAY_CEILING');
      if (ceilingSetting) {
        return (0, _toBoolean.default)(ceilingSetting.get('settingValue'));
      }
      return false;
    }),

    isAdjustment: Ember.computed.equal('baseChangeset.ruleType', 'BASED_ON_ADJUSTMENT'),
    isRestriction: Ember.computed.equal('baseChangeset.ruleType', 'RESTRICTION'),
    isCeiling: Ember.computed.equal('baseChangeset.ruleType', 'CEILING'),

    noTypeSelected: Ember.computed.empty('baseChangeset.ruleType'),
    noChildren: Ember.computed.equal('variableChangeset.children.length', 0),

    defaultCurrencyCode: Ember.computed('customer.settings', function () {
      var currency = this.get('customer.settings').findBy('settingCode', 'DEFAULT_CURRENCY');
      if (currency) {
        return currency.get('settingValue');
      } else {
        return 'USD';
      }
    }),

    _linkRule: function _linkRule(rule) {
      var _this = this;

      this.ajax.put('/rest/v1/rules/' + rule.get('id') + '/link', {
        contentType: 'application/x-www-form-urlencoded',
        data: {
          reference: 'price',
          referenceID: this.get('variableChangeset.price.id'),
          referenceName: rule.get('ruleType')
        }
      }).catch(function (error) {
        _this.get('flash')('<i class=\'fa fa-warning\'></i> ' + (error.responseText || error.status) + ' ' + error.statusText, 'danger');
      });
    },
    _unlinkRule: function _unlinkRule(rule, refID) {
      var _this2 = this;

      this.ajax.put('/rest/v1/rules/' + rule.get('id') + '/unlink', {
        contentType: 'application/x-www-form-urlencoded',
        data: {
          reference: 'price',
          referenceID: refID
        }
      }).catch(function (error) {
        _this2.get('flash')('<i class=\'fa fa-warning\'></i> ' + (error.responseText || error.status) + ' ' + error.statusText, 'danger');
      });
    },
    rateCodeFilter: function rateCodeFilter(item) {
      return item.get('sellYN');
    },
    discountFilter: function discountFilter(item) {
      return item.get('sellYN') && item.get('basedOnPrice.id');
    },
    _getRuleTypeLabel: function _getRuleTypeLabel() {
      var ruleType = this.get('baseChangeset.ruleType');
      switch (ruleType) {
        case 'BASED_ON_ADJUSTMENT':
          return this.i18n.t('rule._form.discount');
        case 'RESTRICTION':
          return this.i18n.t('rule._form.restriction');
        case 'CEILING':
          return this.i18n.t('rule._form.ceiling');
      }
    },


    _setCeilingRate: Ember.observer('isCeiling', function () {
      if (this.get('isCeiling')) {
        var defaultPriceCode = this.get('defaultPriceCode');
        var defaultPrice = this.get('prices' || []).findBy('externalReference', defaultPriceCode);
        if (defaultPrice) {
          this.set('variableChangeset.price', defaultPrice);
        }
      } else {
        this.set('variableChangeset.price', null);
      }
    }),

    actions: {
      addRule: function addRule() {
        var _this3 = this;

        var baseChangeset = this.get('baseChangeset');

        Ember.RSVP.all([baseChangeset.validate()], 'validating changesets').then(function () {

          // all validations pass
          if (baseChangeset.get('isValid')) {

            var rule = {
              ruleMethod: 'RULE',
              filterType: 'ANY',
              day1: true,
              day2: true,
              day3: true,
              day4: true,
              day5: true,
              day6: true,
              day7: true,
              children: [{
                filterType: 'ALL',
                ruleMethod: 'FILTER',
                children: [{
                  filterType: 'FIELD_WHERE',
                  ruleMethod: 'FILTER'
                }]
              }]
            };

            _this3.get('variableChangeset').set('children', [].concat(_toConsumableArray(_this3.get('variableChangeset.children')), [rule]));

            var rateCode = _this3.get('isCeiling') ? _this3.i18n.t('rule-rates._form_modal.bar') : _this3.get('variableChangeset.price.externalReference');

            _this3.get('showModal')('rule/-modal', {
              rule: rule,
              ruleType: _this3.get('baseChangeset.ruleType'),
              beginDate: _this3.get('baseChangeset.beginDate'),
              endDate: _this3.get('baseChangeset.endDate'),
              headerMessage: _this3.i18n.t('rule-rates._form_modal.header_message', {
                rateCode: rateCode,
                htmlSafe: true
              }), // G6 prefers BAR to RACK; todo: US3910 update G6 price loader to set BAR for RACK's shortDescription/externalReference2? (other customers won't necessarily want BAR displayed)
              successMessage: _this3.i18n.t('rule-rates._form_modal._success_modal.success_message', {
                rateCode: rateCode,
                htmlSafe: true
              }),
              onNewCondition: function onNewCondition() {
                _this3.send('addRule');
              },
              onRemoveCondition: function onRemoveCondition(rule) {
                _this3.send('removeCondition', rule);
              }
            });
          }
        });
      },
      editRule: function editRule(rule) {
        var _this4 = this;

        var rateCode = this.get('isCeiling') ? this.i18n.t('rule-rates._form_modal.bar') : this.get('variableChangeset.price.externalReference');

        this.get('variableChangeset').set('children', [].concat(_toConsumableArray(this.get('variableChangeset.children'))));

        this.get('showModal')('rule/-modal', {
          rule: rule,
          ruleType: this.get('baseChangeset.ruleType'),
          beginDate: this.get('baseChangeset.beginDate'),
          endDate: this.get('baseChangeset.endDate'),
          headerMessage: this.i18n.t('rule-rates._form_modal.header_message', {
            rateCode: rateCode,
            htmlSafe: true
          }),
          successMessage: this.i18n.t('rule-rates._form_modal._success_modal.success_message', {
            rateCode: rateCode,
            htmlSafe: true
          }),
          onNewCondition: function onNewCondition() {
            _this4.send('addRule');
          },
          onRemoveCondition: function onRemoveCondition(rule) {
            _this4.send('removeCondition', rule);
          }
        });
      },
      save: function save() {
        var _this5 = this;

        var baseChangeset = this.get('baseChangeset');
        var variableChangeset = this.get('variableChangeset');

        Ember.RSVP.all([baseChangeset.validate(), variableChangeset.validate()], 'validating changesets').then(function () {

          // all validations pass
          if (baseChangeset.get('isValid') && variableChangeset.get('isValid')) {

            baseChangeset.execute();
            variableChangeset.execute();

            // Do the Actual save
            var model = _this5.get('model');
            var beginDate = (0, _moment.default)(model.get('beginDate')).utc().format('YYYY-MM-DD');
            var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
            model.set('beginDate', _moment.default.tz(beginDate, _this5.get('tz.customer')).startOf('day'));

            if (_this5.get('isOngoing')) {
              // Set endDate to null
              model.set('endDate', null);
            } else {
              model.set('endDate', endDate ? _moment.default.tz(endDate, _this5.get('tz.customer')).startOf('day') : undefined);
            }

            model.set('filterValue', JSON.stringify(model.get('filterValue')));

            model.save().then(function () {
              // Link the rate code to the rule in the rule_xref table
              if (!_this5.get('origPrice.id')) {
                _this5._linkRule(model);
              } else if (_this5.get('origPrice.id') !== model.get('price.id')) {
                _this5._unlinkRule(model, _this5.get('origPrice.id'));
                _this5._linkRule(model);
              }

              // Grab the rule type label
              var ruleTypeLabel = _this5._getRuleTypeLabel();

              // Show the success modal
              _this5.dialog.showSuccess({
                titleText: _this5.i18n.t('rule._form._success_modal.title'),
                messageText: _this5.i18n.t(_this5.get('successMessage'), {
                  ruleName: ruleTypeLabel, rateName: _this5.get('isCeiling') ? _this5.i18n.t('rule-rates._form_modal.bar') : _this5.get('model.price.externalReference')
                }),
                confirmText: _this5.i18n.t('rule._form._success_modal.button_label'),
                onConfirm: function onConfirm() {
                  _this5.get('onSave')();
                  _this5.dialog.close();
                }
              });
            }).catch(function (error) {
              _this5.get('flash')('<i class=\'fa fa-warning\'></i> ' + error, 'danger');
            });
          }
        });
      },
      updateSelection: function updateSelection(condition, isSelected) {
        if (isSelected) {
          // set the notify flag within the condition
          var json = Ember.get(condition, 'filterValue');
          var filter = void 0;
          if (!json) {
            filter = Ember.Object.create({
              notify: true
            });
          } else {
            filter = Ember.Object.create(JSON.parse(json));
            filter.set('notify', true);
          }
          Ember.set(condition, 'filterValue', JSON.stringify(filter));
          this.get('selectedConditions').addObject(condition);
        } else {
          // clear the notify flag within the condition
          var _json = Ember.get(condition, 'filterValue');
          if (_json) {
            var _filter = Ember.Object.create(JSON.parse(_json));
            _filter.set('notify', null);
            Ember.set(condition, 'filterValue', JSON.stringify(_filter));
          }

          this.get('selectedConditions').removeObject(condition);
        }
      },
      removeCondition: function removeCondition(condition) {
        var children = [].concat(_toConsumableArray(this.get('variableChangeset.children')));
        children.removeObject(condition);
        this.get('variableChangeset').set('children', children);
      }
    }

  });
});