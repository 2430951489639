define('ux-pcc/components/customer/performance/matrix-pricing-copy-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    templateName: "components/customer/performance/matrix-pricing-copy-row",
    classNames: ['modal-panel__item-row', 'price-points-row'],

    isEditingAllocation: true,
    isEditing: false,
    pricePoint: null,
    previousPricing: null,
    viewOnly: false,
    previousPricePoint: Ember.computed(function () {
      var pricePoint = {};
      var previous = this.get('previousPricing.pricePoints').findBy('label', this.get('pricePoint.label'));
      if (previous) {
        pricePoint.value = previous.content.value;
        pricePoint.lead = previous.content.lead;
      }
      return pricePoint;
    }),
    hasPreviousPricePointLead: Ember.computed('previousPricePoint.lead', function () {
      return this.get('previousPricePoint.lead') || this.get('previousPricePoint.lead') === 0;
    }),
    hasPricePointLead: Ember.computed('pricePoint.lead', function () {
      return this.get('pricePoint.lead') || this.get('pricePoint.lead') === 0;
    }),
    parentController: null,
    isMobile: false,
    isNotMobile: Ember.computed.not('isMobile'),

    actions: {
      toggleIsEditing: function toggleIsEditing(type) {
        if (this.get('viewOnly')) {
          return;
        }
        this.get('parentController').toggleProperty('isCopying');
        if (!type) {
          this.set('isEditing', false);
        } else {
          this.set('isEditing', true);
          if (type === 'value') {
            this.set('isEditingAllocation', true);
          } else {
            this.set('isEditingAllocation', false);
          }
        }
      }
    }
  });
});