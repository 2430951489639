define('ux-pcc/components/nav-tabs/nav-tab', ['exports', 'ux-components/components/nav-tabs/nav-tab'], function (exports, _navTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _navTab.default;
    }
  });
});