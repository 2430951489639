define('ux-pcc/components/yield-manager/views/ymcd-view', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/components/yield-manager/views/base-view', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _baseView, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _baseView.default.extend(_fileSaver.default, {

    layoutName: 'components/yield-manager/views/base-view',

    leadColumnTitle: Ember.computed(function () {
      return this.get('i18n').t('yield-manager.on_rent_date');
    }),

    sliceResolution: 'day',

    // define the charts to be displayed
    dataFields: Ember.computed('data', function () {
      var grossFlag = this.get('isGrossValue');
      var displaySnapshot = this.get('displaySnapshot');
      return [Ember.Object.create({
        label: '<div><div>' + this.get('i18n').t('yield-manager.days') + '</div><div class=\'view-screen__chart-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        leftAxisTitle: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.rate_of_add') + ' %',
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-days.added_title'),
        axisSync: 'right',
        values: [{
          key: 'increaseUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }, {
          key: 'stlyIncreaseUsageMinusNoShows',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: 'number',
          lineClasses: 'days-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displaySnapshot ? 'snapshotUsage' : 'roaUsage',
          label: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.roa') + ' %',
          format: 'percent',
          lineClasses: 'days compare-shade',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          shade: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '<div><div>' + this.get('i18n').t('yield-manager.yield') + '</div><div class=\'view-screen__chart-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        leftAxisTitle: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.rate_of_add') + ' %',
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-yield.added_title'),
        axisSync: 'right',
        values: [{
          key: grossFlag ? 'grossYieldAdded' : 'baseYieldAdded',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'stlyGrossYieldAdded' : 'stlyBaseYieldAdded',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: 'currency',
          formatHash: this.get('formatYieldOptions'),
          lineClasses: 'yield-compare',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displaySnapshot ? grossFlag ? 'snapshotGrossYield' : 'snapshotBaseYield' : grossFlag ? 'roaGrossYield' : 'roaBaseYield',
          label: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.roa') + ' %',
          format: 'percent',
          lineClasses: 'yield compare-shade',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'roaYieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          shade: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '<div><div>' + this.get('i18n').t('yield-manager.revenue') + '</div><div class=\'view-screen__chart-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        leftAxisTitle: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.rate_of_add') + ' %',
        rightAxisTitle: '' + this.get('i18n').t('dashboard-widget.build-revenue.added_title'),
        axisSync: 'right',
        values: [{
          key: grossFlag ? 'increaseGross' : 'increaseBase',
          label: this.get('i18n').t('yield-manager.view.add_current_year'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'right'
        }, {
          key: grossFlag ? 'stlyIncreaseGross' : 'stlyIncreaseBase',
          label: this.get('i18n').t('yield-manager.view.add_prior_year'),
          format: 'currency',
          formatHash: this.get('formatRevenueOptions'),
          lineClasses: 'revenue-compare',
          chartType: 'column',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue-compare',
          enabled: true,
          category: 'right'
        }, {
          key: displaySnapshot ? grossFlag ? 'snapshotGross' : 'snapshotBase' : grossFlag ? 'pctChangeRoaGross' : 'pctChangeRoaBase',
          label: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.roa') + ' %',
          format: 'percent',
          lineClasses: 'revenue compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'roaRevenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          shade: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '<div><div>' + this.get('i18n').t('yield-manager.view.percent_var') + '</div><div class=\'view-screen__chart-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        leftAxisTitle: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        values: [{
          key: 'pctChangeUsage',
          label: this.get('i18n').t('yield-manager.view.days_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.days_variance'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.yield_variance'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
          label: this.get('i18n').t('yield-manager.view.revenue_var'),
          legendLabel: this.get('i18n').t('yield-manager.view.revenue_variance'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        label: '<div><div>' + this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.roa') + '</div><div class=\'view-screen__chart-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        leftAxisTitle: this.get('i18n').t(displaySnapshot ? 'yield-manager.snapshot' : 'yield-manager.roa') + ' %',
        rightAxisTitle: '' + this.get('i18n').t('yield-manager.view.percent_var'),
        axisSync: 'right',
        values: [{
          key: displaySnapshot ? 'snapshotUsage' : 'roaUsage',
          label: '' + this.get('i18n').t(displaySnapshot ? 'yield-manager.view.days_snapshot' : 'yield-manager.view.days_roa'),
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: displaySnapshot ? grossFlag ? 'snapshotGrossYield' : 'snapshotBaseYield' : grossFlag ? 'roaGrossYield' : 'roaBaseYield',
          label: '' + this.get('i18n').t(displaySnapshot ? 'yield-manager.view.yield_snapshot' : 'yield-manager.view.yield_roa'),
          format: 'percent',
          lineClasses: 'yield',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          category: 'left'
        }, {
          key: displaySnapshot ? grossFlag ? 'snapshotGross' : 'snapshotBase' : grossFlag ? 'roaGross' : 'roaBase',
          label: '' + this.get('i18n').t(displaySnapshot ? 'yield-manager.view.revenue_snapshot' : 'yield-manager.view.revenue_roa'),
          format: 'percent',
          lineClasses: 'revenue',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          category: 'left'
        }, {
          key: 'pctChangeUsage',
          label: this.get('i18n').t('yield-manager.view.days_variance'),
          format: 'percent',
          lineClasses: 'days compare-shade',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          shade: true,
          category: 'right'
        }, {
          key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
          label: this.get('i18n').t('yield-manager.view.yield_variance'),
          format: 'percent',
          lineClasses: 'yield compare-shade',
          chartType: 'line',
          bulletType: 'triangleUp',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--yield',
          enabled: true,
          shade: true,
          category: 'right'
        }, {
          key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
          label: this.get('i18n').t('yield-manager.view.revenue_variance'),
          format: 'percent',
          lineClasses: 'revenue compare-shade',
          chartType: 'line',
          bulletType: 'square',
          valueClassname: 'revenueClasses',
          legendClass: 'chart-color--revenue',
          enabled: true,
          shade: true,
          category: 'right'
        }]
      })];
    }),

    currentColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'dow',
        label: this.get('i18n').t('yield-manager.day'),
        groupColumnHeaderLabel: '',
        groupColumnHeaderSpan: 1
      }, {
        key: 'usage',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div></div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'grossYield' : 'baseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        key: grossFlag ? 'gross' : 'base',
        label: this.get('i18n').t('yield-manager.rev') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
        format: 'currency',
        formatHash: this.get('formatRevenueOptions')
      }, {
        key: 'increaseUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>' + this.get('i18n').t('yield-manager.added') + '</div><div class=\'group-column-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div>',
        groupColumnHeaderSpan: 2
      }, {
        key: grossFlag ? 'grossYieldAdded' : 'baseYieldAdded',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }];
    }),

    stlyColumns: Ember.computed('stlyData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'dow',
        label: this.get('i18n').t('yield-manager.day'),
        groupColumnHeaderLabel: '',
        groupColumnHeaderSpan: 1
      }, {
        key: 'stlyUsage',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>' + this.get('i18n').t('yield-manager.accumulated_title') + '</div></div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        key: grossFlag ? 'stlyGross' : 'stlyBase',
        label: this.get('i18n').t('yield-manager.rev') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
        format: 'currency',
        formatHash: this.get('formatRevenueOptions')
      }, {
        key: 'stlyIncreaseUsageMinusNoShows',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>' + this.get('i18n').t('yield-manager.added') + '</div><div class=\'group-column-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div></div>',
        groupColumnHeaderSpan: 2
      }, {
        key: grossFlag ? 'stlyGrossYieldAdded' : 'stlyBaseYieldAdded',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }];
    }),

    percentDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      return [{
        key: 'dow',
        label: this.get('i18n').t('yield-manager.day'),
        groupColumnHeaderLabel: '',
        groupColumnHeaderSpan: 1
      }, {
        key: 'pctChangeUsage',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'percent',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'>% ' + this.get('i18n').t('yield-manager.change') + '</div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'pctChangeGrossYield' : 'pctChangeBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'percent'
      }, {
        key: grossFlag ? 'pctChangeGross' : 'pctChangeBase',
        label: this.get('i18n').t('yield-manager.rev'),
        format: 'percent'
      }, {
        key: 'deltaUsage',
        label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
        format: 'number',
        groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>&nbsp;</div><div>' + this.get('i18n').t('yield-manager.actual') + '</div></div>',
        groupColumnHeaderSpan: 3
      }, {
        key: grossFlag ? 'deltaGrossYield' : 'deltaBaseYield',
        label: this.get('i18n').t('yield-manager.yield'),
        format: 'currency',
        formatHash: this.get('formatYieldOptions')
      }, {
        key: grossFlag ? 'deltaGross' : 'deltaBase',
        label: this.get('i18n').t('yield-manager.rev') + ' ' + (this.get('currencyCode') ? '(' + this.get('currencyCode') + ')' : ''),
        format: 'currency',
        formatHash: this.get('formatRevenueOptions')
      }];
    }),

    roaDeltaColumns: Ember.computed('tableData', function () {
      var grossFlag = this.get('isGrossValue');
      if (this.get('displaySnapshot')) {
        return [{
          key: 'snapshotUsage',
          label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>% ' + this.get('i18n').t('yield-manager.snapshot') + '</div><div class=\'group-column-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div><div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'snapshotGrossYield' : 'snapshotBaseYield',
          label: this.get('i18n').t('yield-manager.yield'),
          format: 'percent'
        }, {
          key: grossFlag ? 'snapshotGross' : 'snapshotBase',
          label: this.get('i18n').t('yield-manager.rev'),
          format: 'percent'
        }];
      } else {
        return [{
          key: 'roaUsage',
          label: '<div>' + this.get('i18n').t('yield-manager.actuals_minus_no_shows') + '</div>',
          format: 'percent',
          groupColumnHeaderLabel: '<div class=\'centered group-column-label\'><div>% ' + this.get('i18n').t('yield-manager.roa') + '</div><div class=\'group-column-sub-label\'>' + this.get('rateOfAddDescription') + '</div></div><div>',
          groupColumnHeaderSpan: 3
        }, {
          key: grossFlag ? 'roaGrossYield' : 'roaBaseYield',
          label: this.get('i18n').t('yield-manager.yield'),
          format: 'percent'
        }, {
          key: grossFlag ? 'roaGross' : 'roaBase',
          label: this.get('i18n').t('yield-manager.rev'),
          format: 'percent'
        }];
      }
    }),

    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      return version !== '1' ? 'DAILY_ACCUMULATION_COMPARISON_V2' : 'DAILY_ACCUMULATION_COMPARISON';
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customerID');
      var tz = this.get('tz');

      var startDate = _moment.default.tz(this.get('selectedDateParam'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('selectedEndDateParam'), tz).startOf('day');

      this.set('startDate', startDate);
      this.set('endDate', endDate);
      this.set('data', null);

      var params = {
        customerid: customerID,
        begindateStr: startDate.format('YYYY-MM-DD'),
        enddateStr: endDate.format('YYYY-MM-DD'),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        aggregate: false,
        groupBy: 'ALL'
      };

      var requests = [this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'YMCD Data').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            entries = _ref3$ === undefined ? [] : _ref3$;

        var mapped = {};
        var dataRows = [];
        var stlyDataRows = [];

        if (!entries || entries.length < 1) {
          _this.set('data', []);
          _this.set('tableData', []);
          _this.set('stlyData', []);
          return;
        }

        var settings = _this.get('customer.settings');
        entries = entries.rejectBy('subContentType', 'FULL_SPAN');
        entries.forEach(function (e) {
          var date = (0, _moment.default)(e.dataValues['date']);
          var key = date.format();

          mapped[key] = e.dataValues;
          //add the slice
          mapped[key]['slice'] = key;
          mapped[key]['currencyCode'] = e.currencyCode;
          mapped[key]['dow'] = date.format('ddd');

          // Need 'copies' so we can set amounts
          var rowValues = Ember.merge({}, mapped[key]);
          var stlyValues = Ember.merge({}, mapped[key]);
          stlyValues['dow'] = (0, _moment.default)(e.dataValues['stlyDate']).format('ddd');

          // Populate the rows for each table.  Will reuse dataRows for the delta values
          dataRows.push({ label: (0, _dateFormatter.dateFormatter)(date, { settings: settings }), values: rowValues });
          stlyDataRows.push({ label: (0, _dateFormatter.dateFormatter)(e.dataValues['stlyDate'], { settings: settings }), values: stlyValues });
        });

        var dateSort = function dateSort(o1, o2) {
          var date1 = (0, _moment.default)(o1.values.date);
          var date2 = (0, _moment.default)(o2.values.date);

          return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
        };

        dataRows.sort(dateSort);
        stlyDataRows.sort(dateSort);

        _this.set('data', mapped);
        _this.set('tableData', dataRows);
        _this.set('stlyData', stlyDataRows);
      }).finally(function () {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('loading', false);
        }
      });
    },


    currentRunDate: Ember.computed('tableData', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      var startDate = (0, _dateFormatter.dateFormatter)(this.get('selectedDateParam'), { tz: tz, settings: settings });
      return this.get('i18n').t('yield-manager.on_rent_header', { runDate: startDate });
    }),

    stlyRunDate: Ember.computed('stlyData', function () {
      var tz = this.get('tz');
      var settings = this.get('customer.settings');
      var startDate = (0, _dateFormatter.dateFormatter)(this.get('selectedStlyDateParam'), { tz: tz, settings: settings });
      return this.get('i18n').t('yield-manager.on_rent_header', { runDate: startDate });
    }),

    percentDeltaTitle: Ember.computed('tableData', function () {
      return '' + this.get('i18n').t('yield-manager.change');
    }),

    roaDeltaTitle: Ember.computed('tableData', function () {
      return '' + this.get('rateOfAddTypeDescription');
    }),

    actions: {
      exportCsv: function exportCsv() {
        var _this2 = this;

        this.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + this.get('i18n').t('yield-manager.message.download_requested'), 'success');
        var tz = this.get('tz');
        var customerID = this.get('customerID');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');

        var settings = this.get('customer.settings');
        var filename = this.get('i18n').t('yield-manager.file-prefix.ymcd') + '_' + this.get('customerExternalReference') + '_' + (0, _dateFormatter.dateFormatter)(startDate, { settings: settings }) + '_' + (0, _dateFormatter.dateFormatter)(endDate, { settings: settings }) + '.csv';

        var params = {
          customerid: customerID,
          begindateStr: startDate.format('YYYY-MM-DD'),
          enddateStr: endDate.format('YYYY-MM-DD'),
          asOfDateBeginStr: startDate.format('YYYY-MM-DD'),
          asOfDateEndStr: endDate.format('YYYY-MM-DD'),
          aggregationType: this.get('aggregationType'),
          rollupStatus: this.get('rollupStatus'),
          aggregate: false,
          groupBy: 'ALL'
        };

        this.get('ajax').post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          headers: { accept: 'text/csv' },
          contentType: 'application/json',
          dataType: 'blob',
          data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
        }).then(function (data) {
          return _this2.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          return _this2.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this2.get('i18n').t('yield-manager.message.download_error'), 'danger');
        });
      }
    }
  });
});