define('ux-pcc/utils/get-provider-from-name', ['exports', 'ux-data/utils/get-provider-from-name'], function (exports, _getProviderFromName) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getProviderFromName.default;
    }
  });
});