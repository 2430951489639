define('ux-pcc/routes/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('session.isAuthenticated')) {
        return this.session.invalidate().then(function () {
          if (_this.config.logoutUrl) {
            console.info('logout URL was configured. Redirecting to ' + _this.config.logoutUrl);
            window.location.replace(_this.config.logoutUrl);
          } else {
            // Abort this transition.  The app will automatically redirect to the login route when the session has been invalidated
            transition.abort();
          }
        });
      } else {
        this.transitionTo('login');
      }
    }
  });
});