define('ux-pcc/controllers/competitive-sets/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customer: Ember.inject.controller(),

    actions: {
      saveCompetitiveSet: function saveCompetitiveSet(model) {
        var _this = this;

        model.save().then(function () {
          _this.transitionToRoute('competitive-sets.list.detail', model.get('id'));
          _this.send('refresh');
          _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.i18n.t('competitive-set.-form.success', { competitor: model.get('name') }), 'success');
        }).catch(function (error) {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      },
      close: function close() {
        this.transitionToRoute('competitive-sets');
      },
      modal: function modal(modalName, model) {
        this.send('showModal', modalName, model);
      }
    }
  });
});