define('ux-pcc/controllers/rules-advanced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    permission: Ember.inject.service(),
    filteredTypes: Ember.computed('model.types', function () {
      var _this = this;

      var ruleTypes = this.get('model.types');
      var customerPermissionType = this.get('customer.permissionType');

      // Filter out the rule types the user is not allowed to see
      return ruleTypes.filter(function (item /*, index, enumerable*/) {
        return _this.get('permission').isPermitted(customerPermissionType + "ruletype", "view", item.type.toLowerCase());
      });
    })
  });
});