define('ux-pcc/controllers/tags/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    searchText: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelData', []);
      this.set('sort', ['externalReference']);
    },


    modelObserver: Ember.observer('model.[]', function () {
      var modelArr = Ember.A();
      modelArr.addObjects(this.get('model') || []);
      this.set('modelData', modelArr);
    }),

    bloodhound: Ember.computed('model', function () {
      var objects = this.get('model') || [];

      // constructs the suggestion engine
      return new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return [].concat(Bloodhound.tokenizers.whitespace(d.name)).concat(Bloodhound.tokenizers.whitespace(d.externalReference)).concat(Bloodhound.tokenizers.whitespace(d.externalReferenceNumber));
        },
        limit: 1000,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: objects.map(function (customer) {
          return {
            id: customer.get('id'),
            name: customer.get('name'),
            externalReference: customer.get('externalReference'),
            externalReferenceNumber: customer.get('externalReference').replace(/[^0-9.]/g, ''),
            object: customer
          };
        })
      });
    }),

    processSuggestions: Ember.observer('searchText', function () {
      var results = Ember.A();

      if (this.get('searchText') !== '') {
        var bloodhound = this.get('bloodhound');
        bloodhound.search(this.get('searchText'), function (suggestions) {
          return suggestions.forEach(function (suggestion) {
            return results.pushObject(suggestion.object);
          });
        });
      } else {
        results.addObjects(this.get('model'));
      }

      this.set('modelData', results);
    })
  });
});