define('ux-pcc/components/sister-properties/property-panel', ['exports', 'accounting'], function (exports, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    property: null,

    tagName: '',

    isExpanded: false,

    adjustmentClasses: Ember.computed('property.modifier', function () {
      return this.get('property.modifier') >= 0 ? this.get('property.modifier') > 0 ? 'brand-success' : '' : 'brand-danger';
    }),

    formattedOccupancy: Ember.computed('property.occupancy', function () {
      return _accounting.default.formatNumber(this.get('property.occupancy'), 1);
    }),

    actions: {
      confirmDelete: function confirmDelete(object) {
        this.get('onConfirmDelete')(object);
      },
      openSummary: function openSummary(object) {
        var _this = this;

        if (this.get('isExpanded')) {
          this.send('closeSummary');
        } else {
          this.set('isExpanded', false);
          Ember.run.later(function () {
            _this.set('model', object);
            _this.set('isExpanded', true);
          }, 1);
        }
      },
      closeSummary: function closeSummary() {
        this.set('isExpanded', false);
      },
      edit: function edit(object) {
        this.get('onEdit')(object);
      }
    }
  });
});