define('ux-pcc/validations/at-least-one-presence', ['exports', 'ux-data/validations/at-least-one-presence'], function (exports, _atLeastOnePresence) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _atLeastOnePresence.default;
    }
  });
});