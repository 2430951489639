define('ux-pcc/components/portfolios/portfolio-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    parameter: null,

    tagName: '',

    isExpanded: false,
    isMobile: Ember.computed.bool('deviceType.mobile'),

    actions: {
      confirmDelete: function confirmDelete(object) {
        this.confirmDelete(object);
      },
      confirmUnlink: function confirmUnlink(parent, child) {
        this.confirmUnlink(parent, child);
      },
      openSummary: function openSummary() {
        var _this = this;

        if (this.get('isExpanded')) {
          this.send('closeSummary');
        } else {
          this.set('isExpanded', false);
          Ember.run.later(function () {
            _this.set('isExpanded', true);
          }, 1);
        }
      },
      closeSummary: function closeSummary() {
        this.set('isExpanded', false);
      }
    }
  });
});