define('ux-pcc/controllers/customer/performance/data-metrics', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(Ember.Evented, {
    metricDate: Ember.computed(function () {
      var tz = this.tz.get("customer");
      return _moment.default.tz(tz).startOf('day');
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    })

  });
});