define('ux-pcc/components/customer/performance/matrix/event-popout', ['exports', 'ux-pcc/templates/components/customer/performance/matrix/event-popout'], function (exports, _eventPopout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _eventPopout.default,

    tagName: ''
  });
});