define('ux-pcc/controllers/reservations/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    searchText: Ember.computed('isMobile', function () {
      return this.get('isMobile') ? this.i18n.t("reservations.index.search_placeholder.mobile") : this.i18n.t("reservations.index.search_placeholder.full");
    }),

    noResultDatesText: Ember.computed('loading', 'checkInVariation', function () {
      return this.get('checkInVariation') === 0 ? this.i18n.t("reservations.index.searched_date") : this.i18n.t("reservations.index.searched_dates");
    }),

    currencyCode: Ember.computed('customer.settings', function () {
      var model = this.get("customer.settings").findBy("settingCode", "DEFAULT_CURRENCY");
      if (model && model.get("settingValue")) {
        return model.get("settingValue");
      } else {
        return "USD";
      }
    })

  });
});