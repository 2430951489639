define('ux-pcc/validations/no-cc', ['exports', 'ux-data/validations/no-cc'], function (exports, _noCc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _noCc.default;
    }
  });
});