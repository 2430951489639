define('ux-pcc/components/dashboard-widget/weekly-overview-chart', ['exports', 'accounting', 'moment', 'ux-components/utils/moment-range'], function (exports, _accounting, _moment, _momentRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    isMobile: Ember.computed.bool('deviceType.mobile'),
    _minWeeklyIndex: 0,
    _weeklyData: null,
    _upcomingWeek: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchData();
    },


    filteredProducts: Ember.computed('rollupProductIds.[]', 'products.[]', function () {
      var _this = this;

      // filter the product list down where the rollup product is in the path
      return (this.products || []).filter(function (p) {
        return (p.get('ancestors') || []).filter(function (a) {
          return _this.rollupProductIds.includes(a.get('id'));
        }).length > 0;
      });
    }),

    _fetchData: Ember.observer('customer.id', 'selectedDate', 'rollupProductIds.[]', function () {
      var _this2 = this;

      var customerID = this.customer.id;
      var tz = this.tz.customer;
      var selectedDate = _moment.default.tz(this.selectedDate, tz).startOf('day');

      this.set('loading', true);
      this.set('_weeklyData', null);
      this.set('_upcomingWeek', null);

      var promises = {};

      if (this.showInventorySegments) {
        promises.overviewCollection = this.get('ajax').request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.clone().add(6, 'days').format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'REVENUE', // Only need the revenue analytics, as we will use the sold count for OCCUPANCY numbers
            analytictype: 'COUNT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE,REVENUE',
            rollupstatus: 'ROLLUP',
            groupBy: 'PRODUCT',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;
          return analyticEntries.filterBy('groupBy', 'PRODUCT').rejectBy('subContentType');
        });

        promises.rollup = this.get('ajax').request('/rest/v1/pms/dailyMetrics?ids[]=' + Ember.get(this, 'rollupProductIds').join('&ids[]='), {
          data: {
            customerID: customerID,
            begindate: selectedDate.format('YYYY-MM-DD'),
            enddate: selectedDate.clone().add(6, 'days').format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref2) {
          var _ref2$pMSDailyMetrics = _ref2.pMSDailyMetrics,
              pMSDailyMetrics = _ref2$pMSDailyMetrics === undefined ? [] : _ref2$pMSDailyMetrics;
          return pMSDailyMetrics;
        });

        promises.sellRate = this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.clone().add(6, 'days').format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'SELL_RATE',
            subcontenttype: 'PUBLIC',
            analytictype: 'AMOUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: this.rollupStatus,
            fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
          }
        }).then(function (_ref3) {
          var _ref3$analyticEntries = _ref3.analyticEntries,
              analyticEntries = _ref3$analyticEntries === undefined ? [] : _ref3$analyticEntries;
          return analyticEntries;
        });
      } else {
        promises.analytics = this.get('ajax').request('rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: selectedDate.format(),
            enddate: selectedDate.clone().add(6, 'days').format(),
            frequency: 'DAY',
            customerid: customerID,
            contenttype: 'OCCUPANCY,ADR,SELL_RATE',
            analytictype: 'AMOUNT,PERCENT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: this.rollupStatus,
            fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
          }
        }).then(function (_ref4) {
          var _ref4$analyticEntries = _ref4.analyticEntries,
              analyticEntries = _ref4$analyticEntries === undefined ? [] : _ref4$analyticEntries;
          return analyticEntries;
        });
      }

      Ember.RSVP.hash(promises).then(function (results) {
        return _this2.set('_upcomingWeek', results);
      }).finally(function () {
        return _this2.set('loading', false);
      });
    }),

    weeklyData: Ember.observer('_upcomingWeek', function () {
      var _this3 = this;

      var mapped = {};
      var dateMap = {};
      var upcomingWeek = this._upcomingWeek;

      if (upcomingWeek) {
        var tz = this.tz.customer;
        var today = _moment.default.tz(tz).startOf('day');
        var endDate = _moment.default.tz(tz).add(this.get('_weeklyChartDaysShown'), 'days').endOf('day');
        // Ensure days for each in range
        (0, _momentRange.default)(_moment.default.tz(today, tz).startOf('day'), _moment.default.tz(endDate, tz).endOf('day')).forEach(function (m) {
          dateMap[m.tz(tz).format()] = { slice: m.tz(tz).format() };
        });

        if (this.showInventorySegments) {
          var filteredProducts = Ember.get(this, 'filteredProducts');
          var filteredAnalytics = upcomingWeek.overviewCollection.filter(function (a) {
            return filteredProducts.findBy('id', a.groupByValue);
          });
          var mappedRevenueData = {};
          filteredAnalytics.forEach(function (ae) {
            var beginDate = _moment.default.tz(ae.beginDate, tz);
            var key = beginDate.format();

            if (!mappedRevenueData[key]) {
              mappedRevenueData[key] = 0;
            }

            mappedRevenueData[key] += parseFloat(ae.dataValue);
          });

          var inventoryData = upcomingWeek.rollup;
          var mappedInventoryData = {};
          inventoryData.forEach(function (d) {
            var beginDate = _moment.default.tz(d.rpcDate, tz);
            var key = beginDate.format();

            if (!mappedInventoryData[key]) {
              mappedInventoryData[key] = {
                sold: 0,
                yieldable: 0
              };
            }

            mappedInventoryData[key].sold += (d.scheduledArrivals || []).length + (d.stayOvers || []).length;
            mappedInventoryData[key].yieldable += d.yieldable || 0;
          });

          var sellRates = upcomingWeek.sellRate;
          sellRates.forEach(function (sr) {
            var beginDate = _moment.default.tz(sr.beginDate, tz);
            var key = beginDate.format();
            var dateClass = today.isSame(beginDate, 'day') ? 'today' : '';

            if (!mapped[key] && dateMap[key]) {
              mapped[key] = {
                bar: 0,
                adr: 0,
                otb: 0,
                slice: key,
                bulletClass: 'bubble-bullet',
                dateClass: dateClass
              };
            }

            if (mapped[key]) {
              var inventory = mappedInventoryData[key];
              var revenue = mappedRevenueData[key];
              mapped[key].bar = parseFloat(sr.dataValue);
              mapped[key].adr = inventory.sold !== 0 ? revenue / inventory.sold : 0;
              mapped[key].otb = (0, _accounting.formatNumber)(inventory.yieldable !== 0 ? inventory.sold / inventory.yieldable * 100 : 0, 1);
            }
          });
        } else {
          var entries = upcomingWeek.analytics.toArray();
          (entries || []).forEach(function (e) {
            var m = e.beginDate;
            var beginDate = _moment.default.tz(m, tz);
            var key = beginDate.format();
            var dateClass = today.isSame(beginDate) ? 'today' : '';

            if (!mapped[key] && dateMap[key]) {
              mapped[key] = {
                bar: 0,
                adr: 0,
                otb: 0,
                slice: key,
                bulletClass: 'bubble-bullet',
                dateClass: dateClass
              };
            }

            if (mapped[key]) {
              switch (e.contentType) {
                case 'OCCUPANCY':
                  if (e.subContentType && e.subContentType.toUpperCase() === _this3.occupancySubcontentType.toUpperCase()) {
                    mapped[key].otb = (0, _accounting.formatNumber)(parseFloat(e.dataValue), 1);
                  }
                  break;
                case 'ADR':
                  mapped[key].adr = parseFloat(e.dataValue);
                  break;
                case 'SELL_RATE':
                  if (e.subContentType && e.subContentType.toUpperCase() === 'PUBLIC') {
                    mapped[key].bar = parseFloat(e.dataValue);
                  }
                  break;
              }
            }
          });
        }

        var results = Object.keys(mapped).map(function (key) {
          return mapped[key];
        });

        if (results && results.length > 0) {
          // Keep this for manipulation later
          this.set('_weeklyData', results);
          this.set('weekAtAGlance', results.slice(0, this.get('_weeklyChartDaysShown')));
        } else {
          this.set('weekAtAGlance', null);
        }
      }
    }),

    disableWeeklyLeft: Ember.computed.equal('_minWeeklyIndex', 0),
    disableWeeklyRight: Ember.computed('_minWeeklyIndex', function () {
      var weekAtAGlance = this.get('_weeklyData');
      return weekAtAGlance && this.get('_minWeeklyIndex') + 3 >= weekAtAGlance.length;
    }),

    _weeklyChartDaysShown: Ember.computed('isMobile', function () {
      if (this.get('isMobile')) {
        return 3;
      }

      return 7;
    }),

    actions: {
      weeklyChartWidgetLeft: function weeklyChartWidgetLeft() {
        var weeklyData = this.get('_weeklyData');
        var minWeeklyIndex = this.get('_minWeeklyIndex') - 1;
        var dayCount = this.get('_weeklyChartDaysShown');

        if (minWeeklyIndex + dayCount <= weeklyData.length) {
          this.set('weekAtAGlance', weeklyData.slice(minWeeklyIndex, minWeeklyIndex + dayCount));
          this.set('_minWeeklyIndex', minWeeklyIndex);
        }
      },
      weeklyChartWidgetRight: function weeklyChartWidgetRight() {
        var weeklyData = this.get('_weeklyData');
        var minWeeklyIndex = this.get('_minWeeklyIndex') + 1;
        var dayCount = this.get('_weeklyChartDaysShown');

        if (minWeeklyIndex + dayCount <= weeklyData.length) {
          this.set('weekAtAGlance', weeklyData.slice(minWeeklyIndex, minWeeklyIndex + dayCount));
          this.set('_minWeeklyIndex', minWeeklyIndex);
        }
      }
    }
  });
});