define('ux-pcc/components/rule-advanced/rule-filter-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (this.get('conditions').length > 0 && !this.get('conditions').findBy('value', changeset.get('filterCondition'))) {
        changeset.set('filterCondition', this.get('conditions.firstObject.value'));
      }

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        filterCondition: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form_modal.no_type').toString()
        })
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    conditionsObserver: Ember.observer('conditions', function () {
      var changeset = this.get('changeset');
      if (this.get('conditions').length > 0 && !this.get('conditions').findBy('value', changeset.get('filterCondition'))) {
        changeset.set('filterCondition', this.get('conditions.firstObject.value'));
      }
    }),

    conditions: Ember.computed('filterFieldItem', 'conditionOptions.@each.value', function () {
      var filterFieldItem = this.get('filterFieldItem');
      if (!filterFieldItem) {
        return [];
      }
      var conditions = this.get('conditionOptions');

      return conditions.filter(function (opt) {
        return filterFieldItem.conditions.includes(opt.value);
      });
    }),

    modelFilterCondition: Ember.computed('changeset.filterCondition', 'conditionOptions.@each.value', function () {
      return this.get('conditionOptions').findBy('value', this.get('changeset.filterCondition'));
    }),

    comparators: Ember.computed('filterFieldItem.{comp,value}', 'comparatorOptions.@each.value', function () {
      var filterFieldItem = this.get('filterFieldItem');
      if (!filterFieldItem || !filterFieldItem.comp) {
        return [];
      }

      var fieldOptions = this.get('comparatorOptions').findBy('group', filterFieldItem.comp);

      return (fieldOptions || { options: [] }).options.filter(function (opt) {
        // Filter out self if included
        return opt.value !== filterFieldItem.value;
      });
    }),

    isGreaterLessThanCondition: Ember.computed('changeset.filterCondition', function () {
      return ['GT', 'GTEQ', 'LT', 'LTEQ'].includes(this.get('changeset.filterCondition'));
    }),

    type: Ember.computed.readOnly('filterFieldItem.type'),

    isNumberLikeType: Ember.computed('type', function () {
      return ['percent', 'number', 'numerical'].includes(this.get('type'));
    })

  });
});