define('ux-pcc/controllers/affiliates/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    affiliatesController: Ember.inject.controller('affiliates'),
    view: Ember.computed.readOnly('affiliatesController.view'),

    sort: [],

    results: Ember.computed('isPropertyView', 'properties', 'chains', function () {
      if (this.get("isPropertyView")) {
        return this.get('properties');
      } else {
        return this.get('chains');
      }
    }),

    chains: Ember.computed('model.[]', function () {
      return (this.get('model') || []).filterBy('customerType', 'AFFILIATE');
    }),

    properties: Ember.computed('model.[]', function () {
      return (this.get('model') || []).filterBy('customerType', 'CUSTOMER');
    }),

    isPropertyView: Ember.computed.equal('view', "property"),
    isChainView: Ember.computed.equal('view', "chain"),
    isAffiliate: Ember.computed.equal('customer.customerType', 'AFFILIATE'),
    isPortfolio: Ember.computed.equal('customer.customerType', 'CUSTOMERS'),

    actions: {}

  });
});