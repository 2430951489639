define('ux-pcc/components/rule/advanced-rule-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      // Prevents popover from being 'centered' on its parent element if the center is off-screen
      this.set('popperOptions', {
        modifiers: {
          preventOverflow: {
            escapeWithReference: false
          }
        }
      });
    },


    type: Ember.computed('rule.ruleType', 'filteredTypes', function () {
      var types = this.get('filteredTypes') || [];

      var type = types.findBy('type', this.get('rule.ruleType'));

      return type ? type.shortDescription : '';
    }),

    sortedChildren: Ember.computed('rule.children.@each.orderBy', function () {
      return this.get('rule.children').sortBy('orderBy');
    }),

    actions: {
      confirmDelete: function confirmDelete(rule) {
        this.get('confirmDelete')(rule);
      }
    }
  });
});