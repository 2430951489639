define('ux-pcc/components/panels/pantry/payment-item', ['exports', 'ux-components/components/panels/pantry/payment-item'], function (exports, _paymentItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paymentItem.default;
    }
  });
});