define("ux-pcc/utils/charts/sync-zero", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calcMinMax = calcMinMax;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function calcMinMax(data, fields) {
    var valueStats = {};
    var options = {};

    // calc min, max, and range
    fields.forEach(function (field) {
      var min = Number.MAX_SAFE_INTEGER;
      var max = Number.MIN_SAFE_INTEGER;

      var values = data.map(function (d) {
        return d[field];
      }).filter(function (v) {
        return v;
      });

      min = Math.min.apply(Math, [0, min].concat(_toConsumableArray(values)));
      max = Math.max.apply(Math, [0, max].concat(_toConsumableArray(values)));

      //TODO: better rounding algorithm
      var precision = 1;
      valueStats[field] = {
        min: magicRound(min, precision),
        max: magicRound(max, precision),
        range: magicRound(max, precision) - magicRound(min, precision)
      };
    });

    var base = { range: Number.MAX_SAFE_INTEGER };

    // calc base data set
    fields.forEach(function (field) {
      if (valueStats[field].range < base.range) {
        base = valueStats[field];
      }
    });

    var scaledMin = Number.MAX_SAFE_INTEGER;
    var scaledMax = Number.MIN_SAFE_INTEGER;

    // calc scaling factor for each data set
    fields.forEach(function (field) {
      valueStats[field].scalingFactor = valueStats[field].range / base.range;
      valueStats[field].scaledMin = valueStats[field].min / valueStats[field].scalingFactor;
      valueStats[field].scaledMax = valueStats[field].max / valueStats[field].scalingFactor;

      scaledMin = Math.min(scaledMin, valueStats[field].scaledMin);
      scaledMax = Math.max(scaledMax, valueStats[field].scaledMax);
    });

    // calc min and max for each data set
    fields.forEach(function (field) {
      options[field] = {
        min: Math.min(scaledMin, valueStats[field].scaledMin) * valueStats[field].scalingFactor,
        max: Math.max(scaledMax, valueStats[field].scaledMax) * valueStats[field].scalingFactor
      };
    });

    return options;
  }

  function magicRound(number, precision) {
    var magic = Math.pow(10, precision);
    if (number > 0) {
      return Math.ceil(number / magic) * magic;
    } else {
      return Math.floor(number / magic) * magic;
    }
  }
});