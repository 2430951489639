define('ux-pcc/mixins/flashable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    flashMessages: Ember.A(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('flashMessages', this.get('flashMessages'));
    },


    actions: {
      /**
       Add new flash messages to the screen
        @method actions.flash
       @param {String} message The message to be interpolated
       @param {String} type The flash message type
       @param {Boolean} keepOthers Will prevent other flashes at this level from being cleared on new flash
       */
      flash: function flash(message, type, keepOthers) {
        if (!keepOthers) {
          this.get('flashMessages').clear();
        }

        this.get('flashMessages').addObject(Ember.Object.create({
          message: message,
          type: type
        }));
      },


      /**
       Remove all of the flash messages from the screen
        @method actions.clearFlashes
       */
      clearFlashes: function clearFlashes() {
        this.get('flashMessages').clear();
      },


      /**
       This action will clear all flashes on this route when the route transitions.
        @method actions.willTransition
       */
      willTransition: function willTransition() /* transition */{
        this.send('clearFlashes');
        return true;
      }
    }
  });
});