define('ux-pcc/routes/tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');
      return this.store.query('customer', {
        folderid: customer.get("id"),
        detaillevel: 'XREF',
        include: 'tags,address',
        limit: -1
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});