define('ux-pcc/models/report', ['exports', 'ux-data/models/report'], function (exports, _report) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _report.default;
    }
  });
});