define('ux-pcc/controllers/rules-simple/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    saveLabel: 'rules-simple.form.save_rule',

    policyType: Ember.computed.readOnly('model.ruleType')

  });
});