define('ux-pcc/components/charts/analysis-chart', ['exports', 'ux-components/utils/format-currency', 'ux-pcc/helpers/wrap-negative', 'ux-pcc/components/charts/chart-basic'], function (exports, _formatCurrency, _wrapNegative, _chartBasic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    showLegend: true,
    showValueAxisLabels: false,
    showGuide: true,
    showBalloon: true,
    currencyCode: 'USD',
    showCurrency: true,

    classNames: ['analysis-chart'],
    sliceResolution: 'week',

    bubbleValue: function bubbleValue(data, _ref) {
      var label = _ref.label,
          key = _ref.key,
          format = _ref.format,
          formatHash = _ref.formatHash,
          valueClassname = _ref.valueClassname;

      var spanClass = data[valueClassname] || '';

      var htmlTemplate = function htmlTemplate(title, value) {
        return '<div>\n                <span class=\'bubble-label\'>' + title + '</span>\n                <span class=\'' + spanClass + '\'>' + value + '</span>\n              </div>';
      };

      if (format === 'currency') {
        return htmlTemplate(label + ' (' + formatHash.currencyCode + '): ', (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(data[key], formatHash.currencyCode, { hash: formatHash })));
      } else {
        return htmlTemplate(label + ': ', (0, _wrapNegative.wrapNegative)(data[key], formatHash));
      }
    },
    generateBubble: function generateBubble(item, index) {
      var _this = this;

      var dataFields = this.get('dataFields');
      var showBubble = false;

      for (var i = 0; i < dataFields.length; i++) {
        if (dataFields[i].key in item.dataContext) {
          if (i === index) {
            showBubble = true;
          }

          break;
        }
      }

      if (!showBubble) {
        return '';
      }

      var displayHTML = '';

      var bubbleOrder = this.get('bubbleOrder');
      bubbleOrder.forEach(function (field) {
        var dataField = dataFields.findBy('key', field.key);
        if (field.key in item.dataContext && !!dataField) {
          displayHTML += _this.bubbleValue(item.dataContext, dataField);
        }
      });

      return displayHTML;
    },


    dataTransform: Ember.computed('sliceResolution', function () {
      var dataFields = this.get('dataFields');
      return function (data) {
        data.forEach(function (d) {
          // Set the value class for a negative value
          dataFields.forEach(function (field) {
            if (d[field.key] < 0) {
              d[field.valueClassname] = 'negative-value-class';
            }
          });
        });

        return data;
      };
    }),

    generateGraphs: function generateGraphs(dataFields) {
      var _this2 = this;

      var graphs = [];
      if (this.get('data.length') > 0) {
        var generateGraph = function generateGraph(title, config, index) {
          var graph = {
            title: title,
            balloonFunction: function balloonFunction(item) {
              return _this2.generateBubble(item, index);
            },
            fillAlphas: 1,
            lineAlpha: 1,
            type: Ember.isEmpty(config.chartType) ? 'line' : config.chartType,
            clustered: true,
            id: 'analysis-' + index + ' chart-color--' + (config.lineClasses ? config.lineClasses : index),
            valueField: config.key,
            valueAxis: config.category,
            connect: false,
            classNameField: config.valueClassname,
            balloon: { fillAlpha: 1 }
          };

          if (config.chartType !== 'column') {
            graph.fillAlphas = 0;
            graph.bullet = config.bulletType || 'round';
            graph.bulletSize = 6;
            graph.bulletBorderAlpha = 1;
            graph.bulletAlpha = 1;
            graph.classNameField = 'bulletClass';
            graph.useLineColorForBulletBorder = true;
            graph.lineThickness = 1.5;
            graph.dashLength = config.dashLength || 0;
          }

          return graph;
        };

        dataFields.forEach(function (field, i) {
          return graphs.push(generateGraph(field.chartLabel ? field.chartLabel : field.label, field, i));
        });
      }

      return graphs;
    },
    _config: function _config() {
      var dataFields = this.get('dataFields');

      var calendars = this.get('_data');

      if (!calendars) {
        return;
      }

      var guides = [];

      if (this.get('markStartCategory')) {
        guides.push({
          category: this.get('markStartCategory'),
          toCategory: this.get('markEndCategory'),
          fillAlpha: 0.2,
          fillColor: '#888888',
          lineAlpha: 0,
          labelRotation: 90,
          inside: true,
          lineThickness: 2,
          expand: true
        });
      }

      // Figure out the multipliers for the value axis for synchronization by
      // looping over the values to determine the maximums in order to set the multiplier
      // let countMax = 0.1;
      // let yieldMax = 0.1;
      // let revenueMax = 0.1;
      //
      // let countMin = 0;
      // let yieldMin = 0;
      // let revenueMin = 0;

      var countFields = dataFields.filterBy('category', 'count') || [];
      var yieldFields = dataFields.filterBy('category', 'yield') || [];
      var revenueFields = dataFields.filterBy('category', 'revenue') || [];

      // calendars.forEach(item => {
      //   countFields.mapBy('key').forEach(k => {
      //     countMax = Math.max(countMax, item[k]);
      //     countMin = Math.min(countMax, item[k]);
      //   });
      //
      //   yieldFields.mapBy('key').forEach(k => {
      //     yieldMax = Math.max(yieldMax, item[k]);
      //     yieldMin = Math.min(yieldMax, item[k]);
      //   });
      //
      //   revenueFields.mapBy('key').forEach(k => {
      //     revenueMax = Math.max(revenueMax, item[k]);
      //     revenueMin = Math.min(revenueMax, item[k]);
      //   });
      // });

      // let revenueMultiplier = Math.ceil(revenueMax / yieldMax);
      // let countMultiplier = Math.ceil(countMax / yieldMax);

      var valueAxes = [];
      if (revenueFields.length > 0) {
        valueAxes.push({
          id: 'revenue',
          color: '#999999',
          gridAlpha: 0.1,
          axisAlpha: 0,
          labelsEnabled: this.get('showValueAxisLabels'),
          zeroGridAlpha: 0,
          //minimum: 0,
          position: 'right',
          title: '' + this.get('i18n').t('yield-manager.revenue') + (this.get('showCurrency') ? ' (' + this.get('currencyCode') + ')' : ''),
          showFirstLabel: true,
          usePrefixes: true
          // synchronizeWith: 'yield',
          // synchronizationMultiplier: revenueMultiplier
        });
      }

      if (countFields.length > 0) {
        valueAxes.push({
          id: 'count',
          color: '#999999',
          gridAlpha: 0.1,
          axisAlpha: 0,
          labelsEnabled: this.get('showValueAxisLabels'),
          zeroGridAlpha: 0,
          //minimum: 0,
          position: 'left',
          title: this.get('i18n').t('yield-manager.analyze.cards.charge_days'),
          showFirstLabel: true,
          usePrefixes: true
          // synchronizeWith: 'yield',
          // synchronizationMultiplier: countMultiplier
        });
      }

      if (yieldFields.length > 0) {
        valueAxes.push({
          id: 'yield',
          color: '#999999',
          gridAlpha: 0.1,
          axisAlpha: 0.1,
          labelsEnabled: this.get('showValueAxisLabels'),
          zeroGridAlpha: 0,
          //minimum: 0,
          position: 'left',
          title: '' + this.get('i18n').t('yield-manager.yield') + (this.get('showCurrency') ? ' (' + this.get('currencyCode') + ')' : ''),
          offset: 80,
          showFirstLabel: true,
          usePrefixes: true,
          guides: [{
            id: 'zero-marker',
            value: 0,
            lineAlpha: 0.8,
            lineThickness: 3,
            above: true,
            inside: true,
            fillColor: '#505558',
            lineColor: '#505558'
          }]
        });
      }

      var config = {
        type: 'serial',
        addClassNames: true,
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        trendLines: [],
        allLabels: [],
        balloon: {},
        titles: [],
        graphs: this.generateGraphs(dataFields),
        synchronizeGrid: true,
        valueAxes: valueAxes,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass'
        },
        legend: {
          position: 'bottom',
          useGraphSettings: true,
          markerSize: 5,
          enabled: this.get('showLegend')
        },
        guides: guides,
        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: this.get('showBalloon'),
          cursorAlpha: 0,
          cursorColor: '#4d96cb',
          color: '#f3faff',
          categoryBalloonEnabled: false
        },
        language: this.get('i18n.locale'),
        dataProvider: calendars
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      this._createChart();
    },


    _data: Ember.computed('data', 'dataTransform', 'dataFields.[]', function () {
      var data = this.get('data');
      // todo: may want to copy 'data' so mutation doesn't occur
      return this.get('dataTransform')(data);
    }),

    _createChart: function _createChart() {
      var chart = this._super();
      Ember.run.scheduleOnce('afterRender', this, function () {
        return chart.validateData();
      }, 0);
    }
  });
});