define('ux-pcc/controllers/adhoc-sql', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),

    loadingFlag: false,
    resultsPerPage: 10,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('records', []);
      this.set('fields', []);
      this.set('sort', []);
    },


    actions: {
      updateMyCode: function updateMyCode(newCode) {
        this.set('myCode', newCode);
      },
      run: function run() {
        var _this = this;

        console.log(this.get('sql'));

        this.set('loadingFlag', true);
        this.set('records', []);
        this.set('fields', []);
        this.set('sort', []);

        var sqlURL = '/rest/v1/queries/adhoc/sql';
        this.ajax.post(sqlURL, {
          data: {
            sql: this.get('sql'),
            type: 'application/json'
          },
          dataType: 'json',
          contentType: 'application/x-www-form-urlencoded'
        }).then(function () {
          var contents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          if (contents.length > 0) {
            _this.set('fields', Object.keys(contents.get('firstObject')));

            contents.forEach(function (record) {
              record.values = Object.values(record).map(function (val) {
                if (Array.isArray(val) || (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object') {
                  return JSON.stringify(val);
                } else {
                  return (0, _lodash.unescape)(val).replace(/&apos;/g, '\'');
                }
              });
            });

            _this.set('records', contents);
          }
        }).catch(function (jqXHR) {
          _this.dialog.showError({
            messageText: _this.i18n.t('adhoc-sql.message.fetch_error') + ':' + jqXHR.message
          });
        }).finally(function () {
          return _this.set('loadingFlag', false);
        });
      }
    }
  });
});