define('ux-pcc/components/competitor-web-rates', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    competitors: null,
    loadDate: null,
    page: 1,
    tagName: "",

    _resultsPerPage: 4,

    ajax: Ember.inject.service(),

    enableSlider: Ember.computed('competitors.length', function () {
      return this.get('competitors.length') > this.get('_resultsPerPage');
    }),

    isMinPage: Ember.computed('page', function () {
      return this.get('page') === 1;
    }),

    isMaxPage: Ember.computed('competitors', 'page', function () {
      return this.get('page') * this.get('_resultsPerPage') >= this.get('competitors.length');
    }),

    pageStartIndex: Ember.computed('page', function () {
      return (this.get('page') - 1) * this.get('_resultsPerPage') + 1;
    }),

    pageEndIndex: Ember.computed('competitors.length', 'page', function () {
      var maxForPage = this.get('page') * this.get('_resultsPerPage');
      return maxForPage < this.get('competitors.length') ? maxForPage : this.get('competitors.length');
    }),

    maxPage: Ember.computed('competitors', 'page', function () {
      return Math.ceil(this.get('competitors.length') / this.get('_resultsPerPage'));
    }),

    pagedResults: Ember.computed('page', 'competitors', function () {
      var _this = this;

      var model = this.get('competitors') || [];
      var objects = [];

      model.forEach(function (result, index) {
        if (index + 1 >= _this.get('pageStartIndex') && index + 1 <= _this.get('pageEndIndex')) {
          objects.push(result);
        }
      });

      return objects;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchData();
    },


    fectchDataObserver: Ember.observer('customer.id', 'selectedDate', function () {
      this._fetchData();
    }),

    _fetchData: Ember.observer('customer.id', 'selectedDate', function () {
      var _this2 = this;

      var customerID = this.get('customer.id');
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('selectedDate'), tz).startOf('day');

      this.set('loading', true);
      this.set('competitors', null);

      this.ajax.request('rest/v1/analyticEntries', {
        data: {
          channelcode: 'ALL',
          begindate: selectedDate.format(),
          enddate: selectedDate.format(),
          frequency: 'DAY',
          customerid: customerID,
          contenttype: 'SELL_RATE',
          analytictype: 'AMOUNT',
          action: 'STATISTIC',
          datatype: 'VALUE',
          rollupstatus: this.get('rollupStatus'),
          fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
        }
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

        // if no data than return
        if (analyticEntries.length === 0) {
          _this2.set("competitors", null);
        } else {
          var competitiveRates = analyticEntries.map(function (competitor) {
            var comp = Ember.get(competitor, 'description');
            if (comp) {
              Ember.set(competitor, 'description', decodeURIComponent(comp.replace(/\+/g, '%20')));
            }
            Ember.set(competitor, 'dataValue', parseFloat(Ember.get(competitor, 'dataValue')));
            Ember.set(competitor, 'currencyCode', _this2.get('currencyCode'));
            return competitor;
          });

          var sellableRate = competitiveRates.filterBy('description', _this2.get('customer.name')).get('firstObject') || competitiveRates.filterBy("subContentType", "PUBLIC").get('firstObject');

          if (sellableRate) {
            competitiveRates = competitiveRates.map(function (competitor) {
              Ember.set(competitor, 'isLower', Ember.get(sellableRate, 'dataValue') > Ember.get(competitor, 'dataValue'));
              return competitor;
            });
          }
          competitiveRates = competitiveRates.rejectBy('description', undefined);
          _this2.set('competitors', competitiveRates.rejectBy('description', _this2.get('customer.name')).sortBy('dataValue'));
        }
      }).finally(function () {
        _this2.set('loading', false);
      });
    }),

    actions: {
      next: function next() {
        var resultsPerPage = this.get('_resultsPerPage');
        var numberResults = this.get('competitors.length');
        var page = this.get('page') + 1;

        if (page * resultsPerPage >= numberResults) {
          page = Math.ceil(numberResults / resultsPerPage);
        }

        this.set('page', page);
      },
      prev: function prev() {
        var page = Math.max(1, this.get('page') - 1);
        this.set('page', page);
      }
    }

  });
});