define('ux-pcc/routes/customer/performance/report', ['exports', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fileSaver.default, {

    ajax: Ember.inject.service(),

    queryParams: {
      beginDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var tz = this.get('tz.customer');
      var beginDate = void 0;
      var endDate = void 0;

      // If we have a begin date, use that as the starting point
      if (params.beginDate) {
        beginDate = _moment.default.tz(params.beginDate, tz).startOf('day');
      } else {
        beginDate = _moment.default.tz(tz).startOf('day');
      }

      if (this.get('deviceType.mobile')) {
        endDate = _moment.default.tz(beginDate, tz).endOf('day');
      } else if (params.endDate) {
        endDate = _moment.default.tz(params.endDate, tz).endOf('day');
        // Check to make sure the endDate is >= the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = _moment.default.tz(beginDate, tz).endOf('day');
        }
      } else {
        endDate = _moment.default.tz(beginDate, tz).add(30, 'days').endOf('day');
      }

      var customerId = this.modelFor('customer').get('id');

      var baseUrl = '/rest/v1/inventoryByBarPricePoint';

      return Ember.RSVP.hash({
        barPlanAllocations: this.get('ajax').request(baseUrl, {
          data: {
            customerid: customerId,
            channelid: 10,
            begindate: beginDate.format(),
            enddate: endDate.format(),
            isRealTime: true
          }
        }).then(function (contents) {
          if (contents.analyticEntries.customers && contents.analyticEntries.customers.length > 0) {
            return contents.analyticEntries.customers[0].customer.calendar;
          } else {
            return [];
          }
        }),
        roomsSold: this.get('ajax').request(baseUrl, {
          data: {
            customerid: customerId,
            channelid: 10,
            begindate: beginDate.format(),
            enddate: endDate.format()
          }
        }).then(function (contents) {
          if (contents.analyticEntries.customers && contents.analyticEntries.customers.length > 0) {
            return contents.analyticEntries.customers[0].customer.calendar;
          } else {
            return [];
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      var tz = this.get('tz.customer');
      var beginDate = void 0;
      var endDate = void 0;

      controller.set('isMobile', this.get('deviceType.mobile'));
      controller.set('isDesktop', this.get('deviceType.desktop'));

      // Set the controller begin and end dates to match those used in the Model
      if (controller.get('beginDate')) {
        beginDate = _moment.default.tz(controller.get('beginDate'), tz).startOf('day');
      } else {
        beginDate = _moment.default.tz(tz).startOf('day');
      }
      controller.set('beginDate', beginDate.format());

      if (controller.get('isMobile')) {
        endDate = _moment.default.tz(beginDate, tz).endOf('day');
        controller.set('endDate', endDate.startOf('day').format());
      } else if (controller.get('endDate')) {
        endDate = _moment.default.tz(controller.get('endDate'), tz).endOf('day');
        // Check to make sure the endDate is >= the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = _moment.default.tz(beginDate, tz).endOf('day');
        }
        controller.set('endDate', endDate.startOf('day').format());
      } else {
        controller.set('endDate', beginDate.clone().add(30, 'days').format());
      }

      this._super(controller, model);
    },


    actions: {
      exportCsv: function exportCsv() {
        var _this = this;

        var tz = this.get('tz.customer');
        var customerId = this.modelFor('customer').get('id');
        var beginDate = _moment.default.tz(this.controller.get('beginDate'), tz);
        var endDate = _moment.default.tz(this.controller.get('beginDate'), tz).add(400, 'days');
        var filename = this.i18n.t('customer.performance.report.file_prefix') + '_' + this.modelFor('customer').get('externalReference') + '_' + beginDate.format('YYYYMMDD') + '.csv';

        this.get('ajax').request('/rest/v1/inventoryByBarPricePoint', {
          headers: { accept: 'text/csv' },
          contentType: 'text/csv',
          dataType: 'blob',
          data: {
            customerid: customerId,
            channelid: 10,
            begindate: beginDate.format(),
            enddate: endDate.format(),
            isRealTime: true
          }
        }).then(function (data) {
          return _this.saveFileAs(filename, data, 'text/csv');
        }, function () {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this.i18n.t('customer.performance.download_error'), 'danger');
        });
      }
    }
  });
});