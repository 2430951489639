define('ux-pcc/helpers/type-eq', ['exports', 'ux-components/helpers/type-eq'], function (exports, _typeEq) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _typeEq.default;
    }
  });
  Object.defineProperty(exports, 'typeEq', {
    enumerable: true,
    get: function () {
      return _typeEq.typeEq;
    }
  });
});