define('ux-pcc/components/guarantee-panel/guarantee-form-voucher', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-voucher'], function (exports, _guaranteeFormVoucher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormVoucher.default;
    }
  });
});