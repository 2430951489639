define('ux-pcc/controllers/customer/setup/customer-form', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    dialog: Ember.inject.service(),

    formHeader: Ember.computed('i18n.locale', 'model.{customerType,formType}', function () {
      if (this.get('model.formType') === 'add') {
        return this.i18n.t('pcc-components.customer-list.add-label', { label: this.i18n.t('pcc-components.customer-list.' + this.get('model.customerType').toLowerCase()) });
      } else {
        return this.i18n.t('pcc-components.customer-list.edit-label', { label: this.i18n.t('pcc-components.customer-list.' + this.get('model.customerType').toLowerCase()) });
      }
    }),

    customerName: Ember.computed.alias('model.customer.name'),

    validations: {
      name: (0, _validators.validatePresence)(true),
      phoneNumber: (0, _validators.validateFormat)({ type: 'phone', allowBlank: true })
    },

    changeset: Ember.computed('model.customer', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model.customer'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    actions: {
      clearAndClose: function clearAndClose(customer) {
        customer.get('addresses').forEach(function (address) {
          if (address.get('hasDirtyAttributes')) {
            address.rollbackAttributes();
          }
        });
        customer.get('contactInformations').forEach(function (ci) {
          if (ci.get('hasDirtyAttributes')) {
            ci.rollbackAttributes();
          }
        });
        if (customer.get('hasDirtyAttributes')) {
          customer.rollbackAttributes();
        }
        this.send('close');
      },
      close: function close() {
        if (this.get('model.onClose')) {
          this.get('model.onClose')();
        }
        this.send('closeModal');
      },
      save: function save(changeset) {
        var _this = this;

        changeset.validate().then(function () {

          if (changeset.get('isValid')) {
            changeset.execute();
            changeset.save().then(function () {
              if (_this.get('model.onSave')) {
                _this.get('model.onSave')();
              }
              _this.send('close');
              _this.dialog.showSuccess({
                titleText: _this.i18n.t('pcc-components.customer-list.dialogs.success.title'),
                messageText: _this.i18n.t(_this.get('model.saveMessage'), { customerName: _this.get('model.customer.name') }),
                confirmText: _this.i18n.t('pcc-components.customer-list.dialogs.success.button_label')
              });
            }).catch(function () {
              _this.dialog.showError();
            });
          }
        });
      }
    }
  });
});