define('ux-pcc/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    dialog: Ember.inject.service(),
    permission: Ember.inject.service(),
    session: Ember.inject.service(),

    gaInternalId: Ember.computed.readOnly('config.gaInternalId'),
    gaId: Ember.computed.readOnly('config.gaId'),

    init: function init() {
      this._super.apply(this, arguments);
      this.permissionsChanged();
    },


    permissionsChanged: Ember.observer('session.data.authenticated.permissions.[]', function () {
      // Create a Map for the permissions and put them on the session for access later
      this.permission.updatePermissions(this.get('session.data.authenticated.permissions') || []);
    })

  });
});