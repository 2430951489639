define('ux-pcc/components/guarantee-panel/guarantee-form-cc', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-cc'], function (exports, _guaranteeFormCc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormCc.default;
    }
  });
});