define('ux-pcc/controllers/comment/-modal', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_flashable.default, {

    store: Ember.inject.service(),

    sortedComments: Ember.computed('model.comments.[]', function () {
      return this.get('model.comments').sortBy('lastModified').reverse();
    }),

    actions: {
      save: function save() {
        var _this = this;

        var comment = this.store.createRecord('comment', {
          customer: this.get('model.customer'),
          fkID: this.get('model.referenceId'),
          fkReference: this.get('model.reference'),
          commentType: this.get('model.commentType'),
          commentDate: this.get('model.date'),
          text: this.get('commentText')
        });

        comment.save().then(function () {
          _this.set('commentText', '');
          _this.get('model.comments').addObject(comment);
        }, function (error) {
          _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      },
      cancel: function cancel() {
        this.set('commentText', '');
        this.send('removeModal');
      }
    }
  });
});