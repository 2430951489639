define('ux-pcc/controllers/customer', ['exports', 'moment', 'ux-components/utils/field-parser'], function (exports, _moment, _fieldParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    howTo: Ember.inject.service('how-to'),
    permission: Ember.inject.service(),

    userCustomerID: Ember.computed.readOnly('session.data.authenticated.customerID'),
    userCustomer: null,
    dialog: Ember.inject.service(),
    refreshingFlag: false,

    menus: [],

    menuService: Ember.inject.service('menu'),

    activeMenu: Ember.computed.readOnly('menuService.activeMenu'),
    activeMenuLabel: Ember.computed.readOnly('menuService.activeMenuLabel'),
    activeMenuClassSuffix: Ember.computed.readOnly('menuService.activeMenuClassSuffix'),

    isSidebarInformationExpanded: false,

    customerType: Ember.computed.alias('model.customerType'),
    permissionType: Ember.computed.alias('model.permissionType'),

    isAffiliate: Ember.computed.equal('model.customerType', 'AFFILIATE'),
    isPortfolio: Ember.computed.equal('model.customerType', 'CUSTOMERS'),
    isProperty: Ember.computed.equal('model.customerType', 'CUSTOMER'),

    settings: Ember.computed.alias('model.settings'),

    isTrainingAdmin: Ember.computed(function () {
      return this.permission.isPermitted('training', 'edit', 'multimedia');
    }),

    isSisterProperty: Ember.computed('settings', function () {
      // Check if this is a sister property
      var isSisterProperty = false;
      var settings = this.settings ? this.settings.toArray() : [];
      if (settings) {
        // Get the sister property setting
        var sisterSetting = settings.findBy('settingCode', 'PRICE_BASED_ON');

        // Strip out any spaces
        if (sisterSetting && sisterSetting.get('settingValue').length > 0) {
          isSisterProperty = sisterSetting.get('settingValue').toLowerCase() === 'true';
        }
      }
      return isSisterProperty;
    }),

    notificationClearDuration: Ember.computed('settings.[]', function () {
      var durationSetting = (this.settings || []).findBy('settingCode', 'GUEST_MESSAGING_NOTIFICATION_ALERT_DISPLAY_SECONDS');
      if (durationSetting) {
        return parseInt(durationSetting.get('settingValue')) * 1000;
      }
      return undefined;
    }),

    guestMessagingNotificationAlertSound: Ember.computed('settings.@each.settingValue', function () {
      var notificationAlertSound = this.settings.findBy('settingCode', 'GUEST_MESSAGING_NOTIFICATION_ALERT_SOUND') || {};
      return Ember.get(notificationAlertSound, 'settingValue') ? Ember.get(notificationAlertSound, 'settingValue') : undefined;
    }),

    guestMessagingNotificationAlertVolume: Ember.computed('settings.@each.settingValue', function () {
      var notificationAlertVolume = this.settings.findBy('settingCode', 'GUEST_MESSAGING_NOTIFICATION_ALERT_VOLUME') || {};
      return Ember.get(notificationAlertVolume, 'settingValue') ? parseInt(Ember.get(notificationAlertVolume, 'settingValue')) : undefined;
    }),

    userDisplayName: Ember.computed('session.data.authenticated.{firstName,lastName}', function () {
      if (!this.get('session.data.authenticated.firstName')) {
        return '';
      }

      return this.get('session.data.authenticated.firstName').charAt(0) + '. ' + this.get('session.data.authenticated.lastName');
    }),

    appCuesIdentify: Ember.on('init', Ember.observer('session.data.authenticated', function () {
      if (typeof Appcues !== 'undefined') {
        Appcues.identify(this.get('session.data.authenticated.userID'), { // Unique identifier for current user
          name: this.get('session.data.authenticated.firstName') + ' ' + this.get('session.data.authenticated.lastName'), // Current user’s name
          email: this.get('session.data.authenticated.userName'), // Current user’s email
          created_at: Date.now() // Unix timestamp of user signup date
        });
      }
    })),

    allowGuestChat: Ember.computed('menus.[]', function () {
      return !!this.menuService.flattenedMenus.findBy('actionUrl', 'customer.pms.guest-engagement');
    }),

    getReservationProduct: function getReservationProduct(reservation) {
      var products = (reservation.products || []).toArray().filterBy('productTemplate.id', '10a00000-0000-0000-0000-000000000000');
      if (products && products.length > 0) {
        if (this.get('reservation.status') === 'CANCEL') {
          return (products.filterBy('status', 'CANCEL') || []).get('lastObject') || (products.filterBy('status', 'ABSENT') || []).get('lastObject');
        } else {
          return (products.rejectBy('status', 'CANCEL').rejectBy('status', 'SESSION_CANCEL') || []).get('firstObject');
        }
      } else {
        return (reservation.products || []).toArray().get('firstObject');
      }
    },


    folioGuestChatTasks: null,
    folioGuestChatOptions: Ember.computed(function () {
      var _this = this;

      var options = {
        dataRefreshOptions: {
          key: 'chat-notifier',
          type: 'modelRefresh',
          refreshInterval: 15000
        },
        queryFunction: function queryFunction(since) {
          var queryOptions = {
            customerID: _this.get('model.id'),
            categoryCode: 'WORKFLOW_GUEST_CONVERSATION',
            fkReference: 'RESERVATION',
            include: 'ml',
            beginDate: _moment.default.tz(since, 'UTC').format(),
            endDate: (0, _moment.default)().add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
          };
          return _this.store.query('workflow-task', queryOptions).then(function (results) {
            return (results || []).filter(function (msg) {
              return msg.status === 'UNREAD' && msg.subCategoryCode === 'INBOUND';
            });
          });
        },
        notificationType: 'info',
        notificationClearDuration: this.notificationClearDuration,
        sound: function sound() {
          return _this.guestMessagingNotificationAlertSound;
        },
        volume: function volume() {
          return _this.guestMessagingNotificationAlertVolume;
        },
        getMessageBody: function getMessageBody(task) {
          return _this.store.findRecord('reservation', Ember.get(task, 'fkID'), { include: 'products', reload: true }).then(function (reservation) {
            var reservationProduct = _this.getReservationProduct(reservation);

            // TODO: figure out how to group messages so we can just respond with count
            if (reservationProduct.get('fulfillmentProduct.id')) {
              return _this.store.findRecord('product', reservationProduct.get('fulfillmentProduct.id')).then(function (fulfillmentProduct) {
                return fulfillmentProduct.get('shortDescription') + ' - ' + (0, _fieldParser.default)(Ember.get(task, 'name'), ':', 2) + ': ' + Ember.get(task, 'details');
              });
            }

            return reservation.get('reservationNumber') + ' - ' + (0, _fieldParser.default)(Ember.get(task, 'name'), ':', 2) + ': ' + Ember.get(task, 'details');
          });
        },
        alertClickMessage: this.i18n.t('chat-alerts.goto_folio'),
        onClick: function onClick(task) {
          // this is a deep link, so can't transition to id
          window.location = '/v2/#/' + _this.get('model.id') + '/pms/folios/' + Ember.get(task, 'fkID') + '/guest-engagement';
        }

        // Turn off autoClear of notifications if <=0
      };if (!isNaN(this.notificationClearDuration) && this.notificationClearDuration <= 0) {
        options['autoClear'] = false;
      }

      return options;
    }),

    deepLinkUrl: Ember.computed('router.rootURL', function () {
      var rootURL = this.router.rootURL;

      if (window.location.hostname === 'localhost') {
        return 'https://localhost:4201/deep-link';
      } else {
        var index = window.location.pathname.replace(rootURL, '/');
        return '/v2' + index + '#/deep-link';
      }
    }),

    actions: {
      itemSelected: function itemSelected(item) {
        if (!item) {
          return;
        }

        var customerID = this.get('model.id');

        if (item.fkReference === 'RESERVATION') {
          this.transitionToRoute('reservation.view', item.fkID);
        } else if (item.fkReference === 'AGREEMENT') {
          var route = 'customer.pms.groups.group';
          window.location = this.deepLinkUrl + '?targetRoute=' + route + '&modelID=' + item.fkID + '&customerID=' + customerID;
        } else if (item.fkReference === 'CUSTOMER') {
          if (item.parameters && item.parameters['CUSTOMER_TYPE'] === 'DIRECT_BILL') {
            var _route = 'customer.pms.profiles.direct-bills.direct-bill';
            window.location = this.deepLinkUrl + '?targetRoute=' + _route + '&modelID=' + item.fkID + '&customerID=' + customerID;
          } else if (item.parameters && item.parameters['CUSTOMER_TYPE'] === 'TRAVEL_AGENT') {
            var _route2 = 'customer.pms.profiles.travel-agents.travel-agent';
            window.location = this.deepLinkUrl + '?targetRoute=' + _route2 + '&modelID=' + item.fkID + '&customerID=' + customerID;
          } else if (item.parameters && item.parameters['CUSTOMER_TYPE'] === 'MEMBER') {
            var _route3 = 'customer.pms.profiles.loyalty-members.loyalty-member';
            window.location = this.deepLinkUrl + '?targetRoute=' + _route3 + '&modelID=' + item.fkID + '&customerID=' + customerID;
          } else {
            this.transitionToRoute('customer.index', item.fkID);
          }
        } else if (item.id) {
          this.transitionToRoute('customer.index', item.id);
        }
      },
      filterCustomer: function filterCustomer(customerId, alertType) {
        var type = void 0;

        switch (alertType) {
          case 'NOTIFY_ALERT':
            type = 'alert';
            break;
          case 'NOTIFY_WARN':
            type = 'warning';
            break;
          case 'NOTIFY_INFO':
            type = 'message';
            break;
        }

        this.transitionToRoute('alerts.index', { queryParams: { propertyId: customerId, alertType: type } });
      },
      toggleMenu: function toggleMenu() {
        this.menuService.toggleMenu();
      },
      updateActiveMenu: function updateActiveMenu(menu) {
        this.set('activeApplicationLink', menu);
      },
      updateMenuSelection: function updateMenuSelection(menu) {
        if (menu) {
          this.menuService.set('pendingMenu', menu);
          this.menuService.updateActiveMenu();
        }
      },
      closeMenu: function closeMenu() {
        this.menuService.closeMenu();
      },
      transitionTo: function transitionTo(route) {
        var customer = this.model;

        var url = this.deepLinkUrl + '?targetRoute=' + route + '&customerID={{customer.id}}';

        url = url.replace(/{{\w+(\.\w*)*}}/g, function (match) {
          switch (match) {
            case '{{customer.externalReference}}':
              return customer.get('externalReference');
            case '{{customer.id}}':
              return customer.get('id');
          }
        });

        window.location = url;
      }
    }
  });
});