define('ux-pcc/initializers/device-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var deviceType = Ember.Object.extend({
      currentSize: 0,
      mobile: Ember.computed.equal('currentType', 'XS'),
      tablet: Ember.computed.equal('currentType', 'SM'),
      tabletLandscape: Ember.computed('currentType', function () {
        return this.get('currentType') === 'LG' && this.get('currentSize') < 1025;
      }),
      desktop: Ember.computed.equal('currentType', 'LG'),
      currentType: Ember.computed('currentSize', function () {
        if (this.get('currentSize') < 768) {
          return 'XS';
        } else if (this.get('currentSize') < 992) {
          return 'SM';
        } else {
          return 'LG';
        }
      })
    }).create();

    var screenSizeChanged = function screenSizeChanged() {
      deviceType.set('currentSize', Ember.$(window).width());
    };

    screenSizeChanged();
    Ember.$(window).on('resize', screenSizeChanged);

    application.register('deviceType:main', deviceType, { instantiate: false });
    application.inject('view', 'deviceType', 'deviceType:main');
    application.inject('component', 'deviceType', 'deviceType:main');
    application.inject('route', 'deviceType', 'deviceType:main');
    application.inject('controller', 'deviceType', 'deviceType:main');
    application.inject('service', 'deviceType', 'deviceType:main');
  }

  exports.default = {
    name: 'device-type',
    initialize: initialize
  };
});