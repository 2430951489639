define('ux-pcc/components/panels/pantry/cc-payment', ['exports', 'ux-components/components/panels/pantry/cc-payment'], function (exports, _ccPayment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ccPayment.default;
    }
  });
});