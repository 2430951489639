define('ux-pcc/models/marketing-campaign', ['exports', 'ux-data/models/marketing-campaign'], function (exports, _marketingCampaign) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marketingCampaign.default;
    }
  });
});