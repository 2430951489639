define('ux-pcc/controllers/customer/performance/report', ['exports', 'ux-pcc/controllers/customer/performance/abstract-matrix', 'moment', 'lodash', 'accounting', 'ux-components/utils/format-currency'], function (exports, _abstractMatrix, _moment, _lodash, _accounting, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractMatrix.default.extend(Ember.Evented, {
    queryParams: ['displayType', 'filterType', 'beginDate', 'endDate', 'hideRooms', 'hideInactive'],

    table: Ember.inject.service(),

    displayType: 'all',
    roomsTab: Ember.computed('displayType', function () {
      return this.get('displayType') !== 'all';
    }),

    filterType: 'rooms',

    pricePointAllocations: [],
    pricePointColumnHeaders: [],
    hideFilters: true,

    hideRooms: true,
    hideInactive: false,

    isMobile: false,
    isDesktop: false,
    isNonMobile: Ember.computed.not('isMobile'),
    isNonDesktop: Ember.computed.not('isDesktop'),
    isTablet: Ember.computed.and('isNonMobile', 'isNonDesktop'),

    fixedColumnCount: Ember.computed('displayType', function () {
      return this.get('displayType') === 'all' ? 3 : 2;
    }),

    beginDate: null,
    endDate: null,
    minEndDate: Ember.computed('beginDate', function () {
      var minDate = this.get('beginDate');
      if (typeof minDate === 'string') {
        // let tz = this.tz.get("customer");
        // Need to set back one day so you can pick the same as the current
        minDate = (0, _moment.default)(minDate).subtract(1, 'day').startOf('day');
      }
      return minDate;
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate')
      };
    }),

    _triggerResize: Ember.observer('hideRooms', 'hideInactive', function () {
      Ember.run.schedule("afterRender", function () {
        Ember.$(window).trigger('resize.fixed-column');
      });
    }),

    _mapValues: function _mapValues(model) {
      var _this = this;

      var showRooms = this.get('roomsTab');
      var tz = this.tz.get("customer");
      var today = _moment.default.tz(tz).startOf("day");
      var isMobile = this.get('isMobile');

      var generateDefaultClassNames = function generateDefaultClassNames(date, actionable) {
        var names = [];

        if (date.valueOf() === today.valueOf()) {
          names.push("active");
        }

        if (actionable) {
          names.push("actionable");
        }

        return names;
      };

      var yieldConsumedHTML = (0, _lodash.template)('<div class="yield-consumed-amounts">\n      <div class="yield-amount">\n        <span data-id="value"><%= data.y %></span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.rooms') + '</span>\n      </div>\n      <div class="consumed-amount">\n        <span data-id="value"><%= data.c %></span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.rooms') + ' %></span>\n      </div>\n    </div>', { 'variable': "data" });

      // Allocated HTML Template
      var inventoryHTML = (0, _lodash.template)('<div class="allocated-column center">\n      <div class="allocated-percent">\n        <span data-id="value" class="percent"><%= data.percent %></span>\n      </div>', { 'variable': "data" });

      // Rule Level HTML Template
      var emptyPricePointHTML = function emptyPricePointHTML() {
        if (!_this.get('roomsTab')) {
          return '<div class="price-point-cell-top">&mdash;</div>\n        <div class="price-point-cell-bottom">&mdash;</div>';
        } else {
          return '<div class="centered">&mdash;</div>';
        }
      };

      var pricePointHTML = (0, _lodash.template)('<div data-value="<%= data.val %>" data-lead="<%= data.lead %>" class="price-point-cell-top center">\n      <div class="price-point-value price-point-allocated-pct">\n        <span data-id="value" class="percent"><%= data.val %></span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.allocated') + '</span>\n      </div>\n      <div class="price-point-value price-point-lead">\n        <%= (data.lead != void(0)) ? \'<span class="badge">\' + data.lead + \'</span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.lead_time') + '</span>\' : \'&nbsp;\' %>\n      </div>\n      </div>\n      <div data-value="<%= data.val %>" data-lead="<%= data.lead %>" class="price-point-cell-bottom center">\n      <div class="price-point-value price-point-left-to-sell">\n        <%= (data.leftToSell != void(0)) ? \'<span class="">\' +  data.leftToSell + \'</span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.rooms') + '</span>\' : \'&nbsp;\' %>\n      </div>\n      <div class="price-point-value price-point-allocated-rooms">\n        <%= (data.allocated != void(0)) ? \'<span class="">\' +  data.allocated + \'</span> <span class="annotation">' + this.get('i18n').t('customer.performance.report.left_to_sell') + '</span>\' : \'&nbsp;\' %>\n      </div>\n    </div>', { 'variable': "data" });

      var roomsSoldHTML = (0, _lodash.template)('<div data-value="<%= data.val %>" data-lead="<%= data.lead %>" class="price-point-cell-top center">\n      <div style="display: inline-block; font-weight: bold; min-width: 3em;">\n        <div data-id="value"><%= data.val %></div>\n      </div>\n    </div>', { 'variable': "data" });

      // Finalized data containers
      this.pricePointColumnHeaders = [];
      var dataValues = {};

      // Loop through the calendar items
      model.forEach(function (item, i) {

        if (item.error) {
          //Inform about the error
          _this.send('flash', "<i class='fa fa-warning'></i> " + _this.get('i18n').t("customer.performance.report.load_error") + " '" + item.error + "'.", 'danger');
        } else {
          var key = item.date;
          var date = _moment.default.tz(key, tz);
          var isBeforeToday = date.isBefore(today);

          var yieldable = parseFloat(item.yieldable);
          var allocated = parseFloat(item.allocated);
          var consumed = parseFloat(item.consumed);

          // Set up the table data for each date row
          dataValues[key] = {
            pricePoints: {}
          };

          // --> Inventory

          // Yieldable
          var yieldableAmt = _accounting.default.formatNumber(yieldable, 0);
          dataValues[key].yieldable_consumed = {
            value: yieldConsumedHTML({ y: yieldableAmt, c: consumed }),
            classNames: ["center"].concat(generateDefaultClassNames(date))
          };

          var allocatedPct = Math.round(allocated / yieldable * 100);
          if (isNaN(allocatedPct)) {
            allocatedPct = 0;
          }

          if (!showRooms) {
            dataValues[key].allocation = {
              count: allocated,
              value: inventoryHTML({ count: allocated, percent: allocatedPct }),
              classNames: ["center"].concat(generateDefaultClassNames(date))
            };
          }

          var activeIndexKey = null;

          // Loop through the price point data elements to populate the table rows
          item.data.forEach(function (entry, j) {

            var pricePoint = (0, _formatCurrency.default)(entry.pricePoint, 'USD');

            var pricePointKey = isMobile ? pricePoint : 'rule_' + j;
            // Grab price point as column names on the first day as you are looping through them (all days will have same number of price points)
            if (i === 0 && !isMobile) {
              _this.pricePointColumnHeaders.push(pricePoint);
              // Keep a mapping of the number of items allocated for hiding the column
              _this.pricePointAllocations[pricePoint] = 0;
            }

            var decoratorClasses = [];
            var entryAllocated = parseFloat(entry.allocated);
            // Keep track of the allocations for each price point to help determine which rows/columns to hide when hiding empty price points is requested
            if (isMobile) {
              _this.pricePointAllocations[pricePoint] = entryAllocated;
            } else {
              _this.pricePointAllocations[pricePoint] += entryAllocated;
            }
            // If mobile and nothing is allocated, add the empty class
            if (isMobile && entryAllocated === 0) {
              decoratorClasses.push("no-allocations");
            }

            var entryConsumed = parseFloat(entry.consumed);
            var entryLeftToSell = entry.left ? parseFloat(entry.left) : void 0;
            var entryLeadTime = entry.leadTime && parseInt(entry.leadTime) >= 0 ? parseInt(entry.leadTime) : void 0;

            // Initialize the column value
            dataValues[key].pricePoints[pricePointKey] = { id: pricePointKey, value: void 0 };

            // set active index highlight on the first cell of the row with inventory left to sell
            if (!activeIndexKey && entryLeftToSell > 0) {
              decoratorClasses.push("active-cell-highlight");
              activeIndexKey = pricePointKey;
            }

            // Move the active highlight if there is a lead time value as appropriate
            if (entryLeadTime !== void 0 && !isBeforeToday && today.isAfter((0, _moment.default)(date).subtract(entryLeadTime + 1, 'days')) && entryLeftToSell > 0) {

              if (activeIndexKey) {
                var activePricePoint = dataValues[key].pricePoints[activeIndexKey].value;
                if (activePricePoint) {
                  activePricePoint["classNames"] = activePricePoint["classNames"].filter(function (c) {
                    return c !== "active-cell-highlight";
                  });
                }
              }

              decoratorClasses.push("active-cell-highlight");
              activeIndexKey = pricePointKey;
            }

            // Set the cell value
            dataValues[key].pricePoints[pricePointKey].value = {
              value: entryAllocated === 0 && entryConsumed === 0 && entryLeadTime === void 0 ? emptyPricePointHTML() : showRooms ? roomsSoldHTML({ val: entryConsumed }) : pricePointHTML({
                val: Math.round(parseFloat(entry.allocPct) * 100),
                leftToSell: entryLeftToSell,
                allocated: entryAllocated,
                lead: entryLeadTime
              }),
              actionable: !isMobile && entryAllocated !== 0 && !showRooms,
              classNames: [].concat(generateDefaultClassNames(date, !isMobile && entryAllocated !== 0 && !showRooms)).concat(decoratorClasses)
            };
          });
        }
      });

      // Don't need to do this for mobile as it was already handled when processing the row
      if (!isMobile && this.pricePointColumnHeaders.length > 0) {
        // Now that we have finished processing, need to loop back through and throw a class on the cell if the column is empty
        this.pricePointColumnHeaders.forEach(function (entry, j) {
          if (_this.pricePointAllocations[entry] === 0) {
            // Find that column in the data value price points
            for (var key in dataValues) {
              dataValues[key].pricePoints['rule_' + j].value.classNames.push("no-allocations");
            }
          }
        });
      }

      return dataValues;
    },


    _processData: Ember.observer('model', 'displayType', function () {
      var _this2 = this;

      var dateLabelHtml = function dateLabelHtml(date) {
        var formattedDate = date.format('L');
        formattedDate = formattedDate.substring(0, formattedDate.length - 4) + formattedDate.substring(formattedDate.length - 2);

        if (_this2.get('roomsTab')) {
          return '<div><span class="date-day">' + date.format("ddd") + ',</span> <span class="date-full">' + formattedDate + '</span></div>';
        } else {
          return '<div><span class="date-day split-date">' + date.format("ddd") + ',<br></span><span class="date-full">' + formattedDate + '</span></div>';
        }
      };

      var model = this.get('roomsTab') ? this.get('model.roomsSold') : this.get('model.barPlanAllocations');

      if (model) {
        var mapped = this._mapValues(model);

        var tz = this.tz.get("customer");
        var today = _moment.default.tz(tz).startOf("day");

        var rows = [];

        if (this.get('isNonMobile')) {
          rows = Object.keys(mapped).map(function (m) {
            var date = (0, _moment.default)(m).tz(tz);
            var names = [];
            if (date.valueOf() === today.valueOf()) {
              names.push("active");
            }

            if (date.day() === 0 || date.day() === 6) {
              names.push("guide");
            }

            (0, _lodash.values)(mapped[m].pricePoints).forEach(function (point) {
              mapped[m][point.id] = point.value;
            });
            delete mapped[m].pricePoints;

            return {
              id: m,
              label: dateLabelHtml(date),
              labelClassNames: names.join(" "),
              values: mapped[m]
            };
          });
        } else {
          // Load the mobile records, turning the price point into the label
          var keys = Object.keys(mapped);

          if (keys && keys.length > 0) {
            // Push the Yld/Consumed and Allocated rows on to the stack
            rows.push({
              id: "yieldable_consumed",
              label: '' + this.get('i18n').t('customer.performance.report.yield_consumed'),
              labelClassNames: "row-label",
              values: mapped[keys[0]].yieldable_consumed
            });

            if (!this.get('roomsTab')) {
              rows.push({
                id: "allocation",
                label: '' + this.get('i18n').t('customer.performance.report.allocated'),
                labelClassNames: "row-label",
                values: mapped[keys[0]].allocation
              });
            }

            (0, _lodash.values)(mapped[keys[0]].pricePoints).forEach(function (point) {
              var map = {};
              map['value'] = point.value;
              var labelClass = _this2.pricePointAllocations[point.id] === 0 ? "no-allocations" : "";

              rows.push({
                id: point.id,
                label: point.id,
                labelClassNames: labelClass,
                values: map
              });
            });
          }
        }

        var groups = [{
          id: "calendars",
          label: "Date",
          rows: Ember.A()
        }];

        if (rows.length > 0) {
          var max = rows.length;
          var step = 5;
          var index = 0;

          var _chunkData = void 0;
          _chunkData = function chunkData() {
            index += step;

            if (max >= index) {
              Ember.run.later(_chunkData, 100);
            } else {
              _this2.get('table').trigger('tableInvalidate');
            }

            groups[0].rows.addObjects(rows.splice(0, step));
          };

          Ember.run.scheduleOnce("afterRender", _chunkData);

          groups[0].rows.addObjects(rows.splice(0, step));
        }

        // Set up initial table
        this.set('performanceTableData', {
          groups: groups,
          columns: this._columnsForRange()
        });
      }
    }),

    _columnsForRange: Ember.observer('displayType', function () {
      var _this3 = this;

      var columns = [];

      if (this.get('isNonMobile')) {
        columns.push({
          key: "yieldable_consumed",
          label: '' + this.get('i18n').t('customer.performance.report.yield_consumed'),
          classNames: "centered"
        });

        if (!this.get('roomsTab')) {
          columns.push({
            key: "allocation",
            label: '' + this.get('i18n').t('customer.performance.report.allocated_short'),
            classNames: "centered"
          });
        }

        var entries = this.pricePointColumnHeaders;

        entries.forEach(function (entry, i) {
          var names = ["centered"];
          if (_this3.pricePointAllocations[entry] === 0) {
            names.push("no-allocations");
          }

          columns.push({
            key: 'rule_' + i,
            label: entry,
            classNames: names.join(" ")
          });
        });

        return columns;
      } else {
        columns.push({
          key: 'value',
          label: 'Values',
          classNames: "centered"
        });
        return columns;
      }
    }),

    actions: {
      viewFilters: function viewFilters() {
        this.toggleProperty('hideFilters');
      },
      tableReady: function tableReady() {
        var clearPopout = function clearPopout() {
          var popout = Ember.$(".popout");
          if (popout) {
            popout.remove();
            var cell = Ember.$(".popped");
            cell.removeClass("popped");
          }
        };

        // Register an event to close any popouts when clicking outside the popable cells
        Ember.$(document).on('click', function (event) {
          if (!Ember.$(Ember.$(event.target).closest('td').children()[0]).hasClass("popped")) {
            // remove the popout and reset the selected cell
            clearPopout();
          }
        });

        Ember.$('div.ember-view.table-basic.table-horizontal.table-responsive.zero-lot').on('scroll', function () {
          // remove the popout and reset the selected cell
          clearPopout();
        });
      },
      popCell: function popCell(context) {
        var clearPopout = function clearPopout() {
          var popout = Ember.$(".popout");
          if (popout) {
            popout.remove();
            var _cell = Ember.$(".popped");
            _cell.removeClass("popped");
          }
        };

        var cell = context.$(':first');
        if (Ember.$(cell).hasClass("popped")) {
          clearPopout();
        } else {
          // Remove any current popout window if it exists for another cell
          clearPopout();

          // Now popout the new cell
          var newCell = Ember.$(cell).clone(true);
          newCell.addClass("popout");
          // Add style to cell being expanded
          cell.addClass("popped");

          var cellPosition = cell.position();

          Ember.$(context.element).append(newCell);
          newCell.css("marginLeft", cellPosition.left - 75 + "px");
          var popoutHeightAdjust = this.get("hideRooms") ? newCell.height() + 35 : 205;
          newCell.css("marginTop", cellPosition.top - popoutHeightAdjust + "px");
        }
      }
    }
  });
});