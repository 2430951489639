define('ux-pcc/routes/customer-reference', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model: function model(params) {

      return Ember.RSVP.hash({
        customer: this.ajax.request('/rest/v1/customers/customerByExternalReference/' + params.code).then(function (results) {
          return results.customer;
        }),
        location: params.location
      });
    },
    afterModel: function afterModel(model /*, transition*/) {
      var property = model.customer;
      if (property) {
        this.transitionTo('/customers/' + property.id + '/' + model.location);
      } else {
        this.transitionTo('/http404');
      }
    }
  });
});