define('ux-pcc/components/charts/legend-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'span',

    toggleItem: function toggleItem() {
      this.toggleProperty('enabled');
    }
  });
});