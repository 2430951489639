define('ux-pcc/components/codes/code-list', ['exports', 'ux-components/components/codes/code-list'], function (exports, _codeList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _codeList.default;
    }
  });
});