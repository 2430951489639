define('ux-pcc/controllers/yield-manager/analyze/-filter-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    model: null,
    analyze: Ember.inject.controller('yield-manager/analyze'),
    opportunitiesActualLevelColumns: null,
    opportunitiesActualFilterColumns: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('opportunitiesActualLevelColumns', []);
      this.set('opportunitiesActualFilterColumns', []);
    },


    setColumns: Ember.on('didInsertElement', Ember.observer('model', function () {
      this.set('opportunitiesActualLevelColumns', Ember.copy(this.get('model.opportunitiesActualLevelColumns')));
      this.set('opportunitiesActualFilterColumns', Ember.copy(this.get('model.opportunitiesActualFilterColumns')));
    })),

    actions: {
      toggleLevelColumn: function toggleLevelColumn(column) {
        if (this.get('opportunitiesActualLevelColumns').includes(column)) {
          this.get('opportunitiesActualLevelColumns').removeObject(column);
        } else {
          this.get('opportunitiesActualLevelColumns').push(column);
        }
      },
      toggleFilterColumn: function toggleFilterColumn(column) {
        if (this.get('opportunitiesActualFilterColumns').includes(column)) {
          this.get('opportunitiesActualFilterColumns').removeObject(column);
        } else {
          this.get('opportunitiesActualFilterColumns').push(column);
        }
      },
      save: function save() {
        if (this.get('model.onSave')) {
          this.get('model.onSave')(this.get('opportunitiesActualLevelColumns'), this.get('opportunitiesActualFilterColumns'));
        }
        this.set('opportunitiesActualLevelColumns', this.get('model.opportunitiesActualLevelColumns'));
        this.set('opportunitiesActualFilterColumns', this.get('model.opportunitiesActualFilterColumns'));
        this.send('removeModal');
      },
      cancel: function cancel() {
        this.set('opportunitiesActualLevelColumns', this.get('model.opportunitiesActualLevelColumns'));
        this.set('opportunitiesActualFilterColumns', this.get('model.opportunitiesActualFilterColumns'));
        this.send('removeModal');
      }
    }
  });
});