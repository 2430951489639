define('ux-pcc/controllers/rules-simple/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['ruleType'],

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    menu: Ember.inject.service(),

    ruleType: '',

    showElapsed: false,

    showModal: false,

    testRule: null,

    headerFields: Ember.computed('i18n.locale', function () {
      var headerFields = [];

      headerFields.pushObject({ property: 'shortDescription', label: this.i18n.t('rules-simple.name').toString() });
      headerFields.pushObject({ property: 'beginDateSort', label: this.i18n.t('rules-simple.date_range').toString() });
      headerFields.pushObject({ property: 'children.length', label: this.i18n.t('rules-simple.conditions').toString() });
      return headerFields;
    }),

    sortedHeaderFields: Ember.computed('headerFields', function () {
      return this.headerFields.filterBy('property');
    }),

    ruleConditionPrefix: Ember.computed('ruleType', function () {
      switch (this.ruleType) {
        case 'CANCEL':
          return 'cancel-';
        case 'DEPOSIT':
          return 'deposit-';
        case 'TAX':
          return 'tax-';
        case 'FEE':
          return 'fee-';
        case 'BASED_ON_ADJUSTMENT':
          return 'discount-';
        default:
          return '';
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('rulesSort', ['shortDescription']);
      this.set('testRule', { children: [{}, {}] });
      // Prevents popover from being 'centered' on its parent element if the center is off-screen
      this.set('popperOptions', {
        modifiers: {
          preventOverflow: {
            escapeWithReference: false
          }
        }
      });
    },


    ruleMenuItem: Ember.computed('menu.menus.[]', function () {
      return this.menu.flattenedMenus.findBy('reference', 'customer.pms.config.rules-simple') || {};
    }),

    ruleChildren: Ember.computed('ruleMenuItem', function () {
      var menus = {};
      menus[this.ruleMenuItem.actionUrl] = Ember.Object.create({
        "menu": this.ruleMenuItem
      });
      return menus;
    }),

    filteredRules: Ember.computed('model.rules.[]', 'showElapsed', function () {
      var _this = this;

      var rules = this.model;

      var today = _moment.default.tz(this.tz.customer).startOf('day');
      if (!this.showElapsed) {
        rules = rules.filter(function (rule) {
          return !rule.endDate || today.isSameOrBefore(_moment.default.tz(rule.endDate, _this.tz.customer));
        });
      }

      return rules;
    }),

    startPickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endPickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    actions: {
      sort: function sort(key, ascending) {
        if (ascending) {
          this.set('rulesSort', [key]);
        } else {
          this.set('rulesSort', [key + ':desc']);
        }
      },
      showModal: function showModal() {
        this.set('showModal', true);
      },
      closeModal: function closeModal() {
        this.set('showModal', false);
      },
      addCondition: function addCondition() {
        this.testRule.children.pushObject({});
      },
      removeCondition: function removeCondition(index) {
        this.testRule.children.removeAt(index);
      }
    }
  });
});