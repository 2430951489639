define('ux-pcc/controllers/availability/-base-modal', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    beginSlice: Ember.computed('model.selectedSlice', function () {
      return this.get('model.selectedSlice');
    }),

    beginSliceChanged: Ember.observer('beginSlice', function () {
      var slice = this.get('beginSlice');
      var value = "";
      // Strip to local for manipulation
      if (slice) {
        var converted = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));
        value = converted.format('LL');
      }

      // Update dependent picker
      var dependentPicker = Ember.$("#endPicker").data("DateTimePicker");
      if (dependentPicker) {
        dependentPicker.minDate(value);
        if ((0, _moment.default)(dependentPicker.date()) < (0, _moment.default)(value)) {
          dependentPicker.date(value);
        }
      }
    }),

    endSlice: Ember.computed('model.selectedSlice', function () {
      return this.get('model.selectedSlice');
    }),

    isMobile: Ember.computed.readOnly('model.isMobile'),

    previousPricingObserver: Ember.observer('previousPricing', 'model.selectedSlice', function () {
      if (this.get('previousPricing')) {
        this.set('beginSlice', this.get('previousPricing.beginSlice'));
        this.set('endSlice', this.get('previousPricing.endSlice'));
        this.set('day0', !this.get('previousPricing.day0'));
        this.set('day1', !this.get('previousPricing.day1'));
        this.set('day2', !this.get('previousPricing.day2'));
        this.set('day3', !this.get('previousPricing.day3'));
        this.set('day4', !this.get('previousPricing.day4'));
        this.set('day5', !this.get('previousPricing.day5'));
        this.set('day6', !this.get('previousPricing.day6'));
      } else {
        this.set('beginSlice', this.get('model.selectedSlice'));
        this.set('endSlice', this.get('model.selectedSlice'));
        this.set('day0', true);
        this.set('day1', true);
        this.set('day2', true);
        this.set('day3', true);
        this.set('day4', true);
        this.set('day5', true);
        this.set('day6', true);
      }
    }),

    day0: true,
    day1: true,
    day2: true,
    day3: true,
    day4: true,
    day5: true,
    day6: true,
    priceSeason: "NONE",

    _serialize: function _serialize() {
      var beginSlice = this.get("beginSlice");
      var endSlice = this.get("endSlice");

      return {
        beginSlice: !beginSlice || typeof beginSlice === "string" ? beginSlice : beginSlice.format(),
        endSlice: !endSlice || typeof endSlice === "string" ? endSlice : endSlice.format(),
        day0: this.get('day0'),
        day1: this.get('day1'),
        day2: this.get('day2'),
        day3: this.get('day3'),
        day4: this.get('day4'),
        day5: this.get('day5'),
        day6: this.get('day6'),
        priceSeason: this.get('priceSeason')
      };
    }
  });
});