define('ux-pcc/components/guarantee-panel/guarantee-form-corporation', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-corporation'], function (exports, _guaranteeFormCorporation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormCorporation.default;
    }
  });
});