define('ux-pcc/routes/rules-simple/edit', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notifications: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('rule', params.id, { include: 'children' });
    },


    actions: {
      saveRuleSet: function saveRuleSet() {
        var _this = this;

        var model = this.get('controller.model');
        // Force isDirty to ensure children save
        var beginDate = (0, _moment.default)(model.get('beginDate')).utc().format('YYYY-MM-DD');
        var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
        model.set('beginDate', (0, _moment.default)(beginDate).startOf('day'));
        model.set('endDate', endDate ? (0, _moment.default)(endDate).startOf('day') : null);
        model.save().then(function () {
          // Success!
          _this.controllerFor('rules-simple').send('refresh');
          _this.replaceWith('rules-simple');
          _this.notifications.success(_this.i18n.t('rules.index.success_save_message', { ruleName: model.get('shortDescription') }));
        }).catch(function (error) {
          return _this.notifications.error(error);
        });
      }
    }
  });
});