define('ux-pcc/components/flexible-image', ['exports', 'ux-components/components/flexible-image'], function (exports, _flexibleImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _flexibleImage.default;
    }
  });
});