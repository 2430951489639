define('ux-pcc/routes/competitive-sets/index', ['exports', 'ux-pcc/mixins/route-alias-mixin'], function (exports, _routeAliasMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeAliasMixin.default, {

    aliasRouteName: 'competitive-sets.list'

  });
});