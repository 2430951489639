define('ux-pcc/routes/customer/performance/portfolio', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      beginDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      _page: {
        refreshModel: true
      },
      resultsPerPage: {
        refreshModel: true
      },
      tag: {
        refreshModel: true
      },
      query: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var _this = this;

      var customer = this.modelFor('customer');
      var tz = this.tz.get("customer");
      var sort = params.orderBy || "name";
      var parameters = {
        customerID: customer.get('id'),
        frequency: 'DAY',
        pageSort: sort, //the sorting of individual fields on CustomerPerformance model isn't supported with back-end paging (customers are sorted prior to obtaining data)
        pageSize: params.resultsPerPage || 50,
        pageNumber: params._page || 1,
        limit: 1500
      };

      var beginDate = params.beginDate || _moment.default.tz(tz);
      beginDate = _moment.default.tz(beginDate, tz).startOf('day');
      parameters.begindate = beginDate.format();

      if (params.endDate) {
        var endDate = _moment.default.tz(params.endDate, tz).startOf('day');
        // Check to make sure the endDate is > the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = beginDate.clone().startOf('day');
        }
        parameters.enddate = endDate.format();
      } else {
        parameters.enddate = beginDate.clone().startOf('day').format();
      }

      if (params.tag) {
        parameters.tag = encodeURIComponent(params.tag);
      }

      if (params.query) {
        parameters.q = encodeURIComponent(params.query);
      }

      this.controllerFor('customer.performance.portfolio').set('loading', true);

      return this.get('store').query('customer-performance', parameters).then(function (results) {
        _this.set('meta', results.get('meta'));
        return results || [];
      });
    },


    setupController: function setupController(controller, model) {
      var tz = this.tz.get("customer");
      var beginDate = void 0;
      var endDate = void 0;

      // Set the controller begin and end dates
      if (controller.get("beginDate")) {
        beginDate = _moment.default.tz(controller.get('beginDate'), tz).startOf('day');
      } else {
        beginDate = _moment.default.tz(tz).startOf('day');
      }

      if (controller.get("endDate")) {
        endDate = _moment.default.tz(controller.get('endDate'), tz).startOf('day');
        // Check to make sure the endDate is > the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = beginDate.clone();
        }
        controller.set('endDate', endDate.format("YYYY-MM-DD"));
      } else {
        controller.set('endDate', beginDate.clone().startOf('day').format());
      }

      controller.set('beginDate', beginDate.format("YYYY-MM-DD"));
      controller.set('totalResults', this.get('meta.resultsFound'));
      controller.set('tags', (this.get('meta.tags') || []).sort());
      controller.set('loading', false);

      this._super(controller, model);
    },
    actions: {
      loading: function loading() {
        return false;
      }
    }
  });
});