define('ux-pcc/router', ['exports', 'ux-pcc/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login', { resetNamespace: true }, function () {
      this.route('callback');
    });

    this.route('logout');

    this.route('customer-reference');

    this.route('customer', { path: '/customers/:customer_id', resetNamespace: true }, function () {
      this.route('performance', function () {
        this.route('dashboard');
        this.route('personal-dashboard');
        this.route('inventory');
        this.route('rates');
        this.route('pricing');
        this.route('market');
        this.route('bookings');
        this.route('matrix');
        this.route('matrix-pricing');
        this.route('report');
        this.route('data-metrics');
        this.route('portfolio');
        this.route('room-types', { path: '/types' });
        this.route('channels');
        this.route('data-monitoring', { path: '/data-monitoring', resetNamespace: true }, function () {
          this.route('detail', { path: '/:id' });
        });
        this.route('pacing', { path: '/pacing', resetNamespace: true }, function () {
          this.route('daily');
          this.route('intraday');
        });
        this.route('auto-matrix-pricing');
        this.route('matrix-pricing-target');
        this.route('stayover');
      });

      this.route('sister-properties');

      this.route('yield-manager', { path: 'yield-management', resetNamespace: true }, function () {
        this.route('dashboard', function () {
          this.route('view', { path: '/view/:id' });
        });

        this.route('analyze', function () {
          this.route('table', function () {
            this.route('opportunities');
            this.route('trends');
          });
        });

        this.route('view', function () {});
      });

      this.route('dashboard-views', { resetNamespace: true }, function () {});

      this.route('channel-restatus', { path: 'channel-restatus', resetNamespace: true });

      this.route('reservations', { resetNamespace: true }, function () {
        this.route('reservation', { path: '/:reservation_id', resetNamespace: true }, function () {
          this.route('view');
        });
      });

      this.route('alerts', { resetNamespace: true }, function () {});

      this.route('portfolios', { resetNamespace: true }, function () {
        this.route('new');
        this.route('portfolio', { path: '/:id', resetNamespace: true });
      });

      this.route('competitor-pricing', { resetNamespace: true }, function () {});

      this.route('manage-competitors', { resetNamespace: true }, function () {});

      this.route('competitive-sets', { resetNamespace: true }, function () {
        this.route('new');
        this.route('add-pricing');
        this.route('list', { path: '/list' }, function () {
          this.route('detail', { path: '/:id' });
        });
        this.route('competitive-set', { path: '/:id', resetNamespace: true });
      });

      this.route('affiliates', { resetNamespace: true }, function () {
        this.route('new');
        this.route('affiliate', { path: '/:id', resetNamespace: true });
      });

      this.route('tags', { resetNamespace: true }, function () {});

      this.route('rules', { resetNamespace: true }, function () {
        this.route('new');
        this.route('edit', { path: '/:id' });
      });

      this.route('rules-advanced', { resetNamespace: true }, function () {
        this.route('new');
        this.route('edit', { path: '/:id' });
      });

      this.route('rule-rates', { resetNamespace: true }, function () {
        this.route('new');
        this.route('edit', { path: '/:id' });
      });

      this.route('rules-simple', { resetNamespace: true }, function () {
        this.route('new');
        this.route('edit', { path: '/:id' });
      });

      this.route('rules-feed', { resetNamespace: true }, function () {});

      this.route('revenue', { resetNamespace: true }, function () {});
      this.route('adhoc-sql', { resetNamespace: true }, function () {});

      this.route('monthly-booking-summary');
    });

    // All other routes
    this.route('http404', { path: '*path' });
    this.route('deep-link');
  });

  if (typeof Appcues !== 'undefined') {
    Router.reopen({
      checkForAppcues: Ember.on('didInsertElement', function () {
        Ember.run.scheduleOnce('afterRender', function () {
          Appcues.start();
        });
      })
    });
  } else {
    console.warn('Appcues failed to load.');
  }

  exports.default = Router;
});