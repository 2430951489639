define('ux-pcc/components/segment-graph', ['exports', 'moment', 'accounting'], function (exports, _moment, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),
    tagName: "",
    data: null,
    segments: null,
    loading: true,
    legendColumns: 3,
    rollupStatus: 'DETAIL',
    containerClassName: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchData();
    },
    _fetchData: function _fetchData() {
      var _this = this;

      var selectedDate = _moment.default.tz(this.get('beginDate'), this.get('tz')).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), this.get('tz')).endOf('day');

      if (endDate.isBefore(selectedDate)) {
        return;
      }

      this.set('loading', true);

      var params = {
        customerid: this.get('customerID'),
        begindate: selectedDate.format(),
        enddate: endDate.format(),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus')
      };

      if (this.get('filter')) {
        params.filterValues = this.get('filter');
      }

      var requests = [this.ajax.request('/rest/v1/analyticEntryAggregation/process', { data: params }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'Segment-graph').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        return _this.set('data', { analytics: data });
      }).catch(function () {
        return _this.set('data', { analytics: [] });
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    dataObserver: Ember.observer('customerID', 'beginDate', 'endDate', function () {
      this._fetchData();
    }),

    pieChart: Ember.computed.equal("chartType", "pie"),
    flagChart: Ember.computed.equal("chartType", "flag"),

    rotateGraph: Ember.computed('chartValues.segments.length', 'deviceType.mobile', function () {
      if (this.get('deviceType.mobile')) {
        return true;
      } else {
        return this.get('chartValues.segments.length') > 5;
      }
    }),

    chartValues: Ember.computed('data', 'segments', function () {
      var _this2 = this;

      if (!this.get('data.analytics')) {
        return null;
      }

      var values = {};
      var segments = [];
      var entries = this.get('data.analytics');
      var displayChart = false;

      // Map the segment values for retrieval later
      var segmentValueMap = {};
      if (entries && entries.length > 0) {
        // let i=0;

        // Loop through the entries and fill out the map
        entries.forEach(function (e) {
          if (e) {
            if (e.dataValues) {
              segmentValueMap = e.dataValues;
            } else if (e.groupByValue && e.groupByValue !== "") {
              var segmentName = decodeURIComponent(e.groupByValue);
              // Add the value to the map
              segmentValueMap[segmentName] = parseFloat(e.dataValue);
            }
          }
        });

        var legendValues = this.get('segments');

        if (legendValues && legendValues.length > 0) {

          // Map the values to the appropriate segments and add the classes
          segments = legendValues.map(function (segment, i) {
            var label = segment.label;
            var key = segment.key;
            var val = 0;

            if (key in segmentValueMap) {
              val = segmentValueMap[key];
              displayChart = true;
            } else if (label in segmentValueMap) {
              val = segmentValueMap[label];
              displayChart = true;
            }
            return {
              "label": label,
              "value": _this2.get('flagChart') ? val : val >= 100 ? _accounting.default.formatNumber(val) : _accounting.default.formatNumber(val, 1),
              "pie_color_class": 'chart-color--' + i,
              "label_classes": 'chart-color--' + i,
              "value_classes": 'chart-color--' + i + ' percent'
            };
          });
        } else {
          // Use the group by values as the labels
          for (var key in segmentValueMap) {
            var val = segmentValueMap[key];
            displayChart = true;
            var segment = {
              "label": key,
              "value": this.get('flagChart') ? val : val >= 100 ? _accounting.default.formatNumber(val) : _accounting.default.formatNumber(val, 1)
            };
            segments.push(segment);
          }
          // Sort the segments by the label
          segments = segments.sortBy('label');
          // Now loop through and add the correct segment index to the sorted list
          segments.forEach(function (e, i) {
            e.pie_color_class = 'chart-color--' + i;
            e.label_classes = 'chart-color--' + i;
            e.value_classes = 'chart-color--' + i + ' percent';
          });
        }
      }

      if (displayChart) {
        values.segments = segments;

        if (segments && segments.length > 0) {
          values.legend = segments;
        } else {
          values.legend = [];
        }
      }

      return values;
    })

  });
});