define('ux-pcc/routes/login', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashable.default, {

    session: Ember.inject.service(),

    beforeModel: function beforeModel() /*transition*/{
      this.session.prohibitAuthentication('index');
    }
  });
});