define('ux-pcc/models/rule-xref', ['exports', 'ux-data/models/rule-xref'], function (exports, _ruleXref) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ruleXref.default;
    }
  });
});