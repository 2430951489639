define('ux-pcc/components/rules/condition-values/range', ['exports', 'ux-pcc/components/rules/condition-values/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    validations: Ember.computed('i18n.locale', function () {
      return {};
    }),

    actions: {
      registerValidation: function registerValidation(e) {
        this.get('onRegisterValidation')(e);
      },
      unregisterValidation: function unregisterValidation(e) {
        this.get('onUnregisterValidation')(e);
      }
    }
  });
});