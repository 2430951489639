define('ux-pcc/routes/customer/performance/room-types', ['exports', 'ux-pcc/mixins/routes/customer/performance'], function (exports, _performance) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_performance.default, {

    // Need this to call the CustomerPerformanceRouteMixin.setupController
    // to set the dates correctly
    model: function model() {
      return {};
    }
  });
});