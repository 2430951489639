define('ux-pcc/routes/customer', ['exports', 'ux-pcc/mixins/flashable'], function (exports, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_flashable.default, {
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),

    menuService: Ember.inject.service('menu'),
    menus: Ember.computed.alias('menuService.menus'),
    authenticatedUserId: null,

    titleToken: function titleToken(model) {
      return model.get('name');
    },


    _localeObserver: Ember.observer('i18n.locale', function () {
      this.refresh();
    }),

    _refreshSession: function _refreshSession(userId) {
      var shouldRefresh = !Ember.isEmpty(this.authenticatedUserId);

      this.set('authenticatedUserId', userId);
      if (shouldRefresh) {
        this.refresh();
      }
    },


    _sessionObserver: Ember.observer('session.data.authenticated.userID', function () {
      // Apparently the authenticated.userId gets updated a lot, so need an additional check on the observer
      if (!Ember.isEmpty(this.get('session.data.authenticated.userID')) && this.authenticatedUserId !== this.get('session.data.authenticated.userID')) {
        Ember.run.once(this, '_refreshSession', this.get('session.data.authenticated.userID'));
      }
    }),

    beforeModel: function beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model: function model(params) {
      return this.store.findRecord('customer', params.customer_id, {
        include: 'address,settings,xref,tags,attributes',
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var settings = model.get('settings') || [];

      var timezone = 'UTC';

      // get timezone setting
      var tzSetting = settings.findBy('settingCode', 'TIMEZONE');
      if (tzSetting) {
        timezone = tzSetting.get('settingValue');
      }

      this.tz.set('customer', timezone);
      var propertyType = void 0;

      switch (model.get('customerType')) {
        case 'AFFILIATE':
          propertyType = 'affiliate';

          // DE197 - Open mobile menu on first entry from login for chain level users
          if (this.get('deviceType.mobile') && transition && transition.router && transition.router.oldState && transition.router.oldState.handlerInfos && transition.router.oldState.handlerInfos.length > 0) {
            var transitionFromLogin = transition.router.oldState.handlerInfos.findBy('name', 'login');
            if (transitionFromLogin) {
              this.menuService.set('isOpen', true);
            }
          }
          break;
        case 'CUSTOMERS':
          propertyType = 'portfolio';
          break;
        case 'CUSTOMER':
          propertyType = 'property';
          break;
        default:
          propertyType = model.get('customerType').toLowerCase();
      }

      var menuReference = null;

      // get menu reference setting for looking up the menu
      var menuReferenceSetting = settings.findBy('settingCode', 'PCC_MENU_REFERENCE');
      if (menuReferenceSetting) {
        menuReference = menuReferenceSetting.get('settingValue');
      }

      // If the menuReference setting hasn't been set, key off the propertyType
      if (!menuReference && propertyType) {
        menuReference = 'pcc.menu.' + propertyType;
      } else if (menuReference && propertyType && menuReference.includes('{{propertyType}}')) {
        menuReference = menuReference.replace('{{propertyType}}', propertyType);
      }

      var promises = [];
      // Fetch the menus for the current view
      if (menuReference) {
        promises.push(this.store.query('permission', {
          reference: menuReference,
          domain: 'menu',
          action: 'view',
          instance: propertyType
        }).then(function (menus) {

          menus = menus.sortBy('orderBy');

          _this.set('menus', menus);

          var children = [];

          if (menus.get('length') > 0 && menus.get('firstObject.children.length') > 0) {
            children = menus.get('firstObject.children').sortBy('orderBy');
          }

          // NOTE: When going directly to the customer route, there maybe a {CustomerType}_DEFAULT_LANDING_ACTION_URL setting
          // that will override the menu logic here. That code lives in routes/customer/index.js.  That said...
          // If the top level menu item does not have an action, or doesn't match the first child item,
          // set it to the first child menu item UNLESS the main menu action is a hard LINK
          var childMenu = children.get('length') > 0 ? children.get('firstObject') : '';
          if (menus.get('length') > 0 && (Ember.isEmpty(menus.get('firstObject.actionUrl')) || !Ember.isEmpty(childMenu) && menus.get('firstObject.actionType') !== 'LINK' && menus.get('firstObject.actionUrl') !== childMenu.get('actionUrl'))) {
            if (!Ember.isEmpty(childMenu)) {
              menus.set('firstObject.actionUrl', childMenu.get('actionUrl'));
              menus.set('firstObject.actionType', childMenu.get('actionType'));
            }
          }
        }));
      } else {
        promises.push(Ember.Object.create({ 'permissions': [] }));
      }

      promises.push(this.store.findRecord('customer', this.get('session.data.authenticated.customerID')).then(function (customer) {
        _this.set('userCustomer', customer);
      }));

      return Ember.RSVP.all(promises);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('metrics', this.metrics);
      controller.set('refreshingFlag', false);
      controller.set('userCustomer', this.userCustomer);
      if (this.menus) {
        var menus = this.menus;

        // Strip restricted menu items if customer it PRICE_BASED_ON;
        if (menus && controller.get('isSisterProperty')) {

          // Grab the Revenue Optimization menu item
          var revenueMenu = menus.findBy('actionUrl', 'customer.performance.dashboard');
          if (revenueMenu && revenueMenu.get('children')) {
            revenueMenu.set('children', revenueMenu.get('children').rejectBy('actionUrl', 'customer.sister-properties'));

            // Lock the BAR allocation menu item
            var barAllocationMenu = revenueMenu.get('children').findBy('actionUrl', 'customer.performance.matrix-pricing');
            if (barAllocationMenu) {
              barAllocationMenu.set('locked', true);
            }
          }
        }

        controller.set('menus', menus);
      } else {
        console.error('Invalid Customer Type. Unable to load menu!');
      }
    },


    actions: {
      didTransition: function didTransition() {
        this.menuService.updateActiveMenu();
      },
      reloadPage: function reloadPage() {
        this.refresh();
      }
    }
  });
});