define('ux-pcc/mixins/query-data-in-viewport', ['exports', 'ember-in-viewport'], function (exports, _emberInViewport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_emberInViewport.default, {

    inViewport: false,

    // Override in implementation to fetch the data to be displayed when component enters viewport
    queryDataMethod: null,
    isLoadingFlagName: 'loading',
    // Set to true to ignore viewport wait, and just query
    disableViewportLoad: false,

    // Define list of properties to watch for changes where the data should be requeried if in viewport
    queryDataWatchProperties: [],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        viewportSpy: true,
        viewportRefreshRate: 300
      });

      var props = this.get('queryDataWatchProperties') || [];

      props.forEach(function (property) {
        _this.addObserver(property, _this, function () {
          Ember.run.once(_this, 'resetInViewport');
        });
      });

      if (this.get('disableViewportLoad')) {
        this._fetchData();
        this.set('inViewport', true);
      }
    },


    /**
     * If the viewport is in view, then the data will be queried immediately.
     * If the viewport is NOT in view, then the inViewport flag will be reset and the next time
     * the component comes into view, the data will be queried
     */
    resetInViewport: function resetInViewport() {
      if (!this.get(this.get('isLoadingFlagName'))) {
        if (this.get('inViewport') && !this.get('viewportEntered') && !this.get('disableViewportLoad')) {
          this.set('inViewport', false);
        } else {
          this.notifyPropertyChange('inViewport');
        }
      }
    },
    _fetchData: function _fetchData() {
      var _this2 = this;

      if (typeof this.queryDataMethod === 'function') {
        this.set(this.get('isLoadingFlagName'), true);

        return this.queryDataMethod().finally(function () {
          return _this2.set(_this2.get('isLoadingFlagName'), false);
        });
      }
    },
    didEnterViewport: function didEnterViewport() {
      this.set('inViewport', true);
    },


    _inViewportObserver: Ember.observer('inViewport', function () {
      if (this.get('inViewport') || this.get('disableViewportLoad')) {
        this._fetchData();
      }
    })
  });
});