define('ux-pcc/controllers/customers/customer-form', ['exports', 'ux-components/controllers/customers/customer-form'], function (exports, _customerForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _customerForm.default;
    }
  });
});