define('ux-pcc/components/dashboard-widget/reservation-chart', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _moment, _stringifyQueryParams, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {
    classNames: ['widget-box'],

    ajax: Ember.inject.service(),

    data: null,
    loading: false,
    model: null,
    showComments: true,
    showReservationList: false,
    sliceResolution: 'day',
    tz: null,
    includeCancels: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    },


    currentSlide: Ember.computed('selectedPeriod', 'locked', {
      get: function get() {
        if (this.get('locked')) {
          return this.get('selectedPeriod') || 0;
        } else {
          return this.get('_currentSlide') || 0;
        }
      },
      set: function set(key, value) {
        this.set('_currentSlide', value);
        return value;
      }
    }),

    onToggleLocked: function onToggleLocked() {},
    onSlideChanged: function onSlideChanged() {},

    selectedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BEGIN_DATE'),

    selectedEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.END_DATE'),

    stlwDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLW_DATE_ERROR'),
    stlyDateErrorParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_DATE_ERROR'),

    beginDate: Ember.computed('currentSlide', 'model.view.dashboardViewParameters', function () {
      var params = this.get('model.view.dashboardViewParameters');
      var currentSlide = this.get('currentSlide');
      switch (currentSlide) {
        case 0:
          return Ember.get(params, 'BEGIN_DATE');
        case 1:
          return Ember.get(params, 'STLW_BEGIN_DATE');
        case 2:
          return Ember.get(params, 'STLY_BEGIN_DATE');
        default:
          return Ember.get(params, 'BEGIN_DATE');
      }
    }),

    endDate: Ember.computed('currentSlide', 'model.view.dashboardViewParameters', function () {
      var params = this.get('model.view.dashboardViewParameters');
      var currentSlide = this.get('currentSlide');
      switch (currentSlide) {
        case 0:
          return Ember.get(params, 'END_DATE');
        case 1:
          return Ember.get(params, 'STLW_END_DATE');
        case 2:
          return Ember.get(params, 'STLY_END_DATE');
        default:
          return Ember.get(params, 'END_DATE');
      }
    }),

    dateDisplay: Ember.computed('zoomStartDate', 'zoomEndDate', 'beginDate', 'endDate', 'currentSlide', 'hideTopBar', function () {
      var currentSlide = this.get('currentSlide');
      var settings = this.get('customer.settings');
      var startDate = (currentSlide === 0 ? this.get('zoomStartDate') : this.get('beginDate')) || (0, _moment.default)();
      var endDate = (currentSlide === 0 ? this.get('zoomEndDate') : this.get('endDate')) || (0, _moment.default)();
      var formattedDate = this.get('hideTopBar') ? this.i18n.t('yield-manager.analyze.arrival_dates_error') : (0, _dateFormatter.dateFormatter)(startDate, { settings: settings }) + ' \u2014 ' + (0, _dateFormatter.dateFormatter)(endDate, { settings: settings });
      return this.i18n.t('yield-manager.analyze.arrival_dates') + ': ' + formattedDate;
    }),

    dataProvider: Ember.computed('data', function () {
      var tz = this.get('tz');
      var mapped = this.get('data');
      if (!mapped) {
        return null;
      }

      var dateSort = function dateSort(o1, o2) {
        var date1 = _moment.default.tz(o1, tz);
        var date2 = _moment.default.tz(o2, tz);
        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
      };

      return Object.keys(mapped).sort(dateSort).map(function (key) {
        return mapped[key];
      });
    }),

    currencyCode: Ember.computed.readOnly('dataProvider.firstObject.currencyCode'),

    title: '',
    type: 'reservations',
    reservationsPct: '',
    daysPct: '',
    yieldPct: '',
    revenuePct: '',
    reservationsPctCP1: '',
    daysPctCP1: '',
    yieldPctCP1: '',
    revenuePctCP1: '',

    reservationsStly: '',
    daysStly: '',
    yieldStly: '',
    revenueStly: '',
    reservationsCP1: '',
    daysCP1: '',
    yieldCP1: '',
    revenueCP1: '',

    reservations: '',
    days: '',
    yield: '',
    revenue: '',

    showReservationsActuals: false,
    showDaysActuals: false,
    showYieldActuals: false,
    showRevenueActuals: false,

    hideTopBar: Ember.computed('currentSlide', 'stlwDateErrorParam', 'stlyDateErrorParam', function () {
      var currentSlide = this.get('currentSlide');
      return currentSlide === 1 && this.get('stlwDateErrorParam') || currentSlide === 2 && this.get('stlyDateErrorParam');
    }),

    scrollBarConfig: Ember.computed('isMobile', 'dataProvider.length', function () {
      var dataLength = this.get('dataProvider.length');
      var gridCount = dataLength < 12 ? dataLength : dataLength / 2 < 12 ? dataLength / 2 : 12;
      return {
        oppositeAxis: false,
        offset: 45,
        scrollbarHeight: 30,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.2,
        selectedBackgroundColor: '#888888',
        dragIconHeight: 20,
        dragIconWidth: 20,
        autoGridCount: false,
        gridCount: this.get('isMobile') ? 3 : gridCount,
        color: '#AAAAAA',
        dragIcon: 'dragIconRoundSmall',
        resizeEnabled: false // don't allow adjustment of the graph display period
      };
    }),

    dataFields: Ember.computed('title', 'type', 'i18n.locale', 'includeCancels', 'stlwDateErrorParam', 'stlyDateErrorParam', function () {
      var type = this.get('type');
      var reservationsLabel = '' + this.i18n.t('yield-manager.reservations-chart.' + type + '.reservations');
      var variationLabel = this.i18n.t('yield-manager.reservations-chart.' + type + '.reservations') + '/' + this.i18n.t('yield-manager.reservations-chart.' + type + '.running_days') + ' %';
      var daysLabel = '' + this.i18n.t('yield-manager.reservations-chart.' + type + '.running_days');
      var reservationsLegendLabel = '' + this.i18n.t('yield-manager.reservations-chart.' + type + '.legend.reservations');
      var daysLegendLabel = '' + this.i18n.t('yield-manager.reservations-chart.' + type + '.legend.running_days');

      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();

      return [
      // base
      Ember.Object.create({
        leftAxisTitle: reservationsLabel,
        rightAxisTitle: daysLabel,
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.reservation-chart.' + type) + '_' + this.i18n.t('yield-manager.file-prefix.ysga.cp0') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'count', 'duration', 'stlwPctCountVariance', 'stlwPctTotalVariance', 'stlyPctCountVariance', 'stlyPctTotalVariance'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'count',
          label: reservationsLegendLabel,
          format: 'number',
          lineClasses: 'reservations',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'duration',
          label: daysLegendLabel,
          format: 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }]
      }),
      // compare period 1
      Ember.Object.create({
        leftAxisTitle: variationLabel,
        rightAxisTitle: daysLabel,
        noData: this.get('stlwDateErrorParam'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.reservation-chart.' + type) + '_' + this.i18n.t('yield-manager.file-prefix.ysga.cp1') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'count', 'duration', 'stlwPctCountVariance', 'stlwPctTotalVariance', 'stlyPctCountVariance', 'stlyPctTotalVariance'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'stlwPctCountVariance',
          label: reservationsLegendLabel,
          format: 'percent',
          lineClasses: 'reservations',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'stlwPctTotalVariance',
          label: daysLegendLabel,
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }]
      }),
      // compare period 2
      Ember.Object.create({
        leftAxisTitle: variationLabel,
        rightAxisTitle: daysLabel,
        noData: this.get('stlyDateErrorParam'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.reservation-chart.' + type) + '_' + this.i18n.t('yield-manager.file-prefix.ysga.cp2') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'count', 'duration', 'stlwPctCountVariance', 'stlwPctTotalVariance', 'stlyPctCountVariance', 'stlyPctTotalVariance'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'stlyPctCountVariance',
          label: reservationsLegendLabel,
          format: 'percent',
          lineClasses: 'reservations',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'stlyPctTotalVariance',
          label: daysLegendLabel,
          format: 'percent',
          lineClasses: 'days',
          chartType: 'line',
          bulletType: 'circle',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'right'
        }]
      })];
    }),

    // probably going to need two more pairs of dates here
    _fireRequeryObserver: Ember.observer('zoomStartDate', 'zoomEndDate', 'filterChange', function () {
      if (this.get('zoomStartDate') && this.get('zoomEndDate')) {
        Ember.run.once(this, 'queryDataMethod');
      }
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var tz = this.get('tz');
      var view = JSON.parse(JSON.stringify(this.get('model.view')));

      // Set the dates to the zoomed dates
      Ember.set(view.dashboardViewParameters, 'BEGIN_DATE', _moment.default.tz(this.get('zoomStartDate'), tz));
      Ember.set(view.dashboardViewParameters, 'END_DATE', _moment.default.tz(this.get('zoomEndDate'), tz));

      // set groupBy to BEGIN_DATE
      Ember.set(view.dashboardViewParameters, 'GROUP_BY', 'BEGIN_DATE');

      // set viewBy to DAY
      Ember.set(view.dashboardViewParameters, 'VIEW_BY', 'DAY');

      if (!Ember.isEmpty(this.get('destinationStatus'))) {
        Ember.set(view.dashboardViewParameters, 'DESTINATION_STATUS', this.get('destinationStatus'));
      }

      var params = {
        aggregationType: 'BOOKING_DETAIL_AGGREGATOR',
        rollupStatus: 'ROLLUP',
        accumulate: false
      };

      var requests = {
        baseRequest: this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref) {
          var _ref$collections = _ref.collections,
              collections = _ref$collections === undefined ? [] : _ref$collections;
          return collections;
        })
      };

      var bookDate = _moment.default.tz(view.dashboardViewParameters.BOOK_DATE, tz).startOf('day');
      var beginDate = _moment.default.tz(view.dashboardViewParameters.BEGIN_DATE, tz).startOf('day');
      var endDate = _moment.default.tz(view.dashboardViewParameters.END_DATE, tz).startOf('day');

      var stlyBookDate = bookDate.clone().subtract(52, 'weeks');
      var stlyBeginDate = beginDate.clone().subtract(52, 'weeks');
      var stlyEndDate = endDate.clone().subtract(52, 'weeks');

      var cp2BookDate = _moment.default.tz(view.dashboardViewParameters.STLY_BOOK_DATE, tz).startOf('day');
      var cp2BeginDate = _moment.default.tz(view.dashboardViewParameters.STLY_BEGIN_DATE, tz).startOf('day');
      var cp2EndDate = _moment.default.tz(view.dashboardViewParameters.STLY_END_DATE, tz).startOf('day');

      var cp2DoesNotMatchStly = !stlyBookDate.isSame(cp2BookDate, 'day') || !stlyBeginDate.isSame(cp2BeginDate, 'day') || !stlyEndDate.isSame(cp2EndDate, 'day');

      if (cp2DoesNotMatchStly) {
        Ember.set(view.dashboardViewParameters, 'STLY_BOOK_DATE', stlyBookDate);
        Ember.set(view.dashboardViewParameters, 'STLY_BEGIN_DATE', stlyBeginDate);
        Ember.set(view.dashboardViewParameters, 'STLY_END_DATE', stlyEndDate);

        requests.stlyRequest = this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
          contentType: 'application/json',
          track: true,
          data: (0, _serializeDashboardView.default)(view, tz)
        }).then(function (_ref2) {
          var _ref2$collections = _ref2.collections,
              collections = _ref2$collections === undefined ? [] : _ref2$collections;
          return collections;
        });
      }

      this.set('loading', true);
      this.set('data', null);
      return Ember.RSVP.hash(requests, 'YSGA reservation data').then(function (_ref3) {
        var _ref3$baseRequest = _ref3.baseRequest,
            baseRequest = _ref3$baseRequest === undefined ? [] : _ref3$baseRequest,
            _ref3$stlyRequest = _ref3.stlyRequest,
            stlyRequest = _ref3$stlyRequest === undefined ? [] : _ref3$stlyRequest;

        var mapped = {};

        if (baseRequest.length === 0 || cp2DoesNotMatchStly && stlyRequest.length === 0) {
          _this.set('data', []);
          return;
        }

        baseRequest.forEach(function (e) {
          if (e.subContentType !== 'FULL_SPAN') {
            var variable = e.dataValues.date;
            var date = (0, _moment.default)(variable);
            var key = date.format();

            mapped[key] = e.dataValues;
            //add the slice
            mapped[key].formattedDate = (0, _dateFormatter.dateFormatter)(date, { settings: _this.get('customer.settings') });
            mapped[key].slice = key;
            mapped[key].currencyCode = e.currencyCode;
            mapped[key].dow = date.format('ddd');
          }
        });

        var fullSpanEntry = baseRequest.findBy('subContentType', 'FULL_SPAN');

        _this.setProperties({
          reservations: fullSpanEntry.dataValues.count,
          days: fullSpanEntry.dataValues.duration,
          yield: fullSpanEntry.dataValues.yield,
          revenue: fullSpanEntry.dataValues.amount,

          reservationsStly: fullSpanEntry.dataValues.stlyCount,
          daysStly: fullSpanEntry.dataValues.stlyDuration,
          yieldStly: fullSpanEntry.dataValues.stlyYield,
          revenueStly: fullSpanEntry.dataValues.stlyAmount,

          reservationsPct: fullSpanEntry.dataValues.stlyPctChangeCount,
          daysPct: fullSpanEntry.dataValues.stlyPctChangeDuration,
          yieldPct: fullSpanEntry.dataValues.stlyPctChangeYield,
          revenuePct: fullSpanEntry.dataValues.stlyPctChangeAmount,

          reservationsCP1: fullSpanEntry.dataValues.stlwCount,
          daysCP1: fullSpanEntry.dataValues.stlwDuration,
          yieldCP1: fullSpanEntry.dataValues.stlwYield,
          revenueCP1: fullSpanEntry.dataValues.stlwAmount,

          reservationsPctCP1: fullSpanEntry.dataValues.stlwPctChangeCount,
          daysPctCP1: fullSpanEntry.dataValues.stlwPctChangeDuration,
          yieldPctCP1: fullSpanEntry.dataValues.stlwPctChangeYield,
          revenuePctCP1: fullSpanEntry.dataValues.stlwPctChangeAmount,

          reservationsCP2: fullSpanEntry.dataValues.stlyCount,
          daysCP2: fullSpanEntry.dataValues.stlyDuration,
          yieldCP2: fullSpanEntry.dataValues.stlyYield,
          revenueCP2: fullSpanEntry.dataValues.stlyAmount,

          reservationsPctCP2: fullSpanEntry.dataValues.stlyPctChangeCount,
          daysPctCP2: fullSpanEntry.dataValues.stlyPctChangeDuration,
          yieldPctCP2: fullSpanEntry.dataValues.stlyPctChangeYield,
          revenuePctCP2: fullSpanEntry.dataValues.stlyPctChangeAmount
        });

        if (cp2DoesNotMatchStly) {
          var stlyFullSpanEntry = stlyRequest.findBy('subContentType', 'FULL_SPAN');

          _this.setProperties({
            reservationsStly: stlyFullSpanEntry.dataValues.stlyCount,
            daysStly: stlyFullSpanEntry.dataValues.stlyDuration,
            yieldStly: stlyFullSpanEntry.dataValues.stlyYield,
            revenueStly: stlyFullSpanEntry.dataValues.stlyAmount,

            reservationsPct: stlyFullSpanEntry.dataValues.stlyPctChangeCount,
            daysPct: stlyFullSpanEntry.dataValues.stlyPctChangeDuration,
            yieldPct: stlyFullSpanEntry.dataValues.stlyPctChangeYield,
            revenuePct: stlyFullSpanEntry.dataValues.stlyPctChangeAmount
          });
        }

        _this.set('data', mapped);
      }).catch(function () {
        return _this.set('data', []);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    actions: {
      toggleLocked: function toggleLocked() {
        this.get('onToggleLocked')(this.get('currentSlide'));
      },
      toggleActuals: function toggleActuals(value) {
        this.toggleProperty('show' + value + 'Actuals');
      },
      onSlideChanged: function onSlideChanged() {
        if (this.get('locked')) {
          this.get('onSlideChanged')(this.get('currentSlide'));
        }
      },
      viewReservationList: function viewReservationList() {
        var title = this.get('title').toString();
        var view = JSON.parse(JSON.stringify(this.get('model.view')));
        var tz = this.get('tz');

        // Set the dates to the zoomed dates
        Ember.set(view.dashboardViewParameters, 'BEGIN_DATE', _moment.default.tz(this.get('zoomStartDate'), tz));

        Ember.set(view.dashboardViewParameters, 'END_DATE', _moment.default.tz(this.get('zoomEndDate'), tz));

        // set groupBy to BEGIN_DATE
        Ember.set(view.dashboardViewParameters, 'GROUP_BY', 'BEGIN_DATE');

        // set viewBy to DAY
        Ember.set(view.dashboardViewParameters, 'VIEW_BY', 'DAY');

        // set limit of 1000 results
        Ember.set(view.dashboardViewParameters, 'DISPLAY_MAXIMUM', '1000');

        // set destination status
        if (!Ember.isEmpty(this.get('destinationStatus'))) {
          Ember.set(view.dashboardViewParameters, 'DESTINATION_STATUS', this.get('destinationStatus'));
        }

        this.openModal('yield-manager/reservation-list', {
          title: title,
          beginDate: this.get('zoomStartDate'),
          endDate: this.get('zoomEndDate'),
          tz: tz,
          customerID: this.get('customer.id'),
          view: view,
          customer: this.get('customer'),
          formatRevenueOptions: this.get('formatRevenueOptions')
        });
      }
    }
  });
});