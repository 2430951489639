define('ux-pcc/components/competitive-set/competitor-shopped-rates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isExpanded: false,
    classNames: ['shopped-rates'],
    currencyCode: null,
    model: null,
    timestamp: null,
    onSave: null,

    rates: Ember.computed('model.rates.[]', function () {
      // skip the -1-1 row, as it is already being displayed
      var rates = (this.get('model.rates') || []).filter(function (v) {
        return v.key.indexOf('-1-1') < 0;
      });

      return rates.map(function (rate) {
        return JSON.parse(decodeURI(rate.value));
      });
    }),

    click: function click() {
      this.get('onOpenModal')('competitor-pricing/competitor-shopping-form', {
        timestamp: this.get('timestamp'),
        competitor: this.get('model'),
        currencyCode: this.get('currencyCode'),
        onSave: this.get('onSave')
      });
    }
  });
});