define('ux-pcc/components/inputs/date-span-selector', ['exports', 'ux-components/components/inputs/date-span-selector'], function (exports, _dateSpanSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dateSpanSelector.default;
    }
  });
});