define('ux-pcc/routes/portfolio', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    session: Ember.inject.service('session'),

    model: function model(params) {
      var customer = this.modelFor('customer');
      var userCustomer = this.get('session.data.authenticated.customerID');

      var users = this.store.query('user', { customerID: customer.get('id') });
      var customerUsers = this.store.query('user', { customerID: userCustomer });
      var portfolio = this.store.findRecord('customer', params.id, { include: 'settings,xref' });

      var promises = {
        users: Ember.RSVP.all([users, customerUsers]).then(function (results) {
          var users = Ember.A();
          users.addObjects(results[0]);

          if (customer.get('id') !== userCustomer) {
            users.addObjects(results[1]);
          }

          return users;
        }),
        portfolio: portfolio
      };

      return Ember.RSVP.hash(promises);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.portfolio);
      controller.set('users', model.users);
      var settings = model.portfolio.get('settings') || [];
      var managers = settings.findBy('settingCode', "MANAGERS");
      controller.set('managers', managers && managers.get('settingValue') ? managers.get('settingValue') : "");
    },


    actions: {
      refresh: function refresh() {
        return true;
      }
    }
  });
});