define('ux-pcc/components/competitive-set/competitor-panel', ['exports', 'ux-components/utils/format-currency'], function (exports, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: "",
    isMobile: false,
    isExpanded: false,
    competitor: null,
    columns: null,
    currencyCode: null,
    onExpand: null,

    manualRatesEnable: false,

    colspan: Ember.computed('isMobile', function () {
      return this.get('isMobile') ? 2 : 1;
    }),

    rowTimestamp: Ember.computed('row', 'isMobile', function () {
      if (this.get('isMobile')) {
        return this.get('row.rateKey');
      } else {
        return this.get('row.key');
      }
    }),

    competitorList: Ember.computed('columns.@each.{id,type,name,imported,rates,shoppedRates}', 'row', function () {
      var _this = this;

      var wrapSingleDayValues = function wrapSingleDayValues(competitor) {

        var cellHtml = "";

        var getClassList = function getClassList(value) {
          if (isNaN(value) || Ember.isEmpty(value)) {
            return "text-danger";
          }
        };

        var getFormattedValue = function getFormattedValue(value) {
          return isNaN(value) || Ember.isEmpty(value) ? Ember.isEmpty(value) ? _this.get('i18n').t('pcc-components.competitor.rates.no_price') : value : (0, _formatCurrency.default)(value, _this.get('currencyCode'));
        };

        if (competitor.key === 'public') {
          return '<span class="' + getClassList(competitor.rate) + '">' + getFormattedValue(competitor.rate) + '</span>';
        } else {

          cellHtml = '<div class="single-day__wrapper-values">';

          if (competitor.imported) {
            cellHtml += '<span class="' + getClassList(competitor.rate) + ' import-rate">' + getFormattedValue(competitor.rate) + '</span>';
          } else {
            cellHtml += '<span class="import-rate import-rate__muted">' + _this.get('i18n').t('pcc-components.competitor.rates.not_applicable') + '</span>';
          }

          if (competitor.rates && competitor.rates.length > 0) {

            var oneAdultOneDay = competitor.rates.filter(function (v) {
              return v.key.indexOf('-1-1') > 0;
            });

            if (oneAdultOneDay.length === 1) {
              var rate = JSON.parse(decodeURI(oneAdultOneDay[0].value));
              cellHtml += '<span class="' + getClassList(rate.amount) + ' shopped-rate-value">' + getFormattedValue(rate.amount) + '</span>';
            } else {
              cellHtml += '<span class="' + getClassList(null) + '">' + getFormattedValue(null) + '</span>';
            }
          } else if (!competitor.imported) {
            // Only add a no price flag if this is a manual competitor (ie not imported)
            cellHtml += '<span class="' + getClassList(null) + '">' + getFormattedValue(null) + '</span>';
          }

          cellHtml += '</div>';

          return cellHtml;
        }
      };

      var valueList = [];

      var key = this.get('row.key');
      var rateKey = this.get('row.rateKey');
      var competitors = this.get('columns');

      if (this.get('isMobile')) {
        // just print out the non-public rates per row
        var competitor = competitors.findBy('id', key);

        // Flag for if we have rates other than the OneAdultOneDay
        var nonOneAdultOneDay = false;
        if (competitor.shoppedRates[rateKey]) {
          var rates = competitor.shoppedRates[rateKey];
          var nonOneAndOneRates = rates.filter(function (v) {
            return v.key.indexOf('-1-1') < 0;
          });
          nonOneAdultOneDay = nonOneAndOneRates.length > 0;
        }

        var competitorObject = {
          key: competitor.id,
          name: competitor.name,
          imported: competitor.imported,
          rate: competitor.rates[rateKey],
          rates: competitor.shoppedRates[rateKey] ? competitor.shoppedRates[rateKey].sortBy('key') : competitor.shoppedRates[rateKey],
          additionalRates: nonOneAdultOneDay,
          singleDayRates: {},
          nonPublicRate: competitor.id !== 'public'
        };

        competitorObject.singleDayRates = wrapSingleDayValues(competitorObject);
        valueList.push(competitorObject);
      } else {

        // Grab all the rate keys for the day so all competitors have the same number of rows
        var rateKeys = {};

        competitors.forEach(function (e) {
          var rates = e.shoppedRates[key];
          if (e.type.toLowerCase() !== 'public' && rates) {

            if (Array.isArray(rates)) {
              rates.forEach(function (r) {
                // only put in keys where there are collected values
                if (!Ember.isEmpty(r.value) && r.value.indexOf('collectedDate') > 0) {
                  rateKeys[r.key] = r.key;
                }
              });
            }
          }
        });

        rateKeys = Object.keys(rateKeys).sort();

        competitors.forEach(function (competitor) {

          // Flag for if we have rates other than the OneAdultOneDay
          var nonOneAdultOneDay = false;

          // if the competitors have shopped rates, ensure they all have the same number of rows for display
          if (competitor.shoppedRates[key]) {
            var shoppedRates = competitor.shoppedRates[key];

            rateKeys.forEach(function (rateKey) {

              if (!shoppedRates.findBy('key', rateKey)) {
                shoppedRates.push({ key: rateKey, value: encodeURI('{}') });
              } else if (rateKey.indexOf('-1-1') < 0 && shoppedRates.findBy('key', rateKey).value.indexOf('amount') > 0) {
                // Flag the fact that we have a valid non-OneAdultOneDay rate
                nonOneAdultOneDay = true;
              }
            });
            // Remove any rows not in the rateKeys
            shoppedRates = shoppedRates.filter(function (rate) {
              return rateKeys.indexOf(rate.key) >= 0;
            });
            competitor.shoppedRates[key] = shoppedRates.sortBy('key');
          }

          var competitorObject = {
            key: competitor.id,
            name: competitor.name,
            imported: competitor.imported,
            rate: competitor.rates[key],
            rates: competitor.shoppedRates[key],
            additionalRates: nonOneAdultOneDay,
            singleDayRates: {},
            nonPublicRate: competitor.id !== 'public'
          };

          competitorObject.singleDayRates = wrapSingleDayValues(competitorObject);
          valueList.push(competitorObject);
        });
      }

      return valueList;
    }),

    actions: {
      toggleSummary: function toggleSummary() {
        if (this.get('manualRatesEnabled')) {
          if (this.get('isExpanded')) {
            this.set('isExpanded', false);
          } else {
            this.set('isExpanded', true);
          }
          if (this.get('onExpand')) {
            this.get('onExpand')();
          }
        }
      },
      editClick: function editClick(competitor, onSave) {
        this.get('onOpenModal')('competitor-pricing/competitor-shopping-form', {
          timestamp: this.get('rowTimestamp'),
          competitor: competitor,
          currencyCode: this.get('currencyCode'),
          onSave: onSave
        });
      },
      updateRates: function updateRates(analyticEntry) {
        var competitors = this.get('columns');
        var row = this.get('rowTimestamp');
        var rateMap = analyticEntry.get('dataValues');

        // find the competitor, and update the rate information
        var competitor = competitors.findBy('id', analyticEntry.get('groupByValue'));

        if (competitor) {
          var modifiedRates = [];

          if (rateMap) {
            Object.keys(rateMap).forEach(function (k) {
              modifiedRates.push({ key: k, value: rateMap[k] });
            });
          }
          competitor.shoppedRates[row] = modifiedRates;
          this.notifyPropertyChange('columns');
        }
      }
    }
  });
});