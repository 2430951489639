define("ux-pcc/components/dashboard-widget/widget-box", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: "div",
    classNames: ["widget-box"]

  });
});