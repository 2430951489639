define('ux-pcc/components/table-header', ['exports', 'ux-components/components/table-header'], function (exports, _tableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tableHeader.default;
    }
  });
});