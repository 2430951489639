define('ux-pcc/components/guarantee-panel/guarantee-form-prepay', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-prepay'], function (exports, _guaranteeFormPrepay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormPrepay.default;
    }
  });
});