define('ux-pcc/components/yield-manager/organization-panel', ['exports', 'ux-components/utils/to-boolean', 'ux-pcc/helpers/array-contains', 'ux-pcc/helpers/toggle-list-value', 'ux-pcc/utils/stringify-multiselect'], function (exports, _toBoolean, _arrayContains2, _toggleListValue, _stringifyMultiselect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['parameter-panel', 'organization-panel'],

    onParamUpdates: function onParamUpdates() {},

    store: Ember.inject.service(),

    defaultLevel: 'LEVEL_COUNTRY',

    selectedAgreements: null,
    selectedLevels: null,
    typeaheadOptions: null,
    locationTypes: null,
    divisions: null,
    brands: null,

    init: function init() {
      if (!this || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      this._super.apply(this, arguments);
      this.set('typeaheadOptions', { highlight: true, autoselect: true });
      this.set('selectedAgreements', []);
      this.set('selectedLevels', []);
      this._setBrands();
      this._setLocationTypes();
      this._setDivisions();
    },


    corporateParam: Ember.computed.alias('dashboardViewParameters.CORPORATE'),

    locationTypeParam: Ember.computed.alias('dashboardViewParameters.LOCATION_TYPE'),

    divisionParam: Ember.computed.alias('dashboardViewParameters.DIVISION'),

    levelHierarchyParam: Ember.computed.alias('dashboardViewParameters.LEVEL_HIERARCHY'),

    levelParam: Ember.computed.alias('dashboardViewParameters.LEVEL'),

    missingLevelParam: Ember.computed('levelParam', function () {
      return Ember.isEmpty(this.get('levelParam'));
    }),

    brandParam: Ember.computed.alias('dashboardViewParameters.BRAND_CODE'),

    agreementParam: Ember.computed.alias('dashboardViewParameters.AGREEMENT'),

    _setBrands: function _setBrands() {
      var _this = this;

      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'BRAND',
        systemYN: 'Y'
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('brands', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },


    customerLevels: Ember.computed('customer.id', function () {
      return this.get('store').query('code', { customerID: this.get('customer.id'), codeType: 'LEVEL_HIERARCHY' }).then(function (levels) {
        return levels.sortBy('orderBy');
      });
    }),

    showAgreements: Ember.computed('customer.{id,settings.[]}', function () {
      var settings = this.get('customer.settings');
      var showAgreementsSetting = settings.findBy('settingCode', 'PCC.FILTER_PANEL_SHOW_AGREEMENTS');
      return showAgreementsSetting && showAgreementsSetting.get('settingValue') ? (0, _toBoolean.default)(showAgreementsSetting.get('settingValue')) : false;
    }),

    levelDropdownLimit: 25,

    displayedLevelCount: 0,

    _levelHierarchyParamObserver: Ember.observer('levelHierarchyParam', function () {
      this.set('displayedLevelCount', 0);
      this.set('selectedLevels', []);
    }),

    levels: Ember.computed('levelHierarchyParam', function () {
      var _this2 = this;

      var levelType = this.get('levelHierarchyParam') || this.get('defaultLevel');

      return this.get('store').query('customer', { tags: levelType, includeBaseFolder: true, limit: this.get('levelDropdownLimit') }).then(function (levels) {
        var rtnLevels = [];
        var metaResultsFound = levels.get('meta.pagination.resultsFound');
        rtnLevels.addObjects(levels);

        // Need to check the levels and ensure that the items already selected were returned
        var promises = [];
        var currentLevels = _this2.get('levelParam') && !Ember.isEmpty(_this2.get('levelParam')) ? _this2.get('levelParam').split('|') : [];

        currentLevels.forEach(function (customerId) {
          var level = levels.findBy('id', customerId);
          if (!level) {
            promises.push(_this2.get('store').findRecord('customer', customerId).then(function (record) {
              if (record) {
                rtnLevels.pushObject(record);
                _this2.send('levelSelected', record);
              }
            }));
          } else {
            _this2.send('levelSelected', level);
          }
        });

        if (promises.length > 0) {
          return Ember.RSVP.allSettled(promises).then(function () {
            _this2.send('setDisplayedLevelCount', metaResultsFound || rtnLevels.get('length'));
            //this.set('displayedLevelCount', metaResultsFound || rtnLevels.get('length'));
            return rtnLevels.sortBy('externalReference');
          });
        } else {
          _this2.send('setDisplayedLevelCount', metaResultsFound || rtnLevels.get('length'));
          //this.set('displayedLevelCount', metaResultsFound || levels.get('length'));
          return rtnLevels.sortBy('externalReference');
        }
      });
    }),

    _checkAndSetSingleLevel: function _checkAndSetSingleLevel() {
      var _this3 = this;

      this.get('levels').then(function (levels) {
        if ((levels || []).length === 1) {
          _this3.changeLevels(levels.get('firstObject'), true);
        }
      });
    },


    _levelParamObserver: Ember.observer('activeOrganizationGroup', 'levelParam', function () {
      if (Ember.isEmpty(this.get('levelParam')) && this.get('activeOrganizationGroup') === 'levels') {
        Ember.run.once(this, '_checkAndSetSingleLevel');
      }
    }),

    typeaheadAccountsData: Ember.computed('customer.id', function () {
      var _this4 = this;

      var customer = this.get('customer');
      var agreements = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/rest/v1/agreements/search?q=%QUERY*&affiliateID=' + customer.get('affiliateCustomer.id') + '&limit=5',
          filter: function filter(_ref) {
            var _ref$agreementSearche = _ref.agreementSearches,
                agreementSearches = _ref$agreementSearche === undefined ? [] : _ref$agreementSearche;
            return agreementSearches.map(function (obj) {
              return Ember.Object.create(obj);
            });
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this4.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'agreements',
        displayKey: 'name',
        source: agreements,
        templates: {
          empty: '<div class=\'menu-header__search-no-results\'>' + this.get('i18n').t('dashboard-views.no_agreements') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class=\'menu-header__search-item-section-wrapper\'>\n                                <div class=\'menu-header__search-item-section\'>\n                                  ' + model.get('shortDescription') + ' - (' + model.get('accessKey') + ')\n                                </div>\n                              </div>';
          }
        }
      };
    }),

    typeaheadLevelData: Ember.computed('levelHierarchyParam', function () {
      var _this5 = this;

      var levels = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 10,
        remote: {
          url: '/rest/v1/customers?q=%QUERY*&limit=200&tags=' + this.get('levelHierarchyParam') + '&fields=customers,id,name,customerType,externalReference&customertype=AFFILIATE,CUSTOMER,CUSTOMERS',
          filter: function filter(_ref2) {
            var _ref2$customers = _ref2.customers,
                customers = _ref2$customers === undefined ? [] : _ref2$customers;
            return customers.map(function (obj) {
              return Ember.Object.create(obj);
            });
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this5.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'levels',
        displayKey: 'name',
        source: levels,
        templates: {
          empty: '<div class=\'menu-header__search-no-results\'>' + this.get('i18n').t('dashboard-views.form.no_records') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class=\'menu-header__search-item-section-wrapper\'>\n                                <div class=\'menu-header__search-item-section\'>\n                                  ' + model.get('name') + ' - ' + model.get('externalReference') + '\n                                </div>\n                              </div>';
          }
        }
      };
    }),

    _setLocationTypes: function _setLocationTypes() {
      var _this6 = this;

      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_LOCATION_TYPE'
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (!(_this6.get('isDestroyed') || _this6.get('isDestroying'))) {
          _this6.set('locationTypes', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },
    _setDivisions: function _setDivisions() {
      var _this7 = this;

      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_DIVISION'
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        if (!(_this7.get('isDestroyed') || _this7.get('isDestroying'))) {
          _this7.set('divisions', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },
    changeLevels: function changeLevels(item, skipToggleCheck) {
      var levels = this.get('selectedLevels');

      if (levels.findBy('id', item.get('id'))) {
        levels = levels.rejectBy('id', item.get('id'));
        this.set('selectedLevels', levels);
      } else {
        levels.addObject(item);
      }

      if (skipToggleCheck || (this.get('levelParam') || '').indexOf(item.get('id')) < 0) {
        this.set('levelParam', (0, _toggleListValue.toggleListValue)([this.get('levelParam'), item.get('id'), levels], { separator: '|', resetWhenAllSelected: false }));
        this.set('breadcrumbName', (0, _toggleListValue.toggleListValue)([this.get('breadcrumbName'), item.get('externalReference'), levels], { separator: '|', resetWhenAllSelected: false }));
      }
    },
    _getName: function _getName(item) {
      var rtnString = item.get('name');
      if (item.get('name') !== item.get('externalReference')) {
        rtnString = rtnString + ' (' + item.get('externalReference') + ')';
      }
      return rtnString;
    },
    _getAgreementName: function _getAgreementName(item) {
      var rtnString = item.get('shortDescription');
      if (item.get('shortDescription') !== item.get('accessKey')) {
        rtnString = rtnString + ' (' + item.get('accessKey') + ')';
      }
      return rtnString;
    },


    levelsList: Ember.computed('levels.[]', 'levelParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('levels') || [], this.get('levelParam'), 'id', null, this.get('i18n').t('dashboard-views.form.none').toString(), this.get('_getName'));
    }),

    selectedLevelsList: Ember.computed('selectedLevels.[]', 'levelParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('selectedLevels') || [], this.get('levelParam'), 'id', null, this.get('i18n').t('dashboard-views.form.none').toString(), this.get('_getName'));
    }),

    brandsList: Ember.computed('brands.[]', 'brandParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('brands') || [], this.get('brandParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all').toString());
    }),

    agreements: Ember.computed('agreementParam', function () {
      var _this8 = this;

      // Need to check the levels and ensure that the items already selected were returned
      var promises = [];
      var rtnAgreements = [];
      var selectedAgreements = this.get('selectedAgreements');
      var currentAgreements = this.get('agreementParam') && !Ember.isEmpty(this.get('agreementParam')) ? this.get('agreementParam').split('|') : [];

      currentAgreements.forEach(function (agreementId) {
        if (!selectedAgreements.findBy('id', 'agreementId')) {
          promises.push(_this8.get('store').findRecord('agreement', agreementId).then(function (record) {
            if (record) {
              rtnAgreements.pushObject(record);
              _this8.send('agreementSelected', record);
            }
          }));
        }
      });

      if (promises.length > 0) {
        return Ember.RSVP.allSettled(promises).then(function () {
          return rtnAgreements.sortBy('shortDescription');
        });
      } else {
        return Ember.RSVP.allSettled([Ember.RSVP.resolve(rtnAgreements.sortBy('shortDescription'))]);
      }
    }),

    selectedAgreementsList: Ember.computed('selectedAgreements.[]', 'agreementParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('selectedAgreements') || [], this.get('agreementParam'), 'id', null, this.get('i18n').t('dashboard-views.form.all').toString(), this.get('_getAgreementName'));
    }),

    locationTypesList: Ember.computed('locationTypes.[]', 'locationTypeParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('locationTypes') || [], this.get('locationTypeParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all').toString());
    }),

    divisionsList: Ember.computed('divisions.[]', 'divisionParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('divisions') || [], this.get('divisionParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all').toString());
    }),

    actions: {
      closeParamPanel: function closeParamPanel() {
        this.set('activeOrganizationGroup', '');
      },
      saveParamUpdates: function saveParamUpdates() {
        this.set('activeOrganizationGroup', '');

        if (this.get('onParamUpdates')) {
          this.get('onParamUpdates')();
        }
      },
      setLevelHierarchy: function setLevelHierarchy(value) {
        // When level hierarchy changes, clear the levelParam and the selected levels
        this.set('levelParam', '');
        this.set('selectedLevels', []);
        if (value !== this.get('levelHierarchyParam')) {
          this.set('levelHierarchyParam', value);
        }
        this.set('breadcrumbName', '');
      },
      setActiveOrganizationGroup: function setActiveOrganizationGroup(value) {
        this.set('activeOrganizationGroup', value);
      },
      arrayContains: function arrayContains(array, value) {
        return (0, _arrayContains2.arrayContains)([array, value]);
      },
      agreementSelected: function agreementSelected(item) {
        var agreements = this.get('selectedAgreements');
        var itemId = item.get('id');

        if (!agreements.findBy('id', itemId)) {
          agreements.addObject(item);
          if (this.get('agreementParam').indexOf(itemId) === -1) {
            this.set('agreementParam', (0, _toggleListValue.toggleListValue)([this.get('agreementParam'), itemId, agreements], {
              separator: '|',
              resetWhenAllSelected: false
            }));
          }
        }
      },
      agreementDeselected: function agreementDeselected(item) {
        var agreements = this.get('selectedAgreements');
        var itemId = item.get('id');

        if (agreements.findBy('id', itemId)) {
          agreements = agreements.rejectBy('id', itemId);
          this.set('agreements', agreements);
          this.set('agreementParam', (0, _toggleListValue.toggleListValue)([this.get('agreementParam'), itemId, agreements], { separator: '|', resetWhenAllSelected: false }));
        }
      },
      clearAgreements: function clearAgreements() {
        this.set('selectedAgreements', []);
        this.set('agreementParam', '');
      },
      levelSelected: function levelSelected(item) {
        // If it already is in the list, skip it
        if (this.get('selectedLevels').findBy('id', item.get('id'))) {
          return;
        }

        this.changeLevels(item, false);
      },
      levelClicked: function levelClicked(item) {
        this.changeLevels(item, true);
      },
      getName: function getName(item) {
        return this._getName(item);
      },
      setDisplayedLevelCount: function setDisplayedLevelCount(levelCount) {
        this.set('displayedLevelCount', levelCount);
      }
    }
  });
});