define('ux-pcc/components/rules/rule-form', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'moment'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({

    tagName: '',

    permission: Ember.inject.service(),
    dialog: Ember.inject.service(),

    isAlertType: Ember.computed('changeset.ruleType', function () {
      return this.get('changeset.ruleType').match(/^ALERT_/i);
    }),

    isPolicyType: Ember.computed('changeset.ruleType', function () {
      return ["GUARANTEE", "CANCEL"].includes(this.get('changeset.ruleType'));
    }),

    isTaxableType: Ember.computed('changeset.ruleType', function () {
      return ["PRICE", "FEE", "MEAL_PLAN"].includes(this.get('changeset.ruleType'));
    }),

    isTaxType: Ember.computed('changeset.ruleType', function () {
      return this.get('changeset.ruleType') === "TAX";
    }),

    // daysInWindow: computed(function () {
    //   let model = this.get("customer.settings").findBy("settingCode", "NOTIFICATION_MAX_DAYS");
    //   if (model && model.get("settingValue")) {
    //     return model.get("settingValue");
    //   } else {
    //     return -1;
    //   }
    // }),

    // ongoingLabel: computed(function () {
    //   let daysInWindow = this.get('daysInWindow');
    //
    //   if (daysInWindow > 0) {
    //     return this.i18n.t('rule._form.ongoing_rolling', {days: daysInWindow});
    //   } else {
    //     return this.i18n.t('rule._form.ongoing');
    //   }
    //
    // }),

    isOngoing: Ember.computed.empty('changeset.endDate'),

    types: Ember.computed('i18n.locale', function () {
      return [{ type: '', shortDescription: this.i18n.t('rule._form_modal.select_notification_type') }, { type: 'ALERT_INV', shortDescription: this.i18n.t('rule._form_modal.inventory') }, { type: 'ALERT_PERF', shortDescription: this.i18n.t('rule._form_modal.performance') }, { type: 'ALERT_COMP', shortDescription: this.i18n.t('rule._form_modal.competition') }, { type: 'ALERT_OCC', shortDescription: this.i18n.t('rule._form_modal.occ') }];
    }),

    noTypeSelected: Ember.computed.empty('changeset.ruleType'),
    noChildren: Ember.computed.equal('changeset.children.length', 0),
    currencyCode: Ember.computed(function () {
      var model = this.get("customer.settings").findBy("settingCode", "DEFAULT_CURRENCY");
      if (model && model.get("settingValue")) {
        return model.get("settingValue");
      } else {
        return "USD";
      }
    }),

    filteredTypes: Ember.computed('rules', 'types', function () {
      var _this = this;

      var ruleTypes = this.get('types');
      var customerPermissionType = this.get('customer.permissionType');

      // Filter out the rule types the user is not allowed to see
      ruleTypes = ruleTypes.filter(function (item /*, index, enumerable*/) {
        return _this.get('permission').isPermitted(customerPermissionType + "ruletype", "view", item.type.toLowerCase());
      });
      return ruleTypes;
    }),

    ruleTypeChanged: Ember.computed('changeset.ruleType', function () {
      // Changing the rule type with children on a new record will blow away children
      if (!this.get('id') && this.get('changeset.children.length')) {
        this.get('changeset.children').clear();
      }
    }),

    validations: Ember.computed('i18n.locale', function () {
      return {
        ruleType: (0, _validators.validatePresence)(true),
        shortDescription: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rule_name').toString()
        }),
        beginDate: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_begin_date').toString()
        })
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      var changeset = new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);

      changeset.set('children', [].concat(_toConsumableArray(this.get('model.children'))));
      return changeset;
    }),

    actions: {
      addRule: function addRule() {
        var _this2 = this;

        var changeset = this.get('changeset');

        // validate the changeset before adding children, but don't execute until the actual save
        changeset.validate().then(function () {

          // all validations pass
          if (changeset.get('isValid')) {

            var notificationType = _this2.get('filteredTypes').findBy('type', _this2.get('changeset.ruleType'));

            if (!notificationType) {
              _this2.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this2.i18n.t('rule._form.invalid_notification_type_message'), 'danger');
            } else {

              var rule = {
                filterType: "ANY",
                ruleMethod: "RULE",
                day1: true,
                day2: true,
                day3: true,
                day4: true,
                day5: true,
                day6: true,
                day7: true,
                customerID: _this2.get('customer.id'),
                children: [{
                  filterType: "ALL",
                  ruleMethod: "FILTER",
                  children: [{
                    filterType: "FIELD_WHERE",
                    ruleMethod: "FILTER"
                  }]
                }]
              };

              // If this is a Competitor alert, insert a Competitor dropdown condition
              if (changeset.get('ruleType') === 'ALERT_COMP') {
                var competitorRule = { filterField: 'COMPETITOR', filterType: 'ALL' };
                rule.children.get('firstObject.children').insertAt(0, competitorRule);
              }

              _this2.get('changeset').set('children', [].concat(_toConsumableArray(_this2.get('changeset.children')), [rule]));

              _this2.get('showModal')('rule/-modal', {
                rule: rule,
                ruleType: changeset.get('ruleType'),
                customer: _this2.get('customer'),
                beginDate: (0, _moment.default)(changeset.get('beginDate')).utc().format('YYYY-MM-DD'),
                endDate: changeset.get('endDate') ? (0, _moment.default)(changeset.get('endDate')).utc().format('YYYY-MM-DD') : null,
                headerMessage: _this2.i18n.t('rule._form_modal.header_message', {
                  ruleType: notificationType.shortDescription,
                  htmlSafe: true
                }),
                successMessage: _this2.i18n.t('rule._form_modal._success_modal.success_message', {
                  ruleName: changeset.get('shortDescription')
                }),
                successReturnLink: _this2.i18n.t('rule._form_modal._success_modal.success_return_link'),
                onNewCondition: function onNewCondition() {
                  _this2.send('addRule');
                },
                onRemoveCondition: function onRemoveCondition(rule) {
                  _this2.send('removeCondition', rule);
                }
              });
            }
          }
        });
      },
      save: function save() {
        var _this3 = this;

        var changeset = this.get('changeset');

        changeset.validate().then(function () {

          // all validations pass
          if (changeset.get('isValid')) {

            changeset.execute();

            // Do the Actual save
            var model = _this3.get('model');
            var beginDate = (0, _moment.default)(model.get("beginDate")).utc().format('YYYY-MM-DD');
            var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
            model.set("beginDate", _moment.default.tz(beginDate, _this3.get('tz.customer')).startOf("day"));
            if (_this3.get('isOngoing')) {
              // Set endDate to null
              model.set('endDate', null);
            } else {
              model.set('endDate', endDate ? _moment.default.tz(endDate, _this3.get('tz.customer')).startOf('day') : undefined);
            }

            model.save().then(function () {
              // Grab the rule type label
              var notificationType = _this3.get('filteredTypes').findBy('type', _this3.get('model.ruleType'));

              if (!notificationType) {
                _this3.dialog.showError();
              } else {

                var ruleTypeLabel = notificationType.shortDescription;

                // Show the success modal
                _this3.dialog.showSuccess({
                  titleText: _this3.i18n.t('rule._form._success_modal.title'),
                  messageText: _this3.i18n.t(_this3.get('successMessage'), {
                    ruleName: ruleTypeLabel
                  }),
                  confirmText: _this3.i18n.t('rule._form._success_modal.button_label'),
                  onConfirm: function onConfirm() {
                    _this3.get('ruleSaved')();
                    _this3.dialog.close();
                  }
                });
              }
            }).catch(function (error) {
              _this3.get('flash')('<i class=\'fa fa-warning\'></i> ' + error, 'danger');
            });
          }
        });
      },
      editRule: function editRule(rule) {
        var _this4 = this;

        var notificationType = this.get('filteredTypes').findBy('type', this.get('changeset.ruleType'));

        if (!notificationType) {
          this.get('flash')('<i class=\'fa fa-warning\'></i> ' + this.i18n.t('rule._form.invalid_notification_type_message'), 'danger');
        } else {

          this.get('showModal')('rule/-modal', {
            rule: rule,
            ruleType: this.get('changeset.ruleType'),
            customer: this.get('customer'),
            beginDate: this.get('changeset.beginDate'),
            endDate: this.get('changeset.endDate'),
            headerMessage: this.i18n.t('rule._form_modal.edit_header_message', {
              ruleType: notificationType.shortDescription,
              htmlSafe: true
            }),
            successMessage: this.i18n.t('rule._form_modal._success_modal.success_message', {
              rateCode: this.get('rateCode.externalReference'),
              htmlSafe: true
            }),
            onNewCondition: function onNewCondition() {
              _this4.send('addRule');
            },
            onRemoveCondition: function onRemoveCondition(rule) {
              _this4.send('removeCondition', rule);
            }
          });
        }
      },
      copyTemplate: function copyTemplate(rule) {
        var _this5 = this;

        rule.copy().then(function (copy) {
          copy.set('id', null);
          copy.set('customer', _this5.get('customer'));
          _this5.set('model', copy);
        }).catch(function () {
          _this5.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this5.i18n.t('rule._form.failed_to_copy_rule_message'), 'danger');
        });
      },
      removeCondition: function removeCondition(condition) {
        var children = [].concat(_toConsumableArray(this.get('changeset.children')));
        this.get('changeset').set('children', children.rejectBy('id', condition.id));
      },
      updateSelection: function updateSelection() {
        // noop
      }
    }

  });
});