define('ux-pcc/controllers/customer/performance/-auto-matrix-pricing-copy-modal', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    store: Ember.inject.service(),
    model: null,
    beginSlice: Ember.computed.oneWay('model.startDate'),
    endSlice: Ember.computed.oneWay('model.endDate'),
    day0: true,
    day1: true,
    day2: true,
    day3: true,
    day4: true,
    day5: true,
    day6: true,
    removeExistingRuleCalendars: false,

    disableSave: Ember.computed('model.{includePY,includeYesterday,include3Weeks}', 'beginSlice', 'endSlice', function () {
      // disable save if no rule parameters chosen or begin date is prior to yesterday or end date is prior to begin date
      return !this.get('model.includePY') && !this.get('model.includeYesterday') && !this.get('model.include3Weeks') || _moment.default.tz(this.get('beginSlice'), this.tz.get('customer')).startOf('day').isBefore(_moment.default.tz(this.tz.get('customer')).subtract(1, 'days').startOf('day')) || _moment.default.tz(this.get('endSlice'), this.tz.get('customer')).startOf('day').isBefore(_moment.default.tz(this.get('beginSlice'), this.tz.get('customer')).startOf('day'));
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: _moment.default.tz(this.tz.get('customer')).subtract(1, 'days').startOf('day')
      };
    }),
    isOngoing: false,
    _saveRule: function _saveRule(ruleID, startDate, endDate) {
      var _this = this;

      var mainRules = [];
      var conditionRules = [];
      var parameters = [];
      // range selected - save rule calendars
      if (endDate) {
        // add children rules for rule conditions
        if (this.get('model.includePY')) {
          var pyRule = {
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_PRIOR_YEAR'
          };
          conditionRules.push(pyRule);
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_py'));
        }
        if (this.get('model.includeYesterday')) {
          conditionRules.push({
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_YESTERDAY'
          });
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_yesterday'));
        }
        if (this.get('model.include3Weeks')) {
          conditionRules.push({
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_3WEEKAVG'
          });
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_3_weeks'));
        }
        // create ALL (WHEN) filter rule
        var filterRule = {
          filterType: 'ALL',
          ruleMethod: 'FILTER',
          children: conditionRules
        };
        // create ANY (THEN) filter rule
        var filterWrapperRule = {
          filterType: 'ANY',
          ruleMethod: 'FILTER',
          children: [filterRule]
        };
        // create main rule
        var mainRule = {
          amountRule: 'AUTO_ALLOCATION',
          ruleMethod: 'RULE',
          ruleType: "PRICE_AUTO_ALLOCATION",
          shortDescription: 'BAR Auto Allocations',
          children: [filterWrapperRule]
        };
        mainRules.push(mainRule);

        // save rule calendar request
        var requestTemplate = {
          customerID: this.get('model.customer.id'),
          day1: this.get('day0') ? "Y" : "N",
          day2: this.get('day1') ? "Y" : "N",
          day3: this.get('day2') ? "Y" : "N",
          day4: this.get('day3') ? "Y" : "N",
          day5: this.get('day4') ? "Y" : "N",
          day6: this.get('day5') ? "Y" : "N",
          day7: this.get('day6') ? "Y" : "N",
          ruleID: ruleID,
          ruleType: "PRICE_AUTO_ALLOCATION",
          beginDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          endDate: endDate.clone().endOf("day").format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          children: mainRules
        };
        return this.ajax.post('/rest/v1/ruleCalendarRequests', {
          data: JSON.stringify(requestTemplate),
          contentType: "application/json"
        }).then(function () /*responses*/{
          _this.send('removeModal');
          _this.set('isSaving', false);
          // save rule modification alert
          var payload = _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success_alert', {
            userFirstName: _this.get('model.user.firstName'),
            userLastName: _this.get('model.user.lastName'),
            startDate: startDate.clone().startOf('day').format('LL'),
            endDate: endDate.clone().startOf('day').format('LL'),
            parameters: parameters.join(", ")
          });
          var alert = _this.store.createRecord('alert', {
            customer: _this.get('model.customer'),
            alertMethod: 'API',
            actionCode: 'GET',
            payloadType: 'TEXT',
            status: 'OK',
            alertType: 'NOTIFY_RULE_MODIFICATION',
            payload: payload,
            fkReference: 'RULE',
            fkID: ruleID,
            alertRequestedDate: (0, _moment.default)(),
            alertDate: (0, _moment.default)(),
            retryCount: 0,
            responseRequiredYN: false
          });
          alert.save();
          _this.dialog.showSuccess({
            titleText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success_title'),
            messageText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success', {
              startDate: startDate.clone().format('dddd, L'),
              endDate: endDate.clone().format('dddd, L')
            }),
            confirmText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success_button'),
            onConfirm: function onConfirm() {
              _this.setProperties({
                day0: true,
                day1: true,
                day2: true,
                day3: true,
                day4: true,
                day5: true,
                day6: true,
                removeExistingRuleCalendars: false,
                isOngoing: false
              });
              _this.dialog.close();
              _this.send('refresh');
            }
          });
        }, function () {
          _this.set('isSaving', false);
          _this.dialog.showError();
        });
      } else {
        // ongoing selected - save base rule and remove rule calendars if requested
        // add children rules for rule conditions
        if (this.get('model.includePY')) {
          var _pyRule = {
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_PRIOR_YEAR'
          };
          conditionRules.push(this.store.createRecord('rule', _pyRule));
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_py'));
        }
        if (this.get('model.includeYesterday')) {
          conditionRules.push(this.store.createRecord('rule', {
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_YESTERDAY'
          }));
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_yesterday'));
        }
        if (this.get('model.include3Weeks')) {
          conditionRules.push(this.store.createRecord('rule', {
            filterCondition: 'GT',
            filterType: 'FIELD_WHERE',
            ruleMethod: 'FILTER',
            filterField: 'HOUR_SOLD_TODAY',
            filterField2: 'HOUR_SOLD_3WEEKAVG'
          }));
          parameters.push(this.i18n.t('customer.performance.auto-matrix-pricing.greater_than_3_weeks'));
        }
        // create ALL (WHEN) filter rule
        var _filterRule = this.store.createRecord('rule', {
          filterType: 'ALL',
          ruleMethod: 'FILTER',
          children: []
        });
        _filterRule.get('children').addObjects(conditionRules);
        // create ANY (THEN) filter rule
        var _filterWrapperRule = this.store.createRecord('rule', {
          filterType: 'ANY',
          ruleMethod: 'FILTER',
          children: []
        });
        _filterWrapperRule.get('children').addObject(_filterRule);

        var rule = this.get('model.adjustmentRule');
        rule.set('children', [_filterWrapperRule]);
        rule.set('beginDate', startDate);
        rule.set('endDate', null);
        rule.save().then(function () {
          _this.send('removeModal');
          _this.set('isSaving', false);
          // delete existing calendar overrides if requested
          if (_this.get('removeExistingRuleCalendars')) {
            _this.ajax.post('/rest/v1/ruleCalendarRequests', {
              data: JSON.stringify({
                customerID: _this.get('model.customer.id'),
                day1: "Y",
                day2: "Y",
                day3: "Y",
                day4: "Y",
                day5: "Y",
                day6: "Y",
                day7: "Y",
                ruleID: ruleID,
                ruleType: "PRICE_AUTO_ALLOCATION",
                beginDate: startDate.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                endDate: startDate.clone().add(365, 'days').endOf("day").format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                deleteYN: "Y"
              }),
              contentType: "application/json"
            });
          }
          // save rule modification alert
          var payload = _this.i18n.t('customer.performance.auto-matrix-pricing.copy.ongoing_alert', {
            userFirstName: _this.get('model.user.firstName'),
            userLastName: _this.get('model.user.lastName'),
            startDate: startDate.clone().startOf('day').format('LL'),
            parameters: parameters.join(", ")
          });
          var alert = _this.store.createRecord('alert', {
            customer: _this.get('model.customer'),
            alertMethod: 'API',
            actionCode: 'GET',
            payloadType: 'TEXT',
            status: 'OK',
            alertType: 'NOTIFY_RULE_MODIFICATION',
            payload: payload,
            fkReference: 'RULE',
            fkID: ruleID,
            alertRequestedDate: (0, _moment.default)(),
            alertDate: (0, _moment.default)(),
            retryCount: 0,
            responseRequiredYN: false
          });
          alert.save();
          // show success modal
          _this.dialog.showSuccess({
            titleText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success_title'),
            messageText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success', {
              startDate: startDate.clone().format('dddd, L'),
              endDate: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.ongoing')
            }),
            confirmText: _this.i18n.t('customer.performance.auto-matrix-pricing.copy.success_button'),
            onConfirm: function onConfirm() {
              _this.setProperties({
                day0: true,
                day1: true,
                day2: true,
                day3: true,
                day4: true,
                day5: true,
                day6: true,
                removeExistingRuleCalendars: false,
                isOngoing: false
              });
              _this.dialog.close();
              _this.send('refresh');
            }
          });
        }, function () {
          _this.set('isSaving', false);
          _this.dialog.showError();
        });
      }
    },

    actions: {
      applyRule: function applyRule() {
        var _this2 = this;

        this.set('isSaving', true);
        var tz = this.tz.get('customer');
        var startDate = _moment.default.tz(this.get('beginSlice'), tz);
        var isOngoing = !this.get('endSlice');
        var endDate = _moment.default.tz(this.get('endSlice'), tz);
        // create allocation rule if there isn't one
        var ruleTemplate = {
          customerID: this.get('model.customer.id'),
          day1: "Y",
          day2: "Y",
          day3: "Y",
          day4: "Y",
          day5: "Y",
          day6: "Y",
          day7: "Y"
        };
        if (!this.get('model.adjustmentRule')) {
          var adjustmentRule = this.store.createRecord('rule', Ember.merge({
            amountRule: 'AUTO_ALLOCATION',
            calculationRule: 'CONDITION_REQUIRED',
            ruleMethod: 'RULE',
            ruleType: 'PRICE_AUTO_ALLOCATION',
            shortDescription: 'BAR Auto Allocations'
          }, ruleTemplate));
          adjustmentRule.save().then(function () {
            _this2.set('model.adjustmentRule', adjustmentRule);
            _this2._saveRule(adjustmentRule.get('id'), startDate, isOngoing ? null : endDate);
          }, function () {
            _this2.set('isSaving', false);
            _this2.dialog.showError();
          });
        } else {
          this._saveRule(this.get('model.adjustmentRule.id'), startDate, isOngoing ? null : endDate);
        }
      },
      close: function close() {
        this.send('removeModal');
      },
      toggleOngoing: function toggleOngoing() {
        var ongoing = this.get('isOngoing');
        if (ongoing) {
          this.set('isOngoing', false);
          this.set('endSlice', _moment.default.tz(this.get("beginSlice"), this.tz.get('customer')).startOf('day'));
          this.set('removeExistingRuleCalendars', false);
        } else {
          this.set('isOngoing', true);
          this.set('endSlice', null);
          this.set('removeExistingRuleCalendars', true);
        }
      }
    }
  });
});