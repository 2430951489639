define('ux-pcc/routes/competitor-pricing/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor('customer');

      return this.store.query('customer', {
        folderid: customer.get('id'),
        folderyn: 'y',
        customertype: 'MANUAL_COMPETITORS'
      });
    }
  });
});