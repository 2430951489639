define('ux-pcc/components/segment-table', ['exports', 'moment', 'accounting', 'ux-components/utils/format-currency'], function (exports, _moment, _accounting, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    data: null,
    loading: true,
    enableSlider: false,
    enablePager: false,
    columnsPerScreen: null,
    rollupStatus: 'DETAIL',
    sortByGroups: true,
    isAtStart: Ember.computed.equal('_screen', 1),
    isAtEnd: Ember.computed('_screen', 'columnsPerScreen', 'columns.length', function () {
      return this.get('_screen') + this.get('columnsPerScreen') > this.get('columns.length');
    }),

    columns: [],
    showableColumns: Ember.computed('columns', '_screen', 'mobileIndex', function () {
      var origColumns = this.get('columns');

      if (this.get('deviceType.mobile') && this.get('mobileIndex') >= 0) {
        // Always grab the first column
        var rtnColumns = origColumns.slice(0, 1);

        var offset = this.get('mobileIndex') + 1;

        return rtnColumns.concat(origColumns.slice(offset, offset + 1));
      } else if (this.get('enableSlider') && this.get('columnsPerScreen')) {
        // Always grab the first column
        var _rtnColumns = origColumns.slice(0, 1);

        var _offset = this.get('_screen');

        return _rtnColumns.concat(origColumns.slice(_offset, _offset + (this.get('columnsPerScreen') - 1)));
      } else {
        return origColumns;
      }
    }),

    useFixedColumn: true,
    group: false,

    _screen: 1,

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchData();
    },


    parameterObserver: Ember.observer('customerID', 'beginDate', 'endDate', 'aggregationType', function () {
      this._fetchData();
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var selectedDate = _moment.default.tz(this.get('beginDate'), this.get('tz')).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), this.get('tz')).endOf('day');

      if (endDate.isBefore(selectedDate)) {
        return;
      }

      this.set('loading', true);

      var params = {
        customerid: this.get('customerID'),
        begindate: selectedDate.format(),
        enddate: endDate.format(),
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus')
      };

      if (this.get('filter')) {
        params.filterValues = this.get('filter');
      }

      var requests = [this.ajax.request('/rest/v1/analyticEntryAggregation/process', { data: params }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'SegmentTable: Getting segment details').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        return _this.set('data', { analytics: data });
      }).catch(function () {
        return _this.set('data', { analytics: [] });
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    parseData: Ember.observer('data', 'showableColumns', function () {
      var _this2 = this;

      var entries = this.get('data.analytics');
      var segments = [];

      if (entries && entries.length > 0) {
        var currencyCode = this.get('context.price.defaultCurrencyCode') || "USD";
        var columnKeys = this.get('showableColumns');
        var segmentMap = {};
        if (this.get('segments')) {
          this.get('segments').forEach(function (segment, i) {
            segmentMap[segment.key.toUpperCase()] = segment;
            segment.orderBy = i;
            // Set default values for the segment columns
            segment.data = { groupName: segment.key, label: segment.label, values: {}, classNames: "" };
            columnKeys.forEach(function (c) {
              if (c.key && c.key.length > 0) {
                segment.data.values[c.key] = '&mdash;';
              }
            });
          });
        }

        var limitRows = {};
        var filterRows = false;
        if (this.get('limitRows')) {
          filterRows = true;
          this.get('limitRows').forEach(function (entry) {
            limitRows[entry.key.toUpperCase()] = entry.label;
          });
        }

        entries.forEach(function (e) {

          var data = e.dataValues;

          var dataValues = segmentMap[decodeURIComponent(e.groupByValue)] ? segmentMap[decodeURIComponent(e.groupByValue)].data.values : {};

          if (data) {

            // Loop through the keys and reformat for display
            columnKeys.forEach(function (c) {
              var key = c.key;
              var format = c.format;
              if (data && key in data) {
                if (format === 'formatCurrency') {
                  dataValues[key] = (0, _formatCurrency.default)(data[key], currencyCode);
                } else if (format === 'formatNumber') {
                  dataValues[key] = (data[key] >= 100 ? _accounting.default.formatNumber(data[key]) : _accounting.default.formatNumber(data[key], 1)) + '%';
                } else {
                  dataValues[key] = data[key];
                }
              } else {
                dataValues[key] = '&mdash;';
              }
            });

            var groupByValues = decodeURIComponent(e.groupByValue).split(',');
            var classNames = groupByValues.indexOf('TOTAL') > -1 ? "total-row" : "";

            if (filterRows) {
              if (groupByValues[0] in limitRows) {
                if (!(groupByValues[0].toUpperCase() in segmentMap)) {
                  segmentMap[groupByValues[0].toUpperCase()] = { data: {} };
                }
                segmentMap[groupByValues[0].toUpperCase()].data.label = limitRows[groupByValues[0]];
                segmentMap[groupByValues[0].toUpperCase()].data.values = dataValues;
                segmentMap[groupByValues[0].toUpperCase()].data.groupName = groupByValues[0];
                segmentMap[groupByValues[0].toUpperCase()].data.classNames = classNames;
              }
            } else {
              if (segmentMap[e.groupByValue.toUpperCase()]) {
                segmentMap[e.groupByValue.toUpperCase()].data.label = _this2.get('segments') ? segmentMap[e.groupByValue.toUpperCase()].label : decodeURIComponent(e.groupByValue);
                segmentMap[e.groupByValue.toUpperCase()].data.values = dataValues;
                segmentMap[e.groupByValue.toUpperCase()].data.groupName = groupByValues[0];
                segmentMap[e.groupByValue.toUpperCase()].data.classNames = classNames;
              }
            }
          } else {
            // No actual data so clear the map value
            if (segmentMap[decodeURIComponent(e.groupByValue)]) {
              segmentMap[decodeURIComponent(e.groupByValue)].data.values = null;
            }
          }
        });

        // Pull the segmentData from the map and put in the segments list
        for (var key in segmentMap) {
          if (segmentMap[key].data.values) {
            segments.push(segmentMap[key].data);
          }
        }
      }

      if (segments.length > 0) {

        if (this.get('sortByGroups')) {
          // Add ordering and grouping logic
          segments = segments.sort(function (a, b) {
            if (a.groupName === 'TOTAL') {
              return -1;
            }
            if (b.groupName === 'TOTAL') {
              return 1;
            }
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });

          // Sort the segments in the same order they are in on the other sections
          if (this.get("segments")) {
            segments = segments.sort(function (a, b) {
              if (a.groupName === 'TOTAL') {
                return -1;
              }
              if (b.groupName === 'TOTAL') {
                return 1;
              }
              if (segmentMap[a.groupName.toUpperCase()].orderBy < segmentMap[b.groupName.toUpperCase()].orderBy) {
                return -1;
              }
              if (segmentMap[a.groupName.toUpperCase()].orderBy > segmentMap[b.groupName.toUpperCase()].orderBy) {
                return 1;
              }
              return 0;
            });
          }
        }

        if (this.get('addGrouping')) {
          var prevSegment = "";
          var segmentCount = 0;
          segments.forEach(function (seg) {
            if (prevSegment.length === 0) {
              prevSegment = seg.groupName;
            } else if (prevSegment !== seg.groupName) {
              prevSegment = seg.groupName;
              segmentCount++;
            }
            if (segmentCount % 2 > 0 && seg.classNames.length <= 0) {
              seg.classNames = 'guide';
            }
          });
        }
      }

      var segmentRows = [];
      segmentRows.push({ label: this.get('showableColumns')[0].label, rows: segments });

      // Grab all but the first column
      var columns = this.get('showableColumns').slice(1);

      this.set('segmentTableData', {
        groups: segmentRows,
        columns: columns
      });
    }),

    mobileIndexResetOnDate: Ember.observer('data', 'enablePager', function () {
      if (this.get('enablePager')) {
        this.set('mobileIndex', 0);
      }
    }),

    mobileTitle: Ember.computed('mobileIndex', function () {
      if (!this.get('columns')) {
        return;
      }
      return this.get('columns')[this.get('mobileIndex') + 1].label;
    }),

    actions: {
      left: function left() {
        var screen = this.get('_screen');
        this.set('_screen', --screen);
      },

      right: function right() {
        var screen = this.get('_screen');
        this.set('_screen', ++screen);
      },
      changePage: function changePage(amount) {
        var mobileIndex = this.get('mobileIndex') + amount;
        var maxIndex = this.get('columns.length') - 2;

        if (mobileIndex > maxIndex) {
          mobileIndex = 0;
        } else if (mobileIndex < 0) {
          mobileIndex = maxIndex;
        }

        this.set('mobileIndex', mobileIndex);
      }
    }

  });
});