define('ux-pcc/serializers/rule-calendar', ['exports', 'ux-data/serializers/rule-calendar'], function (exports, _ruleCalendar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ruleCalendar.default;
    }
  });
});