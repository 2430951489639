define('ux-pcc/components/data-monitoring/detail-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpen: false,
    showOnlyDiscrepancies: true,
    showAll: Ember.computed.not('showOnlyDiscrepancies'),

    hasPropertiesMismatched: Ember.computed.notEmpty('result.propertiesMismatch'),
    hasPropertiesMissing: Ember.computed('result.{propertiesMissing,hasPropertiesMismatched}', function () {
      return !this.get('hasPropertiesMismatched') && this.get('result.propertiesMissing.length') > 0;
    }),

    hasYieldableInventoryDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('GENERAL_INVENTORY') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('GENERAL_INVENTORY');
    }),

    hasOOOInventoryDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('OOO_INVENTORY') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('OOO_INVENTORY');
    }),

    hasManagerHoldDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('MANAGER_HOLD') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('MANAGER_HOLD');
    }),

    hasOverbookingDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('OVERBOOKING_INVENTORY') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('OVERBOOKING_INVENTORY');
    }),

    hasSoldDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('RESERVATION_COUNT') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('RESERVATION_COUNT');
    }),

    hasSellableRateDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('SELLRATE') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('SELLRATE');
    }),

    hasRevenueDiscrepancy: Ember.computed('result.{propertiesMismatch,propertiesMissing}', function () {
      return this.get('result.propertiesMismatch') && this.get('result.propertiesMismatch').includes('REVENUE') || this.get('result.propertiesMissing') && this.get('result.propertiesMissing').includes('REVENUE');
    }),

    highlightYieldable: Ember.computed.and('showAll', 'hasYieldableInventoryDiscrepancy'),
    showYieldable: Ember.computed('showOnlyDiscrepancies', 'hasYieldableInventoryDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasYieldableInventoryDiscrepancy');
    }),

    highlightOOO: Ember.computed.and('showAll', 'hasOOOInventoryDiscrepancy'),
    showOOO: Ember.computed('showOnlyDiscrepancies', 'hasOOOInventoryDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasOOOInventoryDiscrepancy');
    }),

    highlightManagerHold: Ember.computed.and('showAll', 'hasManagerHoldDiscrepancy'),
    showManagerHold: Ember.computed('showOnlyDiscrepancies', 'hasManagerHoldDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasManagerHoldDiscrepancy');
    }),

    highlightOverbooking: Ember.computed.and('showAll', 'hasOverbookingDiscrepancy'),
    showOverbooking: Ember.computed('showOnlyDiscrepancies', 'hasOverbookingDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasOverbookingDiscrepancy');
    }),

    highlightSold: Ember.computed.and('showAll', 'hasSoldDiscrepancy'),
    showSold: Ember.computed('showOnlyDiscrepancies', 'hasSoldDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasSoldDiscrepancy');
    }),

    highlightSellableRate: Ember.computed.and('showAll', 'hasSellableRateDiscrepancy'),
    showSellableRate: Ember.computed('showOnlyDiscrepancies', 'hasSellableRateDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasSellableRateDiscrepancy');
    }),

    highlightRevenue: Ember.computed.and('showAll', 'hasRevenueDiscrepancy'),
    showRevenue: Ember.computed('showOnlyDiscrepancies', 'hasRevenueDiscrepancy', function () {
      return !this.get('showOnlyDiscrepancies') || this.get('showOnlyDiscrepancies') && this.get('hasRevenueDiscrepancy');
    }),

    actions: {
      toggleOpen: function toggleOpen() {
        this.toggleProperty('isOpen');
      }
    }
  });
});