define('ux-pcc/components/rule-advanced/rule-filter', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isGroup:filter-collection:filter-rule'],

    pickerFormat: 'LL',

    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    init: function init() {
      this._super.apply(this, arguments);

      this.set('pickerOptions', {
        useCurrent: false
      });

      var changeset = this.get('changeset');

      var properties = {};

      if (!changeset.get('filterField') && this.get('fieldOptions.firstObject.options.firstObject.value')) {
        properties.filterField = this.get('fieldOptions.firstObject.options.firstObject.value');
      }

      if (!changeset.get('filterField2') && this.get('comparators.length') > 0 && this.get('comparators.firstObject.value')) {
        properties.filterField2 = this.get('comparators.firstObject.value');
      }

      if (!changeset.get('filterCondition') && this.get('conditions.firstObject.value')) {
        properties.filterCondition = this.get('conditions.firstObject.value');
      }

      changeset.setProperties(properties);

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('isGroup', 'i18n.locale', function () {

      // filterType is controlled external to this component when specifying a group
      if (this.get('model.filterType') !== 'FIELD_WHERE') {
        return {
          children: (0, _validators.validateLength)({
            min: 1,
            message: this.i18n.t('rule._form_modal.no_filters').toString()
          })
        };
      } else {
        return {
          filterField: (0, _validators.validatePresence)({
            presence: true,
            message: this.i18n.t('rule._form_modal.no_type').toString()
          })
        };
      }
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    sortedChildren: Ember.computed('changeset.children.@each.orderBy', function () {
      return this.get('changeset.children').sortBy('orderBy');
    }),

    filterFieldItems: Ember.computed('fieldOptions.@each.options', function () {
      var fieldOptions = [];
      this.get('fieldOptions').forEach(function (fieldGroup) {
        fieldOptions = fieldOptions.concat(fieldGroup.options);
      });
      return fieldOptions;
    }),

    filterFieldItem: Ember.computed('changeset.filterField', 'filterFieldItems.@each.value', function () {
      var filterField = this.get('changeset.filterField');
      var fieldOptions = this.get('filterFieldItems');
      return fieldOptions.findBy('value', filterField);
    }),

    modelFilterFieldItem: Ember.computed('changeset.filterField', 'filterFieldItems.@each.value', function () {
      return this.get('filterFieldItems').findBy('value', this.get('changeset.filterField'));
    }),

    isGroup: Ember.computed('changeset.filterType', 'groupOptions', function () {
      var type = this.get('changeset.filterType');
      return this.get('groupOptions').any(function (e) {
        return e.value === type;
      });
    }),

    actions: {
      getValue: function getValue(field, obj) {
        return obj ? Ember.get(obj, field) : null;
      },
      addFilter: function addFilter() {

        if (!this.get('changeset.children')) {
          this.set('changeset.children', []);
        }

        this.get('changeset.children').addObject({
          ruleMethod: 'FILTER',
          filterType: 'FIELD_WHERE',
          children: [],
          orderBy: null
        });
      },
      addFilterGroup: function addFilterGroup() {

        if (!this.get('changeset.children')) {
          this.set('changeset.children', []);
        }

        this.get('changeset.children').addObject({
          ruleMethod: 'FILTER',
          filterType: 'ALL',
          children: [],
          orderBy: null
        });
      },
      removeFilter: function removeFilter(filter) {
        // Given the full rule set is serialized, we can simply remove all descendant children
        this.get('changeset.children').removeObject(filter);
      },
      removeSelf: function removeSelf(filter) {
        this.removeFilter(filter);
      },
      moveUp: function moveUp(model) {
        var children = this.get('sortedChildren');

        var oldIndex = children.indexOf(model);
        var newIndex = Math.max(oldIndex - 1, 0);

        children.removeAt(oldIndex);
        children.insertAt(newIndex, model);

        this.get('changeset').set('children', children);
      },
      moveDown: function moveDown(model) {
        var children = this.get('sortedChildren');

        var oldIndex = children.indexOf(model);
        var newIndex = Math.min(oldIndex + 1, children.get('length') - 1);

        children.removeAt(oldIndex);
        children.insertAt(newIndex, model);

        this.get('changeset').set('children', children);
      }
    }
  });
});