define('ux-pcc/helpers/is-permitted', ['exports', 'ux-components/helpers/is-permitted'], function (exports, _isPermitted) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isPermitted.default;
    }
  });
  Object.defineProperty(exports, 'isPermitted', {
    enumerable: true,
    get: function () {
      return _isPermitted.isPermitted;
    }
  });
});