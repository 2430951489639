define('ux-pcc/components/inputs/percent-input', ['exports', 'ux-components/components/inputs/percent-input'], function (exports, _percentInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _percentInput.default;
    }
  });
});