define('ux-pcc/components/sortable-drop-list/sort-option', ['exports', 'ux-components/components/sortable-drop-list/sort-option'], function (exports, _sortOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sortOption.default;
    }
  });
});