define('ux-pcc/components/tags/tag-panel', ['exports', 'ember-uuid'], function (exports, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    i18n: Ember.inject.service(),

    // TODO: remove this once ember generates the elementId for tagless components (maybe 3.4)?
    temporaryElementId: (0, _emberUuid.v4)(),

    customer: null,

    isExpanded: false,

    editTags: Ember.computed.alias('customer.tags'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('tokenOptions', {
        createTokensOnBlur: true
      });
    },


    primaryAddress: Ember.computed('customer.addresses.@each.addressType', function () {
      return (this.get('customer.addresses') || []).findBy('addressType', 'BUSINESS') || (this.get('customer.addresses') || []).findBy('addressType', 'MAIN');
    }),

    actions: {
      openEdit: function openEdit() {
        var _this = this;

        if (this.get('isExpanded')) {
          this.send('closeEdit');
        } else {
          this.set('isExpanded', false);

          Ember.run.later(function () {
            _this.set('isExpanded', true);
          }, 1);
        }
      },
      closeEdit: function closeEdit() {
        this.set('isExpanded', false);
        this.set('editTags', this.get('customer.tags'));
      },
      save: function save() {
        var _this2 = this;

        var customer = this.get('customer');
        customer.set('tags', this.get('editTags').mapBy('value'));
        customer.save().then(function () {
          // Success!
          _this2.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + _this2.get('i18n').t('pcc-components.tags.tag-panel.success_message', { name: customer.get('name') }), 'success');
          _this2.send('closeEdit');
        }).catch(function (error) {
          _this2.get('flash')('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      }
    }
  });
});