define('ux-pcc/controllers/yield-manager/view/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    parentController: Ember.inject.controller('yield-manager.view'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    view: Ember.computed.alias('model.view'),
    viewParams: Ember.computed.alias('parentController.model.dashboardViewParameters'),
    prices: Ember.computed.alias('parentController.prices'),
    channels: Ember.computed.alias('parentController.channels'),
    subchannels: Ember.computed.alias('parentController.subchannels'),
    productGroups: Ember.computed.alias('parentController.productGroups'),
    brands: Ember.computed.alias('parentController.brands'),

    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    timezone: Ember.computed('model.type', function () {
      return ['YSGA'].indexOf(this.get('model.type')) > -1 ? _moment.default.tz.guess(true) : this.tz.get('customer');
    }),

    revenuePrecision: Ember.computed('customer', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'PCC.YIELD_MANAGEMENT_REVENUE_PRECISION');
      return model && model.get('settingValue') ? model.get('settingValue') : 0;
    }),

    actions: {
      flashMessage: function flashMessage(message, type) {
        this.send('flash', message, type);
      },
      updateFilter: function updateFilter(data) {
        this.get('parentController').send('updateFilter', data);
      }
    }
  });
});