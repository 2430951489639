define('ux-pcc/components/ml-wrapper', ['exports', 'ux-components/components/ml-wrapper'], function (exports, _mlWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mlWrapper.default;
    }
  });
});