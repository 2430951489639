define('ux-pcc/routes/channel-restatus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    store: Ember.inject.service(),

    model: function model() {
      var _this = this;

      var customer = this.modelFor('customer');

      // Grab the effective channel parameters so you can grab the list of allowed channels for restatus
      return this.store.query('channel-parameter', {
        channelID: this.get('channel.id'),
        customerID: customer.get('id'),
        effective: true
      }).then(function () {
        var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var sirParameter = results.findBy('parameterName', 'CHANNEL_INTERFACE_RESTATUS');
        if (sirParameter && sirParameter.get('parameterValue')) {

          var restatusChannels = sirParameter.get('parameterValue').split(',');

          var requests = [];

          restatusChannels.forEach(function (key) {
            requests.push(_this.store.queryRecord('channel', { channelCode: key }).then(function (results) {
              return results;
            }).catch(function () {
              // do nothing, channel not found, so do not include in list
            }));
          });

          return Ember.RSVP.all(requests).then(function (data) {
            var channelList = [];
            data.forEach(function (channel) {
              // Search by code may not find it, so it returns null, so don't add to the list
              if (channel) {
                channelList.push(channel);
              }
            });
            return { channels: channelList.sortBy('shortDescription') };
          });
        } else {
          return { channels: [] };
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model.channels.length > 0) {
        controller.set('selectedChannelId', model.channels[0].get('id'));
      }
    }
  });
});