define('ux-pcc/components/customer/customer-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    customerID: null,
    selectedDate: null,
    rollupStatus: 'DETAIL',
    competitorLoadDate: null,
    currencyCode: 'USD',
    forecastModelsSetting: null,
    showAdrForecast: null,
    showOccForecast: null,
    occupancyForecastSubcontentType: null,
    occupancySubcontentType: null,
    showBarPlanAllocation: null,
    showWeeklyOverview: null

  });
});