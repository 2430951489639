define('ux-pcc/models/reservation-search', ['exports', 'ux-data/models/reservation-search'], function (exports, _reservationSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservationSearch.default;
    }
  });
});