define('ux-pcc/routes/customer/performance/matrix', ['exports', 'moment', 'lodash', 'ux-components/utils/to-boolean', 'ux-components/utils/stringify-query-params'], function (exports, _moment, _lodash, _toBoolean, _stringifyQueryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    permission: Ember.inject.service(),
    ajax: Ember.inject.service(),

    queryParams: {
      startDate: {
        refreshModel: true
      },
      viewBy: {
        refreshModel: true
      },
      inventoryRollup: {
        refreshModel: true
      }
    },

    initialSpan: 3,

    model: function model(params) {
      var _this = this;

      var customer = this.modelFor('customer');
      var settings = customer.get('settings').findBy('settingCode', 'PCC.ENABLE_MATRIX_INVENTORY_ROLLUP_SELECTOR');
      this.set('showInventoryRollup', settings && settings.get('settingValue') ? (0, _toBoolean.default)(settings.get('settingValue')) : false);

      var tz = this.tz.get('customer');
      var span = this.get('initialSpan');
      var startDate = (params.startDate ? _moment.default.tz(params.startDate, tz) : _moment.default.tz(tz)).startOf('day');

      this.set('viewBy', params.viewBy);
      this.set('inventoryRollup', params.inventoryRollup);

      var promises = {
        rollupProducts: this.get('showInventoryRollup') ? this.store.query('product', {
          customerID: customer.get('id'),
          productUsageType: 'INVENTORY_ROLLUP'
        }) : Ember.RSVP.resolve([]),
        products: this.get('showInventoryRollup') ? this.store.query('product', {
          customerID: customer.get('id'),
          productUsageType: 'AVAILABILITY',
          productTemplateID: '10a00000-0000-0000-0000-000000000000',
          include: 'ancestors'
        }) : Ember.RSVP.resolve([])

        // TODO: Figure out a better place to hold constants for page size
      };var width = Ember.$(window).width();
      if (width < 768) {
        return Ember.RSVP.hash(promises).then(function (results) {
          return Ember.RSVP.hash({
            rollupProducts: results.rollupProducts,
            products: results.products,
            data: _this._requestData(startDate, 1, startDate, params.viewBy, _this.get('inventoryRollup') ? [_this.get('inventoryRollup')] : results.rollupProducts.mapBy('id'))
          });
        });
      } else {
        // Subtract 3 days from the beginDate to get the begin date of the range retrieved
        var date = startDate.clone();
        date = date.subtract(span, 'days');

        return Ember.RSVP.hash(promises).then(function (results) {
          return Ember.RSVP.hash({
            rollupProducts: results.rollupProducts,
            products: results.products,
            data: _this._requestData(date, span * 2, startDate, params.viewBy, _this.get('inventoryRollup') ? [_this.get('inventoryRollup')] : results.rollupProducts.mapBy('id'))
          });
        });
      }
    },
    _calculatePreviousComparisonDate: function _calculatePreviousComparisonDate(date, viewBy) {
      switch (viewBy) {
        case 'day':
          return date.clone().subtract(1, 'day');
        case 'month':
          return date.clone().subtract(1, 'month');
        default:
          return date.clone().subtract(52, 'weeks');
      }
    },
    setupController: function setupController(controller, model) {
      var _this2 = this;

      // TODO: Figure out a better place to hold constants for page size
      var width = Ember.$(window).width();
      controller.set('rollupProducts', model.rollupProducts.sortBy('shortDescription'));
      controller.set('products', model.products);
      controller.set('isMobile', width < 768);
      controller.set('isDesktop', width > 992);
      controller.set('viewBy', this.viewBy);

      var tz = this.tz.get('customer');
      var span = this.get('initialSpan');
      var date = (0, _moment.default)(model.data.beginDate).tz(tz).startOf('day');

      // If the start date was set, format it
      if (controller.get('startDate')) {
        var startDate = _moment.default.tz(controller.get('startDate'), tz).startOf('day');
        controller.set('startDate', startDate.format());
      } else {
        controller.set('startDate', date.format());
      }

      controller.set('beginDate', date.clone());

      if (controller.get('isMobile')) {
        controller.set('endDate', date.clone());
        this.controller.set('scrollingPaused', true);
      } else {
        controller.set('endDate', date.clone().add(span * 2, 'days'));
      }

      controller.linkFor = function (route, options) {
        var qs = (0, _lodash.toPairs)(options).map(function (p) {
          return [p[0], encodeURIComponent(p[1])].join('=');
        }).join('&');

        return _this2.router.generate(route) + '?' + qs;
      };

      this._super(controller, model.data);
    },
    _requestData: function _requestData(date, timespan, selectedDate, viewBy, rollupProductIds) {
      var customer = this.modelFor('customer');
      var customerId = customer.get('id');
      var rollupStatus = 'CUSTOMER' === customer.get('customerType') ? 'DETAIL' : 'ROLLUP';
      var stly = this._calculatePreviousComparisonDate(date.clone(), viewBy);

      var template = {
        channelcode: 'ALL',
        begindate: date.format(),
        enddate: date.clone().add(timespan, 'days').format(),
        frequency: 'DAY',
        customerid: customerId
      };

      var weather = this.ajax.request('/rest/v1/analyticEntries', {
        data: Ember.merge({
          contenttype: 'WEATHER',
          analytictype: 'AMOUNT',
          action: 'STATISTIC',
          datatype: 'VALUE',
          rollupstatus: rollupStatus,
          fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
        }, template)
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;
        return analyticEntries;
      });

      var params = {
        customerID: customerId,
        beginDate: template.begindate,
        endDate: template.enddate
      };

      var events = this.store.query('marketing-campaign', {
        customerID: customerId,
        campaignType: 'SPECIAL_EVENT,FESTIVAL,FAMILY,MUSIC,SPORT,FUNDRAISER,FILM',
        beginDate: date.format('YYYY-MM-DD'),
        endDate: date.clone().add(timespan, 'days').format('YYYY-MM-DD')
      });

      var promises = {
        weather: weather,
        events: events
      };

      if (this.get('showInventoryRollup')) {
        // Weird syntax where you can pass in multiple ids, but they have to be separate
        Ember.merge(promises, {
          dailyMetrics: this.ajax.request('rest/v1/pms/dailyMetrics?ids[]=' + rollupProductIds.join('&ids[]='), {
            data: {
              customerID: customerId,
              begindate: date.format('YYYY-MM-DD'),
              enddate: date.clone().add(timespan, 'days').format('YYYY-MM-DD')
            }
          }).then(function (_ref2) {
            var _ref2$pMSDailyMetrics = _ref2.pMSDailyMetrics,
                pMSDailyMetrics = _ref2$pMSDailyMetrics === undefined ? [] : _ref2$pMSDailyMetrics;
            return pMSDailyMetrics;
          }),
          dailyMetricsStly: this.ajax.request('rest/v1/pms/dailyMetrics?ids[]=' + rollupProductIds.join('&ids[]='), {
            data: {
              customerID: customerId,
              begindate: stly.format('YYYY-MM-DD'),
              enddate: stly.clone().add(timespan, 'days').format('YYYY-MM-DD')
            }
          }).then(function (_ref3) {
            var _ref3$pMSDailyMetrics = _ref3.pMSDailyMetrics,
                pMSDailyMetrics = _ref3$pMSDailyMetrics === undefined ? [] : _ref3$pMSDailyMetrics;
            return pMSDailyMetrics;
          }),
          dailyMetricsPerformance: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.merge({
              contenttype: 'REVENUE', // Only need the revenue analytics, as we will use the sold count for OCCUPANCY numbers
              analytictype: 'COUNT',
              subcontenttype: '',
              action: 'STATISTIC',
              datatype: 'VALUE,REVENUE',
              rollupstatus: 'ROLLUP',
              groupBy: 'PRODUCT',
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
            }, template)
          }).then(function (_ref4) {
            var _ref4$analyticEntries = _ref4.analyticEntries,
                analyticEntries = _ref4$analyticEntries === undefined ? [] : _ref4$analyticEntries;

            return analyticEntries.filterBy('groupBy', 'PRODUCT').rejectBy('subContentType');
          }),
          dailyMetricsPerformanceStly: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.assign({
              contenttype: 'REVENUE', // Only need the revenue analytics, as we will use the sold count for OCCUPANCY numbers
              analytictype: 'COUNT',
              subcontenttype: '',
              action: 'STATISTIC',
              datatype: 'VALUE,REVENUE',
              rollupstatus: 'ROLLUP',
              groupBy: 'PRODUCT',
              groupByValue: 'ad59a2fa-7c63-49f4-887f-432a01d96caf,297c6097-624b-4242-82f5-03534987956d',
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
            }, template, {
              begindate: stly.format(),
              enddate: stly.clone().add(timespan, 'days').format()
            })
          }).then(function (_ref5) {
            var _ref5$analyticEntries = _ref5.analyticEntries,
                analyticEntries = _ref5$analyticEntries === undefined ? [] : _ref5$analyticEntries;

            return analyticEntries.filterBy('groupBy', 'PRODUCT').rejectBy('subContentType');
          })
        });
      } else {
        var adrForecast = this.ajax.post('/rest/Forecast/ADR' + (0, _stringifyQueryParams.default)(params), { contentType: 'application/json' });

        Ember.merge(promises, {
          performance: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.merge({
              contenttype: 'OCCUPANCY,OCCUPANCY_FORECAST,ADR,REVPAR,REVENUE',
              analytictype: 'AMOUNT,PERCENT',
              action: 'STATISTIC',
              datatype: 'VALUE,REVENUE',
              rollupstatus: rollupStatus,
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
            }, template)
          }).then(function (_ref6) {
            var _ref6$analyticEntries = _ref6.analyticEntries,
                analyticEntries = _ref6$analyticEntries === undefined ? [] : _ref6$analyticEntries;
            return analyticEntries;
          }),
          performanceSTLY: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.assign({
              contenttype: 'OCCUPANCY,ADR,REVPAR,REVENUE',
              analytictype: 'AMOUNT,PERCENT',
              subcontenttype: '',
              action: 'STATISTIC',
              datatype: 'VALUE,REVENUE',
              rollupstatus: rollupStatus,
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
            }, template, {
              begindate: stly.format(),
              enddate: stly.clone().add(timespan, 'days').format()
            })
          }).then(function (_ref7) {
            var _ref7$analyticEntries = _ref7.analyticEntries,
                analyticEntries = _ref7$analyticEntries === undefined ? [] : _ref7$analyticEntries;
            return analyticEntries;
          }),
          adrForecast: adrForecast.then(function (_ref8) {
            var adrForecasts = _ref8.adrForecasts;
            return adrForecasts;
          })
        });
      }

      if (customer.get('customerType') === 'CUSTOMER' && !this.get('showInventoryRollup')) {
        var subContentTypes = 'PHYSICAL,OVERSELL,DO_NOT_SELL,CONSUMED,OUT_OF_ORDER,YIELDABLE';
        if (this.permission.isPermitted('performance_matrix', 'view', 'group_block_pickup')) {
          subContentTypes += ',BLOCKED,PICKED_UP';
        }
        Ember.merge(promises, {
          inventory: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.merge({
              contenttype: 'INVENTORY',
              analytictype: 'COUNT',
              subcontenttype: subContentTypes,
              action: 'STATISTIC',
              datatype: 'VALUE',
              rollupstatus: rollupStatus,
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupBy',
              groupBy: 'PRODUCT'
            }, template)
          }).then(function (_ref9) {
            var _ref9$analyticEntries = _ref9.analyticEntries,
                analyticEntries = _ref9$analyticEntries === undefined ? [] : _ref9$analyticEntries;
            return analyticEntries;
          }),
          inventorySTLY: this.ajax.request('/rest/v1/analyticEntries', {
            data: Ember.assign({
              contenttype: 'INVENTORY',
              analytictype: 'COUNT',
              subcontenttype: subContentTypes,
              action: 'STATISTIC',
              datatype: 'VALUE',
              rollupstatus: rollupStatus,
              fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupBy',
              groupBy: 'PRODUCT'
            }, template, {
              begindate: stly.format(),
              enddate: stly.clone().add(timespan, 'days').format()
            })
          }).then(function (_ref10) {
            var _ref10$analyticEntrie = _ref10.analyticEntries,
                analyticEntries = _ref10$analyticEntrie === undefined ? [] : _ref10$analyticEntrie;
            return analyticEntries;
          })
        });
        if (this.permission.isPermitted('performance_matrix', 'view', 'rates')) {
          Ember.merge(promises, {
            rates: this.ajax.request('/rest/v1/analyticEntries', {
              data: Ember.merge({
                channelcode: 'ALL',
                contenttype: 'SELL_RATE',
                analytictype: 'AMOUNT',
                action: 'STATISTIC',
                datatype: 'VALUE',
                rollupstatus: rollupStatus,
                fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
              }, template)
            }).then(function (_ref11) {
              var _ref11$analyticEntrie = _ref11.analyticEntries,
                  analyticEntries = _ref11$analyticEntrie === undefined ? [] : _ref11$analyticEntrie;
              return analyticEntries;
            })
          });
        }

        if (this.permission.isPermitted(customer.get('permissionType') + 'kpi', 'view', 'info_station')) {
          Ember.merge(promises, {
            infoStation: this.ajax.request('/rest/v1/analyticEntries', {
              data: Ember.merge({
                channelcode: 'ALL',
                contenttype: 'EXTERNAL_SUMMARY',
                analytictype: 'DETAILS',
                action: 'STATISTIC',
                datatype: 'VALUE',
                rollupstatus: rollupStatus,
                fields: 'id,beginDate,endDate,description,dataValues,contentType,subContentType'
              }, template)
            }).then(function (_ref12) {
              var _ref12$analyticEntrie = _ref12.analyticEntries,
                  analyticEntries = _ref12$analyticEntrie === undefined ? [] : _ref12$analyticEntrie;
              return analyticEntries;
            })
          });
        }
      }

      return Ember.RSVP.hash(promises).then(function (data) {
        return Ember.merge(data, { beginDate: date, selectedDate: selectedDate });
      });
    },


    actions: {
      loadNext: function loadNext(timespan) {
        var _this3 = this;

        var firstRun = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.controller.get('scrollingPaused') && !firstRun) {
          return;
        }
        this.controller.set('scrollingPaused', true);

        var date = this.controller.get('endDate').clone().add(1, 'days');
        timespan = timespan || 10;
        this._requestData(date, timespan, null, this.controller.get('viewBy'), this.controller.get('filteredRollupProductIds')).then(function (results) {
          _this3.controller._hijackModel(results, date, timespan, false, firstRun);
          _this3.controller.set('endDate', date.clone().add(timespan, 'days'));
          Ember.run.schedule('afterRender', _this3, function () {
            _this3.controller.set('scrollingPaused', false);
          });
        }, function () {
          _this3.controller.set('scrollingPaused', false);
        });
      },
      loadPrevious: function loadPrevious(timespan) {
        var _this4 = this;

        var firstRun = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.controller.get('scrollingPaused') && !firstRun) {
          return;
        }
        this.controller.set('scrollingPaused', true);

        timespan = timespan || 10;
        var date = this.controller.get('beginDate').clone().subtract(timespan + 1, 'days');
        this._requestData(date, timespan, null, this.controller.get('viewBy'), this.controller.get('filteredRollupProductIds')).then(function (results) {
          _this4.controller._hijackModel(results, date, timespan, true, firstRun);
          _this4.controller.set('beginDate', date);

          Ember.run.schedule('afterRender', _this4, function () {
            _this4.controller.set('scrollingPaused', false);
          });
        }, function () {
          _this4.controller.set('scrollingPaused', false);
        });
      }
    }
  });
});