define('ux-pcc/controllers/customer/performance/index', ['exports', 'accounting', 'moment', 'ux-components/utils/moment-range', 'ux-components/utils/to-boolean'], function (exports, _accounting, _moment, _momentRange, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),

    beginDate: null,
    endDate: null,

    selectedProduct: 'ALL',
    data: null,

    occupancySubcontentType: Ember.computed('customer.settings.[]', function () {
      var setting = (this.customer.settings || []).findBy('settingCode', 'INVENTORY_CALCULATION_METHOD_PROPERTY_INSIGHTS');
      return setting && setting.settingValue ? setting.settingValue : 'YIELDABLE';
    }),

    minEndDate: Ember.computed('beginDate', 'tz.customer', function () {
      var beginDate = _moment.default.tz(this.beginDate, this.tz.customer);
      return beginDate.clone().add(6, 'days').startOf('day');
    }),

    maxEndDate: Ember.computed('beginDate', 'tz.customer', function () {
      var beginDate = _moment.default.tz(this.beginDate, this.tz.customer);
      return beginDate.clone().add(52, 'weeks').endOf('day');
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', 'maxEndDate', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.minEndDate,
        maxDate: this.maxEndDate
      };
    }),

    occupancyDataFields: Ember.computed('i18n.locale', function () {
      return [{ key: 'occupancy', label: this.i18n.t('customer.performance.overview.chart.occupancy') },
      // { key: 'occupancy_forecast', label: this.i18n.t('customer.performance.overview.chart.forecast') },
      { key: 'occupancy_stly', label: this.i18n.t('customer.performance.overview.chart.occupancy_stly') }];
    }),

    revenueDataFields: Ember.computed('i18n.locale', function () {
      return [{ key: 'adr', label: this.i18n.t('customer.performance.overview.chart.adr') }, { key: 'revpar', label: this.i18n.t('customer.performance.overview.chart.revpar') }, { key: 'adr_stly', label: this.i18n.t('customer.performance.overview.chart.adr_stly') }, { key: 'revpar_stly', label: this.i18n.t('customer.performance.overview.chart.revpar_stly') }];
    }),

    currencyCode: Ember.computed('customer.settings.[]', function () {
      // Get the customer setting for the default currency code
      var currencySetting = (this.customer.settings || []).findBy('settingCode', 'DEFAULT_CURRENCY');
      return currencySetting && currencySetting.settingValue ? currencySetting.settingValue : 'USD';
    }),

    inventoryRollupSetting: Ember.computed('customer.settings', function () {
      var setting = (this.customer.settings || []).findBy('settingCode', 'PCC.ENABLE_PROPERTY_DASHBOARD_INVENTORY_ROLLUP_SELECTOR');
      return setting ? (0, _toBoolean.default)(setting.settingValue) : false;
    }),

    showInventorySegments: Ember.computed('model.rollupProducts.[]', 'inventoryRollupSetting', function () {
      return this.inventoryRollupSetting && (this.model.rollupProducts || []).length > 0;
    }),

    rollupProductIds: Ember.computed('model.rollupProducts.[]', 'selectedProduct', function () {
      if (this.selectedProduct === 'ALL') {
        return this.model.rollupProducts.mapBy('id');
      }

      return this.model.rollupProducts.filterBy('externalReference', this.selectedProduct).mapBy('id');
    }),

    filteredProducts: Ember.computed('rollupProductIds.[]', 'model.products.[]', function () {
      var _this = this;

      // filter the product list down where the rollup product is in the path
      return (this.model.products || []).filter(function (p) {
        return (p.get('ancestors') || []).filter(function (a) {
          return _this.rollupProductIds.includes(a.get('id'));
        }).length > 0;
      });
    }),

    _fetchObserver: Ember.observer('beginDate', 'endDate', 'rollupProductIds.[]', function () {
      Ember.run.once(this, '_fetchData');
    }),

    _fetchData: function _fetchData() {
      var _this2 = this;

      this.set('loading', true);

      var beginDate = _moment.default.tz(this.beginDate, this.tz.customer);
      var endDate = _moment.default.tz(this.endDate, this.tz.customer);

      var beginDateSTLY = beginDate.clone().subtract(52, 'weeks');
      var endDateSTLY = endDate.clone().subtract(52, 'weeks');

      var isProperty = this.customer.customerType === 'CUSTOMER';

      var promises = {};

      if (this.showInventorySegments) {
        promises.occupancyCollection = this.ajax.request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: beginDate.format(),
            enddate: endDate.format(),
            frequency: 'DAY',
            customerid: this.customer.id,
            contenttype: 'REVENUE',
            subcontenttype: '',
            analytictype: 'COUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'ROLLUP',
            groupby: 'PRODUCT',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;
          return analyticEntries;
        });

        promises.occupancyCollectionSTLY = this.ajax.request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: beginDateSTLY.format(),
            enddate: endDateSTLY.format(),
            frequency: 'DAY',
            customerid: this.customer.id,
            contenttype: 'REVENUE',
            analytictype: 'COUNT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'ROLLUP',
            groupby: 'PRODUCT',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue,groupByValue'
          }
        }).then(function (_ref2) {
          var _ref2$analyticEntries = _ref2.analyticEntries,
              analyticEntries = _ref2$analyticEntries === undefined ? [] : _ref2$analyticEntries;
          return analyticEntries;
        });

        promises.rollup = this.ajax.request('/rest/v1/pms/dailyMetrics?ids[]=' + this.rollupProductIds.join('&ids[]='), {
          data: {
            customerID: this.customer.id,
            begindate: beginDate.format('YYYY-MM-DD'),
            enddate: endDate.format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref3) {
          var _ref3$pMSDailyMetrics = _ref3.pMSDailyMetrics,
              pMSDailyMetrics = _ref3$pMSDailyMetrics === undefined ? [] : _ref3$pMSDailyMetrics;
          return pMSDailyMetrics;
        });

        promises.rollupSTLY = this.ajax.request('/rest/v1/pms/dailyMetrics?ids[]=' + this.rollupProductIds.join('&ids[]='), {
          data: {
            customerID: this.customer.id,
            begindate: beginDateSTLY.format('YYYY-MM-DD'),
            enddate: endDateSTLY.format('YYYY-MM-DD'),
            includeInventoryMetrics: true
          }
        }).then(function (_ref4) {
          var _ref4$pMSDailyMetrics = _ref4.pMSDailyMetrics,
              pMSDailyMetrics = _ref4$pMSDailyMetrics === undefined ? [] : _ref4$pMSDailyMetrics;
          return pMSDailyMetrics;
        });
      } else {
        promises.occupancyCollection = this.ajax.request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: beginDate.format(),
            enddate: endDate.format(),
            frequency: 'DAY',
            customerid: this.customer.id,
            contenttype: 'OCCUPANCY,ADR,REVPAR', //'OCCUPANCY,OCCUPANCY_FORECAST,ADR,REVPAR',
            analytictype: 'AMOUNT,PERCENT',
            action: 'STATISTIC',
            datatype: 'VALUE,REVENUE',
            rollupstatus: isProperty ? 'DETAIL' : 'ROLLUP',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
          }
        }).then(function (_ref5) {
          var _ref5$analyticEntries = _ref5.analyticEntries,
              analyticEntries = _ref5$analyticEntries === undefined ? [] : _ref5$analyticEntries;
          return analyticEntries;
        });

        promises.occupancyCollectionSTLY = this.ajax.request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: beginDateSTLY.format(),
            enddate: endDateSTLY.format(),
            frequency: 'DAY',
            customerid: this.customer.id,
            contenttype: 'OCCUPANCY,ADR,REVPAR',
            analytictype: 'AMOUNT,PERCENT',
            subcontenttype: '',
            action: 'STATISTIC',
            datatype: 'VALUE,REVENUE',
            rollupstatus: isProperty ? 'DETAIL' : 'ROLLUP',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
          }
        }).then(function (_ref6) {
          var _ref6$analyticEntries = _ref6.analyticEntries,
              analyticEntries = _ref6$analyticEntries === undefined ? [] : _ref6$analyticEntries;
          return analyticEntries;
        });
      }

      Ember.RSVP.hash(promises).then(function (results) {
        return _this2.computeData(results);
      }).finally(function () {
        return _this2.set('loading', false);
      });
    },
    computeData: function computeData(data) {
      var _this3 = this;

      var mapped = {};
      var range = (0, _momentRange.default)(_moment.default.tz(this.beginDate, this.tz.customer).startOf('day'), _moment.default.tz(this.endDate, this.tz.customer).endOf('day'));
      // Ensure days for each in range
      range.forEach(function (m) {
        mapped[m.format()] = {
          slice: m.format(),
          inventoryData: {
            sold: 0,
            sold_stly: 0,
            revenue: 0,
            revenue_stly: 0,
            yieldable: 0,
            yieldable_stly: 0
          }
        };
      });

      if (this.showInventorySegments) {
        data.rollup.forEach(function (d) {
          var date = _moment.default.tz(d.rpcDate, _this3.tz.customer);
          var inventoryData = mapped[date.format()].inventoryData;
          inventoryData.sold += (d.scheduledArrivals || []).length + (d.stayOvers || []).length;
          inventoryData.yieldable += d.yieldable || 0;
        });

        data.rollupSTLY.forEach(function (d) {
          var date = _moment.default.tz(d.rpcDate, _this3.tz.customer).add(52, 'weeks');
          var inventoryData = mapped[date.format()].inventoryData;
          inventoryData.sold_stly += (d.scheduledArrivals || []).length + (d.stayOvers || []).length;
          inventoryData.yieldable_stly += d.yieldable || 0;
        });

        data.occupancyCollection.filter(function (a) {
          return _this3.filteredProducts.findBy('id', a.groupByValue);
        }).forEach(function (d) {
          var date = _moment.default.tz(d.beginDate, _this3.tz.customer);
          var inventoryData = mapped[date.format()].inventoryData;
          inventoryData.revenue += parseFloat(d.dataValue);
        });

        data.occupancyCollectionSTLY.filter(function (a) {
          return _this3.filteredProducts.findBy('id', a.groupByValue);
        }).forEach(function (d) {
          var date = _moment.default.tz(d.beginDate, _this3.tz.customer).add(52, 'weeks');
          var inventoryData = mapped[date.format()].inventoryData;
          inventoryData.revenue_stly += parseFloat(d.dataValue);
        });

        Object.keys(mapped).forEach(function (key) {
          var m = mapped[key];
          m.occupancy = (0, _accounting.formatNumber)(m.inventoryData.yieldable !== 0 ? m.inventoryData.sold / m.inventoryData.yieldable * 100 : 0, 1);
          m.adr = m.inventoryData.sold !== 0 ? m.inventoryData.revenue / m.inventoryData.sold : 0;
          m.revpar = m.inventoryData.yieldable !== 0 ? m.inventoryData.revenue / m.inventoryData.yieldable : 0;

          m.occupancy_stly = (0, _accounting.formatNumber)(m.inventoryData.yieldable_stly !== 0 ? m.inventoryData.sold_stly / m.inventoryData.yieldable_stly * 100 : 0, 1);
          m.adr_stly = m.inventoryData.sold_stly !== 0 ? m.inventoryData.revenue_stly / m.inventoryData.sold_stly : 0;
          m.revpar_stly = m.inventoryData.yieldable_stly !== 0 ? m.inventoryData.revenue_stly / m.inventoryData.yieldable_stly : 0;
        });
      } else {
        var entries = (data.occupancyCollection || []).addObjects(data.occupancyCollectionSTLY);
        var isProperty = this.customer.customerType === 'CUSTOMER';

        var getPropName = function getPropName(contentType, subContentType, isStly) {
          switch (contentType) {
            case 'ADR':
              return isStly ? 'adr_stly' : 'adr';
            case 'REVPAR':
              return isStly ? 'revpar_stly' : 'revpar';
            case 'OCCUPANCY':
              return isStly ? 'occupancy_stly' : 'occupancy';
            // case 'OCCUPANCY_FORECAST':
            //   // plannedEnhancement: multiple forecasting models (use subContentType.toLowerCase())
            //   return 'occupancy_forecast';
          }
        };

        entries.forEach(function (e) {
          var date = _moment.default.tz(e.beginDate, _this3.tz.customer);

          // check stly; update to actual date if is stly
          var isStly = !date.isBetween(range.get('firstObject'), range.get('lastObject'), null, '[]');
          var m = isStly ? date.clone().add(52, 'weeks') : date;
          var key = m.format();

          var prop = getPropName(e.contentType, e.subContentType, isStly);

          if (mapped[key]) {
            switch (e.contentType) {
              // case "OCCUPANCY_FORECAST" :
              //   mapped[key].occupancy_forecast = parseFloat(e.dataValue);
              //   break;
              case 'OCCUPANCY':
                if (!isProperty || e.subContentType && _this3.occupancySubcontentType && e.subContentType.toUpperCase() === _this3.occupancySubcontentType.toUpperCase()) {
                  mapped[key][prop] = parseFloat(e.dataValue);
                }
                break;
              default:
                mapped[key][prop] = parseFloat(e.dataValue);
                break;
            }
          }
        });
      }

      this.set('data', Object.keys(mapped).map(function (key) {
        return mapped[key];
      }));
    }
  });
});