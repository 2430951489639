define('ux-pcc/components/rules/rule-then', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['rule-then'],

    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    thenTypeComponent: Ember.computed('ruleType', function () {
      if (this.get('ruleType').indexOf('ALERT') === 0) {
        return 'rules/thens/notification-then';
      } else {
        return 'rules/thens/' + this.get('ruleType').toLowerCase() + '-then';
      }
    }),

    actions: {
      registerValidation: function registerValidation(e) {
        this.get('onRegisterValidation')(e);
      },
      unregisterValidation: function unregisterValidation(e) {
        this.get('onUnregisterValidation')(e);
      }
    }
  });
});