define('ux-pcc/components/card-view/card-footer', ['exports', 'ux-components/components/card-view/card-footer'], function (exports, _cardFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cardFooter.default;
    }
  });
});