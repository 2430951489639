define('ux-pcc/routes/affiliates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    i18n: Ember.inject.service(),

    model: function model() /* params */{
      var _this = this;

      var customer = this.modelFor('customer');

      switch (customer.get('customerType')) {
        case "CUSTOMERS":
          {
            // Get xrefs
            return this.store.query('customer', {
              folderid: customer.get('id'),
              detaillevel: 'XREF',
              include: 'address'
            });
          }
        case "AFFILIATE":
          {
            var customers = Ember.A();
            return this.store.query('customer', { folderid: customer.get('id'), folderyn: "Y" }).then(function (results) {
              var promises = [];
              (results || []).rejectBy('affiliateCustomer.id', null).forEach(function (result) {
                promises.push(_this.store.query('customer', {
                  folderid: result.get('id'),
                  folderyn: "ALL",
                  include: "address"
                }).then(function (customerResults) {
                  customers.addObjects(customerResults);
                }));
              });
              return Ember.RSVP.all(promises, "Resolving customer promises").then(function () {
                return customers;
              });
            });
          }
        default:
          {
            // Get children
            // TODO folderyn is a filter, so ALL is implied by default
            return this.store.query('customer', {
              folderid: customer.get('id'),
              folderyn: "ALL",
              customertype: "AFFILIATE,CUSTOMER,CUSTOMERS",
              include: "address"
            });
          }
      }
    },
    afterModel: function afterModel(customers, transition /*, queryParams*/) {
      var properties = customers ? customers.filterBy('customerType', "CUSTOMER") : [];
      if (properties.length === 0 && (!transition.queryParams || !transition.queryParams.view || transition.queryParams.view === 'property')) {
        transition.queryParams.view = 'chain';
      }
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      confirmDelete: function confirmDelete(object) {

        this.dialog.showConfirmation({
          titleText: this.i18n.t('affiliates.delete.warning'),
          messageText: this.i18n.t('affiliates.delete.confirm_delete'),
          confirmText: this.i18n.t('affiliates.delete.proceed'),
          cancelText: this.i18n.t('affiliates.delete.close'),
          onConfirm: function onConfirm() {
            var _this2 = this;

            object.destroyRecord().then(function () /* results */{
              var model = _this2.controller.get('model');
              var item = model.findBy('id', object.get('id'));
              model.removeObject(item);
              _this2.send('returnOrTransitionTo', 'affiliates');
              _this2.send('flash', "<i class='fa fa-thumbs-up'></i> " + "Successfully deleted affiliate '" + object.get('name') + "'.", 'success');
            }, function (error) {
              _this2.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
            });
          }
        });
      }
    }
  });
});