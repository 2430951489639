define('ux-pcc/mixins/controllers/places-searchable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _populateCustomer: function _populateCustomer(customer, place) {
      customer.set('name', place.name);
      var phone = customer.get('contactInformations').findBy('informationType', 'MAIN_PHONE');
      phone.set('informationValue', place.formattedPhoneNumber);

      if (place.address !== null) {
        var _address = this.store.createRecord('address', place.address);
        _address.set('addressType', 'BUSINESS');
        customer.get('addresses').addObject(_address);
      }
      var address = (customer.get('addresses') || []).findBy('addressType', 'BUSINESS') || (customer.get('addresses') || []).findBy('addressType', 'MAIN');
      address.set('rawAddressText', place.formattedAddress);
      address.set('geolocationLatitude', place.geolocationLatitude);
      address.set('geolocationLongitude', place.geolocationLongitude);

      return customer;
    },
    _populateExistingCustomer: function _populateExistingCustomer(newCustomer, customer) {
      newCustomer.set('name', customer.get('externalReference') + ' - ' + customer.get('name'));

      newCustomer.set('contactInformations', (customer.get('contactInformations') || []).toArray());
      newCustomer.set('addresses', (customer.get('addresses') || []).toArray());

      return customer;
    },

    actions: {
      enableAddressEdit: function enableAddressEdit() {
        this.set('addressEditable', true);
      }
    }
  });
});