define('ux-pcc/metrics-adapters/google-tag-manager', ['exports', 'ember-metrics/metrics-adapters/google-tag-manager'], function (exports, _googleTagManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _googleTagManager.default.extend({
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var userId = options.distinctId;
      var email = options.email;

      var dataLayer = this.dataLayer;

      var identifyEvent = {
        userId: userId,
        email: email
      };

      window[dataLayer].push(identifyEvent);

      return identifyEvent;
    }
  });
});