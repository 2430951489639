define('ux-pcc/components/customer-multimedia', ['exports', 'ux-components/components/customer-multimedia'], function (exports, _customerMultimedia) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _customerMultimedia.default;
    }
  });
});