define('ux-pcc/controllers/login/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),

    showForm: Ember.computed('config.authType', function () {
      return this.get('config.authType') !== 'SAML2';
    }),

    showExternal: Ember.computed('config.authType', function () {
      return this.get('config.authType') === 'FIREBASE';
    }),

    headerText: Ember.computed('i18n.locale', 'forgotPassword', function () {
      return this.forgotPassword ? this.i18n.t('login.index.reset_password') : this.i18n.t('login.index.sign_in_now');
    }),

    forgotPasswordLinkText: Ember.computed('i18n.locale', 'forgotPassword', function () {
      return this.forgotPassword ? this.i18n.t('login.index.back') : this.i18n.t('login.index.forgot_password');
    }),

    actions: {
      /**
       This action will authenticate the session with the configured
       [Ember.SimpleAuth.LoginControllerMixin#authenticatorFactory](#Ember-SimpleAuth-LoginControllerMixin-authenticatorFactory)
       if both `identification` and `password` are non-empty. It passes both
       values to the authenticator.
        _The action also resets the `password` property so sensitive data does not
       stay in memory for longer than necessary._
        @method actions.authenticate
       */
      authenticate: function authenticate() {
        var _this = this;

        this.send('clearFlashes');
        this.set('isLoading', true);

        this.session.authenticate('authenticator:token', this.email, this.password).catch(function () {
          return (/*reason*/_this.send('flash', _this.i18n.t('login.index.sign_in_failed_message'), 'danger')
          );
        }).finally(function () {
          return _this.set('isLoading', false);
        });

        this.set('password', null);
      },
      forgotPassword: function forgotPassword() {
        var _this2 = this;

        this.send('clearFlashes');
        this.set('isLoading', true);

        this.ajax.request('/rest/v1/auth/forgotPassword', { data: { email: this.email } }).then(function () {
          _this2.send('flash', _this2.i18n.t('login.index.forgot_password_success'), 'success');
          _this2.set('forgotPassword', false);
        }).catch(function () {
          return _this2.send('flash', _this2.i18n.t('login.index.forgot_password_failure'), 'danger');
        }).finally(function () {
          return _this2.set('isLoading', false);
        });
      },
      toggleForgotPassword: function toggleForgotPassword() {
        this.send('clearFlashes');
        this.toggleProperty('forgotPassword');
      },
      externalLogin: function externalLogin(providerName) {
        var _this3 = this;

        this.session.authenticate('authenticator:firebase', providerName).catch(function (reason) {
          _this3.send('flash', _this3.i18n.t('login.index.external.sign_in_failed_message') + ' ' + reason, 'danger');
        });
      }
    }
  });
});