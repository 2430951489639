define('ux-pcc/initializers/ember-drag-drop-polyfill', ['exports', 'ember-drag-drop-polyfill/initializers/ember-drag-drop-polyfill'], function (exports, _emberDragDropPolyfill) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberDragDropPolyfill.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberDragDropPolyfill.initialize;
    }
  });
});