define('ux-pcc/mixins/components/infinitely-scrollable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    margin: 100,

    // Life cycle
    didInsertElement: function didInsertElement() {
      this._super();
      this._togglePause();
    },

    willDestroyElement: function willDestroyElement() {
      Ember.$(this.element).off('.infinite');
      this._super();
    },

    // Handlers

    _togglePause: Ember.observer('paused', function () {
      var _this = this;

      if (this.get('paused')) {
        Ember.$(this.element).off('.infinite');
      } else {
        Ember.run.next(function () {
          Ember.$(_this.element).on('scroll.infinite', Ember.run.bind(_this, _this._handleScroll));
        });
      }
    }),

    _handleScroll: function _handleScroll() {
      var _this2 = this;

      var stateProps = {
        right: this._distanceRight(),
        left: this._distanceLeft(),
        width: this._scrollWidth()
      };

      var handleScrollUpdate = function handleScrollUpdate(unshift) {
        var $element = _this2.element;
        Ember.run.schedule('afterRender', _this2, function () {
          if (unshift) {
            $element.scrollLeft = _this2._scrollWidth() - stateProps.width;
          } else {
            $element.scrollLeft = stateProps.left;
          }
        });
      };

      var scrollUpdateRightObserver = function scrollUpdateRightObserver() {
        _this2.get('_columns').removeObserver('length', scrollUpdateRightObserver);
        Ember.run.once(_this2, handleScrollUpdate);
      };

      var scrollUpdateLeftObserver = function scrollUpdateLeftObserver() {
        _this2.get('_columns').removeObserver('length', scrollUpdateLeftObserver);
        Ember.run.once(_this2, handleScrollUpdate, true);
      };

      if (stateProps.right <= this.get('margin')) {
        this.get('_columns').addObserver('length', scrollUpdateRightObserver);
        this.next();
      }
      if (stateProps.left <= this.get('margin')) {
        this.get('_columns').addObserver('length', scrollUpdateLeftObserver);
        this.previous();
      }

      return false;
    },

    // Privates

    _distanceRight: function _distanceRight() {
      var $element = this.element;
      return $element.scrollWidth - $element.clientWidth - $element.scrollLeft;
    },

    _distanceLeft: function _distanceLeft() {
      var $element = this.element;
      return $element.scrollLeft;
    },

    _scrollWidth: function _scrollWidth() {
      var $element = this.element;
      return $element.scrollWidth;
    }

  });
});