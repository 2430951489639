define('ux-pcc/components/rules/conditions/-condition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['rule-condition-values'],

    filterConditions: []
  });
});