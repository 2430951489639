define('ux-pcc/components/customer/performance/matrix-pricing-target/target-variable', ['exports', 'accounting', 'ux-components/utils/format-currency'], function (exports, _accounting, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    inputType: "number",
    options: null,
    pricePoint: null,
    currencyCode: 'USD',

    selectInput: Ember.computed.equal("inputType", "select"),

    prunedOptions: Ember.computed('options.[]', 'day.values.targetPricePoints.@each.id', function () {
      var _this = this;

      // Strip out any existing options
      return this.get('options').filter(function (option) {
        return option.id === _this.get('valueField') || !_this.get('day.values.targetPricePoints').findBy('id', option.id);
      });
    }),

    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      // TODO: this could be moved to the didInsertElement hook w/o the afterRender
      Ember.run.schedule("afterRender", function () {
        if (_this2.get('inputType') === "number") {
          var $input = Ember.$(Ember.$(_this2.get('element')).find('input'));
          $input.on('keydown', function (evt) {
            if ($input.val().length >= 3 && !/^[\b]+$/.test(String.fromCharCode(evt.keyCode))) {
              evt.preventDefault();
            }
          });

          $input.bind("paste", function () /* evt */{
            //check to ensure pasted text is number only and is not greater than 3 characters in length
            Ember.run.later("afterRender", function () {
              if (!/^[\d]+$/.test($input.val()) || $input.val().length > 3) {
                $input.val("");
              }
            }, 10);
          });
        }
      });
    },
    _updateCalculations: function _updateCalculations() {
      var day = this.get('day');

      var targetPricePoints = (this.get('day.values.targetPricePoints') || []).filter(function (pp) {
        return !Ember.isEmpty(pp.id) && parseFloat(pp.values.allocatedPercent) > 0;
      });
      var pricePoints = this.get('day.values.allPricePoints');
      var yieldable = this.get('day.values.yieldable.count');

      // If the price point is cleared out, clear the rest of the fields
      if (this.get('pricePoint') && Ember.isEmpty(this.get('pricePoint.id'))) {
        this.set('pricePoint.values.allocatedPercent', 0);
        this.set('pricePoint.values.allocated', 0);
        this.set('pricePoint.values.lead', null);
        this.set('pricePoint.values.remaining', 0);
      }

      var targetValues = {
        avgAdr: 0,
        pyVar: 0,
        testAllocPct: 0,
        testAllocCount: 0,
        totalBasedOnOccupancy: 0
      };

      // Grab the target allocation and figure out the occupancy
      var targetAvgOccPct = parseFloat(_accounting.default.formatNumber(Ember.get(day, "values.targetAvgOccupancy"), 0));
      if (isNaN(parseFloat(Ember.get(day, "values.targetAvgOccupancy")))) {
        targetAvgOccPct = 0;
      }

      var targetAvgOcc = Math.round(yieldable * (targetAvgOccPct / 100));

      // Set the top rooms remaining
      Ember.set(day, "values.targetItemsRemaining", yieldable - targetAvgOcc);

      // Grab the target dilution
      var targetAvgDilution = parseFloat(_accounting.default.formatNumber(Ember.get(day, "values.targetAvgDilution"), 0));
      if (isNaN(parseFloat(Ember.get(day, "values.targetAvgDilution")))) {
        targetAvgDilution = 0;
      }

      var dilutionMultiplier = (100 - targetAvgDilution) / 100;

      // Set the variance to the previous year
      Ember.set(day, "values.targetPyVar", _accounting.default.formatNumber(targetAvgOccPct - Ember.get(day, "values.avgPyOccupancy"), 0));
      // Loop through the price points and set the appropriate values
      var usedAllocations = this.get('day.values.consumed.count') || 0;
      var remainingConsumed = this.get('day.values.consumed.count') || 0;
      var startingRevenue = parseFloat(_accounting.default.unformat(this.get('day.values.otbRevenue')) || "0");

      var finalPricePoint = Ember.get(targetPricePoints, "lastObject");

      targetPricePoints.forEach(function (pricePoint) {
        var price = pricePoints.findBy('id', pricePoint.id);
        if (price) {

          var allocatedPercent = 0;
          if (!isNaN(Ember.get(pricePoint, "values.allocated"))) {
            allocatedPercent = parseFloat(_accounting.default.formatNumber(Ember.get(pricePoint, "values.allocatedPercent"), 0));
          }
          // Keep track of the allocation percents, so we can set it at the end
          targetValues.testAllocPct += allocatedPercent;

          var consumed = 0;

          var allocatedSoFar = Math.floor(targetValues.testAllocPct / 100 * yieldable);
          var allocated = allocatedSoFar - targetValues.testAllocCount;
          Ember.set(pricePoint, "values.allocated", allocated);

          targetValues.testAllocCount += allocated;

          // Consume all available inventory to fill up to the active point
          if (remainingConsumed > 0) {
            consumed = Math.min(allocated, remainingConsumed);
            remainingConsumed -= consumed;
          }

          // If we still have allocations, or this is the finalPricePoint, start bucketing into the price points
          if (allocated - consumed > 0 || price.id === finalPricePoint.id) {
            // check how many to use based on the remaining target allocation
            var items = targetAvgOcc - usedAllocations > allocated - consumed && price.id !== finalPricePoint.id ? allocated - consumed : targetAvgOcc - usedAllocations;
            usedAllocations += items;
            targetValues.totalBasedOnOccupancy += items * price.amount;
            consumed = consumed + items;
          }

          Ember.set(pricePoint, 'values.remaining', allocated - consumed);
        }
      });

      // Set the allocation percent from all the price points
      Ember.set(day, "values.allocations.testAllocPct", _accounting.default.formatNumber(targetValues.testAllocPct, 0));

      // Calculate the target adr based on the calculated amount * the dilution and divide by the occupancy
      var targetAvgAdr = targetAvgOcc !== 0 ? (targetValues.totalBasedOnOccupancy * dilutionMultiplier + startingRevenue) / targetAvgOcc : 0;
      Ember.set(day, "values.targetAvgAdr", (0, _formatCurrency.default)(targetAvgAdr, this.get('currencyCode'), { hash: { hideSymbol: true } }));
      // Set the adr variance from the previous year
      Ember.set(day, "values.targetPyAdrVar", (0, _formatCurrency.default)(targetAvgAdr - Ember.get(day, "values.pyAvgAdr"), this.get('currencyCode'), { hash: { hideSymbol: true } }));
    },


    _targetObserver: Ember.observer('day.values.{targetAvgOccupancy,targetAvgDilution}', 'day.values.pricePoints.@each.values.allocatedPercent', function () {
      Ember.run.once(this, "_updateCalculations");
    }),
    _targetPricePointObserver: Ember.observer('pricePoint.id', 'pricePoint.values.allocatedPercent', function () {
      Ember.run.once(this, "_updateCalculations");
    }),

    didRender: function didRender() {
      // Run once to do the initial calculations for the target numbers
      Ember.run.once(this, "_updateCalculations");
    }
  });
});