define('ux-pcc/controllers/data-monitoring/index', ['exports', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileSaver.default, {

    queryParams: ['page', 'filterCustomerID', 'filterCustomerName', 'sortField', 'sortAscending', 'shortTermFilter', 'longTermFilter'],

    ajax: Ember.inject.service(),

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    page: 1,
    totalResults: 0,
    resultsPerPage: 50,
    filterCustomerName: null,
    filterCustomerID: null,
    sortField: null,
    sortAscending: true,
    shortTermFilter: 'NONE',
    longTermFilter: 'NONE',
    isAll: Ember.computed.equal('shortTermFilter', 'NONE'),
    isMatched: Ember.computed.equal('shortTermFilter', 'MATCHING'),
    isMissing: Ember.computed.equal('shortTermFilter', 'MISSING'),
    isMismatch: Ember.computed.equal('shortTermFilter', 'MISMATCH'),
    isMismatchMissing: Ember.computed.equal('shortTermFilter', 'MISSING_OR_MISMATCH'),

    isExportDisabled: Ember.computed.or('isAll', 'isMatched'),

    sortedList: { sortProperties: [] },

    fixedColumn: Ember.computed.not('deviceType.mobile'),
    placeholder: Ember.computed(function () {
      return this.i18n.t('customer.performance.data-monitoring.search_placeholder');
    }),

    typeaheadOptions: {
      highlight: true,
      autoselect: true
    },

    typeaheadData: Ember.computed(function () {
      var _this = this;

      var customersBloodhound = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/rest/v1/customers?q=%QUERY*&limit=10&fields=customers,id,name,customerType,externalReference,addresses,address1,city,state,postalCode&include=address&customertype=AFFILIATE,CUSTOMER,CUSTOMERS',
          filter: function filter(_ref) {
            var _ref$customers = _ref.customers,
                customers = _ref$customers === undefined ? [] : _ref$customers;

            return customers.map(function (customer) {
              var icon = '';

              switch (customer.customerType) {
                case 'CUSTOMER':
                  icon = 'fa-building-o';
                  break;
                case 'AFFILIATE':
                  icon = 'fa-sitemap';
                  break;
                case 'CUSTOMERS':
                  icon = 'fa-folder-open-o';
                  break;
                case 'COMPETITORS':
                  icon = 'fa-trophy';
                  break;
              }

              customer.icon = '<i class="fa ' + icon + '"></i>';

              var address = (customer.addresses || []).findBy('addressType', 'BUSINESS') || (customer.addresses || []).findBy('addressType', 'MAIN');
              if (address) {
                customer.businessAddress = [address.address1, address.city, address.state, address.postalCode].join(', ');
              } else {
                customer.businessAddress = '';
              }

              return customer;
            });
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'customers',
        displayKey: 'name',
        source: customersBloodhound,
        templates: {
          empty: '<div class="menu-header__search-no-results">' + this.i18n.t('application.no_customers') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class="menu-header__search-item-section-wrapper">\n                                <div class="menu-header__search-item-aside">' + model.icon + '</div>\n                                <div class="menu-header__search-item-section">\n                                  ' + model.name + ' <small>' + model.externalReference + '</small>\n                                  <div class="menu-header__search-item-small">' + model.businessAddress + '</div>\n                                </div>\n                              </div>';
          }
        }
      };
    }),

    isMinPage: Ember.computed.equal('page', 1),

    isMaxPage: Ember.computed('model', 'page', 'resultsPerPage', 'resultsFound', function () {
      return this.get('page') * this.get('resultsPerPage') >= this.get('resultsFound');
    }),

    pageStartIndex: Ember.computed('resultsPerPage', 'page', function () {
      return (this.get('page') - 1) * this.get('resultsPerPage') + 1;
    }),

    pageEndIndex: Ember.computed('resultsFound', 'page', 'resultsPerPage', function () {
      var maxForPage = this.get('page') * this.get('resultsPerPage');
      return maxForPage < this.get('resultsFound.length') ? maxForPage : this.get('resultsFound.length');
    }),

    maxPage: Ember.computed('resultsFound', 'page', 'resultsPerPage', function () {
      return Math.ceil(this.get('resultsFound') / this.get('resultsPerPage'));
    }),

    loading: false,
    exportLoading: false,

    changedReconciliationData: Ember.observer('model', function () {
      var _this2 = this;

      var entries = this.get('model');

      var headerCellHTML = function headerCellHTML(data) {
        return '<div class=\'reconciliation-list__header--cell ' + (data.notActionable === true ? 'reconciliation-list__header--not-actionable' : '') + ' ' + (data.isFirst === true ? 'reconciliation-list__header--cell__first' : '') + ' ' + (data.isSort === true ? 'reconciliation-list__header--cell__sorted' : '') + '\'>\n      ' + data.label + '\n      ' + (data.isSort === true && data.isSortAsc === true ? '<i class=\'fa fa-arrow-down\'/>' : '') + '\n      ' + (data.isSort === true && data.isSortAsc !== true ? '<i class=\'fa fa-arrow-up\'/>' : '') + '\n    </div>';
      };

      var tableCellHTML = function tableCellHTML(data) {
        return '<div class=\'reconciliation-list__cell ' + (data.isGeneric === true ? 'reconciliation-list__cell--generic' : '') + ' ' + (data.isFirst === true ? 'reconciliation-list__cell--first' : '') + '\'>\n      <div class=\'reconciliation-list__cell--value\'>' + data.value + '</div>\n    </div>';
      };

      var tableCellHTMLComment = function tableCellHTMLComment(data) {
        return '<div class=\'reconciliation-list__cell reconciliation-list__cell--value reconciliation-list__cell--value__comment\' data-comment=\'' + data.comment + '\' data-commentid=\'' + data.commentid + '\'>\n          <i class=\'fa fa-comment' + (data.comment ? '-o' : '') + '\'></i>\n          ' + (data.comment ? '<i class=\'fa fa-plus\' style=\'font-size: .8em;\'></i>' : '') + '\n        </div>';
      };

      var tableCellValueHTML = function tableCellValueHTML(data) {
        return '<div class=\'reconciliation-list__cell\'>\n      <div class=\'reconciliation-list__cell--value\' style=\'padding: 7px;\'>\n        ' + (data.value > 0 ? '<span class=\'reconciliation-list__cell-mismatch\'>' + data.value + '</span>' : '') + '\n        ' + (data.missing > 0 ? '<span class=\'reconciliation-list__cell--missing\'>' + data.missing + '</span>' : '') + '\n        ' + (data.value === 0 && data.missing === 0 ? '<span class=\'reconciliation-list__cell--matched\'>0</span>' : '') + '\n      </div>\n    </div>';
      };

      var createRow = function createRow(data) {
        var classNames = [];
        var values = {};
        var has7day = data.summaryDetail['SHORT_TERM'];
        var has8day = data.summaryDetail['LONG_TERM'];

        values['PROPERTY_CODE'] = {
          value: tableCellHTML({ value: data.customerCode, isFirst: true }),
          actionable: true
        };

        values['PROPERTY_NAME'] = {
          value: tableCellHTML({ value: data.name, isGeneric: true }),
          actionable: true
        };

        values['REVENUE'] = {
          value: tableCellHTML({ value: data.revenue ? 'Y' : 'N', isGeneric: true }),
          actionable: true
        };

        values['PMS_TYPE'] = {
          value: tableCellHTML({ value: data.pmsType, isGeneric: true }),
          actionable: true
        };

        values['SHORT_TERM_DAYS'] = {
          value: tableCellValueHTML({
            value: has7day && data.summaryDetail['SHORT_TERM'].daysMismatch ? data.summaryDetail['SHORT_TERM'].daysMismatch : 0,
            missing: has7day && data.summaryDetail['SHORT_TERM'].daysMissing ? data.summaryDetail['SHORT_TERM'].daysMissing : 0
          }),
          actionable: true
        };

        values['SHORT_TERM_ISSUES'] = {
          value: tableCellHTML({ value: _this2.i18n.t('customer.performance.data-monitoring.issues', { count: has7day && data.summaryDetail['SHORT_TERM'].totalCountIssue ? data.summaryDetail['SHORT_TERM'].totalCountIssue : 0 }) }),
          actionable: true
        };

        values['LONG_TERM_DAYS'] = {
          value: tableCellValueHTML({
            value: has8day && data.summaryDetail['LONG_TERM'].daysMismatch ? data.summaryDetail['LONG_TERM'].daysMismatch : 0,
            missing: has8day && data.summaryDetail['LONG_TERM'].daysMissing ? data.summaryDetail['LONG_TERM'].daysMissing : 0
          }),
          actionable: true
        };

        values['LONG_TERM_ISSUES'] = {
          value: tableCellHTML({ value: _this2.i18n.t('customer.performance.data-monitoring.issues', { count: has8day && data.summaryDetail['LONG_TERM'].totalCountIssue ? data.summaryDetail['LONG_TERM'].totalCountIssue : 0 }) }),
          actionable: true
        };

        values['DAY_TOTAL'] = {
          value: tableCellHTML({ value: (has7day && data.summaryDetail['SHORT_TERM'].totalDaysIssue ? data.summaryDetail['SHORT_TERM'].totalDaysIssue : 0) + (has8day && data.summaryDetail['LONG_TERM'].totalDaysIssue ? data.summaryDetail['LONG_TERM'].totalDaysIssue : 0) }),
          actionable: true
        };

        values['DATA_TOTAL'] = {
          value: tableCellHTML({ value: (has7day && data.summaryDetail['SHORT_TERM'].totalCountIssue ? data.summaryDetail['SHORT_TERM'].totalCountIssue : 0) + (has8day && data.summaryDetail['LONG_TERM'].totalCountIssue ? data.summaryDetail['LONG_TERM'].totalCountIssue : 0) }),
          actionable: true
        };

        values['COMMENT'] = {
          value: tableCellHTMLComment({
            comment: data.summaryComment ? data.summaryComment.comment : '',
            commentid: data.summaryComment ? data.summaryComment.commentID : ''
          })
        };

        var actionable = true;
        var id = data.customerID;

        return { label: '', classNames: classNames, values: values, actionable: actionable, id: id };
      };

      var createGroup = function createGroup(data) {
        var currentWeek = [];
        data.forEach(function (e) {
          var group = {
            labelAsCell: false,
            actionable: true,
            classNames: currentWeek.length > 0 ? ['hidden'] : ['active'],
            rows: []
          };

          var dataRow = createRow(e);
          group.rows.push(dataRow);
          currentWeek.push(group);
        });

        currentWeek.forEach(function (row, index) {
          if (index % 2 === 1) {
            currentWeek[index].rows.forEach(function (row) {
              return row.classNames.push('performance-inventory-row--alternate');
            });
          }
        });

        return currentWeek;
      };

      var columns = [];
      var sortField = this.get('sortField');
      var sortAscending = this.get('sortAscending');
      columns.push({
        key: 'PROPERTY_CODE',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.code'),
          isFirst: true,
          isSort: sortField === 'PROPERTY_CODE',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'PROPERTY_NAME',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.name'),
          isSort: sortField === 'PROPERTY_NAME',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'REVENUE',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.grow'),
          isSort: sortField === 'REVENUE',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'PMS_TYPE',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.pms'),
          isSort: sortField === 'PMS_TYPE',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'SHORT_TERM_DAYS',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.next', { count: 7 }),
          isSort: sortField === 'SHORT_TERM_DAYS',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'SHORT_TERM_ISSUES',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.next_data', { count: 7 }),
          isSort: sortField === 'SHORT_TERM_ISSUES',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'LONG_TERM_DAYS',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.plus_days', { count: 8 }),
          isSort: sortField === 'LONG_TERM_DAYS',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'LONG_TERM_ISSUES',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.plus_data', { count: 8 }),
          isSort: sortField === 'LONG_TERM_ISSUES',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'DAY_TOTAL',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.day_total'),
          isSort: sortField === 'DAY_TOTAL',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'DATA_TOTAL',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.data_total'),
          isSort: sortField === 'DATA_TOTAL',
          isSortAsc: sortAscending
        })
      });

      columns.push({
        key: 'COMMENT',
        label: headerCellHTML({
          label: this.i18n.t('customer.performance.data-monitoring.comment'),
          notActionable: true
        })
      });

      this.set('reconciliationTableData', {
        groups: createGroup(entries),
        columns: columns,
        actionable: true
      });

      Ember.run.later(function () {
        Ember.run.schedule('afterRender', function () {
          // override default table cell event action
          Ember.$('.reconciliation-list__cell--value__comment').off('click', _this2._commentClick);
          Ember.$('.reconciliation-list__cell--value__comment').on('click', _this2, _this2._commentClick);
        });
      }, 1000);
    }),

    _commentClick: function _commentClick(e) {
      var commentID = Ember.$(e.target).closest('.reconciliation-list__cell--value__comment').data('commentid');
      var comment = Ember.$(e.target).closest('.reconciliation-list__cell--value__comment').data('comment');
      e.data.send('addComment', commentID, comment);
      e.preventDefault();
      e.stopPropagation();
    },


    actions: {
      cellView: function cellView(context, columnKey, rowId) {
        this.transitionToRoute('data-monitoring.detail', rowId);
      },
      columnSort: function columnSort(columnKey) {
        if (columnKey !== 'COMMENT') {
          if (this.get('sortField') === columnKey) {
            this.toggleProperty('sortAscending');
          } else {
            this.set('sortAscending', true);
          }
          this.set('sortField', columnKey);
        }
      },
      next: function next() {
        var page = this.get('page') + 1;
        var resultsPerPage = this.get('resultsPerPage');
        var numberResults = this.get('resultsFound');

        if (page * resultsPerPage >= numberResults) {
          page = Math.ceil(numberResults / resultsPerPage);
        }

        this.set('page', page);
      },
      prev: function prev() {
        this.set('page', Math.max(1, this.get('page') - 1));
      },
      itemSelected: function itemSelected(item) {
        this.set('filterCustomerName', item.name);
        this.set('filterCustomerID', item.id);
        this.set('page', 1);
      },
      resetFilter: function resetFilter() {
        this.set('filterCustomerName', null);
        this.set('filterCustomerID', null);
      },
      clearSelection: function clearSelection() {
        if (!this.get('filterCustomerName')) {
          this.set('filterCustomerName', null);
        }
      },
      addComment: function addComment(commentID, comment) {
        var modalModel = Ember.Object.create({
          commentID: commentID,
          comment: comment
        });

        this.send('showModal', 'data-monitoring/-comment-modal', modalModel);
      },
      setFilter: function setFilter(filter) {
        this.set('shortTermFilter', filter);
        this.set('longTermFilter', filter);
      },
      exportData: function exportData() {
        var _this3 = this;

        this.set('exportLoading', true);
        var tz = this.get('tz.customer');
        var customerID = this.get('filterCustomerID') ? this.get('filterCustomerID') : this.get('customer.id');
        var beginDate = _moment.default.tz(tz).startOf('day');
        var filename = beginDate.format('YYYY-MM-DD') + '_data-monitoring.csv';

        var data = {
          beginDate: beginDate.format('YYYY-MM-DD'),
          days: 179,
          include: 'GENERAL_INVENTORY,RESERVATION_COUNT,OOO_INVENTORY,OVERBOOKING_INVENTORY,MANAGER_HOLD,SELLRATE,REVENUE'
        };

        if (this.get('shortTermFilter')) {
          data.shortTermFilter = this.get('shortTermFilter');
        }

        if (this.get('longTermFilter')) {
          data.longTermFilter = this.get('longTermFilter');
        }

        this.ajax.request('/rest/v1/dataMonitoring/summaryExport/' + customerID, {
          headers: { accept: 'text/csv' },
          dataType: 'blob',
          contentType: 'text/csv',
          data: data
        }).then(function (data) {
          _this3.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          _this3.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this3.i18n.t('customer.performance.download_error'), 'danger');
        }).finally(function () {
          _this3.set('exportLoading', false);
        });
      }
    }

  });
});