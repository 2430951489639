define('ux-pcc/helpers/ordinal-for', ['exports', 'ux-components/helpers/ordinal-for'], function (exports, _ordinalFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _ordinalFor.default;
    }
  });
  Object.defineProperty(exports, 'ordinalFor', {
    enumerable: true,
    get: function () {
      return _ordinalFor.ordinalFor;
    }
  });
});