define('ux-pcc/components/rules/thens/based-on-adjustment-then', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (!changeset.get('filterField')) {
        changeset.set('filterField', this.get('filterFields.firstObject.value'));
      }

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onRegisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        amount: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form_modal.no_value').toString()
        })
      };
    }),

    changeset: Ember.computed('rule', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);
    })
  });
});