define('ux-pcc/controllers/customer/sister-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    parentName: Ember.computed.readOnly("customer.name"),
    dialog: Ember.inject.service(),

    currencyCode: Ember.computed(function () {
      var model = (this.get("customer.settings") || []).findBy("settingCode", "DEFAULT_CURRENCY");
      if (model && model.get("settingValue")) {
        return model.get("settingValue");
      } else {
        return "USD";
      }
    }),

    isMobile: Ember.computed.bool('deviceType.mobile'),

    siblings: Ember.computed('customer.id', 'model.@each.customerId', function () {

      // Assemble the list of all currently related customer ids, to pass to the modal
      var siblings = [this.get("customer.id")];
      siblings.addObjects(this.get('model').mapBy('customerId'));

      return siblings;
    }),

    actions: {
      flash: function flash() {
        return true;
      },
      openAddProperty: function openAddProperty() {

        this.send('showModal', 'customer/-sister-property-modal', {
          isEdit: false,
          customerId: "",
          customer: null,
          adjustment: "",
          bar: this.get('currentBar'),
          excludedIds: this.get("siblings"),
          currencyCode: this.get('currencyCode')
        });
      },
      openEditProperty: function openEditProperty(property) {

        this.send('showModal', 'customer/-sister-property-modal', {
          isEdit: true,
          customerId: Ember.get(property, 'customerId'),
          customer: property,
          adjustment: Ember.get(property, 'modifier'),
          bar: this.get('currentBar'),
          currencyCode: this.get('currencyCode')
        });
      },
      confirmDelete: function confirmDelete(object) {
        var _this = this;

        this.set('propertyToRemove', object);

        this.get('dialog').showConfirmation({
          titleText: this.get('i18n').t('customer.sister-properties.message.remove_property'),
          messageText: this.get('i18n').t('customer.sister-properties.message.warning_message') + " " + this.get('i18n').t('customer.sister-properties.message.proceed'),
          confirmText: this.get('i18n').t('customer.sister-properties.message.continue'),
          cancelText: this.get('i18n').t('customer.sister-properties.message.cancel'),
          onConfirm: function onConfirm() {
            _this.send('processDeleteResponse');
          },
          onCancel: function onCancel() {
            _this.set('propertyToRemove', null);
          }
        });
      },
      processDeleteResponse: function processDeleteResponse() {
        var _this2 = this;

        var parent = this.get('customer');
        var child = this.get('propertyToRemove');

        if (child) {
          var propertyName = Ember.get(child, 'externalReference') + ' - ' + Ember.get(child, 'name');

          // Remove the record
          var promise = new Ember.RSVP.Promise(function (resolve, reject) {
            _this2.get('ajax').delete('/rest/priceBasedOn/' + parent.get('id') + '/' + Ember.get(child, 'customerId')).then(function (data) {
              resolve(data);
            }, function (xhr) {
              reject(xhr.statusText);
            });
          }, "PriceBasedOn: Remove PRICE_BASEDON record");

          promise.then(function () {
            _this2.set('propertyToRemove', null);
            _this2.send('refresh');
            _this2.send('flash', "<i class='fa fa-thumbs-up'></i> " + _this2.get('i18n').t('customer.sister-properties.message.success_delete_message', { propertyName: propertyName }), 'success');
          }).catch(function (error) {
            _this2.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
          });
        }
      }
    }

  });
});