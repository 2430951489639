define('ux-pcc/components/app-modal/modal-footer', ['exports', 'ux-components/components/app-modal/modal-footer'], function (exports, _modalFooter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modalFooter.default;
    }
  });
});