define('ux-pcc/components/rule-advanced/rule-filter-comparator', ['exports', 'ember-changeset', 'ember-changeset-validations'], function (exports, _emberChangeset, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('pickerOptions', {
        useCurrent: false
      });

      var changeset = this.get('changeset');

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        // filterField2: validatePresence({
        //   presence: true,
        //   message: this.i18n.t('rule._form_modal.no_value').toString()
        // })
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    isAdjustableComparison: Ember.computed('changeset.filterField2', 'comparators.@each.value', function () {
      var comparators = this.get('comparators');
      if (!comparators) {
        return false;
      }
      var comparator = comparators.findBy('value', this.get('changeset.filterField2'));
      return (comparator || {}).adjustable;
    }),

    isSelectableComparison: Ember.computed('changeset.filterField2', 'comparators.@each.value', function () {
      var comparators = this.get('comparators');
      if (!comparators) {
        return false;
      }
      var comparator = comparators.findBy('value', this.get('changeset.filterField2'));
      return (comparator || {}).selectable;
    }),

    isValueComparison: Ember.computed.not('changeset.filterField2'),

    valueRules: Ember.computed('type', 'changeset.filterField2', 'listOptions', 'isSelectableComparison', function () {
      var type = this.get('type');
      if (!type) {
        return [];
      }

      var options = [{ value: 'ADD', label: ' + ' }, { value: 'SUBTRACT', label: ' - ' }];

      if (type === 'numerical') {
        options = options.concat([{ value: 'PERCENT_ADD', label: ' + (%) ' }, { value: 'PERCENT_SUBTRACT', label: ' - (%) ' }]);
      }

      // For selectable comparisons
      if (this.get('isSelectableComparison')) {
        var superSubField = this.get('changeset.filterField2');
        options = this.get('listOptions').filterBy('type', superSubField);
      }

      return options;
    })

  });
});