define('ux-pcc/components/token-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.TextField.extend({
    options: {},

    validate: true,

    tokens: [],
    id: '',
    name: '',
    classNames: ['tokenfield'],
    placeholder: '',

    onUpdate: function onUpdate() {},

    tokensChanged: Ember.observer('tokens', function () {
      var element = this.get('element');
      // XXX Terrible, but works. Should set flag or something to prevent recursion
      var equality = JSON.stringify(Ember.$(element).tokenfield('getTokens')) === JSON.stringify(this.get('tokens'));
      if (!equality) {
        Ember.$(element).tokenfield('setTokens', this.get('tokens'));
      }
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var element = this.get('element');
      this._setupOptions();

      Ember.$(element).on('tokenfield:createdtoken', function () {
        var tokens = Ember.$(element).tokenfield('getTokens');
        _this.set('tokens', tokens);
        _this.get('onUpdate')(tokens);
      });

      Ember.$(element).on('tokenfield:removedtoken', function () {
        var tokens = Ember.$(element).tokenfield('getTokens');
        _this.set('tokens', tokens);
        _this.get('onUpdate')(tokens);
      });
    },


    optionsObserver: Ember.observer('options', function () {
      this._setupOptions();
    }),

    _setupOptions: function _setupOptions() {
      var element = this.get('element');
      var options = { tokens: this.get('tokens') };

      Ember.merge(options, this.get('options') || {});

      if (Ember.$(element).data('bs.tokenfield')) {

        Ember.$(element).tokenfield('setTokens', options.tokens);

        if (Ember.$(element).data('bs.tokenfield').$input.autocomplete) {
          var _Ember$$$data$$input;

          (_Ember$$$data$$input = Ember.$(element).data('bs.tokenfield').$input).autocomplete.apply(_Ember$$$data$$input, _toConsumableArray(options.typeahead));
        } else if (options.typeahead) {
          var _Ember$$$data$$input2;

          // if the typeahead hasn't been initialized, do it now
          (_Ember$$$data$$input2 = Ember.$(element).data('bs.tokenfield').$input).typeahead.apply(_Ember$$$data$$input2, _toConsumableArray(options.typeahead));
        }
      } else {
        // initialize tokenfield
        Ember.$(element).tokenfield(options);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var element = this.get('element');

      Ember.$(element).off('tokenfield:createdtoken');
      Ember.$(element).off('tokenfield:removedtoken');
      Ember.$(element).tokenfield('destroy');
    }
  });
});