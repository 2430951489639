define('ux-pcc/controllers/pacing/intraday', ['exports', 'moment', 'lodash', 'ux-pcc/mixins/customer/performance/span-selectable'], function (exports, _moment, _lodash, _spanSelectable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_spanSelectable.default, {
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    queryParams: ['endDate'],

    timespan: 7,

    fixedColumn: Ember.computed.not('deviceType.mobile'),
    isMobile: false,
    isDesktop: false,
    isNonMobile: Ember.computed.not('isMobile'),
    isNonDesktop: Ember.computed.not('isDesktop'),
    isTablet: Ember.computed.and('isNonMobile', 'isNonDesktop'),
    endDate: null,

    datePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        maxDate: (0, _moment.default)()
      };
    }),

    columns: Ember.computed(function () {
      //plannedEnhancement: pull columns from a setting
      return [{
        key: 'DOA',
        label: this.i18n.t('pacing.intra_day.table.otb')
      }, {
        key: 'DOA, 4wks',
        label: this.i18n.t('pacing.intra_day.table.otb')
      }, {
        key: 'Midnight-6a',
        label: this.i18n.t('pacing.intra_day.table.12a')
      }, {
        key: 'Midnight-6a, 4wks',
        label: this.i18n.t('pacing.intra_day.table.12a')
      }, {
        key: '6a-9a',
        label: this.i18n.t('pacing.intra_day.table.6a')
      }, {
        key: '6a-9a, 4wks',
        label: this.i18n.t('pacing.intra_day.table.6a')
      }, {
        key: '9a-Noon',
        label: this.i18n.t('pacing.intra_day.table.9a')
      }, {
        key: '9a-Noon, 4wks',
        label: this.i18n.t('pacing.intra_day.table.9a')
      }, {
        key: 'Noon-3p',
        label: this.i18n.t('pacing.intra_day.table.12p')
      }, {
        key: 'Noon-3p, 4wks',
        label: this.i18n.t('pacing.intra_day.table.12p')
      }, {
        key: '3p-6p',
        label: this.i18n.t('pacing.intra_day.table.3p')
      }, {
        key: '3p-6p, 4wks',
        label: this.i18n.t('pacing.intra_day.table.3p')
      }, {
        key: '6p-9p',
        label: this.i18n.t('pacing.intra_day.table.6p')
      }, {
        key: '6p-9p, 4wks',
        label: this.i18n.t('pacing.intra_day.table.6p')
      }, {
        key: '9p-Midnight',
        label: this.i18n.t('pacing.intra_day.table.9p')
      }, {
        key: '9p-Midnight, 4wks',
        label: this.i18n.t('pacing.intra_day.table.9p')
      }];
    }),

    changedPacingData: Ember.observer('model', function () {
      var _this = this;

      this.set('timespan', this.get('deviceType.mobile') ? 1 : 7);
      var entries = this.get('model');
      var tz = this.tz.get('customer');

      var headerCellHTML = (0, _lodash.template)('<div class="performance-inventory-header">\n          <div class="">\n            <div class="performance-inventory-cell__value">\n              <%= (data.isDOA===true) ? \'<div>\'+ data.label+\'</div><div>\'+data.avgLabel+\'</div>\' : \'<div></div><div class=""> \'+data.label+\'</div>\'%>\n            </div>\n          </div>\n        </div>', { 'variable': 'data' });

      var headerAverageCellHTML = (0, _lodash.template)('<div class="performance-inventory-header">\n          <div class="">\n            <div class="performance-inventory-cell__value">\n              <div><%= data.label %></div>\n              <%= (data.avg===false) ? \'<div class="intraday-pacing__hidden">\'+ data.avgLabel+\'</div>\' : \'<div class=""> \'+data.avgLabel+\'</div>\'%>\n            </div>\n          </div>\n        </div>', { 'variable': 'data' });

      var tableCellHTML = (0, _lodash.template)('<div class="">\n          <div class="performance-inventory-cell__value">\n            <div class="pacing-table__header"><span class="<%= data.classNames %>"><%= (data.value) ? data.value : \'&mdash;\' %></span></div>\n          </div>\n        </div>', { 'variable': 'data' });

      var mobileLabelHTML = (0, _lodash.template)('<div class=""><%= data.label %></div><div class="intraday-pacing__sublabel"><%= data.sublabel %></div>\n          ', { 'variable': 'data' });

      var getClassName = function getClassName(value) {
        if (!isNaN(value) && value < 0) {
          return 'negative';
        }
      };

      var createRow = function createRow(label, classNames) {
        var values = {};
        _this.get('columns').forEach(function (m) {
          values[m.key] = { value: tableCellHTML({}) };
        });
        return { label: label, classNames: classNames, values: values };
      };

      var createMobileRow = function createMobileRow(label, dateValue) {
        var classNames = [];
        var values = {};

        values[_moment.default.tz(dateValue, tz).format('ddd')] = { value: tableCellHTML({}) };
        values[_this.i18n.t('pacing.day_var', { dow: _moment.default.tz(dateValue, tz).format('ddd') }).toString()] = { value: tableCellHTML({}) };

        return { label: label, classNames: classNames, values: values };
      };

      var createGroup = function createGroup(data) {

        var groups = [];
        var today = _moment.default.tz(tz).startOf('day');

        data.forEach(function (e, index) {

          var groupByValue = e.Date;
          var classnames = [];

          var date = _moment.default.tz(groupByValue, tz).startOf('day');
          if (date.valueOf() === today.valueOf()) {
            classnames.push('active');
          }

          var formattedDate = _moment.default.tz(groupByValue, tz).format('L');
          formattedDate = formattedDate.substring(0, formattedDate.length - 4) + formattedDate.substring(formattedDate.length - 2);

          var group = {
            label: formattedDate,
            labelAsCell: !_this.get('deviceType.mobile'),
            classNames: [index === 0 ? 'active' : 'hidden'],
            rows: []
          };
          // Current year row
          var dataRow = createRow(_moment.default.tz(groupByValue, tz).format('ddd'), classnames);
          // Variation row
          var varRow = createRow(_this.i18n.t('pacing.stly_var'), classnames);

          _this.get('columns').forEach(function (m) {
            // Current year data
            var current = e.Current.findBy('key', m.key) || {};
            var object = { value: current.amount, classNames: getClassName(current.amount) };
            dataRow.values[m.key] = tableCellHTML(object);
            // Variation data
            var variation = e.Variance.findBy('key', m.key) || {};
            var varObject = { value: variation.amount, classNames: getClassName(variation.amount) };
            varRow.values[m.key] = tableCellHTML(varObject);
          });

          group.rows.push(dataRow);
          group.rows.push(varRow);

          groups.push(group);
        });

        return groups;
      };

      var createMobileGroup = function createMobileGroup(columns, entries) {

        var groups = [];
        var group = {
          label: '',
          labelAsCell: false,
          classNames: ['active'],
          rows: []
        };
        columns.forEach(function (e) {
          var dataRow = createMobileRow('', entries.Date);
          var object = entries.Current && entries.Current.findBy('key', e.key) ? { value: entries.Current.findBy('key', e.key).amount, classNames: getClassName(entries.Current.findBy('key', e.key).amount) } : null;
          var varObject = entries.Variance && entries.Variance.findBy('key', e.key) ? { value: entries.Variance.findBy('key', e.key).amount, classNames: getClassName(entries.Variance.findBy('key', e.key).amount) } : null;
          var isAvg = e.key.indexOf('4wks') > 0;
          var isDOA = e.key.indexOf('DOA') > 0;
          if (!isAvg || isDOA && isAvg) {
            var width = Ember.$(window).width();
            if (width < 400) {
              if (isDOA && isAvg) {
                dataRow.label = mobileLabelHTML({
                  label: e.label,
                  sublabel: _this.i18n.t('pacing.intra_day.table.4wks')
                });
              } else if (isDOA && !isAvg) {
                dataRow.label = mobileLabelHTML({
                  label: e.label,
                  sublabel: _this.i18n.t('pacing.intra_day.table.pickup')
                });
              } else {
                dataRow.label = e.label;
              }
            } else {
              if (isDOA && isAvg) {
                dataRow.label = e.label + ', ' + _this.i18n.t('pacing.intra_day.table.4wks');
              } else if (isDOA && !isAvg) {
                dataRow.label = e.label + ' ' + _this.i18n.t('pacing.intra_day.table.pickup');
              } else {
                dataRow.label = e.label;
              }
            }
            dataRow.values[_moment.default.tz(entries.Date, tz).format('ddd')] = tableCellHTML(object);
            dataRow.values[_this.i18n.t('pacing.stly_var').toString()] = tableCellHTML(varObject);
            group.rows.push(dataRow);
          }
        });
        groups.push(group);
        return groups;
      };

      // Build table based on mobile/desktop
      if (this.get('isMobile')) {
        var mobileColumns = [];
        var entry = entries.get('firstObject');
        // Build columns - Current and Var
        mobileColumns.push({ key: _moment.default.tz(entry.Date, tz).format('ddd'), label: headerCellHTML({ label: _moment.default.tz(entry.Date, tz).format('ddd'), avg: void 0, avgLabel: '' }) });
        mobileColumns.push({ key: this.i18n.t('pacing.stly_var').toString(), label: headerCellHTML({ label: this.i18n.t('pacing.stly_var'), avg: void 0, avgLabel: '' }) });

        this.set('pacingTableData', {
          groups: createMobileGroup(this.get('columns'), entry),
          columns: mobileColumns
        });
      } else {
        // Build columns
        var columns = [];
        this.get('columns').forEach(function (m) {
          var isAvg = m.key.indexOf('4wks') > 0;
          var isDOA = m.key === 'DOA';
          var column = void 0;
          if (isAvg) {
            column = {
              key: m.key,
              label: headerAverageCellHTML({
                label: m.label,
                avg: isAvg,
                avgLabel: _this.i18n.t('pacing.intra_day.table.4wks'),
                isDOA: false
              })
            };
          } else {
            column = {
              key: m.key,
              label: headerCellHTML({
                label: m.label,
                avg: isAvg,
                avgLabel: isDOA ? _this.i18n.t('pacing.intra_day.table.pickup') : _this.i18n.t('pacing.intra_day.table.4wks'),
                isDOA: isDOA
              })
            };
          }
          columns.push(column);
        });

        this.set('pacingTableData', {
          groups: createGroup(entries),
          columns: columns
        });
      }
    })
  });
});