define('ux-pcc/components/yield-manager/views/base-view', ['exports', 'moment', 'ux-components/utils/format-currency', 'ux-components/utils/to-boolean', 'ux-pcc/helpers/wrap-negative'], function (exports, _moment, _formatCurrency, _toBoolean, _wrapNegative) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['view-screen'],
    loading: false,
    tz: null,
    rollupStatus: 'DETAIL',
    model: null,

    dataFields: null,
    stlyColumns: null,
    percentDeltaColumns: null,
    actualDeltaColumns: null,
    roaDeltaColumns: null,
    leadColumnTitle: 'Date',

    sliceResolution: 'week',

    highlightedRowIndex: -1,

    highlightedRowClass: 'view-screen__table-content--row-highlight',
    showComments: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._dataObserver();
      this._setTmVsGrossParamValue();
    },


    _dataObserver: Ember.observer('customerID', 'model.view', 'aggregateVersion', function () {
      if (this.get('customerID')) {
        this.set('loading', true);
        this.set('highlightedRowIndex', -1);
        Ember.run.once(this, '_fetchData');
      }
    }),

    chartDataFields: Ember.computed('dataFields.[]', function () {
      var dataFields = this.get('dataFields');
      var chartData = [];

      dataFields.forEach(function (chartDataItem) {
        chartData = chartData.concat(chartDataItem.values);
      });
      return chartData;
    }),

    currentColumns: Ember.computed('dataFields', function () {
      var columns = [{ key: 'dow', label: 'Day' }];
      return columns.concat(this.get('dataFields'));
    }),

    chartDataProvider: Ember.computed('data', function () {
      var tz = this.get('tz');
      var mapped = this.get('data');
      if (!mapped) {
        return null;
      }

      var dateSort = function dateSort(o1, o2) {
        var date1 = _moment.default.tz(o1, tz);
        var date2 = _moment.default.tz(o2, tz);

        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
      };

      return Object.keys(mapped).sort(dateSort).map(function (key) {
        return mapped[key];
      });
    }),

    formatField: function formatField(format, value, currencyHash) {
      if (format === 'currency') {
        return (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(value, this.get('currencyCode'), { hash: currencyHash }));
      } else if (format === 'percent') {
        return (0, _wrapNegative.wrapNegative)(value, { precision: 1 /*, suffix: '%'*/ });
      } else if (format === 'truncatedNumber') {
        return (0, _wrapNegative.wrapNegative)(value, { precision: 2 });
      } else if (format === 'number') {
        return (0, _wrapNegative.wrapNegative)(value, { precision: 0 });
      } else {
        return (0, _wrapNegative.wrapNegative)(value);
      }
    },


    currencyCode: Ember.computed('tableData.@each.currencyCode', function () {
      var tableData = this.get('tableData');
      return tableData ? tableData.get('firstObject.values.currencyCode') : 'USD';
    }),

    formatRevenueOptions: Ember.computed('currencyCode', 'revenuePrecision', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: this.get('revenuePrecision'),
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatYieldOptions: Ember.computed('currencyCode', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: 2,
        decimal: '.',
        thousand: ',',
        hideSymbol: true
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    generateCellClassNames: function generateCellClassNames(cellValue) {
      var classList = [];
      if (cellValue < 0.00) {
        classList.push('negative');
      }

      return classList;
    },
    _formatData: function _formatData(data, columns) {
      var _this = this;

      // Loop over each row, and format the fields using the format from the column definition
      data.forEach(function (row) {
        columns.forEach(function (col) {
          // skip if already set to an object value
          if (_typeof(row.values[col.key]) !== 'object') {
            var value = row.values[col.key] ? row.values[col.key] : 0;
            Ember.set(row.values, col.key, {
              value: _this.formatField(col.format, value, col.formatHash),
              classNames: _this.generateCellClassNames(value)
            });
          }
        });

        if (row.children) {
          _this._formatData(row.children, columns);
        }
      });
    },
    _populateTableData: function _populateTableData(columnProperty, dataProperty, leadColumnSpan) {
      var columns = this.get(columnProperty);
      var groups = Ember.A();
      var data = this.get(dataProperty);

      if (data) {
        this._formatData(data, columns);
      }

      groups.push({
        label: this.get('leadColumnTitle'),
        rows: data,
        hasGroupedColumnHeaders: columns.filterBy('groupColumnHeaderLabel').length > 0,
        colspan: leadColumnSpan
      });

      return {
        groups: groups,
        columns: columns
      };
    },


    stlyTableData: Ember.computed('tableData', 'stlyData', 'stlyColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('stlyColumns', 'stlyData');
      }
    }),

    currentTableData: Ember.computed('tableData', 'currentColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('currentColumns', 'tableData');
      }
    }),

    percentDeltaTableData: Ember.computed('tableData', 'percentDeltaColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('percentDeltaColumns', 'tableData');
      }
    }),

    actualDeltaTableData: Ember.computed('tableData', 'actualDeltaColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('actualDeltaColumns', 'tableData');
      }
    }),

    roaDeltaTableData: Ember.computed('tableData', 'roaDeltaColumns', 'loading', function () {
      if (!this.get('loading')) {
        return this._populateTableData('roaDeltaColumns', 'tableData');
      }
    }),

    selectedDateParam: Ember.computed.alias('model.view.dashboardViewParameters.BEGIN_DATE'),

    selectedEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.END_DATE'),

    selectedStlyDateParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_BEGIN_DATE'),

    selectedStlyEndDateParam: Ember.computed.alias('model.view.dashboardViewParameters.STLY_END_DATE'),

    tmVsGrossParam: Ember.computed.alias('model.view.dashboardViewParameters.TM_VS_GROSS'),

    tmVsGrossParamValue: null,

    _setTmVsGrossParamValue: function _setTmVsGrossParamValue() {
      if (this.get('tmVsGrossParam') !== this.get('tmVsGrossParamValue')) {
        this.set('tmVsGrossParamValue', this.get('tmVsGrossParam'));
      }
    },


    _tmVsGrossParamValueObserver: Ember.observer('tmVsGrossParam', function () {
      this._setTmVsGrossParamValue();
    }),

    isGrossValue: Ember.computed('tmVsGrossParamValue', function () {
      return this.get('tmVsGrossParamValue') === 'GROSS';
    }),

    sourceLocationCodeParam: Ember.computed.alias('model.view.dashboardViewParameters.SOURCE_LOCATION_CODE'),

    rateOfAddRanges: Ember.computed(function () {
      return [{ value: '-1', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 1 }) }, { value: '-2', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 2 }) }, { value: '-3', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 3 }) }, { value: '-4', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 4 }) }, { value: '-5', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 5 }) }, { value: '-6', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 6 }) }, { value: '-7', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 7 }) }, { value: '-14', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 14 }) }, { value: '-21', description: this.get('i18n').t('dashboard-views.form.last_days', { count: 21 }) }];
    }),

    rateOfAddParam: Ember.computed.alias('model.view.dashboardViewParameters.RATE_OF_ADD'),

    rateOfAddDescription: Ember.computed('rateOfAddRanges', 'rateOfAddParam', function () {
      var rateOfAdd = this.get('rateOfAddRanges').findBy('value', '' + this.get('rateOfAddParam'));
      if (!Ember.isEmpty(rateOfAdd)) {
        return Ember.get(rateOfAdd, 'description');
      }

      return '';
    }),

    rateOfAddTypeParam: Ember.computed.alias('model.view.dashboardViewParameters.RATE_OF_ADD_TYPE'),

    rateOfAddTypeValues: Ember.computed(function () {
      return [{ value: 'ROA', description: this.get('i18n').t('dashboard-views.form.rate_of_add') }, { value: 'SNAPSHOT', description: this.get('i18n').t('dashboard-views.form.snapshot') }];
    }),

    rateOfAddTypeDescription: Ember.computed('rateOfAddTypeValues', 'rateOfAddTypeParam', function () {
      var rateOfAddType = this.get('rateOfAddTypeValues').findBy('value', '' + this.get('rateOfAddTypeParam'));
      if (!Ember.isEmpty(rateOfAddType)) {
        return Ember.get(rateOfAddType, 'description');
      }

      return '';
    }),

    displaySnapshot: Ember.computed.equal('rateOfAddTypeParam', 'SNAPSHOT'),

    dataViewParam: Ember.computed.alias('model.view.dashboardViewParameters.DATA_VIEW'),

    displayPercentages: Ember.computed.equal('dataViewParam', 'PERCENT'),

    actions: {
      editView: function editView() {
        this.get('editView')(this.get('model.view'));
      },
      viewInfo: function viewInfo() {
        this.get('viewInfo')(this.get('model.view'));
      },
      rowMouseIn: function rowMouseIn(index) {
        this.set('highlightedRowIndex', index);
      },
      tableMouseOut: function tableMouseOut() {
        this.set('highlightedRowIndex', -1);
      }
    }
  });
});