define('ux-pcc/components/multimedia-resources/linked-image-chooser', ['exports', 'ux-components/components/multimedia-resources/linked-image-chooser'], function (exports, _linkedImageChooser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkedImageChooser.default;
    }
  });
});