define('ux-pcc/components/customers/attraction-row', ['exports', 'ux-components/components/customers/attraction-row'], function (exports, _attractionRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _attractionRow.default;
    }
  });
});