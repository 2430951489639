define('ux-pcc/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel: function beforeModel() /* transition */{
      this._super.apply(this, arguments);

      // redirect user to customer.index if authenticated
      if (this.session.isAuthenticated) {
        this.transitionTo('customer', this.session.get('data.authenticated.customerID'));
      } else {
        this.transitionTo('login');
      }
    }
  });
});