define('ux-pcc/components/info-icon', ['exports', 'ux-components/components/info-icon'], function (exports, _infoIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _infoIcon.default;
    }
  });
});