define('ux-pcc/routes/portfolios/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');
      var userCustomer = this.get('session.data.authenticated.customerID');

      var users = this.store.query('customer', { customerID: customer.get('id') });
      var customerUsers = this.store.query('user', { customerID: userCustomer });

      var promises = {
        users: Ember.RSVP.all([users, customerUsers]).then(function (results) {
          var users = Ember.A();
          users.addObjects(results[0]);

          if (customer.get('id') !== userCustomer) {
            users.addObjects(results[1]);
          }

          return users;
        }),
        portfolio: this.store.createRecord('customer', {
          folderYN: true,
          customerType: "CUSTOMERS",
          parent: customer,
          affiliateCustomer: customer,
          status: "OK"
        })
      };

      return Ember.RSVP.hash(promises);
    },
    setupController: function setupController(controller, model) {
      // this._super(controller, model);
      this._super(controller, model.portfolio);
      controller.set('users', model.users);
      controller.set('managers', "");
    },


    actions: {
      refresh: function refresh() {
        return true;
      }
    }
  });
});