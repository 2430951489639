define('ux-pcc/serializers/reservation', ['exports', 'ux-data/serializers/reservation'], function (exports, _reservation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservation.default;
    }
  });
});