define('ux-pcc/initializers/timezone', ['exports', 'ux-data/initializers/timezone'], function (exports, _timezone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timezone.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _timezone.initialize;
    }
  });
});