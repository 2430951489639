define('ux-pcc/helpers/numeric-duration', ['exports', 'ember-hifi/helpers/numeric-duration'], function (exports, _numericDuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _numericDuration.default;
    }
  });
  Object.defineProperty(exports, 'numericDuration', {
    enumerable: true,
    get: function () {
      return _numericDuration.numericDuration;
    }
  });
});