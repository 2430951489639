define('ux-pcc/components/dashboard-widget/build-revenue', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['build-revenue'],

    data: null,

    addPY: true,
    addCY: true,
    cumPY: true,
    cumCY: true,

    showRevVarActuals: false,
    showLwRoaActuals: false,
    showTwRoaActuals: false,
    showYstRoaActuals: false,
    showPercentBookedActuals: false,
    showComments: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', []);
    },


    chartData: Ember.computed('data', function () {
      return (this.get('data') || []).rejectBy('subContentType', 'FULL_SPAN');
    }),

    fullSpan: Ember.computed('data', function () {
      return (this.get('data') || []).findBy('subContentType', 'FULL_SPAN');
    }),

    thisWeek: Ember.computed('chartData', 'selectedDate', function () {
      var today = _moment.default.tz(this.get('tz.customer'));
      return this.get('chartData').find(function (ae) {
        return today.isSame(ae.entryDate, 'week');
      });
    }),

    rev_var: Ember.computed.readOnly('fullSpan.dataValues.pctChangeBase'),
    cy_rev: Ember.computed.readOnly('fullSpan.dataValues.base'),
    tw_roa: Ember.computed.readOnly('fullSpan.dataValues.twRoaBase'),
    lw_roa: Ember.computed.readOnly('fullSpan.dataValues.lwRoaBase'),
    yst_roa: Ember.computed.readOnly('fullSpan.dataValues.ystBaseRoa'),
    pct_booked: Ember.computed.readOnly('fullSpan.dataValues.pctBookedRevenue'),

    rev_actual_cy: Ember.computed.readOnly('fullSpan.dataValues.base'),
    rev_actual_py: Ember.computed.readOnly('fullSpan.dataValues.stlyBase'),
    rev_actual_yst_cy: Ember.computed.readOnly('fullSpan.dataValues.ystBaseAdded'),
    rev_actual_yst_py: Ember.computed.readOnly('fullSpan.dataValues.ystStlyBaseAdded'),
    rev_actual_tw_cy: Ember.computed.readOnly('fullSpan.dataValues.twIncreaseBase'),
    rev_actual_tw_py: Ember.computed.readOnly('fullSpan.dataValues.twStlyIncreaseBase'),
    rev_actual_lw_cy: Ember.computed.readOnly('fullSpan.dataValues.lwIncreaseBase'),
    rev_actual_lw_py: Ember.computed.readOnly('fullSpan.dataValues.lwStlyIncreaseBase'),
    rev_actual_booked_pytd: Ember.computed.readOnly('fullSpan.dataValues.eodYesterdayStlyBase'),
    rev_actual_booked_py: Ember.computed.readOnly('fullSpan.dataValues.stlyBookedTotalBase'),

    currencyCode: Ember.computed.readOnly('chartData.lastObject.currencyCode'),

    mappedData: Ember.computed('data', function () {
      var _this = this;

      var thisWeek = this.get('thisWeek');
      return this.get('chartData').map(function (ae) {
        var record = {
          slice: _moment.default.tz(ae.dataValues['endDate'], _this.get('tz.customer')),
          addedPriorYear: Number(ae.dataValues['stlyIncreaseBase']),
          addedCurrentYear: Number(ae.dataValues['increaseBase']),
          cumPriorYear: Number(ae.dataValues['stlyBase']),
          cumCurrentYear: Number(ae.dataValues['base']),
          cumPctVariance: Number(ae.dataValues['pctChangeBase']),
          addPctVariance: Number(ae.dataValues['twRoaBase'])
        };

        if (thisWeek && thisWeek.entryDate && ae.entryDate && ae.entryDate === thisWeek.entryDate) {
          record['cumPriorYear'] = Number(_this.get('rev_actual_py'));
          record['cumPriorYearToDate'] = Number(ae.dataValues['stlyBase']);
          record['addPriorYearToDate'] = Number(ae.dataValues['eodYesterdayStlyIncreaseBase']);
          record['cumPctVariance'] = Number(_this.get('rev_var'));
          record['addPctVariance'] = Number(_this.get('tw_roa'));
        }

        return record;
      });
    }),

    toggleActuals: function toggleActuals(value) {
      this.toggleProperty('show' + value + 'Actuals');
    }
  });
});