define('ux-pcc/models/analytic-entry', ['exports', 'ux-data/models/analytic-entry'], function (exports, _analyticEntry) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _analyticEntry.default;
    }
  });
});