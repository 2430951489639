define('ux-pcc/components/yield-manager/location-panel', ['exports', 'ux-pcc/helpers/array-contains', 'ux-pcc/helpers/capitalize', 'ux-pcc/helpers/toggle-list-value', 'ux-pcc/utils/stringify-multiselect', 'ux-components/utils/to-boolean'], function (exports, _arrayContains2, _capitalize2, _toggleListValue, _stringifyMultiselect, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['parameter-panel', 'location-panel'],

    onParamUpdates: function onParamUpdates() {},

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    agreements: null,
    products: null,
    subProducts: null,
    reservationCategories: null,
    statuses: null,
    lengthOfRents: null,

    typeaheadOptions: null,

    init: function init() {
      if (!this || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      this._super.apply(this, arguments);
      this.set('agreements', []);
      this.set('products', []);
      this.set('subProducts', []);
      this.set('typeaheadOptions', { highlight: true, autoselect: true });
      this._setChannels();
      this._setCountries();
      this._initProducts();
      this._setPrices();
      this._setStatuses();
      this._setLengthOfRents();
      this._setSubChannels();
      this._setReservationCategories();
    },
    _setChannels: function _setChannels() {
      var _this = this;

      this.get('store').query('code', {
        customerID: this.get('customer.id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_CHANNEL'
      }).then(function (results) {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('channels', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('shortDescription'));
        }
      });
    },


    showSubchannels: Ember.computed('customer.{id,settings.[]}', function () {
      var settings = this.get('customer.settings');
      var showSubchannelSetting = settings.findBy('settingCode', 'PCC.ADVANCED_ANALYTICS_ENABLE_SUBCHANNELS');
      return showSubchannelSetting && showSubchannelSetting.get('settingValue') ? (0, _toBoolean.default)(showSubchannelSetting.get('settingValue')) : false;
    }),

    showProducts: Ember.computed('customer.{id,settings.[]}', function () {
      var settings = this.get('customer.settings');
      var showProductSetting = settings.findBy('settingCode', 'PCC.ADVANCED_ANALYTICS_FILTER_ENABLE_PRODUCTS');
      return showProductSetting && showProductSetting.get('settingValue') ? (0, _toBoolean.default)(showProductSetting.get('settingValue')) : false;
    }),

    _rootChannelObserver: Ember.observer('customer.id', function () {
      Ember.run.once(this, '_setChannels');
    }),

    _setSubChannels: function _setSubChannels() {
      var _this2 = this;

      if (this.get('channelParam') && !this.get('hideSubChannels')) {
        return this.get('store').query('channel', {
          customerID: this.get('customer.id'),
          parentChannelCode: this.get('channelParam'),
          reload: true
        }).then(function (channels) {
          if (!(_this2.get('isDestroyed') || _this2.get('isDestroying'))) {
            _this2.set('subChannels', channels.sortBy('shortDescription'));
          }
        });
      } else {
        if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
          this.set('subChannels', []);
        }
      }
    },


    _channelParamObserver: Ember.observer('channelParam', function () {
      this._setSubChannels();
    }),

    hideSubChannels: Ember.computed('channelParam', 'showSubchannels', function () {
      var channels = this.get('channelParam') ? this.get('channelParam').split('|') : [];
      return this.get('showSubchannels') ? !this.get('channelParam') || channels.length > 1 : true;
    }),

    reservationCategoryParam: Ember.computed.alias('dashboardViewParameters.RETURN_TYPE'),

    lengthOfRentParam: Ember.computed.alias('dashboardViewParameters.LENGTH_OF_RENT'),

    sourceLocationCodeParam: Ember.computed.alias('dashboardViewParameters.SOURCE_LOCATION_CODE'),

    priceCodeParam: Ember.computed.alias('dashboardViewParameters.PRICE_CODE'),

    productCodeParam: Ember.computed.alias('dashboardViewParameters.PRODUCT_CODE'),

    subProductCodeParam: Ember.computed.alias('dashboardViewParameters.SUB_PRODUCT_CODE'),

    channelParam: Ember.computed.alias('dashboardViewParameters.CHANNEL'),

    subChannelParam: Ember.computed.alias('dashboardViewParameters.SUB_CHANNEL'),

    bookSrcCountryParam: Ember.computed.alias('dashboardViewParameters.BOOK_SRC_COUNTRY'),

    bookSrcAccountParam: Ember.computed.alias('dashboardViewParameters.BOOK_SRC_ACCOUNT'),

    statusParam: Ember.computed.alias('dashboardViewParameters.DESTINATION_STATUS'),

    productBlueprintCountParam: Ember.computed.alias('dashboardViewParameters.PRODUCT_BLUEPRINT_COUNT'),

    //if channel has changed, remove existing subChannels
    channelParamValueObserver: Ember.observer('channelParam', function () {
      var subChannelParam = this.get('subChannelParam');
      if (subChannelParam) {
        this.set('subChannelParam', '');
      }
    }),

    _setPrices: function _setPrices() {
      var _this3 = this;

      this.get('store').query('code', { customerID: this.get('customer.id'), codeType: 'RENTAL_TYPE' }).then(function (prices) {
        if (!(_this3.get('isDestroyed') || _this3.get('isDestroying'))) {
          _this3.set('prices', prices.sortBy('orderBy'));
        }
      });
    },
    _setCountries: function _setCountries() {
      var _this4 = this;

      if (this.get('customer.id')) {
        this.get('store').query('country', { customerID: this.get('customer.id') }).then(function (countries) {
          if (!(_this4.get('isDestroyed') || _this4.get('isDestroying'))) {
            _this4.set('countries', countries.sortBy('shortDescription'));
          }
        });
      }
    },


    _customerIdObserver: Ember.observer('customer.id', function () {
      this._setPrices();
      this._setCountries();
    }),

    levelHierarchyParam: Ember.computed.alias('dashboardViewParameters.LEVEL_HIERARCHY'),

    levelParam: Ember.computed.alias('dashboardViewParameters.LEVEL'),

    dataTypeParam: Ember.computed.alias('dashboardViewParameters.DATA_TYPE'),

    isYSGA: Ember.computed.equal('dataTypeParam', 'YSGA'),

    areTicketsSelected: Ember.computed.equal('sourceLocationCodeParam', 'TICKET'),

    _initProducts: function _initProducts() {
      var _this5 = this;

      var selectedLevels = this.get('levelParam');

      if (selectedLevels) {
        this.get('ajax').request('/rest/v1/dashboardViews/products', { data: {
            customers: selectedLevels,
            fields: 'id,shortDescription,externalReference,customerID'
          } }).then(function (_ref) {
          var lists = _ref.lists;


          // Check the uniqueness of the customer ids coming back, as they are the blueprints
          // Only proceed if using a single blueprint
          var customerIds = (lists || []).mapBy('customerID').uniq();
          if (customerIds.length === 1) {

            _this5.get('store').query('product', { ids: lists.map(function (product) {
                return product.id;
              }), include: 'children,children.children', reload: true }).then(function (products) {
              if (!(_this5.get('isDestroyed') || _this5.get('isDestroying'))) {
                _this5.set('products', products.sortBy('shortDescription'));
              }
            });
          } else {
            if (!(_this5.get('isDestroyed') || _this5.get('isDestroying'))) {
              // Clear products list, and the product parameter
              _this5.set('productCodeParam', '');
              _this5.set('products', []);
            }
          }
          if (!(_this5.get('isDestroyed') || _this5.get('isDestroying'))) {
            _this5.set('productBlueprintCountParam', customerIds.length);
          }
        });
      } else {
        if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
          // Clear products list, and the product parameter
          this.set('productCodeParam', '');
          this.set('products', []);
          this.set('productBlueprintCountParam', 0);
        }
      }
    },


    _levelParamObserver: Ember.observer('levelParam', function () {
      this._initProducts();
    }),

    hideSubProducts: Ember.computed('productCodeParam', function () {
      var products = this.get('productCodeParam') ? this.get('productCodeParam').split('|') : [];
      return !this.get('productCodeParam') || products.length < 1;
    }),

    _setSubProducts: function _setSubProducts() {
      var subProducts = [];
      if (this.get('productCodeParam') && !this.get('hideSubProducts')) {
        var selectedProducts = (this.get('productCodeParam') || '').split('|');
        var productList = this.get('products');
        selectedProducts.forEach(function (productCode) {
          var product = productList.findBy('externalReference', productCode);

          if (product && product.get('children.length') > 0) {
            product.get('children').forEach(function (child) {
              subProducts.pushObject(child);
            });
          }
        });
      }
      this.set('subProducts', subProducts.sortBy('shortDescription'));
    },


    _productCodeParamValueObserver: Ember.observer('productCodeParam', 'products', 'products.[]', function () {
      Ember.run.once(this, '_setSubProducts');
    }),

    _setSubProductCodeParam: function _setSubProductCodeParam() {
      if (this.get('subProductCodeParam')) {
        var selectedSubProducts = (this.get('subProductCodeParam') || '').split('|');
        var availableSubProducts = this.get('subProducts');

        var stillSelectedSubProducts = selectedSubProducts.filter(function (subProductCode) {
          return availableSubProducts.findBy('externalReference', subProductCode);
        });
        this.set('subProductCodeParam', stillSelectedSubProducts.join('|'));
      }
    },


    _subProductsObserver: Ember.observer('subProducts', 'subProducts.[]', function () {
      Ember.run.once(this, '_setSubProductCodeParam');
    }),

    _setReservationCategories: function _setReservationCategories() {
      var _this6 = this;

      this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_RESERVATION_CATEGORY'
      }).then(function (results) {
        if (!(_this6.get('isDestroyed') || _this6.get('isDestroying'))) {
          _this6.set('reservationCategories', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },
    _setStatuses: function _setStatuses() {
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        if (this.get('isYSGA')) {
          this.set('statuses', this.get('destinationStatuses.ysga'));
        } else if (this.get('areTicketsSelected')) {
          this.set('statuses', this.get('destinationStatuses.tickets'));
        } else {
          this.set('statuses', this.get('destinationStatuses.base'));
        }
        this._resetStatus();
      }
    },


    _statusObserver: Ember.observer('isYSGA', 'i18n.locale', 'sourceLocationCodeParam', 'destinationStatuses.[]', function () {
      Ember.run.once(this, '_setStatuses');
    }),

    _resetStatus: function _resetStatus() {
      // Make sure DESTINATION_STATUS is contained within the list of stati
      var stati = this.get('statuses');
      if (!(this.get('isDestroyed') || this.get('isDestroying')) && !this.get('readOnly')) {
        if (stati && stati.length > 0 && !stati.findBy('codeValue', this.get('statusParam'))) {
          this.set('statusParam', Ember.get(stati, 'firstObject.codeValue'));
        }
      }
    },
    _setLengthOfRents: function _setLengthOfRents() {
      var _this7 = this;

      return this.get('store').query('code', {
        customerID: this.customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_LENGTH_OF_RENT'
      }).then(function (results) {
        if (!(_this7.get('isDestroyed') || _this7.get('isDestroying'))) {
          _this7.set('lengthOfRents', results.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        }
      });
    },


    typeaheadData: Ember.computed('customer.id', function () {
      var _this8 = this;

      var customerID = this.get('customer.id');
      var agreements = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/rest/v1/search?q=%QUERY*&scope=agreements&summary=false&customerID=' + customerID + '&folderID=' + customerID + '&limit=5',
          filter: function filter(_ref2) {
            var _ref2$agreementSearch = _ref2.agreementSearches,
                agreementSearches = _ref2$agreementSearch === undefined ? [] : _ref2$agreementSearch;
            return agreementSearches.map(function (obj) {
              return Ember.Object.create(obj);
            });
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this8.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'agreements',
        displayKey: 'name',
        source: agreements,
        templates: {
          empty: '<div class="menu-header__search-no-results">' + this.get('i18n').t('dashboard-views.no_agreements') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class="menu-header__search-item-section-wrapper">\n                                <div class="menu-header__search-item-section">\n                                  ' + model.get('shortDescription') + '\n                                </div>\n                              </div>';
          }
        }
      };
    }),

    channelList: Ember.computed('channels.[]', 'channelParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('channels') || [], this.get('channelParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all'));
    }),

    subchannelList: Ember.computed('subChannels.[]', 'subChannelParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('subChannels') || [], this.get('subChannelParam'), 'channelCode', 'shortDescription', this.get('i18n').t('dashboard-views.form.all'), function (value) {
        return (0, _capitalize2.capitalize)([value]);
      });
    }),

    pricesList: Ember.computed('prices.[]', 'priceCodeParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('prices') || [], this.get('priceCodeParam'), 'externalReference', 'shortDescription', this.get('i18n').t('dashboard-views.form.all'));
    }),

    productsList: Ember.computed('products.[]', 'productCodeParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('products') || [], this.get('productCodeParam'), 'externalReference', 'shortDescription', this.get('i18n').t('dashboard-views.form.all'));
    }),

    subproductsList: Ember.computed('subProducts.[]', 'subProductCodeParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('subProducts') || [], this.get('subProductCodeParam'), 'externalReference', 'shortDescription', this.get('i18n').t('dashboard-views.form.all'));
    }),

    statusList: Ember.computed('statuses.[]', 'statusParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('statuses') || [], this.get('statusParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all').toString());
    }),

    lorList: Ember.computed('lengthOfRents.[]', 'lengthOfRentParam', function () {
      return (0, _stringifyMultiselect.default)(this.get('lengthOfRents') || [], this.get('lengthOfRentParam'), 'codeValue', 'shortDescription', this.get('i18n').t('dashboard-views.form.all').toString());
    }),

    actions: {
      closeParamPanel: function closeParamPanel() {
        this.set('activeChannelsGroup', '');
      },
      saveParamUpdates: function saveParamUpdates() {
        this.set('activeChannelsGroup', '');

        if (this.get('onParamUpdates')) {
          this.get('onParamUpdates')();
        }
      },
      setActiveChannelsGroup: function setActiveChannelsGroup(value) {
        this.set('activeChannelsGroup', value);
      },
      arrayContains: function arrayContains(array, value) {
        return (0, _arrayContains2.arrayContains)([array, value]);
      },
      capitalize: function capitalize(value) {
        return (0, _capitalize2.capitalize)([value]);
      },
      agreementSelected: function agreementSelected(item) {
        var agreements = this.get('agreements') || [];
        if (!agreements.findBy('id', item.get('id'))) {
          agreements.addObject(item);
        }

        var agreementParam = this.get('bookSrcAccountParam');

        if (agreementParam) {
          this.set('bookSrcAccountParam', (0, _toggleListValue.toggleListValue)([Ember.get(agreementParam, 'value'), item.get('id'), agreements], { separator: '|', resetWhenAllSelected: false }));
        }
      }
    }

  });
});