define('ux-pcc/components/dashboard-widget/bar-allocation', ['exports', 'moment', 'ux-components/utils/format-currency'], function (exports, _moment, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    isMobile: Ember.computed.bool('deviceType.mobile'),
    isDesktop: Ember.computed.bool('deviceType.desktop'),
    isNonDesktop: Ember.computed.not('isDesktop'),
    isNonMobile: Ember.computed.not('isMobile'),

    _minBarIndex: 0,
    _pricePoints: null,

    barAllocations: [],
    currencyCode: 'USD',

    _displayedPricePoints: Ember.computed('isMobile', 'isDesktop', function () {
      if (this.get('isDesktop')) {
        return 5;
      } else if (this.get('isMobile')) {
        return 1;
      }
      return 3;
    }),

    barPricePoints: [],
    disableBarLeft: Ember.computed.equal('_minBarIndex', 0),
    disableBarRight: Ember.computed('_minBarIndex', function () {

      var pricePoints = this.get('_pricePoints');

      return pricePoints && this.get('_minBarIndex') + this.get('_displayedPricePoints') >= pricePoints.length;
    }),

    _fetchData: Ember.on('init', Ember.observer('customer.id', 'selectedDate', function () {
      var _this = this;

      var customerID = this.get('customer.id');
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('selectedDate'), tz).startOf('day');
      var endDate = selectedDate.clone().endOf("day").format();

      this.set('loading', true);

      var requests = [this.ajax.request('/rest/v1/inventoryByBarPricePoint', {
        data: {
          customerid: customerID,
          channelid: '10a00000-0000-0000-0000-000000000000',
          begindate: selectedDate.format(),
          enddate: endDate,
          isRealTime: true
        }
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;


        if (analyticEntries.customers && analyticEntries.customers.length > 0) {
          return analyticEntries.customers[0].customer.calendar[0].data;
        } else {
          return [];
        }
      })];

      Ember.RSVP.all(requests, "Bar Allocations").then(function (data) {
        _this.set('barAllocations', data[0]);
      }).finally(function () {
        _this.set('loading', false);
      });
    })),

    allocationPlan: Ember.observer('barAllocations', function () {
      var _this2 = this;

      var activeIndex = -1;
      var barAllocations = this.get('barAllocations');
      var pricePoints = [];
      var leadTimeIndex = -1;

      if (barAllocations) {
        var tz = this.tz.get('customer');
        var today = _moment.default.tz(tz).startOf('day');
        var date = _moment.default.tz(this.get('selectedDate'), tz);
        var isBeforeToday = date.isBefore(today);

        barAllocations = barAllocations.filter(function (entry) {
          return parseFloat(entry.allocPct) > 0 || entry.leadTime;
        });

        pricePoints = barAllocations.map(function (entry, i) {
          entry.id = 'price-point-' + i;
          entry.title = "";
          entry.classList = "";
          entry.pricePoint = (0, _formatCurrency.default)(entry.pricePoint, _this2.get('currencyCode'));
          entry.allocPct = Math.round(parseFloat(entry.allocPct) * 100);
          var entryLeadTime = entry.leadTime && parseInt(entry.leadTime) >= 0 ? parseInt(entry.leadTime) : void 0;

          if (activeIndex < 0) {
            if (entry.left > 0) {
              activeIndex = i;
            }
          }

          // Move the active highlight if there is a lead time value as appropriate
          if (entryLeadTime !== void 0 && !isBeforeToday && today.isAfter((0, _moment.default)(date).subtract(entryLeadTime + 1, 'days'))) {
            if (entry.left > 0) {
              // Set to 0 because the active lead time should be the first item in the list
              activeIndex = 0;
            }
            leadTimeIndex = i;
          }

          if (entryLeadTime !== void 0) {
            entry.classList = "shaded";
            entry.leadTime = entryLeadTime;
          } else {
            entry.leadTime = null;
          }

          // Set the progress bars widths
          var soldPct = 0;
          var consumed = parseFloat(entry.consumed);
          var allocated = parseFloat(entry.allocated);
          if (consumed > 0 && allocated > 0) {
            if (consumed >= allocated) {
              soldPct = 100;
            } else {
              soldPct = Math.round(consumed / allocated * 100);
            }
          }
          var unsoldPct = 100 - soldPct;

          entry.soldWidth = 'width: ' + soldPct + '%;';
          entry.unsoldWidth = 'width: ' + unsoldPct + '%;';

          return entry;
        });
      }

      // Remove records prior to the lead time record
      if (leadTimeIndex > 0) {
        pricePoints.splice(0, leadTimeIndex);
        // Move the activeIndex to the correct spot in the array
        activeIndex -= leadTimeIndex;
      }

      // Set the titles around the price points
      if (activeIndex >= 0) {

        pricePoints[activeIndex].classList += " active";
        pricePoints[activeIndex].title = this.get('i18n').t('customer._overview.price_point.sellable_bar');

        if (activeIndex > 0) {
          pricePoints[activeIndex - 1].title = this.get('i18n').t('customer._overview.price_point.last_bar');
        }

        if (activeIndex + 1 < pricePoints.length) {
          pricePoints[activeIndex + 1].title = this.get('i18n').t('customer._overview.price_point.next_bar');
        }

        // Add future class to all price points > active index
        for (var i = activeIndex + 1; i < pricePoints.length; i++) {
          pricePoints[i].classList += " future";
        }
      }

      // Keep this for manipulation later
      this.set('_pricePoints', pricePoints);

      // Place the current sellable bar in the appropriate spot
      if (pricePoints) {

        // First one displayed for mobile
        if (this.get('_displayedPricePoints') === 1 && activeIndex >= 0) {
          this.set('_minBarIndex', activeIndex);
        } else if (this.get('_displayedPricePoints') === 5 && pricePoints.length >= 5) {
          if (activeIndex > 2) {
            // in the middle if there are two remaining
            if (activeIndex + 2 <= pricePoints.length) {
              this.set('_minBarIndex', activeIndex - 2);
            } else {
              // Show the last 5 items with the sellable rate in the proper spot
              this.set('_minBarIndex', pricePoints.length - this.get('_displayedPricePoints'));
            }
          }
        }
      }

      // Set the bound array
      if (pricePoints) {
        this.set('barPricePoints', pricePoints.slice(this.get('_minBarIndex'), this.get('_minBarIndex') + this.get('_displayedPricePoints')));
      } else {
        this.set('barPricePoints', null);
      }
    }),

    actions: {
      barWidgetLeft: function barWidgetLeft() {

        var pricePoints = this.get('_pricePoints');
        var minBarIndex = this.get('_minBarIndex') - 1;
        var pricePointCount = this.get('_displayedPricePoints');

        if (minBarIndex + pricePointCount <= pricePoints.length) {
          this.set('barPricePoints', pricePoints.slice(minBarIndex, minBarIndex + pricePointCount));
          this.set('_minBarIndex', minBarIndex);
        }
      },
      barWidgetRight: function barWidgetRight() {

        var pricePoints = this.get('_pricePoints');
        var minBarIndex = this.get('_minBarIndex') + 1;
        var pricePointCount = this.get('_displayedPricePoints');

        if (minBarIndex + pricePointCount <= pricePoints.length) {
          this.set('barPricePoints', pricePoints.slice(minBarIndex, minBarIndex + pricePointCount));
          this.set('_minBarIndex', minBarIndex);
        }
      }
    }
  });
});