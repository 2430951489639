define('ux-pcc/components/training/resource-admin-modal', ['exports', 'ux-components/components/training/resource-admin-modal'], function (exports, _resourceAdminModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _resourceAdminModal.default;
    }
  });
});