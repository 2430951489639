define('ux-pcc/routes/competitive-sets/list/detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    model: function model(params) {
      // TODO If we integrate paging, this will need to be a call
      return this.modelFor('competitive-sets.list').findBy('id', params.id);
    },


    actions: {
      delete: function _delete(model) {
        var _this = this;

        model.destroyRecord().then(function () {
          var competitiveSets = _this.controllerFor('competitive-sets').get('model');
          competitiveSets.removeObject(model);
          _this.replaceWith('competitive-sets');
          _this.send('flash', "<i class='fa fa-thumbs-up'></i> " + _this.i18n.t('competitive-sets.list.detail.delete_success', { competitor: model.get('name') }), 'success');
        }, function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      unlinkProperty: function unlinkProperty(property) {
        var _this2 = this;

        var controller = this.controller;

        // Remove deleted xref
        var url = '/rest/v1/customerXrefs/customer/' + controller.get('model.id') + '/xrefcustomer/' + property.get('id') + '/type/LINK';
        var name = property.get('name');
        this.ajax.delete(url).then(function () {
          controller.set('model.children', controller.get('model.children').rejectBy('id', property.get('id')));
          controller.send('flash', "<i class='fa fa-thumbs-up'></i> " + _this2.i18n.t('competitive-sets.list.detail.unlink_success', { competitor: name }), 'success');
        });
      }
    }
  });
});