define('ux-pcc/components/dashboard-widget/booking-trends-previous-weeks', ['exports', 'ember-cli-file-saver/mixins/file-saver', 'lodash', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/date-sort', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _fileSaver, _lodash, _moment, _stringifyQueryParams, _dateFormatter, _dateSort, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_fileSaver.default, {

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),

    currentSlide: 0,
    data: null,
    loading: false,
    model: null,
    selectedHour: null,
    showComments: true,
    sliceResolution: 'week',
    tz: null,

    bookDate: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedHour', (0, _moment.default)().startOf('hour').format('HH:mm'));
      this.set('data', []);
    },


    hourList: Ember.computed('i18n.locale', function () {
      return (0, _lodash.range)(24).map(function (h) {
        return (0, _moment.default)(h, 'H').format('HH:mm');
      });
    }),

    arrivalDate: Ember.computed('zoomStartDate', 'tz', function () {
      return _moment.default.tz(this.get('zoomStartDate'), this.get('tz')).startOf('day');
    }),

    // Need to do this because opening the info wrapper overwrites the BOOK_DATE from a string to a moment, would trigger
    // a refresh if using a regular computed.
    _bookDateObserver: Ember.observer('model.view.dashboardViewParameters.BOOK_DATE', 'tz', function () {
      var date = _moment.default.tz(this.get('model.view.dashboardViewParameters.BOOK_DATE'), this.get('tz')).startOf('day');

      if (!date.isSame(this.get('bookDate'), 'day')) {
        this.set('bookDate', date);
      }
    }),

    dataProvider: Ember.computed('data.[]', 'bookDate', function () {
      var settings = this.get('customer.settings');
      var tz = this.get('tz');
      var mapped = {};
      var initialDate = this.get('bookDate');
      var data = this.get('data');

      data.forEach(function (d) {
        var date = _moment.default.tz(d.dataValues.bookDate, tz).startOf('day');
        var key = date.format();

        if (!mapped[key]) {
          mapped[key] = {};
        }

        var dataSuffix = d.groupByValue.includes('HOUR') ? 'Current' : 'FullSpan';
        mapped[key]['yieldDays' + dataSuffix] = Number(d.dataValues.yieldCumulativeDays);
        mapped[key]['yieldResCount' + dataSuffix] = Number(d.dataValues.yieldCumulativeResCount);
        mapped[key]['amountDays' + dataSuffix] = Number(d.dataValues.amountCumulativeDays);
        mapped[key]['amountResCount' + dataSuffix] = Number(d.dataValues.amountCumulativeResCount);
        mapped[key]['count' + dataSuffix] = Number(d.dataValues.count);
        mapped[key]['duration' + dataSuffix] = Number(d.dataValues.duration);

        var diffWeeks = date.clone().diff(initialDate, 'weeks');
        mapped[key].slice = date;
        mapped[key].currencyCode = d.currencyCode;
        mapped[key].formattedDate = (0, _dateFormatter.dateFormatter)(date, { settings: settings });
        mapped[key].dow = date.format('ddd');
        mapped[key].sliceFormat = (0, _dateFormatter.dateFormatter)(date, { year: false, returnFormat: true, settings: settings }) + '[<br>' + (diffWeeks !== 0 ? diffWeeks + 'W ' : '') + mapped[key].dow + ']';
        if (diffWeeks === 0) {
          mapped[key].useAlternateHoverLabel = true;
        }
      });

      return Object.keys(mapped).sort((0, _dateSort.dateSort)(tz)).map(function (key) {
        return mapped[key];
      });
    }),

    currencyCode: Ember.computed('dataProvider.[]', function () {
      return this.get('dataProvider.firstObject.currencyCode');
    }),

    l7wCurrentLabel: Ember.computed('currentSlide', 'i18n.locale', 'selectedHour', function () {
      var base = void 0;
      if (this.get('currentSlide') === 0) {
        base = this.i18n.t('yield-manager.booking-trends.day-of-booking.header.l7w_res_count');
      } else if (this.get('currentSlide') === 1) {
        base = this.i18n.t('yield-manager.booking-trends.day-of-booking.header.l7w_days');
      }

      return base + ' (' + this.i18n.t('yield-manager.booking-trends.day-of-booking.through_hour', { hour: this.get('selectedHour') }) + ')';
    }),

    l7wFullSpanLabel: Ember.computed('currentSlide', 'i18n.locale', function () {
      var base = void 0;
      if (this.get('currentSlide') === 0) {
        base = this.i18n.t('yield-manager.booking-trends.day-of-booking.header.l7w_res_count');
      } else if (this.get('currentSlide') === 1) {
        base = this.i18n.t('yield-manager.booking-trends.day-of-booking.header.l7w_days');
      }

      return base + ' (' + this.i18n.t('yield-manager.booking-trends.day-of-booking.full_span') + ')';
    }),

    lastDataItem: Ember.computed('dataProvider.[]', function () {
      var _this = this;

      return this.get('dataProvider').find(function (d) {
        return d.slice.isSame(_this.get('bookDate'), 'day');
      });
    }),

    todays_yield: Ember.computed('lastDataItem.{yieldDaysCurrent,yieldResCountCurrent}', 'currentSlide', function () {
      var _ref = this.get('lastDataItem') || {},
          yieldDaysCurrent = _ref.yieldDaysCurrent,
          yieldResCountCurrent = _ref.yieldResCountCurrent;

      return this.get('currentSlide') === 0 ? yieldResCountCurrent : yieldDaysCurrent;
    }),

    todays_rev: Ember.computed('lastDataItem.{amountDaysCurrent,amountResCountCurrent}', 'currentSlide', function () {
      var _ref2 = this.get('lastDataItem') || {},
          amountDaysCurrent = _ref2.amountDaysCurrent,
          amountResCountCurrent = _ref2.amountResCountCurrent;

      return this.get('currentSlide') === 0 ? amountResCountCurrent : amountDaysCurrent;
    }),

    last7WeeksData: Ember.computed('dataProvider.[]', function () {
      var _this2 = this;

      return this.get('dataProvider').reject(function (d) {
        return d.slice.isSame(_this2.get('bookDate'), 'day');
      });
    }),

    avg_current: Ember.computed('last7WeeksData.[]', 'bookDate', 'currentSlide', function () {
      var isResCountSlide = this.get('currentSlide') === 0;
      return this.get('last7WeeksData').mapBy(isResCountSlide ? 'countCurrent' : 'durationCurrent').reduce(function (a, b) {
        return a + b;
      }, 0) / 7;
    }),

    avg_full_span: Ember.computed('last7WeeksData.[]', 'bookDate', 'currentSlide', function () {
      var isResCountSlide = this.get('currentSlide') === 0;
      return this.get('last7WeeksData').mapBy(isResCountSlide ? 'countFullSpan' : 'durationFullSpan').reduce(function (a, b) {
        return a + b;
      }, 0) / 7;
    }),

    _fireRequeryObserver: Ember.observer('selectedHour', 'bookDate', 'filterChange', 'zoomStartDate', function () {
      Ember.run.once(this, 'queryDataMethod');
    }),

    queryDataMethod: function queryDataMethod() {
      var _this3 = this;

      var tz = this.get('tz');
      var dashboardParams = this.get('model.view');

      Ember.set(dashboardParams, 'dashboardViewParameters.BOOK_HOUR', this.get('selectedHour'));
      Ember.set(dashboardParams, 'dashboardViewParameters.VIEW_BY', 'HOUR');

      var params = (0, _stringifyQueryParams.default)({
        aggregationType: 'BOOKING_WEEKLY_DETAIL_AGGREGATOR',
        groupBy: 'BOOK_DATE'
      });

      this.set('loading', true);
      return this.ajax.post('/rest/v1/analyticEntryAggregation/process' + params, {
        contentType: 'application/json',
        track: true,
        data: (0, _serializeDashboardView.default)(dashboardParams, tz)
      }).then(function (_ref3) {
        var _ref3$collections = _ref3.collections,
            collections = _ref3$collections === undefined ? [] : _ref3$collections;
        return _this3.set('data', collections);
      }).catch(function () {
        return _this3.set('data', []);
      }).finally(function () {
        return _this3.set('loading', false);
      });
    },


    dataFields: Ember.computed('selectedHour', 'i18n.locale', function () {
      var settings = this.get('customer.settings');
      var now = (0, _moment.default)();
      return [Ember.Object.create({
        leftAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.day-of-booking.res_count'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-previous-weeks.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-previous-weeks.res_count') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'countCurrent', 'countFullSpan', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'countCurrent',
          label: this.i18n.t('yield-manager.booking-trends.day-of-booking.through_hour', { hour: this.get('selectedHour') }),
          format: 'number',
          lineClasses: 'reservations',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations',
          enabled: true,
          category: 'left'
        }, {
          key: 'countFullSpan',
          label: this.i18n.t('yield-manager.booking-trends.day-of-booking.full_span'),
          alternateHoverLabel: this.i18n.t('yield-manager.booking-trends.day-of-booking.current'),
          format: 'number',
          lineClasses: 'reservations-compare',
          chartType: 'column',
          valueClassname: 'usageClasses',
          legendClass: 'chart-color--reservations-compare',
          enabled: true,
          category: 'left'
        }]
      }), Ember.Object.create({
        leftAxisTitle: '' + this.i18n.t('yield-manager.booking-trends.day-of-booking.running_days'),
        exportConfig: {
          enabled: true,
          libs: { autoLoad: false },
          fileName: this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-previous-weeks.title') + '_' + this.i18n.t('yield-manager.file-prefix.ysga.booking-trends-previous-weeks.days') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          exportFields: ['formattedDate', 'durationCurrent', 'durationFullSpan', 'currencyCode'],
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.i18n.t('charts.save_as').toString(),
              menu: ['CSV']
            }]
          }]
        },
        values: [{
          key: 'durationCurrent',
          label: this.i18n.t('yield-manager.booking-trends.day-of-booking.through_hour', { hour: this.get('selectedHour') }),
          format: 'number',
          lineClasses: 'days',
          chartType: 'column',
          valueClassname: 'dayClasses',
          legendClass: 'chart-color--days',
          enabled: true,
          category: 'left'
        }, {
          key: 'durationFullSpan',
          label: this.i18n.t('yield-manager.booking-trends.day-of-booking.full_span'),
          alternateHoverLabel: this.i18n.t('yield-manager.booking-trends.day-of-booking.current'),
          format: 'number',
          lineClasses: 'days-compare',
          chartType: 'column',
          valueClassname: 'yieldClasses',
          legendClass: 'chart-color--days-compare',
          enabled: true,
          category: 'left'
        }]
      })];
    })
  });
});