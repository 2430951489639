define('ux-pcc/serializers/agreement-xref', ['exports', 'ux-data/serializers/agreement-xref'], function (exports, _agreementXref) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _agreementXref.default;
    }
  });
});