define('ux-pcc/controllers/yield-manager/view', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['aggregateVersion', 'viewType', 'viewId', 'viewBeginDate', 'viewEndDate'],

    viewBeginDate: null,
    viewEndDate: null,
    hideAdhoc: true,
    mode: 'stacked',
    yieldManager: Ember.inject.controller('yield-manager'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    ajax: Ember.inject.service(),
    requeryAnalytics: false,
    selectedLevels: null,
    products: null,
    isOpportunities: false,
    childLoading: false,
    allowDownload: false,
    downloadTrigger: false,

    // Set to closed for default
    showFilter: true, //false,

    dataTypeParameter: Ember.computed.alias('model.dashboardViewParameters.DATA_TYPE'),

    levelHierarchyParam: Ember.computed.readOnly('model.dashboardViewParameters.LEVEL_HIERARCHY'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedLevels', []);
    },


    // Hack so the code-description only fires when necessary
    _levelHierarchyParamObserver: Ember.observer('levelHierarchyParam', function () {
      if (this.get('levelHierarchyParam') !== this.get('levelHierarchyParamValue')) {
        this.set('levelHierarchyParamValue', this.get('levelHierarchyParam'));
      }
    }),
    levelHierarchyParamValue: null,

    currentLevel: Ember.computed('model.dashboardViewParameters', function () {
      var selectedView = this.get('model');
      var levelHierarchy = selectedView.get('dashboardViewParameters.LEVEL_HIERARCHY');
      var level = selectedView.get('dashboardViewParameters.LEVEL');
      var levelCode = this.get('customerLevels').findBy('codeValue', levelHierarchy);
      var levelDescription = levelCode ? levelCode.get('shortDescription') : this.get('i18n').t('dashboard-views.form.unknown_level');

      return this.get('selectedLevels.lastObject.LEVEL_DESCRIPTION') || [{
        'LEVEL_HIERARCHY': levelHierarchy,
        'LEVEL': level,
        'LEVEL_DESCRIPTION': levelDescription
      }];
    }),

    actions: {
      toggleShowFilter: function toggleShowFilter() {
        this.toggleProperty('showFilter');
      },
      saveParamUpdates: function saveParamUpdates(holdPosition) {

        if (this.get('dataTypeParameter') === 'YSGA') {
          // Set timestamp for all the queries for the page
          this.set('model.dashboardViewParameters.LAST_MODIFY_FILTER', _moment.default.tz(_moment.default.tz.guess(true)).format());
        } else {
          this.set('model.dashboardViewParameters.LAST_MODIFY_FILTER', null);
        }
        // Just changed the search, so top level breadcrumb should be re-initialized
        // this._initializeBreadcrumb();
        this.send('refreshChildRoute');
        // Slide to the top
        if (!holdPosition) {
          Ember.$('#main-content').animate({ scrollTop: 0 });
        }

        this.set('viewType', this.get('dataTypeParameter'));
        this.toggleProperty('reloadParameters');
      },
      updateFilter: function updateFilter(data) {
        var _this = this;

        // Set the filter value with the selected item, and reload the data
        var category = this.get('model.dashboardViewParameters.' + data.filterCategory);
        if (typeof category !== 'undefined') {

          // If LEVEL, then we need to look up the customer, and switch the LEVEL_HIERARCHY parameter as well
          if (data.filterCategory === 'LEVEL') {

            // Requery if not already at this level, and customer id is not null
            if (data.customerID && category !== data.customerID) {
              this.get('store').findRecord('customer', data.customerID).then(function (newCustomer) {

                var tagList = (newCustomer.get('tags') || []).filter(function (tag) {
                  return tag.startsWith('LEVEL_');
                });

                if (tagList.length > 0) {
                  _this.set('model.dashboardViewParameters.LEVEL_HIERARCHY', tagList[0]);
                  _this.set('model.dashboardViewParameters.' + data.filterCategory, data.customerID);
                  _this.send('saveParamUpdates');
                }
              }, function () {
                _this.send('flash', '<i class="fa fa-warning"></i> ' + _this.get('i18n').t('yield-manager.analyze.message.level_fetch_error'), 'danger');
              });
            }
          } else {
            this.set('model.dashboardViewParameters.' + data.filterCategory, data.categoryValue);
            this.send('saveParamUpdates');
          }
        }
      },
      download: function download() {
        this.notifyPropertyChange('triggerDownload');
      }
    }
  });
});