define('ux-pcc/components/charts/chart-dow-percent-arrivals', ['exports', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/utils/charts/mark-today'], function (exports, _chartBasic, _markToday) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({
    _config: function _config() {
      var _this = this;

      var dataFields = this.get('dataFields');
      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this.get('data') && _this.get('data').length > 0) {
          // Put them in reverse order they appear, as they get popped off the array below
          var colors = ['#5DE3D8', '#72BF43', '#4E5356', '#A8ACAD', '#086FC4', '#8AD3F7'];

          var generateGraph = function generateGraph(title, value, index) {
            var chartColor = colors.pop();
            return {
              title: title,
              balloonFunction: function balloonFunction(item) {
                var balloonData = '';
                if (index === parseInt(dataFields.length / 2)) {
                  dataFields.forEach(function (d) {
                    balloonData += '<div>\n                                    <span class=\'bubble-label\'>' + d.label + ': </span>\n                                    <span class=\'bubble-value percent\'>' + item.dataContext[d.key] + '</span>\n                                  </div>';
                  });
                }

                return balloonData;
              },
              fillAlphas: 1,
              fillColors: chartColor,
              lineAlpha: 1,
              lineColor: chartColor,
              type: 'column',
              id: value + ' chart-color--' + index,
              valueField: value,
              valueAxis: 'percentArrival'
            };
          };

          dataFields.forEach(function (d, i) {
            return graphs.push(generateGraph(d.label, d.key, i));
          });
        }

        return graphs;
      };

      var calendars = this.get('data');
      var config = {
        type: 'serial',
        addClassNames: true,
        categoryField: 'dow',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryAxis: {
          gridAlpha: 0.1,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass',
          labelsEnabled: !this.get('deviceType.mobile')
        },
        chartCursor: {
          balloonPointerOrientation: 'vertical',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: true,
          cursorAlpha: 0,
          color: '#f3faff',
          categoryBalloonEnabled: false
        },
        legend: {
          enabled: true,
          position: 'top',
          markerSize: 10,
          markerType: 'square'
        },
        trendLines: [],
        graphs: generateGraphs(),
        valueAxes: [{
          id: 'percentArrival',
          gridAlpha: 0.1,
          autoGridCount: false,
          gridCount: 10,
          axisAlpha: 0.1,
          tickLength: this.get('deviceType.mobile') ? 0 : 30,
          position: 'left',
          minimum: 0,
          maximum: 100,
          strictMinMax: true,
          unit: '%'
        }],
        allLabels: [],
        balloon: {
          adjustBorderColor: true,
          borderAlpha: 0,
          shadowAlpha: 0,
          color: '#ffffff',
          fillColor: '#0B8CF5',
          fillAlpha: 0.9,
          textAlign: 'middle',
          verticalPadding: 15,
          horizontalPadding: 20,
          offsetX: 50
        },
        titles: [],
        dataProvider: calendars
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },
    _createChart: function _createChart() {
      var chart = this._super();
      var element = this.get('element');

      if (chart) {
        (0, _markToday.chartsMarkToday)(element, 3, { y: 10 });
        chart.addListener('drawn', function () {
          return (0, _markToday.chartsMarkToday)(element, 3, { y: 10 });
        });
      }
    }
  });
});