define('ux-pcc/components/charts/line-chart', ['exports', 'moment', 'ux-pcc/config/environment', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/helpers/wrap-negative', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/charts/mark-today'], function (exports, _moment, _environment, _chartBasic, _wrapNegative, _dateFormatter, _markToday) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    classNames: ['line-chart'],

    sliceResolution: 'day',

    enableZoom: true,
    exportPosition: 'top-right',
    enableExport: false,

    zoomIndexStart: 0,
    zoomIndexEnd: 30,

    showGraphInZoom: Ember.computed('days', 'tm', function () {
      return this.get('days') ? 'cum-days chart-color--days' : 'cum-days chart-color--revenue';
    }),

    dataTransform: Ember.computed('data', 'sliceResolution', function () {
      var _this = this;

      var settings = this.get('customer.settings');
      return function (data) {
        var today = _moment.default.tz(_this.get('tz.customer')).startOf('day');

        data.forEach(function (d) {
          if (d.entrySlice.isSame(today, _this.get('sliceResolution'))) {
            d.dateClass = 'today';
          }

          d.slice = d.entrySlice.format('ddd') + '<br>' + (0, _dateFormatter.dateFormatter)(d.entrySlice, { year: false, settings: settings });
        });

        return data;
      };
    }),

    markDay: Ember.computed('data', function () {
      return (this.get('data').findBy('dateClass', 'today') || {}).slice;
    }),

    // Get the first day that will be used for the week 'shading' guides
    startDay: Ember.computed('selectedDate', function () {
      return this.get('selectedDate').clone().add(1, 'day').endOf('week').add(1, 'day');
    }),

    // Change the guides when the selected date is changed (handle changes in month)
    selectedDateChanged: Ember.observer('selectedDate', function () {
      this._shadowAltWeeks();
    }),

    _legendObserver: Ember.observer('days', 'tm', function () {
      this._createChart();
    }),

    _config: function _config() {
      var _this2 = this;

      var data = this.get('_data');
      var config = this.get('graphConfig');

      var generateGraphs = function generateGraphs() {
        var graphs = [];

        if (_this2.get('data.length') > 0) {

          var balloonFunction = function balloonFunction(item, graph) {
            if (graph.valueField === 'tmRevVar' && _this2.get('days')) {
              return '';
            }

            var _generateHTML = function _generateHTML(label, classStyle, value) {
              return '<div>\n                      <span class=\'bubble-label\'>' + label + ': </span>\n                      <span class=\'bubble-value ' + classStyle + '\'>' + value + '</span>\n                    </div>';
            };

            var displayHTML = '';

            if (_this2.get('days')) {
              var deVarLabel = item.dataContext.deVarLabel;
              var deVarValue = item.dataContext.deVar;
              var deVarClass = deVarValue < 0 ? 'negative-value-class' : '';
              deVarValue = (0, _wrapNegative.wrapNegative)(deVarValue, { suffix: '%', precision: 1 });
              displayHTML += _generateHTML(deVarLabel, deVarClass, deVarValue);
            }

            if (_this2.get('tm')) {
              var tmRevVarLabel = item.dataContext.tmRevVarLabel;
              var tmRevVarValue = item.dataContext.tmRevVar;
              var tmRevVarClass = tmRevVarValue < 0 ? 'negative-value-class' : '';
              tmRevVarValue = (0, _wrapNegative.wrapNegative)(tmRevVarValue, { suffix: '%', precision: 1 });
              displayHTML += _generateHTML(tmRevVarLabel, tmRevVarClass, tmRevVarValue);
            }

            return displayHTML;
          };

          config.get('graphs').forEach(function (graphConfig) {

            if (graphConfig.valueField === 'deVar' && !_this2.get('days') || graphConfig.valueField === 'tmRevVar' && !_this2.get('tm')) {
              return;
            }

            var graph = {
              title: graphConfig.title,
              fillAlphas: 0,
              type: 'line',
              bullet: graphConfig.bulletStyle ? graphConfig.bulletStyle : 'round',
              bulletSize: 5,
              bulletBorderAlpha: 1,
              bulletAlpha: 1,
              classNameField: 'bulletClass',
              useLineColorForBulletBorder: true,
              lineThickness: 2,
              id: 'cum-days ' + graphConfig.colorClass,
              valueField: graphConfig.valueField,
              balloonFunction: balloonFunction,
              balloon: { fillAlpha: 1 }
            };

            if (graphConfig.type) {
              graph.type = graphConfig.type;
            }

            graphs.push(graph);
          });
        }

        return graphs;
      };

      var valueAxes = [{
        id: 'added',
        autoGridCount: true,
        gridAlpha: 0.1,
        gridCount: 8,
        axisAlpha: 0,
        position: 'left',
        unit: '%',
        minimum: 0,
        title: this.get('_addedTitle'),
        usePrefixes: true,
        guides: [{
          id: 'zero-marker',
          value: 0,
          lineAlpha: 1,
          lineThickness: 3,
          above: true,
          inside: true,
          fillColor: '#000000',
          lineColor: '#000000'
        }]
      }];

      var guides = [];

      if (this.get('markDay')) {
        guides.push({
          id: 'today',
          category: this.get('markDay'),
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        });
      }

      var _config = {
        hideCredits: true,
        type: 'serial',
        addClassNames: true,
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,

        synchronizeGrid: true,

        trendLines: [],
        allLabels: [],
        balloon: {},
        titles: [],

        graphs: generateGraphs(),

        valueAxes: valueAxes,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass'
        },

        guides: guides,

        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: true,
          cursorAlpha: 0,
          cursorColor: '#4D96CB',
          color: '#F3FAFF',
          categoryBalloonEnabled: false
        },

        language: this.get('i18n.locale'),

        dataProvider: data
      };

      if (this.get('title')) {
        _config.titles.push({ text: this.get('title') });
      }

      if (this.get('enableExport')) {
        var settings = this.get('customer.settings');
        var now = (0, _moment.default)();
        _config.export = {
          enabled: true,
          libs: { autoLoad: false },
          position: this.get('exportPosition'),
          fileName: this.get('filePrefix') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.get('i18n').t('charts.save_as').toString(),
              menu: ['JSON']
            }]
          }]
        };
      }

      if (this.get('enableZoom')) {
        this.set('scrollBarExists', true);
        _config.pathToImages = _environment.default.rootURL + 'assets/amcharts/images/'; // required for grips

        _config.chartScrollbar = {
          graph: this.get('showGraphInZoom'),
          oppositeAxis: false,
          offset: 50,
          scrollbarHeight: 60,
          backgroundAlpha: 0,
          selectedBackgroundAlpha: 0.1,
          selectedBackgroundColor: '#888888',
          graphFillAlpha: 0,
          graphLineAlpha: 0.2,
          graphLineColor: '#888888',
          selectedGraphFillAlpha: 0,
          selectedGraphLineAlpha: 1,
          selectedGraphLineColor: '#888888',
          dragIconHeight: 20,
          dragIconWidth: 20,
          graphType: 'line',
          gridCount: 4,
          color: '#AAAAAA',
          dragIcon: 'dragIconRoundSmall'
        };
      } else {
        this.set('scrollBarExists', false);
      }

      return _config;
    },
    _shadowAltWeeks: function _shadowAltWeeks() {
      var chart = this.get('_chart');
      if (!chart || !chart.categoryAxis || !chart.categoryAxis.guides) {
        return;
      }

      // Clear previous shading guides. Relies on the fact that all shading guides are after the first guide.
      while (chart.categoryAxis.guides.length > 1) {
        var guide = chart.categoryAxis.guides[1];
        chart.categoryAxis.removeGuide(guide);
      }

      var months = 5;
      var weeksPerMonth = 4;
      for (var i = 0; i < months * weeksPerMonth; i += 2) {
        var _guide = new AmCharts.Guide();
        _guide.category = this.get('startDay').clone().add(i, 'week').format(this.get('dateFormat'));

        // Set data of new guide
        _guide.toCategory = this.get('startDay').clone().add(i + 1, 'week').subtract(1, 'day').format(this.get('dateFormat'));
        _guide.fillAlpha = 0.2;
        _guide.fillColor = '#888888';
        _guide.lineAlpha = 0;
        _guide.labelRotation = 90;
        _guide.inside = true;
        _guide.lineThickness = 2;
        _guide.expand = true;

        // Add new guide to chart
        chart.categoryAxis.addGuide(_guide);
      }

      // Redraw the chart with new guides
      chart.validateNow();
    },
    zoomChart: function zoomChart() {
      var chart = this.get('_chart');
      var indexEnd = this.get('zoomIndexEnd');
      if (chart.dataProvider && chart.dataProvider.length > 0) {
        chart.zoomToIndexes(this.get('zoomIndexStart'), indexEnd < chart.dataProvider.length - 1 ? indexEnd : chart.dataProvider.length - 1);
      }
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      // If we have changed the size from mobile to desktop or back, need to
      // re-create the chart to remove or show the scrollbar
      if (this.get('enableZoom') && !this.get('scrollBarExists') || !this.get('enableZoom') && this.get('scrollBarExists')) {
        this._createChart();
      }
    },
    _createChart: function _createChart() {
      var _this3 = this;

      var chart = this._super();
      var element = this.get('element');

      if (chart) {
        (0, _markToday.chartsMarkToday)(element, 3);
        chart.addListener('drawn', function () {
          if (_this3.get('enableZoom') && _this3.get('zoomIndexStart') >= 0) {
            _this3.zoomChart();
          }

          (0, _markToday.chartsMarkToday)(element, 3);
        });

        // The drawn event is not fired when the chart scrolls with the scrollbar
        // Instead, the zoomed event is fired.
        chart.addListener('zoomed', function (zoomInfo) {
          (0, _markToday.chartsMarkToday)(element, 3);
          if (_this3.get('actionOnZoom')) {
            _this3.get('actionOnZoom')(zoomInfo);
          }
        });
      }

      // Needed for guides to be on initial render.
      this._shadowAltWeeks();
    }
  });
});