define('ux-pcc/utils/pad-number', ['exports', 'ux-components/utils/pad-number'], function (exports, _padNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _padNumber.default;
    }
  });
});