define('ux-pcc/components/charts/yield-chart', ['exports', 'moment', 'accounting', 'ux-components/utils/format-currency', 'ux-pcc/components/charts/chart-basic'], function (exports, _moment, _accounting, _formatCurrency, _chartBasic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    showLegend: true,
    showValueAxisLabels: false,
    showGuide: true,
    showBalloon: true,

    _config: function _config() {
      var _this = this;

      var tz = this.get('tz');
      var dataFields = this.get('dataFields');
      var lineThickness = 1.5;

      var dateLabel = function dateLabel(valueText, tz) {

        var m = (0, _moment.default)(valueText).tz(tz);
        return '' + m.format("MMMDD");
      };

      var bubbleValue = function bubbleValue(valueObject, key, format) {
        if (format === 'currency') {
          return (0, _formatCurrency.default)(valueObject[key], valueObject['currencyCode'], { hash: { precision: 0 } });
        } else if (format === 'percent') {
          return _accounting.default.formatNumber(valueObject[key], 1);
        } else if (format === 'number') {
          return '' + _accounting.default.formatNumber(valueObject[key], '');
        } else {
          return valueObject[key];
        }
      };

      var bubbleContent = function bubbleContent(item, graphKey) {
        var dataField = dataFields.findBy('key', graphKey);
        return bubbleValue(item.dataContext, graphKey, dataField.format);
      };

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this.get('data') && _this.get('data').length > 0) {
          // Put them in reverse order they appear, as they get popped off the array below
          var colors = ["#5de3d8", "#72BF43", "#4e5356", "#a8acad", "#086fc4", "#8ad3f7"];

          var generateGraph = function generateGraph(title, value, index, lineClasses) {

            var valueAxis = "count";
            switch (value) {
              case "gross":
              case "base":
              case "stlyGross":
              case "stlyBase":
                valueAxis = "revenue";
                break;
              default:
                valueAxis = "count";
                break;
            }

            var chartColor = colors.pop();
            return {
              title: title,
              balloonFunction: function balloonFunction(item, graph) {
                return bubbleContent(item, graph.valueField);
              },
              "fillAlphas": 0,
              "lineColor": chartColor,
              "lineColorField": "color_" + index,
              "type": "line",
              "lineThickness": lineThickness,
              "id": index + ' chart-color--' + (lineClasses ? lineClasses : index),
              "valueField": value,
              "valueAxis": valueAxis,
              "clustered": true,
              "connect": false
            };
          };

          for (var i = 0; i < dataFields.length; i++) {
            graphs.push(generateGraph(dataFields[i].chartLabel ? dataFields[i].chartLabel : dataFields[i].label, dataFields[i].key, i, dataFields[i].lineClasses));
          }
        }

        return graphs;
      };

      var calendars = this.get('data');

      if (!calendars) {
        return;
      }

      var config = {
        "type": "serial",
        "synchronizeGrid": true,
        "addClassNames": true,
        "startDuration": 0,
        "dataDateFormat": "YYYY-MM-DD",
        sequencedAnimation: false,
        panEventsEnabled: false,
        "categoryField": "date",
        "categoryAxis": {
          color: "#666666",
          axisColor: "#666666",
          gridAlpha: 0,
          axisAlpha: 0,
          "gridPosition": "start",
          //"parseDates": true,
          //"boldPeriodBeginning": false,
          //markPeriodChange: false,
          labelFrequency: 2,
          //"dateFormats": [{"period":"fff","format":"JJ:NN:SS"},{"period":"ss","format":"JJ:NN:SS"},{"period":"mm","format":"JJ:NN"},{"period":"hh","format":"JJ:NN"},{"period":"DD","format":"MMMDD"},{"period":"WW","format":"MMMDD"},{"period":"MM","format":"MMMDD"},{"period":"YYYY","format":"MMMDD"}],
          //"equalSpacing": true,
          autoGridCount: !this.get('deviceType.mobile'),
          gridCount: 3,
          "labelFunction": function labelFunction(valueText) {
            if (!Ember.isEmpty(valueText)) {
              return dateLabel(valueText, tz);
            }
          }
        },
        "chartCursor": {
          "enabled": this.get('showBalloon'),
          "zoomable": false,
          "selectWithoutZooming": true,
          cursorColor: "#4d96cb",
          color: "#f3faff",
          categoryBalloonEnabled: false,
          cursorAlpha: 0,
          "balloonPointerOrientation": "horizontal",
          valueBalloonsEnabled: true
        },
        "trendLines": [],
        "graphs": generateGraphs(),
        "valueAxes": [{
          "id": "revenue",
          color: "#999999",
          "gridAlpha": 0.1,
          "axisAlpha": 0,
          "labelsEnabled": this.get('showValueAxisLabels'),
          "unit": "$",
          "zeroGridAlpha": 0,
          "minimum": 0,
          "position": "right",
          "labelFunction": function labelFunction(valueText /*, serialDataItem*/) {
            // TODO Use Customer currency code
            return _accounting.default.formatMoney(valueText, { precision: 0 });
          },
          showFirstLabel: true
        }, {
          "id": "count",
          color: "#999999",
          "gridAlpha": 0.1,
          "axisAlpha": 0,
          "labelsEnabled": this.get('showValueAxisLabels'),
          "zeroGridAlpha": 0,
          "minimum": 0,
          "position": "left",
          "labelFunction": function labelFunction(valueText) {
            return valueText;
          },
          showFirstLabel: true
        }],
        legend: {
          "enabled": this.get('showLegend'),
          "position": "top",
          "markerSize": 10,
          "markerType": "square",
          "valueWidth": 0,
          "useGraphSettings": false
        },
        "allLabels": [],
        "balloon": {
          "adjustBorderColor": true,
          "shadowAlpha": 0,
          "fillAlpha": 0.9,
          "textAlign": "middle",
          "verticalPadding": 5,
          "horizontalPadding": 15,
          "enabled": this.get('showBalloon')
        },
        "titles": [],
        "zoomOutOnDataUpdate": true,
        "dataProvider": calendars
      };

      if (this.get('showGuide')) {
        config["guides"] = [{
          id: "today",
          category: _moment.default.tz(this.get('tz')).startOf('day').format(),
          lineColor: "#666666",
          color: "#999999",
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        }];
      }

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },

    _afterRedrawOnDataChanged: function _afterRedrawOnDataChanged() {
      this._super();
    },

    _createChart: function _createChart() {

      var data = this.get('data');
      if (!data || data.length <= 0) {
        return;
      }

      this._super();

      // Enable interactivity
      var $chartDiv = Ember.$(this.element).find('.amcharts-chart-div');
      $chartDiv.find('svg').css('cursor', "pointer").on('click', function () {
        // var i = this.get('_chart').chartCursor.index;
        // var data = this.get('data')[i];
      });
    },

    dataFieldsChanged: Ember.observer('dataFields.[]', function () {
      this._createChart();
    })

  });
});