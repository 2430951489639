define('ux-pcc/components/widget/flag-graph', ['exports', 'moment', 'ux-pcc/components/charts/chart-basic', 'ux-components/utils/format-currency'], function (exports, _moment, _chartBasic, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      // grow the flag chart to fill the widget better
      if (this.containerClassName) {
        Ember.$(document).ready(function () {
          var containerWidgets = document.getElementsByClassName(_this.containerClassName);
          if (containerWidgets.length > 0) {
            var height = .9 * containerWidgets[0].offsetHeight;
            var chartContainer = Ember.$(_this.get('element')).find('.am-chart-container');
            if (chartContainer && height > chartContainer.height()) {
              chartContainer.height(height);
            }
          }
        });
      }
    },


    _labelPeriod: function _labelPeriod(valueText, tz /*, i*/) {
      var m = (0, _moment.default)(valueText).tz(tz);
      var value = m.format("MM/DD");

      return m.format("ddd") + "\n\r" + value;
    },

    _config: function _config() {
      var _this2 = this;

      // var tz = this.get('tz.customer');
      var currencyCode = this.get('context.price.defaultCurrencyCode') || "USD";

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this2.get('data') && _this2.get('data').length > 0) {

          // Actual Occupancy
          graphs.push({
            balloonFunction: function balloonFunction() /*item, graph*/{
              return "";
              //return `<span class='flag-value'> ${formatCurrency(item.dataContext["value"], currencyCode)}</span>`;
            },
            "fillAlphas": 0.5,
            "fillColors": "#4facf8",
            "lineAlpha": 0,
            "lineColor": "#4facf8",
            "type": "column",
            "clustered": false,
            "columnWidth": _this2.get('rotate') ? 0.25 : 0.5,
            "id": "label",
            "valueField": "value",
            "valueAxis": "category",
            "labelText": "[[value]]",
            "labelFunction": function labelFunction(item) {
              return (0, _formatCurrency.default)(item.values.value, currencyCode);
            },
            "classNameField": "pie_color_class"
          });
        }

        return graphs;
      };

      var data = this.get('data');
      var config = {
        "type": "serial",
        "addClassNames": true,
        "classNameField": "pie_color_class",
        "categoryField": "label",
        "startDuration": 0,
        "rotate": this.get('rotate'),
        sequencedAnimation: false,
        panEventsEnabled: false,
        "categoryAxis": {
          gridAlpha: 0,
          axisAlpha: 0.1,
          "gridPosition": "start",
          "color": "#505558",
          "parseDates": false,
          "classNameField": "dateClass",
          "autoWrap": true,
          "fontSize": this.get('rotate') ? 10 : 8
        },
        "chartCursor": {
          "balloonPointerOrientation": this.get('rotate') ? "horizontal" : "vertical",
          "zoomable": false,
          "selectWithoutZooming": true,
          "fullWidth": false,
          "enabled": true,
          "cursorAlpha": 0,
          "categoryBalloonEnabled": false
        },
        legend: {
          "enabled": false
        },
        "trendLines": [],

        "graphs": generateGraphs(),

        "valueAxes": [{
          "id": "category",
          //"autoGridCount": false,
          "color": "#005B9F",
          "gridAlpha": this.get('rotate') ? 0 : 0.1,
          //"gridCount": 0,
          "axisAlpha": 0,
          "minimum": 0,
          "labelsEnabled": false
        }],
        "allLabels": [],
        "balloon": {
          "adjustBorderColor": true,
          "shadowAlpha": 0,
          "textAlign": "middle"
        },
        "titles": [],
        "dataProvider": data
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },

    _afterRedrawOnDataChanged: function _afterRedrawOnDataChanged() {
      this._super();
    },

    _createChart: function _createChart() {
      this._super();

      // Adjustment hack because of chart width and heights needing to be specific px size vs. percentages
      // Labels sometimes get cut off, so adjust the width a bit
      if (this.get('rotate')) {
        var $chartContainer = Ember.$(this.get('element')).find('.am-chart-container');

        Ember.$($chartContainer).css({ overflow: 'visible' });
        var chartWidth = Ember.$(this.get('element')).parent().parent().find(".flag-chart").parent().outerWidth() * 0.98;
        $chartContainer.find('.amcharts-chart-div').width(chartWidth + 'px');
        $chartContainer.find('.amcharts-chart-div svg:first-child').width(chartWidth + 'px');

        // Adjust the chart height as well if necessary to 90% of the widget container to "fill" the space to match the other
        // widgets on the pages
        var containerHeight = Ember.$(this.get('element')).closest('.widget-section-container').height() * 0.90;
        if ($chartContainer.height() < containerHeight) {
          $chartContainer.height(containerHeight + 'px');
        }
      }

      // Enable interactivity
      var $chartDiv = Ember.$(this.get('element')).find('.amcharts-chart-div');
      $chartDiv.find('svg').css('cursor', "pointer").on('click', function () {
        // var i = this.get('_chart').chartCursor.index;
        // var data = this.get('data')[i];
      });
    },

    optionChanged: Ember.observer('rotate', function () {
      // Need to recreate the chart if the rotation changes
      this._createChart();
    })

  });
});