define('ux-pcc/controllers/rules-feed/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['alertStatus', 'startDate', 'endDate', 'viewBy'],

    alertStatus: 'current',

    startDate: null,
    endDate: null,
    viewBy: 'newest',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model')
  });
});