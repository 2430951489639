define('ux-pcc/components/charts/chart-performance', ['exports', 'moment', 'ux-pcc/components/charts/chart-basic'], function (exports, _moment, _chartBasic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    _config: function _config() {
      var _this = this;
      var colors = ["#762a83", "#9970ab", "#c2a5cf", "#a6dba0", "#5aae61", "#1b7837"];
      var generateGraphs = function generateGraphs() {
        var graphs = [];
        // var properties = {};
        if (_this.get('data')[0] && _this.get('data')[0].properties && _this.get('data')[0].properties.length > 0) {
          _this.get('data')[0].properties.forEach(function (property) {
            graphs.push({
              title: property.name,
              balloonFunction: function balloonFunction(item /*, graph*/) {
                var dataValue = parseFloat(item.dataContext[property.id + "-revpar"]);
                var value = "";
                if (dataValue > 0) {
                  value += " <span style='color: green;white-space: pre;'><i class='fa fa-arrow-up'></i> " + dataValue + "%</span>";
                } else {
                  value += " <span style='color: red;white-space: pre;'><i class='fa fa-arrow-down'></i> " + dataValue * -1 + "%</span>";
                }
                return property.name + value;
              },
              "fillAlphas": 0,
              "lineColor": colors.pop(),
              "type": "line",
              "lineThickness": 2,
              "id": property.id + "revpar",
              "valueField": property.id + "-revpar",
              "valueAxis": "amount",
              "clustered": true
            });
          });
        }

        return graphs;
      };

      var config = {
        categoryAxis: {
          labelFunction: function labelFunction(valueText, serialDataItem) {
            var i = this.data.indexOf(serialDataItem);
            return i.toString();
          }
        },
        "graphs": generateGraphs(),
        "valueAxes": [{
          "id": "amount",
          color: "#999999",
          "gridAlpha": 0.2,
          "axisAlpha": 0,
          "position": "left",
          showFirstLabel: false
        }]
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },

    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      this._super();

      var chart = this.get('_chart');
      chart.categoryAxis.guides = this._buildGuides();
    },

    _buildGuides: function _buildGuides() {

      return [{
        category: _moment.default.tz(this.get('tz.customer')).startOf('day').format(),
        lineColor: "#666666",
        color: "#999999",
        lineAlpha: 1,
        lineThickness: 2,
        boldLabel: true,
        dashLength: 5,
        inside: true,
        labelRotation: 90,
        label: "Today"
      }].concat(this._super()).compact();
    },

    _createChart: function _createChart() {
      this._super();

      var _this = this;

      //TODO: REEVALUATE
      // Enable interactivity
      var $chartDiv = Ember.$(this.element).find('.amcharts-chart-div');
      $chartDiv.find('svg').css('cursor', "pointer").on('click', function () /*event*/{
        var i = _this.get('_chart').chartCursor.index;
        var data = _this.get('data')[i];

        _this.sendAction('selected', _this.get('_focused'), data.slice);
      });
      $chartDiv.find('.amcharts-graph-line:not(.amcharts-legend-marker)').css('pointer-events', "all");

      this._senseDisturbance('.amcharts-balloon-div', "amcharts-balloon-div", {
        onlyY: true
      });
    },

    _senseDisturbance: function _senseDisturbance(selector, key, options) {
      var _this = this;
      var regex = new RegExp(key + "-([\\w\\-_]+)", "i");

      var $chartDiv = Ember.$(this.element).find('.amcharts-chart-div');

      var clearFocus = function clearFocus() {
        $chartDiv.find('.amcharts-graph-line').each(function (index, elem) {
          var classes = Ember.$(elem).attr('class').split(" ") || [];
          Ember.$(elem).attr('class', classes.reject(function (e) {
            return e === "focus";
          }).join(" "));
          _this.set('_focused', undefined);
        });
      };

      var handleMove = function handleMove(event) {
        var first = Ember.$(selector).nearest({ x: event.pageX, y: event.pageY }, options)[0];
        if (first) {
          clearFocus();
          var matches = Ember.$(first).attr('class').match(regex);

          var $target = $chartDiv.find('.amcharts-graph-line.amcharts-graph-' + matches[1]);
          var classes = $target.attr('class').split(" ") || [];
          classes.push("focus");

          _this.set('_focused', matches[1].replace('revpar', ''));

          $target.attr('class', classes.uniq().join(" "));
        }
      };

      // TODO This may be a prime candidate for rx/kefir stream property
      $chartDiv.mousemove(function (event) {
        Ember.run.debounce(this, handleMove, event, 10);
      });

      $chartDiv.mouseleave(function () {
        clearFocus();
      });
    }
  });
});