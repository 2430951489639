define('ux-pcc/components/tables/table-cell', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "td",

    classNameBindings: ['computedClassNames'],

    computedClassNames: Ember.computed('model.classNames', function () {
      return (this.get('model.classNames') || []).join(" ");
    })
  });
});