define('ux-pcc/controllers/affiliates/-tag-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customer: Ember.computed.alias('model.customer'),

    customerController: Ember.inject.controller('customer'),

    ajax: Ember.inject.service(),

    tokenOptions: {
      createTokensOnBlur: true
    },
    actions: {
      close: function close() {
        this.send('removeModal');
      },
      save: function save() {
        var _this = this;

        var customer = this.get('customer');
        customer.set('tags', customer.get('tags').map(function (t) {
          return t.value;
        }));
        customer.save().then(function () {
          // Re-query children customers so they will have updated tags
          var customerController = _this.get('customerController');

          return _this.store.query('customer', {
            folderid: customerController.get('model.id'),
            detaillevel: 'XREF'
          }).then(function (properties) {
            customerController.set("model.xrefs", properties);
          });
        }).then(function () {
          // Success!
          _this.send('flash', "<i class='fa fa-thumbs-up'></i> " + "Successfully updated affiliate '" + customer.get('name') + "'", 'success');
          _this.send('removeModal');
        }, function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      removeModal: function removeModal() {
        return true;
      }
    }
  });
});