define('ux-pcc/components/rules/condition-values/noop', ['exports', 'ux-pcc/components/rules/condition-values/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    validations: Ember.computed('i18n.locale', function () {
      return {};
    })

  });
});