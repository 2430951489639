define('ux-pcc/components/charts/competitor-rates-chart', ['exports', 'moment', 'accounting', 'ux-pcc/utils/chart-date-label'], function (exports, _moment, _accounting, _chartDateLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    enableExport: false,

    didInsertElement: function didInsertElement() {
      this._createChart();
    },
    _createChart: function _createChart() {
      var _this = this;

      var tz = this.get('tz.customer');

      var dataProvider = this.get('dataProvider');
      var config = {
        hideCredits: true,
        type: "serial",
        categoryField: "slice",
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryAxis: {
          color: "#666666",
          axisColor: "#666666",
          axisThickness: 2,
          gridAlpha: 0,
          gridPosition: "start",
          parseDates: false,
          labelFunction: function labelFunction(valueText, serialDataItem) {
            var i = _this.get('dataProvider').indexOf(serialDataItem.dataContext);
            return (0, _chartDateLabel.default)(valueText, tz, i, _this.get('dataProvider').length);
          }
        },
        chartCursor: {
          zoomable: false,
          selectWithoutZooming: true,
          cursorColor: "#4d96cb",
          color: "#f3faff",
          categoryBalloonEnabled: false
        },

        trendLines: [],
        graphs: [{
          title: "Public",
          balloonFunction: function balloonFunction(item /*, graph*/) {
            return "Public " + "<strong>" + _accounting.default.formatMoney(item.dataContext["public"]) + "</strong>";
          },

          fillAlphas: 0,
          lineColor: "#9acbc7",
          type: "line",
          lineThickness: 2,
          id: "public",
          valueField: "public",
          valueAxis: "amount",
          clustered: true,
          connect: false
        }, {
          title: "Maximum",
          balloonFunction: function balloonFunction(item /*, graph*/) {
            return "Maximum " + "<strong>" + _accounting.default.formatMoney(item.dataContext["maximum"]) + "</strong> (" + item.dataContext["maximum_competitor"] + ")";
          },

          fillAlphas: 0.1,
          fillToGraph: "average",
          lineColor: "#fec44f",
          type: "line",
          lineThickness: 1,
          dashLength: 2,
          id: "maximum",
          valueField: "maximum",
          valueAxis: "amount",
          clustered: true,
          connect: false
        }, {
          title: "Minimum",
          balloonFunction: function balloonFunction(item /*, graph*/) {
            return "Minimum " + "<strong>" + _accounting.default.formatMoney(item.dataContext["minimum"]) + "</strong> (" + item.dataContext["minimum_competitor"] + ")";
          },

          fillAlphas: 0.1,
          fillToGraph: "average",
          lineColor: "#fec44f",
          type: "line",
          lineThickness: 1,
          dashLength: 2,
          id: "minimum",
          valueField: "minimum",
          valueAxis: "amount",
          clustered: true,
          connect: false
        }, {
          title: "Average",
          balloonFunction: function balloonFunction(item /*, graph*/) {
            return "Competitor Average " + "<strong>" + _accounting.default.formatMoney(item.dataContext["average"]) + "</strong>";
          },

          fillAlphas: 0,
          lineColor: "#fec44f",
          type: "line",
          lineThickness: 2,
          id: "average",
          valueField: "average",
          valueAxis: "amount",
          clustered: true,
          connect: false
        }],
        valueAxes: [{
          id: "amount",
          color: "#999999",
          gridAlpha: 0.2,
          axisAlpha: 0,
          position: "left",
          labelFunction: function labelFunction(valueText /*, serialDataItem*/) {
            // TODO Use Customer currency code
            return _accounting.default.formatMoney(valueText, { precision: 0 });
          }
        }],
        guides: [{
          category: _moment.default.tz(this.get('tz.customer')).startOf('day').format(),
          lineColor: "#666666",
          color: "#999999",
          lineAlpha: 1,
          lineThickness: 2,
          boldLabel: true,
          dashLength: 5,
          inside: true,
          labelRotation: 90,
          label: "Today"
        }],
        legend: {
          divid: "competitor-rates-chart-legend",
          useGraphSettings: true,
          valueWidth: 0
        },
        allLabels: [],
        balloon: {},
        titles: [],
        zoomOutOnDataUpdate: true,
        dataProvider: dataProvider,
        export: {
          enabled: this.get('enableExport'),
          libs: { "autoLoad": false },
          fileName: this.get('i18n').t("customer.performance.rates.competitor-rates.chart_filename", { date: (0, _moment.default)().format() }),
          menu: [{
            class: "export-main",
            menu: [{
              label: this.get('i18n').t("charts.export_menu.download").toString(),
              menu: [{
                label: this.get('i18n').t("charts.export_menu.image").toString(),
                format: "PNG"
              }, "PDF"]
            }, {
              label: this.get('i18n').t("charts.export_menu.print").toString(),
              format: "PRINT"
            }, {
              label: this.get('i18n').t("charts.export_menu.cancel").toString(),
              action: "cancel"
            }]
          }]
        }

      };

      var chart = AmCharts.makeChart("competitor-rates-chart", config);

      this.set('_chart', chart);
    },


    dataRangeChanged: Ember.observer('dataProvider', function () {
      var chart = this.get('_chart');

      if (!chart) {
        return;
      }

      chart.dataProvider = this.get('dataProvider');
      chart.categoryAxis.guides = [{
        category: _moment.default.tz(this.get('tz.customer')).startOf('day').format(),
        lineColor: "#666666",
        color: "#999999",
        lineAlpha: 1,
        lineThickness: 2,
        boldLabel: true,
        dashLength: 5,
        inside: true,
        labelRotation: 90,
        label: "Today"
      }];

      Ember.run.scheduleOnce('afterRender', this, function () {
        chart.validateData();
      }, 0);
    })
  });
});