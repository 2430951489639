define('ux-pcc/components/json-viewer/array-node', ['exports', 'ux-components/components/json-viewer/array-node'], function (exports, _arrayNode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _arrayNode.default;
    }
  });
});