define('ux-pcc/components/channel/restatus-panel', ['exports', 'moment', 'ux-components/utils/to-boolean'], function (exports, _moment, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    customer: null,
    channel: null,

    model: null,

    beginDate: (0, _moment.default)().format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().format("YYYY-MM-DD"),
    resyncOption: '',
    selectedProducts: [],
    selectedPrices: [],
    checkingPrevRequests: true,
    submitting: false,

    isMobile: Ember.computed.bool('deviceType.mobile'),

    maxRange: Ember.computed.alias('model.range'),

    detailTab: 're-status',

    init: function init() {
      this._super.apply(this, arguments);

      this.loadData();
    },


    dataLoadObserver: Ember.observer('customer.id', 'channel.id', function () {
      this.loadData();
    }),

    loadData: function loadData() {
      var _this = this;

      var isProperty = this.get('customer.customerType') === 'CUSTOMER';
      var restatusAllowed = false;
      var autoProvisionEnabled = false;
      var range = 365;
      var restatusOptions = [];

      this.set('submitting', false);
      this.set('checkingPrevRequests', true);

      if (isProperty && this.get('channel')) {
        // Grab the effective channel parameters so you can grab the list of allowed channels for restatus
        this.store.query('channel-parameter', {
          channelID: this.get('channel.id'),
          customerID: this.get('customer.id'),
          effective: true
        }).then(function () {
          var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          var sirParameter = results.findBy('parameterName', 'CHANNEL_INTERFACE_RESTATUS');
          if (sirParameter && sirParameter.get('parameterValue')) {
            var restatusChannels = sirParameter.get('parameterValue').split(',');
            if (restatusChannels.indexOf(_this.get('channel.channelCode').toUpperCase()) >= 0) {
              restatusAllowed = true;
            }
          }

          var apParameter = results.findBy('parameterName', 'AUTO_PROVISION_ENABLE');
          if (apParameter && apParameter.get('parameterValue')) {
            autoProvisionEnabled = (0, _toBoolean.default)(apParameter.get('parameterValue'));
          }

          var rangeParameter = results.findBy('parameterName', 'CHANNEL_INTERFACE_RESTATUS_MAX_RANGE');
          if (rangeParameter && rangeParameter.get('parameterValue')) {
            range = parseInt(rangeParameter.get('parameterValue'));
          }

          var roParameter = results.findBy('parameterName', 'CHANNEL_INTERFACE_RESTATUS_OPTIONS');
          if (roParameter && roParameter.get('parameterValue')) {
            restatusOptions = roParameter.get('parameterValue').split(',');
          }

          return Ember.RSVP.hash({
            restatusAllowed: restatusAllowed,
            autoProvisionEnabled: autoProvisionEnabled,
            range: range,
            restatusOptions: restatusOptions,
            products: _this.get('ajax').request('/rest/v1/channels/customerConversions', {
              data: {
                customerID: _this.get('customer.id'),
                channelCode: _this.get('channel.channelCode').toUpperCase(),
                conversionType: 'PRODUCT'
              }
            }).then(function (_ref) {
              var _ref$products = _ref.products,
                  products = _ref$products === undefined ? [] : _ref$products;

              return products;
            }),
            prices: _this.get('ajax').request('/rest/v1/channels/customerConversions', {
              data: {
                customerID: _this.get('customer.id'),
                channelCode: _this.get('channel.channelCode').toUpperCase(),
                conversionType: 'PRICE'
              }
            }).then(function (_ref2) {
              var _ref2$prices = _ref2.prices,
                  prices = _ref2$prices === undefined ? [] : _ref2$prices;

              return prices;
            })
          });
        }).then(function (results) {
          _this.set('model', results);
        });
      } else {
        return Ember.RSVP.hash({
          restatusAllowed: restatusAllowed,
          autoProvisionEnabled: autoProvisionEnabled,
          maxRange: range,
          restatusOptions: restatusOptions,
          products: [],
          prices: []
        }).then(function (results) {
          _this.set('model', results);
        });
      }
    },
    _manageAutoProvisioning: function _manageAutoProvisioning() {
      var _this2 = this;

      var status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Check';

      this.set('loadingChecklist', true);
      this.ajax.request('/rest/v1/channels/manageChannelProvisioning', {
        data: {
          customerID: this.get('customer.id'),
          channelCode: this.get('channel.channelCode'),
          status: status
        }
      }).then(function (_ref3) {
        var autoProvisionResponse = _ref3.autoProvisionResponse;

        console.log(autoProvisionResponse);
        _this2.set('autoProvisionResponse', autoProvisionResponse);
      }).catch(function (error) {
        console.error(error);
      }).finally(function () {
        _this2.set('loadingChecklist', false);
      });
    },


    _channel: Ember.observer('channel.channelCode', 'customer.id', 'model.autoProvisionEnabled', function () {
      if (this.get('model.autoProvisionEnabled')) {
        this._manageAutoProvisioning('Check');
      }
    }),

    minEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate')).startOf('day');
    }),

    maxEndDate: Ember.computed('beginDate', 'maxRange', function () {
      return (0, _moment.default)(this.get('beginDate')).add(this.get('maxRange'), 'days');
    }),

    restatusInProgress: Ember.computed('prevRequests', function () {
      var rtnVal = false;
      var alerts = this.get('prevRequests');
      if (alerts && alerts.length > 0) {
        var alert = alerts[0];
        switch (alert.status) {
          case 'PENDING':
          case 'PROCESS':
            {
              rtnVal = true;
              break;
            }
          case 'PENDING_CHILD':
            {
              // If there are any pending children, disable restatus submissions
              var children = alerts.filterBy('parentID', alert.id);
              var pendingChildCount = (children || []).filterBy('status', 'PENDING');
              if (pendingChildCount.length > 0) {
                rtnVal = true;
              }
              break;
            }
          default:
            {
              rtnVal = false;
              break;
            }
        }
      }

      return rtnVal;
    }),

    prevRequests: null,

    _getType: function _getType(type) {
      switch (type) {
        case 'INVENTORY':
          return this.i18n.t('channel-restatus.inventory');
        case 'PRICE_AMOUNT':
          return this.i18n.t('channel-restatus.price_amounts');
        case 'RESTRICTION':
          return this.i18n.t('channel-restatus.restrictions');
        case 'RESERVATION':
          return this.i18n.t('channel-restatus.reservations');
        case 'CUSTOMER':
          return this.i18n.t('channel-restatus.customer');
        case 'CUSTOMER_BATCH':
          return this.i18n.t('channel-restatus.customer_batch');
      }
      return this.i18n.t('channel-restatus.all');
    },
    _getLastRestatus: function _getLastRestatus() {
      var _this3 = this;

      this.get('ajax').request('/rest/v1/channels/lastRestatus', {
        data: {
          customerID: this.get('customer.id'),
          channelCode: this.get('channel.channelCode').toUpperCase()
        }
      }).then(function (_ref4) {
        var _ref4$alerts = _ref4.alerts,
            alerts = _ref4$alerts === undefined ? [] : _ref4$alerts;


        var getStatus = function getStatus(status) {
          switch (status) {
            case 'OK':
              return _this3.i18n.t('channel-restatus.completed');
            case 'ERROR':
              return _this3.i18n.t('channel-restatus.failed');
            case 'PENDING':
            case 'PENDING_TASK':
            case 'PENDING_PARENT':
            case 'PENDING_CHILD':
              return _this3.i18n.t('channel-restatus.pending');
            case 'CANCELED':
              return _this3.i18n.t('channel-restatus.canceled');
            default:
              return _this3.i18n.t('channel-restatus.other');
          }
        };

        if (alerts && alerts.filterBy('actionCode', 'RESTATUS_INTERFACE').length > 0) {
          var restatusAlerts = alerts.filterBy('actionCode', 'RESTATUS_INTERFACE');
          var prevRequests = [];
          restatusAlerts.forEach(function (alert) {
            try {
              var payload = JSON.parse(alert.payload);
              var children = alerts.filterBy('parentID', alert.id);
              var failedChildCount = (children || []).filter(function (child) {
                return child.status === 'FAILED' || child.status === 'ERROR';
              });
              prevRequests.push({
                status: alert.status,
                statusClass: failedChildCount.length > 0 ? 'status-error' : '',
                txStatus: failedChildCount.length > 0 ? getStatus('ERROR') + ' - ' + _this3.i18n.t('channel-restatus.error_count', { count: failedChildCount.length }) : getStatus(alert.status),
                requestedDate: _moment.default.tz(alert.creationDate, _this3.tz.get('customer')).format(_this3.get('isMobile') ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'),
                beginDate: payload.beginDate,
                endDate: payload.endDate,
                type: _this3._getType(payload.restatusType),
                referenceValue: alert.referenceValue,
                children: children
              });
            } catch (e) {
              // payload not in a json format, so don't include it
            }
          });

          if (prevRequests.length > 0) {
            _this3.set('prevRequests', prevRequests);
          }
        }
      }).finally(function () {
        _this3.set('checkingPrevRequests', false);
      });
    },


    _prevRequestsObserver: Ember.observer('model.restatusAllowed', 'recheck', function () {

      // Clear the existing records
      this.set('prevRequests', null);

      this.set('checkingPrevRequests', true);

      if (this.get('model.restatusAllowed')) {
        Ember.run.once(this, '_getLastRestatus');
      }
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: (0, _moment.default)().startOf('day')
      };
    }),

    showRestatus: Ember.computed.alias('restatusAllowed'),

    beginDateObserver: Ember.observer('beginDate', function () {
      var tz = this.get('tz.customer');
      var beginDate = _moment.default.tz(this.get('beginDate'), tz);
      var endDate = _moment.default.tz(this.get('endDate'), tz);

      if (endDate && endDate.isBefore(beginDate)) {
        this.set('endDate', (0, _moment.default)(this.get('beginDate')));
      }
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate'),
        maxDate: this.get('maxEndDate')
      };
    }),

    availableResyncOptions: Ember.computed(function () {
      return [{ value: 'INVENTORY', label: this.i18n.t('channel-restatus.inventory') }, { value: 'PRICE_AMOUNT', label: this.i18n.t('channel-restatus.price_amounts') }, { value: 'RESTRICTION', label: this.i18n.t('channel-restatus.restrictions') }, { value: 'RESERVATION', label: this.i18n.t('channel-restatus.reservations') }, { value: 'PRODUCT', label: this.i18n.t('channel-restatus.products') }, { value: 'PRICE_HEADER', label: this.i18n.t('channel-restatus.price_headers') }];
    }),

    resyncOptions: Ember.computed('availableResyncOptions', 'model.restatusOptions', function () {

      var availableOptions = this.get('model.restatusOptions') || [];
      var resyncOptions = this.get('availableResyncOptions');

      return availableOptions.map(function (option) {
        return resyncOptions.findBy('value', option);
      });
    }),

    _resyncOptionObserver: Ember.observer('resyncOption', function () {

      var option = this.get('resyncOption');

      if (option !== 'INVENTORY' && option !== 'PRODUCT') {
        this.set('selectedProducts', []);
      }

      if (option !== 'PRICE_AMOUNT' && option !== 'PRICE_HEADER') {
        this.set('selectedPrices', []);
      }
    }),

    actions: {
      refreshHistory: function refreshHistory() {
        // Clear the existing records
        this.set('prevRequests', null);
        this.set('checkingPrevRequests', true);
        this._getLastRestatus();
      },
      submitRestatus: function submitRestatus() {
        var _this4 = this;

        this.set('submitting', true);
        var option = this.get('resyncOption');
        var beginDate = _moment.default.tz(this.get('beginDate'), this.tz.get('customer')).format('YYYY-MM-DD');
        var endDate = _moment.default.tz(this.get('endDate'), this.tz.get('customer')).format('YYYY-MM-DD');

        var params = {
          customerID: this.get('customer.id'),
          channelCode: this.get('channel.channelCode'),
          changeType: option,
          begindate: beginDate,
          enddate: endDate,
          force: true
        };

        if ((option === 'INVENTORY' || option === 'PRODUCT') && this.get('selectedProducts').length > 0) {
          params.productID = this.get('selectedProducts').toString();
        }

        if ((option === 'PRICE_AMOUNT' || option === 'PRICE_HEADER') && this.get('selectedPrices').length > 0) {
          params.priceID = this.get('selectedPrices').toString();
        }

        this.get('ajax').request('/rest/v1/channels/restatusInterface', {
          data: params
        }).then(function (response) {
          // Success!
          var submittedAlerts = response.alerts;
          var restatusAlert = submittedAlerts.findBy('actionCode', 'RESTATUS_INTERFACE');

          // Add this request to the list of records
          var alerts = [{
            status: 'PENDING',
            txStatus: _this4.i18n.t('channel-restatus.pending'),
            requestedDate: _moment.default.tz((0, _moment.default)(), _this4.tz.get('customer')).format('YYYY-MM-DD HH:mm:ss'),
            beginDate: beginDate,
            endDate: endDate,
            type: _this4._getType(option),
            children: submittedAlerts.filterBy('parentID', restatusAlert.id)
          }];

          var prevRequests = _this4.get('prevRequests');
          if (prevRequests && prevRequests.length > 0) {
            alerts = alerts.concat(prevRequests);
          }
          _this4.set('prevRequests', alerts);

          _this4.get('flash')('<i class=\'fa fa-thumbs-up\'></i> ' + _this4.i18n.t('channel-restatus.success', { channel: _this4.get('channel.shortDescription') }), 'success');
        }, function () {
          _this4.get('flash')('<i class=\'fa fa-warning\'></i> ' + _this4.i18n.t('channel-restatus.failure', { channel: _this4.get('channel.channelName') }), 'danger');
        }).finally(function () {
          _this4.set('submitting', false);
        });
      },
      selectProduct: function selectProduct(event) {
        var selectedProducts = Ember.$(event.target).val();
        this.set('selectedProducts', selectedProducts || []);
      },
      selectPrice: function selectPrice() {
        var selectedPrices = Ember.$(event.target).val();
        this.set('selectedPrices', selectedPrices || []);
      },
      refresh: function refresh() {
        // trigger a recheck of pending restatus
        this.notifyPropertyChange('recheck');
      },
      setTab: function setTab(tab) {
        this.set('detailTab', tab);
      },
      autoProvisionOpen: function autoProvisionOpen() {
        this._manageAutoProvisioning('Open');
      },
      autoProvisionClose: function autoProvisionClose() {
        this._manageAutoProvisioning('Close');
      }
    }

  });
});