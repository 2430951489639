define('ux-pcc/components/no-data-jumbotron', ['exports', 'ux-components/components/no-data-jumbotron'], function (exports, _noDataJumbotron) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _noDataJumbotron.default;
    }
  });
});