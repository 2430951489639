define('ux-pcc/routes/yield-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var _this = this;

      var customer = this.modelFor('customer');
      var settings = customer.get('settings').toArray() || [];

      var blueprintCustomerID = Ember.get(settings.findBy('settingCode', 'CUSTOMER_BLUEPRINT') || {}, 'settingValue');

      var subchannels = [];
      var channels = [];
      var channelRequest = null;

      channelRequest = this.get('store').query('code', {
        customerID: customer.get('id'),
        codeType: 'ADVANCED_ANALYTICS_FILTER_CHANNEL'
      }).then(function (results) {
        var promises = [];
        results.reject(function (code) {
          var shortDescription = code.get('shortDescription').toUpperCase();
          return shortDescription.indexOf('EXCLUDE') === 0;
        }).forEach(function (c) {
          promises.addObject(_this.store.queryRecord('channel', { customerID: customer.get('id'), channelCode: c.get('codeValue') }));
        });

        return Ember.RSVP.allSettled(promises).then(function () {
          var results = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          results.forEach(function (result) {
            channels.push(result.value);
            _this._fetchChildren(customer, result.value, subchannels);
          });
          return channels;
        });
      });

      return Ember.RSVP.hash({
        reportingTimePeriods: this.get('store').query('code', {
          customerID: customer.get('id'),
          codeType: 'PRESET_DATE_SPAN'
        }),
        channels: channelRequest,
        subchannels: subchannels,
        // grab the products and map the car group hierarchy for the current customer we are on
        productGroups: blueprintCustomerID ? this.get('store').query('product', { customerID: blueprintCustomerID }) : [],
        prices: this.get('store').query('price', { customerID: customer.get('id') }),
        brands: this.get('store').query('code', { customerID: customer.get('id'), codeType: 'BRAND', systemYN: 'Y' }).then(function () {
          var codes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          return codes.sortBy('orderBy');
        })
        // ysgaDestinationStati:this.get('store').query('code', {
        //   customerID: customer.get('id'),
        //   codeType: 'ADVANCED_ANALYTICS_FILTER_YSGA_RESERVATION_TYPE'
        // }).then((results)=>{
        //   return results.reject((code) => {
        //     let shortDescription = code.get('shortDescription').toUpperCase();
        //     return shortDescription.indexOf('EXCLUDE') === 0;
        //   }).sortBy('orderBy');
        // }),
        // ticketDestinationStati: this.get('store').query('code', {
        //   customerID: customer.get('id'),
        //   codeType: 'ADVANCED_ANALYTICS_FILTER_TICKET_RESERVATION_TYPE'
        // }).then((results)=>{
        //   return results.reject((code) => {
        //     let shortDescription = code.get('shortDescription').toUpperCase();
        //     return shortDescription.indexOf('EXCLUDE') === 0;
        //   }).sortBy('orderBy');
        // }),
        // baseDestinationStati:  this.get('store').query('code', {
        //   customerID: customer.get('id'),
        //   codeType: 'ADVANCED_ANALYTICS_FILTER_RESERVATION_TYPE'
        // }).then((results)=>{
        //   return results.reject((code) => {
        //     let shortDescription = code.get('shortDescription').toUpperCase();
        //     return shortDescription.indexOf('EXCLUDE') === 0;
        //   }).sortBy('orderBy');
        // })
      });
    },
    _fetchChildren: function _fetchChildren(customer, channel, array) {
      this.get('store').query('channel', {
        customerID: customer.get('id'),
        parentChannelCode: channel.get('channelCode')
      }).then(function (results) {
        return results.forEach(function (c) {
          return array.push(c);
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('reportingTimePeriods', model.reportingTimePeriods.sortBy('orderBy'));
      controller.set('channels', model.channels);
      controller.set('subchannels', model.subchannels);
      controller.set('productGroups', model.productGroups);
      controller.set('prices', model.prices);
      controller.set('brands', model.brands);
      // controller.set('destinationStatuses', {
      //   ysga: model.ysgaDestinationStati,
      //   tickets: model.ticketDestinationStati,
      //   base: model.baseDestinationStati
      // });
    }
  });
});