define('ux-pcc/helpers/substring', ['exports', 'ux-components/helpers/substring'], function (exports, _substring) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _substring.default;
    }
  });
  Object.defineProperty(exports, 'substring', {
    enumerable: true,
    get: function () {
      return _substring.substring;
    }
  });
});