define('ux-pcc/components/rule-advanced/rule-filter-value-rule', ['exports', 'ember-changeset', 'ember-changeset-validations'], function (exports, _emberChangeset, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        // filterValueRule: validatePresence({
        //   presence: true,
        //   message: this.i18n.t('rule._form_modal.no_value').toString()
        // })
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    })

  });
});