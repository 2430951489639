define('ux-pcc/controllers/customer/performance/rates', ['exports', 'moment', 'ux-components/utils/to-boolean', 'ux-pcc/mixins/customer/performance/span-selectable'], function (exports, _moment, _toBoolean, _spanSelectable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_spanSelectable.default, {

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    i18n: Ember.inject.service(),
    permission: Ember.inject.service(),

    isMobile: Ember.computed.equal('model.timespan', 1),
    isNonMobile: Ember.computed.gt('model.timespan', 1),

    currentSlice: null,

    manualRatesEnabled: Ember.computed(function () {
      return this.get('permission').isPermitted("competitors", "rates", "edit");
    }),

    currencyCode: Ember.computed("customer.settings.[]", function () {
      var settings = this.get("customer.settings").findBy("settingCode", "DEFAULT_CURRENCY");
      if (settings && settings.get("settingValue")) {
        return settings.get("settingValue");
      } else {
        return "USD";
      }
    }),

    useFixedColumn: Ember.computed('isNonMobile', 'tableDataProvider.length', function () {
      return this.get('isNonMobile') && this.get('tableDataProvider.length') > 1;
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    competitorLoadDate: Ember.computed('customer.udfValues', function () {
      if (this.get('customer.udfValues') && this.get('customer.udfValues').hasOwnProperty('TCLK_LOAD_DATE')) {
        var tz = this.tz.get("customer");
        return (0, _moment.default)(this.get('customer.udfValues')["TCLK_LOAD_DATE"]).tz(tz).format('lll');
      } else {
        return null;
      }
    }),

    // Need for hidden chart
    dataProvider: Ember.computed('analytics.[]', function () {
      var _this = this;

      var entries = this.get('analytics');
      var tz = this.tz.get("customer");

      var mapped = {};
      var range = this.get('range');
      // Ensure days for each in range
      range.forEach(function (m) {
        mapped[m.tz(tz).format()] = { slice: m.tz(tz).format() };
      });

      entries.forEach(function (e) {
        var m = Ember.get(e, 'beginDate');
        var key = _moment.default.tz(m, tz).format();

        var map = mapped[key];
        if (map) {
          switch (Ember.get(e, 'contentType')) {
            case "SELL_RATE":
              if (Ember.get(e, 'subContentType') === "COMPETITOR") {
                map['competitor'] = map['competitor'] || [];
                map['competitor'].push({
                  label: _this.toTitleCase(decodeURIComponent(Ember.get(e, 'description').replace(/\+/g, ' '))),
                  value: parseFloat(Ember.get(e, 'dataValue'))
                });
              } else {
                map['public'] = parseFloat(Ember.get(e, 'dataValue'));
              }
              break;
          }
        }
      });

      return Object.keys(mapped).map(function (key) {
        var map = mapped[key];

        if (map['competitor'] && map['competitor'] instanceof Array) {
          var filtered = map['competitor'].mapBy('value').filter(function (v) {
            return v;
          });
          map['minimum'] = filtered.length > 0 ? Math.min.apply(Math, filtered) : NaN;
          map['maximum'] = filtered.length > 0 ? Math.max.apply(Math, filtered) : NaN;
          map['average'] = filtered.reduce(function (ac, v) {
            return ac + v;
          }, 0) / filtered.length;

          map['minimum_competitor'] = map['competitor'].find(function (c) {
            return c.value.toString() === map['minimum'].toString();
          }).label;

          map['maximum_competitor'] = map['competitor'].find(function (c) {
            return c.value.toString() === map['maximum'].toString();
          }).label;
        }

        return map;
      });
    }),

    hideCompetitors: Ember.computed('customer.settings.[]', function () {
      var hideCompetitorsSetting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.HIDE_PERFORMANCE_COMPETITORS') || {};
      return (0, _toBoolean.default)(Ember.get(hideCompetitorsSetting, 'settingValue'));
    }),

    manualCompetitors: Ember.computed.alias('model.manualCompetitors'),
    analytics: Ember.computed.alias('model.analytics'),

    tableDataProvider: Ember.computed('manualCompetitors.@each.{id,name,dataValue,dataValues}', 'analytics.@each.{type,contentType,beginDate,dataValue,dataValues}', 'hideCompetitors', function () {
      var _this2 = this;

      var entries = this.get('analytics');
      var manualCompetitors = this.get('manualCompetitors');
      var tz = this.tz.customer;

      var hideCompetitors = this.get('hideCompetitors');

      var competitors = {};

      entries.forEach(function (e) {
        var subContentType = e.subContentType;
        var contentType = e.contentType;
        var competitorId = subContentType === 'COMPETITOR' ? e.groupByValue : 'public';

        // Set the list of competitors from the analytics
        if (!competitors[competitorId]) {
          var propertyName = subContentType === 'PUBLIC' ? '' + _this2.i18n.t('shopped-competitors.rates.my_property') : decodeURIComponent(e.description).replace(/\+/g, ' ');

          if (hideCompetitors && subContentType === 'COMPETITOR' && contentType !== 'UNPUBLISHED_RATE') return;

          competitors[competitorId] = {
            id: competitorId,
            name: _this2.toTitleCase(propertyName),
            type: subContentType,
            rates: {},
            shoppedRates: {},
            shopped: false,
            imported: true
          };
        }

        var competitor = competitors[competitorId];
        var m = e.beginDate;
        var key = _moment.default.tz(m, tz).format();

        if (competitor.type === 'PUBLIC' || e.contentType === 'SELL_RATE') {
          competitor.rates[key] = e.dataValue;
        } else {
          competitor.shopped = true;
          competitor.shoppedRates[key] = e.dataValues;
        }
      });

      // Add any missing manual competitors so that rates can be shopped, or replace the competitor name
      manualCompetitors.forEach(function (c) {
        var competitorId = c.get('id');
        var name = decodeURIComponent(c.get('name').replace(/\+/g, ' '));
        if (!competitors[competitorId]) {
          competitors[competitorId] = {
            classNames: 'manual-competitor__header',
            id: competitorId,
            name: name,
            type: 'COMPETITOR',
            rates: {},
            shoppedRates: {},
            shopped: true,
            imported: false
          };
        } else {
          // The property name is stored with the analytic at the time of their creation, but need to keep the manual competitor names synced, so use what is stored
          // with the competitor
          competitors[competitorId].name = name;
          competitors[competitorId].imported = false;
          competitors[competitorId].classNames = 'manual-competitor__header';
        }
      });

      var tableData = Object.keys(competitors).map(function (key) {
        return competitors[key];
      });

      // Sort by name, and have the imported competitors come first
      return tableData.sortBy('name').sort(function (a, b) {
        return b.imported && !a.imported;
      });
    }),

    ratesTableDataPopulated: Ember.computed('ratesTableData.groups.firstObject.rows.length', function () {
      return this.get('ratesTableData.groups.firstObject.rows.length') > 0;
    }),

    ratesTableData: Ember.computed('tableDataProvider.@each.{id,name,type,dataValues,shoppedRates}', function () {
      // Get columns for data
      var range = this.get('range');
      var tz = this.tz.get("customer");
      // Pivot the table for non-mobile display
      var pivot = this.get('isNonMobile');

      var groupLabel = pivot ? '<div>' + this.get('i18n').t('shopped-competitors.rates.arrival') + '</div><div>' + this.get('i18n').t('shopped-competitors.rates.date') + '</div>' : this.get('i18n').t('shopped-competitors.rates.my_property');

      var columns = [];
      var map = {};

      // Build the data for table
      var competitors = this.get('tableDataProvider');

      if (competitors && competitors.length > 0) {

        // Setup the columns for each competitor
        var myProperty = competitors.findBy('id', 'public');
        // If you didn't find rates for this property, manufacture a public property
        if (!myProperty) {
          myProperty = {
            id: 'public',
            imported: false,
            name: "",
            rates: {},
            shopped: false,
            shoppedRates: {},
            type: 'PUBLIC'
          };
        }
        myProperty.name = this.get('i18n').t('shopped-competitors.rates.my') + ' ' + this.get('i18n').t('shopped-competitors.rates.property');
        columns.push(myProperty);

        competitors.forEach(function (e) {
          if (e.type.toLowerCase() !== 'public') {
            columns.push(e);
          }
        });
      }

      var stripeRowIndex = 1;
      var rows = [];
      if (pivot) {

        // Build the data for table
        if (competitors && competitors.length > 0) {
          // Loop through all the days in the range
          range.forEach(function (m) {
            var formatted = _moment.default.tz(m, tz).format();
            var dow = _moment.default.tz(m, tz).startOf('day').format('ddd');
            var date = _moment.default.tz(m, tz).startOf('day').format('L');
            date = date.substring(0, date.length - 4) + date.substring(date.length - 2);
            var key = '<span class="date-cell"><span class="day-of-week">' + dow + ',</span> ' + date + '</span>';

            map[formatted] = map[formatted] || { date: formatted, label: key };
          });
        }

        rows = Object.keys(map).map(function (key) {
          return {
            key: key,
            label: map[key].label,
            rateKey: map[key].rateKey,
            class: stripeRowIndex++ % 2 === 0 ? 'striped-row' : ''
          };
        });
      } else {
        // Use mobile format
        competitors.forEach(function (competitor) {
          map[competitor.id] = { label: competitor.name, rateKey: range.get('firstObject').tz(tz).format() };
          rows.push({
            key: competitor.id,
            label: competitor.name,
            rateKey: range.get('firstObject').tz(tz).format(),
            class: stripeRowIndex++ % 2 === 0 ? 'striped-row' : '',
            labelClasses: competitor.classNames
          });
        });
      }
      return {
        rows: rows,
        groups: [{ label: groupLabel, classNames: ['active'], rows: rows }],
        columns: columns
      };
    }),

    toTitleCase: function toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },


    actions: {
      openModal: function openModal(name, model) {
        this.send('showModal', name, model);
      }
    }

  });
});