define("ux-pcc/utils/chart-date-label", ["exports", "moment"], function (exports, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = chartDateLabel;
    function chartDateLabel(valueText, tz, index, numOfItems) {

        var m = (0, _moment.default)(valueText).tz(tz);
        var month = m.format("MMM");
        var day = m.format("DD");

        // For high number of items, just show the day over the month
        if (numOfItems && numOfItems > 15) {
            return day + "\n\r" + month;
        } else {
            var value = "";
            // Show the month at the beginning of the chart
            if (index === 0) {
                value = month;
            } else if (day === "01") {
                // Or on the first day of the month
                value = month;
            } else {
                // should the day
                value = day;
            }
            return value + "\n\r" + m.format("ddd");
        }
    }
});