define('ux-pcc/utils/serialize-dashboard-view', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = serializeDashboardView;
  function serializeDashboardView(inView, tz) {

    // Serialize it, so we can reformat the dates without modifying the original
    var view = JSON.parse(JSON.stringify(typeof inView.serialize === 'function' ? inView.serialize() : inView));

    // PRODUCT_CODE should be the lowest levels selected, so if there are selections in the
    // SUB_PRODUCT_CODE field, use those to Overwrite the PRODUCT_CODE
    var subProductParam = view.dashboardViewParameters.SUB_PRODUCT_CODE;
    if (subProductParam && !Ember.isEmpty(subProductParam)) {
      Ember.set(view.dashboardViewParameters, 'PRODUCT_CODE', subProductParam);
    }

    var dataTypeParam = view.dashboardViewParameters.DATA_TYPE;

    if (dataTypeParam === 'YSGA') {
      // For all YSGA calls, set the LEVEL_HIERARCHY to LEVEL_EXACT
      var reservationTypeParam = view.dashboardViewParameters.LEVEL_HIERARCHY;
      if (reservationTypeParam !== 'LEVEL_EXACT') {
        Ember.set(view.dashboardViewParameters, 'LEVEL_HIERARCHY', 'LEVEL_EXACT');
      }

      // set AMOUNT to be TOTAL_AMOUNT or TOTAL_AMOUNT_FROM_PRICE
      if (view.dashboardViewParameters.TM_VS_GROSS === 'GROSS') {
        Ember.set(view.dashboardViewParameters, 'AMOUNT_MODE', 'TOTAL_AMOUNT');
      } else {
        Ember.set(view.dashboardViewParameters, 'AMOUNT_MODE', 'TOTAL_AMOUNT_FROM_PRICE');
      }
    }

    // if the date compare is MONTH (aka Financial), pass CUSTOM to the backend
    if (view.dashboardViewParameters.DATE_COMPARE === 'MONTH') {
      Ember.set(view.dashboardViewParameters, 'DATE_COMPARE', 'CUSTOM');
    }

    // Set the TIME_ZONE, if not already set
    var timezoneParam = view.dashboardViewParameters.TIME_ZONE;
    if (Ember.isEmpty(timezoneParam)) {
      Ember.set(view.dashboardViewParameters, 'TIME_ZONE', _moment.default.tz.guess(true));
    }

    // ES-1385: For SOURCE_LOCATION_CODE = TICKET, and DESTINATION_STATUS = WALKUP, update the values
    // so the backend works correctly
    var sourceLocationCodeParam = view.dashboardViewParameters.SOURCE_LOCATION_CODE;
    if ('TICKET' === sourceLocationCodeParam) {
      var destinationStatusParam = view.dashboardViewParameters.DESTINATION_STATUS;
      if ('WALKUP' === destinationStatusParam) {
        Ember.set(view.dashboardViewParameters, 'SOURCE_LOCATION_CODE', 'WALKUP');
        Ember.set(view.dashboardViewParameters, 'DESTINATION_STATUS', 'BOOK');
      } else if (destinationStatusParam.indexOf('CANCEL') >= 0) {
        var destinations = destinationStatusParam.split('|');
        destinations.splice(destinations.indexOf('CANCEL'), 1);
        Ember.set(view.dashboardViewParameters, 'DESTINATION_STATUS', destinations.join('|'));
      }
    }

    // Convert dashboardViewParameters to an array for the backend for now
    var dashboardViewParameterList = Object.keys(view.dashboardViewParameters).map(function (key) {
      var value = view.dashboardViewParameters[key];
      // Clear any values that are set to all
      return { name: key, value: value === 'ALL' ? '' : value };
    });

    view.dashboardViewParameters = dashboardViewParameterList;

    var adjustDateParams = ['BEGIN_DATE', 'END_DATE', 'STLY_BEGIN_DATE', 'STLY_END_DATE', 'STLW_BEGIN_DATE', 'STLW_END_DATE', 'BOOK_DATE', 'STLY_BOOK_DATE', 'STLW_BOOK_DATE'];

    adjustDateParams.forEach(function (key) {
      var param = view.dashboardViewParameters.findBy('name', key);
      if (param) {
        if (Ember.isEmpty(param.value)) {
          view.dashboardViewParameters = view.dashboardViewParameters.rejectBy('name', key);
        } else {
          Ember.set(param, 'value', _moment.default.tz(param.value, tz).format('YYYY-MM-DD'));
        }
      }
    });

    view.dashboardViewParameters = view.dashboardViewParameters.rejectBy('value', null);

    return JSON.stringify(view);
  }
});