define('ux-pcc/controllers/portfolio/-add-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    dialog: Ember.inject.service(),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sort', ['externalReference']);
    },


    addedProperties: Ember.A(),

    actions: {
      itemSelected: function itemSelected(item) {
        var _this = this;

        if (item) {
          this.store.findRecord('customer', item.id).then(function (customer) {
            _this.get('addedProperties').addObject(customer);
          });
        }
      },
      remove: function remove(customer) {
        this.get('addedProperties').removeObject(customer);
      },
      save: function save() {
        var _this2 = this;

        // Add properties to list
        var properties = this.get('addedProperties');
        var model = this.get('model');

        // Save property xrefs
        var promises = properties.map(function (property) {
          var xref = _this2.store.createRecord('customer-xref', {
            customer: model,
            xrefCustomer: property,
            xrefType: 'LINK'
          });
          return xref.save();
        });

        this.set('loading', true);

        Ember.RSVP.all(promises).then(function () {

          // manually update the xrefs and children
          model.get('xrefs').addObjects(properties);
          model.get('children').addObjects(properties);

          _this2.set('addedProperties', Ember.A());

          _this2.send('close');

          var message = properties.length > 1 ? _this2.i18n.t('portfolios.list.link_success_modal.success_message_multiple', { count: properties.length }) : _this2.i18n.t('portfolios.list.link_success_modal.success_message', { propertyName: properties.get('firstObject.name') });

          // Show the success modal
          _this2.dialog.showSuccess({
            titleText: _this2.i18n.t('portfolios.list.unlink_success_modal.title'),
            messageText: message,
            confirmText: _this2.i18n.t('portfolios.list.unlink_success_modal.button_label'),
            onConfirm: function onConfirm() {
              // this.refresh();
              _this2.dialog.close();
            }
          });
        }).catch(function (error) {
          _this2.send('flash', "<i class='fa fa-warning'></i> " + (error.responseText || error.status + " " + error.statusText), 'danger');
        }).finally(function () {
          _this2.set('loading', false);
        });
      },
      closeAndClear: function closeAndClear() {
        this.set('addedProperties', Ember.A());

        this.send('close');
        this.send('removeModal');
      },
      close: function close() {
        this.send('removeModal');
      }
    }
  });
});