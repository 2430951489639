define('ux-pcc/controllers/deep-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['targetRoute', 'customerID', 'modelID', 'qp']

  });
});