define('ux-pcc/utils/date-formatter', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateFormatter = dateFormatter;
  function dateFormatter(date) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$settings = _ref.settings,
        settings = _ref$settings === undefined ? [] : _ref$settings,
        _ref$day = _ref.day,
        day = _ref$day === undefined ? true : _ref$day,
        _ref$month = _ref.month,
        month = _ref$month === undefined ? true : _ref$month,
        _ref$year = _ref.year,
        year = _ref$year === undefined ? true : _ref$year,
        separator = _ref.separator,
        dayFormat = _ref.dayFormat,
        monthFormat = _ref.monthFormat,
        yearFormat = _ref.yearFormat,
        returnFormat = _ref.returnFormat,
        tz = _ref.tz;

    var setting = settings.findBy('settingCode', 'PCC.DATE_FORMATTER_SETTING');

    if (setting) {
      setting = setting.get('settingValue');
    }

    var dateFormatSetting = setting ? JSON.parse(setting) : { separator: '-', order: [{ day: 'DD' }, { month: 'MMM' }, { year: 'YYYY' }] };

    if (dayFormat) {
      dateFormatSetting.order.find(function (value) {
        return value.day;
      }).day = dayFormat;
    }

    if (monthFormat) {
      dateFormatSetting.order.find(function (value) {
        return value.month;
      }).month = monthFormat;
    }

    if (yearFormat) {
      dateFormatSetting.order.find(function (value) {
        return value.year;
      }).year = yearFormat;
    }

    var format = dateFormatSetting.order.map(function (e) {
      if (e.day && day) {
        return dayFormat || e.day;
      } else if (e.month && month) {
        return monthFormat || e.month;
      } else if (e.year && year) {
        return yearFormat || e.year;
      }
    }).compact().join(separator || dateFormatSetting.separator);

    if (typeof date === 'string') {
      date = tz ? _moment.default.tz(date, tz) : (0, _moment.default)(date);
    }

    if (returnFormat) {
      return format;
    } else {
      return tz ? _moment.default.tz(date, tz).format(format) : date.format(format);
    }
  }
});