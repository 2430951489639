define('ux-pcc/helpers/is-selected', ['exports', 'ux-components/helpers/is-selected'], function (exports, _isSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _isSelected.default;
    }
  });
  Object.defineProperty(exports, 'isSelected', {
    enumerable: true,
    get: function () {
      return _isSelected.isSelected;
    }
  });
});