define('ux-pcc/components/charts/view-chart', ['exports', 'accounting/format-number', 'moment', 'ux-pcc/config/environment', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/helpers/wrap-negative', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/charts/mark-today', 'ux-pcc/utils/which-browser', 'ux-components/utils/format-currency'], function (exports, _formatNumber, _moment, _environment, _chartBasic, _wrapNegative, _dateFormatter, _markToday, _whichBrowser, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    showLegend: true,
    showValueAxisLabels: false,
    showGuide: true,
    showBalloon: true,
    currencyCode: 'USD',
    showCurrency: true,
    exportConfig: null,

    // Settings to blur the table if selected range is too big
    tableColumnLimit: null,
    tableBlurred: false,
    tableBlurMessage: null,
    blurTable: Ember.computed('tableColumnLimit', function () {
      return this.get('tableColumnLimit') > 1;
    }),

    classNames: ['view-chart'],
    sliceResolution: 'week',
    enableZoom: false,
    zoomIndexStart: 0,
    zoomIndexEnd: 6,
    tz: null,

    init: function init() {
      this._super.apply(this, arguments);
    },
    _formatValue: function _formatValue(value, format, formatHash, tableView) {
      if (format === 'currency') {
        if (tableView) {
          return (0, _formatCurrency.default)(value, this.get('currencyCode'), { hash: formatHash });
        } else {
          return (0, _formatNumber.default)(value, formatHash.precision, '');
        }
      } else if (format === 'percent') {
        return (0, _formatNumber.default)(value, 1, '');
      } else if (format === 'truncatedNumber') {
        return (0, _formatNumber.default)(value, 2, '');
      } else if (format === 'number') {
        return (0, _formatNumber.default)(value, 0, '');
      } else {
        return value;
      }
    },


    // default scrollbar config - allow override by passing in config
    scrollBarConfig: Ember.computed(function () {
      return {
        oppositeAxis: false,
        offset: 50,
        scrollbarHeight: 60,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.1,
        selectedBackgroundColor: '#888888',
        dragIconHeight: 20,
        dragIconWidth: 20,
        graphType: 'line',
        gridCount: 4,
        color: '#AAAAAA',
        dragIcon: 'dragIconRoundSmall'
      };
    }),

    dataTransform: Ember.computed('sliceResolution', 'dataFields.@each.enabled', function () {
      var _this = this;

      var dataFields = (this.get('dataFields') || []).filterBy('enabled');
      var sliceResolution = this.get('sliceResolution');
      var settings = this.get('customer.settings');

      return function (data) {
        var today = _moment.default.tz(_this.get('tz'));
        return data.map(function (d) {
          var newData = Ember.assign({}, d);
          newData.moment = (0, _moment.default)(newData.slice);

          if (newData.moment.isSame(today, sliceResolution)) {
            newData.dateClass = 'today';
          }

          // Set the value class for a negative value
          dataFields.forEach(function (field) {
            if (field.key in newData) {
              var value = newData[field.key] ? newData[field.key] : 0;
              newData[field.key] = _this._formatValue(value, field.format, field.formatHash);
            }
          });

          if (newData.sliceFormat) {
            newData.formattedSlice = newData.moment.format(newData.sliceFormat);
          } else {
            if (sliceResolution === 'hour') {
              newData.formattedSlice = newData.moment.format('h:mm<br>A');
            } else {
              newData.formattedSlice = newData.moment.format('ddd') + '<br>' + (0, _dateFormatter.dateFormatter)(newData.moment, { year: false, settings: settings });
            }
          }

          return newData;
        });
      };
    }),

    markDay: Ember.computed('data', function () {
      return (this.get('data').findBy('dateClass', 'today') || {}).formattedSlice;
    }),

    syncRight: Ember.computed.equal('syncAxis', 'right'),
    syncLeft: Ember.computed.not('syncRight'),

    _bubbleValue: function _bubbleValue(valueObject, key, format, formatHash, valueClass) {
      var value = valueObject.dataContext[key];
      var spanClass = valueObject.dataContext[valueClass];

      var getNVC = function getNVC(v) {
        if (!Ember.isEmpty(v) && v.toString().indexOf('(') > -1) {
          return 'negative-value-class';
        }

        return '';
      };

      if (format === 'currency') {
        var bubbleValue = (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(value, this.get('currencyCode'), { hash: formatHash }));
        return '<span class=\'bubble-value ' + getNVC(bubbleValue) + ' ' + spanClass + '\'>' + bubbleValue + '</span>';
      } else if (format === 'percent') {
        var _bubbleValue2 = (0, _wrapNegative.wrapNegative)(value, { precision: 1, suffix: '%' });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue2) + ' ' + spanClass + '\'>' + _bubbleValue2 + '</span>';
      } else if (format === 'truncatedNumber') {
        var _bubbleValue3 = (0, _wrapNegative.wrapNegative)(value, { precision: 2 });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue3) + ' ' + spanClass + '\'>' + _bubbleValue3 + '</span>';
      } else if (format === 'number') {
        var _bubbleValue4 = (0, _wrapNegative.wrapNegative)(value, { precision: 0 });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue4) + ' ' + spanClass + '\'>' + _bubbleValue4 + '</span>';
      } else {
        var _bubbleValue5 = (0, _wrapNegative.wrapNegative)(value);
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue5) + ' ' + spanClass + '\'>' + _bubbleValue5 + '</span>';
      }
    },
    _styleBalloon: function _styleBalloon(item, index) {
      var _this2 = this;

      var bubbleOrder = this.get('graphBubbleOrder');
      var dataFields = (this.get('dataFields') || []).filterBy('enabled');

      var showBubble = false;

      // Show the bubble for the first graph that has the key in it
      for (var i = 0; i < bubbleOrder.length; i++) {
        if (bubbleOrder[i].key in item.dataContext) {
          if (i === index) {
            showBubble = true;
          }

          break;
        }
      }

      if (!showBubble) {
        return '';
      }

      var _generateHTML = function _generateHTML(label, value) {
        return '<div>\n                <span class=\'bubble-label\'>' + label + ': </span>\n                ' + value + '\n              </div>';
      };

      var displayHTML = '';

      dataFields.forEach(function (dataField) {
        if (dataField.key in item.dataContext && !dataField.excludeFromHover) {
          var label = item.dataContext.useAlternateHoverLabel && dataField.alternateHoverLabel ? dataField.alternateHoverLabel : dataField.label;
          displayHTML += _generateHTML(label, _this2._bubbleValue(item, dataField.key, dataField.format, dataField.formatHash, dataField.valueClassname));
        }
      });

      return displayHTML;
    },
    _config: function _config() {
      var _this3 = this;

      var dataFields = (this.get('dataFields') || []).filterBy('enabled').filterBy('category');
      var rightFields = dataFields.filterBy('category', 'right') || [];
      var leftFields = dataFields.filterBy('category', 'left') || [];

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this3.get('data.length') > 0) {

          var generateGraph = function generateGraph(title, data, index) {
            var graph = {
              title: title,
              balloonFunction: function balloonFunction(item) {
                return _this3._styleBalloon(item, index);
              },
              fillAlphas: 1,
              lineAlpha: 1,
              type: Ember.isEmpty(data.chartType) ? 'line' : data.chartType,
              clustered: true,
              id: 'analysis-' + index + ' chart-color--' + (data.lineClasses ? data.lineClasses : index),
              valueField: data.key,
              valueAxis: data.category,
              connect: false,
              classNameField: data.valueClassname,
              balloon: { fillAlpha: 1 }
            };

            if (data.chartType !== 'column') {
              graph.fillAlphas = 0;
              graph.bullet = data.bulletType || 'round';
              graph.bulletSize = 6;
              graph.bulletBorderAlpha = 1;
              graph.bulletAlpha = 1;
              graph.classNameField = 'bulletClass';
              graph.useLineColorForBulletBorder = true;
              graph.lineThickness = 1.5;
              graph.dashLength = data.dashLength || 0;
            }

            return graph;
          };

          // Since not all the values will be displayed, we need to move the bubble
          // when the graph is there, but the key is not
          var graphBubbleOrder = [];
          dataFields.forEach(function (d, i) {
            var graphTitle = d.chartLabel ? d.chartLabel : d.label;
            graphBubbleOrder.pushObject({ key: d.key });
            graphs.push(generateGraph(graphTitle, d, i));
          });

          _this3.set('graphBubbleOrder', graphBubbleOrder);
        }

        return graphs;
      };

      var calendars = this.get('_data');

      if (!calendars) {
        return;
      }

      var guides = [];

      if (this.get('markDay')) {
        guides.push({
          id: 'today',
          category: this.get('markDay'),
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        }, {
          category: this.get('markDay'),
          toCategory: this.get('markDay'),
          fillAlpha: 0.2,
          fillColor: '#888888',
          lineAlpha: 0,
          labelRotation: 90,
          inside: true,
          lineThickness: 2,
          expand: true
        });
      }

      // Figure out the multipliers for the value axis for synchronization by
      // looping over the values to determine the maximums in order to set the multiplier
      var rightMax = Number.MIN_SAFE_INTEGER;
      var leftMax = Number.MIN_SAFE_INTEGER;
      var rightMin = Number.MAX_SAFE_INTEGER;
      var leftMin = Number.MAX_SAFE_INTEGER;

      // Find the minimum of the values displayed in the graph
      calendars.forEach(function (item) {
        rightFields.forEach(function (f) {
          rightMax = Math.max(rightMax, Number(item[f.key] || 0.1));
          rightMin = Math.min(rightMin, Number(item[f.key] || 0));
        });
        leftFields.forEach(function (f) {
          leftMax = Math.max(leftMax, Number(item[f.key] || 0.1));
          leftMin = Math.min(leftMin, Number(item[f.key] || 0));
        });
      });

      var valueAxes = [];

      if (leftFields.length > 0) {
        var leftAxis = {
          id: 'left',
          autoGridCount: true,
          gridAlpha: 0.1,
          axisAlpha: 0.1,
          position: 'left',
          title: this.get('leftAxisTitle'),
          labelsEnabled: this.get('showValueAxisLabels'),
          showFirstLabel: true,
          usePrefixes: true,
          zeroGridAlpha: 0,
          includeAllValues: this.get('enableZoom'),
          strictMinMax: leftMin >= 0, // If the minimum is above 0, force amcharts to make the axis minimum 0 to display the 0 line
          minimum: leftMin >= 0 ? 0 : leftMin // Otherwise, the minimum will be below 0, and the 0 line will be displayed anyway
        };

        if (this.get('syncLeft') && rightFields.length > 0 && leftMax > 0.1 && rightMax > 0.1) {
          // if one/both axis has all negative numbers, don't synchronize axes
          var synchronize = Math.max(rightMax ? leftMax / rightMax : 0, rightMin ? leftMin / rightMin : 0);
          leftAxis.synchronizationMultiplier = synchronize >= 0.5 ? synchronize : 0.5;
          leftAxis.synchronizeWith = 'right';
          leftAxis.strictMinMax = false;
        } else {
          leftAxis.guides = [{
            id: 'zero-marker1',
            value: 0,
            lineAlpha: 1,
            lineThickness: 3,
            above: true,
            inside: true,
            fillColor: '#000000',
            lineColor: '#000000'
          }];
        }

        valueAxes.push(leftAxis);
      }

      if (rightFields.length > 0) {
        var rightAxis = {
          id: 'right',
          autoGridCount: true,
          gridAlpha: 0.1,
          axisAlpha: 0.1,
          position: 'right',
          title: this.get('rightAxisTitle'),
          labelsEnabled: this.get('showValueAxisLabels'),
          showFirstLabel: true,
          usePrefixes: true,
          zeroGridAlpha: 0,
          includeAllValues: this.get('enableZoom'),
          strictMinMax: rightMin >= 0, // If the minimum is above 0, force amcharts to make the axis minimum 0 to display the 0 line
          minimum: rightMin >= 0 ? 0 : rightMin // Otherwise, the minimum will be below 0, and the 0 line will be displayed anyway
        };

        if (this.get('syncRight') && leftMax > 0.1 && rightMax > 0.1) {
          // if one/both axis has all negative numbers, don't synchronize axes
          var _synchronize = Math.max(leftMax ? rightMax / leftMax : 0, leftMin ? rightMin / leftMin : 0);
          rightAxis.synchronizationMultiplier = _synchronize >= 0.5 ? _synchronize : 0.5;
          rightAxis.synchronizeWith = 'left';
          rightAxis.strictMinMax = false;
        } else {
          rightAxis.guides = [{
            id: 'zero-marker',
            value: 0,
            lineAlpha: 1,
            lineThickness: 3,
            above: true,
            inside: true,
            fillColor: '#000000',
            lineColor: '#000000'
          }];
        }

        valueAxes.push(rightAxis);
      }

      var config = {
        type: 'serial',
        addClassNames: true,
        categoryField: 'formattedSlice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        trendLines: [],
        allLabels: [],
        balloon: {},
        titles: [],
        graphs: generateGraphs(),
        valueAxes: valueAxes,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass'
        },
        legend: {
          position: 'bottom',
          useGraphSettings: true,
          markerSize: 5,
          enabled: this.get('showLegend')
        },
        guides: guides,
        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: this.get('showBalloon'),
          cursorAlpha: 0,
          cursorColor: '#4d96cb',
          color: '#f3faff',
          categoryBalloonEnabled: false
        },
        language: this.get('i18n.locale'),
        dataProvider: calendars
      };

      if (this.get('showTable')) {
        config.dataTableId = 'am-table';
      }

      if (this.get('enableZoom')) {
        this.set('scrollBarExists', true);
        config.pathToImages = _environment.default.rootURL + 'assets/amcharts/images/'; // required for grips
        config.zoomOutText = '';
        config.chartScrollbar = this.get('scrollBarConfig');
      } else {
        this.set('scrollBarExists', false);
      }

      if (this.get('exportConfig')) {
        config.export = this.get('exportConfig');
      }

      return Ember.$.extend(true, {}, this._super(), config, this.get('tableConfig'));
    },
    zoomChart: function zoomChart() {
      var chart = this.get('_chart');
      var indexEnd = this.get('zoomIndexEnd');
      if (chart.dataProvider && chart.dataProvider.length > 0) {
        chart.zoomToIndexes(this.get('zoomIndexStart'), indexEnd < chart.dataProvider.length - 1 ? indexEnd : chart.dataProvider.length - 1);
      }
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      // If we have changed the size from mobile to desktop or back, need to
      // re-create the chart to remove or show the scrollbar
      if (this.get('enableZoom') && !this.get('scrollBarExists') || !this.get('enableZoom') && this.get('scrollBarExists')) {
        this._createChart();
      }
    },


    _legendObserver: Ember.observer('dataFields.@each.enabled', function () {
      Ember.run.once(this, '_createChart');
    }),

    _data: Ember.computed('data', 'dataTransform', 'dataFields.[]', function () {
      var data = this.get('data');
      // todo: may want to copy 'data' so mutation doesn't occur
      return this.get('dataTransform')(data);
    }),

    _createChart: function _createChart() {
      var _this4 = this;

      var chart = this._super();

      if (chart) {
        var element = this.get('element');
        (0, _markToday.chartsMarkToday)(element, 3);
        chart.addListener('drawn', function () {
          if (_this4.get('enableZoom') && _this4.get('zoomIndexStart') >= 0) {
            _this4.zoomChart();
          }

          if (_this4.get('showTable') && _this4.get('tableRows.length') > 0) {
            _this4._buildTable(element, chart, _this4.get('tableRows'), _this4.get('enableZoom'), _this4.get('zoomIndexStart'), _this4.get('zoomIndexEnd'));
          }

          (0, _markToday.chartsMarkToday)(element, 3);
        });

        // The drawn event is not fired when the chart scrolls with the scrollbar
        // Instead, the zoomed event is fired.
        chart.addListener('zoomed', function (zoomInfo) {
          (0, _markToday.chartsMarkToday)(element, 3);
          if (_this4.get('actionOnZoom')) {
            _this4.get('actionOnZoom')(zoomInfo);
          }

          if (_this4.get('showTable') && _this4.get('tableRows.length') > 0) {
            _this4._buildTable(element, chart, _this4.get('tableRows'), _this4.get('enableZoom'), zoomInfo.startIndex, zoomInfo.endIndex);
          }
        });
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        return chart.validateData();
      }, 0);
    },
    _buildTable: function _buildTable(element, chart, dataRows, enableZoom, zoomIndexStart, zoomIndexEnd) {
      // check if export to table is enabled
      if (chart.dataTableId === undefined) {
        return;
      }

      // Limit the amount of data to show based on zoom
      var dataProvider = chart.dataProvider;
      if (enableZoom && zoomIndexStart >= 0) {
        if (this.get('blurTable') && zoomIndexEnd - zoomIndexStart > this.get('tableColumnLimit')) {
          zoomIndexEnd = zoomIndexStart + this.get('tableColumnLimit');
        }

        dataProvider = dataProvider.slice(zoomIndexStart, zoomIndexEnd < dataProvider.length - 1 ? zoomIndexEnd + 1 : dataProvider.length);
      }

      // Exit if the table is already blurred, and there are too manny columns
      if (this.get('tableBlurred') && dataProvider.length >= this.get('tableColumnLimit') + 1) {
        return;
      }

      // create table
      var holder = Ember.$(element).find('.' + chart.dataTableId);
      var table = document.createElement('table');

      if (holder && holder[0]) {
        holder[0].innerHTML = '';

        // Max table columns reached, so blur the table and add cover text
        if (this.get('blurTable') && dataProvider.length >= this.get('tableColumnLimit') + 1) {
          table.className = 'blur';
          // Add cover text
          var coverTextContainer = document.createElement('div');
          coverTextContainer.className = 'am-table__cover-label centered';

          var coverTextDiv = document.createElement('div');
          coverTextDiv.className = 'am-table__cover-text';

          var coverText = document.createElement('h4');
          coverText.innerHTML = this.get('tableBlurMessage') || this.i18n.t('charts.view-chart.range_too_large');
          coverTextDiv.appendChild(coverText);
          coverTextContainer.appendChild(coverTextDiv);
          // Add it to the holder
          holder[0].appendChild(coverTextContainer);

          this.set('tableBlurred', true);
        } else {
          this.set('tableBlurred', false);
        }

        // Add the table data
        holder[0].appendChild(table);
        var tr = void 0,
            td = void 0;

        // construct table
        for (var i = 0; i < dataRows.length; i++) {
          // add rows
          tr = document.createElement('tr');
          tr.setAttribute('data-valuefield', dataRows[i].valueField);
          table.appendChild(tr);
          td = document.createElement('td');
          td.className = 'row-title';
          td.innerHTML = dataRows[i].label;
          tr.appendChild(td);

          for (var x = 0; x < dataProvider.length; x++) {
            td = document.createElement('td');
            td.innerHTML = this._formatValue(dataProvider[x][dataRows[i].valueField], dataRows[i].format, dataRows[i].formatHash, true);
            if (!(0, _whichBrowser.isIE)()) {
              td.style = 'width: calc((100%-120px)/' + dataProvider.length + ')';
            }

            tr.appendChild(td);
          }
        }
      }
    }
  });
});