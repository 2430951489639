define('ux-pcc/routes/rules-simple/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    dialog: Ember.inject.service(),
    notifications: Ember.inject.service(),

    queryParams: {
      ruleType: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var customer = this.modelFor('customer');

      if (Ember.isEmpty(params.ruleType)) {
        params.ruleType = 'CANCEL';
      }

      return this.store.query('rule', {
        customerID: customer.get('id'),
        ruleType: params.ruleType,
        include: 'children',
        effective: false
      }).then(function (rules) {
        return rules.filterBy('filterValueRule', 'SIMPLE');
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('sortProperties', ['shortDescription']);

      if (Ember.isEmpty(controller.ruleType)) {
        controller.set('ruleType', 'CANCEL');
      }
    },


    actions: {
      confirmDelete: function confirmDelete(rule) {
        var _this = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('rules.index.discard_rule'),
          messageText: this.i18n.t('rules.index.confirm_delete'),
          confirmText: this.i18n.t('rules.index.continue'),
          cancelText: this.i18n.t('rules.index.cancel'),
          onConfirm: function onConfirm() {

            if (rule) {

              rule.destroyRecord().then(function () /* results */{
                _this.refresh();
                _this.notifications.success(_this.i18n.t('rules.index.success_delete_message', {
                  ruleName: rule.get('shortDescription')
                }));
              }).catch(function (error) {
                _this.notifications.error(error);
              });
            }
          },
          onCancel: function onCancel() {
            _this.dialog.close();
          }
        });
      },
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});