define('ux-pcc/components/availability/property-availability-panel', ['exports', 'ux-components/components/availability/property-availability-panel'], function (exports, _propertyAvailabilityPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _propertyAvailabilityPanel.default;
    }
  });
});