define('ux-pcc/transforms/string[]', ['exports', 'ux-data/transforms/string[]'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _string.default;
    }
  });
});