define('ux-pcc/adapters/price-adjustment-calendar', ['exports', 'ux-data/adapters/price-adjustment-calendar'], function (exports, _priceAdjustmentCalendar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _priceAdjustmentCalendar.default;
    }
  });
});