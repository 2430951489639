define('ux-pcc/routes/yield-manager/view/index', ['exports', 'ux-components/mixins/routes/full-screen'], function (exports, _fullScreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fullScreen.default, {
    _getSubTitle: function _getSubTitle(type) {
      switch (type) {
        case 'PRODUCT':
          return this.get('i18n').t('dashboard-views.form.product_types');
        case 'CHANNEL':
          return this.get('i18n').t('dashboard-views.form.channels');
        case 'PRICE':
          return this.get('i18n').t('dashboard-views.form.prices');
        case 'YICD':
        case 'YMTD':
        case 'YMCD':
        case 'YMCD-RES':
          return this.get('i18n').t('dashboard-views.form.' + type.toLowerCase());
        default:
          return this.get('i18n').t('dashboard-views.form.ymcd');
      }
    },
    _getBrand: function _getBrand(brand) {
      if (!brand) {
        return 'ehi';
      } else if (brand.indexOf('|') > -1) {
        return "";
      }
      return brand;
    },
    model: function model(params) {
      return params;
    },
    setupController: function setupController(controller) {
      // Use the model from the controller, so we don't reload all the views and code values
      var view = this.controllerFor('yield-manager.view').get('model');
      var viewParams = view.get('dashboardViewParameters');
      var type = viewParams.get('DATA_TYPE');
      var newView = {
        title: view.get('name'),
        type: type,
        view: view,
        gross: viewParams.get('TM_VS_GROSS') === 'GROSS',
        reportingTimePeriod: viewParams.get('REPORTING_TIME_PERIOD'),
        brand: viewParams.get('BRAND_CODE') ? this._getBrand(viewParams.get('BRAND_CODE')) : "",
        subTitle: this._getSubTitle(type)
      };

      if (viewParams.get('DATE_COMPARE')) {
        newView['weekOverWeek'] = viewParams.get('DATE_COMPARE') === 'WEEK';
      }

      if (viewParams.get('DATE_RANGE')) {
        newView['range'] = viewParams.get('DATE_RANGE');
      }

      var aggregateVersion = this.controllerFor('yield-manager.view').get('aggregateVersion');
      controller.set('aggregateVersion', aggregateVersion);

      // Set the model on the controller
      this._super(controller, Ember.Object.create(newView));
    },


    actions: {
      refreshChildRoute: function refreshChildRoute() {
        this.refresh();
      }
    }
  });
});