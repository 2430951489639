define('ux-pcc/components/charts/occupancy-chart', ['exports', 'accounting', 'moment'], function (exports, _accounting, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, this._createChart);
    },
    _createChart: function _createChart() {
      var tz = this.tz.customer;

      var dataProvider = this.dataProvider;
      var dataFields = this.dataFields;

      var bubbleContent = function bubbleContent(item) {
        var m = _moment.default.tz(item.category, tz);
        var displayData = ['<div class="center">\n           <span class="bubble-value">' + m.format('dddd') + ', ' + m.format('L') + '</span>\n         </div>'];

        dataFields.forEach(function (dataField) {
          var keyInContext = dataField.key in item.dataContext;
          displayData.push('<div>\n             <span class="bubble-label">' + dataField.label + ':</span>\n             <span class="bubble-value ' + (keyInContext ? 'percent' : '') + '">' + (keyInContext ? (0, _accounting.formatNumber)(item.dataContext[dataField.key], 1) : 'NA') + '</span>\n           </div>');
        });

        return displayData.join('');
      };

      // Metrics Chart
      var lineThickness = 1.5;
      var chart = AmCharts.makeChart('occupancy-chart', {
        hideCredits: true,
        addClassNames: true,
        type: 'serial',
        categoryField: 'slice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        categoryAxis: {
          color: '#666666',
          axisColor: '#666666',
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          parseDates: false,
          labelFunction: function labelFunction(valueText) {
            var m = _moment.default.tz(valueText, tz);
            return m.format('ddd') + '\n\r' + m.format('L').substring(0, 5);
          }
        },
        chartCursor: {
          zoomable: false,
          selectWithoutZooming: true,
          cursorColor: '0', //"#4d96cb",
          color: '#f3faff',
          categoryBalloonEnabled: false,
          cursorAlpha: 0,
          balloonPointerOrientation: 'vertical'
        },
        trendLines: [],
        graphs: [{
          title: this.i18n.t('customer.performance.overview.chart.occupancy'),
          balloonFunction: function balloonFunction(item) {
            return 'occupancy' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          lineColorField: 'occupancy_color',
          type: 'line',
          lineThickness: lineThickness,
          id: 'occupancy chart-color--0',
          valueField: 'occupancy',
          valueAxis: 'percentOccupancy',
          clustered: true,
          connect: false
        },
        /*
        {
          title: this.i18n.t('customer.performance.overview.chart.forecast'),
          balloonFunction: () => '',
          fillAlphas: 0,
          type: 'line',
          lineThickness: lineThickness,
          id: 'occupancyForecast',
          valueField: 'occupancy_forecast',
          valueAxis: 'percentOccupancy',
          clustered: true,
          connect: false
        },
        */
        {
          title: this.i18n.t('customer.performance.overview.chart.occupancy_stly'),
          balloonFunction: function balloonFunction(item) {
            return !('occupancy' in item.dataContext) && 'occupancy_stly' in item.dataContext ? bubbleContent(item) : '';
          },
          fillAlphas: 0,
          type: 'line',
          lineThickness: lineThickness,
          id: 'occupancyStly chart-color--0 muted',
          valueField: 'occupancy_stly',
          valueAxis: 'percentOccupancy',
          clustered: true,
          connect: false
        }],
        valueAxes: [{
          id: 'percentOccupancy',
          color: '#999999',
          gridAlpha: 0.1,
          axisAlpha: 0,
          zeroGridAlpha: 0,
          position: 'left',
          minimum: 0,
          unit: '%',
          showFirstLabel: true
        }],
        guides: [{
          id: 'today',
          category: _moment.default.tz(this.tz.customer).startOf('day').format(),
          lineColor: '#666666',
          color: '#999999',
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        }],
        legend: {
          enabled: true,
          position: 'top',
          markerSize: 10,
          markerType: 'square',
          valueWidth: 0
        },
        allLabels: [],
        balloon: {
          adjustBorderColor: true,
          borderAlpha: 0,
          shadowAlpha: 0,
          color: '#ffffff',
          fillColor: '#0B8CF5',
          fillAlpha: 0.9,
          textAlign: 'middle',
          verticalPadding: 15,
          horizontalPadding: 20
        },
        titles: [],
        zoomOutOnDataUpdate: true,
        dataProvider: dataProvider
      }, 1);

      this.set('_chart', chart);
    }
  });
});