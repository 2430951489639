define('ux-pcc/validations/price', ['exports', 'ux-data/validations/price'], function (exports, _price) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _price.default;
    }
  });
});