define('ux-pcc/utils/remove-record-from-store', ['exports', 'ux-components/utils/remove-record-from-store'], function (exports, _removeRecordFromStore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _removeRecordFromStore.default;
    }
  });
});