define('ux-pcc/components/forms/affiliate', ['exports', 'lodash', 'ux-pcc/mixins/controllers/places-searchable'], function (exports, _lodash, _placesSearchable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_placesSearchable.default, {

    store: Ember.inject.service(),

    customerTypeLabel: Ember.computed('model.customerType', function () {
      var _this = this;

      var obj = {
        CHAIN: 'AFFILIATE',
        PORTFOLIO: 'CUSTOMERS',
        PROPERTY: 'CUSTOMER',
        COMPETITIVE_SET: 'COMPETITORS',
        COMPETITOR: 'COMPETITOR'
      };

      var type = (0, _lodash.findKey)(obj, function (value) {
        return value === _this.get('model.customerType');
      }).toLowerCase();

      return this.i18n.t('affiliate.-form.information.customer_types.' + type);
    }),

    customerTypes: Ember.computed(function () {
      return [{
        value: 'AFFILIATE',
        label: this.i18n.t('affiliate.-form.information.customer_types.chain')
      }, {
        value: 'CUSTOMER',
        label: this.i18n.t('affiliate.-form.information.customer_types.property')
      }];
    }),

    typeChanged: Ember.on('init', Ember.observer('model.customerType', function () {
      var model = this.get('model');

      // clear address and phone if switching between types on new object
      if (model && !model.get('id')) {

        this.get('model.addresses').clear();
        this.get('model.addresses').addObject(this.store.createRecord('address', {
          addressType: 'BUSINESS',
          rawAddressText: ''
        }));

        this.get('model.contactInformations').clear();
        this.get('model.contactInformations').addObject(this.store.createRecord('contact-information', {
          informationType: 'MAIN_PHONE',
          informationValue: ''
        }));
      }
    })),

    canEdit: Ember.computed(function () {
      return true;
    }),
    cannotEdit: Ember.computed.not('canEdit'),

    isAffiliate: Ember.computed('model.customerType', function () {
      return this.get('model.customerType') === 'AFFILIATE';
    }),

    address: Ember.computed('model.addresses.[]', function () {
      var address = this.get('model.primaryAddress');
      if (!address) {
        address = this.store.createRecord('address', {
          addressType: 'BUSINESS',
          rawAddressText: ''
        });
        this.get('model.addresses').addObject(address);
      }
      return address;
    }),

    phone: Ember.computed('model.contactInformations.[]', function () {
      var phone = this.get('model.contactInformations').findBy('informationType', 'MAIN_PHONE');
      if (!phone) {
        phone = this.store.createRecord('contact-information', {
          informationType: 'MAIN_PHONE',
          informationValue: ''
        });
        this.get('model.contactInformations').addObject(phone);
      }
      return phone;
    }),

    actions: {
      highlightSelection: function highlightSelection(item /*, name, element*/) {
        var _this2 = this;

        if (item && item.placeID) {
          var url = '/rest/v1/places/' + item.placeID;
          this.ajax.request(url, {
            dataType: 'json'
          }).then(function (record) {
            return _this2._populateCustomer(_this2.get('model'), record);
          });
        }
      },
      submit: function submit() {
        this.submit();
      },
      close: function close() {
        this.close();
      },
      setLoading: function setLoading() {
        //noop
      }
    }

  });
});