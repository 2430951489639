define('ux-pcc/controllers/customer/performance/channels', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    queryParams: ['beginDate', 'endDate'],

    beginDate: (0, _moment.default)().format("YYYY-MM-DD"),
    endDate: (0, _moment.default)().format("YYYY-MM-DD"),
    channels: null,
    rollupStatus: Ember.computed('customer.customerType', function () {
      return 'CUSTOMER' === this.get('customer.customerType') ? 'DETAIL' : 'ROLLUP';
    }),

    enableSlider: Ember.computed.bool('deviceType.tablet'),

    nbrDetailColumns: 6,

    performanceDetailColumns: Ember.computed('i18n.locale', function () {
      return [{ key: "", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.channel') }, { key: "sold", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.rooms_sold') }, { key: "soldVar", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.rooms_sold_var') }, { key: "adr", format: "formatCurrency", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.booked_adr') }, { key: "adrVar", format: "formatCurrency", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.adr_var') }, { key: "occPct", format: "formatNumber", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.occ_contrib') }, { key: "revPct", format: "formatNumber", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.rev_contrib') }, { key: "totalRev", format: "formatCurrency", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.total_rev') }, { key: "revVar", format: "formatCurrency", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.rev_var') }];
    }),

    performanceDetailRows: Ember.computed(function () {
      var returnList = [{ key: "TOTAL", label: this.get('i18n').t('customer.performance.channel.table.channel-performance.total') }];
      returnList = returnList.concat(this.get('channels'));

      return returnList;
    }),

    performanceDetailFilter: Ember.computed(function () {
      var settings = this.get('customer.settings').toArray() || [];

      // Get the customer setting for the rollup channels to pass to the channel fetch call
      var channelCodes = settings.findBy("settingCode", "ROLLUP_CHANNEL_LIST");

      // Strip out any spaces
      if (channelCodes && channelCodes.get('settingValue').length > 0) {
        channelCodes = channelCodes.get('settingValue').replace(/\s/g, "");
      } else {
        // Set some defaults for now
        channelCodes = 'PMS,WEBSITE,CALL_CENTER,OTA,GDS';
      }

      return channelCodes;
    }),

    minEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate'));
    }),

    maxEndDate: Ember.computed('beginDate', function () {
      return (0, _moment.default)(this.get('beginDate')).clone().add(180, 'days');
    }),

    datesObserver: Ember.observer('beginDate', 'endDate', function () {
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('beginDate'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), tz).endOf('day');
      //restrict dates in case of url param modifications
      if (_moment.default.duration(endDate.diff(selectedDate)).asDays() > 180) {
        this.set('endDate', (0, _moment.default)(this.get('maxEndDate')).format('YYYY-MM-DD'));
      }
    }),

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    endDatePickerOptions: Ember.computed('i18n.locale', 'minEndDate', 'maxEndDate', function () {
      var options = {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: this.get('minEndDate')
      };
      if (this.get('maxEndDate')) {
        options.maxDate = this.get('maxEndDate');
      }
      return options;
    })

  });
});