define('ux-pcc/routes/yield-manager/view', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      viewType: {
        refreshModel: true
      },
      viewId: {
        refreshModel: true
      },
      viewBeginDate: {
        refreshModel: false
      },
      viewEndDate: {
        refreshModel: false
      }
    },

    ajax: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {

      var viewType = transition.queryParams.viewType;
      // If this is not a multi-book date, then strip the viewBookDate parameter
      // from the query string, and set the others
      if (viewType && !this.isMultiComparisonAnalysis(viewType)) {
        this.set('viewBookDate', null);
        this.set('viewBeginDate', transition.queryParams.viewBeginDate);
        this.set('viewEndDate', transition.queryParams.viewEndDate);
      } else {
        if (transition.queryParams.viewBookDate === 'null' || Ember.isEmpty(transition.queryParams.viewBookDate)) {
          this.set('viewBookDate', null);
          delete transition.queryParams['viewBeginDate'];
          delete transition.queryParams['viewEndDate'];
        } else {
          // If there is a viewBookDate, then set all the queryString parameters
          this.set('viewBookDate', transition.queryParams.viewBookDate);
          this.set('viewBeginDate', transition.queryParams.viewBeginDate);
          this.set('viewEndDate', transition.queryParams.viewEndDate);
        }
      }
    },
    _levelByCustomerType: function _levelByCustomerType(customer) {
      switch (customer.get('customerType')) {
        case customer.Type.PROPERTY:
          return 'LEVEL_STATION';
        default:
          return '';
      }
    },
    isMultiComparisonAnalysis: function isMultiComparisonAnalysis(type) {
      return ['YSGA'].indexOf(type) > -1;
    },
    model: function model(params) {
      var _this = this;

      var customer = this.modelFor('customer');
      var view = null;
      var controller = this.controllerFor('yield-manager.view');

      if (Ember.isEmpty(params.viewId)) {
        var tagList = (customer.get('tags') || []).filter(function (tag) {
          return tag.startsWith('LEVEL_');
        }).map(function (tag) {
          return { label: tag };
        });

        var useExistingView = false;
        var previousView = controller && controller.get('model.dashboardViewParameters') ? JSON.parse(JSON.stringify(controller.get('model.dashboardViewParameters'))) : null;
        if (previousView) {

          // Check to see if we are looking at a view for the current customer
          var prevLevel = previousView.SESSION_CUSTOMER_ID;
          useExistingView = prevLevel && prevLevel === customer.get('id');
        }

        if (useExistingView) {

          view = Ember.RSVP.resolve(controller.get('model')).then(function (v) {
            Ember.set(v, 'dashboardViewParameters.DATA_TYPE', params.viewType);
            return v;
          });
        } else {
          var tz = ['YSGA'].indexOf(params.viewType) > -1 ? _moment.default.tz.guess(true) : this.tz.get('customer');
          var model = {
            name: customer.get('externalReference'),
            dashboardViewParameters: Ember.Object.create({
              'SESSION_CUSTOMER_ID': customer.get('id'),
              'SHOW_VIEW_TYPE': 'TRUE',
              'DATA_TYPE': params.viewType,
              'DATA_VIEW': 'ACTUAL',
              'TM_VS_GROSS': 'TM',
              'DATE_SYNC': 'MONTH',
              'DATE_RANGE': '1',
              'RATE_OF_ADD': '-1',
              'DATE_COMPARE': 'YEAR',
              'DAY_OF_WEEK': 'ALL',
              'REPORTING_TIME_PERIOD': '',
              'LEVEL_HIERARCHY': tagList.length > 0 ? tagList[0].label : this._levelByCustomerType(customer),
              'LEVEL': customer.get('id'),
              'CORPORATE': 'CORPORATE',
              'LOCATION_TYPE': '',
              'DIVISION': '',
              'BRAND_CODE': '',
              'AGREEMENT': '',
              'CHANNEL': '',
              'SUB_CHANNEL': '',
              'BOOK_SRC_COUNTRY': '',
              'BOOK_SRC_ACCOUNT': '',
              'LENGTH_OF_RENT': '',
              'DESTINATION_STATUS': 'BOOK|INHOUSE|DEPARTED',
              'PRICE_CODE': '',
              'RETURN_TYPE': 'ALL',
              'PRODUCT_CODE': '',
              'SUB_PRODUCT_CODE': '',
              'SOURCE_LOCATION_CODE': 'RESERVATION',
              'FULFILLMENT_STATUS': '',
              'PRODUCT_BLUEPRINT_COUNT': 0,
              'ANALYZE_BREADCRUMB_HOME': 'customer.monthly-booking-summary',
              'BEGIN_DATE': this.get('viewBeginDate') ? _moment.default.tz(this.get('viewBeginDate'), tz).startOf('day') : _moment.default.tz(tz).startOf('month')
            })
          };

          if (this.get('viewEndDate')) {
            Ember.set(model, 'dashboardViewParameters.END_DATE', _moment.default.tz(this.get('viewEndDate'), tz).endOf('day'));
          }

          if (this.get('viewBookDate')) {
            Ember.set(model, 'dashboardViewParameters.BOOK_DATE', _moment.default.tz(this.get('viewBookDate'), tz).startOf('day'));
          }

          view = Ember.RSVP.resolve(this.get('store').createRecord('dashboard-view', model));
        }
      } else {
        view = this.store.findAll('dashboard-view').then(function (results) {
          var rtnView = results.findBy('id', params.viewId);

          if (rtnView && rtnView.get('dashboardViewParameters.length') > 0) {
            // TODO: convert to object
            // Add the current customer id for tracking if we switch views but want to keep using the
            // same dashboardViewParameters across all the Y screens
            rtnView.set('dashboardViewParameters.SESSION_CUSTOMER_ID', customer.get('id'));
          }

          return rtnView;
        });
      }

      return Ember.RSVP.hash({
        view: view.then(function (viewData) {
          if (Ember.get(viewData, 'dashboardViewParameters.DATA_TYPE') === 'YSGA') {
            // Set timestamp for all the queries for the page
            Ember.set(viewData, 'dashboardViewParameters.LAST_MODIFY_FILTER', _moment.default.tz(_moment.default.tz.guess(true)).format());
            Ember.set(viewData, 'dashboardViewParameters.DESTINATION_STATUS', 'BOOK|INHOUSE|DEPARTED|CANCEL|NET');
          } else {
            Ember.set(viewData, 'dashboardViewParameters.LAST_MODIFY_FILTER', null);
          }

          var levelParam = Ember.get(viewData, 'dashboardViewParameters.LEVEL');
          var prevLevelParam = Ember.get(viewData, 'dashboardViewParameters.PREVIOUS_LEVEL');

          // If same level, don't get products again
          if (levelParam && levelParam === prevLevelParam && controller.get('productGroups.length') > 0) {
            _this.set('productGroups', null);
            return viewData;
          } else {
            // Get the level(s) and grab the top level products
            if (levelParam) {

              var selectedLevels = levelParam;
              Ember.set(viewData, 'dashboardViewParameters.PREVIOUS_LEVEL', selectedLevels);

              return _this.get('ajax').request('/rest/v1/dashboardViews/products', {
                data: {
                  customers: selectedLevels,
                  fields: 'id,shortDescription,externalReference,customerID'
                }
              }).then(function (_ref) {
                var lists = _ref.lists;

                // Check the uniqueness of the customer ids coming back, as they are the blueprints
                // Only proceed if using a single blueprint
                var customerIds = (lists || []).mapBy('customerID').uniq();
                Ember.set(viewData, 'dashboardViewParameters.PRODUCT_BLUEPRINT_COUNT', customerIds.length);
                if (customerIds.length === 1) {

                  return _this.get('store').query('product', { ids: lists.map(function (product) {
                      return product.id;
                    }), include: 'children,children.children' }).then(function (products) {
                    _this.set('productGroups', products.sortBy('shortDescription'));
                    // Return the original view
                    return viewData;
                  });
                } else {
                  _this.set('productGroups', []);
                  // Return the original view
                  return viewData;
                }
              });
            } else {
              return viewData;
            }
          }
        }),
        customerLevels: this.get('store').query('code', { customerID: customer.get('id'), codeType: 'LEVEL_HIERARCHY' }),
        aggregateVersion: params.aggregateVersion,
        prices: this.get('store').query('code', { customerID: customer.get('id'), codeType: 'RENTAL_TYPE' })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('customerLevels', model.customerLevels.sortBy('orderBy'));
      controller.set('aggregateVersion', model.aggregateVersion);
      controller.set('prices', model.prices);
      var yieldManager = this.controllerFor('yield-manager');
      controller.set('channels', yieldManager.get('model.channels'));
      controller.set('subchannels', yieldManager.get('model.subchannels'));
      if (this.get('productGroups')) {
        controller.set('productGroups', this.get('productGroups') || []);
      }
      controller.set('brands', yieldManager.get('model.brands'));

      // Set the BOOK_DATE if set on the url
      if (!Ember.isEmpty(this.get('viewBookDate'))) {
        Ember.set(model.view, 'dashboardViewParameters.BOOK_DATE', this.get('viewBookDate'));
      }

      this._super(controller, model.view);
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        if (isExiting || transition.targetName !== 'yield-manager.view.index') {
          controller.set('model.dashboardViewParameters', null);
        }
      }
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        controller.set('viewBookDate', null);
      }
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        controller.set('viewBeginDate', null);
      }
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        controller.set('viewEndDate', null);
      }
    }
  });
});