define('ux-pcc/controllers/customer/performance/matrix-pricing', ['exports', 'moment', 'ux-components/utils/moment-range', 'lodash', 'accounting', 'ux-pcc/controllers/customer/performance/abstract-matrix', 'ux-components/utils/format-currency'], function (exports, _moment, _momentRange, _lodash, _accounting, _abstractMatrix, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractMatrix.default.extend(Ember.Evented, {
    queryParams: ["startDate"],

    table: Ember.inject.service(),
    dialog: Ember.inject.service(),

    startDate: null,

    hideLeftToSell: false,
    hideCalculated: false,
    isDisplayEventTime: false,
    isDisplayFreeze: false,
    isDisplayAutoAdjust: false,
    autoAllocationRule: null,
    hasSetupError: false,
    isHideAutoAdjustmentsOrFreeze: Ember.computed('isDisplayAutoAdjust', 'isDisplayFreeze', function () {
      return !this.get('isDisplayAutoAdjust') && this.get('isDisplayFreeze') || this.get('isDisplayAutoAdjust') && !this.get('isDisplayFreeze');
    }),

    isHideAutoAdjustmentAndFreeze: Ember.computed('isDisplayAutoAdjust', 'isDisplayFreeze', function () {
      return !this.get('isDisplayAutoAdjust') && !this.get('isDisplayFreeze');
    }),

    isShowAutoAdjustmentAndFreeze: Ember.computed('isDisplayAutoAdjust', 'isDisplayFreeze', function () {
      return this.get('isDisplayAutoAdjust') && this.get('isDisplayFreeze');
    }),

    hasOngoingAutoAllocationRule: Ember.computed('autoAllocationRule.{children.[],endDate}', function () {
      return this.get('autoAllocationRule') && this.get('autoAllocationRule.children.length') > 0 && !this.get('autoAllocationRule.endDate');
    }),

    hideAdd: Ember.computed('startDate', function () {
      if (this.get('isMobile')) {
        var tz = this.tz.get("customer");
        var today = _moment.default.tz(tz).startOf("day");

        var selectedDate = _moment.default.tz(this.get('startDate'), tz).startOf('day');

        if (selectedDate.isBefore(today)) {
          return true;
        }
      }

      return false;
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: (0, _moment.default)().startOf('day').subtract(1, 'day')
      };
    }),

    cannotPersist: Ember.computed('dirtyCalendars.length', function () {
      return this.get('dirtyCalendars.length') <= 0;
    }),

    dirtyCalendars: Ember.computed.filterBy('model.calendars', 'isDirty'),

    hasPreviousPricingSave: false,
    hasPreviousPricingSaveObserver: Ember.observer('hasPreviousPricingSave', function () {
      if (!this.get('hasPreviousPricingSave')) {
        this.set('beginSlice', this.get('model.selectedSlice'));
        this.set('endSlice', this.get('model.selectedSlice'));
      }
    }),

    comments: {}, //TODO: remove global holder of comments

    setupMessage: Ember.computed('i18n.locale', function () {
      return this.i18n.t('customer.performance.matrix-pricing.no_rule') + ' - ' + this.i18n.t('customer.performance.matrix-pricing.messages.no_rule');
    }),

    _mapValues: function _mapValues(model) {
      var _this = this;

      var tz = this.tz.customer;
      var today = _moment.default.tz(tz).startOf('day');
      var yesterday = today.clone().subtract(1, 'day');
      var mapped = {};

      // Create map keys for all dates searched
      if (model.searchedDates && model.searchedDates.startDate && model.searchedDates.endDate) {
        (0, _momentRange.default)(model.searchedDates.startDate, model.searchedDates.endDate).forEach(function (m) {
          var key = m.tz(tz).format();
          if (!mapped[key]) {
            mapped[key] = {};
          }
        });
      }

      var mapInventoryValue = function mapInventoryValue(e) {
        var key = _moment.default.tz(e.priceDate, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        mapped[key]['inventory_physical'] = e.physical.inventoryQuantity;
        mapped[key]['inventory_yieldable'] = e.yieldable.inventoryQuantity;
        mapped[key]['inventory_consumed'] = e.yieldable.consumedQuantity;
      };

      // Munge inventory
      model.calendarHeaders.forEach(mapInventoryValue);

      var mapCalendarValue = function mapCalendarValue(e) {
        var m = (0, _moment.default)(Ember.get(e, 'ruleDate'));
        var key = m.tz(tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        var children = Ember.get(e, 'children') || [];
        var times = children.filterBy('externalReference', 'EVENT_TIME');
        var freezes = children.filterBy('externalReference', 'FREEZE').concat(children.filterBy('externalReference', 'FUTURE_FREEZE')).concat(children.filterBy('externalReference', 'CEILING_RULE'));

        var calendars = children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

        mapped[key]["eventTimes"] = times.length > 0 ? times.get('firstObject') : null;
        mapped[key]["freezes"] = freezes.length > 0 ? freezes.get('firstObject') : null;
        mapped[key]["calendars"] = calendars;
      };

      // Munge calendars
      var rules = this.get('ruleSet.children');
      model.calendars.forEach(mapCalendarValue);

      // Finalize data

      // --> Price Points
      var pricePoints = {};
      var yieldableVals = {};
      var allocationVals = {};
      var consumedVals = {};
      var sellableVals = {};
      var comments = this.get('comments');
      var eventTimes = {};
      var freezes = {};
      var autoAdjustmentRules = {};

      var mapComments = function mapComments(e) {
        var m = Ember.get(e, 'commentDate');
        var key = m.tz(tz).format();
        if (!comments[key]) {
          comments[key] = Ember.A();
        }

        if (!comments[key].findBy('id', Ember.get(e, 'id'))) {
          comments[key].addObject(e);
        }
      };

      // Munge comments
      if (model.comments) {
        model.comments.forEach(mapComments);
      }

      var mapAutoAdjustmentRules = function mapAutoAdjustmentRules(e) {
        var m = (0, _moment.default)(Ember.get(e, 'ruleDate'));
        var key = m.tz(tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        mapped[key]['autoAdjustmentRules'] = e;
      };

      // Munge auto allocation rules
      if (model.autoAllocationCalendars) {
        model.autoAllocationCalendars.forEach(mapAutoAdjustmentRules);
      }

      var mapSellableRateValue = function mapSellableRateValue(e) {
        var key = _moment.default.tz(e.priceDate, tz).format();
        if (!mapped[key]) {
          mapped[key] = {};
        }

        mapped[key]['SELLABLE_RATE'] = {
          value: (0, _formatCurrency.default)(parseFloat(e.priceAmount || 0), e.currencyCode || "USD"),
          amount: e.priceAmount || 0
        };
      };

      // Munge sellable rate analytics
      if (this.isIgnorePricingRules) {
        model.calendarHeaders.forEach(mapSellableRateValue);
      }

      var generateDefaultClassNames = function generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle, actionable, frozen) {
        var names = ['matrix-pricing-cell'];
        if (isToday) {
          names.push("matrix-pricing-cell--active");
        }

        if ((isAfterYesterday || isToday) && actionable) {
          names.push("matrix-pricing-cell--actionable");
          if (frozen) {
            names.push("matrix-pricing-cell--frozen");
          }
        } else if (isBeforeYesterday) {
          names.push("matrix-pricing-cell--non-actionable");
        }

        // Alternate the weekly backgrounds.  Taking into account that
        // most years have 53 weeks in them.
        if (isWeeklyStyle) {
          names.push("matrix-pricing-cell--guide");
        }

        return names;
      };
      rules = (rules || []).rejectBy('externalReference', 'EVENT_TIME');
      rules.forEach(function (rule /*, i*/) {
        var amount = Ember.get(rule, 'amount');
        pricePoints['rule_' + amount] = {
          id: 'rule_' + amount,
          label: Ember.get(rule, 'shortDescription'),
          amount: amount,
          values: {},
          labelClassNames: "matrix-pricing-label__price-point"
        };
      });

      // Yieldable
      var yieldableHTML = (0, _lodash.template)('<div class="matrix-pricing-cell__yieldable-count">\n          <span data-id="value"><%= data.count %></span>\n          <%=( data.hideCalculated)? \'\': \'<div class="matrix-pricing-cell__count matrix-pricing-cell__text matrix-pricing-cell__text--rooms">' + this.get('i18n').t('customer.performance.matrix-pricing.rooms') + '</div>\'%>\n        </div>', { 'variable': "data" });

      // Allocated
      var inventoryHTML = (0, _lodash.template)('<div class="matrix-pricing-cell__percent">\n          <span data-id="value"><%= isNaN(data.percent) ? 0 : data.percent %></span>%\n        </div>\n        <div class="matrix-pricing-cell__count">\n          <span data-id="value"><%= isNaN(data.count) ? 0 : data.count %></span>\n          <%=( data.hideCalculated)?\'\': \'<span class="matrix-pricing-cell__text">' + this.get('i18n').t('customer.performance.matrix-pricing.rooms') + '</span>\'%>\n        </div>', { 'variable': "data" });

      // Rule Level
      var pricePointHTML = (0, _lodash.template)('<div data-value="<%= data.val %>" data-lead="<%= data.lead %>">\n        <div class="matrix-pricing-cell__price-allocation">\n         <%= (data.val > 0 || ( !isNaN(data.allocated) && data.allocated > 0) ) ? \'<span class="matrix-pricing-cell__price-allocated-percent">\' + data.val + \'%</span> <span class="matrix-pricing-cell__price-allocated-count">\' + (( !isNaN(data.allocated) && data.allocated > 0) ? data.allocated : \'0\') + \'</span><span class="annotation matrix-pricing-cell__label">' + this.get('i18n').t('customer.performance.matrix-pricing.rooms') + '</span>\' : \'&mdash;\' %>\n        </div>\n        <div class="matrix-pricing-cell__price-allocation-remaining">\n          <%= (data.remaining > 0 || data.val > 0) ? (isNaN(data.remaining) ? \'0\' : data.remaining) + \' <span class="annotation matrix-pricing-cell__label">' + this.get('i18n').t('customer.performance.matrix-pricing.rooms') + '</span><span class="matrix-pricing-cell__label">' + this.get('i18n').t('customer.performance.matrix-pricing.left') + '</span>\' : \'&mdash;\' %>\n        </div>\n        <div class="matrix-pricing-cell__price-lead-time">\n          <%= (data.lead != void(0)) ? \'<span class="matrix-pricing-cell__price-lead-time-badge">\' + data.lead + \'</span> <span class="matrix-pricing-cell__label">\' + data.leadLabel + \'</span>\' : \'&mdash;\' %>\n        </div>\n      </div>', { 'variable': "data" });

      // Time
      var timeHTML = (0, _lodash.template)('<%= (data.time != void(0)) ? \'<div class="matrix-pricing-cell__event-time--rule time-cell" data-date=\'+data.date+\' title=\'+data.occ+\'><div class="matrix-pricing-cell__event-time--time">\'+data.time+\'</div><div class="matrix-pricing-cell__event-time--rate">\'+data.amount+\'</div></div>\' : \'<i class="fa fa-clock-o time-cell" data-date=\'+data.date+\'></i>\' %>\n        ', { 'variable': "data" });

      // Freeze
      var freezeHTML = (0, _lodash.template)('<%= (data.freeze != void(0)) ? \'<i class="matrix-pricing-cell__freeze--rule matrix-pricing-cell__freeze--icon" data-date=\'+data.date+\'></i>\' : \'<i class=" matrix-pricing-cell__freeze--icon" data-date=\'+data.date+\'></i>\' %>\n        ', { 'variable': "data" });

      var todayFreezeHTML = (0, _lodash.template)('<%= \'<i class="matrix-pricing-cell__freeze--rule matrix-pricing-cell__freeze--icon matrix-pricing-cell__freeze--icon__today " data-date=\'+data.date+\'>\'+data.amount+\'</i>\' %>\n        ', { 'variable': "data" });

      var autoAllocationHTML = (0, _lodash.template)('<%= (data.autoAllocation != void(0)) ? \'<i class="fa fa-retweet matrix-pricing-cell__auto-allocation--rule matrix-pricing-cell__auto-allocation--icon" data-date=\'+data.date+\'></i>\' : \'<i class="fa fa-retweet matrix-pricing-cell__auto-allocation--icon" data-date=\'+data.date+\'></i>\' %>\n        ', { 'variable': "data" });

      Object.keys(mapped).forEach(function (key) {
        // Remove non daily calendars
        var date = _moment.default.tz(key, tz);
        if (!date.isSame((0, _moment.default)(date).startOf('day'))) {
          delete mapped[key];
          return;
        }

        var map = mapped[key];
        var calendars = map.calendars || rules;
        var isBeforeYesterday = date.isBefore(yesterday);
        var isAfterYesterday = date.isSameOrAfter(yesterday, 'day');
        var isToday = date.isSame(today, 'day');
        var isWeeklyStyle = (0, _moment.default)(date).startOf('week').week() % 2 === 0;

        // --> Inventory

        yieldableVals[key] = {
          value: yieldableHTML({
            count: (0, _accounting.formatNumber)(map.inventory_yieldable, 0),
            hideCalculated: _this.get('hideCalculated')
          }),
          classNames: ['matrix-pricing-cell__yieldable'].concat(map.inventory_yieldable < map.inventory_physical ? ['matrix-pricing-cell--danger'] : [], generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        allocationVals[key] = {
          count: 0,
          value: inventoryHTML({ count: 0, percent: 0, hideCalculated: _this.get('hideCalculated') }),
          classNames: ["matrix-pricing-cell__allocation"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        // Consumed
        var remainingConsumed = map.inventory_consumed;
        var consumedPct = map.inventory_yieldable ? Math.floor(map.inventory_consumed / map.inventory_yieldable * 100) : 0;
        consumedVals[key] = {
          count: map.inventory_consumed,
          value: inventoryHTML({
            count: map.inventory_consumed,
            percent: consumedPct,
            hideCalculated: _this.get('hideCalculated')
          }),
          classNames: ["matrix-pricing-cell__consumed"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        var activeIndex = undefined;
        var activeRule = undefined;

        sellableVals[key] = {
          value: '&mdash;',
          amount: undefined,
          classNames: ['matrix-pricing-cell__sellable', 'matrix-pricing-cell--danger'].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        var hasFreeze = mapped[key]['freezes'];
        // Event Time Rules
        eventTimes[key] = {
          value: timeHTML({ time: void 0, date: key }),
          actionable: false,
          classNames: ["matrix-pricing-cell__event-time"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle, true, hasFreeze))
        };

        if (mapped[key]['eventTimes']) {
          var timeRule = mapped[key]['eventTimes'];
          var timeDesc = _this._formatTimeDescription(Ember.get(timeRule, 'shortDescription'));
          var timeFilters = Ember.get(timeRule, "children.firstObject.children.firstObject.children") || [];
          var timeEquality = timeFilters ? _this._timeEqualityLabel(Ember.get(timeFilters.findBy('filterField', 'OCC_PCT'), 'filterCondition')) : "";
          var timeOccupancy = timeFilters ? Ember.get(timeFilters.findBy('filterField', 'OCC_PCT' || []), 'filterValue') : "";
          var timeOccDisplay = timeFilters ? timeEquality.replace('>', '&gt;').replace('<', '&lt;') + timeOccupancy + "%" : ""; // ex. <=50%
          var timeRate = (0, _formatCurrency.default)(Ember.get(timeRule, 'amount'), Ember.get(timeRule, 'currencyCode') || "USD");
          eventTimes[key] = {
            value: timeHTML({ time: timeDesc, date: key, occ: timeOccDisplay, amount: timeRate }),
            actionable: false,
            classNames: ["matrix-pricing-cell__event-time"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle, true, hasFreeze))
          };
        }

        // Freeze Rules
        freezes[key] = {
          value: freezeHTML({ freeze: void 0, date: key }),
          actionable: false,
          classNames: ["matrix-pricing-cell__freeze"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        if (mapped[key]['freezes']) {
          var amount = Ember.get(mapped[key]['freezes'], 'amount');
          var currencyCode = Ember.get(mapped[key]['freezes'], 'currencyCode') || "USD";
          if (amount) {
            freezes[key] = {
              value: todayFreezeHTML({
                freeze: "true",
                amount: (0, _formatCurrency.default)(amount, currencyCode),
                date: key
              }),
              actionable: false,
              classNames: ["matrix-pricing-cell__freeze"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
            };
          } else {
            freezes[key] = {
              value: freezeHTML({ freeze: "true", date: key }),
              actionable: false,
              classNames: ["matrix-pricing-cell__freeze"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
            };
          }
        }

        // Auto Allocation Rules
        autoAdjustmentRules[key] = {
          value: autoAllocationHTML({ autoAllocation: void 0, date: key }),
          actionable: false,
          classNames: ["matrix-pricing-cell__auto-allocation"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
        };

        if (mapped[key]['autoAdjustmentRules'] || _this.get('hasOngoingAutoAllocationRule') && _moment.default.tz(key, _this.tz.get('customer')).startOf('day').isSameOrAfter(_moment.default.tz(_this.get('autoAllocationRule.beginDate'), _this.tz.get('customer')).startOf('day'))) {
          autoAdjustmentRules[key] = {
            value: autoAllocationHTML({
              autoAllocation: "true",
              date: key
            }),
            actionable: false,
            classNames: ["matrix-pricing-cell__auto-allocation"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
          };
        }

        // --> Price Points

        var accumulatedActualPercent = 0;
        var remainingSinceActive = 0;
        var ruleIds = [];

        calendars = calendars.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

        calendars.forEach(function (rule, i) {
          var ruleId = 'rule_' + Ember.get(rule, 'amount');
          ruleIds.push(ruleId);
          var allocation = _this._computeAllocation(rule);

          accumulatedActualPercent += allocation.value;

          var consumed = 0;

          var allocatedSoFar = Math.floor(accumulatedActualPercent / 100 * map.inventory_yieldable);
          var allocated = allocatedSoFar - allocationVals[key].count;

          allocationVals[key].count += allocated;

          var decoratorClasses = [];

          // Consume all available inventory to fill up to the active point
          if (remainingConsumed > 0) {
            consumed = Math.min(allocated, remainingConsumed);
            remainingConsumed -= consumed;
          }

          var remaining = allocated - consumed;

          if (activeIndex) {
            remainingSinceActive += remaining;
          }

          if (activeIndex === void 0 && (consumed < allocated || remainingConsumed === 0) && (allocated > 0 || i === calendars.get('length') - 1) && remaining > 0) {
            if (!activeIndex) {
              remainingSinceActive += remaining;
            }

            decoratorClasses.push("matrix-pricing-cell--active-highlight");
            activeIndex = ruleId;
            activeRule = (0, _formatCurrency.default)(Ember.get(rule, 'amount'), Ember.get(rule, 'currencyCode') || "USD");
            remaining = remainingSinceActive;

            if (!_this.isIgnorePricingRules) {
              sellableVals[key] = {
                value: Ember.get(rule, 'shortDescription'),
                amount: Ember.get(rule, 'amount'),
                classNames: ["matrix-pricing-cell__sellable"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
              };
            }
          }

          // Set active for lead time if it is present
          if (allocation.leadTime !== void 0 && today.isAfter((0, _moment.default)(date).subtract(allocation.leadTime + 1, 'days')) && (remaining > 0 || remainingSinceActive > 0)) {
            if (!activeIndex) {
              remainingSinceActive += remaining;
            }

            if (activeIndex) {
              var activePricePoint = pricePoints[activeIndex] ? pricePoints[activeIndex].values[key] : false;

              if (activePricePoint) {
                activePricePoint["classNames"] = activePricePoint["classNames"].filter(function (c) {
                  return c !== "matrix-pricing-cell--active-highlight";
                });
              }

              for (var _i = 0; _i < ruleIds.length - 1; _i++) {
                if (pricePoints[ruleIds[_i]]) {
                  pricePoints[ruleIds[_i]].values[key].values.remaining = 0;
                }
              }
            }

            decoratorClasses.push("matrix-pricing-cell--active-highlight");
            activeIndex = ruleId;
            activeRule = (0, _formatCurrency.default)(Ember.get(rule, 'amount'), Ember.get(rule, 'currencyCode') || "USD");
            remaining = remainingSinceActive;

            if (!_this.isIgnorePricingRules) {
              sellableVals[key] = {
                value: Ember.get(rule, 'shortDescription'),
                amount: Ember.get(rule, 'amount'),
                classNames: ["matrix-pricing-cell__sellable"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle))
              };
            }
          }

          // Setup price point's styling and data values
          if (pricePoints[ruleId]) {
            var _hasFreeze = mapped[key]['freezes'];
            var leadTime = allocation.leadTime;
            var leadLabel = leadTime && leadTime !== 1 ? _this.get('i18n').t('customer.performance.matrix-pricing.many') : _this.get('i18n').t('customer.performance.matrix-pricing.one');
            pricePoints[ruleId].values[key] = {
              values: {
                val: allocation.value,
                consumed: consumed,
                allocated: allocated,
                remaining: remaining,
                lead: leadTime,
                leadLabel: leadLabel
              },
              actionable: !isBeforeYesterday,
              classNames: ["td-table"].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle, true, _hasFreeze)).concat(decoratorClasses)
            };
          }
        });

        // Setup styling for sellable rates if using analytics
        if (_this.isIgnorePricingRules) {
          if (mapped[key]['SELLABLE_RATE']) {
            sellableVals[key] = {
              value: mapped[key]['SELLABLE_RATE'].value,
              amount: mapped[key]['SELLABLE_RATE'].amount,
              classNames: ['matrix-pricing-cell__sellable'].concat(generateDefaultClassNames(isBeforeYesterday, isAfterYesterday, isToday, isWeeklyStyle)).concat(activeRule && (!hasFreeze || hasFreeze && !isToday) && activeRule !== mapped[key]['SELLABLE_RATE'].value ? 'matrix-pricing-cell__sellable--warning' : '')
            };
          }
        }

        // Loop through and render html for price points after day is completed
        for (var i = 0; i < ruleIds.length; i++) {
          if (pricePoints[ruleIds[i]]) {
            pricePoints[ruleIds[i]].values[key].value = pricePointHTML(pricePoints[ruleIds[i]].values[key].values);
          }
        }

        var allocatedPct = Math.floor(allocationVals[key].count / map.inventory_yieldable * 100);
        allocationVals[key].value = inventoryHTML({ count: allocationVals[key].count, percent: allocatedPct });
        // Set classes for under allocation
        if (allocatedPct < 100) {
          allocationVals[key].classNames = ["matrix-pricing-cell--danger"].concat(allocationVals[key].classNames);
        }

        return map;
      });

      return {
        sellableVals: sellableVals,
        pricePoints: pricePoints,
        yieldableVals: yieldableVals,
        allocationVals: allocationVals,
        consumedVals: consumedVals,
        comments: comments,
        eventTimes: eventTimes,
        freezes: freezes,
        autoAdjustmentRules: autoAdjustmentRules
      };
    },


    _processData: Ember.observer('model', function () {
      var _this2 = this;

      var model = this.get('model');
      var mapped = this._mapValues(model);
      var columns = this._columnsForRange(this.get('beginDate'), this.get('endDate'), mapped, (0, _moment.default)(this.get('startDate')));

      // create table rows
      var rows = [{ id: "sellableVals", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.sellable'), values: mapped.sellableVals, labelClassNames: "matrix-pricing-label__sellable-bar" }, { id: "yieldableVals", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.yieldable'), values: mapped.yieldableVals, labelClassNames: "matrix-pricing-label__yieldable" }, { id: "allocationVals", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.allocated'), values: mapped.allocationVals, labelClassNames: "matrix-pricing-label__allocated" }, { id: "consumedVals", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.consumed'), values: mapped.consumedVals, labelClassNames: "matrix-pricing-label__consumed" }];
      // add auto-adjust row first if setting present and true (will end up in second row)
      if (this.get('isDisplayAutoAdjust')) {
        rows = rows.insertAt(0, { id: "autoAdjustmentRules", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.auto_allocation'), values: mapped.autoAdjustmentRules, labelClassNames: "matrix-pricing-label__auto-allocations" });
      }
      // add freeze row first if setting present and true
      if (this.get('isDisplayFreeze')) {
        rows = rows.insertAt(0, { id: "freezes", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.freeze'), values: mapped.freezes, labelClassNames: "matrix-pricing-label__freezes" });
      }
      // add event time row if setting present and true
      if (this.get('isDisplayEventTime')) {
        rows.push({ id: "eventTimes", label: this.get('i18n').t('customer.performance.matrix-pricing.row_labels.time_of_day'), values: mapped.eventTimes, labelClassNames: "matrix-pricing-label__event-times" });
      }
      // add pricepoints
      rows = rows.concat((0, _lodash.values)(mapped.pricePoints));

      var groups = [{
        id: "inventory",
        rows: Ember.A(),
        classNames: "matrix-pricing-label__header"
      }];

      groups[0].rows.addObjects(rows);

      Ember.run.later(function () {
        Ember.run.schedule("afterRender", function () {
          // override default table header cell event action
          Ember.$('.matrix-pricing__comment').off('click', _this2._commentClick);
          Ember.$('.matrix-pricing__comment').on('click', _this2, _this2._commentClick);
          // override default table cell event action
          Ember.$('.matrix-pricing-cell__event-time').off('click', _this2._timeClick);
          Ember.$('.matrix-pricing-cell__event-time').on('click', _this2, _this2._timeClick);
          Ember.$('.matrix-pricing-cell__freeze').off('click', _this2._freezeClick);
          Ember.$('.matrix-pricing-cell__freeze').on('click', _this2, _this2._freezeClick);
          Ember.$('.matrix-pricing-cell__auto-allocation').off('click', _this2._autoAdjustClick);
          Ember.$('.matrix-pricing-cell__auto-allocation').on('click', _this2, _this2._autoAdjustClick);
        });
      }, 1000);

      this.set('pricePoints', (0, _lodash.values)(mapped.pricePoints));
      this.set('sellableVals', mapped.sellableVals);
      this.set('freezes', mapped.freezes);
      this.set('autoAdjustmentCells', mapped.autoAdjustmentRules);
      // Set up initial table
      this.set('performanceTableData', {
        groups: groups,
        columns: columns
      });

      if (!this.get('isMobile')) {

        Ember.run.later(function () {
          _this2.send('loadNext', 7, true);
        }, 1000);
        Ember.run.later(function () {
          _this2.send('loadPrevious', 10, true);
        }, 2000);
        Ember.run.later(function () {
          _this2.send('loadNext', 7, true);
          Ember.$('.table-matrix-pricing').css("maxWidth", "100%");
        }, 3000);
      }
    }),

    _hijackModel: function _hijackModel(data, beginDate, timespan) {
      var _this3 = this;

      var unshift = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

      var mapped = this._mapValues(data);
      var columns = this.get('performanceTableData.columns');

      var newCols = this._columnsForRange(beginDate, (0, _moment.default)(beginDate).add(timespan, 'days'), mapped);

      if (unshift) {
        columns.unshiftObjects(newCols);
      } else {
        columns.pushObjects(newCols);
      }

      var tableData = this.get('performanceTableData');
      ['inventory'].forEach(function (id) {
        tableData.groups.findBy('id', id).rows.forEach(function (row) {
          if (row.id.match(/^rule_/)) {
            Ember.merge(row.values, ((0, _lodash.values)(mapped.pricePoints).findBy('id', row.id) || {}).values);
          } else {
            Ember.merge(row.values, mapped[row.id]);
          }
        });
      });
      this.set('freezes', Ember.merge(this.get('freezes'), mapped.freezes));
      this.set('autoAdjustmentCells', Ember.merge(this.get('autoAdjustmentCells'), mapped.autoAdjustmentRules));
      this.set('scrollingPaused', false);
      this.get('table').trigger('tableInvalidate');
      Ember.run.schedule("afterRender", function () {
        Ember.$('#main-content').trigger('scroll');
        // override default table header cell event action
        Ember.$('.matrix-pricing__comment').off('click', _this3._commentClick);
        Ember.$('.matrix-pricing__comment').on('click', _this3, _this3._commentClick);
        // override default table cell event action
        Ember.$('.matrix-pricing-cell__event-time').off('click', _this3._timeClick);
        Ember.$('.matrix-pricing-cell__event-time').on('click', _this3, _this3._timeClick);
        Ember.$('.matrix-pricing-cell__freeze').off('click', _this3._freezeClick);
        Ember.$('.matrix-pricing-cell__freeze').on('click', _this3, _this3._freezeClick);
        Ember.$('.matrix-pricing-cell__auto-allocation').off('click', _this3._autoAdjustClick);
        Ember.$('.matrix-pricing-cell__auto-allocation').on('click', _this3, _this3._autoAdjustClick);
      });
    },
    _commentClick: function _commentClick(e) {
      e.data.send('addComment', Ember.$(e.target).closest('.matrix-pricing__comment').data('date'));
      e.preventDefault();
      e.stopPropagation();
    },
    _autoAdjustClick: function _autoAdjustClick(e) {
      var date = Ember.$(e.target).find('.matrix-pricing-cell__auto-allocation--icon').data('date') || Ember.$(e.target).closest('.matrix-pricing-cell__auto-allocation--icon').data('date');
      if (date) {
        e.data.send('transitionToAutoAllocations', date);
      }
    },
    _timeClick: function _timeClick(e) {
      var date = Ember.$(e.target).find('.time-cell').data('date') || Ember.$(e.target).closest('.time-cell').data('date');
      if (date) {
        e.data.send('editEventTime', date);
      }
      e.preventDefault();
      e.stopPropagation();
    },
    _freezeClick: function _freezeClick(e) {
      var element = Ember.$(e.target).find('.matrix-pricing-cell__freeze--icon').length > 0 ? Ember.$(e.target).find('.matrix-pricing-cell__freeze--icon') : Ember.$(e.target).closest('.matrix-pricing-cell__freeze--icon');

      if (element) {
        var date = element.data('date');
        if (element.hasClass('matrix-pricing-cell__freeze--rule')) {
          e.data.send('addFreeze', date, false); // has freeze rule, remove freeze
        } else {
          e.data.send('addFreeze', date, true); // doesn't have freeze rule, create freeze
        }
      }
      e.preventDefault();
      e.stopPropagation();
    },
    _overlayModel: function _overlayModel(data, key) {
      var mapped = this._mapValues(data);

      var tableData = this.get('performanceTableData');

      ['inventory'].forEach(function (id) {
        tableData.groups.find(function (g) {
          return g.id === id;
        }).rows.forEach(function (row) {
          var cell = row.values[key];
          if (!cell) {
            cell = Ember.Object.create({});
            row.values[key] = cell;
          }

          if (row.id.match(/^rule_/)) {
            Ember.setProperties(cell, ((0, _lodash.values)(mapped.pricePoints).find(function (p) {
              return p.id === row.id;
            }) || { values: {} }).values[key]);
          } else {
            Ember.setProperties(cell, mapped[row.id][key]);
          }
        });
      });

      this.get('pricePoints').forEach(function (pp) {
        var cell = pp.values[key];
        if (pp.id.match(/^rule_/)) {
          Ember.setProperties(cell, ((0, _lodash.values)(mapped.pricePoints).find(function (p) {
            return p.id === pp.id;
          }) || { values: {} }).values[key]);
        } else {
          Ember.setProperties(cell, mapped[pp.id][key]);
        }
      });
    },
    _composeRule: function _composeRule(key, rowId, allocation) {
      var _this4 = this;

      // allocation rule
      var boundedTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterCondition":"<%= data.condition %>",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.lower %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          },\n          {\n            "filterCondition":"LTEQ",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.upper %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          }\n        ],\n        "filterType":"ALL",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // lead time rule
      var leadTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterCondition":"LTEQ",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.upper %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          },\n          {\n            "filterCondition":"LT",\n            "filterField":"ARRIVAL_DATE",\n            "filterField2":"BOOKING_DATE",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.leadTime %>",\n            "filterValueRule":"ADD",\n            "ruleMethod":"FILTER"\n          }\n        ],\n        "filterType":"ALL",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // any filter rule
      var anyTemplate = (0, _lodash.template)('\n      {\n        "children":[<%= data.children %>],\n        "filterType":"ANY",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });

      // base rule
      var ruleTemplate = (0, _lodash.template)('\n      {\n        "amount":<%= data.amount %>,\n        "amountRule":"VALUE",\n        "calculationRule":"AMOUNT_NOOP",\n        "day1":"Y",\n        "day2":"Y",\n        "day3":"Y",\n        "day4":"Y",\n        "day5":"Y",\n        "day6":"Y",\n        "day7":"Y",\n        "ruleMethod":"RULE",\n        "shortDescription":"<%= data.title %>",\n        "children":[<%= data.children %>]\n      }', { variable: "data" });

      var tz = this.tz.get('customer');
      var calendar = this.get('model').calendars.find(function (c) {
        var m = (0, _moment.default)(Ember.get(c, 'ruleDate'));
        return m.tz(tz).format() === key;
      });

      var children = calendar ? Ember.get(calendar, 'children') || [] : [];
      var rules = children.get('length') > 0 && children.get('firstObject') && children.get('firstObject.amount') ? children : this.get('ruleSet.children');
      var newRules = [];
      var timeRules = rules.filterBy('externalReference', 'EVENT_TIME') || [];
      var freezeRules = rules.filterBy('externalReference', 'FREEZE') || [];
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'FUTURE_FREEZE'));
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'CEILING_RULE'));
      rules = rules.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE') || [];
      // compose pricepoint rules
      var aggregateOcc = 0;
      rules.forEach(function (rule /*, i*/) {
        var ruleRowId = 'rule_' + Ember.get(rule, 'amount');

        var newRuleContent = ruleTemplate({
          title: Ember.get(rule, 'shortDescription'),
          amount: Ember.get(rule, 'amount')
        });

        var currentAllocation = _this4._computeAllocation(rule);

        var isPassThrough = (currentAllocation.value > 0 || currentAllocation.leadTime !== void 0) && ruleRowId !== rowId;
        var isAllocated = (allocation.value > 0 || allocation.leadTime !== void 0) && ruleRowId === rowId;

        if (isAllocated || isPassThrough) {
          var allChildren = [];
          var data = isAllocated ? allocation : currentAllocation;

          allChildren.push(boundedTemplate({
            condition: aggregateOcc > 0 ? "GT" : "GTEQ",
            lower: aggregateOcc,
            upper: aggregateOcc + data.value
          }));

          if (data.leadTime !== void 0) {
            allChildren.push(leadTemplate({
              leadTime: data.leadTime,
              upper: aggregateOcc + data.value
            }));
          }

          aggregateOcc += data.value;
          newRuleContent = ruleTemplate({
            title: Ember.get(rule, 'shortDescription'),
            amount: Ember.get(rule, 'amount'),
            children: anyTemplate({ children: allChildren.toString() })
          });
        }

        newRules.push(JSON.parse(newRuleContent));
      });
      // compose event time rule
      if (timeRules.length > 0) {
        newRules.push(timeRules[0]);
      }
      // compose freeze rule
      if (freezeRules.length > 0) {
        newRules.push(freezeRules[0]);
      }
      return newRules;
    },
    _composeTimeRule: function _composeTimeRule(key, pricePoint, equality, occupancy, timeOfDayEquality, timeOfDay, isSave) {
      // event time rule
      var boundedTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterCondition":"<%= data.equality %>",\n            "filterField":"OCC_PCT",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.occupancy %>",\n            "ruleMethod":"FILTER"\n          },\n          {\n            "filterCondition":"<%= data.timeOfDayEquality %>",\n            "filterField":"EVENT_TIME",\n            "filterType":"FIELD_WHERE",\n            "filterValue":"<%= data.timeOfDay %>",\n            "ruleMethod":"FILTER"\n          }\n        ],\n        "filterType":"ALL",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // any filter rule
      var anyTemplate = (0, _lodash.template)('\n      {\n        "children":[<%= data.children %>],\n        "filterType":"ANY",\n        "ruleMethod":"FILTER"\n      }', { variable: "data" });
      // base rule
      var timeRuleTemplate = (0, _lodash.template)('\n      {\n        "amount":<%= data.amount %>,\n        "amountRule":"VALUE",\n        "calculationRule":"AMOUNT_NOOP",\n        "day1":"Y",\n        "day2":"Y",\n        "day3":"Y",\n        "day4":"Y",\n        "day5":"Y",\n        "day6":"Y",\n        "day7":"Y",\n        "ruleMethod":"RULE",\n        "externalReference":"EVENT_TIME",\n        "shortDescription":"<%= data.title %>",\n        "children":[<%= data.children %>]\n      }', { variable: "data" });

      var tz = this.tz.get('customer');
      var calendar = this.get('model').calendars.find(function (c) {
        var m = (0, _moment.default)(Ember.get(c, 'ruleDate'));
        return m.tz(tz).format() === key;
      });

      var rules = calendar ? Ember.get(calendar, 'children') : this.get('ruleSet.children');
      var newRules = [];
      var freezeRules = rules.filterBy('externalReference', 'FREEZE') || [];
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'FUTURE_FREEZE'));
      freezeRules = freezeRules.concat(rules.filterBy('externalReference', 'CEILING_RULE'));
      // compose pricepoint rules
      var pricePointRules = rules.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE') || [];
      pricePointRules.forEach(function (pricePoint) {
        newRules.push(pricePoint);
      });
      // if saving event time rule, create it; otherwise will "delete" by not saving existing rule
      if (isSave) {
        rules = rules.filterBy('externalReference', 'EVENT_TIME') || [];

        var newRuleContent = timeRuleTemplate({
          title: timeOfDay,
          amount: pricePoint
        });

        var allChildren = [];

        allChildren.push(boundedTemplate({
          value: pricePoint,
          occupancy: occupancy,
          equality: equality,
          timeOfDayEquality: timeOfDayEquality,
          timeOfDay: timeOfDay,
          title: timeOfDay
        }));

        newRuleContent = timeRuleTemplate({
          title: timeOfDay,
          amount: pricePoint,
          children: anyTemplate({ children: allChildren.toString() })
        });
        var newRule = JSON.parse(newRuleContent);
        newRules.push(newRule);
      }
      // compose freeze rule
      if (freezeRules.length > 0) {
        newRules.push(pricePoint);
      }
      return newRules;
    },
    _composeFreezeRule: function _composeFreezeRule(key, isSave, currentRate) {
      var tz = this.tz.get('customer');
      var today = _moment.default.tz(tz).startOf("day");
      var date = _moment.default.tz(key, tz).startOf("day");
      var afterToday = date.isAfter(today, 'day');

      var boundedTemplate = (0, _lodash.template)('\n      {\n        "children":[\n          {\n            "filterType":"<%= data.type %>",\n            "ruleMethod":"FILTER"\n          }\n        ]\n      }', { variable: "data" });

      // freeze rule
      var freezeRuleTemplate = (0, _lodash.template)('\n      {\n        "amount":<%= data.amount %>,\n        "amountRule":"VALUE",\n        "calculationRule":"AMOUNT_NOOP",\n        "day1":"Y",\n        "day2":"Y",\n        "day3":"Y",\n        "day4":"Y",\n        "day5":"Y",\n        "day6":"Y",\n        "day7":"Y",\n        "ruleMethod":"RULE",\n        "filterType":"<%= data.type %>",\n        "externalReference":"<%= data.type %>",\n        "shortDescription":"<%= data.title %>",\n        "children":[<%= data.children %>]\n      }', { variable: "data" });

      var futureFreezeRuleTemplate = (0, _lodash.template)('\n      {\n        "amountRule":"VALUE",\n        "calculationRule":"AMOUNT_NOOP",\n        "day1":"Y",\n        "day2":"Y",\n        "day3":"Y",\n        "day4":"Y",\n        "day5":"Y",\n        "day6":"Y",\n        "day7":"Y",\n        "ruleMethod":"RULE",\n        "filterType":"<%= data.type %>",\n        "externalReference":"<%= data.type %>",\n        "shortDescription":"<%= data.title %>",\n        "children":[<%= data.children %>]\n      }', { variable: "data" });

      var calendar = this.get('model').calendars.find(function (c) {
        var m = (0, _moment.default)(Ember.get(c, 'ruleDate'));
        return m.tz(tz).format() === key;
      });

      var rules = calendar ? Ember.get(calendar, 'children') : this.get('ruleSet.children');
      var newRules = [];
      // compose pricepoint rules
      var pricePointRules = rules.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE');

      pricePointRules.forEach(function (pricePoint) {
        newRules.push(pricePoint);
      });

      // compose event time rule
      var timeRules = rules.filterBy('externalReference', 'EVENT_TIME');
      if (timeRules.length > 0) {
        newRules.push(timeRules[0]);
      }

      // if creating freeze rule, create it; otherwise will "delete" by not saving existing rule
      if (isSave) {
        var allChildren = [];

        allChildren.push(boundedTemplate({
          title: afterToday ? this.get('i18n').t('customer.performance.matrix-pricing.freeze.future_freeze') : currentRate,
          type: afterToday ? "FUTURE_FREEZE" : "FREEZE"
        }));

        var newRuleContent = afterToday ? futureFreezeRuleTemplate({
          title: this.get('i18n').t('customer.performance.matrix-pricing.freeze.future_freeze'),
          type: "FUTURE_FREEZE",
          children: allChildren.toString()
        }) : freezeRuleTemplate({
          title: currentRate,
          amount: currentRate,
          type: "FREEZE",
          children: allChildren.toString()
        });

        var newRule = JSON.parse(newRuleContent);
        newRules.push(newRule);
      }

      return newRules;
    },
    _computeAllocation: function _computeAllocation(rule) {
      var allocation = {
        value: 0,
        leadTime: void 0
      };

      var filterCollection = Ember.get(rule, "children.firstObject.children") || [];
      var isEmpty = filterCollection === void 0 || filterCollection.get("length") === 0;
      if (isEmpty) {
        return allocation;
      }

      var occFilters = filterCollection.get('firstObject.children') || [];
      allocation.value = occFilters.filterBy('filterField', 'OCC_PCT').mapBy('filterValue').reduce(function (a, b) {
        return Math.abs(a - b);
      }, 0);

      if (!parseInt(allocation.value)) {
        allocation.value = 0;
      }

      var hasLeadTime = filterCollection.get('length') > 1;
      if (hasLeadTime) {

        var leadTimeChildren = Ember.get(filterCollection.objectAt(1), 'children');

        if (!leadTimeChildren) {
          console.error("Malformed lead time rule - missing children");
        } else {
          var leadTime = leadTimeChildren.filterBy('filterField', "ARRIVAL_DATE").filterBy('filterField2', "BOOKING_DATE").get('firstObject.filterValue');

          allocation.leadTime = parseInt(leadTime) || parseInt(leadTime) === 0 ? parseInt(leadTime) : void 0;
        }
      }

      return allocation;
    },
    _updateCalendar: function _updateCalendar(rules, key) {
      var dirty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      var tz = this.tz.get("customer");
      var date = (0, _moment.default)(key).tz(tz);

      var calendar = this.get('model').calendars.find(function (c) {
        return (0, _moment.default)(Ember.get(c, 'ruleDate')).tz(tz).valueOf() === date.valueOf();
      });

      if (calendar) {
        Ember.set(calendar, 'children', rules);
        Ember.set(calendar, 'isDirty', dirty);
      } else {
        calendar = {
          ruleDate: date.format(),
          children: rules,
          isDirty: dirty
        };

        this.get('model').calendars.addObject(calendar);
      }

      var results = {
        inventory: this.get('model').inventory.filter(function (i) {
          return (0, _moment.default)(Ember.get(i, 'beginDate')).tz(tz).valueOf() === date.valueOf();
        }),
        calendars: [calendar],
        calendarHeaders: this.model.calendarHeaders
      };

      this._overlayModel(results, key);
    },


    tableClassNames: Ember.computed('hideLeftToSell', 'hideCalculated', function () {
      var classes = ["table-condensed", "table-pricing", "table-matrix-pricing-fixed"];

      if (this.get('hideLeftToSell')) {
        classes.push('table-matrix-pricing--hide-left-to-sell');
      }

      if (this.get('hideCalculated')) {
        classes.push('table-matrix-pricing--hide-calculated');
      }

      Ember.run.schedule("afterRender", function () {
        Ember.$(window).trigger('table.style-changed');
      });

      return classes.join(' ');
    }),

    _columnsForRange: function _columnsForRange(beginDate, endDate, mapped, selectedDate) {
      var tz = this.tz.get('customer');
      var today = _moment.default.tz(tz).startOf('day');
      var yesterday = (0, _moment.default)(today).subtract(1, 'day');
      var columns = [];

      if (!selectedDate) {
        selectedDate = today;
      }
      // table header
      var headerHTML = (0, _lodash.template)('\n        <% if (data.comments && data.comments.length > 0) { %>\n          <div style=\'position:relative;margin-top: -15px;margin-left: 46px;\' class=\'matrix-pricing__comment\' data-date=\'<%= data.date %>\'>\n              <i class=\'fa fa-comment\'></i>\n          </div>\n        <% } else { %>\n          <div style=\'position:relative;margin-top: -15px;margin-left: 46px;\' class=\'matrix-pricing__comment\' data-date=\'<%= data.date %>\'>\n              <i class=\'fa fa-comment-o\'></i>\n              <i class=\'fa fa-plus\' style=\'position: absolute;margin-top: -5px;margin-left: 7px;font-size: .8em;\'></i>\n          </div>\n        <% } %>\n        <div style=\'text-align: center;\'><span class=\'day-of-week\'><%= data.date.format("dd")%></span></div>\n        <div style=\'text-align: center;\'><span><%= data.date.format(data.subDateFormat) %></span></div>', { 'variable': "data" });
      // construct columns
      (0, _momentRange.default)(beginDate, endDate).forEach(function (m) {
        var date = m.tz(tz);
        var subDateFormat = date.format("DD") === "01" ? "MMM D" : "D";
        var column = {
          key: date.format(),
          label: headerHTML({ date: date, subDateFormat: subDateFormat, comments: mapped["comments"][date.format()] })
        };

        column.actionable = date.isSameOrAfter(yesterday, 'day') && !mapped['freezes'][date.format()];

        var names = ['matrix-pricing-header-cell'];
        if (date.valueOf() === today.valueOf()) {
          names.push("matrix-pricing-cell--active");
        }

        if (date.valueOf() === selectedDate.valueOf()) {
          names.push("selected");
        }

        // Alternate the weekly backgrounds.  Taking into account that
        // most years have 53 weeks in them.
        if ((0, _moment.default)(date).startOf('week').week() % 2 === 0) {
          names.push("matrix-pricing-cell--guide");
        }

        if (column.actionable) {
          names.push("matrix-pricing-cell--actionable");
        } else if (date.isBefore(yesterday, 'day')) {
          names.push("matrix-pricing-cell--non-actionable");
        }

        column.classNames = names.join(' ');
        columns.push(column);
      });

      return columns;
    },
    _findTimeRules: function _findTimeRules(date) {
      var tz = this.tz.get("customer");
      var calendar = this.get('model').calendars.find(function (c) {
        var m = (0, _moment.default)(Ember.get(c, 'ruleDate'));
        return m.tz(tz).format() === date;
      });
      var rules = calendar ? Ember.get(calendar, 'children') : this.get('ruleSet.children');
      if (!this.get('isDisplayEventTime')) {
        return [];
      }
      return rules.filterBy('externalReference', 'EVENT_TIME') || [];
    },
    _serialize: function _serialize(date, pricePoints) {
      return Ember.$.extend({
        date: date || void 0,
        pricePoints: pricePoints
      }, this._super());
    },
    _hasFreeze: function _hasFreeze(columnKey) {
      var hasFreeze = false;
      if (this.get('freezes')[columnKey]) {
        var freeze = this.get('freezes')[columnKey].value;
        if (Ember.$(freeze).hasClass('matrix-pricing-cell__freeze--rule')) {
          hasFreeze = true;
        }
      }
      return hasFreeze;
    },
    _formatTimeDescription: function _formatTimeDescription(time) {
      return (0, _moment.default)(time, "HH:mm").format("hh:mmA"); // format 24 hour time into 12 hour AM/PM
    },
    _timeEqualityLabel: function _timeEqualityLabel(equality) {
      switch (equality) {
        case 'LTEQ':
          return "<=";
        case 'LT':
          return "<";
        case 'GTEQ':
          return ">=";
        case 'GT':
          return ">";
      }
    },


    actions: {
      cellEditRequested: function cellEditRequested(context, columnKey, rowId /*, groupId*/) {
        var _this5 = this;

        var $cell = context.$();
        var $cellContent = $cell.find("> div:first-of-type").detach();
        var $cellHtml = $cellContent.find("> div");

        var $container = Ember.$('<div class="matrix-pricing-cell__edit-container"></div>');

        var valueString = $cellHtml.data('value');
        var leadString = $cellHtml.data('lead');

        var previousColumn = function previousColumn() {
          context.$().prev('td').find('> div').trigger('click');
        };

        var nextColumn = function nextColumn() {
          context.$().next('td').find('> div').trigger('click');
        };

        var next = function next() {
          Ember.$(context.$().parent().next('tr').find('td').get(context.$().index())).find('> div').trigger('click');
        };

        var previous = function previous() {
          Ember.$(context.$().parent().prev('tr').find('td').get(context.$().index())).find('> div').trigger('click');
        };

        var update = function update(allocation, leadTime) {
          allocation = parseInt(allocation) ? parseInt(allocation) : 0;
          if (allocation !== valueString || leadTime !== leadString) {
            var allocated = {
              value: allocation,
              leadTime: leadTime !== "" && (parseInt(leadTime) || parseInt(leadTime) === 0) ? parseInt(leadTime) : void 0
            };
            var rules = _this5._composeRule(columnKey, rowId, allocated);

            _this5._updateCalendar(rules, columnKey);
          }
        };

        var cancel = function cancel() {
          $cell.html($cellContent);
          $cell.removeClass("is-edit");

          $container.remove();
        };

        // TODO: this is a hack
        //  See https://github.com/emberjs/ember.js/issues/16483 for details.
        var owner = Ember.getOwner(this);

        var ComponentFactory = owner.factoryFor('component:customer/performance/matrix-edit');

        var component = ComponentFactory.create({
          allocation: valueString,
          leadTime: leadString,
          previousColumn: previousColumn,
          nextColumn: nextColumn,
          next: next,
          previous: previous,
          cancel: cancel,
          update: update
        });

        // this is extremely fragile, not sure how much longer this is going to work
        context.renderer.appendTo(component, document.querySelector('#' + context.elementId));
      },
      ruleCopyRequested: function ruleCopyRequested(columnKey /*, groupId*/) {
        if (!this.get('cannotPersist')) {
          return;
        }
        var hasFreeze = this._hasFreeze(columnKey);
        var timeRules = this._findTimeRules(columnKey);
        this.send('showModal', 'customer/performance/-matrix-pricing-copy-modal', {
          isCopy: true,
          selectedSlice: (0, _moment.default)(columnKey).tz(this.get('tz').customer),
          date: (0, _moment.default)(columnKey).tz(this.get('tz').customer),
          pricePoints: this.get('pricePoints'),
          isMobile: this.get('isMobile'),
          hasPreviousPricingSave: false,
          sellableVals: this.get('sellableVals')[columnKey],
          hasFreeze: hasFreeze,
          timeRules: timeRules
        });
      },
      openAddPricing: function openAddPricing() {
        if (!this.get('cannotPersist')) {
          return;
        }

        this.send('showModal', 'customer/performance/-matrix-pricing-copy-modal', {
          isCopy: false,
          selectedSlice: _moment.default.tz(this.get('startDate'), this.get('tz').customer),
          date: _moment.default.tz(this.get('startDate'), this.get('tz').customer),
          pricePoints: this.get('pricePoints'),
          isMobile: this.get('isMobile'),
          hasPreviousPricingSave: false
        });
      },
      addComment: function addComment(date) {
        var _this6 = this;

        var d = (0, _moment.default)(date).tz(this.get('tz').customer).startOf('day');

        // query the customer until the rl-rule model is converted
        this.get('store').findRecord('customer', this.get('ruleSet.customer.id')).then(function (customer) {
          var modalModel = Ember.Object.create({
            date: d,
            commentType: "REVENUE_MANAGEMENT",
            reference: "CUSTOMER",
            referenceId: _this6.get('ruleSet.customer.id'),
            customer: customer,
            comments: _this6.get('comments')[d.format()] || Ember.A()
          });

          _this6.send('showModal', 'comment/-modal', modalModel);
        });
      },
      editEventTime: function editEventTime(date) {
        var d = _moment.default.tz(date, this.get('tz.customer')).startOf('day');
        var yesterday = _moment.default.tz(this.get('tz.customer')).startOf('day').subtract(1, 'day');

        if (d.isSameOrAfter(yesterday, 'day')) {
          var timeRules = this._findTimeRules(date);
          var modalModel = Ember.Object.create({
            date: d,
            pricePoints: this.get('pricePoints'),
            eventTimes: timeRules
          });

          this.send('showModal', 'customer/performance/-event-time-modal', modalModel);
        }
      },
      addFreeze: function addFreeze(date, isFreeze) {
        var _this7 = this;

        var tz = this.get('tz.customer');
        var d = (0, _moment.default)(date).tz(tz).startOf('day');
        var yesterday = _moment.default.tz(tz).startOf('day').subtract(1, 'day');

        if (d.isSameOrAfter(yesterday)) {
          var calendar = this.get('model').calendars.find(function (c) {
            return (0, _moment.default)(Ember.get(c, 'ruleDate')).tz(tz).valueOf() === (0, _moment.default)(date).valueOf();
          });

          if (!calendar) {
            var ruleSet = this.get('ruleSet').get('children');
            calendar = {
              ruleDate: date,
              children: ruleSet,
              isDirty: false
            };

            this.get('model').calendars.addObject(calendar);
          }

          var sellableVals = this.get('sellableVals');
          var currentRate = null;
          if (sellableVals && sellableVals[date]) {
            currentRate = sellableVals[date].amount;
          }
          var rules = this._composeFreezeRule(date, isFreeze, currentRate);

          this._updateCalendar(rules, date);

          if (calendar) {
            rules = Ember.get(calendar, 'children');
          } else {
            rules = Ember.get(this.get('ruleSet'), 'children');
          }

          var complete = function complete(success) {
            _this7.get('model').calendars.forEach(function (c) {
              Ember.set(c, 'isDirty', false);
            });
            var tz = _this7.tz.get("customer");
            var recordDate = _moment.default.tz(date, tz).format("l");
            if (success) {
              _this7.get('dialog').showSuccess({
                titleText: _this7.get('i18n').t('customer.performance.matrix-pricing.copy.success'),
                messageText: isFreeze ? _this7.get('i18n').t('customer.performance.matrix-pricing.freeze.success_text', { date: recordDate }) : _this7.get('i18n').t('customer.performance.matrix-pricing.freeze.delete_text', { date: recordDate }),
                confirmText: _this7.get('i18n').t('customer.performance.matrix-pricing.copy.done')
              });
            }
          };

          this.send('requestFreeze', date, rules, complete);
        }
      },
      transitionToAutoAllocations: function transitionToAutoAllocations(date) {
        this.transitionToRoute('customer.performance.auto-matrix-pricing', { queryParams: { startDate: _moment.default.tz(date, this.tz.get('customer')).format('YYYY-MM-DD') } });
      }
    }
  });
});