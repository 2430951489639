define('ux-pcc/components/customer/setup/customer-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    onSave: null,
    isExpanded: false,
    addDisabled: false,
    list: [],

    addTitle: Ember.computed('i18n.locale', 'customerType', function () {
      return this.i18n.t('pcc-components.customer-list.add-label', { label: this.i18n.t('pcc-components.customer-list.' + this.get('customerType').toLowerCase()) });
    }),

    actions: {
      openEditModal: function openEditModal(template, model) {
        this.openModal(template, {
          customer: model,
          customerType: this.get('customerType'),
          formType: 'edit',
          saveMessage: 'pcc-components.customer-list.dialogs.record-updated',
          onSave: this.get('onSave')
        });
      },
      addCustomer: function addCustomer() {
        var customer = this.store.createRecord('customer', {
          parent: this.get('folder'),
          customerType: this.get('customerType'),
          status: 'OK'
        });

        customer.get('addresses').addObject(this.store.createRecord('address', {
          fkReference: 'CUSTOMER',
          addressType: 'BUSINESS',
          rawAddressText: ''
        }));

        customer.get('contactInformations').addObject(this.store.createRecord('contact-information', {
          fkReference: 'CUSTOMER',
          informationType: 'MAIN_PHONE',
          informationValue: ''
        }));

        this.openModal('customer/setup/customer-form', {
          customer: customer,
          onSave: this.get('onSave'),
          customerType: this.get('customerType'),
          formType: 'add',
          saveMessage: 'pcc-components.customer-list.dialogs.record-added'
        });
      },
      flash: function flash(message, type) {
        this.flash(message, type);
      },
      toggleSummary: function toggleSummary() {
        var _this = this;

        if (this.get('isExpanded')) {
          this.set('isExpanded', false);
        } else {
          this.set('isExpanded', false);
          Ember.run.later(function () {
            _this.set('isExpanded', true);
          }, 1);
        }
      }
    }
  });
});