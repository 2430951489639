define('ux-pcc/components/yield-manager/cards/stacked-card', ['exports', 'ux-components/utils/to-boolean'], function (exports, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    loading: false,
    tz: null,
    rollupStatus: 'DETAIL',
    data: null,
    selectedDate: null,
    currencyCode: 'USD',
    currentChartRow: null,
    classNames: ['widget-box', 'view-card__container'],
    model: null,
    cardData: null,
    exportCsv: null,
    type: '',
    hideDownload: true,
    currentSlide: 0,
    marginLeft: null,
    sortAscending: true,
    showInfo: false,

    title: Ember.computed('level', function () {
      return this.i18n.t('yield-manager.analyze.card_title', { level: this.get('level') });
    }),

    noDataFound: Ember.computed('alternateJumboText', function () {
      return this.alternateJumboText || this.i18n.t('customer._overview.no_data');
    }),

    selectedDataView: 'ACTUAL',
    _selectedDataViewObserver: Ember.observer('selectedDataView', function () {
      if (this.get('selectedDataView') !== this.get('dataViewParameter')) {
        this.set('dataViewParameter', this.get('selectedDataView'));
      }
    }),

    usePct: Ember.computed.equal('selectedDataView', 'PERCENT'),
    valueLabelSuffix: Ember.computed('usePct', function () {
      return this.get('usePct') ? '%' : '';
    }),

    dataViewParameter: Ember.computed.alias('view.dashboardViewParameters.DATA_VIEW'),

    titleType: Ember.computed.alias('mode'),

    isMobile: Ember.computed.bool('deviceType.mobile'),

    revenuePrecision: Ember.computed('customer.settings.[]', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'PCC.YIELD_MANAGEMENT_REVENUE_PRECISION');
      return model && model.get('settingValue') ? model.get('settingValue') : 0;
    }),

    formatRevenueOptions: Ember.computed('currencyCode', 'revenuePrecision', 'customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: this.get('revenuePrecision'),
        decimal: '.',
        thousand: ',',
        hideSymbol: true,
        currencyCode: this.get('currencyCode')
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatRevenuePercentOptions: Ember.computed('formatRevenueOptions', function () {
      var formatOptions = JSON.parse(JSON.stringify(this.get('formatRevenueOptions')));
      formatOptions.suffix = '%';
      formatOptions.precision = 1;

      return formatOptions;
    }),

    formatYieldOptions: Ember.computed('currencyCode', 'customer.settings', function () {
      var setting = (this.get('customer.settings') || []).findBy('settingCode', 'PCC.CURRENCY_CODE_BASED_FORMAT');
      var useCurrencyCodeBasedFormat = setting ? (0, _toBoolean.default)(setting.get('settingValue')) : false;

      var formatOptions = {
        precision: 2,
        decimal: '.',
        thousand: ',',
        hideSymbol: true,
        currencyCode: this.get('currencyCode')
      };

      if (useCurrencyCodeBasedFormat) {
        delete formatOptions.decimal;
        delete formatOptions.thousand;
      }

      return formatOptions;
    }),

    formatYieldPercentOptions: Ember.computed('formatYieldOptions', function () {
      var formatOptions = JSON.parse(JSON.stringify(this.get('formatYieldOptions')));
      formatOptions.suffix = '%';
      formatOptions.precision = 1;

      return formatOptions;
    }),

    pages: Ember.computed('usageList', 'yieldList', 'revenueList', 'sortAscending', 'currencyCode', 'usePct', function () {
      if (!this.get('usageList') || !this.get('yieldList') || !this.get('revenueList')) {
        return null;
      }

      var currencyStr = this.get('currencyCode') && !this.get('usePct') ? ' (' + this.get('currencyCode') + ')' : '';
      return [{
        data: this.get('usageList'),
        mainColorClass: 'chart-color--days',
        title: '' + this.i18n.t('yield-manager.analyze.cards.days')
      }, {
        data: this.get('yieldList'),
        mainColorClass: 'chart-color--yield',
        title: '' + this.i18n.t('yield-manager.analyze.base.header.yield') + currencyStr
      }, {
        data: this.get('revenueList'),
        mainColorClass: 'chart-color--revenue',
        title: '' + this.i18n.t('yield-manager.analyze.base.header.revenue') + currencyStr
      }];
    }),

    brand: Ember.computed('view.dashboardViewParameters.BRAND_CODE', function () {
      var brand = this.get('view.dashboardViewParameters.BRAND_CODE');
      if (!brand || brand.indexOf('|') > -1) {
        brand = 'ehi';
      }

      return 'view-card__brand ' + brand;
    }),

    lockText: Ember.computed('synced', function () {
      return this.get('synced') ? this.i18n.t('yield-manager.analyze.cards.unlock') : this.i18n.t('yield-manager.analyze.cards.lock');
    }),

    _syncObserver: Ember.observer('synced', 'syncedPanel', function () {
      // If we are synchronizing the graphs, and we are not currently on the correct slide, update it
      if (this.get('synced') && typeof this.get('syncedPanel') !== 'undefined' && this.get('currentSlide') !== this.get('syncedPanel')) {
        this.set('currentSlide', this.get('syncedPanel'));
      }
    }),

    _formatGraphData: function _formatGraphData() {
      var _this = this;

      var entries = this.get('results');
      if (!entries || entries.filterBy('groupByValue').rejectBy('groupByValue', 'TOTAL').length < 1) {
        this.set('usageList', null);
        this.set('yieldList', null);
        this.set('revenueList', null);
        return;
      }

      var usageList = [];
      var yieldList = [];
      var revenueList = [];
      var longLabel = false;

      var grossFlag = this.get('view.dashboardViewParameters.TM_VS_GROSS') === 'GROSS';
      var usePct = this.get('usePct');

      entries.forEach(function (e) {
        if (e.groupByValue && e.groupByValue.toUpperCase() !== 'TOTAL' /* && e.reference === 'REQUESTED'*/) {

            var chargeDaysVariance = Number(e.dataValues.deltaUsage);
            var yieldVariance = Number(grossFlag ? e.dataValues.deltaGrossYield : e.dataValues.deltaBaseYield);
            var revenueVariance = Number(grossFlag ? e.dataValues.deltaGross : e.dataValues.deltaBase);

            var chargeDaysPct = Number(e.dataValues.pctChangeUsage);
            var yieldPct = Number(grossFlag ? e.dataValues.pctChangeGrossYield : e.dataValues.pctChangeBaseYield);
            var revenuePct = Number(grossFlag ? e.dataValues.pctChangeGross : e.dataValues.pctChangeBase);
            var groupName = Ember.isEmpty(e.groupDescription) ? e.groupByValue : e.groupDescription;

            // Check for long labels and set a flag so they will wrap on the chart
            if (groupName.length > 12) {
              longLabel = true;
            }

            var bubbleConfig = [{
              label: _this.i18n.t('yield-manager.analyze.base.header.cd'),
              value: usePct ? chargeDaysPct : chargeDaysVariance,
              formatHash: { precision: 1, suffix: usePct ? '%' : '' },
              useAbbreviation: usePct ? false : true
            }, {
              label: _this.i18n.t('yield-manager.analyze.base.header.yield'),
              value: usePct ? yieldPct : yieldVariance,
              format: usePct ? 'percent' : 'currency',
              formatHash: _this.get('formatYield' + (usePct ? 'Percent' : '') + 'Options'),
              useAbbreviation: false
            }, {
              label: _this.i18n.t('yield-manager.analyze.base.header.revenue'),
              value: usePct ? revenuePct : revenueVariance,
              formatHash: { precision: 1, suffix: usePct ? '%' : '' },
              useAbbreviation: usePct ? false : true
            }];

            // Chart puts negative values on the left, so we need to come in with reversed numbers to display appropriately
            var usageRecord = {
              category: groupName,
              categoryValue: e.groupByValue,
              customerID: e.customerID,
              bubbleConfig: bubbleConfig,
              valueClass: 'negative-value-class',
              filterCategory: _this.get('filterCategory'),
              titleSuffix: usePct ? '' : ' (' + _this.get('currencyCode') + ')'
            };

            var yieldRecord = {
              category: groupName,
              categoryValue: e.groupByValue,
              customerID: e.customerID,
              bubbleConfig: bubbleConfig,
              valueClass: 'negative-value-class',
              filterCategory: _this.get('filterCategory'),
              titleSuffix: usePct ? '' : ' (' + _this.get('currencyCode') + ')'
            };

            var revenueRecord = {
              category: groupName,
              categoryValue: e.groupByValue,
              customerID: e.customerID,
              bubbleConfig: bubbleConfig,
              valueClass: 'negative-value-class',
              filterCategory: _this.get('filterCategory'),
              titleSuffix: usePct ? '' : ' (' + _this.get('currencyCode') + ')'
            };

            if (usePct) {
              usageRecord.CY = chargeDaysPct >= 0 ? chargeDaysPct : 0;
              usageRecord.PY = chargeDaysPct < 0 ? chargeDaysPct : 0;
              usageRecord.order = chargeDaysPct;
              usageRecord.formatHash = { suffix: '%', precision: 1 };

              yieldRecord.CY = yieldPct >= 0 ? yieldPct : 0;
              yieldRecord.PY = yieldPct < 0 ? yieldPct : 0;
              yieldRecord.order = yieldPct;
              yieldRecord.formatHash = _this.get('formatYieldPercentOptions');

              revenueRecord.CY = revenuePct >= 0 ? revenuePct : 0;
              revenueRecord.PY = revenuePct < 0 ? revenuePct : 0;
              revenueRecord.order = revenuePct;
              revenueRecord.formatHash = _this.get('formatRevenuePercentOptions');
            } else {
              usageRecord.CY = chargeDaysVariance >= 0 ? chargeDaysVariance : 0;
              usageRecord.PY = chargeDaysVariance < 0 ? chargeDaysVariance : 0;
              usageRecord.order = chargeDaysVariance;
              usageRecord.precision = 1;
              usageRecord.useAbbreviation = true;
              usageRecord.formatHash = { precision: 0 };

              yieldRecord.CY = yieldVariance >= 0 ? yieldVariance : 0;
              yieldRecord.PY = yieldVariance < 0 ? yieldVariance : 0;
              yieldRecord.order = yieldVariance;
              yieldRecord.format = 'currency';
              yieldRecord.formatHash = _this.get('formatYieldOptions');
              yieldRecord.useAbbreviation = false;

              revenueRecord.CY = revenueVariance >= 0 ? revenueVariance : 0;
              revenueRecord.PY = revenueVariance < 0 ? revenueVariance : 0;
              revenueRecord.order = revenueVariance;
              revenueRecord.precision = 1;
              revenueRecord.useAbbreviation = true;
            }

            usageList.push(usageRecord);
            yieldList.push(yieldRecord);
            revenueList.push(revenueRecord);
          }
      });

      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        this.set('usageList', this.get('sortAscending') ? usageList.sortBy('order') : usageList.sortBy('order').reverse());
        this.set('yieldList', this.get('sortAscending') ? yieldList.sortBy('order') : yieldList.sortBy('order').reverse());
        this.set('revenueList', this.get('sortAscending') ? revenueList.sortBy('order') : revenueList.sortBy('order').reverse());
        if (longLabel) {
          this.set('marginLeft', 120);
        }
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.dataViewObserver();
    },


    dataViewObserver: Ember.observer('results', 'loading', 'selectedDataView', function () {
      this.set('mode', this.i18n.t('yield-manager.analyze.cards.charge_days'));
      if (this.get('results') && this.get('results.length') > 0) {
        Ember.run.once(this, '_formatGraphData');
      } else {
        this.set('usageList', null);
        this.set('yieldList', null);
        this.set('revenueList', null);
      }
    }),

    actions: {
      openMoreActions: function openMoreActions() {
        this.toggleProperty('showMoreActions');
      },
      openCalendar: function openCalendar() {
        this.showDateModal('dateWormhole_' + this.get('filterCategory'));
        this.set('showMoreActions', false);
      },
      toggleSort: function toggleSort() {
        if (this.get('usageList')) {
          this.set('usageList', this.get('usageList').reverse());
          this.set('yieldList', this.get('yieldList').reverse());
          this.set('revenueList', this.get('revenueList').reverse());
        }

        this.toggleProperty('sortAscending');
        this.set('showMoreActions', false);
      },
      toggleViewType: function toggleViewType(value) {
        this.set('selectedDataView', value);
        this.set('showMoreActions', false);
      },
      rowClick: function rowClick(row) {
        if (row.graphKey) {
          this.set('currentChartRow', row);
        }
      },
      viewInfo: function viewInfo() {
        this.toggleProperty('showInfo');
        // make sure the div is within the viewing area

        // If not mobile, check to see if we need to move to the
        // other side if there isn't room to display on the right
        if (!this.get('isMobile)')) {
          var repositionModal = function repositionModal() {
            var containerDiv = Ember.$('.view-card-feed');
            var viewDiv = Ember.$('.view-card__info-view');
            var viewDivPosition = viewDiv.offset();
            if (viewDivPosition) {
              var viewRight = viewDivPosition.left + viewDiv.width() - 45;
              var containerRight = containerDiv.offset().left + containerDiv.width();

              if (viewRight > containerRight) {
                // move it to left side
                viewDiv.css('left', '-' + viewDiv.width() + 'px');
              }
            } else {
              Ember.run.later(function () {
                return repositionModal();
              }, 10);
            }
          };

          if (this.get('showInfo')) {
            repositionModal();
          }
        }
      },
      changeMode: function changeMode(mode, tab) {
        if (this.get('onAnalyzeClick')) {
          this.get('onAnalyzeClick')(mode, tab);
        }
      },
      download: function download(type) {
        if (this.get('downloadClick')) {
          this.get('downloadClick')(type);
        }
      },
      cardChanged: function cardChanged() {
        // Update the title shown on the card
        var currentSlide = this.get('currentSlide');
        var pages = this.get('pages');
        this.set('mode', pages[currentSlide].title);
        if (this.get('synced')) {
          this.get('onSyncSlide')(currentSlide);
        }
      }
    }
  });
});