define('ux-pcc/components/charts/chart-channel-percent-arrivals', ['exports', 'ux-pcc/components/charts/chart-basic'], function (exports, _chartBasic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({
    _config: function _config() {
      var _this = this;

      var dataFields = this.get('dataFields');
      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this.get('data') && _this.get('data').length > 0) {
          // Put them in reverse order they appear, as they get popped off the array below
          var colors = ["#5de3d8", "#72BF43", "#4e5356", "#a8acad", "#086fc4", "#8ad3f7"];

          var generateGraph = function generateGraph(title, value, index) {
            var chartColor = colors.pop();
            return {
              title: title,
              balloonFunction: function balloonFunction(item /*, graph*/) {
                if (index === parseInt(dataFields.length / 2)) {
                  var balloonData = '';
                  for (var i = 0; i < dataFields.length; i++) {
                    balloonData += '<div><span class=\'bubble-label\'>' + dataFields[i].label + ':</span><span class=\'bubble-value percent\'> ' + item.dataContext[dataFields[i].key] + '</span></div>';
                  }
                  return balloonData;
                } else {
                  return "";
                }
              },
              "fillAlphas": 1,
              "fillColors": chartColor,
              "lineAlpha": 1,
              "lineColor": chartColor,
              "type": "column",
              "id": value + ' chart-color--' + index,
              "valueField": value,
              "valueAxis": "percentArrival"
            };
          };

          for (var i = 0; i < dataFields.length; i++) {
            graphs.push(generateGraph(dataFields[i].label, dataFields[i].key, i));
          }
        }

        return graphs;
      };

      var calendars = this.get('data');
      var config = {
        "type": "serial",
        "addClassNames": true,
        "categoryField": "dow",
        "startDuration": 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        "categoryAxis": {
          gridAlpha: 0.1,
          axisAlpha: 0,
          "gridPosition": "start",
          "color": "#505558",
          "parseDates": false,
          "classNameField": "dateClass",
          labelsEnabled: !this.get('deviceType.mobile')
        },
        "chartCursor": {
          "balloonPointerOrientation": "vertical",
          "zoomable": false,
          "selectWithoutZooming": true,
          "fullWidth": false,
          "enabled": true,
          "cursorAlpha": 0,
          "color": "#f3faff",
          "categoryBalloonEnabled": false
        },
        legend: {
          "enabled": true,
          "position": "top",
          "markerSize": 10,
          "markerType": "square"
        },
        "trendLines": [],

        "graphs": generateGraphs(),

        "valueAxes": [{
          "id": "percentArrival",
          "gridAlpha": 0.1,
          "axisAlpha": 0.1,
          "tickLength": this.get('deviceType.mobile') ? 0 : 30,
          "position": "left",
          "minimum": 0,
          "maximum": 100,
          "strictMinMax": true,
          "unit": "%"
        }],
        "allLabels": [],
        "balloon": {
          "adjustBorderColor": true,
          "borderAlpha": 0,
          "shadowAlpha": 0,
          "color": "#ffffff",
          "fillColor": "#0B8CF5",
          "fillAlpha": 0.9,
          "textAlign": "middle",
          "verticalPadding": 15,
          "horizontalPadding": 20,
          "offsetX": 50
        },
        "titles": [],
        "dataProvider": calendars
      };

      return Ember.$.extend(true, {}, this._super(), config, this.get('config'));
    },
    _afterRedrawOnDataChanged: function _afterRedrawOnDataChanged() {
      this._super();
    },
    _createChart: function _createChart() {
      this._super();

      // Enable interactivity
      var $chartDiv = Ember.$(this.element).find('.amcharts-chart-div');
      $chartDiv.find('svg').css('cursor', "pointer").on('click', function () {
        // var i = this.get('_chart').chartCursor.index;
        // var data = this.get('data')[i];
      });
    }
  });
});