define('ux-pcc/components/rules-simple/deposit-rule-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'moment'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['rules-simple-condition'],
    classNameBindings: ['isReadOnly:rules-simple-condition__read-only'],

    i18n: Ember.inject.service(),

    onDelete: function onDelete() {},
    registerChangeset: function registerChangeset() {},

    rule: null,
    ruleCondition: null,
    index: null,

    selectedTimePeriod: null,
    selectedRelativeTime: null,

    isReadOnly: Ember.computed.equal('mode', 'READONLY'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.mode === 'NEW') {
        this.ruleCondition.amount = 0;
        this.ruleCondition.amountRule = 'FIRST_SLICE';
        this.ruleCondition.calculationRule = 'AMOUNT_PERCENT';
        this.ruleCondition.ruleMethod = 'RULE';
        this.ruleCondition.day1 = 'Y';
        this.ruleCondition.day2 = 'Y';
        this.ruleCondition.day3 = 'Y';
        this.ruleCondition.day4 = 'Y';
        this.ruleCondition.day5 = 'Y';
        this.ruleCondition.day6 = 'Y';
        this.ruleCondition.day7 = 'Y';
        this.ruleCondition.children.push({
          ruleMethod: 'FILTER',
          filterType: 'ALL',
          children: [{
            ruleMethod: 'FILTER',
            filterType: 'FIELD_WHERE',
            filterField: 'HOURS_PRIOR_TO_ARRIVAL',
            filterCondition: 'EQ',
            filterValue: '0'
          }, {
            ruleMethod: 'FILTER',
            filterType: 'FIELD_WHERE',
            filterField: 'EVENT_TIME',
            filterCondition: 'LT',
            filterValue: '18:00'
          }]
        });

        this.set('selectedTimePeriod', 'HOURS');
        this.set('selectedRelativeTime', 'PRIOR_TO_ARRIVAL');
      } else {
        var indexOfFirstUnderscore = this.depositDayCondition.filterField.indexOf('_');
        var timePeriod = this.depositDayCondition.filterField.substring(0, indexOfFirstUnderscore);
        var relativeTime = this.depositDayCondition.filterField.substring(indexOfFirstUnderscore + 1);

        this.set('selectedTimePeriod', timePeriod);
        this.set('selectedRelativeTime', relativeTime);
      }

      // Register additional changesets
      this.registerChangeset('ruleConditionChangeset' + this.index, this.ruleConditionChangeset);
      this.registerChangeset('depositDayConditionChangeset' + this.index, this.depositDayConditionChangeset);
      this.registerChangeset('timeConditionChangeset' + this.index, this.timeConditionChangeset);
    },


    allCondition: Ember.computed('ruleCondition.children.[]', function () {
      return this.ruleCondition.children.findBy('filterType', 'ALL');
    }),

    depositDayCondition: Ember.computed('allCondition.children.[]', function () {
      //TODO: seems very fragile, find better way to determine which rule is which
      return this.allCondition.children.firstObject;
    }),

    timeCondition: Ember.computed('allCondition.children.[]', function () {
      return this.allCondition.children.findBy('filterField', 'EVENT_TIME');
    }),

    timePeriodOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.time-options.hours'),
        value: 'HOURS'
      }, {
        label: this.i18n.t('rules-simple.time-options.days'),
        value: 'DAYS'
      }, {
        label: this.i18n.t('rules-simple.time-options.minutes'),
        value: 'MINUTES'
      }];
    }),

    relativeTimeOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.time-options.prior_to_arrival'),
        value: 'PRIOR_TO_ARRIVAL'
      }, {
        label: this.i18n.t('rules-simple.time-options.booking_date'),
        value: 'AFTER_BOOKING_DATE'
      }];
    }),

    amountRuleOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.penalty-options.first_day'),
        value: 'FIRST_SLICE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.first_day_incl_tax'),
        value: 'FIRST_SLICE_INCL_TAX'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.first_day_incl_tax_fee'),
        value: 'FIRST_SLICE_INCL_TAX_FEE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total_remaining'),
        value: 'TOTAL_REMAINING'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total_remaining_incl_fee'),
        value: 'TOTAL_REMAINING_INCL_FEE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total'),
        value: 'TOTAL'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total_incl_tax'),
        value: 'TOTAL_INCL_TAX'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total_incl_tax_fee'),
        value: 'TOTAL_INCL_TAX_FEE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.flat_price'),
        value: 'VALUE'
      }];
    }),

    timeConditionValue: Ember.computed('timeCondition.filterValue', function () {
      var filterValue = this.timeCondition.filterValue;
      var parsedTime = filterValue.split(':');
      var today = (0, _moment.default)();
      today.hour(parsedTime[0]);
      today.minute(parsedTime[1]);
      return today;
    }),

    ruleConditionChangeset: Ember.computed('ruleCondition', function () {
      var validations = {
        amount: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') }),
        amountRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true }),
        calculationRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.ruleCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    depositDayConditionChangeset: Ember.computed('depositDayCondition', function () {
      var validations = {
        filterValue: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') }),
        filterField: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.depositDayCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    timeConditionChangeset: Ember.computed('timeCondition', function () {
      var validations = {
        filterValue: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.timeCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    actions: {
      deleteCondition: function deleteCondition() {
        this.onDelete(this.index);
      },
      updateTimePeriod: function updateTimePeriod(timePeriod) {
        this.set('depositDayCondition.filterField', timePeriod + '_' + this.selectedRelativeTime);
        this.set('selectedTimePeriod', timePeriod);
      },
      updateRelativeTime: function updateRelativeTime(relativeTime) {
        this.set('depositDayCondition.filterField', this.selectedTimePeriod + '_' + relativeTime);
        this.set('selectedRelativeTime', relativeTime);
      },
      updateAmountRule: function updateAmountRule(amountRule) {
        this.ruleConditionChangeset.set('calculationRule', amountRule === 'VALUE' ? 'AMOUNT_NOOP' : 'AMOUNT_PERCENT');
        this.ruleConditionChangeset.set('amountRule', amountRule);
      }
    }

  });
});