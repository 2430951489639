define('ux-pcc/controllers/data-monitoring/detail', ['exports', '@ember/service/computed', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _computed, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileSaver.default, {
    queryParams: ['page'],
    ajax: Ember.inject.service(),
    showOnlyDiscrepancies: true,
    page: 1,
    totalResults: 0,
    resultsPerPage: 20,
    isMinPage: (0, _computed.equal)('page', 1),

    isMaxPage: Ember.computed('model', 'page', 'resultsPerPage', 'resultsFound', function () {
      return this.get('page') * this.get('resultsPerPage') >= this.get('resultsFound');
    }),

    pageStartIndex: Ember.computed('resultsPerPage', 'page', function () {
      return (this.get('page') - 1) * this.get('resultsPerPage') + 1;
    }),

    pageEndIndex: Ember.computed('resultsFound', 'page', 'resultsPerPage', function () {
      var maxForPage = this.get('page') * this.get('resultsPerPage');
      return maxForPage < this.get('resultsFound.length') ? maxForPage : this.get('resultsFound.length');
    }),

    maxPage: Ember.computed('resultsFound', 'page', 'resultsPerPage', function () {
      return Math.ceil(this.get('resultsFound') / this.get('resultsPerPage'));
    }),

    shortTermResults: Ember.computed('model', function () {
      var _this = this;

      var shortTermResults = [];
      this.get('model').forEach(function (item) {
        var date = _moment.default.tz(item.summaryDate, _this.get('tz.customer')).startOf('day');
        var shortTermDate = _moment.default.tz(_this.get('tz.customer')).add(6, 'days').startOf('day');
        if (date.isSameOrBefore(shortTermDate)) {
          shortTermResults.addObject(item);
        }
      });

      return shortTermResults;
    }),

    longTermResults: Ember.computed('model', function () {
      var _this2 = this;

      var longTermResults = [];
      this.get('model').forEach(function (item) {
        var date = _moment.default.tz(item.summaryDate, _this2.get('tz.customer')).startOf('day');
        var shortTermDate = _moment.default.tz(_this2.get('tz.customer')).add(6, 'days').startOf('day');
        if (date.isAfter(shortTermDate)) {
          longTermResults.addObject(item);
        }
      });

      return longTermResults;
    }),

    sortedShortTermResults: Ember.computed('shortTermResults', 'shortTermSortField', function () {
      return this.get('shortTermResults').sortBy(this.get('shortTermSortField'));
    }),

    sortedLongTermResults: Ember.computed('longTermResults', 'longTermSortField', function () {
      return this.get('longTermResults').sortBy(this.get('longTermSortField'));
    }),

    shortTermSortField: 'summaryDate',
    longTermSortField: 'summaryDate',
    loading: false,

    actions: {
      returnToList: function returnToList() {
        this.transitionToRoute('data-monitoring');
      },
      next: function next() {
        var page = this.get('page') + 1;
        var resultsPerPage = this.get('resultsPerPage');
        var numberResults = this.get('resultsFound');

        if (page * resultsPerPage >= numberResults) {
          page = Math.ceil(numberResults / resultsPerPage);
        }

        this.set('page', page);
      },
      prev: function prev() {
        this.set('page', Math.max(1, this.get('page') - 1));
      },
      exportData: function exportData() {
        var _this3 = this;

        this.set('loading', true);
        var tz = this.tz.get('customer');
        var customerID = this.get('customer.id');
        var beginDate = _moment.default.tz(tz).startOf('day');
        var filename = beginDate.format('YYYY-MM-DD') + '_data-monitoring_details.csv';

        this.ajax.request('/rest/v1/dataMonitoring/summaryExport/' + customerID, {
          headers: { accept: 'text/csv' },
          dataType: 'blob',
          contentType: 'text/csv',
          data: {
            beginDate: beginDate.format('YYYY-MM-DD'),
            days: 179,
            include: 'GENERAL_INVENTORY,RESERVATION_COUNT,OOO_INVENTORY,OVERBOOKING_INVENTORY,MANAGER_HOLD,SELLRATE,REVENUE'
          }
        }).then(function (data) {
          _this3.saveFileAs(filename, data, 'text/csv');
        }).catch(function () {
          _this3.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this3.get('i18n').t('customer.performance.download_error'), 'danger');
        }).finally(function () {
          _this3.set('loading', false);
        });
      }
    }
  });
});