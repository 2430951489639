define('ux-pcc/components/customer/setup/customer-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    dialog: Ember.inject.service(),
    store: Ember.inject.service(),

    folder: null,
    customer: null,

    list: [],
    loading: true,
    disableAdd: false,
    customerType: '',
    folderType: '',

    init: function init() {
      this._super.apply(this, arguments);

      this.dataLoad();
    },


    dataLoadObserver: Ember.observer('folderType', 'customerType', 'customer.id', function () {
      this.dataLoad();
    }),

    dataLoad: function dataLoad() {
      var _this = this;

      Ember.run.once(function () {
        var queryParams = {
          folderid: _this.get('customer.id'),
          folderyn: 'y',
          customertype: _this.get('folderType'),
          createIfMissing: true
        };

        _this.store.query('customer', queryParams).then(function (data) {

          var folder = data.findBy('customerType', _this.get('folderType'));

          if (folder) {
            _this.set('folder', folder);
          } else {
            _this.set('disableAdd', true);
            _this.dialog.showError({
              messageText: _this.i18n.t('pcc-components.customer-list.messages.no-folder')
            });
          }
        }).catch(function () {
          _this.set('disableAdd', true);
          _this.dialog.showError({
            messageText: _this.i18n.t('pcc-components.customer-list.messages.generic')
          });
        }).finally(function () {
          _this.set('loading', false);
        });
      });
    },
    _loadData: function _loadData() {
      var _this2 = this;

      if (this.get('folder.id')) {
        this.set('loading', true);
        this.set('disableAdd', false);
        var queryParams = {
          folderid: this.get('folder.id'),
          customertype: this.get('customerType').toUpperCase(),
          include: 'address,contactinformation'
        };

        this.store.query('customer', queryParams).then(function (data) {
          if (data && data.get('length') > 1) {
            data = data.sortBy('name');
          }
          _this2.set('list', data);
        }).catch(function () {
          _this2.dialog.showError({
            messageText: _this2.i18n.t('pcc-components.customer-list.messages.generic')
          });
        }).finally(function () {
          _this2.set('loading', false);
        });
      } else {
        this.set('list', []);
        this.set('loading', false);
      }
    },


    listObserver: Ember.observer('folder.id', 'customerType', 'customer.id', function () {
      this._loadData();
    }),

    actions: {
      openModal: function openModal(template, model) {
        this.openModal(template, model);
      },
      refresh: function refresh() {
        this._loadData();
      },
      flash: function flash(message, type) {
        this.flash(message, type);
      }
    }
  });
});