define('ux-pcc/controllers/rules-advanced/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['ruleType'],

    permission: Ember.inject.service(),
    dialog: Ember.inject.service(),

    rulesAdvanced: Ember.inject.controller('rules-advanced'),
    customerController: Ember.inject.controller('customer'),

    customer: Ember.computed.readOnly('customerController.model'),

    filteredRuleTypeNotifications: Ember.computed.filterBy('filteredRuleTypes', 'group', 'Notification'),
    sortedRuleTypeNotifications: Ember.computed.sort('filteredRuleTypeNotifications', 'ruleTypeSort'),

    filteredRuleTypeGeneral: Ember.computed.filterBy('filteredRuleTypes', 'group', 'General'),
    sortedRuleTypeGeneral: Ember.computed.sort('filteredRuleTypeGeneral', 'ruleTypeSort'),

    ruleType: 'all',

    init: function init() {
      this._super.apply(this, arguments);

      this.set('ruleTypeSort', ['total', 'shortDescription']);
      this.set('ruleSort', ['shortDescription']);
    },


    permittedRules: Ember.computed('model', function () {
      var _this = this;

      var rules = this.get('model');
      var customerPermissionType = this.get('customer.permissionType');

      return rules.filter(function (item) {
        return item.get('ruleType');
      }).filter(function (item) {
        return _this.get('permission').isPermitted(customerPermissionType + 'ruletype', 'view', item.get('ruleType').toLowerCase());
      });
    }),

    filteredRules: Ember.computed('permittedRules', 'ruleType', function () {
      var rules = this.get('permittedRules');
      var ruleType = this.get('ruleType');

      if (ruleType !== 'all') {
        rules = rules.filterBy('ruleType', ruleType);
      }

      return rules;
    }),

    filteredRuleTypes: Ember.computed('model', 'rulesAdvanced.filteredTypes', function () {
      var ruleTypes = this.get('rulesAdvanced.filteredTypes');
      ruleTypes.forEach(function (ruleType) {
        Ember.set(ruleType, 'total', 0);
        Ember.set(ruleType, 'rulesPresent', false);
      });

      var rules = this.get('model');
      rules.forEach(function (rule) {
        ruleTypes.forEach(function (ruleType) {
          if (rule.get('ruleType') === Ember.get(ruleType, 'type')) {
            Ember.set(ruleType, 'total', Ember.get(ruleType, 'total') + 1);
            Ember.set(ruleType, 'rulesPresent', true);
          }
        });
      });

      return ruleTypes;
    })
  });
});