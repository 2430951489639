define('ux-pcc/controllers/rules/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    templateRules: Ember.computed.alias('controllers.rules.templateRules'),
    saveLabel: 'rule._form.create_rule',
    successMessage: 'rule._form._success_modal.success_new_message',
    ruleNameLabel: 'rule._form.name',
    ruleDescriptionLabel: 'rule._form.description',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model')
  });
});