define("ux-pcc/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "@sentry/tracing", "ux-pcc/config/environment"], function (exports, _browser, _integrations, _tracing, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.startSentry = startSentry;
  function startSentry() {
    _browser.init({
      // XXX: spread operator works in IBE (Ember 3.19) but not here (Ember 3.0)
      // ...config.sentry,
      dsn: _environment.default.sentry.dsn,
      integrations: [new _integrations.Ember(), new _tracing.Integrations.BrowserTracing()],
      release: _environment.default.APP.modulePrefix + "@" + _environment.default.APP.version,
      tracesSampleRate: 0.01, beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException;

        // ignore aborted route transitions from the Ember.js router
        if (error && (error.name === 'TransitionAborted' || error.message === 'The adapter operation was aborted' || error.message === 'The ajax operation was aborted')) {
          return null;
        }

        return event;
      }
    });
  }
});