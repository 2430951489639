define('ux-pcc/components/rules/rule-children', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    tagName: 'div',

    classNames: ['rule-children'],

    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('users', []);

      this._setupUsers();
    },
    _setupUsers: function _setupUsers() {
      var _this = this;

      var customer = this.get('customer');
      var userCustomer = this.get('session.data.authenticated.customerID');

      var requests = [this.store.query('user', { customerID: customer.get('id') }), this.store.query('user', { customerID: userCustomer })];

      Ember.RSVP.all(requests, 'RulesRoute: Resolving rules structure').then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            customerUsers = _ref2[0],
            userUsers = _ref2[1];

        var users = [];
        users.addObjects(customerUsers);

        if (customer.get('id') !== userCustomer) {
          users.addObjects(userUsers);
        }

        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('users', users);
        }
      });
    },


    sortedRules: Ember.computed('rules.@each.orderBy', function () {
      return this.get('rules').sortBy('orderBy');
    }),

    hasRules: Ember.computed.notEmpty('rules'),

    addChildDisabled: Ember.computed('ruleType', 'hasRules', function () {
      // only allow one condition for ceiling rules
      return this.get('ruleType') === 'CEILING' && this.get('hasRules');
    }),

    userTokenOptions: Ember.computed('users', function () {
      var users = this.get('users') || [];

      // the tokenfield never updates if you start off with an empty list
      if (!users.get('length')) {
        return '';
      }

      // constructs the suggestion engine
      var engine = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return [].concat(Bloodhound.tokenizers.whitespace(d.label)).concat(Bloodhound.tokenizers.whitespace(d.email)).concat(Bloodhound.tokenizers.whitespace(d.phone)).concat(Bloodhound.tokenizers.whitespace(d.mobile));
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: users.map(function (user) {
          return {
            value: user.get('id'),
            label: user.get('fullName'),
            email: user.get('emailAddress'),
            phone: user.get('contactPhone'),
            mobile: user.get('contactMobile'),
            key: user.get('fullName') + ' (' + user.get('emailAddress') + ')'
          };
        })
      });

      var typeahead = [{
        hint: true,
        minLength: 2,
        classNames: { menu: 'tt-dropdown-menu' }
      }, {
        name: 'users',
        displayKey: 'key',
        source: engine
      }];

      return {
        typeahead: typeahead,
        createTokensOnBlur: true,
        allowDuplicates: false
      };
    }),

    actions: {
      addRule: function addRule() {
        this.addRule();
      },
      editRule: function editRule(rule) {
        this.editRule(rule);
      },
      updateSelection: function updateSelection(condition, isSelected) {
        this.updateSelection(condition, isSelected);
      },
      sortEndAction: function sortEndAction() {
        this.get('sortedRules').forEach(function (child, index) {
          Ember.set(child, 'orderBy', index);
        });
      }
    }
  });
});