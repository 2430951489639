define('ux-pcc/services/ember-cordova/platform', ['exports', 'ember-cordova-platform/services/ember-cordova/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _platform.default;
    }
  });
});