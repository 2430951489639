define('ux-pcc/controllers/portfolios/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    portfolios: Ember.computed('model.[]', function () {
      var portfolios = this.get('model');

      var getManager = function getManager(portfolio) {
        var settings = portfolio.get('settings') || [];
        var managers = portfolio.get('managers');

        if (managers !== null && managers !== undefined && managers !== "") {
          var tokens = Ember.Object.create(JSON.parse(managers));
          return tokens.userTokens && tokens.userTokens.length > 0 ? tokens.userTokens[0].label : null;
        } else {
          var settingManagers = settings.findBy('settingCode', "MANAGERS");
          if (settingManagers && settingManagers.get('settingValue')) {
            var users = Ember.Object.create(JSON.parse(settingManagers.get('settingValue')));
            return users.userTokens && users.userTokens.length > 0 ? users.userTokens[0].label : null;
          }
        }
        return null;
      };

      // Munge the data for display
      return portfolios.map(function (p) {
        return Ember.Object.create({
          id: p.get('id'),
          name: p.get('name'),
          manager: getManager(p),
          model: p
        });
      });
    })

  });
});