define('ux-pcc/components/yield-manager/info-wrapper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    isMobile: Ember.computed.bool('deviceType.mobile'),
    showInfo: false,
    containerClass: '',
    itemDiv: '',
    contentClass: null,

    _modelObserver: Ember.observer('model.[]', function () {
      // If the model changed, and the info panel is open, close it
      if (this.get('showInfo')) {
        this.send('viewInfo');
      }
    }),

    actions: {
      viewInfo: function viewInfo() {
        var _this = this;

        this.toggleProperty('showInfo');

        // If not mobile, check to see if we need to move to the
        // other side if there isn't room to display on the right
        if (!this.get('isMobile')) {
          var repositionModal = function repositionModal() {
            var containerDiv = Ember.$('.' + _this.get('containerClass'));
            var itemDiv = Ember.$('.' + _this.get('itemClass'));
            var viewDiv = Ember.$('.view-card__info-view');
            var viewDivPosition = viewDiv.offset();
            if (viewDivPosition) {
              var viewRight = viewDivPosition.left + viewDiv.width() - 45;
              var containerRight = containerDiv.offset().left + containerDiv.width() - 10;
              var itemRight = itemDiv.offset().left + itemDiv.width() + viewDiv.width();
              if (viewRight > containerRight) {
                // move it to left side
                viewDiv.css('left', '-' + viewDiv.width() + 'px');
              } else {
                if (itemRight <= containerRight) {
                  viewDiv.css('left', itemDiv.width() + 10 + 'px');
                } else {
                  // Check to see if there is room on the left
                  if (itemDiv.offset().left - viewDiv.width() > containerDiv.offset().left) {
                    viewDiv.css('left', '-' + viewDiv.width() + 'px');
                  } else {
                    // Put the info panel inside the container, and then squish the content
                    viewDiv.css('left', containerDiv.width() - viewDiv.width() + 'px');
                    if (_this.get('contentClass')) {
                      var contentDiv = Ember.$('.' + _this.get('itemClass') + ' .' + _this.get('contentClass'));
                      if (contentDiv) {
                        _this.set('contentDivWidth', contentDiv.width());
                        _this.set('contentDivLeftMargin', contentDiv.css('margin-left'));
                        contentDiv.css('width', contentDiv.width() - viewDiv.width() - 10 + 'px');
                        contentDiv.css('margin-left', '0');
                        _this.set('contentAdjusted', true);
                        _this.send('resize');
                      }
                    }
                  }
                }
              }
            } else {
              Ember.run.later(function () {
                repositionModal();
              }, 10);
            }
          };

          if (this.get('showInfo')) {
            repositionModal();
          } else {
            if (this.get('contentAdjusted') && this.get('contentClass')) {
              var contentDiv = Ember.$('.' + this.get('itemClass') + ' .' + this.get('contentClass'));
              if (contentDiv) {
                contentDiv.css('width', this.get('contentDivWidth'));
                contentDiv.css('margin-left', this.get('contentDivLeftMargin'));
              }
              this.set('contentAdjusted', false);
              this.send('resize');
            }
          }
        }
      },
      resize: function resize() {
        Ember.run.later(function () {
          Ember.$(window).trigger('resize.menu-toggle');
          // Fire a resize event after the transition is complete

          if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
          } else {
            // Windows needs a little more time
            Ember.run.later(function () {
              // for IE and other old browsers
              // causes deprecation warning on modern browsers
              var evt = document.createEvent('UIEvents');
              evt.initUIEvent('resize', true, false, window, 0);
              window.dispatchEvent(evt);
            }, 300);
          }
        }, 400);
      }
    }
  });
});