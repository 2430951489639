define('ux-pcc/components/rules/conditions/sold-by-price-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values'], function (exports, _ruleConditionValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    type: 'number',

    label: Ember.computed('i18n.locale', function () {
      return this.i18n.t('pcc-components.rules.conditions.rooms');
    })

  });
});