define('ux-pcc/routes/rule-rates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({
    model: function model() /* params */{
      var customer = this.modelFor('customer');
      var requests = [this.store.query('price', {
        customerID: customer.get('id'),
        fields: 'id,shortDescription,parentID,externalReference'
      }), this.store.query('channel-parameter', {
        channelID: '10a00000-0000-0000-0000-000000000000',
        customerID: customer.get('id'),
        effective: true
      })];

      return Ember.RSVP.all(requests, 'RuleRatesRoute: Resolving rules structure').then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            prices = _ref2[0],
            channelParameters = _ref2[1];

        return {
          prices: prices.sortBy('shortDescription'),
          parameters: channelParameters.filterBy('parameterType', 'PARAMETER')
        };
      });
    }
  });
});