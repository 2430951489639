define('ux-pcc/routes/customer/performance/data-metrics', ['exports', 'moment', 'ember-cli-file-saver/mixins/file-saver'], function (exports, _moment, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_fileSaver.default, {

    ajax: Ember.inject.service(),

    actions: {
      exportCsv: function exportCsv() {
        var _this = this;

        var tz = this.get('tz.customer');
        var metricDate = _moment.default.tz(this.controller.get('metricDate'), tz);
        var externalReference = this.modelFor('customer').get('externalReference');
        var filename = metricDate.format('YYYY-MM-DD') + '_data-metrics.csv';

        this.get('ajax').request('/rest/v1/exports/customerDataMetrics/' + externalReference + '/detail', {
          headers: { accept: 'text/csv' },
          dataType: 'blob',
          contentType: 'text/csv',
          data: {
            metricdate: metricDate.format()
          }
        }).then(function (data) {
          return _this.saveFileAs(filename, data, 'text/csv');
        }, function () {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this.get('i18n').t('customer.performance.download_error'), 'danger');
        });
      }
    }
  });
});