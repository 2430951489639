define('ux-pcc/components/reservations/history-value', ['exports', 'ux-components/components/reservations/history-value'], function (exports, _historyValue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _historyValue.default;
    }
  });
});