define('ux-pcc/routes/reservation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var reservation = this.store.findRecord('reservation', params.reservation_id, {
        include: 'parties,segments,products,documents,comments,payments,settings'
      });

      var customer = this.modelFor('customer');

      var userDefinedFields = this.store.query('user-defined-field', {
        fkReference: 'RESERVATION',
        customerID: customer.get('id')
      });

      return Ember.RSVP.hash({
        reservation: reservation,
        userDefinedFields: userDefinedFields
      }, 'Querying models in routes/reservation');
    }
  });
});