define('ux-pcc/components/rules-simple/fee-rule-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['rules-simple-condition'],
    classNameBindings: ['isReadOnly:rules-simple-condition__read-only'],

    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    onDelete: function onDelete() {},
    registerChangeset: function registerChangeset() {},

    rule: null,
    ruleCondition: null,
    index: null,

    selectedIfCategory: null,
    selectedIfEquality: null,

    selectedAndCategory: null,
    selectedAndEquality: null,

    isReadOnly: Ember.computed.equal('mode', 'READONLY'),

    init: function init() {
      this._super.apply(this, arguments);

      if (this.mode === 'NEW') {
        this.ruleCondition.amount = 0;
        this.ruleCondition.amountRule = 'VALUE';
        this.ruleCondition.calculationRule = 'AMOUNT_NOOP';
        this.ruleCondition.ruleMethod = 'RULE';
        this.ruleCondition.day1 = 'Y';
        this.ruleCondition.day2 = 'Y';
        this.ruleCondition.day3 = 'Y';
        this.ruleCondition.day4 = 'Y';
        this.ruleCondition.day5 = 'Y';
        this.ruleCondition.day6 = 'Y';
        this.ruleCondition.day7 = 'Y';
        this.ruleCondition.children.push({
          ruleMethod: 'FILTER',
          filterType: 'ALL',
          children: [{
            ruleMethod: 'FILTER',
            filterType: 'ANY',
            children: []
          }, {
            ruleMethod: 'FILTER',
            filterType: 'ANY',
            children: []
          }]
        });

        this.set('selectedIfCategory', 'PRODUCT_TEMPLATE');
        this.set('selectedIfEquality', 'EQ');

        this.set('selectedAndCategory', 'PRODUCT_TEMPLATE');
        this.set('selectedAndEquality', 'EQ');
      } else {
        var ifObject = this.ifCondition.children.firstObject;
        var andObject = this.andCondition.children.firstObject;

        this.set('selectedIfCategory', ifObject.filterField);
        this.set('selectedIfEquality', ifObject.filterCondition);

        this.set('selectedAndCategory', andObject.filterField);
        this.set('selectedAndEquality', andObject.filterCondition);
      }

      // Register additional changesets
      this.registerChangeset('ruleConditionChangeset' + this.index, this.ruleConditionChangeset);
      this.registerChangeset('ifConditionChangeset' + this.index, this.ifConditionChangeset);
      this.registerChangeset('andConditionChangeset' + this.index, this.andConditionChangeset);
    },


    allCondition: Ember.computed('ruleCondition.children.[]', function () {
      return this.ruleCondition.children.findBy('filterType', 'ALL');
    }),

    ifCondition: Ember.computed('allCondition.children.[]', function () {
      return this.allCondition.children[0];
    }),

    andCondition: Ember.computed('allCondition.children.[]', function () {
      return this.allCondition.children[1];
    }),

    categoryValuesMatcher: function categoryValuesMatcher(option, searchTerm) {
      var searchLower = searchTerm.toLowerCase();
      var shortLower = option.shortDescription.toLowerCase();
      var externalLower = option.externalReference.toLowerCase();
      return shortLower.includes(searchLower) || externalLower.includes(searchLower) ? 1 : -1;
    },


    categoryOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.model-options.product_template'),
        value: 'PRODUCT_TEMPLATE'
      }, {
        label: this.i18n.t('rules-simple.model-options.product_reference'),
        value: 'PRODUCT_EXTERNAL_REFERENCE'
      }, {
        label: this.i18n.t('rules-simple.model-options.price_reference'),
        value: 'PRICE_EXTERNAL_REFERENCE'
      }];
    }),

    equalityOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.equality-options.is'),
        value: 'EQ'
      }, {
        label: this.i18n.t('rules-simple.equality-options.is_not'),
        value: 'NOT_EQ'
      }];
    }),

    amountRuleOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.penalty-options.flat_price'),
        value: 'VALUE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.first_day'),
        value: 'FIRST_SLICE'
      }, {
        label: this.i18n.t('rules-simple.penalty-options.total'),
        value: 'TOTAL'
      }];
    }),

    productTemplateOptions: Ember.computed(function () {
      return this.store.findAll('product-template');
    }),

    productOptions: Ember.computed('customer.id', function () {
      return this.store.query('product', {
        customerID: this.customer.id,
        productUsageType: 'AVAILABILITY',
        fields: 'id,shortDescription,externalReference'
      });
    }),

    priceOptions: Ember.computed('customer.id', function () {
      return this.store.query('price', {
        customerID: this.customer.id,
        fields: 'id,shortDescription,parentID,externalReference'
      });
    }),

    ifOptions: Ember.computed('selectedIfCategory', function () {
      switch (this.selectedIfCategory) {
        case 'PRODUCT_TEMPLATE':
          return this.productTemplateOptions;
        case 'PRODUCT_EXTERNAL_REFERENCE':
          return this.productOptions;
        case 'PRICE_EXTERNAL_REFERENCE':
          return this.priceOptions;
      }
    }),

    andOptions: Ember.computed('selectedAndCategory', function () {
      switch (this.selectedAndCategory) {
        case 'PRODUCT_TEMPLATE':
          return this.productTemplateOptions;
        case 'PRODUCT_EXTERNAL_REFERENCE':
          return this.productOptions;
        case 'PRICE_EXTERNAL_REFERENCE':
          return this.priceOptions;
      }
    }),

    mapOptions: function mapOptions(changeset, options, category) {
      var comparisonField = category === 'PRODUCT_TEMPLATE' ? 'id' : 'externalReference';
      return changeset.get('children').map(function (r) {
        return options.findBy(comparisonField, r.filterValue);
      });
    },


    selectedIfValues: Ember.computed('ifConditionChangeset.children.@each.filterValue', 'selectedIfCategory', 'ifOptions', function () {
      var _this = this;

      if (this.ifOptions && typeof this.ifOptions.then === 'function') {
        return this.ifOptions.then(function (options) {
          return _this.mapOptions(_this.ifConditionChangeset, options, _this.selectedIfCategory);
        });
      } else if (this.ifOptions) {
        return this.mapOptions(this.ifConditionChangeset, this.ifOptions, this.selectedIfCategory);
      }
    }),

    selectedAndValues: Ember.computed('andConditionChangeset.children.@each.filterValue', 'selectedAndCategory', 'andOptions', function () {
      var _this2 = this;

      if (this.andOptions && typeof this.andOptions.then === 'function') {
        return this.andOptions.then(function (options) {
          return _this2.mapOptions(_this2.andConditionChangeset, options, _this2.selectedAndCategory);
        });
      } else if (this.andOptions) {
        return this.mapOptions(this.andConditionChangeset, this.andOptions, this.selectedAndCategory);
      }
    }),

    selectedIfValuesString: Ember.computed('selectedIfValues.[]', 'selectedIfCategory', function () {
      var mapField = this.selectedIfCategory === 'PRODUCT_TEMPLATE' ? 'shortDescription' : 'externalReference';

      if (this.selectedIfValues && typeof this.selectedIfValues.then === 'function') {
        return this.selectedIfValues.then(function (values) {
          return values.mapBy(mapField).join(', ');
        });
      } else if (this.selectedIfValues) {
        return this.selectedIfValues.mapBy(mapField).join(', ');
      }
    }),

    selectedAndValuesString: Ember.computed('selectedAndValues.[]', 'selectedAndCategory', function () {
      var mapField = this.selectedAndCategory === 'PRODUCT_TEMPLATE' ? 'shortDescription' : 'externalReference';

      if (this.selectedAndValues && typeof this.selectedAndValues.then === 'function') {
        return this.selectedAndValues.then(function (values) {
          return values.mapBy(mapField).join(', ');
        });
      } else if (this.selectedAndValues) {
        return this.selectedAndValues.mapBy(mapField).join(', ');
      }
    }),

    ruleConditionChangeset: Ember.computed('ruleCondition', function () {
      var validations = {
        amount: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') }),
        amountRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true }),
        calculationRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.ruleCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    ifConditionChangeset: Ember.computed('ifCondition', function () {
      var validations = {
        children: (0, _validators.validateLength)({ min: 1, message: this.i18n.t('rules-simple.validation.invalid_selection') })
      };

      return new _emberChangeset.default(this.ifCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    andConditionChangeset: Ember.computed('andCondition', function () {
      var validations = {
        children: (0, _validators.validateLength)({ min: 1, message: this.i18n.t('rules-simple.validation.invalid_selection') })
      };

      return new _emberChangeset.default(this.andCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    updateCategory: function updateCategory(newCategory, currentCategory, changeset, valuesField, categoryField) {
      if (currentCategory !== newCategory) {
        changeset.set('children', []);
      }

      this.set(categoryField, newCategory);
    },
    updateEquality: function updateEquality(newEquality, currentEquality, changeset, equalityField) {
      if (currentEquality !== newEquality) {
        changeset.get('children').forEach(function (c) {
          return c.filterCondition = newEquality;
        });
      }

      changeset.set('filterType', newEquality === 'NOT_EQ' ? 'ALL' : 'ANY');

      this.set(equalityField, newEquality);
    },
    updateValues: function updateValues(values, category, equality, changeset) {
      var field = category === 'PRODUCT_TEMPLATE' ? 'id' : 'externalReference';
      var newValues = values.mapBy(field);

      // If there were de-selections, remove associated rules
      changeset.set('children', changeset.get('children').reject(function (c) {
        return !newValues.includes(c.filterValue);
      }));

      // Add rules for new selections
      newValues.forEach(function (v) {
        if (!changeset.get('children').findBy('filterValue', v)) {
          changeset.get('children').pushObject({
            ruleMethod: 'FILTER',
            filterType: 'FIELD_WHERE',
            filterField: category,
            filterCondition: equality,
            filterValue: v
          });

          // Manually re-validate to remove validation warning message
          changeset.validate();
        }
      });
    },


    actions: {
      deleteCondition: function deleteCondition() {
        this.onDelete(this.index);
      },
      updateSelectedIfCategory: function updateSelectedIfCategory(category) {
        this.updateCategory(category, this.selectedIfCategory, this.ifConditionChangeset, 'selectedIfValues', 'selectedIfCategory');
      },
      updateSelectedIfEquality: function updateSelectedIfEquality(equality) {
        this.updateEquality(equality, this.selectedIfEquality, this.ifConditionChangeset, 'selectedIfEquality');
      },
      updateSelectedIfValues: function updateSelectedIfValues(values) {
        this.updateValues(values, this.selectedIfCategory, this.selectedIfEquality, this.ifConditionChangeset);
      },
      updateSelectedAndCategory: function updateSelectedAndCategory(category) {
        this.updateCategory(category, this.selectedAndCategory, this.andConditionChangeset, 'selectedAndValues', 'selectedAndCategory');
      },
      updateSelectedAndEquality: function updateSelectedAndEquality(equality) {
        this.updateEquality(equality, this.selectedAndEquality, this.andConditionChangeset, 'selectedAndEquality');
      },
      updateSelectedAndValues: function updateSelectedAndValues(values) {
        this.updateValues(values, this.selectedAndCategory, this.selectedAndEquality, this.andConditionChangeset);
      },
      updateAmountRule: function updateAmountRule(amountRule) {
        this.ruleConditionChangeset.set('calculationRule', amountRule === 'VALUE' ? 'AMOUNT_NOOP' : 'AMOUNT_PERCENT');
        this.ruleConditionChangeset.set('amountRule', amountRule);
      }
    }

  });
});