define('ux-pcc/mixins/customer/performance/span-selectable', ['exports', 'moment', 'ux-components/utils/moment-range'], function (exports, _moment, _momentRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    queryParams: ['timespan', {
      beginDate: "date"
    }],

    inverseDates: Ember.computed(function () {
      return false;
    }),

    beginDate: Ember.computed('customer', function (key, value /*, previousValue*/) {

      var beginDate = void 0;
      // setter
      if (arguments.length > 1 && value !== void 0) {
        var tz = this.tz.get("customer");
        if (this.get('inverseDates')) {
          beginDate = _moment.default.tz(value, tz).endOf('day').format("YYYY-MM-DD");
        } else {
          beginDate = _moment.default.tz(value, tz).startOf('day').format("YYYY-MM-DD");
        }
      }

      // getter
      return beginDate;
    }),

    endDate: Ember.computed('beginDate', 'timespan', 'customer', function () {
      var beginDate = this.get('beginDate');
      var timespan = this.get('timespan');
      // var tz = this.tz.get("customer");

      if (this.get('inverseDates')) {
        return _moment.default.tz(beginDate, this.get('tz.customer')).subtract(timespan - 1, 'days').startOf('day').format();
      }
      return _moment.default.tz(beginDate, this.get('tz.customer')).add(timespan - 1, 'days').endOf('day').format();
    }),

    timespan: Ember.computed('_timespan', function () {
      return this.get('_timespan');
    }),

    _timespanObserver: Ember.observer('_timespan', function () {
      var value = this.get('_timespan') || 7; // default to 7
      // Clamp value between 1 and 180
      var timespan = Math.min(Math.max(value, 1), 180);
      this.set('_timespan', timespan);
    }),

    defaultTimespans: [3, 7, 15, 30, 60, 90, 180],

    timespanContent: Ember.computed('timespan', function () {
      var timespan = this.get('timespan');

      //if a default is selected, avoid duplicate timespan values in the droplist and automatic selection of custom range
      if (this.get('defaultTimespans').includes(timespan)) {
        timespan = 10;
      }

      return [{ value: 3, label: "3 Days" }, { value: 7, label: "7 Days" }, { value: 15, label: "15 Days" }, { value: 30, label: "30 Days" }, { value: 60, label: "60 Days" }, { value: 90, label: "90 Days" }, { value: 180, label: "180 Days" }, { value: timespan, label: "Custom Range", isCustom: true }];
    }),

    range: Ember.computed('beginDate', 'endDate', 'customer', function () {
      var tz = this.tz.get("customer");
      var beginDate = this.get('beginDate');
      var endDate = this.get('endDate');

      if (this.get('inverseDates')) {
        return (0, _momentRange.default)(_moment.default.tz(endDate, tz).endOf('day'), _moment.default.tz(beginDate, tz).startOf('day'));
      }

      return (0, _momentRange.default)(_moment.default.tz(beginDate, tz).startOf('day'), _moment.default.tz(endDate, tz).endOf('day'));
    }),

    calendarDecorator: Ember.computed('range', 'customer', function () {
      var range = this.get('range');
      var tz = this.tz.get('customer');
      return function (date, html) {
        // `this` is scoped to the span-picker component
        // Hook for highlighting days on calendar
        var shifted = _moment.default.tz(date.format("LLL"), "LLL", tz);

        if (shifted.isBetween(range.get('firstObject'), range.get('lastObject'), null, '[]')) {
          html.addClass("active-span");
        }
      };
    }),

    actions: {
      customTimespanChanged: function customTimespanChanged(value) {
        this.transitionTo({ queryParams: { timespan: value } });
      }
    }
  });
});