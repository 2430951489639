define('ux-pcc/components/dashboard-widget/booking-trend', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),

    showDaysActuals: false,
    showYieldActuals: false,
    showRevActuals: false,

    currencyCode: Ember.computed(function () {
      var model = this.get('customer.settings').findBy('settingCode', 'DEFAULT_CURRENCY');
      return model && model.get('settingValue') ? model.get('settingValue') : 'USD';
    }),

    isYst: Ember.computed.equal('model.key', 'YST'),

    daysCY: Ember.computed.readOnly('model.data.dataValues.daysEarnedCY'),
    daysPY: Ember.computed.readOnly('model.data.dataValues.daysEarnedLY'),
    yieldCY: Ember.computed.readOnly('model.data.dataValues.yieldCY'),
    yieldPY: Ember.computed.readOnly('model.data.dataValues.yieldLY'),
    revenueCY: Ember.computed.readOnly('model.data.dataValues.baseRevenueCY'),
    revenuePY: Ember.computed.readOnly('model.data.dataValues.baseRevenueLY'),

    graphConfig: Ember.computed('i18n.locale', function () {
      return Ember.Object.create({
        categoryField: 'slice',
        categoryTitleField: 'segmentTitle',
        showBalloon: false,
        graphs: [{
          title: this.get('i18n').t('dashboard-widget.future-trend.days_variance'),
          colorClass: 'chart-color--days',
          valueField: 'segmentDays',
          valueLabel: 'segmentDaysLabel',
          valueSuffix: '%',
          hideChartLabel: true,
          precisionHash: { precision: 1 }
        }, {
          title: this.get('i18n').t('dashboard-widget.future-trend.fleet_forecast'),
          colorClass: 'chart-color--yield',
          valueField: 'segmentYield',
          valueLabel: 'segmentYieldLabel',
          valueSuffix: '%',
          hideChartLabel: true,
          precisionHash: { precision: 1 }
        }, {
          title: this.get('i18n').t('dashboard-widget.future-trend.fleet_forecast'),
          colorClass: 'chart-color--revenue',
          valueField: 'segmentRevenue',
          valueLabel: 'segmentRevenueLabel',
          valueSuffix: '%',
          hideChartLabel: true,
          precisionHash: { precision: 1 }
        }]
      });
    }),

    segmentData: Ember.computed('model.data.dataValues', function () {
      var data = this.get('model.data.dataValues');
      if (data) {
        return {
          days: Number(data.daysEarnedPctVar),
          yield: Number(data.baseYieldPctVar),
          revenue: Number(data.baseRevenuePctVar)
        };
      }

      return null;
    }),

    dayYieldSegmentData: Ember.computed('segmentData', function () {
      var data = this.get('segmentData');
      return [{
        slice: this.get('model.title').toString(),
        segmentTitle: this.get('model.fullTitle'),
        segmentDays: data ? data.days : 0.0,
        segmentYield: data ? data.yield : 0.0,
        segmentRevenue: data ? data.revenue : 0.0,
        segmentDaysLabel: this.i18n.t('charts.year-over-year-segment.days'),
        segmentYieldLabel: this.i18n.t('charts.year-over-year-segment.yield'),
        segmentRevenueLabel: this.i18n.t('charts.year-over-year-segment.revenue')
      }];
    }),

    trend: Ember.computed('dayYieldSegmentData', function () {
      return { data: this.get('dayYieldSegmentData') };
    }),

    toggleActuals: function toggleActuals(value) {
      this.toggleProperty('show' + value + 'Actuals');
    }
  });
});