define('ux-pcc/components/view-card', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['view-card__container'],
    startDate: (0, _moment.default)(),
    loading: true,
    model: null,
    cardData: null,
    exportCsv: null,

    isMobile: Ember.computed.bool('deviceType.mobile'),

    title: Ember.computed.alias('model.title'),
    subTitle: Ember.computed.alias('model.subTitle'),

    pages: Ember.computed('cardData', function () {
      if (this.get('cardData')) {
        return this.get('cardData').get();
      }
    }),

    range: Ember.computed('model.weekOverWeek', function () {
      return this.get('model.weekOverWeek') ? 1 : 52;
    }),

    brand: Ember.computed('model.brand', function () {
      return 'view-card__brand ' + this.get('model.brand');
    }),

    didRender: function didRender() {
      this._setMobilePaginator();
    },
    _setMobilePaginator: function _setMobilePaginator() {
      if (this.get('deviceType.mobile')) {
        var outerPagination = Ember.$('#yield-manager__paging > .swiper-pagination');
        if (outerPagination && outerPagination.html() && outerPagination.html().length > 0) {
          var element = this.get('element');
          Ember.$('> .mobile-paginator', element).html(outerPagination.html());
        }
      }
    },


    _pullPagination: Ember.observer('deviceType.mobile', function () {
      this._setMobilePaginator();
    }),

    actions: {
      download: function download() {
        this.get('exportCsv')();
      },
      editView: function editView(view) {
        this.get('editView')(view);
      },
      viewInfo: function viewInfo(view) {
        this.get('viewInfo')(view);
      },
      fullScreen: function fullScreen() {
        if (!this.get('deviceType.mobile')) {
          Ember.getOwner(this).lookup('router:main').transitionTo('yield-manager.dashboard.view', this.get('model.view.id'));
        }
      }
    }
  });
});