define('ux-pcc/helpers/include', ['exports', 'ux-components/helpers/include'], function (exports, _include) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _include.default;
    }
  });
  Object.defineProperty(exports, 'include', {
    enumerable: true,
    get: function () {
      return _include.include;
    }
  });
});