define('ux-pcc/components/rules/rule-group', ['exports', 'ux-components/utils/to-boolean', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _toBoolean, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['rule-group'],

    onOr: function onOr() {},
    onRemove: function onRemove() {},
    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    isCeiling: Ember.computed.equal('ruleType', 'CEILING'),

    isDisplayEventTime: Ember.computed('customer.settings', function () {
      var isDisplayEventTime = false;
      var eventTimeSetting = (this.get('customer.settings') || []).findBy('settingCode', 'DISPLAY_EVENT_TIME');
      if (eventTimeSetting) {
        isDisplayEventTime = (0, _toBoolean.default)(eventTimeSetting.get('settingValue'));
      }
      return isDisplayEventTime;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        children: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.get('i18n').t('rule._form_modal.missing_conditional_filter').toString()
        })
      };
    }),

    changeset: Ember.computed('rule', function () {
      var validations = this.get('validations');
      var changeset = new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);

      var children = this.get('rule.children') || [];
      changeset.set('children', [].concat(_toConsumableArray(children)));
      return changeset;
    }),

    actions: {
      add: function add() {
        var newRule = {
          ruleMethod: "FILTER",
          filterType: "FIELD_WHERE",
          children: []
        };

        this.get('changeset.children').addObject(newRule);
      },
      or: function or(condition) {
        this.get('changeset.children').removeObject(condition);

        this.get('onOr')(condition, this.get('rule'));
      },
      remove: function remove(condition) {
        this.get('changeset.children').removeObject(condition);

        // In the case of competitor, you must have at least two conditions per rule
        // so start over
        if (this.get('rule.children.length') === 1 && this.get("rule.children.firstObject.filterField") === 'COMPETITOR') {
          this.get('changeset').set('children', []);
        }

        if (this.get('changeset.children.length') === 0) {
          this.get('onRemove')(this.get('rule'));
        }
      },
      registerValidation: function registerValidation(e) {
        this.get('onRegisterValidation')(e);
      },
      unregisterValidation: function unregisterValidation(v) {
        this.get('onUnregisterValidation')(v);
      }
    }

  });
});