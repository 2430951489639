define('ux-pcc/components/rules/rule-filter/time-period', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    value: null,

    parseValue: function parseValue(value) {
      // IE 11 doesn't support named capture groups
      // const regexp = /(?<sign>-)?P(?:(?<years>-?\d+)Y)?(?:(?<months>-?\d+)M)?(?:(?<weeks>-?\d+)W)?(?:(?<days>-?\d+)D)?/
      //
      // const match = value ? value.match(regexp) || {} : {};
      //
      // return match.groups || {};

      var regexp = /(-)?P(?:(-?\d+)Y)?(?:(-?\d+)M)?(?:(-?\d+)W)?(?:(-?\d+)D)?/;

      var match = value ? value.match(regexp) || {} : {};
      return {
        sign: match[1],
        years: match[2],
        months: match[3],
        weeks: match[4],
        days: match[5]
      };
    },
    formatValue: function formatValue(obj) {
      var value = obj.sign ? '-P' : 'P';

      if (obj.years) {
        value += obj.years + 'Y';
      }

      if (obj.months) {
        value += obj.months + 'M';
      }

      if (obj.weeks) {
        value += obj.weeks + 'W';
      }

      if (obj.days) {
        value += obj.days + 'D';
      }

      return value;
    },
    handleSet: function handleSet(field, value) {
      var obj = this.parseValue(this.get('value'));
      obj[field] = value;
      this.set('value', this.formatValue(obj));

      return value;
    },


    sign: Ember.computed('value', {
      get: function get() {
        return this.parseValue(this.get('value')).sign;
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    }),

    years: Ember.computed('value', {
      get: function get() {
        return this.parseValue(this.get('value')).years || 0;
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    }),

    months: Ember.computed('value', {
      get: function get() {
        return this.parseValue(this.get('value')).months || 0;
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    }),

    weeks: Ember.computed('value', {
      get: function get() {
        return this.parseValue(this.get('value')).weeks || 0;
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    }),

    days: Ember.computed('value', {
      get: function get() {
        return this.parseValue(this.get('value')).days || 0;
      },
      set: function set(field, value) {
        return this.handleSet(field, value);
      }
    })
  });
});