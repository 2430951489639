define('ux-pcc/routes/competitive-sets/add-pricing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('competitive-sets');
    },

    actions: {}
  });
});