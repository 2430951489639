define('ux-pcc/components/yield-manager/date-panel', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['parameter-panel', 'date-panel'],

    bookDateParam: Ember.computed.alias('dashboardViewParameters.BOOK_DATE'),

    beginDateParam: Ember.computed.alias('dashboardViewParameters.BEGIN_DATE'),

    endDateParam: Ember.computed.alias('dashboardViewParameters.END_DATE'),

    stlyBookDateParam: Ember.computed.alias('dashboardViewParameters.STLY_BOOK_DATE'),

    stlyBeginDateParam: Ember.computed.alias('dashboardViewParameters.STLY_BEGIN_DATE'),

    stlyEndDateParam: Ember.computed.alias('dashboardViewParameters.STLY_END_DATE'),

    stlyDateErrorParam: Ember.computed.alias('dashboardViewParameters.STLY_DATE_ERROR'),

    stlwBookDateParam: Ember.computed.alias('dashboardViewParameters.STLW_BOOK_DATE'),

    stlwBeginDateParam: Ember.computed.alias('dashboardViewParameters.STLW_BEGIN_DATE'),

    stlwEndDateParam: Ember.computed.alias('dashboardViewParameters.STLW_END_DATE'),

    stlwDateErrorParam: Ember.computed.alias('dashboardViewParameters.STLW_DATE_ERROR'),

    dateCompareParam: Ember.computed.alias('dashboardViewParameters.DATE_COMPARE'),

    dayOfWeekParam: Ember.computed.alias('dashboardViewParameters.DAY_OF_WEEK'),

    dataTypeParam: Ember.computed.alias('dashboardViewParameters.DATA_TYPE'),

    rateOfAddParam: Ember.computed.alias('dashboardViewParameters.RATE_OF_ADD'),

    rateOfAddTypeParam: Ember.computed.alias('dashboardViewParameters.RATE_OF_ADD_TYPE'),

    dateRangeParam: Ember.computed.alias('dashboardViewParameters.DATE_RANGE'),

    isSingleDayAnalysis: Ember.computed('dataTypeParam', function () {
      return this.get('dataTypeParam') && ['YICD'].indexOf(this.get('dataTypeParam')) > -1;
    }),

    isMultiComparisonAnalysis: Ember.computed('dataTypeParam', function () {
      return this.get('dataTypeParam') && ['YSGA'].indexOf(this.get('dataTypeParam')) > -1;
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    selectedSync: Ember.computed.alias('dashboardViewParameters.DATE_SYNC'),

    _getOffsetDate: function _getOffsetDate(date, dateCompare) {
      var offsetDate = void 0;
      if (dateCompare === 'YEAR') {
        offsetDate = date.clone().subtract(52, 'weeks');
      } else if (dateCompare === 'WEEK') {
        offsetDate = date.clone().subtract(1, 'week');
      } else {
        offsetDate = date.clone().subtract(12, 'months');
      }

      return offsetDate;
    },


    bookSliceChanged: Ember.observer('bookDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('bookDateParam');
      if (slice) {
        var converted = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));

        // Set the CP1 and CP2 booking dates
        this.set('dashboardViewParameters.STLY_BOOK_DATE', this._getOffsetDate(converted.clone(), 'YEAR'));
        this.set('dashboardViewParameters.STLW_BOOK_DATE', this._getOffsetDate(converted.clone(), 'WEEK'));
      }
    }),

    stlwBookSliceChanged: Ember.observer('stlwBookDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('stlwBookDateParam');

      if (slice) {
        var stlwBookDate = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));
        var bookDate = _moment.default.tz(this.get('bookDateParam'), _moment.default.ISO_8601, this.get('timezone'));
        var arrivalBeginDate = _moment.default.tz(this.get('beginDateParam'), _moment.default.ISO_8601, this.get('timezone'));

        // Set the CP1 arrivalDate start date based on BEGIN_DATE + (stlwBookDate - bookDate)
        this.set('dashboardViewParameters.STLW_BEGIN_DATE', arrivalBeginDate.clone().add(Math.round(stlwBookDate.diff(bookDate, 'days')), 'days'));
      }
    }),

    stlwBeginSliceChanged: Ember.observer('stlwBeginDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var stlwArrivalBeginDate = _moment.default.tz(this.get('stlwBeginDateParam'), _moment.default.ISO_8601, this.get('timezone'));
      var arrivalBeginDate = _moment.default.tz(this.get('beginDateParam'), _moment.default.ISO_8601, this.get('timezone'));
      var arrivalEndDate = _moment.default.tz(this.get('endDateParam'), _moment.default.ISO_8601, this.get('timezone'));
      if (stlwArrivalBeginDate && arrivalBeginDate && arrivalEndDate) {
        // Set the CP1 arrival end date based on CP1 arrival BEGIN_DATE + (arrivalEndDate - arrivalBeginDate)
        this.set('dashboardViewParameters.STLW_END_DATE', stlwArrivalBeginDate.clone().add(Math.round(arrivalEndDate.diff(arrivalBeginDate, 'days')), 'days'));
      }
    }),

    stlyBookSliceChanged: Ember.observer('stlyBookDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('stlyBookDateParam');

      if (slice) {
        var stlyBookDate = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));
        var bookDate = _moment.default.tz(this.get('bookDateParam'), _moment.default.ISO_8601, this.get('timezone'));
        var arrivalBeginDate = _moment.default.tz(this.get('beginDateParam'), _moment.default.ISO_8601, this.get('timezone'));

        // Set the CP2 arrivalDate start date based on BEGIN_DATE + (stlyBookDate - bookDate)
        this.set('dashboardViewParameters.STLY_BEGIN_DATE', arrivalBeginDate.clone().add(Math.round(stlyBookDate.diff(bookDate, 'days')), 'days'));
      }
    }),

    isToDateDisabled: Ember.computed('dateCompareParam', 'isSingleDayAnalysis', function () {
      return this.dateCompareParam !== 'CUSTOM' && this.isSingleDayAnalysis;
    }),

    _handleBeginSliceChanged: function _handleBeginSliceChanged() {
      var _this = this;

      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('beginDateParam');

      var converted = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));

      if (this.get('isMultiComparisonAnalysis')) {
        // Update the comparison periods arrival begin dates
        this.set('dashboardViewParameters.STLW_BEGIN_DATE', this._getOffsetDate(converted.clone(), 'WEEK'));
        this.set('dashboardViewParameters.STLY_BEGIN_DATE', this._getOffsetDate(converted.clone(), 'YEAR'));
        // Update the endDate if less than the new beginDate
        var endDate = _moment.default.tz(this.get('endDateParam'), this.get('timezone'));
        if (converted.isAfter(endDate, 'day')) {
          this.set('dashboardViewParameters.END_DATE', converted.clone().endOf('month'));
        }
      } else {
        var value = converted.format('LL');

        var syncType = this.get('selectedSync') || 'NONE';
        var dateCompare = this.get('dateCompareParam');

        if (syncType !== 'NONE') {
          // Update the stlyBeginDate based on the dateCompare value
          this.set('stlyBeginDateParam', this._getOffsetDate(converted.clone(), dateCompare));
        }

        // Update dependent picker if it exists
        var dependentPicker = Ember.$('.dpEndDate').data('DateTimePicker');
        if (dependentPicker) {
          dependentPicker.minDate(value);

          if (syncType !== 'NONE' && dateCompare !== 'CUSTOM') {
            var newDate = converted.clone().endOf(syncType.toLowerCase());
            if (_moment.default.isMoment(dependentPicker.date()) && newDate.format('YYYY-MM-DD') === dependentPicker.date().format('YYYY-MM-DD')) {
              // they could be the same, so force endSlicedChanged to run
              this.notifyPropertyChange('endDateParam');
            } else {
              this.set('endDateParam', newDate);
            }
          } else if ((0, _moment.default)(dependentPicker.date()).isBefore((0, _moment.default)(value))) {
            this.set('endDateParam', converted.clone());
          }
        } else if (!this.get('isSingleDayAnalysis')) {
          // Sometimes the dependentPicker isn't there yet, so
          // try again
          Ember.run.scheduleOnce('afterRender', this, function () {
            _this.notifyPropertyChange('beginDateParam');
          });
        }
      }
    },


    beginSliceChanged: Ember.observer('beginDateParam', 'dateCompareParam', 'selectedSync', function () {

      var slice = this.get('beginDateParam');

      // Strip to local for manipulation
      if (slice) {
        this._handleBeginSliceChanged();
      }
    }),

    endSliceChanged: Ember.observer('endDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('endDateParam');

      // Strip to local for manipulation
      if (slice) {
        var converted = _moment.default.tz(slice, _moment.default.ISO_8601, this.get('timezone'));
        if (this.get('isMultiComparisonAnalysis')) {
          // Update the comparison periods arrival end dates
          this.set('dashboardViewParameters.STLW_END_DATE', this._getOffsetDate(converted.clone(), 'WEEK'));
          this.set('dashboardViewParameters.STLY_END_DATE', this._getOffsetDate(converted.clone(), 'YEAR'));
        } else {
          // Update dependent picker
          var stlyDependentPicker = Ember.$('.dpStlyEndDate').data('DateTimePicker');
          if (stlyDependentPicker) {
            var syncType = this.get('selectedSync') || 'NONE';

            if (syncType !== 'NONE') {
              // Update the stlyEndDate based on the dateCompare value
              var dateCompare = this.get('dateCompareParam');
              var beginDate = _moment.default.tz(this.get('beginDateParam') || (0, _moment.default)(), _moment.default.ISO_8601, this.get('timezone'));

              this.set('stlyBeginDateParam', this._getOffsetDate(beginDate.clone(), dateCompare));
              this.set('stlyEndDateParam', this._getOffsetDate(converted.clone(), dateCompare));
            }
          }
        }
      }
    }),

    stlyBeginSliceChanged: Ember.observer('stlyBeginDateParam', function () {
      if (this.get('disableDateProcessing')) {
        return;
      }

      var slice = this.get('stlyBeginDateParam');
      var tz = this.get('timezone');

      if (slice && this.get('isMultiComparisonAnalysis')) {
        var stlyArrivalBeginDate = _moment.default.tz(slice, _moment.default.ISO_8601, tz);
        var arrivalBeginDate = _moment.default.tz(this.get('beginDateParam'), _moment.default.ISO_8601, tz);
        var arrivalEndDate = _moment.default.tz(this.get('endDateParam'), _moment.default.ISO_8601, tz);
        if (stlyArrivalBeginDate && arrivalBeginDate && arrivalEndDate) {
          // Set the CP2 arrival end date based on CP2 arrival BEGIN_DATE + (arrivalEndDate - arrivalBeginDate)
          this.set('dashboardViewParameters.STLY_END_DATE', stlyArrivalBeginDate.clone().add(Math.round(arrivalEndDate.diff(arrivalBeginDate, 'days')), 'days'));
        }
      } else {
        var _slice = this.get('stlyBeginDateParam');
        var value = '';
        // Strip to local for manipulation
        if (_slice) {
          var converted = _moment.default.tz(_slice, _moment.default.ISO_8601, tz);
          value = converted.format('LL');

          // Update dependent picker
          var dependentPicker = Ember.$('.dpStlyEndDate').data('DateTimePicker');
          if (dependentPicker) {
            dependentPicker.minDate(value);
            if ((0, _moment.default)(dependentPicker.date()).isBefore((0, _moment.default)(value))) {
              dependentPicker.date(value);
            }
          }
        }
      }
    }),

    daysOfWeek: Ember.computed(function () {
      return [{ value: 'ALL', description: this.get('i18n').t('dashboard-views.form.all') }, { value: 'MONDAY', description: this.get('i18n').t('dashboard-views.form.monday') }, { value: 'TUESDAY', description: this.get('i18n').t('dashboard-views.form.tuesday') }, { value: 'WEDNESDAY', description: this.get('i18n').t('dashboard-views.form.wednesday') }, { value: 'THURSDAY', description: this.get('i18n').t('dashboard-views.form.thursday') }, { value: 'FRIDAY', description: this.get('i18n').t('dashboard-views.form.friday') }, { value: 'SATURDAY', description: this.get('i18n').t('dashboard-views.form.saturday') }, { value: 'SUNDAY', description: this.get('i18n').t('dashboard-views.form.sunday') }];
    }),

    dateRanges: Ember.computed(function () {
      return [{ value: '1', description: this.get('i18n').t('dashboard-views.form.days', { count: 1 }) }, { value: '2', description: this.get('i18n').t('dashboard-views.form.days', { count: 2 }) }, { value: '3', description: this.get('i18n').t('dashboard-views.form.days', { count: 3 }) }, { value: '4', description: this.get('i18n').t('dashboard-views.form.days', { count: 4 }) }, { value: '5', description: this.get('i18n').t('dashboard-views.form.days', { count: 5 }) }, { value: '6', description: this.get('i18n').t('dashboard-views.form.days', { count: 6 }) }, { value: '7', description: this.get('i18n').t('dashboard-views.form.weeks', { count: 1 }) }, { value: '14', description: this.get('i18n').t('dashboard-views.form.weeks', { count: 2 }) }, { value: '30', description: this.get('i18n').t('dashboard-views.form.days', { count: 30 }) }, { value: '90', description: this.get('i18n').t('dashboard-views.form.days', { count: 90 }) }];
    }),

    rateOfAddHighlightTitle: Ember.computed('dataTypeParam', 'rateOfAddTypeParam', function () {
      if (['YMCD', 'YMCD-RES'].indexOf(this.get('dataTypeParam')) >= 0) {
        return this.get('i18n').t('dashboard-views.form.roa_highlight.' + this.get('rateOfAddTypeParam').toLowerCase());
      }
      return this.get('i18n').t('dashboard-views.form.roa_highlight.title');
    }),

    rateOfAddRanges: Ember.computed(function () {
      return [{ value: '-1', description: this.get('i18n').t('dashboard-views.form.days', { count: 1 }) }, { value: '-2', description: this.get('i18n').t('dashboard-views.form.days', { count: 2 }) }, { value: '-3', description: this.get('i18n').t('dashboard-views.form.days', { count: 3 }) }, { value: '-4', description: this.get('i18n').t('dashboard-views.form.days', { count: 4 }) }, { value: '-5', description: this.get('i18n').t('dashboard-views.form.days', { count: 5 }) }, { value: '-6', description: this.get('i18n').t('dashboard-views.form.days', { count: 6 }) }, { value: '-7', description: this.get('i18n').t('dashboard-views.form.days', { count: 7 }) }, { value: '-14', description: this.get('i18n').t('dashboard-views.form.days', { count: 14 }) }, { value: '-21', description: this.get('i18n').t('dashboard-views.form.days', { count: 21 }) }];
    }),

    rateOfAddTypeValues: Ember.computed(function () {
      return [{ value: 'ROA', description: this.get('i18n').t('dashboard-views.form.rate_of_add') }, { value: 'SNAPSHOT', description: this.get('i18n').t('dashboard-views.form.snapshot') }];
    }),

    actions: {
      saveParamUpdates: function saveParamUpdates() {
        if (this.get('onParamUpdates')) {
          this.get('onParamUpdates')();
        }
      }
    }

  });
});