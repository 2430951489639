define('ux-pcc/components/guarantee-panel/guarantee-form-deposit', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-deposit'], function (exports, _guaranteeFormDeposit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormDeposit.default;
    }
  });
});