define('ux-pcc/routes/rules-advanced/edit', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('rule', params.id, { include: 'children' });
    },


    actions: {
      saveRuleSet: function saveRuleSet() {
        var _this = this;

        var model = this.get('controller.model');
        // Force isDirty to ensure children save
        var beginDate = (0, _moment.default)(model.get('beginDate')).utc().format('YYYY-MM-DD');
        var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
        model.set('beginDate', (0, _moment.default)(beginDate).startOf('day'));
        model.set('endDate', endDate ? (0, _moment.default)(endDate).startOf('day') : void 0);

        // Check to see if we are editing a simple rule, and clear it, since it probably isn't simple anymore
        if (model.filterValueRule === 'SIMPLE') {
          model.set('filterValueRule', null);
        }
        model.save().then(function () {
          // Success!
          _this.controllerFor('rules-advanced').send('refresh');
          _this.replaceWith('rules-advanced');
          _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.i18n.t('rules.index.success_save_message', {
            ruleName: model.get('shortDescription')
          }), 'success');
        }).catch(function (error) {
          _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      }
    }
  });
});