define('ux-pcc/controllers/pacing/daily', ['exports', 'moment', 'accounting', 'lodash', 'ux-pcc/controllers/customer/performance/abstract-matrix'], function (exports, _moment, _accounting, _lodash, _abstractMatrix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractMatrix.default.extend(Ember.Evented, {
    queryParams: ['beginDate'],

    needs: ['customer'],

    table: Ember.inject.service(),

    fixedColumn: Ember.computed.not('deviceType.mobile'),

    knownFields: Ember.computed('isMobile', function () {
      return {
        "OTB": {
          label: this.i18n.t('pacing.per_day.table.OTB')
        },
        "OTB Total": {
          label: '<div>' + this.i18n.t('pacing.per_day.table.OTB') + '</div><div>' + this.i18n.t('pacing.per_day.table.total') + '</div>' // changed
        },
        "Pickup": {
          label: this.i18n.t('pacing.per_day.table.pickup')
        },
        "Average DOA": {
          label: '<div>' + this.i18n.t('pacing.per_day.table.three_week') + '</div><div>' + this.i18n.t('pacing.per_day.table.avg_doa') + '</div>', // changed
          mobileLabel: this.i18n.t('pacing.per_day.table.three_week') + ' ' + this.i18n.t('pacing.per_day.table.avg_doa')
        },
        "Average Pickup": {
          label: '<div>' + this.i18n.t('pacing.per_day.table.three_week') + '</div><div>' + this.i18n.t('pacing.per_day.table.avg_pickup') + '</div>', // changed
          mobileLabel: this.i18n.t('pacing.per_day.table.three_week') + ' ' + this.i18n.t('pacing.per_day.table.avg_pickup')
        },
        "Est Occ": {
          label: this.i18n.t('pacing.per_day.table.est_occ'),
          calculation: "percent"
        },
        "Curr Occ %": {
          label: this.i18n.t('pacing.per_day.table.occ'),
          calculation: "percent"
        },
        "Occ Comp": {
          label: this.i18n.t('pacing.per_day.table.occ_comp'),
          calculation: "percent",
          type: "Variance",
          limit: "Current",
          hide: this.get('isMobile')
        },
        "Occ Var": {
          label: this.i18n.t('pacing.per_day.table.occ_var'),
          calculation: "percent",
          hide: this.get('isNonMobile')
        }
      };
    }),

    isMobile: Ember.computed.bool('deviceType.mobile'),
    isNonMobile: Ember.computed.not('isMobile'),

    beginDate: null,

    beginDatePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    _parseData: Ember.observer('model', function () {
      var _this = this;

      var entries = this.get('model.currentPace');
      var columns = [];
      var rows = [];
      var groups = [];
      var tz = this.tz.get('customer');

      var knownFieldsMap = this.get('knownFields');

      var convertValue = function convertValue(key, value) {
        key = key.replace(/^[^:]+:/, "");
        if (knownFieldsMap[key] && knownFieldsMap[key].calculation) {
          if (knownFieldsMap[key].calculation === 'percent') {
            if (!isNaN(value)) {
              return _accounting.default.formatNumber(value, 1) + "%";
            }
          }
        }
        return value;
      };

      var getClassName = function getClassName(value) {
        if (!isNaN(value) && value < 0) {
          return "negative";
        }
      };

      var getCYClassName = function getCYClassName(value, compareValue) {
        if (!isNaN(value) && !isNaN(compareValue) && parseFloat(value) < parseFloat(compareValue) || isNaN(value) && !isNaN(compareValue)) {
          return "negative";
        }
      };

      var tableCellHTML = (0, _lodash.template)('<span class="<%= data.className %>">\n                  <%= (data.value) ? data.value : \'&mdash;\' %></span>\n              ', { 'variable': "data" });

      if (entries && entries.length > 0) {

        if (this.get('isMobile')) {
          var key = entries[0]["Date"];
          var date = _moment.default.tz(key, tz);
          var dow = date.format('ddd');

          columns = [{ key: 'Current', label: dow }, { key: 'Variance', label: this.i18n.t('pacing.stly_var') }];

          var current = entries[0].Current;
          var variance = entries[0].Variance;

          Object.keys(current).sort().forEach(function (key) {
            var keyLabel = key.replace(/^[^:]+:/, "");
            if (knownFieldsMap[keyLabel] && !knownFieldsMap[keyLabel].hide) {
              rows.push({
                label: knownFieldsMap[keyLabel] ? knownFieldsMap[keyLabel].mobileLabel ? knownFieldsMap[keyLabel].mobileLabel : knownFieldsMap[keyLabel].label : keyLabel,
                values: {
                  Current: tableCellHTML({
                    value: convertValue(key, current[key]),
                    className: getClassName(current[key])
                  }),
                  Variance: tableCellHTML({
                    value: convertValue(key, variance[key]),
                    className: getClassName(variance[key])
                  })
                }
              });
            }
          });

          groups.push({ label: "", rows: rows });
        } else {

          // Populate the columns from the attributes of the first record
          var _current = entries[0].Current;

          Object.keys(entries[0].Current).sort().forEach(function (key) {
            var keyLabel = key.replace(/^[^:]+:/, "");

            // Loop through the booking window columns
            if (keyLabel.toLowerCase() === 'bookingwindow') {
              _current[key].sortBy('orderBy').forEach(function (window) {
                columns.push({
                  key: window.key,
                  classNames: 'col-' + window.key.replace(/[ +%]/g, ''),
                  label: window.key
                });
              });
            } else {
              if (knownFieldsMap[keyLabel] && !knownFieldsMap[keyLabel].hide) {
                columns.push({
                  key: key,
                  classNames: 'col-' + keyLabel.replace(/[ +%]/g, ''),
                  label: knownFieldsMap[keyLabel].label ? knownFieldsMap[keyLabel].label : keyLabel
                });
              }
            }
          });

          var today = _moment.default.tz(tz).startOf("day");

          var createRow = function createRow(label, rowType, item, classNames, compare, stlyItem, varianceItem) {
            var values = {};

            Object.keys(item).forEach(function (key) {

              // Map the booking window records
              if (key.toLowerCase().indexOf('bookingwindow') >= 0) {
                item[key].forEach(function (window) {
                  var value = convertValue(window.key, window.amount);
                  var compareAmount = compare ? ((stlyItem[key] || {}).findBy('key', window.key) || {}).amount : null;
                  var keyLabel = window.key;
                  values[keyLabel] = {
                    value: tableCellHTML({ value: value, className: compare ? getCYClassName(window.amount, compareAmount) : "" }),
                    classNames: ['col-' + keyLabel]
                  };
                });
              } else {
                var value = convertValue(key, item[key]);
                var _variance = false;
                var columnCompare = compare;

                // Split the key, and check the known fields to see if the value resides on the VarianceItem
                var knownKeys = key.split(':');
                if (knownKeys.length === 2) {
                  var knownFields = _this.get('knownFields');
                  if (knownFields[knownKeys[1]] && knownFields[knownKeys[1]].type === 'Variance') {
                    if (knownFields[knownKeys[1]].limit === rowType) {
                      value = convertValue(key, varianceItem[key]);
                      columnCompare = false;
                      _variance = true;
                    } else {
                      value = null;
                    }
                  }
                }
                var keyLabel = key.replace(/^[^:]+:/, "");
                var compareAmount = columnCompare ? stlyItem[key] : null;
                values[key] = {
                  value: tableCellHTML({ value: value, className: columnCompare ? getCYClassName(item[key], compareAmount) : _variance ? getClassName(varianceItem[key]) : "" }),
                  classNames: ['col-' + keyLabel.replace(/[ +%]/g, '')]
                };
              }
            });

            return {
              label: label,
              values: values,
              classNames: classNames
            };
          };

          var createGroup = function createGroup(data) {

            var masterMap = {};

            // Loop through the calendar items
            data.forEach(function (item, i) {

              if (item.error) {
                //Inform about the error
                _this.send('flash', "<i class='fa fa-warning'></i> " + _this.i18n.t("customer.performance.report.load_error") + " '" + item.error + "'.", 'danger');
              } else {
                var _key = item["Date"];
                var _date = _moment.default.tz(_key, tz).startOf("day");

                var formattedDate = _date.format('L');
                formattedDate = formattedDate.substring(0, formattedDate.length - 4) + formattedDate.substring(formattedDate.length - 2);

                var groupByValue = formattedDate;
                var names = [];

                if (_date.valueOf() === today.valueOf()) {
                  names.push("active");
                }

                if (!(groupByValue in masterMap)) {
                  masterMap[groupByValue] = {
                    group: groupByValue,
                    label: '<span>' + groupByValue + '</span><span class="dow">' + _date.format('ddd') + '</span>',
                    labelAsCell: true,
                    rows: [],
                    classNames: i > 0 ? 'hidden' : ''
                  };
                }

                var valueRow = createRow(_this.i18n.t('pacing.cy'), "Current", item.Current, names, true, item.STLY, item.Variance);
                masterMap[groupByValue].rows.push(valueRow);

                var varRow = createRow(_this.i18n.t('pacing.py'), "STLY", item.STLY, names);
                masterMap[groupByValue].rows.push(varRow);
              }
            });

            var currentWeek = [];

            for (var group in masterMap) {
              var rec = masterMap[group];
              currentWeek.push(rec);
            }

            return currentWeek;
          };

          rows = createGroup(entries);

          // Chunking functions
          groups = Ember.A();

          if (rows.length > 0) {
            var max = rows.length;
            var step = 5;
            var index = 0;

            var _chunkData = void 0;
            _chunkData = function chunkData() {
              index += step;

              if (max >= index) {
                Ember.run.later(_chunkData, 100);
              } else {
                _this.get('table').trigger('tableInvalidate');
              }

              groups.addObjects(rows.splice(0, step));
            };

            Ember.run.scheduleOnce("afterRender", _chunkData);

            groups.addObjects(rows.splice(0, step));
          }
        }

        this.set('pacingTableData', {
          groups: groups,
          columns: columns
        });
      } else {
        this.set('pacingTableData', null);
      }
    })
  });
});