define('ux-pcc/components/rule-advanced/rule-filter-value', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    sortedCodeOptions: Ember.computed.sort('codeOptions', 'codeSort'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      changeset.setProperties({
        filterRangeLow: null,
        filterRangeHigh: null,
        filterValueRule: null,
        filterField2: null
      });

      this.get('onRegisterValidation')(changeset);

      if (this.type === 'code') {
        this.set('codeSort', ['orderBy', 'shortDescription']);
        this.store.query('code', { customerID: this.get('customer.id'), codeType: this.get('filterFieldItem.value') }).then(function (codes) {
          _this.set('codeOptions', codes.reject(function (code) {
            var shortDescription = code.get('shortDescription').toUpperCase();
            return shortDescription.indexOf('EXCLUDE') === 0;
          }).sortBy('orderBy'));
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        filterValue: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form_modal.no_value').toString()
        })
      };
    }),

    changeset: Ember.computed('model', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    listValues: Ember.computed('filterFieldItem.value', 'listOptions', function () {
      var value = this.get('filterFieldItem.value');
      if (!value) {
        return [];
      }

      return this.get('listOptions').filter(function (item) {
        return item.type === value;
      });
    }),

    modelListValue: Ember.computed('changeset.filterValue', 'listOptions.@each.value', function () {
      return this.get('listOptions').findBy('value', this.get('changeset.filterValue'));
    }),

    modelCodeValue: Ember.computed('changeset.filterValue', 'codeOptions.@each.value', function () {
      return (this.get('codeOptions') || []).findBy('codeValue', this.get('changeset.filterValue'));
    }),

    isNumberLikeType: Ember.computed('type', function () {
      return ['percent', 'number', 'numerical'].includes(this.get('type'));
    }),

    actions: {
      getValue: function getValue(field, obj) {
        return obj ? Ember.get(obj, field) : null;
      }
    }
  });
});