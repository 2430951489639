define('ux-pcc/controllers/yield-manager/analyze', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['selectedDate', 'selectedEndDate', 'mode'],

    selectedDate: null,
    selectedEndDate: null,
    hideAdhoc: true,
    mode: 'stacked',
    tableModeOnly: false,
    yieldManager: Ember.inject.controller('yield-manager'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    ajax: Ember.inject.service(),
    menuService: Ember.inject.service('menu'),
    // activeOrganizationGroup: '',
    // activeChannelsGroup: '',
    // beginDate: null,
    // endDate: null,
    // stlyBeginDate: null,
    // stlyEndDate: null,
    // hideSubChannels: false,
    requeryAnalytics: false,
    // isSingleDayAnalysis: false,
    selectedLevels: null,
    products: null,
    isOpportunities: false,
    childLoading: false,
    allowDownload: false,
    downloadTrigger: false,

    // Set to closed for default
    showFilter: false,

    productGroups: null,

    opportunitiesPossibleLevelColumns: null,
    opportunitiesActualLevelColumns: null,
    opportunitiesPossibleFilterColumns: null,
    opportunitiesActualFilterColumns: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('showFilter', false);
      this.set('selectedLevels', []);
      this.set('productGroups', []);
      this.set('opportunitiesPossibleLevelColumns', ['country', 'svp', 'group', 'region', 'subRegion', 'city', 'area', 'station' /*, 'pod', 'uda'*/]);
      this.set('opportunitiesActualLevelColumns', ['country', 'svp', 'group', 'region', 'subRegion', 'city', 'area', 'station']);
      this.set('opportunitiesPossibleFilterColumns', ['channel', 'subChannel', 'ratePlan', 'brand', 'productType']);
      this.set('opportunitiesActualFilterColumns', []);
    },


    // currentLevel: computed.readOnly('selectedLevels.lastObject.LEVEL_DESCRIPTION'),

    // Future Enhancement: Include POD and UDA customers
    // customerLevels: computed('yieldManager.customerLevels', function () {
    //   return this.get('yieldManager.customerLevels').filter((level) => {
    //     return (['LEVEL_POD', 'LEVEL_UDA'].indexOf(level.get('codeValue')) < 0);
    //   }) || [];
    // }),
    //
    // pickerOptions: computed('i18n.locale', function () {
    //   return {
    //     locale: moment.locale(),
    //     useCurrent: false
    //   };
    // }),
    //
    // beginSliceChanged: observer('beginDate', function () {
    //   let slice = this.get('beginDate');
    //   let value = '';
    //   // Strip to local for manipulation
    //   if (slice) {
    //     let converted = moment.tz(slice, moment.ISO_8601, this.tz.get('customer'));
    //     value = converted.format('LL');
    //   }
    //
    //   // Update dependent picker
    //   let dependentPicker = $('#endDate').data('DateTimePicker');
    //   if (dependentPicker) {
    //     dependentPicker.minDate(value);
    //     if (moment(dependentPicker.date()) < moment(value)) {
    //       dependentPicker.date(value);
    //     }
    //   }
    // }),
    //
    // stlyBeginSliceChanged: observer('stlyBeginDate', function () {
    //   let slice = this.get('stlyBeginDate');
    //   let value = '';
    //   // Strip to local for manipulation
    //   if (slice) {
    //     let converted = moment.tz(slice, moment.ISO_8601, this.tz.get('customer'));
    //     value = converted.format('LL');
    //   }
    //
    //   // Update dependent picker
    //   let dependentPicker = $('#stlyEndDate').data('DateTimePicker');
    //   if (dependentPicker) {
    //     dependentPicker.minDate(value);
    //     if (moment(dependentPicker.date()) < moment(value)) {
    //       dependentPicker.date(value);
    //     }
    //   }
    // }),

    // Commenting out because this class does not exist, need to re-add stickyfill dependency if stickyfill is still desired
    // init() {
    //   scheduleOnce('afterRender', this, () => {
    //     $('.yield-manager__analyze-parameters--title').Stickyfill();
    //   });
    // },

    _breadcrumbObserver: Ember.observer('model.dashboardViewParameters.DATA_TYPE', 'menuService.menus.length', function () {
      Ember.run.once(this, '_computeBreadCrumbPermission');
    }),

    _computeBreadCrumbPermission: function _computeBreadCrumbPermission() {
      var prevPermission = this.get('allowBreadcrumbNavigation');

      // If the data type is MBS, user must have access to the MBS menu item to navigate to it
      if (this.get('model.dashboardViewParameters.DATA_TYPE') === 'MBS') {
        var menuList = this.get('menuService.flattenedMenus');

        if (menuList && menuList.length > 0) {
          var viewMenu = menuList.find(function (menu) {
            return (menu.get('actionUrl') || '').indexOf('customer.monthly-booking-summary') >= 0;
          });

          if (viewMenu && !prevPermission) {
            this.set('allowBreadcrumbNavigation', true);
          }
        }

        if (prevPermission) {
          this.set('allowBreadcrumbNavigation', false);
        }
      }

      if (!prevPermission) {
        this.set('allowBreadcrumbNavigation', true);
      }
    },


    allowBreadcrumbNavigation: true,

    _breadcrumbHomeParamObserver: Ember.observer('model.dashboardViewParameters.ANALYZE_BREADCRUMB_HOME', function () {
      // something is double updating this value, so hack around the
      if (this.get('breadcrumbHome') !== this.get('model.dashboardViewParameters.ANALYZE_BREADCRUMB_HOME')) {
        var home = this.get('model.dashboardViewParameters.ANALYZE_BREADCRUMB_HOME');
        this.set('breadcrumbHome', Ember.isEmpty(home) ? 'yield-manager' : home);
      }
    }),

    _updateParameter: function _updateParameter(name, value) {
      var paramValue = this.get('model.dashboardViewParameters');
      Ember.set(paramValue, name, value);
    },


    _levelHierarchyParamObserver: Ember.observer('model.dashboardViewParameters.LEVEL_HIERARCHY', function () {
      // something is double updating this value, so hack around the
      if (this.get('levelHierarchyParam') !== this.get('model.dashboardViewParameters.LEVEL_HIERARCHY')) {
        this.set('levelHierarchyParam', this.get('model.dashboardViewParameters.LEVEL_HIERARCHY'));
      }
    }),

    levelParam: Ember.computed.alias('model.dashboardViewParameters.LEVEL'),

    _updateProductGroups: function _updateProductGroups() {
      var _this = this;

      var selectedLevels = this.get('levelParam');

      return this.get('ajax').request('/rest/v1/dashboardViews/products', { data: {
          customers: selectedLevels,
          fields: 'id,shortDescription,externalReference,customerID'
        } }).then(function (_ref) {
        var lists = _ref.lists;


        // Check the uniqueness of the customer ids coming back, as they are the blueprints
        // Only proceed if using a single blueprint
        var customerIds = (lists || []).mapBy('customerID').uniq();
        if (customerIds.length === 1) {
          var promises = [];
          var productList = [];
          lists.forEach(function (item) {
            promises.push(_this.get('store').findRecord('product', item.id, { include: 'children' }).then(function (product) {
              productList.pushObject(product);
            }));
          });
          return Ember.RSVP.allSettled(promises).then(function () {
            _this.set('productGroups', productList.sortBy('shortDescription'));
          });
        } else {
          _this.set('productGroups', []);
        }
      });
    },


    currentLevel: null,

    _setCurrentLevel: function _setCurrentLevel() {
      var selectedView = this.get('model');
      var levelHierarchy = selectedView.get('dashboardViewParameters.LEVEL_HIERARCHY');
      var level = selectedView.get('dashboardViewParameters.LEVEL');
      var levelCode = this.get('customerLevels').findBy('codeValue', levelHierarchy);
      var levelDescription = levelCode ? levelCode.get('shortDescription') : this.get('i18n').t('dashboard-views.form.unknown_level');

      this.set('currentLevel', this.get('selectedLevels.lastObject.LEVEL_DESCRIPTION') || [{
        'LEVEL_HIERARCHY': levelHierarchy,
        'LEVEL': level,
        'LEVEL_DESCRIPTION': levelDescription
      }]);
    },


    _currentLevelObserver: Ember.observer('model.dashboardViewParameters.{LEVEL_HIERARCHY,LEVEL}', function () {
      Ember.run.once(this, '_setCurrentLevel');
    }),

    // _initializeBreadcrumb(){
    //   // set top level breadcrumb
    //   let selectedView = this.get('model');
    //   let levelHierarchy = selectedView.get('dashboardViewParameters.LEVEL_HIERARCHY');
    //   let level = selectedView.get('dashboardViewParameters.LEVEL');
    //   let levelCode = this.get('customerLevels').findBy('codeValue', levelHierarchy);
    //   let levelDescription = levelCode ? levelCode.get('shortDescription') : this.get('i18n').t('dashboard-views.form.unknown_level');
    //   // this.set('currentLevel', levelDescription);
    //   this.set('selectedLevels', [{
    //     'LEVEL_HIERARCHY': levelHierarchy,
    //     'LEVEL': level,
    //     'LEVEL_DESCRIPTION': levelDescription
    //   }]);
    // },

    // Commenting out because this class does not exist, need to re-add stickyfill dependency if stickyfill is still desired
    // willDestroy: function () {
    //   Stickyfill.remove($('.yield-manager__analyze-parameters--title'));
    // },

    opportunitiesModel: null,

    _setOpportunitiesModel: function _setOpportunitiesModel() {
      var _this2 = this;

      this.set('opportunitiesModel', {
        opportunitiesPossibleLevelColumns: this.get('opportunitiesPossibleLevelColumns'),
        opportunitiesActualLevelColumns: this.get('opportunitiesActualLevelColumns'),
        opportunitiesPossibleFilterColumns: this.get('opportunitiesPossibleFilterColumns'),
        opportunitiesActualFilterColumns: this.get('opportunitiesActualFilterColumns'),
        onSave: function onSave(levelColumns, filterColumns) {
          _this2.set('opportunitiesActualLevelColumns', levelColumns);
          _this2.set('opportunitiesActualFilterColumns', filterColumns);
        }
      });
    },

    opportunitiesModelObserver: Ember.observer('opportunitiesPossibleLevelColumns', 'opportunitiesActualLevelColumns', 'opportunitiesPossibleFilterColumns', 'opportunitiesActualFilterColumns', function () {
      Ember.run.once(this, '_setOpportunitiesModel');
    }),

    actions: {
      toggleShowFilter: function toggleShowFilter() {
        this.toggleProperty('showFilter');
      },
      saveParamUpdates: function saveParamUpdates(holdPosition) {
        var _this3 = this;

        // Just changed the search, so top level breadcrumb should be re-initialized
        // this._initializeBreadcrumb();
        this._updateProductGroups().then(function () {
          _this3.notifyPropertyChange('requeryAnalytics');
          _this3.toggleProperty('reloadParameters');
          // Slide to the top
          if (!holdPosition) {
            Ember.$('#main-content').animate({ scrollTop: 0 });
          }
        });
      },
      changeMode: function changeMode(mode) {
        this.set('mode', mode);
      },
      ascendBreadcrumb: function ascendBreadcrumb() {
        if (this.get('selectedLevels.length') > 1 && !this.get('childLoading')) {
          var currentLevel = this.get('selectedLevels').objectAt(this.get('selectedLevels.length') - 1);
          this.get('selectedLevels').removeObject(currentLevel);
          var ancestorLevel = this.get('selectedLevels').objectAt(this.get('selectedLevels.length') - 1);
          if (ancestorLevel) {
            // this.set('currentLevel', ancestorLevel['LEVEL_DESCRIPTION']);
            // set level hierarchy
            // let levelHierarchy = this.get('model.dashboardViewParameters.LEVEL_HIERARCHY');
            // levelHierarchy = ancestorLevel['LEVEL_HIERARCHY'];
            // this.set('selectedParsedView.LEVEL_HIERARCHY', ancestorLevel['LEVEL_HIERARCHY']);
            // this.set('selectedParsedView.customerLevelLabel', ancestorLevel['LEVEL_DESCRIPTION']);
            // set level
            // let levels = this.get('model.dashboardViewParameters.LEVEL');
            // levels = ancestorLevel['LEVEL'];
            // this._queryCustomerLevels(ancestorLevel['LEVEL_HIERARCHY'], isEmpty(levels) ? {value: 'NONE'} : levels);
            // this.set('activeParam', levels);
            this.send('saveParamUpdates');
          }
        }
      },
      updateFilter: function updateFilter(data) {
        var _this4 = this;

        // Set the filter value with the selected item, and reload the data
        var category = this.get('model.dashboardViewParameters.' + data.filterCategory);
        if (typeof category !== 'undefined') {

          // If LEVEL, then we need to look up the customer, and switch the LEVEL_HIERARCHY parameter as well
          if (data.filterCategory === 'LEVEL') {

            // Requery if not already at this level
            if (this.get('customer.externalReference') !== data.categoryValue) {
              this.get('ajax').request('rest/v1/customers/customerByExternalReference/' + data.categoryValue, {
                data: {
                  include: 'tags'
                }
              }).then(function (_ref2) {
                var customer = _ref2.customer;

                var tagList = (Ember.get(customer, 'tags') || []).filter(function (tag) {
                  return tag.startsWith('LEVEL_');
                });

                if (tagList.length > 0) {
                  _this4.set('model.dashboardViewParameters.LEVEL_HIERARCHY', tagList[0]);
                  _this4.set('model.dashboardViewParameters.' + data.filterCategory, Ember.get(customer, 'id'));
                  _this4.send('saveParamUpdates');
                }
              }, function () {
                _this4.send('flash', '<i class="fa fa-warning"></i> ' + _this4.get('i18n').t('yield-manager.analyze.message.level_fetch_error'), 'danger');
              });
            }
          } else {
            this.set('model.dashboardViewParameters.' + data.filterCategory, data.categoryValue);
            this.send('saveParamUpdates');
          }
        }

        // NOTE: Original Logic to walk the Level Hierachy
        // let currentLevel = this.get('selectedLevels').objectAt((this.get('selectedLevels.length') - 1));
        // let levelCode = this.get('customerLevels').findBy('codeValue', currentLevel.LEVEL_HIERARCHY);
        // let currentLevelIndex = this.get('customerLevels').indexOf(levelCode);
        // let nextIndex = (currentLevelIndex + 1);
        // let selectedLevel = this.get('customerLevels').objectAt(nextIndex);
        // if (selectedLevel){
        //   // this.set('currentLevel', selectedLevel.get('shortDescription'));
        //   this.get('selectedLevels').push({
        //     'LEVEL_HIERARCHY': selectedLevel.get('codeValue'),
        //     'LEVEL': data.customerID,
        //     // 'LEVEL_DESCRIPTION': selectedLevel.get('shortDescription'),
        //     value: data.customerID
        //   });
        //   // set level hierarchy
        //   let levelHierarchy = this.get('model.dashboardViewParameters.LEVEL_HIERARCHY');
        //   levelHierarchy = selectedLevel.get('codeValue');
        //   // this.set('selectedParsedView.LEVEL_HIERARCHY', selectedLevel.get('codeValue'));
        //   // this.set('selectedParsedView.customerLevelLabel', selectedLevel.get('shortDescription'));
        //   // set level
        //   let levels = this.get('model.dashboardViewParameters.LEVEL');
        //   levels = data.customerID;
        //   // this._queryCustomerLevels(selectedLevel.get('codeValue'), {value: 'NONE'});
        //   // this.set('activeParam', levels);
        //   this.send('saveParamUpdates');
        // }
      },
      setOpportunitiesActualLevelColumns: function setOpportunitiesActualLevelColumns(columns) {
        this.set('opportunitiesActualLevelColumns', columns);
      },
      setOpportunitiesActualFilterColumns: function setOpportunitiesActualFilterColumns(columns) {
        this.set('opportunitiesActualFilterColumns', columns);
      },
      download: function download() {
        this.notifyPropertyChange('triggerDownload');
      }
    }
  });
});