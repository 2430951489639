define('ux-pcc/components/competitive-set/competitor-feed', ['exports', 'ux-pcc/components/tables/table-basic', 'ux-components/utils/format-currency'], function (exports, _tableBasic, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tableBasic.default.extend({

    isMobile: false,
    isExpanded: false,
    currencyCode: null,
    margin: 0,

    myProperty: Ember.computed('tableData.columns', 'isMobile', function () {
      var _this = this;

      if (this.get('isMobile')) {
        var publicCompetitor = this.get('tableData.columns').findBy('id', 'public');
        var publicRow = this.get('tableData.rows').findBy('key', 'public');
        var publicRate = "";
        if (publicRow && publicRow.rateKey) {
          publicRate = publicCompetitor.rates[publicRow.rateKey];
        }

        var getClassList = function getClassList(value) {
          if (isNaN(value) || Ember.isEmpty(value)) {
            return "text-danger";
          }
          return "";
        };

        var getFormattedValue = function getFormattedValue(value) {
          return isNaN(value) || Ember.isEmpty(value) ? Ember.isEmpty(value) ? _this.get('i18n').t('pcc-components.competitor.rates.no_price') : value : (0, _formatCurrency.default)(value, _this.get('currencyCode'));
        };

        return {
          name: publicCompetitor.name,
          rate: '<span class="' + getClassList(publicRate) + '">' + getFormattedValue(publicRate) + '</span>'
        };
      }
    }),

    filteredRows: Ember.computed('tableData.rows.@each.key', 'isMobile', function () {
      var rows = this.get('tableData.rows');

      // If this is mobile, strip out the public row
      if (this.get('isMobile')) {
        return rows.filter(function (r) {
          return r.key !== 'public';
        });
      } else {
        return rows;
      }
    }),

    actions: {
      refreshRows: function refreshRows() {
        this.notifyPropertyChange('tableData');
      }
    }
  });
});