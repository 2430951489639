define('ux-pcc/routes/rules-advanced/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    dialog: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');
      return this.store.query('rule', {
        customerID: customer.get('id'),
        include: 'children'
      });
    },


    actions: {
      confirmDelete: function confirmDelete(object) {
        var _this = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('rules.index.discard_rule'),
          messageText: this.i18n.t('rules.index.confirm_delete'),
          confirmText: this.i18n.t('rules.index.continue'),
          cancelText: this.i18n.t('rules.index.cancel'),
          onConfirm: function onConfirm() {

            object.destroyRecord().then(function () {
              _this.refresh();
              _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.i18n.t('rules.index.success_delete_message', {
                ruleName: object.get('shortDescription')
              }), 'success');
            }).catch(function (error) {
              _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
            });
          },
          onCancel: function onCancel() {
            _this.dialog.close();
          }
        });
      },
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});