define('ux-pcc/controllers/customer/performance/-matrix-pricing-target-copy-modal', ['exports', 'moment', 'ux-pcc/controllers/availability/-base-modal'], function (exports, _moment, _baseModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModal.default.extend({
    matrixPricingTargetController: Ember.inject.controller('customer/performance/matrix-pricing-target'),
    date: Ember.computed.oneWay('model.selectedSlice'),
    model: null,
    previousPricing: null,
    hasPreviousPricingSave: false,
    isMobile: Ember.computed.oneWay('matrixPricingTargetController.isMobile'),
    isNotMobile: Ember.computed.not('isMobile'),
    isError: false,
    isSaving: false,
    errorText: '',
    preventBlur: false,
    copyEventTime: false,

    day0Active: false,
    day1Active: false,
    day2Active: false,
    day3Active: false,
    day4Active: false,
    day5Active: false,
    day6Active: false,

    modalTitle: Ember.computed('model.isCopy', function () {
      if (this.get('model.isCopy')) {
        return "customer.performance.matrix-pricing.copy.title";
      } else {
        return "customer.performance.matrix-pricing.add.title";
      }
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false,
        minDate: (0, _moment.default)().startOf('day')
      };
    }),

    copiedPricePoints: Ember.computed('model.{pricePoints,selectedSlice}', function () {
      var _this = this;

      if (!this.get('model.selectedSlice')) {
        return;
      }

      var tz = this.tz.get("customer");
      var selectedSlice = this.get('model.selectedSlice').tz(tz).format();
      return this.get('model.pricePoints').map(function (pricePoint) {
        var pricePointProxy = Ember.ObjectProxy.create({ content: pricePoint });

        if (_this.get('model.isCopy') && pricePoint.values[selectedSlice]) {
          var allocatedValues = pricePoint.values[selectedSlice].values;

          pricePointProxy.set('value', allocatedValues.val);
          pricePointProxy.set('lead', allocatedValues.lead);
        } else {
          pricePointProxy.set('value', null);
          pricePointProxy.set('lead', null);
        }

        return pricePointProxy;
      });
    }),

    _serialize: function _serialize() {
      return Ember.$.extend({
        date: this.get('model.selectedSlice').format() || void 0,
        pricePoints: this.get('copiedPricePoints')
      }, this._super());
    },
    _hasIncompleteWeekSelected: function _hasIncompleteWeekSelected(data) {
      var hasIncompleteWeek = false;
      var dayCount = (0, _moment.default)(data.endSlice).diff((0, _moment.default)(data.beginSlice), 'days') + 1;

      var currentDate = _moment.default.tz(data.beginSlice, this.get('tz').customer);
      this.setProperties({
        day0Active: false,
        day1Active: false,
        day2Active: false,
        day3Active: false,
        day4Active: false,
        day5Active: false,
        day6Active: false
      });

      for (var i = dayCount; i > 0; i--) {
        // Use mod-7 here to make Sunday = 0, Monday = 1, etc.
        var dayOfWeek = currentDate.format('E') % 7;
        var dayActive = !currentDate.isBefore((0, _moment.default)().startOf('day'));

        switch (dayOfWeek) {
          case 0:
            this.set('day0Active', dayActive);
            break;
          case 1:
            this.set('day1Active', dayActive);
            break;
          case 2:
            this.set('day2Active', dayActive);
            break;
          case 3:
            this.set('day3Active', dayActive);
            break;
          case 4:
            this.set('day4Active', dayActive);
            break;
          case 5:
            this.set('day5Active', dayActive);
            break;
          case 6:
            this.set('day6Active', dayActive);
            break;
        }

        currentDate.add(1, 'day');
      }

      if (this.get('day0Active') && !data.day0) {
        hasIncompleteWeek = true;
      }
      if (this.get('day1Active') && !data.day1) {
        hasIncompleteWeek = true;
      }
      if (this.get('day2Active') && !data.day2) {
        hasIncompleteWeek = true;
      }
      if (this.get('day3Active') && !data.day3) {
        hasIncompleteWeek = true;
      }
      if (this.get('day4Active') && !data.day4) {
        hasIncompleteWeek = true;
      }
      if (this.get('day5Active') && !data.day5) {
        hasIncompleteWeek = true;
      }
      if (this.get('day6Active') && !data.day6) {
        hasIncompleteWeek = true;
      }
      this.set('hasIncompleteWeek', hasIncompleteWeek);
    },


    actions: {
      save: function save() {
        var _this2 = this;

        this.set('isSaving', true);
        var data = this._serialize();

        //check to ensure at least one day is selected
        if (!data.day0 && !data.day1 && !data.day2 && !data.day3 && !data.day4 && !data.day5 && !data.day6) {
          this.set('isError', true);
          this.set('errorText', this.get('i18n').t('components.day_switch_bank.no_day_selected_error'));
          return;
        } else {
          this.set('isError', false);
          this.set('errorText', "");
        }
        var parentController = this.get('matrixPricingTargetController');
        parentController.set('loading', true);
        var calendar = parentController.get('model').calendars.find(function (c) {
          return c.get('ruleDate').valueOf() === (0, _moment.default)(data.date).valueOf();
        });

        if (!calendar) {
          var ruleSet = parentController.get('ruleSet').get('children');

          calendar = this.get('store').createRecord('ruleCalendar', {
            ruleDate: data.date,
            children: ruleSet,
            isDirty: false
          });

          parentController.get('model').calendars.addObject(calendar);
        }

        var originalCalendar = void 0;
        if (calendar) {
          originalCalendar = calendar.copy(true);
        }

        data.pricePoints.forEach(function (pricePoint) {
          var allocation = {
            value: pricePoint.get('value') ? parseInt(pricePoint.get('value')) : 0,
            leadTime: pricePoint.get('lead') || pricePoint.get('lead') === 0 ? parseInt(pricePoint.get('lead')) : void 0
          };
          var rules = parentController._composeRule(data.date, pricePoint.get('id'), allocation);

          parentController._updateCalendar(rules, data.date);
        });

        var rules = void 0;
        if (calendar) {
          rules = calendar.get('children');
        } else {
          rules = parentController.get('ruleSet').get('children');
        }

        var complete = function complete(success) {
          if (calendar) {
            parentController._updateCalendar(originalCalendar.get('children'), data.date);
          }
          parentController.get('model').calendars.map(function (c) {
            c.set('isDirty', false);
          });
          _this2.set('isSaving', false);
          if (success) {
            _this2.send('showModal', 'customer/performance/-matrix-pricing-copy-success-modal', {
              isCopy: true,
              pricing: data,
              hasIncompleteWeek: _this2.get('hasIncompleteWeek'),
              pricePoints: _this2.get('model.pricePoints'),
              hasPreviousPricingSave: _this2.get('hasPreviousPricingSave')
            });
          } else {
            _this2.send('cancelPricing');
          }
        };

        this.set('previousPricing', data);
        if (this.get('hasPreviousPricingSave')) {
          this.set('hasPreviousPricingSave', false);
        } else {
          this.set('hasPreviousPricingSave', true);
        }
        this._hasIncompleteWeekSelected(data);

        // filter out freeze rules
        rules = rules.rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE').rejectBy('externalReference', 'CEILING_RULE').rejectBy('externalReference', 'EVENT_TIME');

        this.send('requestCopy', data, rules, complete);
      },
      cancelPricing: function cancelPricing() {
        this.set('previousPricing', null);
        this.set('hasPreviousPricingSave', false);
        this.set('isSaving', false);
        this.send('removeModal');
      }
    }
  });
});