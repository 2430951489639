define('ux-pcc/components/inputs/model-tree-select', ['exports', 'ux-components/components/inputs/model-tree-select'], function (exports, _modelTreeSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _modelTreeSelect.default;
    }
  });
});