define('ux-pcc/mixins/queue-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    enableQueuedLoading: false,

    disableQueuedLoad: Ember.computed.not('enableQueuedLoading'),

    queuedJob: 1,

    resetQueue: function resetQueue() {
      if (this.get('enableQueuedLoading')) {
        this.set('queuedJob', 1);
      } else {
        // notify property change not working, so just toggle the job number
        this.toggleProperty('queuedJob');
      }
    },


    actions: {
      incrementQueue: function incrementQueue() {
        this.set('queuedJob', this.get('queuedJob') + 1);
      }
    }
  });
});