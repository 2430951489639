define('ux-pcc/components/availability/property-availability-rate', ['exports', 'ux-components/components/availability/property-availability-rate'], function (exports, _propertyAvailabilityRate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _propertyAvailabilityRate.default;
    }
  });
});