define('ux-pcc/components/iframe-wrapper', ['exports', 'ux-components/components/iframe-wrapper'], function (exports, _iframeWrapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _iframeWrapper.default;
    }
  });
});