define("ux-pcc/helpers/card-data-window-classes/value", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var CardDataWindowValue = function CardDataWindowValue(label, value, columnClasses, valueClasses) {
    _classCallCheck(this, CardDataWindowValue);

    this.label = label;
    this.value = value;
    this.columnClasses = columnClasses;
    this.valueClasses = valueClasses;
  };

  exports.default = CardDataWindowValue;
});