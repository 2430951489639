define('ux-pcc/components/dashboard-widget/channel-percent-arrivals', ['exports', 'moment', 'accounting'], function (exports, _moment, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    classNames: ['widget-body', 'no-padding'],
    loading: true,
    rollupStatus: 'DETAIL',

    dataFields: null,

    externalMobileIndex: null,

    mobileIndex: Ember.computed('beginDate', function () {
      return 0;
    }),

    mobileIndexObserver: Ember.observer('mobileIndex', function () {
      this.set('externalMobileIndex', this.get('mobileIndex'));
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchData();
    },


    _fetchDataObserver: Ember.observer('customerID', 'beginDate', 'endDate', function () {
      Ember.run.once(this, '_fetchData');
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customer.id');
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('beginDate'), tz).startOf('day');
      var endDate = _moment.default.tz(this.get('endDate'), tz).endOf('day');

      if (endDate.isBefore(selectedDate)) {
        return;
      }

      this.set('loading', true);

      var requests = [this.ajax.request('/rest/v1/analyticEntryAggregation/process', {
        data: {
          customerid: customerID,
          begindate: selectedDate.format(),
          enddate: endDate.format(),
          aggregationType: 'BOOKING_WINDOW_BY_CHANNEL',
          rollupStatus: this.get('rollupStatus')
        }
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;
        return collections;
      })];

      Ember.RSVP.all(requests, 'Channel Percent Arrivals').then(function (_ref2) {
        var _ref3 = _slicedToArray(_ref2, 1),
            _ref3$ = _ref3[0],
            data = _ref3$ === undefined ? [] : _ref3$;

        // if no data than return
        if (data.length === 0) {
          _this.set('data', null);
          _this.set('loading', false);
          return;
        }

        var createBaseData = function createBaseData(dow, codes) {
          var baseData = {
            dow: dow,
            dateClass: ''
          };

          codes.forEach(function (code) {
            baseData[code.key] = '0.0';
          });

          return baseData;
        };

        var dataFields = _this.get('dataFields') || [];

        var dataSet = [createBaseData(_this.i18n.t('customer.performance.bookings.table.day_of_arrival'), dataFields), createBaseData(_this.i18n.t('customer.performance.bookings.table.one_day'), dataFields), createBaseData(_this.i18n.t('customer.performance.bookings.table.four_days'), dataFields), createBaseData(_this.i18n.t('customer.performance.bookings.table.eight_days'), dataFields), createBaseData(_this.i18n.t('customer.performance.bookings.table.fourteen_days'), dataFields), createBaseData(_this.i18n.t('customer.performance.bookings.table.stayover'), dataFields)];

        var dowIndexes = ['0', '1-3', '4-7', '8-14', '15+', 'Stayover'];
        var valuesExist = false;

        for (var x = 0; x < data.length; x++) {
          var dowData = data[x];

          for (var key in dowData.dataValues) {
            valuesExist = true;
            var dowIndex = dowIndexes.indexOf(key);
            if (dowIndex >= 0 && dataSet[dowIndex]) {
              dataSet[dowIndex][dowData.groupByValue] = _accounting.default.formatNumber(dowData.dataValues[key], 1);
            }
          }
        }

        if (valuesExist) {
          _this.set('chartData', dataSet);
        } else {
          _this.set('chartData', null);
        }
      }).finally(function () {
        return _this.set('loading', false);
      });
    },


    data: Ember.computed('chartData', 'mobileIndex', 'deviceType.mobile', function () {
      if (!this.get('chartData')) {
        return;
      }

      if (this.get('deviceType.mobile')) {
        return [this.get('chartData')[this.get('mobileIndex')]];
      } else {
        return this.get('chartData');
      }
    }),

    mobileTitle: Ember.computed('mobileIndex', function () {
      if (!this.get('chartData')) {
        return;
      }

      return this.get('chartData')[this.get('mobileIndex')].dow;
    }),

    actions: {
      changePage: function changePage(amount) {
        var mobileIndex = this.get('mobileIndex') + amount;
        var maxIndex = this.get('chartData.length') - 1;

        if (mobileIndex > maxIndex) {
          mobileIndex = 0;
        } else if (mobileIndex < 0) {
          mobileIndex = maxIndex;
        }

        this.set('mobileIndex', mobileIndex);
      }
    }

  });
});