define('ux-pcc/components/view-date-selector', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['view-date-selector', 'text-center'],

    inputDate: null,
    internalDate: null,

    waitMode: false,
    busy: false,
    unitOfTime: 'day',
    amount: 1,

    overrideFormat: null,

    onChange: function onChange() {},

    _localeObserver: Ember.observer('i18n.locale', function () {
      Ember.run.debounce(this, '_resetCalendarDisplay', 500);
    }),

    locale: Ember.computed('i18n.locale', function () {
      return _moment.default.locale();
    }),

    fullDisplayFormat: Ember.computed('unitOfTime', function () {
      if (this.overrideFormat) {
        return this.overrideFormat;
      }

      switch (this.unitOfTime) {
        case 'year':
          return 'YYYY';
        case 'month':
          return 'MMM-YYYY';
        default:
          return 'DD-MMM-YYYY';
      }
    }),

    shortDisplayFormat: Ember.computed('unitOfTime', function () {
      switch (this.unitOfTime) {
        case 'year':
          return 'YYYY';
        case 'month':
          return 'MMMM';
        case 'week':
          return 'dddd';
        default:
          return 'dddd';
      }
    }),

    viewMode: Ember.computed('unitOfTime', function () {
      switch (this.unitOfTime) {
        case 'year':
          return 'years';
        case 'month':
          return 'months';
        case 'day':
          return 'days';
        default:
          return this.unitOfTime;
      }
    }),

    useCurrent: Ember.computed('unitOfTime', function () {
      switch (this.unitOfTime) {
        case 'years':
          return 'year';
        case 'months':
          return 'month';
        case 'days':
          return 'day';
        default:
          return this.unitOfTime;
      }
    }),

    isToday: Ember.computed('internalDate', 'unitOfTime', function () {
      var today = (0, _moment.default)().startOf('day');
      var internalDate = (0, _moment.default)(this.internalDate).startOf('day');
      return today.isSame(internalDate, this.unitOfTime);
    }),

    previousDate: Ember.computed('internalDate', function () {
      return (0, _moment.default)(this.internalDate).subtract(this.amount, this.unitOfTime);
    }),

    currentDate: Ember.computed('internalDate', function () {
      return (0, _moment.default)(this.internalDate);
    }),

    nextDate: Ember.computed('internalDate', function () {
      return (0, _moment.default)(this.internalDate).add(this.amount, this.unitOfTime);
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('internalDate', (0, _moment.default)(this.inputDate));

      if (this.waitMode) {
        // On initial render, if we would normally disable on load,
        // simulate a load
        this._setToLoading();
        this.addObserver('busy', this, function () {
          return _this.set('loading', _this.busy);
        });
      }
    },
    _setToLoading: function _setToLoading() {
      if (this.waitMode) {
        this.set('loading', true);
      }
    },
    _setDisplayDate: function _setDisplayDate(newDate) {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        // bs-datepicker returns a native js date
        var date = (0, _moment.default)(newDate);
        _this2.onChange(date);
        _this2._setToLoading();
      }, 500);
    },
    _resetCalendarDisplay: function _resetCalendarDisplay() {
      var click = function click(el) {
        var event = document.createEvent('HTMLEvents');
        event.initEvent('click', true, false);
        el.dispatchEvent(event);
      };

      // Hack to handle when the datepicker year is different from the current year (also for locale changes)
      if (this.element) {
        var element = this.element.querySelector('.input-group.date .input-group-addon');
        if (element) {
          // Click the icon to open and close it in order to reset the display date
          click(element);
          click(element);
        }
      }
    },


    actions: {
      left: function left() {
        var previousDate = this.get('previousDate').clone();
        this.set('internalDate', previousDate);
        // If the years are different, reset calendar
        if (!this.get('currentDate').isSame(previousDate, 'year')) {
          Ember.run.debounce(this, '_resetCalendarDisplay', 500);
        }
      },
      right: function right() {
        var nextDate = this.get('nextDate').clone();
        this.set('internalDate', nextDate);
        // If the years are different, reset calendar
        if (!this.get('currentDate').isSame(nextDate, 'year')) {
          Ember.run.debounce(this, '_resetCalendarDisplay', 500);
        }
      },
      dateChanged: function dateChanged(newDate) {
        this._setDisplayDate(newDate);
      }
    }
  });
});