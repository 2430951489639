define('ux-pcc/components/chat-panel/message/body', ['exports', 'ux-components/components/chat-panel/message/body'], function (exports, _body) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _body.default;
    }
  });
});