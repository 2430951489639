define('ux-pcc/models/batch-request-parameter', ['exports', 'ux-data/models/batch-request-parameter'], function (exports, _batchRequestParameter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _batchRequestParameter.default;
    }
  });
});