define('ux-pcc/components/alerts/alert-feed', ['exports', 'ux-components/components/alerts/alert-feed'], function (exports, _alertFeed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _alertFeed.default;
    }
  });
});