define('ux-pcc/components/count-bubble', ['exports', 'ux-components/components/count-bubble'], function (exports, _countBubble) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _countBubble.default;
    }
  });
});