define('ux-pcc/components/competitor-search-list', ['exports', 'ux-pcc/components/search-list'], function (exports, _searchList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _searchList.default.extend({
    layoutName: 'components/search-list',

    store: Ember.inject.service(),

    _fetchObjects: Ember.observer('_page', function () {
      var _this = this;

      var customer = this.customer;

      this.store.query('customer', Ember.assign({}, this.queryOptions, {
        q: this.searchText + '*',
        limit: this.resultsPerPage,
        pagingtoken: this._page,
        folderid: customer.get('id'),
        customertype: 'COMPETITOR'
      })).then(function (results) {
        _this._handleFetch(results);
      });
    })
  });
});