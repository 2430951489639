define('ux-pcc/controllers/customer/performance/dashboard', ['exports', 'moment', 'ux-components/utils/to-boolean'], function (exports, _moment, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    ajax: Ember.inject.service(),
    permission: Ember.inject.service(),
    menuService: Ember.inject.service('menu'),

    queryParams: ['startDate'],

    startDate: null,
    selectedDate: null,
    selectedProduct: 'ALL',

    parentLoading: false,

    isDesktop: Ember.computed.bool('deviceType.desktop'),
    isMobile: Ember.computed.bool('deviceType.mobile'),

    rollupStatus: Ember.computed('customer.customerType', function () {
      return 'CUSTOMER' === this.get('customer.customerType') ? 'DETAIL' : 'ROLLUP';
    }),

    inventoryRollupSetting: Ember.computed('customer.settings', function () {
      var setting = Ember.get(this, 'customer.settings').findBy('settingCode', 'PCC.ENABLE_PROPERTY_DASHBOARD_INVENTORY_ROLLUP_SELECTOR');
      return setting ? (0, _toBoolean.default)(Ember.get(setting, 'settingValue')) : false;
    }),

    showInventorySegments: Ember.computed('model.rollupProducts.[]', 'inventoryRollupSetting', function () {
      return Ember.get(this, 'inventoryRollupSetting') && (Ember.get(this, 'model.rollupProducts') || []).length > 0;
    }),

    rollupProductIds: Ember.computed('model.rollupProducts.[]', 'selectedProduct', function () {
      if (this.selectedProduct === 'ALL') {
        return this.model.rollupProducts.mapBy('id');
      }

      return this.model.rollupProducts.filterBy('externalReference', this.selectedProduct).mapBy('id');
    }),

    currencyCode: Ember.computed('customer.settings.[]', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'DEFAULT_CURRENCY');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'USD';
      }
    }),

    forecastModelsSetting: Ember.computed('customer.settings.[]', function () {
      return this.get('customer.settings').findBy('settingCode', 'forecasting.occupancy.forecasting_models');
    }),

    _barAllocationMenuExists: Ember.computed('menuService.menus.length', function () {
      var barAllocationMenuExists = false;
      var menus = this.get('menuService.menus') || [];

      // Grab the Revenue Optimization menu item
      var revenueMenu = menus.findBy('actionUrl', 'customer.performance.dashboard');
      if (revenueMenu && revenueMenu.get('children')) {
        barAllocationMenuExists = revenueMenu.get('children').findBy('actionUrl', 'customer.performance.matrix-pricing');
      }

      return barAllocationMenuExists;
    }),

    showBarPlanAllocation: Ember.computed('customerController.isSisterProperty', '_barAllocationMenuExists', function () {
      return !this.get('customerController.isSisterProperty') && this.get('_barAllocationMenuExists');
    }),

    showAdrForecast: Ember.computed('customer.permissionType', function () {
      return this.get('permission').isPermitted(this.get('customer.permissionType') + 'forecast', 'view', 'adr');
    }),

    showOccForecast: Ember.computed('customer.permissionType', function () {
      return this.get('permission').isPermitted(this.get('customer.permissionType') + 'forecast', 'view', 'occ');
    }),

    showRevenueRecovery: Ember.computed('customer.permissionType', function () {
      return this.get('permission').isPermitted('revenue-recovery', 'view');
    }),

    dowFormat: Ember.computed('isMobile', 'isDesktop', function () {
      if (this.get('isMobile')) {
        return 'ddd';
      }
      return 'dddd';
    }),

    dateFormat: Ember.computed('isMobile', 'isDesktop', function () {
      if (this.get('isMobile')) {
        return 'MMM Do, YYYY';
      }
      return 'MMMM Do, YYYY';
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    }),

    occupancyForecastSubcontentType: Ember.computed('customer.settings.[]', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'forecasting.occupancy.forecasting_models');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'HOLT_WINTERS_MULTIPLICATIVE';
      }
    }),

    occupancySubcontentType: Ember.computed('customer.settings.[]', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'INVENTORY_CALCULATION_METHOD_PERFORMANCE_SUMMARY');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'YIELDABLE';
      }
    }),

    competitorLoadDate: Ember.computed('customer.udfValues', function () {
      if (this.get('customer.udfValues') && this.get('customer.udfValues').hasOwnProperty('TCLK_LOAD_DATE')) {
        var tz = this.tz.get('customer');
        return (0, _moment.default)(this.get('customer.udfValues')['TCLK_LOAD_DATE']).tz(tz).format('MMMM D, YYYY');
      } else {
        return null;
      }
    }),

    showWeeklyOverview: Ember.computed('selectedDate', function () {
      // weekly component only shows data for within 1 week from today
      var tz = this.tz.get('customer');
      var today = _moment.default.tz(tz).startOf('day');
      var daysFromToday = _moment.default.tz(this.get('selectedDate'), tz).startOf('day').diff(_moment.default.tz(today, tz).startOf('day'), 'days');
      return Math.abs(daysFromToday) <= 7;
    })

  });
});