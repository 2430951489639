define('ux-pcc/models/agreement-price', ['exports', 'ux-data/models/agreement-price'], function (exports, _agreementPrice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _agreementPrice.default;
    }
  });
});