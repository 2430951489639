define('ux-pcc/components/rules/conditions/comp-status-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values'], function (exports, _ruleConditionValues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    type: 'list',

    filterConditions: Ember.computed('i18n.locale', function () {
      return [{ value: 'EQ', label: '' }];
    }),

    filterValues: Ember.computed('i18n.locale', function () {
      return [{ value: 'OPEN', label: this.i18n.t('pcc-components.rules.rule-children.available') }, { value: 'CLOSED', label: this.i18n.t('pcc-components.rules.rule-children.unavailable') }];
    })
  });
});