define('ux-pcc/components/tables/table-basic', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // External attributes

    // Example:
    // {
    //   groups: [{
    //     label: "DBAR",
    //     rows: [{
    //       label: "Premium",
    //       values: {
    //         adr: formatCurrency(120, "USD"),
    //         bookings: 40 + "%",
    //         revpar: formatCurrency(23, "USD")
    //       }
    //    },{
    //       label: "Extended",
    //       values: {
    //         adr: formatCurrency(125, "USD"),
    //         bookings: 30 + "%",
    //         revpar: formatCurrency(24, "USD")
    //       }
    //   }...]
    //   },...],
    //   columns: [{key: "", label: ""},...]
    // }
    tableData: null,

    actionable: false,

    noDataMessage: Ember.computed('i18n.locale', function () {
      return this.i18n.t('pcc-components.table-basic.no_data');
    }),

    hideHeader: false,

    fixedColumns: 1,

    sortableHeader: false,

    sortableModel: null,

    sort: [],

    useFixedColumn: true,

    useFixedHeader: false,

    hasVariableHeights: true,

    calculateFixedColumnSize: true,

    toggleGroup: false,

    infiniteScroll: false,

    margin: 100,

    table: Ember.inject.service(),

    highlightedRowIndex: -1,
    highlightedRowClass: "",

    expandableRows: false,
    showChildToggle: false,

    sendHeaderClickIfPast: false,

    showExpandableWithoutChildren: true,
    childRowClass: 'view-screen__table-content--row-child',

    // Internal properties
    classNames: ['table-basic'],

    _groups: Ember.computed('tableData', 'highlightedRowIndex', function () {
      return this.get('tableData.groups') || [];
    }),

    _columns: Ember.computed('tableData', function () {
      return this.get('tableData.columns') || [];
    }),

    ready: function ready() {},
    cellClicked: function cellClicked() {},
    headerClicked: function headerClicked() {},

    // Handlers
    tableDataObserver: Ember.observer('tableData', function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        _this._destroyFixedColumn();
        _this._buildFixedColumn();

        if (_this.get('useFixedHeader')) {
          _this.repaintFixedHeader();
          _this.repaintFixedScrollbar();
        }

        _this.ready(_this);
      });
    }),

    handleTableInvalidationEvent: function handleTableInvalidationEvent() {
      Ember.run.scheduleOnce('afterRender', this, this.repaintFixedColumn);
    },
    handleRecenterEvent: function handleRecenterEvent() {
      this.ready(this);
    },
    repaintFixedColumn: function repaintFixedColumn() {
      this._destroyFixedColumn();
      this._buildFixedColumn();
      if (this.get('useFixedHeader')) {
        this.repaintFixedHeader();
        this.repaintFixedScrollbar();
      }
    },
    repaintFixedHeader: function repaintFixedHeader() {
      this._destroyFixedHeaders();
      this._buildFixedHeaders();
    },
    repaintFixedScrollbar: function repaintFixedScrollbar() {
      this._destroyFixedScrollbar();
      this._buildFixedScrollbar();
    },


    // Life cycle

    didInsertElement: function didInsertElement() {
      this._super();
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },
    afterRenderEvent: function afterRenderEvent() {
      this._buildFixedColumn();

      // We only need to trigger if the controller is evented
      var target = this.get('table');
      if (target && typeof target.on === "function") {
        target.on('tableInvalidate', this, this.handleTableInvalidationEvent);
        target.on('recenterTable', this, this.handleRecenterEvent);
      }

      if (this.get('useFixedHeader')) {
        this._buildFixedHeaders();
        this._buildFixedScrollbar();
      }

      this.ready(this);
    },
    willDestroyElement: function willDestroyElement() {

      var target = this.get('table');
      if (target && typeof target.off === "function") {
        target.off('tableInvalidate', this, this.handleTableInvalidationEvent);
        target.off('recenterTable', this, this.handleRecenterEvent);
      }

      this._super();
      this._destroyFixedColumn();

      if (this.get('useFixedHeader')) {
        this._destroyFixedHeaders();
        this._destroyFixedScrollbar();
      }
    },


    // Private methods

    _buildFixedColumn: function _buildFixedColumn() {
      if (this.get('useFixedColumn')) {
        var hasVariableHeights = this.get('hasVariableHeights');
        var calculateFixedColumnSize = this.get('calculateFixedColumnSize');
        var $table = Ember.$(this.element).find('table');
        var $fixedColumn = $table.clone();
        var numberOfColumns = this.get('fixedColumns');

        $fixedColumn.attr('data-owner-id', this.elementId);
        var fixedColumnSelector = 'th:nth-child(n+' + (numberOfColumns + 1) + '):not(.table-basic__row-label),td:nth-child(n+' + (numberOfColumns + 1) + '):not(.table-basic__row-label)';
        $fixedColumn.find(fixedColumnSelector).remove();
        $fixedColumn.insertBefore(Ember.$(this.element)).addClass('fixed-column');

        // Don't dynamically resize fixed if disabled
        if (calculateFixedColumnSize) {

          var resizeFixed = function resizeFixed() /*toggleGroup*/{
            var _loop = function _loop(i) {
              // There could be multiple thead elements, as in the case of the performance matrix, so we need to find the max
              // and set the width to that
              var newWidth = 0;
              /* jshint ignore:start */
              $table.find('th:nth-child(' + i + ')').each(function (_, element) {
                if (Ember.$(element).width() > newWidth) {
                  newWidth = Ember.$(element).width();
                }
              });
              /* jshint ignore:end */
              $fixedColumn.find('th:nth-child(' + i + ')').width(newWidth);
            };

            for (var i = 1; i <= numberOfColumns; i++) {
              _loop(i);
            }

            if (hasVariableHeights) {
              $fixedColumn.find('tr').each(function (i, elem) {
                var $ref = $table.find('tr:eq(' + i + ')');
                // Match up fixed column height to matching reference column
                Ember.$(elem).children().each(function (j, cell) {
                  var height = Ember.$($ref.children()[j]).outerHeight();
                  Ember.$(cell).outerHeight(height);
                });
              });
            } else {
              var $ref = $table.find('tr:eq(1)');
              $ref = $ref.children().first();
              var height = $ref.outerHeight();

              $fixedColumn.find('tr td').outerHeight(height);
            }
          };

          Ember.$(window).on("resize.fixed-column", resizeFixed);
          resizeFixed(this.get('toggleGroup'));
        }

        var updateFixedStyles = function updateFixedStyles() {
          $fixedColumn.removeClass().addClass($table.attr("class")).addClass('fixed-column');
        };

        Ember.$(window).on("table.style-changed", updateFixedStyles);
      }
    },
    _buildFixedHeaders: function _buildFixedHeaders() {

      var elementId = this.elementId;

      // let calculateFixedColumnSize = this.get('calculateFixedColumnSize');

      if (elementId) {
        Ember.$.fn.fixMe = function (isFixedColumn) {
          var _this2 = this;

          return this.each(function () {
            var $this = Ember.$(_this2),
                $t_fixed = void 0;

            function init(isFixedColumn) {
              $t_fixed = $this.clone();
              $t_fixed.attr('fh-data-owner-id', elementId);

              // Only keep the first thead
              $t_fixed.find("thead:not(:first-of-type)").remove();

              if (isFixedColumn) {
                $t_fixed.removeAttr('data-owner-id');
                $t_fixed.removeClass("fixed-column").addClass("fixed-column-header");
                $t_fixed.find("tbody").remove().end().addClass("fixed-header").insertBefore($this);
              } else {
                $t_fixed.find("tbody").remove().end().addClass("fixed-header").insertBefore($this.parent());
                $t_fixed.wrap('<div class="table-horizontal-fixed-header-wrapper" fh-data-owner-id="' + elementId + '"/>');
                // Tie the scrolling of the div of the table to the scroll of the header
                $this.parent().scroll(scrollFixedLeft);
              }
              resizeFixed();
            }

            function resizeFixed() {
              $t_fixed.find("th").each(function (index, th) {
                Ember.$(th).css("width", $this.find("th").eq(index).outerWidth() + "px");
                Ember.$(th).css("height", $this.find("th").eq(index).outerHeight() + "px");
              });

              // set the width on the table and the container div to handle the scrolling
              if (!$t_fixed.hasClass("fixed-column-header")) {
                $t_fixed.css("width", $this.css("width"));
                $t_fixed.parent().css("width", $this.parent().css("width"));
              }
            }

            function scrollFixed() {
              var offset = Ember.$(this).scrollTop(),
                  tableOffsetTop = $this.offset().top;

              if (0 < tableOffsetTop) {
                $t_fixed.hide();
              } else if (offset !== 0 && tableOffsetTop <= 0 && $t_fixed.is(":hidden")) {
                $t_fixed.show();
                $t_fixed.parent().prop("scrollLeft", $this.parent().scrollLeft());
              }
            }

            function scrollFixedLeft() {
              $t_fixed.parent().prop("scrollLeft", this.scrollLeft);
            }

            Ember.$(window).resize(resizeFixed);
            Ember.$(window).on("resize.fixed-column", resizeFixed);
            Ember.$("#main-content").scroll(scrollFixed);
            init(isFixedColumn);
          });
        };

        Ember.$(this.element).find('.table').fixMe();

        if (this.get('useFixedColumn')) {
          Ember.$('.fixed-column').fixMe(true);
        }
      }
    },
    _buildFixedScrollbar: function _buildFixedScrollbar() {

      var elementId = this.elementId;
      var margin = this.get('margin');
      var waitForReload = true;

      if (elementId) {

        Ember.$.fn.fixMe = function (infiniteScroll) {
          return this.each(function () {
            var $this = Ember.$(this),
                $t_fixed = void 0;

            function init() {

              var scrollDiv = '<div data-fs-owner-id="' + elementId + '" class="fixed-scroll"><div class="fixed-scroll__content"/></div>';

              $this.parent().after(scrollDiv);
              $t_fixed = Ember.$(".fixed-scroll");

              $t_fixed.scroll(function () {
                if (this.scrollLeft > 1 && $this.parent().prop("scrollLeft") !== this.scrollLeft) {
                  // Handle reloads on infinite scroll
                  if (!infiniteScroll || !waitForReload) {
                    $this.parent().prop("scrollLeft", this.scrollLeft);
                    if (infiniteScroll && this.scrollLeft <= margin) {
                      waitForReload = true;
                    }
                  } else {
                    $t_fixed.prop("scrollLeft", $this.parent().scrollLeft());
                    if (infiniteScroll && $this.parent().scrollLeft() > margin) {
                      waitForReload = false;
                    }
                  }
                } else if (this.scrollLeft === 0 && $this.parent().prop("scrollLeft") !== this.scrollLeft) {
                  $this.parent().prop("scrollLeft", this.scrollLeft);
                }
              });

              resizeFixed();
            }

            function resizeFixed() {
              // set the width on the scroll div and the content div to handle the scrolling
              $t_fixed.find(".fixed-scroll__content").css("width", $this.parent()[0].scrollWidth + 'px'); //$this.css("width"));
              $t_fixed.css("width", $this.parent().css("width"));
              scrollFixed();
            }

            function scrollFixed() {
              var windowHeight = Ember.$("#main-content").outerHeight(),
                  tableOffset = $this.offset().top + $this.outerHeight() + 10; // Height of table + height of scrollbar + offset (which should be negative)

              // If there is an existing scroll and it is on screen, then hide the faked fixed one
              if (tableOffset < windowHeight || $this.parent().get(0).scrollWidth <= $this.parent().innerWidth()) {
                $t_fixed.hide();
              } else {
                $t_fixed.show();
                $t_fixed.prop("scrollLeft", $this.parent().scrollLeft());
              }
            }

            Ember.$(window).resize(resizeFixed);
            Ember.$(window).on("resize.fixed-column", resizeFixed);
            Ember.$("#main-content").scroll(scrollFixed);
            init();
          });
        };

        Ember.$(this.element).find('.table').fixMe(this.get('infiniteScroll'));
      }
    },
    _destroyFixedColumn: function _destroyFixedColumn() {
      if (this.get('useFixedColumn')) {
        Ember.$(document).find('[data-owner-id="' + this.elementId + '"]').remove();
      }
    },
    _destroyFixedHeaders: function _destroyFixedHeaders() {
      if (this.get('useFixedHeader')) {
        Ember.$(document).find('[fh-data-owner-id="' + this.elementId + '"]').remove();
      }
    },
    _destroyFixedScrollbar: function _destroyFixedScrollbar() {
      Ember.$(document).find('[data-fs-owner-id="' + this.elementId + '"]').remove();
    },
    selectChildren: function selectChildren() {
      Ember.run.later(this, 'repaintFixedColumn', 50);
    },
    hideChildren: function hideChildren() {
      Ember.run.later(this, 'repaintFixedColumn', 50);
    },


    actions: {
      headerClicked: function headerClicked(column, group) {
        // Pass along a toggle event for all columns in the header
        this.send("toggle", group);

        if (!this.get('actionable')) {
          return;
        }
        var columnDate = _moment.default.tz(column.key, this.tz.get('customer'));
        if (columnDate) {
          var today = _moment.default.tz(this.tz.get('customer')).startOf('day');
          var isPast = columnDate.isBefore(today);
          if (!isPast || this.get('sendHeaderClickIfPast')) {
            this.headerClicked(column.key, group.id);
          }
        } else {
          this.headerClicked(column.key, group.id);
        }
      },
      cellClicked: function cellClicked(context, column, row, group) {
        if (!Ember.get(context.model, 'actionable')) {
          return;
        }

        this.cellClicked(context, column.key, row.id, group.id);
      },
      sort: function sort(key, ascending) {
        if (ascending) {
          this.set('sort', [key]);
        } else {
          this.set('sort', [key + ":desc"]);
        }
      },
      toggle: function toggle(group) {

        if (group && group.toggleable) {
          var nl = Ember.$(document).find('tbody[data-id=' + group.id + ']');
          nl.each(function (_, e) {
            e.hidden = !e.hidden;

            var rows = Ember.$(document).find('thead[data-id=' + group.id + '] tr');
            rows.each(function (_, r) {
              if (e.hidden) {
                Ember.$(r).addClass('section-collapsed');
              } else {
                Ember.$(r).removeClass('section-collapsed');
              }
            });

            // Change the toggle icon
            var toggles = Ember.$(document).find('thead[data-id=' + group.id + '] i');
            toggles.each(function (_, i) {
              if (e.hidden) {
                i.className = i.className.replace('fa-chevron-up', 'fa-chevron-down');
              } else {
                i.className = i.className.replace('fa-chevron-down', 'fa-chevron-up');
              }
            });
          });
          this.repaintFixedColumn();
          if (this.get('useFixedHeader')) {
            this.repaintFixedHeader();
            this.repaintFixedScrollbar();
          }
        }
      },
      rowMouseIn: function rowMouseIn(index) {
        if (this.get('rowMouseIn')) {
          this.get('rowMouseIn')(index);
        }
      },
      rowMouseOut: function rowMouseOut(index) {
        if (this.get('rowMouseOut')) {
          this.get('rowMouseOut')(index);
        }
      },
      tableMouseOut: function tableMouseOut() {
        if (this.get('tableMouseOut')) {
          this.get('tableMouseOut')();
        }
      },
      add: function add(val1, val2) {
        return (val1 || 0) + val2;
      }
    }

  });
});