define('ux-pcc/components/rules/conditions/rate-code-occ-contrib-condition', ['exports', 'ux-pcc/components/rules/rule-condition-values', 'ember-changeset-validations/validators'], function (exports, _ruleConditionValues, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ruleConditionValues.default.extend({

    store: Ember.inject.service(),

    type: 'percent',

    priceFilter: function priceFilter(item) {
      return item.get('sellYN');
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        filterValueRule: (0, _validators.validateLength)({
          min: 1,
          allowNone: false,
          message: this.i18n.t('rule._form_modal.no_rate_code').toString()
        })
      };
    }),

    filterValueRuleOptions: Ember.computed('customer.id', function () {
      var _this = this;

      return this.store.query('price', {
        customerID: this.get('customer.id')
      }).then(function () {
        var prices = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var rtnValues = [];
        prices.filter(_this.priceFilter).forEach(function (price) {
          var externalReference = price.get('externalReference');
          rtnValues.push({
            val: price.get('id'),
            label: externalReference && externalReference !== price.get('shortDescription') ? externalReference + ' - ' + price.shortDescription : price.shortDescription
          });
        });

        // Sort them by label
        rtnValues = rtnValues.sortBy('label');

        return rtnValues;
      });
    }),

    label: Ember.computed('i18n.locale', function () {
      return this.i18n.t('pcc-components.rules.conditions.pct_of_total_occupancy');
    }),

    actions: {
      getValue: function getValue(field, obj) {
        return obj ? Ember.get(obj, field) : null;
      }
    }
  });
});