define('ux-pcc/components/dashboard-widgets/revenue-recovery', ['exports', 'ux-components/components/dashboard-widgets/revenue-recovery'], function (exports, _revenueRecovery) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _revenueRecovery.default;
    }
  });
});