define('ux-pcc/routes/competitive-sets/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor('customer');

      return this.store.createRecord('customer', {
        folderYN: true,
        customerType: 'COMPETITORS',
        parent: customer,
        status: 'OK'
      });
    }
  });
});