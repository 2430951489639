define('ux-pcc/routes/rules-feed/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      startDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      },
      alertStatus: {
        refreshModel: true
      }
    },

    startDate: null,
    endDate: null,

    notifications: Ember.inject.service(),

    model: function model(params) {
      var customer = this.modelFor('customer');
      var tz = this.tz.customer;
      var parameters = {
        customerID: customer.id,
        status: 'OK',
        alertType: 'NOTIFY_PRICE_BASED_ON,NOTIFY_PRICE_RESTRICTION,NOTIFY_PRICE_CHANGE,NOTIFY_AUTO_ALLOCATION_CHANGE,NOTIFY_RULE_MODIFICATION'
      };

      var today = _moment.default.tz(tz).startOf('day');
      var initialStartDate = params.startDate || today;
      var startDate = _moment.default.tz(initialStartDate, tz).startOf('day');
      if (params.alertStatus !== 'completed' && startDate.isBefore(today, 'day')) {
        startDate = today.clone().startOf('day');
      }
      this.set('startDate', startDate.format());
      parameters.beginDate = startDate.format('YYYY-MM-DD');

      if (params.endDate) {
        var endDate = _moment.default.tz(params.endDate, tz);
        if (endDate.isBefore(startDate, 'day')) {
          endDate = _moment.default.tz(startDate, tz);
        }
        this.set('endDate', endDate.format());
        parameters.endDate = endDate.format('YYYY-MM-DD');
      } else {
        this.set('endDate', null);
        parameters.endDate = null;
      }

      return this.store.query('alert', parameters);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('startDate', this.startDate);
      controller.set('endDate', this.endDate);
    },


    actions: {
      updateAlerts: function updateAlerts(alert) {
        this.refresh();

        if (alert) {
          this.notifications.success(this.i18n.t('pcc-components.alerts.alert-feed.update_success') + ' \'' + alert.get('payload') + '\'.');
        }

        return true;
      }
    }
  });
});