define('ux-pcc/controllers/rules-simple/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['policyType'],

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    rule: null,

    saveLabel: 'rules-simple.form.create_rule'

  });
});