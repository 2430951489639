define('ux-pcc/controllers/customer/performance/-event-time-modal', ['exports', 'moment', 'ux-pcc/controllers/availability/-base-modal'], function (exports, _moment, _baseModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseModal.default.extend({

    dialog: Ember.inject.service(),

    matrixPricing: Ember.inject.controller('customer/performance/matrix-pricing'),
    model: null,
    valid: true,

    validate: function validate() {

      var occupancy = parseFloat(this.get('occupancy'));

      if (isNaN(occupancy)) {
        this.set('valid', false);

        return Ember.RSVP.reject('occupancy % not valid');
      }

      this.set('valid', true);

      return Ember.RSVP.resolve();
    },


    eventTimeRule: Ember.computed('model.eventTimes', function () {
      return this.get('model.eventTimes') ? this.get('model.eventTimes').get('firstObject') : null;
    }),

    _observeModel: Ember.observer('model.date', 'eventTimeRule', function () {

      // populate model data if event time rule is present
      if (this.get('eventTimeRule')) {
        this.set('pricePoint', this.get('eventTimeRule.amount'));
        // fragile as this assumes nested rule is constructed correctly
        var conditions = this.get('eventTimeRule.children.firstObject.children.firstObject.children') || [];
        if (conditions.length > 0) {
          var occupancy = conditions.findBy('filterField', 'OCC_PCT');
          var timeOfDay = conditions.findBy('filterField', 'EVENT_TIME');
          if (occupancy) {
            this.set('occupancy', Ember.get(occupancy, 'filterValue'));
            this.set('equality', Ember.get(occupancy, 'filterCondition'));
          }
          if (timeOfDay) {
            this.set('timeOfDay', Ember.get(timeOfDay, 'filterValue'));
            this.set('timeOfDayEquality', Ember.get(timeOfDay, 'filterCondition'));
          }
        }
      } else {
        // set defaults
        this.set('pricePoint', this.get('model.pricePoints.firstObject.amount'));
        this.set('equality', this.get('filterConditions')[0].value);
        this.set('occupancy', "");
        this.set('timeOfDayEquality', this.get('filterConditions')[0].value);
        this.set('timeOfDay', this.get('filterValues')[0].value);
      }
    }),
    isEdit: Ember.computed.bool('eventTimeRule'),
    pricePoint: "",
    equality: "",
    timeOfDay: "",
    timeOfDayEquality: "",
    occupancy: "",
    // possible enhancement: incorporate time picker but this is sufficient
    filterValues: [{ value: "00:00", label: "12:00 AM" }, { value: "00:30", label: "12:30 AM" }, { value: "01:00", label: "01:00 AM" }, { value: "01:30", label: "01:30 AM" }, { value: "02:00", label: "02:00 AM" }, { value: "02:30", label: "02:30 AM" }, { value: "03:00", label: "03:00 AM" }, { value: "03:30", label: "03:30 AM" }, { value: "04:00", label: "04:00 AM" }, { value: "04:30", label: "04:30 AM" }, { value: "05:00", label: "05:00 AM" }, { value: "05:30", label: "05:30 AM" }, { value: "06:00", label: "06:00 AM" }, { value: "06:30", label: "06:30 AM" }, { value: "07:00", label: "07:00 AM" }, { value: "07:30", label: "07:30 AM" }, { value: "08:00", label: "08:00 AM" }, { value: "08:30", label: "08:30 AM" }, { value: "09:00", label: "09:00 AM" }, { value: "09:30", label: "09:30 AM" }, { value: "10:00", label: "10:00 AM" }, { value: "10:30", label: "10:30 AM" }, { value: "11:00", label: "11:00 AM" }, { value: "11:30", label: "11:30 AM" }, { value: "12:00", label: "12:00 PM" }, { value: "12:30", label: "12:30 PM" }, { value: "13:00", label: "01:00 PM" }, { value: "13:30", label: "01:30 PM" }, { value: "14:00", label: "02:00 PM" }, { value: "14:30", label: "02:30 PM" }, { value: "15:00", label: "03:00 PM" }, { value: "15:30", label: "03:30 PM" }, { value: "16:00", label: "04:00 PM" }, { value: "16:30", label: "04:30 PM" }, { value: "17:00", label: "05:00 PM" }, { value: "17:30", label: "05:30 PM" }, { value: "18:00", label: "06:00 PM" }, { value: "18:30", label: "06:30 PM" }, { value: "19:00", label: "07:00 PM" }, { value: "19:30", label: "07:30 PM" }, { value: "20:00", label: "08:00 PM" }, { value: "20:30", label: "08:30 PM" }, { value: "21:00", label: "09:00 PM" }, { value: "21:30", label: "09:30 PM" }, { value: "22:00", label: "10:00 PM" }, { value: "22:30", label: "10:30 PM" }, { value: "23:00", label: "11:00 PM" }, { value: "23:30", label: "11:30 PM" }],

    filterConditions: [{ value: "GT", label: " > " }, { value: "LT", label: " < " }, { value: "GTEQ", label: " >= " }, { value: "LTEQ", label: " <= " }],

    modalTitle: Ember.computed('isEdit', function () {
      if (this.get('isEdit')) {
        return "customer.performance.matrix-pricing.time_of_day.edit";
      } else {
        return "customer.performance.matrix-pricing.time_of_day.add";
      }
    }),

    _serialize: function _serialize() {
      return Ember.$.extend({
        date: this.get('model.date').format() || void 0,
        pricePoints: this.get('model.pricePoints')
      }, this._super());
    },


    actions: {
      close: function close() {
        this.send('removeModal');
      },
      validate: function validate() {
        this.validate();
      },
      save: function save(isSave) {
        var _this = this;

        this.validate().then(function () {
          var data = _this._serialize();

          var parentController = _this.get('matrixPricing');
          var tz = _this.tz.get("customer");
          var date = (0, _moment.default)(data.date).tz(tz);

          var calendar = parentController.get('model').calendars.find(function (c) {
            return (0, _moment.default)(Ember.get(c, 'ruleDate')).tz(tz).valueOf() === date.valueOf();
          });

          if (!calendar) {
            var ruleSet = parentController.get('ruleSet').get('children');

            calendar = {
              ruleDate: data.date,
              children: ruleSet,
              isDirty: false
            };

            parentController.get('model').calendars.addObject(calendar);
          }

          var pricePoint = _this.get('pricePoint');
          if (!pricePoint) {
            pricePoint = _this.get('model.pricePoints.firstObject.amount');
          }
          var rules = parentController._composeTimeRule(data.date, pricePoint, _this.get('equality'), _this.get('occupancy'), _this.get('timeOfDayEquality'), _this.get('timeOfDay'), isSave);

          parentController._updateCalendar(rules, data.date);

          if (calendar) {
            rules = Ember.get(calendar, 'children');
          } else {
            rules = parentController.get('ruleSet').get('children');
          }

          var complete = function complete(success) {
            parentController.get('model').calendars.forEach(function (c) {
              Ember.set(c, 'isDirty', false);
            });
            var tz = _this.tz.get("customer");
            var date = _moment.default.tz(data.date, tz).format("l");
            if (success) {

              _this.dialog.showSuccess({
                titleText: _this.i18n.t('customer.performance.matrix-pricing.copy.success'),
                messageText: isSave ? _this.i18n.t('customer.performance.matrix-pricing.time_of_day.success_text', { date: date }) : _this.get('i18n').t('customer.performance.matrix-pricing.time_of_day.delete_text', { date: date }),
                confirmText: _this.i18n.t('customer.performance.matrix-pricing.copy.done'),
                onConfirm: function onConfirm() {
                  _this.send('removeModal');
                }
              });
            } else {
              _this.send('removeModal');
            }
          };

          _this.send('requestTimeOfDay', data, rules, complete);
        }).catch(function () /*e*/{}).finally(function () {});
      }
    }
  });
});