define('ux-pcc/routes/yield-manager/dashboard', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      selectedDate: {
        refreshModel: false
      }
    },

    model: function model(params) {
      var tz = this.tz.get('customer');
      this.set('selectedDate', (params.selectedDate ? _moment.default.tz(params.selectedDate, tz) : _moment.default.tz(tz)).startOf('day'));
      return Ember.RSVP.hash({
        views: this.store.findAll('dashboard-view')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.views.sortBy('orderBy'));
      controller.set('selectedDate', this.get('selectedDate').format('YYYY-MM-DD'));
    },


    actions: {
      refresh: function refresh() {
        var controller = this.controllerFor('yield-manager.dashboard');
        controller.set('model.views', this.store.findAll('dashboard-view').then(function (results) {
          return results.sortBy('orderBy');
        }));
        this.send('refreshChildRoute');
      }
    }
  });
});