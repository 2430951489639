define('ux-pcc/components/panels/request-audits-panel/price-adjustment-request', ['exports', 'ux-components/components/panels/request-audits-panel/price-adjustment-request'], function (exports, _priceAdjustmentRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _priceAdjustmentRequest.default;
    }
  });
});