define('ux-pcc/components/customers/property-details', ['exports', 'ux-components/components/customers/property-details'], function (exports, _propertyDetails) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _propertyDetails.default;
    }
  });
});