define('ux-pcc/authenticators/callback', ['exports', 'ux-data/authenticators/callback'], function (exports, _callback) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _callback.default;
    }
  });
});