define('ux-pcc/components/competitive-set/competitor-pricing', ['exports', 'moment', 'lodash', 'ux-components/utils/format-currency'], function (exports, _moment, _lodash, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),

    customer: null,
    competitor: null,
    data: null,
    isDesktop: Ember.computed.bool('deviceType.desktop'),

    defaultCurrencyCode: 'USD',

    init: function init() {
      this._super();
      var tz = this.tz.get("customer");
      this.set('competitor.collectedDate', (0, _moment.default)().tz(tz).startOf('day').clone());
      this.set('competitor.arrivalDate', (0, _moment.default)().tz(tz).startOf('day').clone());

      this._newEntry();
    },


    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    currencySymbol: Ember.computed('currencyCode', function () {
      return (0, _formatCurrency.default)(0, this.get('currencyCode'), { hash: { symbolOnly: true } });
    }),

    _newEntry: function _newEntry() {
      this.set('data', Ember.Object.create({
        customerID: this.get('customer.id'),
        analyticType: 'AMOUNT',
        contentType: 'UNPUBLISHED_RATE',
        channelCode: 'ALL',
        counter: 1,
        dataValue: '',
        dataType: 'VALUE',
        description: this.get('competitor.name'),
        rollupStatus: 'DETAIL',
        groupBy: 'ID',
        groupByValue: this.get('competitor.id'),
        currencyCode: this.get('currencyCode'),
        subContentType: 'COMPETITOR'
      }));
      this.set('competitor.los', 1);
      this.set('competitor.counter', 1);
      this.set('competitor.dataValue', '');
    },


    losOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 15);
    }),

    adultOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 5);
    }),

    phoneNumber: Ember.computed('competitor.contactInformations', function () {
      var phoneNumber = null;
      this.get('competitor.contactInformations').forEach(function (contactInformation) {
        if (contactInformation.get('informationType') === 'MAIN_PHONE') {
          phoneNumber = contactInformation.get('informationValue');
        }
      });
      return phoneNumber;
    }),

    phoneNumberLink: Ember.computed('competitor.phoneNumber', function () {
      return 'tel:' + this.get('competitor.phoneNumber');
    }),

    valuesChanged: Ember.observer('competitor.collectedDate', 'competitor.arrivalDate', 'competitor.los', 'competitor.counter', function () {
      this.send('save');
    }),

    actions: {
      focusOut: function focusOut(view) {
        alert(view);
      },
      save: function save() {
        var _this = this;

        var entry = this.get('data');

        // Return if there is not a price in the data value
        if (!this.get('competitor.dataValue')) {
          return;
        }

        var tz = this.tz.get("customer");

        this.get('ajax').request('/rest/v1/analyticEntries', {
          data: {
            channelcode: 'ALL',
            begindate: (0, _moment.default)(this.get('competitor.arrivalDate')).tz(tz).startOf('day').format(),
            enddate: (0, _moment.default)(this.get('competitor.arrivalDate')).add(1, 'days').tz(tz).startOf('day').format(),
            frequency: 'DAY',
            customerid: this.get('customer.id'),
            contenttype: 'UNPUBLISHED_RATE',
            analytictype: 'AMOUNT',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL'
          }
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

          var generateKey = function generateKey(beginDate, los, nbrAdults) {
            return beginDate + '-' + los + '-' + nbrAdults;
          };

          var map = {};

          if (analyticEntries.get('length') > 0 && analyticEntries.filterBy('groupByValue', _this.get('competitor.id')).length > 0) {
            entry = Ember.Object.create(analyticEntries.filterBy('groupByValue', _this.get('competitor.id'))[0]);
            entry.set('description', _this.get('competitor.name'));
            var dataValues = entry.get('dataValues');
            // Convert array to a map
            dataValues.forEach(function (val) {
              map[val.key] = val.value;
            });
          } else {
            entry.set('entryDate', (0, _moment.default)(_this.get('competitor.arrivalDate')).tz(tz).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
            entry.set('beginDate', (0, _moment.default)(_this.get('competitor.arrivalDate')).tz(tz).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
            entry.set('endDate', (0, _moment.default)(_this.get('competitor.arrivalDate')).tz(tz).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'));
          }

          map["" + generateKey((0, _moment.default)(_this.get('competitor.arrivalDate')).tz(tz).startOf('day').format(), _this.get('competitor.los'), _this.get('competitor.counter'))] = encodeURI(JSON.stringify({
            collectedDate: (0, _moment.default)(_this.get('competitor.collectedDate')).tz(tz).startOf('day'),
            arrivalDate: (0, _moment.default)(_this.get('competitor.arrivalDate')).tz(tz).startOf('day'),
            los: _this.get('competitor.los'),
            adults: _this.get('competitor.counter'),
            amount: _this.get('competitor.dataValue')
          }));

          entry.set('dataValues', map);

          _this.ajax.post('/rest/v1/analyticEntries', {
            data: JSON.stringify(entry)
          }).then(function (_ref2) {
            var analyticEntry = _ref2.analyticEntry;

            var entry = Ember.Object.create(analyticEntry);
            entry.set('isNew', true);
            _this.set('data', entry);
          }).catch(function () {
            _this.get('dialog').showError();
          });
        });
      },
      clear: function clear() {
        this._newEntry();
        this.set('competitor.counter', 1);
        this.set('competitor.los', 1);
        this.set('competitor.dataValue', '');
      }
    }
  });
});