define('ux-pcc/components/dashboard-widget/market-segmentation-percent', ['exports', 'moment', 'accounting'], function (exports, _moment, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    loading: true,
    data: null,
    tagName: "",

    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this._fetchData();
    },


    _fetchDataObserver: Ember.observer('customer.id', 'selectedDate', function () {
      Ember.run.once(this, '_fetchData');
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var customerID = this.get('customer.id');
      var tz = this.tz.get('customer');
      var selectedDate = _moment.default.tz(this.get('selectedDate'), tz).startOf('day');

      this.set('loading', true);
      this.set('data', null);

      var segmentAnalyticsCollection = this.ajax.request('rest/v1/analyticEntries', {
        data: {
          channelcode: 'ALL',
          begindate: selectedDate.format(),
          enddate: selectedDate.format(),
          frequency: 'DAY',
          customerid: customerID,
          contenttype: 'PRICING_PERFORMANCE',
          analytictype: 'COUNT',
          subcontenttype: '',
          action: 'STATISTIC',
          datatype: 'VALUE',
          groupBy: "SEGMENT",
          rollupstatus: this.get('rollupStatus'),
          fields: 'id,beginDate,endDate,dataValues,contentType,subContentType,groupBy,groupByValue'
        }
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

        return analyticEntries;
      });

      var segmentCodesCollection = this.store.query('code', {
        customerID: customerID,
        codeType: 'MARKET_SEGMENT'
      });

      Ember.RSVP.hash({
        segments: segmentAnalyticsCollection,
        marketSegments: segmentCodesCollection
      }).then(function (data) {
        // if no data than return
        if (!data.segments || !data.marketSegments) {
          return;
        }

        var values = {};
        var segments = [];
        var entries = data.segments;
        var marketSegments = data.marketSegments.toArray();

        if (marketSegments && marketSegments.length > 0) {
          // Sort the marketSegments by orderBY
          marketSegments.sort(function (a, b) {
            return a.get('orderBy') - b.get('orderBy');
          });

          // Add General to the list
          marketSegments.push(Ember.Object.create({
            externalReference: 'GENERAL',
            shortDescription: _this.i18n.t('customer.performance.general_segment') + "",
            orderBy: '9999'
          }));

          var segmentValueMap = {};

          // Map the segment values for retrieval later
          if (entries) {

            // Loop through the entries and fill out the map
            entries.filterBy('groupBy', 'SEGMENT').forEach(function (e) {

              // let groupBy = decodeURIComponent(e.get('groupBy')).toUpperCase();
              var segmentValue = 0;

              var segmentName = decodeURIComponent(Ember.get(e, 'groupByValue')).toUpperCase();

              Ember.get(e, 'dataValues').forEach(function (val) {
                if (val.key === 'bookings') {
                  segmentValue = parseFloat(val.value);
                }
              });

              // Add the value to the map
              segmentValueMap[segmentName] = segmentValue >= 100 ? _accounting.default.formatNumber(segmentValue) : _accounting.default.formatNumber(segmentValue, 1);
            });
          }

          // Change the market segments into the array we need, mapping the value
          // to the properly ordered market segments codes
          segments = marketSegments.map(function (segment, i) {
            var label = segment.get('shortDescription');
            if (label) {
              var val = label.toUpperCase() in segmentValueMap ? segmentValueMap[label.toUpperCase()] : 0;
              return {
                "label": label,
                "value": val >= 100 ? _accounting.default.formatNumber(val) : _accounting.default.formatNumber(val, 1),
                "pie_color_class": 'chart-color--' + i,
                "label_classes": 'chart-color--' + i,
                "value_classes": 'chart-color--' + i + ' percent'
              };
            }
          });
        }

        values.segments = segments;

        // Place the center value and the legend
        if (segments && segments.length > 0) {
          values.centerLabel = segments[0].label;
          values.centerValue = segments[0].value;
          // Strip the first value from the array, as that is in the center
          values.legend = segments.slice(1);
        } else {
          values.legend = [];
        }

        _this.set('data', values);
      }).finally(function () {
        _this.set('loading', false);
      });
    }
  });
});