define('ux-pcc/models/batch-request', ['exports', 'ux-data/models/batch-request'], function (exports, _batchRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _batchRequest.default;
    }
  });
});