define('ux-pcc/components/marketing-campaigns/marketing-campaign-row', ['exports', 'ux-components/components/marketing-campaigns/marketing-campaign-row'], function (exports, _marketingCampaignRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _marketingCampaignRow.default;
    }
  });
});