define('ux-pcc/routes/rules-advanced/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var customer = this.modelFor('customer');
      return this.get('store').createRecord('rule', {
        customer: customer,
        ruleMethod: 'SET',
        ruleType: '',
        currencyCode: 'USD',
        children: []
      });
    },


    actions: {
      saveRuleSet: function saveRuleSet() {
        var _this = this;

        var model = this.get('controller.model');
        var beginDate = (0, _moment.default)(model.get('beginDate')).utc().format('YYYY-MM-DD');
        var endDate = model.get('endDate') ? (0, _moment.default)(model.get('endDate')).utc().format('YYYY-MM-DD') : null;
        model.set('beginDate', (0, _moment.default)(beginDate).startOf('day'));
        model.set('endDate', endDate ? (0, _moment.default)(endDate).startOf('day') : null);
        model.save().then(function () {
          // Success!
          _this.controllerFor('rules-advanced').send('refresh');
          _this.replaceWith('rules-advanced');
          _this.send('flash', '<i class=\'fa fa-thumbs-up\'></i> ' + _this.get('i18n').t('rules.index.success_create_message', { ruleName: model.get('shortDescription') }), 'success');
        }).catch(function (error) {
          return _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
        });
      }
    }
  });
});