define('ux-pcc/components/moment-datepicker', ['exports', 'moment', 'ux-pcc/mixins/inbound-actions'], function (exports, _moment, _inboundActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend(_inboundActions.default, {
    classNames: ['datepicker-input'],

    attributeBindings: ['format:data-date-format', 'autocomplete'],

    options: null,

    autocomplete: 'off',
    readonly: true,

    timezone: Ember.computed.oneWay('tz.customer'),

    hasBeenInitialized: false,

    iconOnly: false,

    format: 'LLL',

    slice: null,
    sliceChanged: Ember.observer('slice', function () {
      var slice = this.slice;
      // Strip to local for manipulation
      if (slice) {
        var converted = _moment.default.tz(slice, _moment.default.ISO_8601, this.timezone);
        this._setPickerDate(converted.format('LLL'));
      } else {
        this._setPickerDate('');
      }
    }),

    optionsChanged: Ember.observer('options', function () {
      this._buildPicker();
    }),

    didInsertElement: function didInsertElement() {
      this._super();

      // Add the datepicker icon, and hide the calendar input box
      if (this.iconOnly) {
        var element = this.element;
        var icon = Ember.$('<i class=\'fa fa-calendar datepicker-icon\'></i>');
        Ember.$(element).after(icon);
        Ember.$(element).addClass('icon-only-picker');
        // Add click event to icon to pop the calendar
        icon.on('click', this._handleIconClick(this));
      }

      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },
    afterRenderEvent: function afterRenderEvent() {
      this._buildPicker();
    },
    willDestroyElement: function willDestroyElement() {
      var element = this.element;

      // Remove the datepicker icon we added for the date block.  Removing the icon removes all bound events as well
      if (this.iconOnly && Ember.$(element).parent().children('.datepicker-icon').length > 0) {
        Ember.$(element).parent().children('.datepicker-icon').remove();
      }
      Ember.$(element).off('dp.change');
      if (Ember.$(element).data('DateTimePicker')) {
        Ember.$(element).data('DateTimePicker').destroy();
      }
    },
    _buildPicker: function _buildPicker() {
      var element = this.element;
      var picker = Ember.$(element).data('DateTimePicker');
      if (picker) {
        Ember.$(element).off('dp.change');
        picker.destroy();
      }

      var options = this.options || {};
      // The date picker sets the moment to the language passed in, and it overrides moment across the
      // application so we only want to set the language equal to what the user has already selected
      options.locale = _moment.default.locale();
      options.ignoreReadonly = true;

      // Strip to local for manipulation
      var converted = _moment.default.tz(this.slice, _moment.default.ISO_8601, this.timezone);

      var args = [options];

      Ember.$.fn.datetimepicker.apply(Ember.$(element), args);
      if (this.slice) {
        this._setPickerDate(converted);
        element.value = converted.format(this.format);
      }

      Ember.$(element).on('dp.change', this._handleChange(this));
    },
    _setPickerDate: function _setPickerDate(slice) {
      var _this = this;

      var element = this.element;
      if (slice && Ember.$(element).data('DateTimePicker')) {
        // Assumes slice is timezone-less
        Ember.$(element).data('DateTimePicker').date((0, _moment.default)(slice, 'LLL', this.get('options.locale')));
        Ember.$(element).data('DateTimePicker').viewDate((0, _moment.default)(slice, 'LLL', this.get('options.locale')));
      } else {
        // Clear the field immediately, otherwise the clear appears to take a second to occur
        if (Ember.$(element)) {
          Ember.$(element).val('');
        }
        //  Now run the actual reset of the DateTimePicker component
        Ember.run.later(function () {
          _this._buildPicker();
        }, 5);
      }

      if (this.onupdate) {
        this.onupdate((0, _moment.default)(slice));
      }
    },
    _handleChange: function _handleChange(scope) {
      var format = this.format;
      return function (event) {
        var options = scope.options || {};
        // Strip to TZ for storage
        // event.date should be the previous date; use picker date
        var date = Ember.$(event.target).data('DateTimePicker').date();

        // *** Work around for the datepicker not accurately handling the maxDate selection ***

        // if there is no picker date, try event.date in the case of a maxDate being set
        if (!date && event.date && _moment.default.isMoment(event.date) && event.date.isValid() && options.maxDate && _moment.default.isMoment(options.maxDate) && options.maxDate.isValid()) {

          // If the target and slice are blank, set the target with the previous value
          if (!scope.get('slice')) {
            Ember.$(event.target).data('DateTimePicker').date(event.date.startOf('day'));
          }
          return;
        }

        if (_moment.default && date) {
          var converted = _moment.default.tz(date.clone().format(format), format, scope.get('timezone'));

          if (!converted.isSame(scope.get('slice'))) {
            scope.set('slice', converted.format());
          }
        } else {
          scope.set('slice', '');
        }
      };
    },
    _handleIconClick: function _handleIconClick(scope) {
      return function () {
        var datePicker = scope.get('element');

        Ember.$(datePicker).focus().click();
      };
    },


    actions: {
      redraw: function redraw() {
        // TODO This is likely not the most performant way to redraw
        this._buildPicker();
      }
    }

  });
});