define('ux-pcc/components/dashboard-widget/year-comparison', ['exports', 'moment', 'ux-pcc/mixins/queued-query-data', 'ux-pcc/utils/date-formatter'], function (exports, _moment, _queuedQueryData, _dateFormatter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queuedQueryData.default, {

    ajax: Ember.inject.service(),

    tagName: 'div',
    classNames: ['year-comparison'],

    _data: null,
    _dataPrior: null,

    slideIndexLeft: 0,
    slideIndexRight: 0,
    slideIndexCenter: 0,

    locked: true,
    _lockedProxy: false,
    defaultView: true,

    lockText: Ember.computed('locked', function () {
      return this.i18n.t('dashboard-widget.year-comparison.' + (this.get('locked') ? 'unlock' : 'lock'));
    }),

    sortLeft: 'segmentCurrentYear',
    sortRight: 'segmentCurrentYear',

    sideClicked: '',

    panelListLeft: null,
    panelListRight: null,
    showComments: true,

    // Define required items for query-data-in-viewport mixin
    queryDataWatchProperties: null,

    init: function init() {
      var _this = this;

      if (!this || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }

      this.set('_data', []);
      this.set('_dataPrior', []);
      this.set('queryDataWatchProperties', ['customer.id', 'selectedDate', 'dataType', 'locationType', 'reservationCategory', 'aggregateVersion']);

      var leftList = ['dayYieldSegmentData', 'revYieldSegmentData'];
      var rightList = ['daySegmentData', 'yieldSegmentData', 'revSegmentData'];

      this.set('panelListLeft', leftList);
      this.set('panelListRight', rightList);

      leftList.forEach(function (p) {
        return _this.set(p + 'Sort', _this.get('defaultSortOrder'));
      });
      rightList.forEach(function (p) {
        return _this.set(p + 'Sort', _this.get('defaultSortOrder'));
      });
      this.set('dataSort', this.get('defaultSortOrder'));

      this._super.apply(this, arguments);
    },


    showCurrentYear: Ember.computed.equal('slideIndexCenter', 0),

    noWhitespaceTitle: Ember.computed('title', function () {
      return ('' + (this.get('title') || '')).replace(/\s+/g, '');
    }),

    currencyCode: Ember.computed('_data', '_dataPrior', function () {
      var data = this.get('_data' + (this.get('showCurrentYear') ? '' : 'Prior')) || [];

      if (data.get('firstObject')) {
        return data.get('firstObject.currencyCode');
      }

      return '';
    }),

    formatYieldOptions: Ember.computed('formatYieldBaseOptions', 'currencyCode', function () {
      return Ember.assign({}, this.get('formatYieldBaseOptions'), { currencyCode: this.get('currencyCode') });
    }),

    formatRevenueOptions: Ember.computed('formatRevenueBaseOptions', 'currencyCode', function () {
      return Ember.assign({}, this.get('formatRevenueBaseOptions'), { currencyCode: this.get('currencyCode') });
    }),

    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    total: Ember.computed('_data', '_dataPrior', 'showCurrentYear', function () {
      return (this.get('_data' + (this.get('showCurrentYear') ? '' : 'Prior')) || []).findBy('groupByValue', 'TOTAL');
    }),

    data: Ember.computed('_data', '_dataPrior', 'showCurrentYear', function () {
      var data = (this.get('_data' + (this.get('showCurrentYear') ? '' : 'Prior')) || []).rejectBy('groupByValue', 'TOTAL');
      data.forEach(function (d) {
        return d.sortValue = d.groupByValue;
      });
      return data;
    }),

    daysChangeOverall: Ember.computed('total', function () {
      return this.get('total') ? Number(this.get('total').dataValues.pctChangeUsageMinusNoShows) : 0;
    }),

    yieldChangeOverall: Ember.computed('total', function () {
      return this.get('total') ? Number(this.get('total').dataValues.pctChangeBaseYield) : 0;
    }),

    revChangeOverall: Ember.computed('total', function () {
      return this.get('total') ? Number(this.get('total').dataValues.pctChangeBase) : 0;
    }),

    daySegmentData: Ember.computed('data', function () {
      var _this2 = this;

      return this.get('data').map(function (c) {
        return {
          slice: c.shortDescription ? c.shortDescription : c.groupByValue,
          segmentPriorYear: Number(c.dataValues.stlyUsageMinusNoShows),
          segmentCurrentYear: Number(c.dataValues.usageMinusNoShows),
          segmentPYLabel: _this2.i18n.t('charts.year-over-year-segment.prior_year'),
          segmentCYLabel: _this2.i18n.t('charts.year-over-year-segment.current_year'),
          actualVariance: Number(c.dataValues.usageMinusNoShows) - Number(c.dataValues.stlyUsageMinusNoShows),
          percentVariance: Number(c.dataValues.pctChangeUsageMinusNoShows),
          actualLabel: _this2.i18n.t('charts.year-over-year-segment.actual_var'),
          percentLabel: _this2.i18n.t('charts.year-over-year-segment.percent_var'),
          sortValue: c.sortValue
        };
      });
    }),

    yieldSegmentData: Ember.computed('data', function () {
      var _this3 = this;

      return this.get('data').map(function (c) {
        return {
          slice: c.shortDescription ? c.shortDescription : c.groupByValue,
          segmentPriorYear: Number(c.dataValues.stlyBaseYield),
          segmentCurrentYear: Number(c.dataValues.baseYield),
          segmentPYLabel: _this3.i18n.t('charts.year-over-year-segment.prior_year'),
          segmentCYLabel: _this3.i18n.t('charts.year-over-year-segment.current_year'),
          actualVariance: Number(c.dataValues.baseYield) - Number(c.dataValues.stlyBaseYield),
          percentVariance: Number(c.dataValues.pctChangeBaseYield),
          actualLabel: _this3.i18n.t('charts.year-over-year-segment.actual_var'),
          percentLabel: _this3.i18n.t('charts.year-over-year-segment.percent_var'),
          actualPrecision: 2,
          sortValue: c.sortValue
        };
      });
    }),

    revSegmentData: Ember.computed('data', function () {
      var _this4 = this;

      return this.get('data').map(function (c) {
        return {
          slice: c.shortDescription ? c.shortDescription : c.groupByValue,
          segmentPriorYear: Number(c.dataValues.stlyBase),
          segmentCurrentYear: Number(c.dataValues.base),
          segmentPYLabel: _this4.i18n.t('charts.year-over-year-segment.prior_year'),
          segmentCYLabel: _this4.i18n.t('charts.year-over-year-segment.current_year'),
          actualVariance: Number(c.dataValues.base) - Number(c.dataValues.stlyBase),
          percentVariance: Number(c.dataValues.pctChangeBase),
          actualLabel: _this4.i18n.t('charts.year-over-year-segment.actual_var'),
          percentLabel: _this4.i18n.t('charts.year-over-year-segment.percent_var'),
          actualPrecision: 2,
          sortValue: c.sortValue
        };
      });
    }),

    dayYieldSegmentData: Ember.computed('data', function () {
      var _this5 = this;

      return this.get('data').map(function (c) {
        return {
          slice: c.shortDescription ? c.shortDescription : c.groupByValue,
          segmentCurrentYear: Number(c.dataValues.pctChangeUsageMinusNoShows),
          segmentPriorYear: Number(c.dataValues.pctChangeBaseYield),
          daysActual: Number(c.dataValues.usageMinusNoShows) - Number(c.dataValues.stlyUsageMinusNoShows),
          yieldActual: Number(c.dataValues.baseYield) - Number(c.dataValues.stlyBaseYield),
          daysOverall: _this5.get('daysChangeOverall'),
          yieldOverall: _this5.get('yieldChangeOverall'),
          segmentCYLabel: _this5.i18n.t('charts.year-over-year-segment.days_var'),
          segmentPYLabel: _this5.i18n.t('charts.year-over-year-segment.yield_var'),
          daysActualLabel: _this5.i18n.t('charts.year-over-year-segment.days_actual_var'),
          yieldActualLabel: _this5.i18n.t('charts.year-over-year-segment.yield_actual_var'),
          daysOverallLabel: _this5.i18n.t('charts.year-over-year-segment.days_var_overall'),
          yieldOverallLabel: _this5.i18n.t('charts.year-over-year-segment.yield_var_overall'),
          sortValue: c.sortValue
        };
      });
    }),

    revYieldSegmentData: Ember.computed('data', function () {
      var _this6 = this;

      return this.get('data').map(function (c) {
        return {
          slice: c.shortDescription ? c.shortDescription : c.groupByValue,
          segmentCurrentYear: Number(c.dataValues.pctChangeBase),
          segmentPriorYear: Number(c.dataValues.pctChangeBaseYield),
          revActual: Number(c.dataValues.base) - Number(c.dataValues.stlyBase),
          yieldActual: Number(c.dataValues.baseYield) - Number(c.dataValues.stlyBaseYield),
          revOverall: _this6.get('revChangeOverall'),
          yieldOverall: _this6.get('yieldChangeOverall'),
          segmentCYLabel: _this6.i18n.t('charts.year-over-year-segment.rev_var'),
          segmentPYLabel: _this6.i18n.t('charts.year-over-year-segment.yield_var'),
          revActualLabel: _this6.i18n.t('charts.year-over-year-segment.rev_actual_var'),
          yieldActualLabel: _this6.i18n.t('charts.year-over-year-segment.yield_actual_var'),
          revOverallLabel: _this6.i18n.t('charts.year-over-year-segment.rev_var_overall'),
          yieldOverallLabel: _this6.i18n.t('charts.year-over-year-segment.yield_var_overall'),
          sortValue: c.sortValue
        };
      });
    }),

    sortedData: Ember.computed('data', 'dataSort', 'defaultView', function () {
      return this._sortData(this.get('data'), 'dataSort');
    }),

    sortedDaySegmentData: Ember.computed('daySegmentData', 'daySegmentDataSort', 'defaultView', function () {
      return this._sortData(this.get('daySegmentData'), 'daySegmentDataSort');
    }),

    sortedYieldSegmentData: Ember.computed('yieldSegmentData', 'yieldSegmentDataSort', 'defaultView', function () {
      return this._sortData(this.get('yieldSegmentData'), 'yieldSegmentDataSort');
    }),

    sortedRevSegmentData: Ember.computed('revSegmentData', 'revSegmentDataSort', 'defaultView', function () {
      return this._sortData(this.get('revSegmentData'), 'revSegmentDataSort');
    }),

    sortedDayYieldSegmentData: Ember.computed('dayYieldSegmentData', 'dayYieldSegmentDataSort', 'defaultView', function () {
      return this._sortData(this.get('dayYieldSegmentData'), 'dayYieldSegmentDataSort');
    }),

    sortedRevYieldSegmentData: Ember.computed('revYieldSegmentData', 'revYieldSegmentDataSort', 'defaultView', function () {
      return this._sortData(this.get('revYieldSegmentData'), 'revYieldSegmentDataSort');
    }),

    _sortData: function _sortData(data, sortField) {
      var sortOrder = this.get('defaultView') ? this.get('defaultSortOrder') : this.get(sortField);
      return sortOrder.map(function (k) {
        return data.findBy('sortValue', k);
      }).filter(function (e) {
        return e;
      });
    },
    _getGraphConfig: function _getGraphConfig(valueSuffix, precisionHash) {
      return Ember.Object.create({
        categoryField: 'slice',
        showBalloon: true,
        graphs: [{
          title: this.i18n.t('charts.year-over-year-segment.current_year'),
          colorClass: 'chart-color--days',
          valueField: 'segmentCurrentYear',
          valueLabel: 'segmentCYLabel',
          valueSuffix: valueSuffix,
          precisionHash: precisionHash
        }, {
          title: this.i18n.t('charts.year-over-year-segment.prior_year'),
          colorClass: 'chart-color--yield',
          valueField: 'segmentPriorYear',
          valueLabel: 'segmentPYLabel',
          valueSuffix: valueSuffix,
          precisionHash: precisionHash
        }]
      });
    },


    dayYieldGraphConfig: Ember.computed('i18n.locale', 'formatYieldOptions', function () {
      var config = this._getGraphConfig('%', { precision: 1 });

      config.set('graphs.firstObject.fields', ['segmentCurrentYear', 'segmentPriorYear', 'daysActual', 'yieldActual', 'daysOverall', 'yieldOverall']);
      config.set('graphs.firstObject.labels', ['segmentCYLabel', 'segmentPYLabel', 'daysActualLabel', 'yieldActualLabel', 'daysOverallLabel', 'yieldOverallLabel']);
      config.set('graphs.firstObject.precisions', [{ precision: 1 }, { precision: 1 }, { precision: 0 }, this.get('formatYieldOptions'), { precision: 1 }, { precision: 1 }]);

      config.set('graphs.firstObject.suffixes', [{ suffix: '%' }, { suffix: '%' }, { suffix: '' }, { suffix: '' }, { suffix: '%' }, { suffix: '%' }]);

      return config;
    }),

    revenueYieldGraphConfig: Ember.computed('i18n.locale', 'formatYieldOptions', 'formatRevenueOptions', function () {
      var config = this._getGraphConfig('%', { precision: 1 });

      config.set('graphs.firstObject.colorClass', 'chart-color--revenue');
      config.set('graphs.firstObject.fields', ['segmentCurrentYear', 'segmentPriorYear', 'revActual', 'yieldActual', 'revOverall', 'yieldOverall']);
      config.set('graphs.firstObject.labels', ['segmentCYLabel', 'segmentPYLabel', 'revActualLabel', 'yieldActualLabel', 'revOverallLabel', 'yieldOverallLabel']);
      config.set('graphs.firstObject.precisions', [{ precision: 1 }, { precision: 1 }, this.get('formatRevenueOptions'), this.get('formatYieldOptions'), { precision: 1 }, { precision: 1 }]);

      config.set('graphs.firstObject.suffixes', [{ suffix: '%' }, { suffix: '%' }, { suffix: '' }, { suffix: '' }, { suffix: '%' }, { suffix: '%' }]);

      return config;
    }),

    dayGraphConfig: Ember.computed('i18n.locale', function () {
      var config = this._getGraphConfig(null, { precision: 0 });
      config.set('graphs.lastObject.colorClass', 'chart-color--days-compare');
      config.set('graphs.firstObject.fields', ['segmentCurrentYear', 'segmentPriorYear', 'actualVariance', 'percentVariance']);
      config.set('graphs.firstObject.labels', ['segmentCYLabel', 'segmentPYLabel', 'actualLabel', 'percentLabel']);
      config.set('graphs.firstObject.precisions', [{ precision: 0 }, { precision: 0 }, { precision: 0 }, { precision: 1 }]);

      config.set('graphs.firstObject.suffixes', [{ suffix: '' }, { suffix: '' }, { suffix: '' }, { suffix: '%' }]);

      return config;
    }),

    yieldGraphConfig: Ember.computed('i18n.locale', 'formatYieldOptions', function () {
      var formatYieldOptions = this.get('formatYieldOptions');
      var config = this._getGraphConfig(null, formatYieldOptions);

      config.set('graphs.firstObject.colorClass', 'chart-color--yield');
      config.set('graphs.lastObject.colorClass', 'chart-color--yield-compare');

      config.set('graphs.firstObject.fields', ['segmentCurrentYear', 'segmentPriorYear', 'actualVariance', 'percentVariance']);
      config.set('graphs.firstObject.labels', ['segmentCYLabel', 'segmentPYLabel', 'actualLabel', 'percentLabel']);
      config.set('graphs.firstObject.precisions', [formatYieldOptions, formatYieldOptions, formatYieldOptions, { precision: 1 }]);

      config.set('graphs.firstObject.suffixes', [{ suffix: '' }, { suffix: '' }, { suffix: '' }, { suffix: '%' }]);

      return config;
    }),

    revenueGraphConfig: Ember.computed('i18n.locale', 'formatRevenueOptions', function () {
      var revenueOptions = this.get('formatRevenueOptions');
      var config = this._getGraphConfig(null, revenueOptions);
      config.set('graphs.firstObject.colorClass', 'chart-color--revenue');
      config.set('graphs.lastObject.colorClass', 'chart-color--revenue-compare');

      config.set('graphs.firstObject.fields', ['segmentCurrentYear', 'segmentPriorYear', 'actualVariance', 'percentVariance']);
      config.set('graphs.firstObject.labels', ['segmentCYLabel', 'segmentPYLabel', 'actualLabel', 'percentLabel']);
      config.set('graphs.firstObject.precisions', [revenueOptions, revenueOptions, revenueOptions, { precision: 1 }]);

      config.set('graphs.firstObject.suffixes', [{ suffix: '' }, { suffix: '' }, { suffix: '' }, { suffix: '%' }]);

      return config;
    }),

    selectedDateLastYear: Ember.computed('selectedDate', function () {
      return this.get('selectedDate').clone().subtract(1, 'years');
    }),

    aggregationType: Ember.computed('aggregateVersion', function () {
      var version = this.get('aggregateVersion');
      return version !== '1' ? 'FACET_SEARCH_GROUPING_AGGREGATOR_V2' : 'FACET_SEARCH_GROUPING_AGGREGATOR';
    }),

    queryDataMethod: function queryDataMethod() {
      var _this7 = this;

      if (!this || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      var promises = [];

      var customerID = this.get('customer.id');
      var tz = this.tz.get('customer');
      var selectedDate = this.get('selectedDate').clone().startOf('day');

      // set the asOfDateBase to be the start of day today in the property TZ
      var today = _moment.default.tz(tz).startOf('day');
      var asOfDateBase = today.clone();
      var currentMonth = _moment.default.tz(tz).startOf('month');

      // If selected date is prior to current month, grab the selected month, and add 1 day
      // so that the subtraction below gives you the end of the selected month
      if (selectedDate.isBefore(currentMonth)) {
        asOfDateBase = selectedDate.clone().endOf('month').add(1, 'day');
      }

      this.set('_data', []);
      this.set('_dataPrior', []);

      var queryParameters = {
        customerid: customerID,
        aggregationType: this.get('aggregationType'),
        rollupStatus: this.get('rollupStatus'),
        accumulate: false,
        asOfDateBeginStr: asOfDateBase.clone().subtract(1, 'day').startOf('month').format('YYYY-MM-DD'),
        asOfDateEndStr: asOfDateBase.clone().subtract(1, 'day').format('YYYY-MM-DD'),
        sourceLocationCode: this.get('dataType'),
        locationType: this.get('locationType'),
        returnType: this.get('reservationCategory'),
        groupBy: this.get('type'),
        nocache: this.get('nocache')
      };

      queryParameters.begindateStr = selectedDate.clone().startOf('month').format('YYYY-MM-DD');
      queryParameters.enddateStr = selectedDate.clone().endOf('month').format('YYYY-MM-DD');

      promises.push(this.ajax.request('rest/v1/analyticEntryAggregation/process', { data: queryParameters }).then(function (_ref) {
        var collections = _ref.collections;
        return _this7._processResponse(collections, '_data');
      }).catch(function () {
        if (!_this7 || _this7.get('isDestroyed') || _this7.get('isDestroying')) {
          return;
        }
        _this7.set('_data', null);
      }));

      queryParameters.begindateStr = selectedDate.clone().startOf('month').subtract(52, 'weeks').format('YYYY-MM-DD');
      queryParameters.enddateStr = selectedDate.clone().endOf('month').subtract(52, 'weeks').format('YYYY-MM-DD');

      // If looking in the current month, or the future months, use EOD Yesterday Same time last year
      if (today.isBefore(selectedDate.clone().endOf('month'))) {
        queryParameters.asOfDateEndStr = today.clone().subtract(1, 'day').subtract(52, 'weeks').format('YYYY-MM-DD');
      } else {
        queryParameters.asOfDateEndStr = asOfDateBase.clone().subtract(1, 'day').subtract(52, 'weeks').format('YYYY-MM-DD');
      }

      // Don't need asOfBeginDate so remove it
      delete queryParameters.asOfDateBeginStr;

      promises.push(this.ajax.request('rest/v1/analyticEntryAggregation/process', { track: true, data: queryParameters }).then(function (_ref2) {
        var collections = _ref2.collections;
        return _this7._processResponse(collections, '_dataPrior');
      }).catch(function () {
        if (!_this7 || _this7.get('isDestroyed') || _this7.get('isDestroying')) {
          return;
        }
        _this7.set('_data', null);
      }));

      return Ember.RSVP.all(promises);
    },
    _processResponse: function _processResponse(collections, dataField) {
      var channels = this.get('channels');
      if (channels) {
        collections.filter(function (e) {
          return Object.keys(channels).includes(e.groupByValue);
        }).forEach(function (e) {
          return e['shortDescription'] = channels[e.groupByValue];
        });
      }

      this.set(dataField, collections);
    },


    beginDate: Ember.computed('selectedDate', 'tz.customer', 'showCurrentYear', 'i18n.locale', function () {
      var selectedDate = this.get('selectedDate').clone().startOf('day');
      var settings = this.get('customer.settings');

      var date = void 0;
      if (this.get('showCurrentYear')) {
        date = selectedDate.clone().startOf('month');
      } else {
        date = selectedDate.clone().startOf('month').subtract(52, 'weeks');
      }

      return (0, _dateFormatter.dateFormatter)(date, { settings: settings });
    }),

    endDate: Ember.computed('selectedDate', 'tz.customer', 'showCurrentYear', 'i18n.locale', function () {
      var selectedDate = this.get('selectedDate').clone().startOf('day');
      var settings = this.get('customer.settings');

      var date = void 0;
      if (this.get('showCurrentYear')) {
        date = selectedDate.clone().endOf('month');
      } else {
        date = selectedDate.clone().endOf('month').subtract(52, 'weeks');
      }

      return (0, _dateFormatter.dateFormatter)(date, { settings: settings });
    }),

    previousBeginDate: Ember.computed('selectedDate', 'tz.customer', 'showCurrentYear', 'i18n.locale', function () {
      var selectedDate = this.get('selectedDate').clone().startOf('day');
      var settings = this.get('customer.settings');

      var date = void 0;
      if (this.get('showCurrentYear')) {
        date = selectedDate.clone().startOf('month').subtract(52, 'weeks');
      } else {
        date = selectedDate.clone().startOf('month').subtract(104, 'weeks');
      }

      return (0, _dateFormatter.dateFormatter)(date, { settings: settings });
    }),

    previousEndDate: Ember.computed('selectedDate', 'tz.customer', 'showCurrentYear', 'i18n.locale', function () {
      var selectedDate = this.get('selectedDate').clone().startOf('day');
      var settings = this.get('customer.settings');

      var date = void 0;
      if (this.get('showCurrentYear')) {
        date = selectedDate.clone().endOf('month').subtract(52, 'weeks');
      } else {
        date = selectedDate.clone().endOf('month').subtract(104, 'weeks');
      }

      return (0, _dateFormatter.dateFormatter)(date, { settings: settings });
    }),

    _slideObserver: Ember.observer('slideIndexRight', 'slideIndexLeft', 'slideIndexCenter', '_lockedProxy', function () {
      this._determineSort();
    }),

    _determineSort: function _determineSort() {
      var _this8 = this;

      if (this.get('defaultView')) {
        return;
      }

      var side = this.get('sideClicked');

      var clickedName = this.get('panelList' + side)[this.get('slideIndex' + side)];
      var data = this.get(clickedName);

      var sort = this.get('sort' + side);
      if (sort.includes(':asc')) {
        data = data.sortBy(sort.replace(':asc', ''));
      } else if (sort.includes(':desc')) {
        data = data.sortBy(sort.replace(':desc', '')).reverse();
      }

      var sortOrder = data.map(function (d) {
        return d.sortValue;
      });

      this.set(clickedName + 'Sort', sortOrder);

      if (this.get('locked')) {
        this.get('panelListLeft').forEach(function (p) {
          return _this8.set(p + 'Sort', sortOrder);
        });
        this.get('panelListRight').forEach(function (p) {
          return _this8.set(p + 'Sort', sortOrder);
        });
        this.set('dataSort', sortOrder);
      }
    },


    actions: {
      updateSortLeft: function updateSortLeft(key, ascending) {
        if (ascending) {
          this.set('sortLeft', key + ':asc');
        } else {
          this.set('sortLeft', key + ':desc');
        }

        this.set('sideClicked', 'Left');
        this.set('defaultView', false);

        this._determineSort();
      },
      updateSortRight: function updateSortRight(key, ascending) {
        if (ascending) {
          this.set('sortRight', key + ':asc');
        } else {
          this.set('sortRight', key + ':desc');
        }

        this.set('sideClicked', 'Right');
        this.set('defaultView', false);

        this._determineSort();
      },
      focusDays: function focusDays() {
        this.set('slideIndexLeft', 0);
        this.set('slideIndexRight', 0);
      },
      focusYield: function focusYield() {
        // both left slides have yield
        this.set('slideIndexRight', 1);
      },
      focusRevenue: function focusRevenue() {
        this.set('slideIndexLeft', 1);
        this.set('slideIndexRight', 2);
      },
      toggleLocked: function toggleLocked(side) {
        this.set('sideClicked', side);
        this.toggleProperty('locked');
        if (this.get('locked')) {
          this.toggleProperty('_lockedProxy');
        }
      },
      resetToDefault: function resetToDefault() {
        this.set('defaultView', true);
        this.set('locked', true);
        this.set('sideClicked', '');
        this.set('sortLeft', 'segmentCurrentYear');
        this.set('sortRight', 'segmentCurrentYear');
      }
    }
  });
});