define('ux-pcc/helpers/reservation-status', ['exports', 'ux-components/helpers/reservation-status'], function (exports, _reservationStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _reservationStatus.default;
    }
  });
  Object.defineProperty(exports, 'reservationStatus', {
    enumerable: true,
    get: function () {
      return _reservationStatus.reservationStatus;
    }
  });
});