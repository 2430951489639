define('ux-pcc/routes/data-monitoring/detail', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      page: {
        refreshModel: true
      }
    },

    ajax: Ember.inject.service(),

    model: function model(params) {
      var tz = this.tz.get('customer');
      var customerId = params.id;
      var beginDate = _moment.default.tz(tz).startOf('day');

      var page = params.page || 1;
      var customer = this.store.findRecord('customer', customerId, { include: 'settings' });

      return Ember.RSVP.hash({
        customer: customer,
        metrics: this.ajax.request('/rest/v1/dataMonitoring/detail/' + customerId, {
          data: {
            beginDate: beginDate.format('YYYY-MM-DD'),
            page: page,
            limit: 20,
            include: 'GENERAL_INVENTORY,RESERVATION_COUNT,OOO_INVENTORY,OVERBOOKING_INVENTORY,MANAGER_HOLD,SELLRATE,REVENUE'
          }
        }).then(function (_ref) {
          var _ref$customerSummaryM = _ref.customerSummaryMetrics,
              customerSummaryMetrics = _ref$customerSummaryM === undefined ? [] : _ref$customerSummaryM;

          customerSummaryMetrics.forEach(function (content) {
            // allows for easier sorting
            content.totalIssues = content.summaryDetail.DAY.totalCountIssue;
          });

          return { customerSummaryMetrics: customerSummaryMetrics };
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.metrics.customerSummaryMetrics);
      controller.set('customer', model.customer);
      controller.set('resultsFound', model.metrics.meta ? model.metrics.meta.pagination.resultsFound : 0);
      controller.set('latestExtractDate', model.metrics.meta ? (0, _moment.default)(model.metrics.meta.latestExtractDate) : (0, _moment.default)());

      var currency = model.customer.get('settings').findBy("settingCode", "DEFAULT_CURRENCY");
      if (currency && currency.get("settingValue")) {
        controller.set('currencyCode', currency.get("settingValue"));
      } else {
        controller.set('currencyCode', "USD");
      }
    }
  });
});