define('ux-pcc/components/rules/thens/restriction-then', ['exports', 'ember-changeset', 'ember-changeset-validations'], function (exports, _emberChangeset, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (!changeset.get('amountRule')) {
        changeset.set('amountRule', this.get('thenConditions.firstObject.value'));
      }

      this.get('onRegisterValidation')(changeset);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onRegisterValidation')(this.get('changeset'));
    },


    changeset: Ember.computed('rule', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    validations: {},

    thenConditions: Ember.computed('i18n.locale', function () {
      return [{ value: "0", label: this.i18n.t('pcc-components.rules.rule-then.restriction-then.close') }, { value: "1", label: this.i18n.t('pcc-components.rules.rule-then.restriction-then.open') }, { value: "2", label: this.i18n.t('pcc-components.rules.rule-then.restriction-then.cta') }];
    })
  });
});