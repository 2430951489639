define('ux-pcc/controllers/competitive-sets/add-pricing', ['exports', 'lodash', 'moment'], function (exports, _lodash, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    currencyCode: Ember.computed('customer.settings', function () {
      return Ember.get(this.get('customer.settings').findBy('settingCode', 'CURRENCY_CODE') || {}, 'settingValue') || 'USD';
    }),

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),

    losOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 15);
    }),

    adultOptions: Ember.computed(function () {
      return (0, _lodash.range)(1, 5);
    })
  });
});