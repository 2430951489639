define('ux-pcc/instance-initializers/metrics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {

    // Get the router and add the function to track page views on transition
    var router = appInstance.lookup('router:main');
    var config = appInstance.lookup('config:main');
    var metrics = appInstance.lookup('service:metrics');

    router.on("didTransition", function () {
      var url = this.get('url');

      // Remove the customer from the url so we have page specific data
      url = url.replace(/\/customers\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/, '');

      // Remove any IDs from the url so we have page specific data
      url = url.replace(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g, '');

      // Remove any parameters from the URL
      url = url.split("?")[0];

      // If url is root then set it back to the original url
      if (url === '/') {
        url = this.get('url');
      }

      metrics.trackPage({
        page: url
      });
    });

    var adapters = [];

    // Above Property Google Tag Manager
    if (config.gtmInternalId) {
      adapters.push({
        name: 'GoogleTagManager',
        environments: ['all'],
        config: {
          id: config.gtmInternalId,
          envParams: 'gtm_auth=' + config.gtmAuth + '&gtm_preview=' + config.gtmPreview + '&gtm_cookies_win=x'
        }
      });
    }

    // Intercom
    if (config.intercomAppId) {
      adapters.push({
        name: 'Intercom',
        environments: ['all'],
        config: {
          appId: config.intercomAppId
        }
      });
    }

    metrics.activateAdapters(adapters);
  }

  exports.default = {
    name: 'metrics',
    initialize: initialize
  };
});