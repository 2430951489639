define('ux-pcc/components/rules/thens/notification-then', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'ux-data/validations/conditional'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _conditional) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    onRegisterValidation: function onRegisterValidation() {},
    onUnregisterValidation: function onUnregisterValidation() {},

    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.get('changeset');

      if (!changeset.get('filterValue')) {
        changeset.set('filterValue', {
          userTokens: [],
          emailAlert: false,
          textAlert: false,
          level: 'NOTIFY_ALERT'
        });
      } else {
        if (typeof changeset.get('filterValue') === 'string') {
          changeset.set('filterValue', JSON.parse(changeset.get('filterValue')));
        }
      }

      this.get('onRegisterValidation')(changeset);

      this._setupUsers();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('onUnregisterValidation')(this.get('changeset'));
    },


    validations: Ember.computed('i18n.locale', function () {
      return {
        'filterValue.level': (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form_modal.no_type').toString()
        }),
        'filterValue.userTokens': (0, _conditional.default)({
          if: (0, _conditional.or)((0, _conditional.equal)('filterValue.emailAlert', true), (0, _conditional.equal)('filterValue.textAlert', true)),
          then: (0, _validators.validateLength)({
            min: 1,
            allowNone: false,
            message: this.i18n.t('rule._form_modal.no_recipients').toString()
          })
        }),
        'filterValue.textAlert': (0, _conditional.default)({
          if: (0, _conditional.and)((0, _conditional.notEqual)('filterValue.userTokens.length', 0), (0, _conditional.notEqual)('filterValue.emailAlert', true), (0, _conditional.notEqual)('filterValue.textAlert', true)),
          then: (0, _validators.validateInclusion)({
            list: [true],
            message: this.i18n.t('rule._form_modal.no_notify_method').toString()
          })
        }),
        'filterValue.emailAlert': (0, _conditional.default)({
          if: (0, _conditional.and)((0, _conditional.notEqual)('filterValue.userTokens.length', 0), (0, _conditional.notEqual)('filterValue.textAlert', true), (0, _conditional.notEqual)('filterValue.emailAlert', true)),
          then: (0, _validators.validateInclusion)({
            list: [true],
            message: this.i18n.t('rule._form_modal.no_notify_method').toString()
          })
        })
      };
    }),

    changeset: Ember.computed('rule', function () {
      var validations = this.get('validations');
      return new _emberChangeset.default(this.get('rule'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    _setupUsers: function _setupUsers() {
      var _this = this;

      var customerID = this.get('rule.customerID');
      var userCustomer = this.get('session.data.authenticated.customerID');

      var requests = [this.get('store').query('user', { customerID: customerID }), this.get('store').query('user', { customerID: userCustomer })];

      Ember.RSVP.all(requests, "NotificationThen: Resolving available user details").then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            customerUsers = _ref2[0],
            userUsers = _ref2[1];

        var users = [];
        users.addObjects(customerUsers);

        if (customerID !== userCustomer) {
          users.addObjects(userUsers);
        }

        _this.set('users', users);
      });
    },


    userTokenOptions: Ember.computed('users', function () {
      var users = this.get('users') || [];

      var options = {
        createTokensOnBlur: true,
        allowDuplicates: false
      };

      if (users.get('length')) {
        options.typeahead = [{
          hint: true,
          minLength: 2,
          classNames: { menu: 'tt-dropdown-menu' }
        }, {
          name: 'users',
          displayKey: 'key',
          source: new window.Bloodhound({
            datumTokenizer: function datumTokenizer(d) {
              return [].concat(Bloodhound.tokenizers.whitespace(d.label)).concat(Bloodhound.tokenizers.whitespace(d.email)).concat(Bloodhound.tokenizers.whitespace(d.phone)).concat(Bloodhound.tokenizers.whitespace(d.mobile));
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: users.map(function (user) {
              return {
                value: user.get('id'),
                label: user.get('fullName'),
                email: user.get('emailAddress'),
                phone: user.get('contactPhone'),
                mobile: user.get('contactMobile'),
                key: user.get('fullName') + ' (' + user.get('emailAddress') + ')'
              };
            })
          })
        }];
      }

      return options;
    }),

    actions: {

      // TODO: This shouldn't be necessary after E-C-V 3.0 (Requires Ember 3.13)
      elegantSolution: function elegantSolution() {
        // force clear the validation errors.
        delete this.get('changeset')._errors['filterValue.userTokens'];
        this.get('changeset').validate('filterValue.userTokens');
      }
    }

  });
});