define('ux-pcc/components/charts/easy-pie-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     The `tagName` attribute.
      @property tagName
     @type String
     @default "div"
     */
    tagName: 'div',

    /**
     The `classNames` attribute.
      @property classNames
     @type Array
     @default "easy-pie-chart percentage"
     */
    classNames: ['easy-pie-chart percentage'],

    /**
     The `percent` value that the pie chart should be filled.
      @property percent
     @type int
     @default 100
     */
    percent: 100,

    /**
     The `size` the pie chart should be.
      @property size
     @type int
     @default 50
     */
    size: 50,

    /**
     The `barColor` the easy pie chart should be.
      @property barColor
     @type String
     @default "rgba(255,255,255,0.95)"
     */
    barColor: "rgba(255,255,255,0.95)",

    /**
     The `trackColor` the easy pie chart chart should be.
      @property trackColor
     @type String
     @default "#E2E2E2"
     */
    trackColor: "#E2E2E2",

    /**
     The `inverseChart` the easy pie chart will be working the other direction.
      @property inverseChart
     @type Boolean
     @default false
     */
    inverseChart: false,

    valuesChanged: Ember.observer('percent', function () {
      this.$().data('easyPieChart').update(this.get('percent'));

      var percentDisplay = this.get('percent');

      if (this.get('inverseChart')) {
        percentDisplay = 100 - percentDisplay;
      }

      Ember.$('.percent', this.$()).text(percentDisplay);
    }),

    didInsertElement: function didInsertElement() {
      this._setupEasyPieChart();
    },


    /**
     * Setup the easy pie chart with and add it to the current element
     */
    _setupEasyPieChart: function _setupEasyPieChart() {
      var size = parseInt(this.get('size'));

      var textColor = this.get('barColor');
      var percentDisplay = this.get('percent');

      if (this.get('inverseChart')) {
        textColor = this.get('trackColor');
        percentDisplay = 100 - percentDisplay;
      }

      this.$().attr('data-percent', this.get('percent'));
      this.$().html('<span class="percent" style="font-size:' + size / 4 + 'px;color:' + textColor + ';">' + percentDisplay + '</span>');

      this.$().easyPieChart({
        barColor: this.get('barColor'),
        trackColor: this.get('trackColor'),
        scaleColor: false,
        lineCap: "butt",
        lineWidth: parseInt(size / 7),
        animate: 1000,
        size: size
      });

      this.$().css({
        width: size,
        height: size,
        'line-height': size - 1 + 'px'
      });
    }
  });
});