define('ux-pcc/mixins/rules-advanced-form-controller', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators', 'ux-pcc/mixins/flashable'], function (exports, _emberChangeset, _emberChangesetValidations, _validators, _flashable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create(_flashable.default, {
    rulesAdvanced: Ember.inject.controller('rules-advanced'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    permission: Ember.inject.service(),

    rule: null,
    prices: [],
    products: [],
    productTemplates: [],
    competitiors: [],
    users: [],
    udfValues: [],

    isAlertType: Ember.computed('baseChangeset.ruleType', function () {
      return this.get('baseChangeset.ruleType').match(/^ALERT_/i);
    }),

    isPolicyType: Ember.computed('baseChangeset.ruleType', function () {
      return ['GUARANTEE', 'CANCEL'].includes(this.get('baseChangeset.ruleType'));
    }),

    isTaxableType: Ember.computed('baseChangeset.ruleType', function () {
      return ['PRICE', 'FEE', 'MEAL_PLAN'].includes(this.get('baseChangeset.ruleType'));
    }),

    isTaxType: Ember.computed.equal('baseChangeset.ruleType', 'TAX'),
    isCancelType: Ember.computed.equal('baseChangeset.ruleType', 'CANCEL'),
    isGuaranteeType: Ember.computed.equal('baseChangeset.ruleType', 'GUARANTEE'),
    isNotGuaranteeType: Ember.computed.not('isGuaranteeType'),
    isRestrictionType: Ember.computed.equal('baseChangeset.ruleType', 'RESTRICTION'),
    isDiscountType: Ember.computed.equal('baseChangeset.ruleType', 'BASED_ON_ADJUSTMENT'),

    isOngoing: Ember.computed.empty('baseChangeset.endDate'),

    noTypeSelected: Ember.computed.empty('baseChangeset.ruleType'),
    cannotCreate: Ember.computed('variableChangeset.children.length', 'isGuaranteeType', function () {
      return !this.get('isGuaranteeType') && this.get('variableChangeset.children.length') === 0;
    }),

    currencyCode: Ember.computed('customer.settings.@each.settingValue', function () {
      var model = this.get('customer.settings').findBy('settingCode', 'DEFAULT_CURRENCY');
      if (model && model.get('settingValue')) {
        return model.get('settingValue');
      } else {
        return 'USD';
      }
    }),

    externalCodePrompt: Ember.computed('i18n.locale', 'isGuaranteeType', 'isCancelType', function () {
      return this.get('isGuaranteeType') ? this.i18n.t('rule._form.select_guarantee') : this.i18n.t('rule._form.select_cancel');
    }),

    filteredTypes: Ember.computed('rulesAdvanced.model.types', function () {
      var _this = this;

      var ruleTypes = this.get('rulesAdvanced.model.types');
      var customerPermissionType = this.get('customer.permissionType');

      // Filter out the rule types the user is not allowed to see
      ruleTypes = ruleTypes.filter(function (item /*, index, enumerable*/) {
        return _this.get('permission').isPermitted(customerPermissionType + 'ruletype', 'view', item.type.toLowerCase());
      });
      return ruleTypes;
    }),

    baseValidations: Ember.computed('i18n.locale', function () {
      return {
        ruleType: (0, _validators.validatePresence)(true),
        shortDescription: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rule_name').toString()
        }),
        beginDate: (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_begin_date').toString()
        })
      };
    }),

    variableValidations: Ember.computed('i18n.locale', 'isDiscountType', 'isRestrictionType', function () {

      var validations = {};

      if (!this.isDiscountType && !this.isRestrictionType) {
        validations.externalCode = (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_external_reference').toString()
        });
      } else {
        validations.price = (0, _validators.validatePresence)({
          presence: true,
          message: this.i18n.t('rule._form.missing_rate_code').toString()
        });
      }

      return validations;
    }),

    baseChangeset: Ember.computed('model', 'baseValidations', function () {
      var validations = this.get('baseValidations');
      return new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);
    }),

    variableChangeset: Ember.computed('model', 'variableValidations', function () {
      var validations = this.get('variableValidations');
      var changeset = new _emberChangeset.default(this.get('model'), (0, _emberChangesetValidations.default)(validations), validations);

      changeset.set('children', [].concat(_toConsumableArray(this.get('model.children'))));
      return changeset;
    }),

    rateCodeFilter: function rateCodeFilter(item) {
      return item.get('sellYN');
    },
    discountFilter: function discountFilter(item) {
      return item.get('sellYN') && item.get('basedOnPrice.id');
    },


    actions: {
      save: function save() {
        var _this2 = this;

        var baseChangeset = this.get('baseChangeset');
        var variableChangeset = this.get('variableChangeset');

        Ember.RSVP.all([baseChangeset.validate(), variableChangeset.validate()], 'validating changesets').then(function () {
          // all validations pass
          if (baseChangeset.get('isValid') && variableChangeset.get('isValid')) {
            baseChangeset.execute();
            variableChangeset.execute();
            _this2.send('saveRuleSet');
          }
        }).catch(function () /* e */{
          _this2.send('flash', '<i class=\'fa fa-warning\'></i> ' + _this2.i18n.t('rule._form.save_invalid'), 'danger');
        }).finally(function () {});
      },
      addRule: function addRule() {
        this.set('isEdit', false);
        this.set('rule', {
          ruleMethod: 'RULE',
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          children: []
        });
        if (!this.isDiscountType && !this.isRestrictionType) {
          this.set('rule.amountRule', 'VALUE');
          this.set('rule.calculationRule', 'AMOUNT_NOOP');
        }
        if (this.isDiscountType || this.isRestrictionType || this.isAlertType) {
          this.set('rule.children', [{
            filterType: 'ALL',
            ruleMethod: 'FILTER',
            children: [{
              filterType: 'FIELD_WHERE',
              ruleMethod: 'FILTER'
            }]
          }]);
        }
      },
      editRule: function editRule(rule) {
        this.set('isEdit', true);
        this.set('rule', rule);
      },
      close: function close() {
        this.send('returnOrTransitionTo', 'rules-advanced');
      },
      updateRuleOnSet: function updateRuleOnSet(rule) {
        if (!this.get('isEdit')) {
          this.get('variableChangeset').set('children', [].concat(_toConsumableArray(this.get('variableChangeset.children')), [rule]));
        }
      },
      removeRuleFromSet: function removeRuleFromSet(rule) {
        var children = [].concat(_toConsumableArray(this.get('variableChangeset.children')));
        children.removeObject(rule);
        this.get('variableChangeset').set('children', children);
      },
      updateSelection: function updateSelection() /*condition, isSelected*/{
        // noop for rules advanced
      },
      setPrice: function setPrice(price) {
        this.set('variableChangeset.price', price);
      }
    }
  });
});