define('ux-pcc/components/charts/bubble-chart', ['exports', 'ux-pcc/helpers/wrap-negative', 'ux-components/utils/format-currency', 'ux-pcc/templates/components/charts/bubble-chart'], function (exports, _wrapNegative, _formatCurrency, _bubbleChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bubbleChart.default,

    classNames: ['bubble-chart'],
    tagName: 'div',

    i18n: Ember.inject.service(),

    exportConfig: null,
    sliceResolution: 'day',
    minBulletSize: 10,

    didInsertElement: function didInsertElement() {
      this._createChart();
    },


    _legendObserver: Ember.observer('dataFields.@each.enabled', 'type', function () {
      this._createChart();
    }),

    _config: function _config() {
      var _this = this;

      var data = this.get('_data');
      var dataFields = (this.get('dataFields') || []).filterBy('enabled').filterBy('category');

      var generateGraphs = function generateGraphs() {
        var graphs = [];

        if (_this.get('data.length') > 0) {

          dataFields.forEach(function (graphConfig, i) {
            var graph = {
              title: graphConfig.title ? graphConfig.title : graphConfig.label,
              balloonColor: 'transparent',
              balloonFunction: function balloonFunction(item) {
                return _this._styleBalloon(item, graphConfig.title, graphConfig.groupByValue, graphConfig.legendClass);
              },
              xField: graphConfig.xField,
              yField: graphConfig.yField,
              valueField: graphConfig.valueField,
              xAxis: graphConfig.xField,
              yAxis: graphConfig.yField,
              type: 'line',
              bullet: graphConfig.bulletStyle ? graphConfig.bulletStyle : 'round',
              minBulletSize: _this.get('minBulletSize'),
              maxBulletSize: 100,
              bulletBorderAlpha: 1,
              bulletAlpha: 0,
              bulletBorderThickness: 5,
              classNameField: graphConfig.bulletClass,
              useLineColorForBulletBorder: true,
              id: 'analysis-' + i + ' ' + graphConfig.bulletClass,
              balloon: { fillAlpha: 1 }
            };

            graphs.push(graph);
          });
        }

        return graphs;
      };

      var valueAxes = [{
        id: 'bottom',
        position: 'bottom',
        axisAlpha: 0,
        //minimum: 0,
        //strictMinMax: true,
        minMaxMultiplier: 1.1,
        title: this.get('xAxisTitle')
      }];

      var leftAxis = {
        id: 'left',
        axisAlpha: 0,
        position: 'left',
        minimum: 0,
        //strictMinMax: true,
        gridAlpha: 0,
        title: this.get('yAxisTitle'),
        guides: [{
          id: 'zero-marker',
          value: 0,
          lineAlpha: 1,
          lineThickness: 3,
          above: true,
          inside: true,
          fillColor: '#000000',
          lineColor: '#000000'
        }]
      };

      if (dataFields.length === 1) {
        var yField = dataFields.get('firstObject.yField');
        if (yField) {
          var maximum = data.get('firstObject.' + yField);
          if (maximum && Number(maximum)) {
            leftAxis.strictMinMax = true;
            leftAxis.maximum = Number(maximum) * 1.7;
          }
        }
      } else {
        leftAxis.minMaxMultiplier = 1.7;
      }

      valueAxes.push(leftAxis);

      // set the proper scale for the smallest bubble compared to the largest
      var min = Number.MAX_SAFE_INTEGER;
      var max = Number.MIN_SAFE_INTEGER;
      dataFields.forEach(function (graphConfig) {
        data.forEach(function (item) {
          max = Math.max(max, Number(item[graphConfig.valueField] ? item[graphConfig.valueField] : 0.1));
          min = Math.min(min, Number(item[graphConfig.valueField] ? item[graphConfig.valueField] : 0));
        });
      });

      if (max !== 0 && max !== Number.MIN_SAFE_INTEGER) {
        this.set('minBulletSize', Math.max(Math.abs(Math.round(min / max * 100)), 10));
      }

      var guides = [];

      var config = {
        hideCredits: true,
        type: 'xy',
        addClassNames: true,
        graphs: generateGraphs(),
        categoryField: this.get('categoryField'),
        synchronizeGrid: true,
        valueAxes: valueAxes,
        guides: guides,
        sequencedAnimation: false,
        panEventsEnabled: false,
        trendLines: [],
        allLabels: [],
        titles: [],
        balloon: { fillAlpha: 1, horizontalPadding: 15, verticalPadding: 2, pointerWidth: 0 },
        language: this.get('i18n.locale'),
        dataProvider: data,
        marginLeft: 46,
        marginBottom: 35,
        categoryAxis: {
          color: '#666666',
          axisColor: '#666666',
          axisThickness: 2,
          gridAlpha: 0,
          gridPosition: 'start'
        },
        chartCursor: {
          zoomable: false,
          cursorAlpha: 0,
          cursorColor: 'transparent',
          categoryBalloonEnabled: false
        },
        listeners: [{
          event: 'clickGraphItem',
          method: function method(graphItem) {
            if (_this.get('onGraphClick')) {
              _this.get('onGraphClick')(graphItem.item.dataContext, graphItem.chart.categoryField, graphItem.item.graph.valueField);
            }
          }
        }]
      };

      if (this.get('title')) {
        config.titles.push({ text: this.get('title') });
      }

      if (this.get('exportConfig')) {
        config.export = this.get('exportConfig');
      }

      return config;
    },


    _data: Ember.computed('data', 'dataTransform', function () {
      var data = this.get('data');

      // todo: may want to copy 'data' so mutation doesn't occur
      return this.get('dataTransform')(data);
    }),

    _createChart: function _createChart() {
      var data = this.get('data');
      if (!data || data.length <= 0) {
        return;
      }

      var $chartContainer = Ember.$(this.element).find('.am-chart-container');

      var chart = AmCharts.makeChart($chartContainer[0], this._config());

      this.set('_chart', chart);

      return chart;
    },


    dataTransform: function dataTransform(data) {
      return data;
    },

    dataChanged: Ember.observer('_data', 'type', function () {
      var _this2 = this;

      var chart = this.get('_chart');

      if (!chart) {
        return;
      }

      chart.dataProvider = this.get('_data');

      this._beforeRedrawOnDataChanged();

      Ember.run.scheduleOnce('afterRender', this, function () {
        chart.validateData();
        _this2._afterRedrawOnDataChanged();
      }, 0);
    }),

    _styleBalloon: function _styleBalloon(item, title, groupByValue, className) {
      var _this3 = this;

      if (title && title.toString() !== this.get('showBubbleForGraph')) {
        return '';
      }

      var _generateHTML = function _generateHTML(label, value, divClass) {
        return '<div class=\'' + divClass + '\'>\n                <span class=\'bubble-label\'>' + label + ': </span>\n                ' + value + '\n              </div>';
      };

      var displayHTML = '<div class=\'' + className + '--balloon\'>';
      var dataFields = this.get('balloonFields') || (this.get('dataFields') || []).filterBy('enabled');
      if (groupByValue) {
        dataFields = (dataFields || []).filterBy('item', groupByValue);
      }

      dataFields.forEach(function (dataField) {
        displayHTML += _generateHTML(dataField.label, _this3._bubbleValue(item, dataField.key, dataField.format, dataField.formatHash, dataField.valueClassname), dataField.divClass);
      });
      displayHTML += '</div>';
      return displayHTML;
    },
    _bubbleValue: function _bubbleValue(valueObject, key, format, formatHash, valueClass) {
      var value = valueObject.dataContext[key];
      var spanClass = valueObject.dataContext[valueClass];

      var getNVC = function getNVC(value) {
        if (!Ember.isEmpty(value) && value.toString().indexOf('(') > -1) {
          return 'negative-value-class';
        }

        return '';
      };

      if (format === 'currency') {
        var bubbleValue = (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(value, this.get('currencyCode'), { hash: formatHash }));
        return '<span class=\'bubble-value ' + getNVC(bubbleValue) + ' ' + spanClass + '\'>' + bubbleValue + '</span>';
      } else if (format === 'percent') {
        var _bubbleValue2 = (0, _wrapNegative.wrapNegative)(value, { precision: 1, suffix: '%' });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue2) + ' ' + spanClass + '\'>' + _bubbleValue2 + '</span>';
      } else if (format === 'truncatedNumber') {
        var _bubbleValue3 = (0, _wrapNegative.wrapNegative)(value, { precision: 2 });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue3) + ' ' + spanClass + '\'>' + _bubbleValue3 + '</span>';
      } else if (format === 'number') {
        var _bubbleValue4 = (0, _wrapNegative.wrapNegative)(value, { precision: 0 });
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue4) + ' ' + spanClass + '\'>' + _bubbleValue4 + '</span>';
      } else {
        var _bubbleValue5 = (0, _wrapNegative.wrapNegative)(value);
        return '<span class=\'bubble-value ' + getNVC(_bubbleValue5) + ' ' + spanClass + '\'>' + _bubbleValue5 + '</span>';
      }
    },
    _beforeRedrawOnDataChanged: function _beforeRedrawOnDataChanged() {
      this._createChart();
    }
  });
});