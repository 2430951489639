define('ux-pcc/components/schedule-panel/yearly-form', ['exports', 'ux-components/components/schedule-panel/yearly-form'], function (exports, _yearlyForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _yearlyForm.default;
    }
  });
});