define('ux-pcc/components/sortable-drop-list', ['exports', 'ux-components/components/sortable-drop-list'], function (exports, _sortableDropList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sortableDropList.default;
    }
  });
});