define('ux-pcc/controllers/yield-manager/dashboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    dashboardViews: Ember.inject.controller(),
    dateRange: 1,
    selectedDate: null,
    selectedSlide: null,
    weekOverWeek: false,

    views: Ember.computed.alias('model'),

    actions: {
      openDashboardViews: function openDashboardViews() {
        this.send('showModal', 'dashboard-views', { views: this.get('model') });
      },
      setSlide: function setSlide(viewId) {
        this.set('selectedSlide', viewId);
      },
      addDashboardView: function addDashboardView() {
        var _this = this;

        this.send('showModal', 'dashboard-views', { views: this.get('model') });
        Ember.run.later(function () {
          _this.get('dashboardViews').set('levels', _this.get('countries'));
          _this.get('dashboardViews').send('add');
        }, 300);
      },
      openGridViews: function openGridViews() {
        // Set the calendar picker on the card view back to year over year
        this.set('weekOverWeek', false);
        this.transitionToRoute('yield-manager.dashboard');
      }
    }
  });
});