define('ux-pcc/routes/customer/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    menuService: Ember.inject.service('menu'),
    menus: Ember.computed.alias('menuService.menus'),
    router: Ember.inject.service('-routing'),

    welcomeRoute: 'portfolios',

    _getRoute: function _getRoute(actionUrl) {
      return (actionUrl || '').split('?').get('firstObject');
    },
    _getParameters: function _getParameters(actionUrl) {
      var urlParts = (actionUrl || '').split('?');

      return urlParts.length < 2 ? '' : urlParts.get('lastObject');
    },
    _getQueryStringParams: function _getQueryStringParams(actionUrl) {
      var queryString = this._getParameters(actionUrl);

      if (Ember.isEmpty(queryString)) {
        return null;
      }
      var queryParams = '{"queryParams": {"' + queryString.replace(/=/g, '":"').replace(/&/g, '","') + '"}}';
      return JSON.parse(queryParams);
    },
    _isInternalRoute: function _isInternalRoute(actionUrl) {
      return this.router.hasRoute(this._getRoute(actionUrl));
    },
    _navigateToMenuItem: function _navigateToMenuItem(menuItem, message, customer, transition) {
      var route = menuItem.get('actionUrl');
      var isInternalRoute = this._isInternalRoute(route);
      console.log(message);
      if ('LINK' === menuItem.get('actionType') || !isInternalRoute) {

        var url = 'LINK' === menuItem.get('actionType') ? route : this.deepLinkUrl + '?targetRoute=' + route + '&customerID={{customer.id}}';

        url = url.replace(/{{\w+(\.\w*)*}}/g, function (match) {
          switch (match) {
            case '{{customer.externalReference}}':
              return customer.get('externalReference');
            case '{{customer.id}}':
              return customer.get('id');
          }
        });
        transition.abort();
        window.location = url;
      } else {
        this._transitionTo(route);
      }
    },
    _transitionTo: function _transitionTo(route) {
      var queryStringParams = this._getQueryStringParams(route);
      if (queryStringParams) {
        this.transitionTo(this._getRoute(route), queryStringParams);
      } else {
        this.transitionTo(route);
      }
    },


    deepLinkUrl: Ember.computed('router.rootURL', function () {
      var rootURL = this.router.rootURL;

      if (window.location.hostname === 'localhost') {
        return 'https://localhost:4201/deep-link';
      } else {
        var index = window.location.pathname.replace(rootURL, '/');
        return '/v2' + index + '#/deep-link';
      }
    }),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var customer = this.modelFor('customer');
      var settings = customer.get('settings');
      var menus = this.menus;
      var flatMenus = this.get('menuService.flattenedMenus');

      var selfSignupSetupStep = settings.findBy('settingCode', 'SELF_SIGNUP_SETUP_STEP');

      var defaultLandingPage = null;

      var landingSetting = settings.findBy('settingCode', customer.get('customerType').toUpperCase() + '_DEFAULT_LANDING_ACTION_URL');
      if (landingSetting) {
        defaultLandingPage = flatMenus.findBy('reference', landingSetting.get('settingValue'));
      }

      var loginProgram = flatMenus.findBy('reference', this.get('session.data.authenticated.loginProgram'));

      /**
       * After logging in, redirect the user to one of the following:
       * 1) The self sign up wizard, if SELF_SIGNUP_SETUP_STEP is present
       * 2) The loginProgram attribute of the user session, if found in the current list of menus
       * 3) The default landing page, if set for the current customer
       * 4) The first menu item, if it has an actionUrl
       * 5) This first child menu item of the first menu item, if the first menu item has children
       * 6) Otherwise, fall back to the route defined by welcomeRoute
       */
      if (selfSignupSetupStep && selfSignupSetupStep.get('settingValue') !== 'DONE') {
        window.location = this.deepLinkUrl + '?targetRoute=setup&customerID=' + customer.get('id');
      } else if (loginProgram) {
        this._navigateToMenuItem(loginProgram, 'Login program was set (and found) w/ value \'' + this.get('session.data.authenticated.loginProgram') + '\'', customer, transition);
      } else if (defaultLandingPage) {
        this._navigateToMenuItem(defaultLandingPage, 'Setting for default landing page found w/ value \'' + defaultLandingPage.get('defaultLandingPage') + '\'', customer, transition);
      } else if (menus.get('length') > 0 && menus.get('firstObject.actionUrl.length') > 0) {
        var menuItem = menus.get('firstObject');
        this._navigateToMenuItem(menuItem, 'Transitioning to first menu item w/ value \'' + menuItem.get('actionUrl') + '\'', customer, transition);
      } else if (menus.get('length') > 0 && menus.get('firstObject.children.length') > 0) {
        var children = menus.get('firstObject.children').sortBy('orderBy');
        var _menuItem = children.get('firstObject');
        this._navigateToMenuItem(_menuItem, 'Transitioning to first child menu item w/ value \'' + _menuItem.get('actionUrl') + '\'', customer, transition);
      } else {
        console.warn('Initial route could not be determined.  Falling back to \'' + this.welcomeRoute + '\' route.');
        this._transitionTo(this.welcomeRoute);
      }
    }
  });
});