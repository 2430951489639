define('ux-pcc/routes/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, error) {

      var e = Ember.get(error, 'errors.firstObject') || error;

      // if (window.newrelic && window.newrelic.noticeError && e.message) {
      //   let err = new Error(e.message);
      //   window.newrelic.noticeError(err, e.details);
      // }

      var status = e.status || 500;
      controller.set('status', status);
      if (!e.detail || e.detail.toString().indexOf('DOCTYPE') >= 0) {
        // generic message
      } else {
        controller.set('message', e.detail);
      }
    }
  });
});