define('ux-pcc/components/training/resource-modal', ['exports', 'ux-components/components/training/resource-modal'], function (exports, _resourceModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _resourceModal.default;
    }
  });
});