define("ux-pcc/helpers/card-data-window-classes/screen", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    var CardDataWindowScreen = function () {
        function CardDataWindowScreen() {
            _classCallCheck(this, CardDataWindowScreen);

            this.rows = [];
        }

        _createClass(CardDataWindowScreen, [{
            key: "addRow",
            value: function addRow(row) {
                this.rows.push(row);
            }
        }, {
            key: "getRows",
            value: function getRows() {
                return this.rows;
            }
        }]);

        return CardDataWindowScreen;
    }();

    exports.default = CardDataWindowScreen;
});