define('ux-pcc/helpers/to-boolean', ['exports', 'ux-components/helpers/to-boolean'], function (exports, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _toBoolean.default;
    }
  });
  Object.defineProperty(exports, 'toBoolean', {
    enumerable: true,
    get: function () {
      return _toBoolean.toBoolean;
    }
  });
});