define('ux-pcc/components/comment-card', ['exports', 'ux-components/components/comment-card'], function (exports, _commentCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _commentCard.default;
    }
  });
});