define('ux-pcc/routes/customer/sister-properties', ['exports', 'moment', 'accounting'], function (exports, _moment, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),

    beforeModel: function beforeModel() {

      // If this is a sister property, redirect to the dashboard
      var customer = this.controllerFor('customer').set('model', this.modelFor('customer'));
      if (this.controllerFor('customer').get('isSisterProperty')) {
        this.transitionTo('/customers/' + customer.get('id'));
      }
    },
    model: function model() {
      var customer = this.modelFor('customer');
      var tz = this.tz.get("customer");

      var beginDate = _moment.default.tz(tz).startOf('day').format();

      var bar = this.get('ajax').request('/rest/v1/analyticEntries', {
        data: {
          channelcode: 'ALL',
          begindate: beginDate,
          enddate: _moment.default.tz(beginDate, tz).endOf('day').format(),
          frequency: 'DAY',
          customerid: customer.get('id'),
          contenttype: 'SELL_RATE',
          analytictype: 'AMOUNT',
          action: 'STATISTIC',
          datatype: 'VALUE',
          rollupstatus: 'DETAIL',
          fields: 'id,beginDate,endDate,description,dataValue,contentType,subContentType'
        }
      }).then(function (_ref) {
        var _ref$analyticEntries = _ref.analyticEntries,
            analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

        return analyticEntries;
      });

      return Ember.RSVP.hash({
        bar: bar,
        properties: this.get('ajax').request('/rest/priceBasedOn', {
          data: {
            customerID: customer.get('id')
          }
        }).then(function (_ref2) {
          var _ref2$priceBasedOnCus = _ref2.priceBasedOnCustomers,
              priceBasedOnCustomers = _ref2$priceBasedOnCus === undefined ? [] : _ref2$priceBasedOnCus;

          return priceBasedOnCustomers;
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.properties ? model.properties : []);

      if (model.bar && model.bar.findBy('subContentType', 'PUBLIC')) {
        var barValue = Ember.get(model.bar.findBy('subContentType', 'PUBLIC'), 'dataValue');
        controller.set('currentBar', _accounting.default.formatNumber(barValue, 2));
      } else {
        controller.set('currentBar', '0');
      }
    },


    actions: {
      saveSisterProperty: function saveSisterProperty(property) {
        var _this = this;

        var parent = this.modelFor('customer');
        var child = property.customer;
        var successMessage = void 0;

        var name = Ember.get(child, 'externalReference') + ' - ' + Ember.get(child, 'name');

        // Swap the success message based on edit or new
        if (property.isEdit) {
          successMessage = this.get('i18n').t('customer.sister-properties.modal._success_modal.success_edit_message', {
            propertyName: name
          });
        } else {
          successMessage = this.get('i18n').t('customer.sister-properties.modal._success_modal.success_message', {
            propertyName: name
          });
        }

        // Save the record
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('ajax').post("/rest/priceBasedOn", {
            type: "POST",
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
              customerID: parent.get('id'),
              childID: property.customerId,
              amount: !property.adjustment || isNaN(property.adjustment) ? '0' : property.adjustment
            })
          }).then(function (data) {
            resolve(data);
          }).catch(function (xhr) {
            // check for conflict status due to existing link
            if (409 === xhr.status) {
              reject(_this.get('i18n').t('customer.sister-properties.message.conflict_message', {
                propertyName: name
              }));
            } else {
              reject(xhr.statusText);
            }
          });
        }, "PriceBasedOn: Create/Update PRICE_BASEDON record");

        promise.then(function () {
          _this.get('dialog').showSuccess({
            titleText: _this.get('i18n').t('customer.sister-properties.modal._success_modal.title'),
            messageText: successMessage,
            confirmText: _this.get('i18n').t('customer.sister-properties.modal._success_modal.button_label'),
            onConfirm: function onConfirm() {
              _this.refresh();
            }
          });
        }).catch(function (error) {
          _this.send('flash', "<i class='fa fa-warning'></i> " + error, 'danger');
        });
      },
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});