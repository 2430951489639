define('ux-pcc/routes/portfolios', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),

    model: function model() {
      var customer = this.modelFor('customer');
      return this.store.query('customer', {
        folderid: customer.get('id'),
        customertype: 'CUSTOMERS',
        include: 'settings,xref,settingCode.MANAGERS',
        limit: 1500
        // fields: 'id,name,settings,children,xrefCount,settingCode,settingValue,managers'
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      },
      confirmDelete: function confirmDelete(portfolio) {
        var _this = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('portfolios.list.index.messages.discard_portfolio'),
          messageText: this.i18n.t('portfolios.list.index.messages.warning_message', {
            portfolioName: portfolio.get('name')
          }) + ' ' + this.i18n.t('portfolios.list.index.messages.proceed'),
          confirmText: this.i18n.t('portfolios.list.index.messages.continue'),
          cancelText: this.i18n.t('portfolios.list.index.messages.cancel'),
          onConfirm: function onConfirm() {

            if (portfolio) {

              portfolio.get('model').destroyRecord().then(function () /* results */{
                // Show the success modal
                _this.dialog.showSuccess({
                  titleText: _this.i18n.t('portfolios.list.delete_success_modal.title'),
                  messageText: _this.i18n.t('portfolios.list.delete_success_modal.success_message', { portfolioName: portfolio.get('name') }),
                  confirmText: _this.i18n.t('portfolios.list.delete_success_modal.button_label'),
                  onConfirm: function onConfirm() {
                    _this.refresh();
                    _this.dialog.close();
                  }
                });
              }).catch(function (error) {
                _this.send('flash', '<i class=\'fa fa-warning\'></i> ' + error, 'danger');
              });
            }
          },
          onCancel: function onCancel() {
            _this.dialog.close();
          }
        });
      },
      confirmUnlink: function confirmUnlink(parent, child) {
        var _this2 = this;

        this.dialog.showConfirmation({
          titleText: this.i18n.t('portfolios.list.index.messages.confirm_remove'),
          messageText: this.i18n.t('portfolios.list.index.messages.removal_warning_message', {
            propertyName: child.get('name'),
            portfolioName: parent.get('name')
          }) + ' ' + this.i18n.t('portfolios.list.index.messages.removal_proceed'),
          confirmText: this.i18n.t('portfolios.list.index.messages.removal_continue'),
          cancelText: this.i18n.t('portfolios.list.index.messages.removal_cancel'),
          onConfirm: function onConfirm() {

            if (parent && child) {

              // Remove deleted xref
              var url = '/rest/v1/customerXrefs/customer/' + parent.get('id') + '/xrefcustomer/' + child.get('id') + '/type/LINK';
              _this2.ajax.delete(url).then(function () /* data */{
                // Show the success modal
                _this2.dialog.showSuccess({
                  titleText: _this2.i18n.t('portfolios.list.unlink_success_modal.title'),
                  messageText: _this2.i18n.t('portfolios.list.unlink_success_modal.success_message', { propertyName: child.get('name') }),
                  confirmText: _this2.i18n.t('portfolios.list.unlink_success_modal.button_label'),
                  onConfirm: function onConfirm() {
                    parent.model.get('xrefs').removeObject(child);
                    _this2.refresh();
                    _this2.dialog.close();
                  }
                });
              }).catch(function (error) {
                _this2.send('flash', '<i class=\'fa fa-warning\'></i> ' + error.responseText, 'danger');
              });
            }
          },
          onCancel: function onCancel() {
            _this2.dialog.close();
          }
        });
      },
      refreshPropertyList: function refreshPropertyList() {
        var parent = this.get('parentPortfolio');
        var property = this.get('propertyToRemove');

        parent.model.get('xrefs').removeObject(property);
        this.refresh();
      }
    }
  });
});