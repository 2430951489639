define('ux-pcc/controllers/rule-rates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    defaultPriceCode: Ember.computed('model.parameters', function () {
      var price = this.get('model.parameters' || []).findBy('parameterName', 'DEFAULT_PRICE_SETTING_CODE');
      if (price) {
        return price.get('parameterValue');
      } else {
        return 'RACK';
      }
    })

  });
});