define('ux-pcc/routes/rules-advanced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service('session'),

    model: function model() {
      var _this = this;

      var customer = this.modelFor('customer');
      var userCustomer = this.get('session.data.authenticated.customerID');

      // Request the current channel by code, and get it's children and grandchildren
      var requests = [this.store.query('price', { customerID: customer.get('id'), fields: 'id,shortDescription,parentID,externalReference' }), this.store.query('product', { customerID: customer.get('id'), productUsageType: "AVAILABILITY", fields: 'id,shortDescription,externalReference' }), this.store.query('rule', { customerID: customer.get('affiliateCustomer.id'), include: 'children' }),
      // todo: get from service
      Ember.RSVP.resolve([{
        type: 'PRICE',
        shortDescription: 'Price Calculation',
        facts: ["OCC_PCT", "LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "SELL_RATE", "LOYALTY", "SOLD_BY_PRODUCT"],
        subjects: [{ basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }, { basis: "CLOSE", actions: ["AMOUNT_NOOP"] }, { basis: "OPEN", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'FEE',
        shortDescription: 'Fee Calculation',
        facts: ["LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY", "PRODUCT_TEMPLATE", "TRANSACTION_CODE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'TAX',
        shortDescription: 'Tax Calculation',
        facts: ["LOS", "PRODUCT", "PRICE", "PRODUCT_TEMPLATE", "TRANSACTION_CODE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'GUARANTEE',
        shortDescription: 'Guarantee Policy',
        facts: ["LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY", "TRANSACTION_CODE"],
        subjects: []
      }, {
        type: 'CANCEL',
        shortDescription: 'Cancel Policy',
        facts: ["OCC_PCT", "LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY", "PRODUCT_TEMPLATE", "TRANSACTION_CODE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'DEPOSIT',
        shortDescription: 'Deposit Policy',
        facts: ["OCC_PCT", "LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY", "PRODUCT_TEMPLATE", "TRANSACTION_CODE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX_FEE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX_FEE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING_INCL_FEE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'NO_SHOW',
        shortDescription: 'No-Show Policy',
        facts: ["OCC_PCT", "LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY", "PRODUCT_TEMPLATE", "TRANSACTION_CODE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'MEAL_PLAN',
        shortDescription: 'Meal Plan Policy',
        facts: ["LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE", "LOYALTY"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'OVERSELL',
        shortDescription: 'Oversell Calculation',
        facts: ["OCC_PCT", "LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_ADD", "AMOUNT_SUBTRACT", "AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'ACCOUNTING',
        shortDescription: 'Accounting',
        facts: ["LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'COMMISSION',
        shortDescription: 'Commission',
        facts: ["LOS", "ARRIVAL_DATE", "BOOKING_DATE", "PRODUCT", "PRICE", "BOOKING_SOURCE"],
        subjects: [{ basis: "FIRST_SLICE", actions: ["AMOUNT_PERCENT"] }, { basis: "FIRST_SLICE_INCL_TAX", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL_INCL_TAX", actions: ["AMOUNT_PERCENT"] }, { basis: "TOTAL_REMAINING", actions: ["AMOUNT_PERCENT"] }, { basis: "FIXED_AMOUNT", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_STAY", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_PERSON_PER_INTERVAL", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_STAY", actions: ["AMOUNT_PERCENT"] }, { basis: "PER_ROOM_PER_INTERVAL", actions: ["AMOUNT_PERCENT"] }, { basis: "FLAT_PRICE", actions: ["AMOUNT_PERCENT"] }, { basis: "VALUE", actions: ["AMOUNT_NOOP"] }]
      }, {
        type: 'BASED_ON_ADJUSTMENT',
        shortDescription: 'Discount',
        facts: ['SOLD_BY_PRICE', 'OCC_PCT', 'EVENT_DATE', 'EVENT_TIME'],
        subjects: []
      }, {
        type: 'RESTRICTION',
        shortDescription: 'Restriction',
        facts: ['SOLD_BY_PRICE', 'OCC_PCT', 'EVENT_DATE', 'EVENT_TIME'],
        subjects: []
      }, {
        type: 'ALERT_COMP',
        shortDescription: 'Competition Alert',
        facts: ["COMPETITOR", "COMP_STATUS", "CHANNEL", "SELL_RATE", "EVENT_DATE"],
        subjects: []
      }, {
        type: 'ALERT_OCC',
        shortDescription: 'Occupancy Alert',
        facts: ["OCC_PCT", "FORECAST_OCC", "STLY_OCC", "EVENT_DATE"],
        subjects: []
      }, {
        type: 'ALERT_INV',
        shortDescription: 'Inventory Alert',
        facts: ["OOO_INV", "MGR_HOLD_INV", "CRS_FREEZE_INV", "GROUP_REL_INV", "GROUP_REL_INV_PCT", "EVENT_DATE"],
        subjects: []
      }, {
        type: 'ALERT_PRICE',
        shortDescription: 'Pricing Alert',
        facts: ["SELL_RATE", "EVENT_DATE"],
        subjects: []
      }, {
        type: 'ALERT_PERF',
        shortDescription: 'Performance Alert',
        facts: ["OTB_RES", "ADR_VS_STLY", "REVPAR_VS_STLY", "ADR_VS_STLY_PCT", "REVPAR_VS_STLY_PCT", "INDEX_ADR", "INDEX_OCC", "INDEX_REVPAR", "INDEX_4WEEK_ADR", "INDEX_4WEEK_OCC", "INDEX_4WEEK_REVPAR", "EVENT_DATE"],
        subjects: []
      }, {
        type: 'ALERT_RESV',
        shortDescription: 'Reservation Alert',
        facts: ["PRODUCT", "PRICE", "AMOUNT", "AMOUNT_FROM_PRICE", "TOTAL_AMOUNT", "TOTAL_AMOUNT_FROM_PRICE", "BOOKING_WINDOW", "EVENT_DATE", "ARRIVAL_DATE", "BOOKING_DATE"],
        subjects: []
      }]), this.store.query('user', {
        customerID: customer.get('id')
      }), this.store.query('user', {
        customerID: userCustomer
      }), this.store.query('customer', {
        folderid: customer.get("id"),
        customertype: 'COMPETITORS'
      }).then(function (folders) {
        if (folders.get('length') > 0) {
          return _this.store.query('customer', { folderid: folders.get("firstObject.id"), customertype: 'COMPETITOR' });
        } else {
          return [];
        }
      }), this.store.query('product-template', { customerID: customer.get('id') }), this.store.query('code', { customerID: customer.get('id'), codeType: "CHANNEL_PRICE_CATEGORY" }), this.store.query('code', { customerID: customer.get('id'), codeType: "PRODUCT_CATEGORY" }), this.store.query('currency', { customerID: customer.get('id') }), this.store.query('code', { customerID: customer.get('id'), codeType: "MARKET_SEGMENT" })];

      return Ember.RSVP.all(requests, "RulesRoute: Resolving rules structure").then(function (data) {

        var users = [];
        users.addObjects(data[4]);

        if (customer.get('id') !== userCustomer) {
          users.addObjects(data[5]);
        }

        return Ember.Object.create({
          prices: data[0].sortBy("shortDescription"),
          products: data[1].sortBy("shortDescription"),
          templateRules: data[2].map(function (e) {
            // XXX Group category should be on model
            if (e.get('ruleType').match(/^ALERT_/i)) {
              e.set('group', "Notification");
            } else {
              e.set('group', "General");
            }

            return e;
          }).sortBy('group', 'shortDescription'),
          types: data[3].map(function (e) {
            if (e.type.match(/^ALERT_/i)) {
              e.group = "Notification";
            } else {
              e.group = "General";
            }

            return e;
          }).sortBy('group', 'shortDescription'),
          users: users,
          competitors: data[6].sortBy('name'),
          productTemplates: data[7].sortBy("shortDescription"),
          priceCategories: data[8].sortBy("shortDescription"),
          productCategories: data[9].sortBy("shortDescription"),
          currencies: data[10].sortBy("currencyCode", "shortDescription"),
          marketSegments: data[11].sortBy("shortDescription")
        });
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});