define('ux-pcc/utils/load-extra-moment-locales', ['exports', 'ux-components/utils/load-extra-moment-locales'], function (exports, _loadExtraMomentLocales) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadExtraMomentLocales.default;
    }
  });
});