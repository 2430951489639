define('ux-pcc/mixins/routes/customer/performance', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    queryParams: {
      beginDate: {
        refreshModel: true
      },
      endDate: {
        refreshModel: true
      }
    },

    setupController: function setupController(controller, model) {
      // Set the controller begin and end dates
      var tz = this.tz.customer;
      var beginDate = controller.beginDate ? _moment.default.tz(controller.beginDate, tz) : _moment.default.tz(tz);
      var endDate = void 0;

      if (controller.endDate) {
        endDate = _moment.default.tz(controller.endDate, tz);
        // Check to make sure the endDate is > the beginDate
        if (endDate.isBefore(beginDate)) {
          endDate = beginDate.clone();
        }

        controller.set('endDate', endDate.startOf('day').format());
      } else {
        controller.set('endDate', beginDate.clone().startOf('day').format());
      }

      controller.set('beginDate', beginDate.startOf('day').format());

      this._super(controller, model);
    }
  });
});