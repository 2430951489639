define('ux-pcc/routes/customer/performance/dashboard', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      startDate: {
        refreshModel: true
      }
    },

    i18n: Ember.inject.service(),
    store: Ember.inject.service(),

    titleToken: function titleToken() {
      // TODO Bring labels inline with standards
      return this.i18n.t('customer.index.overview');
    },
    model: function model() {
      var customerModel = this.modelFor('customer');
      return Ember.RSVP.hash({
        rollupProducts: this.store.query('product', {
          customerID: customerModel.id,
          productUsageType: 'INVENTORY_ROLLUP',
          fields: 'externalReference,id,orderBy,productTemplateID,shortDescription'
        }).then(function (results) {
          return results.sortBy('orderBy');
        }),
        products: this.store.query('product', {
          customerID: customerModel.id,
          productUsageType: 'AVAILABILITY',
          productTemplateID: '10a00000-0000-0000-0000-000000000000',
          include: 'ancestors'
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      // If the start date was set, format it
      if (controller.get('startDate')) {
        var startDate = _moment.default.tz(controller.get('startDate'), this.tz.customer).startOf('day');
        controller.set('selectedDate', startDate);
        controller.set('startDate', startDate.format());
      } else {
        controller.set('selectedDate', _moment.default.tz(this.tz.customer).startOf('day'));
        controller.set('startDate', '');
      }
    }
  });
});