define('ux-pcc/components/yield-manager/reservation-list', ['exports', 'moment', 'ux-components/utils/stringify-query-params', 'ux-pcc/templates/components/yield-manager/reservation-list', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/serialize-dashboard-view'], function (exports, _moment, _stringifyQueryParams, _reservationList, _dateFormatter, _serializeDashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _reservationList.default,

    ajax: Ember.inject.service(),
    csv: Ember.inject.service(),
    store: Ember.inject.service(),

    filteredReservations: null,
    isMobile: Ember.computed.bool('deviceType.mobile'),
    loading: false,
    reservations: null,
    sort: null,
    searchText: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('reservations', []);
      this.set('filteredReservations', []);
      this.set('sort', ['bookDate']);
      this.processSuggestions();
    },


    formatRevenueOptions: Ember.computed('model.formatRevenueOptions', function () {
      return Ember.assign({}, this.get('model.formatRevenueOptions'), { precision: 2 });
    }),

    limit: Ember.computed('model.limit', function () {
      return this.get('model.limit') || 50;
    }),

    brands: Ember.computed('model.customerID', function () {
      return this.get('store').query('code', {
        customerID: this.get('model.customerID'),
        codeType: 'BRAND',
        systemYN: 'Y'
      });
    }),

    bloodhound: Ember.computed('reservations.[]', function () {
      var objects = this.get('reservations') || [];

      // constructs the suggestion engine
      return new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return [].concat(Bloodhound.tokenizers.whitespace(d.externalReservationNumber)).concat(Bloodhound.tokenizers.whitespace(d.destinationCustomerExternalReference)).concat(Bloodhound.tokenizers.whitespace(d.originCustomerExternalReference)).concat(Bloodhound.tokenizers.whitespace(d.externalProduct)).concat(Bloodhound.tokenizers.whitespace(d.accessKey)).concat(Bloodhound.tokenizers.whitespace(d.externalPriceCode));
        },
        // Allow for the override of bloodhound suggestion limit
        limit: this.get('limit'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: objects.map(function (object) {
          return object;
        })
      });
    }),

    _suggestionsObserver: Ember.observer('searchText', 'reservations.[]', function () {
      Ember.run.once(this, 'processSuggestions');
    }),

    processSuggestions: function processSuggestions() {
      var _this = this;

      if (this.get('searchText') !== '') {
        var bloodhound = this.get('bloodhound');
        bloodhound.search(this.get('searchText'), function (suggestions) {
          return _this.set('filteredReservations', suggestions);
        });
      } else {
        this.set('filteredReservations', this.get('reservations'));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.set('loading', true);
      var tz = this.get('model.tz');

      var params = {
        aggregationType: 'RESERVATION_DETAIL_AGGREGATOR'
      };

      this.ajax.post('/rest/v1/analyticEntryAggregation/process' + (0, _stringifyQueryParams.default)(params), {
        contentType: 'application/json',
        data: (0, _serializeDashboardView.default)(this.get('model.view'), tz)
      }).then(function (_ref) {
        var _ref$collections = _ref.collections,
            collections = _ref$collections === undefined ? [] : _ref$collections;

        // if no data than return
        if (collections.get('length') === 0) {
          _this2.set('reservations', []);
        } else {
          _this2._fillReservations(collections.map(function (res) {
            return res.dataValues;
          }));
        }
      }).finally(function () {
        return _this2.set('loading', false);
      });
    },
    _fillReservations: function _fillReservations(results) {
      var _this3 = this;

      if (results.get('length') > 0) {
        var max = results.get('length');
        var step = 100;
        var index = 0;

        var chunkData = function chunkData() {
          index += step;

          if (max >= index) {
            Ember.run.later(chunkData, 100);
          }

          var reservations = _this3.get('reservations');
          if (!_this3.get('isDestroyed') && !_this3.get('isDestroying')) {
            _this3.set('reservations', reservations.concat(results.splice(0, step)));
          }
        };

        Ember.run.scheduleOnce('afterRender', chunkData);

        this.set('reservations', results.splice(0, step));
      }
    },


    actions: {
      sort: function sort(key, ascending) {
        if (ascending) {
          this.set('sort', [key]);
        } else {
          this.set('sort', [key + ':desc']);
        }
      },
      closeModal: function closeModal() {
        this.closeModal();
      },
      exportCsv: function exportCsv() {
        var _this4 = this;

        var settings = this.get('model.customer.settings');
        var brands = this.get('brands') || [];

        // CSV headers
        var data = [[this.i18n.t('yield-manager.reservations.reservation_no').toString(), this.i18n.t('yield-manager.reservations.export.book_date').toString(), this.i18n.t('yield-manager.reservations.export.book_time').toString(), this.i18n.t('yield-manager.reservations.export.check_out').toString(), this.i18n.t('yield-manager.reservations.export.check_in').toString(), this.i18n.t('yield-manager.reservations.export.pickup').toString(), this.i18n.t('yield-manager.reservations.export.pickup_time').toString(), this.i18n.t('yield-manager.reservations.export.return').toString(), this.i18n.t('yield-manager.reservations.export.return_time').toString(), this.i18n.t('yield-manager.reservations.export.reserved').toString(), this.i18n.t('yield-manager.reservations.brand').toString(), this.i18n.t('yield-manager.reservations.account').toString(), this.i18n.t('yield-manager.reservations.export.days_earned').toString(), this.i18n.t('yield-manager.reservations.export.total_revenue').toString(), this.i18n.t('yield-manager.reservations.export.rev_per_day').toString(), this.i18n.t('yield-manager.reservations.export.rate').toString(), this.i18n.t('yield-manager.reservations.export.status').toString()]];

        var getStatus = function getStatus(status) {
          var statusCode = ['BOOK', 'MODIFY', 'CANCEL'].indexOf(status) > -1 ? status.toLowerCase() : 'other';
          return _this4.i18n.t('yield-manager.reservations.status.' + statusCode).toString();
        };

        // CSV data rows
        (this.get('filteredReservations') || []).forEach(function (reservation) {
          var localCreationHour = _moment.default.tz(reservation.localCreationHour, 'HH:mm', 'UTC').format('hh:mm A');
          var localBeginHour = _moment.default.tz(reservation.localBeginHour, 'HH:mm', 'UTC').format('hh:mm A');
          var localCheckoutHour = _moment.default.tz(reservation.localCheckoutHour, 'HH:mm', 'UTC').format('hh:mm A');

          var localCreationDate = (0, _dateFormatter.dateFormatter)(reservation.localCreationDate, { settings: settings });
          var localBeginDate = (0, _dateFormatter.dateFormatter)(reservation.localBeginDate, { settings: settings });
          var localCheckoutDate = (0, _dateFormatter.dateFormatter)(reservation.localCheckoutDate, { settings: settings });

          var brand = reservation.brand || '';
          if (brand.indexOf('BRAND_') > -1) {
            brand = brand.replace('BRAND_', '');
          }

          var brandDescription = brands.findBy('codeValue', brand) ? brands.findBy('codeValue', brand).get('shortDescription') : '';
          data.push([reservation.externalReservationNumber, localCreationDate, localCreationHour, reservation.destinationCustomerExternalReference, reservation.originCustomerExternalReference, localBeginDate, localBeginHour, localCheckoutDate, localCheckoutHour, reservation.externalProduct, brandDescription, reservation.accessKey, reservation.reservationDuration, reservation.totalAmount, reservation.amount, reservation.externalPriceCode, getStatus(reservation.status)]);
        });

        // Export data to CSV
        var filename = this.i18n.t('yield-manager.file-prefix.ysga.reservations.title') + '_' + (0, _dateFormatter.dateFormatter)(this.get('model.beginDate'), { settings: settings }) + '_' + (0, _dateFormatter.dateFormatter)(this.get('model.endDate'), { settings: settings });
        this.csv.export(data, { fileName: filename + '.csv', withSeparator: false });
      }
    }
  });
});