define('ux-pcc/components/mobile-date-select', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    displayDate: null,
    tz: null,

    mobilePickerOptions: Ember.computed('i18n.locale', function () {
      return {
        useCurrent: false
      };
    }),

    isToday: Ember.computed('displayDate', function () {
      var today = _moment.default.tz(this.get('tz')).startOf("day");
      var displayedDate = _moment.default.tz(this.get('displayDate'), this.get('tz')).startOf('day');
      return today.isSame(displayedDate);
    }),

    actions: {
      left: function left() {
        // Subtract one day from the displayed date
        var newDate = _moment.default.tz(this.get('displayDate'), this.get('tz')).startOf('day');
        this.set('displayDate', newDate.subtract(1, 'day').format());
      },
      right: function right() {
        // Add one day to the displayed date
        var newDate = _moment.default.tz(this.get('displayDate'), this.get('tz')).startOf('day');
        this.set('displayDate', newDate.add(1, 'day').format());
      }
    }
  });
});