define('ux-pcc/mixins/queued-query-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // Override in implementation to fetch the data to be displayed when component enters viewport
    queryDataMethod: null,

    // Override if not using loading as your in progress flag
    isLoadingFlagName: 'loading',

    // Set to true to ignore queue wait, and just query
    disableQueuedLoad: false,

    // Set to position it should be run in
    queuePosition: 0,

    // pass in the number serving
    currentQueuedJob: null,

    _fetchData: function _fetchData() {
      var _this = this;

      if (typeof this.queryDataMethod === 'function') {
        this.set(this.get('isLoadingFlagName'), true);

        return this.queryDataMethod().finally(function () {
          if (!_this.get('isDestroyed') && !_this.get('isDestroying')) {
            _this.set(_this.get('isLoadingFlagName'), false);
            // Only update the completion flag if queue load is enabled
            if (!_this.get('disableQueuedLoad')) {
              _this.get('completeAction')();
            }
          }
        });
      }
    },


    _currentlyServingObserver: Ember.on('init', Ember.observer('currentQueuedJob', 'disableQueuedLoad', function () {
      if (this.get('currentQueuedJob') === this.get('queuePosition') || this.get('disableQueuedLoad')) {
        this._fetchData();
      } else if (this.get('currentQueuedJob') < this.get('queuePosition')) {
        this.set(this.get('isLoadingFlagName'), true);
      }
    }))
  });
});