define('ux-pcc/services/temp-storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('store', {});
    },
    setValue: function setValue(key, value) {
      this.set('store.' + key, value);
    },
    getValue: function getValue(key) {
      return this.get('store.' + key);
    }
  });
});