define('ux-pcc/routes/yield-manager/analyze', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      view: {
        refreshModel: true
      },
      selectedDate: {
        refreshModel: false
      },
      selectedEndDate: {
        refreshModel: false
      }
    },

    ajax: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (transition.queryParams.selectedDate) {
        this.set('selectedDate', transition.queryParams.selectedDate);
      }
      if (transition.queryParams.selectedEndDate) {
        this.set('selectedEndDate', transition.queryParams.selectedEndDate);
      }
    },
    _levelByCustomerType: function _levelByCustomerType(customer) {
      switch (customer.get('customerType')) {
        case customer.Type.PROPERTY:
          return 'LEVEL_STATION';
        default:
          return '';
      }
    },
    model: function model(params) {
      var _this = this;

      var customer = this.modelFor('customer');
      var view = null;
      if (params.view === 'MBS') {
        var tagList = (customer.get('tags') || []).filter(function (tag) {
          return tag.startsWith('LEVEL_');
        }).map(function (tag) {
          return { label: tag };
        });

        var settings = customer.get('settings');
        var defaultDestinationStatusSetting = settings.findBy('settingCode', 'PCC.ADVANCED_ANALYTICS_FILTER_DESTINATION_STATUS_DEFAULT');
        var defaultDestinationStatus = defaultDestinationStatusSetting ? defaultDestinationStatusSetting.get('settingValue') : '';

        var model = {
          name: customer.get('externalReference'),
          dashboardViewParameters: Ember.Object.create({
            'DATA_TYPE': 'MBS',
            'DISPLAY': 'WEEK_TO_DATE',
            'TM_VS_GROSS': 'TM',
            'DATE_SYNC': 'MONTH',
            'DATA_VIEW': 'PERCENT',
            // ES-2980: Remove this for EHI
            // 'DATE_RANGE': '1',
            // 'RATE_OF_ADD': '-1',
            'DATE_COMPARE': 'YEAR',
            // ES-2980: Remove this for EHI
            // 'DAY_OF_WEEK': 'ALL',
            'REPORTING_TIME_PERIOD': '',
            'LEVEL_HIERARCHY': tagList.length > 0 ? tagList[0].label : this._levelByCustomerType(customer),
            'LEVEL': customer.get('id'),
            'CORPORATE': 'CORPORATE',
            'LOCATION_TYPE': '',
            'DIVISION': '',
            'BRAND_CODE': '',
            'AGREEMENT': '',
            'CHANNEL': '',
            'SUB_CHANNEL': '',
            'BOOK_SRC_COUNTRY': '',
            'BOOK_SRC_ACCOUNT': '',
            'LENGTH_OF_RENT': '',
            'DESTINATION_STATUS': defaultDestinationStatus,
            'PRICE_CODE': '',
            'RETURN_TYPE': 'ALL',
            'PRODUCT_CODE': '',
            'PRODUCT_BLUEPRINT_COUNT': 0,
            // Not sure if this is selectable on Analysis so hide for now
            //'SUB_PRODUCT_CODE': ''},
            'SOURCE_LOCATION_CODE': 'RESERVATION',
            'FULFILLMENT_STATUS': '',
            'ANALYZE_BREADCRUMB_HOME': 'customer.monthly-booking-summary'
          })
        };

        view = Ember.RSVP.resolve(this.get('store').createRecord('dashboard-view', model));
        // make a deep copy of the original parameters to facilitate reset
        var baseParams = JSON.parse(JSON.stringify(model.dashboardViewParameters));
        this.set('baseParams', baseParams);
      } else {
        view = this.store.findAll('dashboard-view').then(function (results) {
          // TODO: convert to object
          return results.findBy('id', params.view);
        });
      }

      return Ember.RSVP.hash({
        view: view.then(function (viewData) {
          // Get the level(s) and grab the top level products
          var levelParam = Ember.get(viewData, 'dashboardViewParameters.LEVEL');
          if (levelParam) {
            var selectedLevels = levelParam;

            return _this.get('ajax').request('/rest/v1/dashboardViews/products', {
              data: {
                customers: selectedLevels,
                fields: 'id,shortDescription,externalReference,customerID'
              }
            }).then(function (_ref) {
              var lists = _ref.lists;


              // Check the uniqueness of the customer ids coming back, as they are the blueprints
              // Only proceed if using a single blueprint
              var customerIds = (lists || []).mapBy('customerID').uniq();
              Ember.set(viewData, 'dashboardViewParameters.PRODUCT_BLUEPRINT_COUNT', customerIds.length);
              if (customerIds.length === 1) {
                var promises = [];
                var productList = [];
                lists.forEach(function (item) {
                  promises.push(_this.get('store').findRecord('product', item.id, { include: 'children' }).then(function (product) {
                    productList.pushObject(product);
                  }));
                });
                Ember.RSVP.allSettled(promises).then(function () {
                  _this.set('productGroups', productList.sortBy('shortDescription'));
                });
              } else {
                _this.set('productGroups', []);
              }
              // Return the original view
              return viewData;
            });
          } else {
            return viewData;
          }
        }),
        customerLevels: this.get('store').query('code', { customerID: customer.get('id'), codeType: 'LEVEL_HIERARCHY' })
      });
    },
    _setDefaultDates: function _setDefaultDates(tz, dashboardViewParameters) {
      // set default dates
      var date = this.get('selectedDate') ? _moment.default.tz(this.get('selectedDate'), tz).startOf('day') : _moment.default.tz(tz).startOf('day');
      var endDate = this.get('selectedEndDate') ? _moment.default.tz(this.get('selectedEndDate'), tz).startOf('day') : date;
      var stly = this.get('selectedDate') ? _moment.default.tz(this.get('selectedDate'), tz).subtract(52, 'weeks').startOf('day') : _moment.default.tz(tz).subtract(52, 'weeks').startOf('day');
      var stlyEndDate = this.get('selectedEndDate') ? _moment.default.tz(this.get('selectedEndDate'), tz).subtract(52, 'weeks').startOf('day') : stly;

      if (dashboardViewParameters) {
        Ember.set(dashboardViewParameters, 'END_DATE', endDate);
        Ember.set(dashboardViewParameters, 'STLY_END_DATE', stlyEndDate);
        var type = Ember.get(dashboardViewParameters, 'DATA_TYPE');
        if (type && type === 'MBS') {
          Ember.set(dashboardViewParameters, 'BEGIN_DATE', date.clone());
          Ember.set(dashboardViewParameters, 'STLY_BEGIN_DATE', stly.clone());

          // Add all the dates to the baseParameters
          this.set('baseParams.END_DATE', endDate);
          this.get('baseParams.STLY_END_DATE', stlyEndDate);
          this.get('baseParams.BEGIN_DATE', date.clone());
          this.get('baseParams.STLY_BEGIN_DATE', stly.clone());
        } else if (type === 'YMTD' || type === 'YMCD') {
          // get date range and set dates
          var dateRange = Ember.get(dashboardViewParameters, 'DATE_RANGE');
          if (dateRange && parseInt(dateRange)) {
            Ember.set(dashboardViewParameters, 'BEGIN_DATE', date.clone().subtract(parseInt(dateRange), 'days'));
            Ember.set(dashboardViewParameters, 'STLY_BEGIN_DATE', stly.clone().subtract(parseInt(dateRange), 'days'));
          } else {
            Ember.set(dashboardViewParameters, 'BEGIN_DATE', date.clone().subtract(10, 'days'));
            Ember.set(dashboardViewParameters, 'STLY_BEGIN_DATE', stly.clone().subtract(10, 'days'));
          }
        } else {
          // YICD is single day
          Ember.set(dashboardViewParameters, 'BEGIN_DATE', date.clone());
          Ember.set(dashboardViewParameters, 'STLY_BEGIN_DATE', stly.clone());
        }
      }
    },
    setupController: function setupController(controller, model) {
      var dataType = model.view.get('dashboardViewParameters.DATA_TYPE');

      this._setDefaultDates(controller.get('tz.customer'), model.view.get('dashboardViewParameters'));

      controller.set('baseParameters', dataType === 'MBS' ? this.get('baseParams') : null);

      controller.set('customerLevels', model.customerLevels.sortBy('orderBy'));

      controller.set('productGroups', this.get('productGroups') || []);

      this._super(controller, model.view);
    }
  });
});