define('ux-pcc/routes/customer/performance/matrix-pricing-target', ['exports', 'moment', 'ux-components/utils/moment-range'], function (exports, _moment, _momentRange) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    store: Ember.inject.service(),

    queryParams: {
      startDate: {
        refreshModel: true
      }
    },

    table: Ember.inject.service(),

    loading: false,

    beforeModel: function beforeModel() {
      // If this is a sister property, redirect to the dashboard
      var customer = this.controllerFor('customer').set('model', this.modelFor('customer'));
      if (this.controllerFor('customer').get('isSisterProperty')) {
        this.transitionTo('/customers/' + customer.get('id'));
      }

      var controller = this.controllerFor('customer.performance.matrix-pricing-target');
      controller.set('targetData', null);
    },


    _selectedDate: null,
    _transitionType: null,
    _checkTransition: false,

    model: function model(params) {
      var tz = this.tz.get('customer');
      var startDate = (params.startDate && _moment.default.tz(params.startDate, tz).isValid() ? _moment.default.tz(params.startDate, tz) : _moment.default.tz(tz)).startOf('day');

      // Keep the selected date for later
      this.set('_selectedDate', startDate.clone());

      var customer = this.modelFor('customer');
      var settings = customer.get('settings');

      var ruleSetting = settings.findBy('settingCode', 'MASTER_PRICING_RULE');
      var ruleId = void 0;
      var deferredRuleSet = void 0;
      if (ruleSetting && ruleSetting.get('settingValue')) {
        ruleId = ruleSetting.get('settingValue');

        deferredRuleSet = this.store.findRecord('rule', ruleId, {
          include: 'children'
        });
      }

      var date = startDate.clone();
      // TODO: Figure out a better place to hold constants for page size
      var width = Ember.$(window).width();
      if (width < 768) {
        return Ember.RSVP.hash({
          priceRuleID: ruleId,
          data: this._requestData(date, 0, ruleId),
          ruleSet: deferredRuleSet,
          ruleId: ruleId,
          date: date,
          autoAllocationRule: this.store.query('rule', {
            customerID: customer.get('id'),
            ruleType: 'PRICE_AUTO_ALLOCATION'
          })
        });
      } else {
        return Ember.RSVP.hash({
          priceRuleID: ruleId,
          data: this._requestData(date, 6, ruleId),
          ruleSet: deferredRuleSet,
          ruleId: ruleId,
          date: date,
          autoAllocationRule: this.store.query('rule', {
            customerID: customer.get('id'),
            ruleType: 'PRICE_AUTO_ALLOCATION'
          })
        });
      }
    },
    setupController: function setupController(controller, model) {

      var date = model.date;

      // TODO: Figure out a better place to hold constants for page size
      var width = Ember.$(window).width();

      if (width < 768) {
        controller.set('isMobile', true);
        controller.set('startDate', date.clone().format());
        controller.set('beginDate', date.clone());
        controller.set('endDate', date.clone());

        // Turn off the ability to scroll
        controller.set('scrollingPaused', true);
      } else {
        controller.set('isNonMobile', true);
        controller.set('startDate', date.clone().format());
        controller.set('beginDate', date.clone());
        controller.set('endDate', date.clone().add(6, 'days'));
      }

      if (model.ruleSet) {
        controller.set('ruleSet', model.ruleSet);
      }

      controller.set('ruleId', model.ruleId);
      controller.set('autoAllocationRule', (model.autoAllocationRule || []).get('firstObject'));
      controller.set('loading', false);

      if (!model.priceRuleID) {
        controller.set('hasSetupError', true);
      } else {
        controller.set('hasSetupError', false);
      }

      this._super(controller, model.data);
    },
    _requestData: function _requestData(date, timespan, templateRuleId) {
      var _this = this;

      var customer = this.modelFor('customer');
      var customerId = customer.get('id');

      var template = {
        channelcode: 'ALL',
        begindate: date.format(),
        enddate: date.clone().add(timespan, 'days').format(),
        frequency: 'DAY',
        customerid: customerId
      };

      var defaultData = [];
      (0, _momentRange.default)(date, date.clone().add(timespan, 'days')).forEach(function (m) {
        defaultData.push({
          ruleDate: m.format()
        });
      });

      var promises = {
        calendars: this.store.query('rule-calendar', {
          begin: date.format(),
          end: date.clone().add(timespan, 'days').format(),
          customerID: customerId,
          ruleType: 'PRICE'
        }).then(function (data) {
          // Filter to just the price point rule
          var ruleId = templateRuleId;
          if (!ruleId) {
            var controller = _this.controllerFor('customer.performance.matrix-pricing-target');
            ruleId = controller.get('ruleSet.id');
          }
          if (ruleId) {
            return data.filterBy('rule.id', ruleId).map(function (c) {
              return JSON.parse(JSON.stringify(c));
            });
          } else {
            return [];
          }
        }),
        autoAllocationCalendars: this.store.query('rule-calendar', {
          begin: date.format(),
          end: date.clone().add(timespan, 'days').format(),
          customerID: customerId,
          ruleType: 'PRICE_AUTO_ALLOCATION'
        }).then(function (data) {
          return data.toArray();
        }),
        inventory: this.ajax.request('/rest/v1/analyticEntries', {
          data: Ember.merge({
            contenttype: 'INVENTORY',
            analytictype: 'COUNT',
            subcontenttype: 'PHYSICAL,OVERSELL,DO_NOT_SELL,CONSUMED,OUT_OF_ORDER,BLOCKED,PICKED_UP,YIELDABLE',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
          }, template)
        }).then(function (_ref) {
          var _ref$analyticEntries = _ref.analyticEntries,
              analyticEntries = _ref$analyticEntries === undefined ? [] : _ref$analyticEntries;

          return analyticEntries;
        }),
        calculations: this.ajax.request('/rest/v1/analyticEntryAggregation/process', {
          data: Ember.merge({
            aggregationType: 'BAR_ALLOCATION',
            contenttype: 'INVENTORY_STATUS',
            analytictype: 'COUNT'
          }, template)
        }).then(function (_ref2) {
          var _ref2$collections = _ref2.collections,
              collections = _ref2$collections === undefined ? [] : _ref2$collections;

          return collections;
        }),
        searchedDates: { startDate: date, endDate: date.clone().add(timespan, 'days') }
      };

      return Ember.RSVP.hash(promises);
    },
    _updateRuleCalendars: function _updateRuleCalendars() {
      var _this2 = this;

      var timeSpan = this.controller.get('isMobile') ? 0 : 6;
      var tz = this.tz.get('customer');
      var date = (0, _moment.default)(this.controller.get('model.calendars.firstObject.ruleDate')).tz(tz);
      return this._requestData(date, timeSpan, this.controller.get('ruleId')).then(function (response) {
        _this2.controller.set('model', response);
      }).finally(function () {
        _this2.controller.set('loading', false);
      });
    },


    actions: {
      requestCopy: function requestCopy(data, rules, complete) {
        var _this3 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var targetData = this.controller.get('targetData');
        var requestTemplate = {
          customerID: this.modelFor('customer').get('id'),
          day1: data.day0 ? 'Y' : 'N',
          day2: data.day1 ? 'Y' : 'N',
          day3: data.day2 ? 'Y' : 'N',
          day4: data.day3 ? 'Y' : 'N',
          day5: data.day4 ? 'Y' : 'N',
          day6: data.day5 ? 'Y' : 'N',
          day7: data.day6 ? 'Y' : 'N',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType'),
          children: rules
        };

        var promises = [];
        // Have to loop through, since days in the range could have exclusion rules (ie. freeze, event, auto)
        (0, _momentRange.default)(data.beginSlice, data.endSlice).forEach(function (m) {
          var ruleDate = m.tz(tz);
          var key = ruleDate.format();

          var day = targetData.findBy('key', key);

          if (day && !day.values.viewOnly && !day.values.demoMode) {
            var request = Ember.assign({}, requestTemplate, {
              beginDate: ruleDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
              endDate: ruleDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
            });

            var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');
            if (pricePoints.length === 0) {
              complete(false);
              _this3.send('flash', _this3.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
              return;
            }

            promises.push(_this3.ajax.post('/rest/v1/ruleCalendarRequests', {
              data: JSON.stringify(request),
              contentType: 'application/json'
            }));
          }
        });

        return Ember.RSVP.all(promises).then(function () /* responses */{
          // complete and close the modal
          complete(true);
          _this3._updateRuleCalendars();
        }).catch(function (error) {
          _this3.send('flash', error, 'danger');
          complete(false);
        });
      },
      requestUpdate: function requestUpdate(calendars) {
        var _this4 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var requestTemplate = {
          customerID: this.modelFor('customer').get('id'),
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType')
        };

        var promises = calendars.map(function (c) {
          var ruleDate = _moment.default.tz(Ember.get(c, 'ruleDate'), tz);

          var request = Ember.assign({}, requestTemplate, {
            beginDate: ruleDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            endDate: ruleDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            children: Ember.get(c, 'children')
          });

          var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');

          if (pricePoints.length === 0) {
            _this4.send('flash', _this4.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
            return;
          }

          return _this4.ajax.post('/rest/v1/ruleCalendarRequests', {
            data: JSON.stringify(request),
            contentType: 'application/json'
          }).then(function () {
            return Ember.set(c, 'isDirty', false);
          });
        });

        Ember.RSVP.all(promises).then(function () /* responses */{
          _this4._updateRuleCalendars().then(function () {
            _this4.send('flash', _this4.i18n.t('customer.performance.matrix-pricing.messages.save_success'), 'success');
          }, function (error) {
            _this4.send('flash', error, 'danger');
          });
        }).catch(function (error) {
          _this4.send('flash', error, 'danger');
        });
      }
    }
  });
});