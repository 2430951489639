define('ux-pcc/components/tables/expandable-table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    row: null,
    isExpanded: Ember.computed.alias('row.isExpanded'),
    wrapperClass: '',
    childRowClass: 'view-screen__table-content--row-child',

    columns: [],

    actions: {
      toggleDrawer: function toggleDrawer() {
        this.toggleProperty('isExpanded');
        if (this.get('isExpanded')) {
          this.get('requestChildren')();
        } else {
          this.get('drawerClosed')();
        }
      }
    }
  });
});