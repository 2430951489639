define('ux-pcc/components/yield-manager/reservation-list-row', ['exports', 'moment', 'accounting', 'ux-pcc/utils/date-formatter'], function (exports, _moment, _accounting, _dateFormatter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    currencyCode: Ember.computed.readOnly('reservation.currencyCode'),

    brand: Ember.computed('reservation.brand', 'brands.[]', function () {
      var brand = this.get('reservation.brand');
      var brands = this.get('brands') || [];
      if (brand.indexOf('BRAND_') > -1) {
        brand = brand.replace('BRAND_', '');
      }
      return brands.findBy('codeValue', brand) ? brands.findBy('codeValue', brand).get('shortDescription') : '';
    }),

    reservationDuration: Ember.computed('reservation.reservationDuration', function () {
      return _accounting.default.formatNumber(this.get('reservation.reservationDuration') || 0, 2);
    }),

    localCreationDate: Ember.computed('reservation.localCreationDate', function () {
      var settings = this.get('customer.settings');
      return (0, _dateFormatter.dateFormatter)(this.get('reservation.localCreationDate'), { settings: settings });
    }),

    localBeginDate: Ember.computed('reservation.localBeginDate', function () {
      var settings = this.get('customer.settings');
      return (0, _dateFormatter.dateFormatter)(this.get('reservation.localBeginDate'), { settings: settings });
    }),

    localCheckoutDate: Ember.computed('reservation.localCheckoutDate', function () {
      var settings = this.get('customer.settings');
      return (0, _dateFormatter.dateFormatter)(this.get('reservation.localCheckoutDate'), { settings: settings });
    }),

    localCreationHour: Ember.computed('reservation.localCreationHour', function () {
      return _moment.default.tz(this.get('reservation.localCreationHour'), 'HH:mm', 'UTC').format('hh:mm A');
    }),

    localBeginHour: Ember.computed('reservation.localBeginHour', function () {
      return _moment.default.tz(this.get('reservation.localBeginHour'), 'HH:mm', 'UTC').format('hh:mm A');
    }),

    localCheckoutHour: Ember.computed('reservation.localCheckoutHour', function () {
      return this.get('reservation.localCheckoutHour') ? _moment.default.tz(this.get('reservation.localCheckoutHour'), 'HH:mm', 'UTC').format('hh:mm A') : '';
    }),

    status: Ember.computed('reservation.status', 'i18n.locale', function () {
      var statusCode = ['BOOK', 'MODIFY', 'CANCEL'].indexOf(this.get('reservation.status')) > -1 ? this.get('reservation.status').toLowerCase() : 'other';
      return this.get('i18n').t('yield-manager.reservations.status.' + statusCode);
    })
  });
});