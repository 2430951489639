define('ux-pcc/components/filter-panel', ['exports', 'ux-components/components/filter-panel'], function (exports, _filterPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _filterPanel.default;
    }
  });
});