define('ux-pcc/components/guarantee-panel/guarantee-form-hold', ['exports', 'ux-components/components/guarantee-panel/guarantee-form-hold'], function (exports, _guaranteeFormHold) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _guaranteeFormHold.default;
    }
  });
});