define('ux-pcc/utils/which-browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isOpera = isOpera;
  exports.isFirefox = isFirefox;
  exports.isSafari = isSafari;
  exports.isIE = isIE;
  exports.isEdge = isEdge;
  exports.isChrome = isChrome;
  exports.isBlink = isBlink;
  // Shamelessly taken from https://stackoverflow.com/a/9851769

  // Opera 8.0+
  function isOpera() {
    /* jshint ignore:start */
    return !!window.opr && !!opr.addons || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    /* jshint ignore:end */
  }

  // Firefox 1.0+
  function isFirefox() {
    return typeof InstallTrigger !== 'undefined';
  }

  // Safari 3.0+ "[object HTMLElementConstructor]"
  function isSafari() {
    /* jshint ignore:start */
    return (/constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification)
    );
    /* jshint ignore:end */
  }

  // Internet Explorer 6-11
  function isIE() {
    return (/*@cc_on!@*/false || !!document.documentMode
    );
  }

  // Edge 20+
  function isEdge() {
    return !isIE && !!window.StyleMedia;
  }

  // Chrome 1 - 71
  function isChrome() {
    return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  }

  // Blink engine detection
  function isBlink() {
    return (isChrome || isOpera) && !!window.CSS;
  }
});