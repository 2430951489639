define('ux-pcc/components/dashboard-widget/future-trend', ['exports', 'moment', 'ux-pcc/mixins/queued-query-data', 'ux-pcc/utils/date-formatter'], function (exports, _moment, _queuedQueryData, _dateFormatter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_queuedQueryData.default, {

    classNames: ['widget-box'],

    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),

    days: true,
    tm: true,
    showComments: true,

    data: null,
    // Define required items for query-data-in-viewport mixin
    queryDataWatchProperties: null,

    init: function init() {
      this.set('queryDataWatchProperties', ['customer.id', 'startDate', 'dataType', 'locationType', 'reservationCategory', 'aggregateVersion']);
      this._super.apply(this, arguments);
    },


    // 'DETAIL' if property, or 'ROLLUP'
    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    aggregate: Ember.computed.equal('rollupStatus', 'ROLLUP'),

    startDate: Ember.computed('selectedDate', 'customer', function () {
      return this.get('selectedDate').clone().startOf('month');
    }),

    initialIndexEnd: Ember.computed('startDate', function () {
      return this.get('startDate').clone().daysInMonth() - 1;
    }),

    aggregationType: Ember.computed('aggregateVersion', function () {
      return this.get('aggregateVersion') !== '1' ? 'DAILY_ACCUMULATION_COMPARISON_V2' : 'DAILY_ACCUMULATION_COMPARISON';
    }),

    beginDate: Ember.computed('zoomStartDate', function () {
      var settings = this.get('customer.settings');
      return (0, _dateFormatter.dateFormatter)(this.get('zoomStartDate'), { tz: this.get('tz.customer'), settings: settings });
    }),

    endDate: Ember.computed('zoomEndDate', function () {
      var settings = this.get('customer.settings');
      return (0, _dateFormatter.dateFormatter)(this.get('zoomEndDate'), { tz: this.get('tz.customer'), settings: settings });
    }),

    queryDataMethod: function queryDataMethod() {
      var _this = this;

      var startDate = this.get('startDate');
      var endDate = startDate.clone().add(4, 'months');

      this.set('data', null);

      return this.get('ajax').request('rest/v1/analyticEntryAggregation/process', {
        track: true,
        data: {
          customerid: this.get('customer.id'),
          begindateStr: startDate.format('YYYY-MM-DD'),
          enddateStr: endDate.format('YYYY-MM-DD'),
          aggregationType: this.get('aggregationType'),
          rollupStatus: this.get('rollupStatus'),
          aggregate: false,
          asOfDateBeginStr: startDate.format('YYYY-MM-DD'),
          asOfDateEndStr: endDate.format('YYYY-MM-DD'),
          sourceLocationCode: this.get('dataType'),
          locationType: this.get('locationType'),
          returnType: this.get('reservationCategory'),
          groupBy: 'ALL',
          nocache: this.get('nocache')
        }
      }).then(function (_ref) {
        var collections = _ref.collections;

        _this.set('data', collections.sortBy('entryDate').map(function (ae) {
          return {
            // entrySlice will be used to create slice in the line-chart component.  Needed a separate field as
            // it gets mutated in the line-chart
            entrySlice: _moment.default.tz(ae.entryDate, 'UTC'),
            deVar: Number(ae.dataValues.pctChangeUsageMinusNoShows),
            //fleetForecast: fleetForecast[i],
            tmRevVar: Number(ae.dataValues.pctChangeBase),
            deVarLabel: _this.get('i18n').t('dashboard-widget.future-trend.days_variance'),
            tmRevVarLabel: _this.get('i18n').t('dashboard-widget.future-trend.rev_variance')
          };
        }));
      }).catch(function () {
        return [];
      });
    },


    graphConfig: Ember.computed('i18n.locale', function () {
      return Ember.Object.create({
        categoryField: 'slice',
        graphs: [{
          title: this.get('i18n').t('dashboard-widget.future-trend.days_variance'),
          valueField: 'deVar',
          type: 'smoothedLine',
          colorClass: 'chart-color--days',
          bulletStyle: 'round'
        },
        /*
        {
          title: this.get('i18n').t('dashboard-widget.future-trend.fleet_forecast'),
          valueField: 'fleetForecast',
          type: 'smoothedLine',
          color: '1'
        },
        */
        {
          title: this.get('i18n').t('dashboard-widget.future-trend.rev_variance'),
          valueField: 'tmRevVar',
          type: 'smoothedLine',
          colorClass: 'chart-color--revenue',
          bulletStyle: 'square'
        }]
      });
    }),

    _levelByCustomerType: function _levelByCustomerType() {
      var customer = this.get('customer');
      return customer.get('customerType') === customer.Type.PROPERTY ? 'LEVEL_STATION' : '';
    },
    _updateZoom: function _updateZoom(zoomInfo) {
      var tz = this.tz.get('customer');
      this.get('updateZoom')(_moment.default.tz(zoomInfo.chart.chartData[zoomInfo.startIndex].dataContext.entrySlice.format('YYYY-MM-DD'), tz), _moment.default.tz(zoomInfo.chart.chartData[zoomInfo.endIndex].dataContext.entrySlice.format('YYYY-MM-DD'), tz));
    },


    actions: {
      setZoomInfo: function setZoomInfo(zoomInfo) {
        Ember.run.scheduleOnce('afterRender', this, this._updateZoom, zoomInfo);
      }
    }
  });
});