define('ux-pcc/components/rules/condition-values/currency', ['exports', 'ux-pcc/components/rules/condition-values/base', 'ember-changeset-validations/validators'], function (exports, _base, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    //TODO: base this off of the actual currency
    currencySymbol: '$',

    validations: Ember.computed('field', 'i18n.locale', function () {

      var validations = {};

      validations[this.field] = (0, _validators.validateLength)({
        min: 1,
        max: 3,
        allowNone: false,
        message: this.i18n.t('rule._form_modal.no_value').toString()
      });

      return validations;
    })
  });
});