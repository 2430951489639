define('ux-pcc/controllers/competitor-pricing/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    currencyCode: Ember.computed(function () {
      var model = this.get("customer.settings").findBy("settingCode", "DEFAULT_CURRENCY");
      if (model && model.get("settingValue")) {
        return model.get("settingValue");
      } else {
        return "USD";
      }
    })

  });
});