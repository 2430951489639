define('ux-pcc/utils/bar-utils', ['exports', 'ux-components/utils/bar-utils'], function (exports, _barUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _barUtils.default;
    }
  });
});