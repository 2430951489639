define('ux-pcc/initializers/customize-text-field-bindings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    Ember.TextField.reopenClass({
      attributeBindings: ['required', 'min', 'max']
    });
  }

  exports.default = {
    name: 'customize-text-field-bindings',
    initialize: initialize
  };
});