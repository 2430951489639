define('ux-pcc/components/schedule-panel/daily-form', ['exports', 'ux-components/components/schedule-panel/daily-form'], function (exports, _dailyForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dailyForm.default;
    }
  });
});