define('ux-pcc/routes/customer/performance/matrix-pricing', ['exports', 'moment', 'ux-components/utils/to-boolean'], function (exports, _moment, _toBoolean) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    menu: Ember.inject.service(),
    store: Ember.inject.service(),

    queryParams: {
      startDate: {
        refreshModel: true
      }
    },

    table: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      // If this is a sister property, redirect to the dashboard
      var customer = this.controllerFor('customer').set('model', this.modelFor('customer'));
      if (this.controllerFor('customer').get('isSisterProperty')) {
        this.transitionTo('/customers/' + customer.get('id'));
      }

      if (this.get('_abortTransition') || this.get('_waitForConfirm')) {
        transition.abort();
        this.set('_abortTransition', false);
      } else if (this.get('_forceTransition')) {
        // Clear flags for next time in and return
        this.set('_forceTransition', false);
        this.set('_resetDate', false);

        return true;
      } else if (transition.targetName === 'customer.performance.matrix-pricing' && transition.router.activeTransition.router.currentHandlerInfos && transition.targetName === transition.router.activeTransition.router.currentHandlerInfos[transition.router.activeTransition.router.currentHandlerInfos.length - 1].name && this.currentModel && this.currentModel.data.calendars && this.currentModel.data.calendars.filterBy('isDirty', true).length > 0) {

        // We get in here because we changed the date, so we need to set startDate back to where it was if they cancel
        this.set('_resetDate', true);
        // Store the transition for later, should we need to continue
        this.set('_transition', transition);
        // Abort the transition, and then launch the confirmation modal
        transition.abort();

        this.get('dialog').showConfirmation({
          titleText: this.i18n.t('customer.performance.matrix-pricing.messages.discard_edits'),
          messageText: this.i18n.t('customer.performance.matrix-pricing.messages.unsaved_data') + ' ' + this.i18n.t('customer.performance.matrix-pricing.messages.proceed'),
          confirmText: this.i18n.t('customer.performance.matrix-pricing.messages.continue_nav'),
          cancelText: this.i18n.t('customer.performance.matrix-pricing.messages.cancel_nav'),
          onConfirm: function onConfirm() {
            _this.send('confirmationResponse', true);
            _this.get('dialog').close();
          },
          onCancel: function onCancel() {
            _this.send('confirmationResponse', false);
            _this.get('dialog').close();
          }
        });
      } else {
        return true;
      }
    },


    _selectedDate: null,
    _transitionType: null,
    _checkTransition: false,

    model: function model(params) {
      var tz = this.tz.get('customer');
      var startDate = (params.startDate && _moment.default.tz(params.startDate, tz).isValid() ? _moment.default.tz(params.startDate, tz) : _moment.default.tz(tz)).startOf('day');

      // Keep the selected date for later
      this.set('_selectedDate', startDate.clone());

      var customer = this.modelFor('customer');
      var settings = customer.get('settings');

      var ruleSetting = settings.findBy('settingCode', 'MASTER_PRICING_RULE');
      var ruleId = void 0;
      var deferredRuleSet = void 0;
      if (ruleSetting && ruleSetting.get('settingValue')) {
        ruleId = ruleSetting.get('settingValue');

        deferredRuleSet = this.store.findRecord('rule', ruleId, {
          include: 'children'
        });
      }

      var pricingRuleSetting = settings.findBy('settingCode', 'IGNORE_PRICING_RULES_SELLABLE_RATE');
      var isIgnorePricingRules = pricingRuleSetting && pricingRuleSetting.get('settingValue') && (0, _toBoolean.default)(pricingRuleSetting.get('settingValue'));

      var displayEventTimeSetting = settings.findBy('settingCode', 'DISPLAY_EVENT_TIME');
      var isDisplayEventTime = displayEventTimeSetting && displayEventTimeSetting.get('settingValue') && (0, _toBoolean.default)(displayEventTimeSetting.get('settingValue'));

      var displayFreezeSetting = settings.findBy('settingCode', 'DISPLAY_FREEZE') || settings.findBy('settingCode', 'DISPLAY_CEILING');
      var isDisplayFreeze = displayFreezeSetting && displayFreezeSetting.get('settingValue') && (0, _toBoolean.default)(displayFreezeSetting.get('settingValue'));

      var displayAutoAdjustSetting = settings.findBy('settingCode', 'DISPLAY_BAR_AUTO_ADJUST');
      var isDisplayAutoAdjust = displayAutoAdjustSetting && displayAutoAdjustSetting.get('settingValue') && (0, _toBoolean.default)(displayAutoAdjustSetting.get('settingValue'));
      var isPermittedAutoAdjust = this.menu.flattenedMenus.findBy('actionUrl', 'customer.performance.auto-matrix-pricing');

      var date = startDate.clone();
      // TODO: Figure out a better place to hold constants for page size
      var width = Ember.$(window).width();
      if (width < 768) {
        return Ember.RSVP.hash({
          priceRuleID: ruleId,
          data: this._requestData(date, 1, ruleId),
          ruleSet: deferredRuleSet,
          date: date,
          isIgnorePricingRules: isIgnorePricingRules,
          isDisplayEventTime: isDisplayEventTime,
          isDisplayFreeze: isDisplayFreeze,
          isDisplayAutoAdjust: isDisplayAutoAdjust && isPermittedAutoAdjust,
          autoAllocationRule: this.store.query('rule', {
            customerID: customer.get('id'),
            ruleType: 'PRICE_AUTO_ALLOCATION'
          })
        });
      } else {
        // Subtract 7 days from the startdate to get the begin date of the initial 10 day range retrieved
        date = date.subtract(7, 'days');

        return Ember.RSVP.hash({
          priceRuleID: ruleId,
          data: this._requestData(date, 10, ruleId),
          ruleSet: deferredRuleSet,
          date: date,
          isIgnorePricingRules: isIgnorePricingRules,
          isDisplayEventTime: isDisplayEventTime,
          isDisplayFreeze: isDisplayFreeze,
          isDisplayAutoAdjust: isDisplayAutoAdjust && isPermittedAutoAdjust,
          autoAllocationRule: this.store.query('rule', {
            customerID: customer.get('id'),
            ruleType: 'PRICE_AUTO_ALLOCATION'
          })
        });
      }
    },
    setupController: function setupController(controller, model) {

      var date = model.date;

      // TODO: Figure out a better place to hold constants for page size
      var width = Ember.$(window).width();

      if (width < 768) {
        controller.set('isMobile', true);
        controller.set('startDate', date.clone().format());
        controller.set('beginDate', date.clone());
        controller.set('endDate', date.clone());

        // Turn off the ability to scroll
        controller.set('scrollingPaused', true);
      } else {
        controller.set('isNonMobile', true);
        controller.set('startDate', date.clone().add(7, 'days').format());
        controller.set('beginDate', date.clone());
        controller.set('endDate', date.clone().add(10, 'days'));
      }

      if (model.ruleSet) {
        controller.set('ruleSet', model.ruleSet);
      }

      controller.set('isIgnorePricingRules', model.isIgnorePricingRules);
      controller.set('isDisplayEventTime', model.isDisplayEventTime);
      controller.set('isDisplayFreeze', model.isDisplayFreeze);
      controller.set('isDisplayAutoAdjust', model.isDisplayAutoAdjust);
      controller.set('autoAllocationRule', (model.autoAllocationRule || []).get('firstObject'));

      if (!model.priceRuleID) {
        controller.set('hasSetupError', true);
      } else {
        controller.set('hasSetupError', false);
      }

      this._super(controller, model.data);
    },
    _requestData: function _requestData(date, timespan, templateRuleId) {
      var _this2 = this;

      var customerId = this.modelFor('customer').id;

      var template = {
        channelcode: 'ALL',
        begindate: date.format(),
        enddate: date.clone().add(timespan, 'days').format(),
        frequency: 'DAY',
        customerid: customerId
      };

      var promises = {
        calendars: this.store.query('rule-calendar', {
          begin: date.format(),
          end: date.clone().add(timespan, 'days').format(),
          customerID: customerId,
          ruleType: 'PRICE'
        }).then(function (data) {
          // Filter to just the price point rule
          var ruleId = templateRuleId;
          if (!ruleId) {
            var controller = _this2.controllerFor('customer.performance.matrix-pricing');
            ruleId = controller.get('ruleSet.id');
          }
          if (ruleId) {
            return data.filterBy('rule.id', ruleId).map(function (c) {
              return JSON.parse(JSON.stringify(c));
            });
          } else {
            return [];
          }
        }),
        autoAllocationCalendars: this.store.query('rule-calendar', {
          begin: date.format(),
          end: date.clone().add(timespan, 'days').format(),
          customerID: customerId,
          ruleType: 'PRICE_AUTO_ALLOCATION'
        }).then(function (data) {
          return data.toArray();
        }),
        calendarHeaders: templateRuleId ? this.ajax.request('/rest/v1/pricePointCalendar/header', {
          data: {
            customerID: customerId,
            begindate: date.format('YYYY-MM-DD'),
            enddate: date.clone().add(timespan, 'day').format('YYYY-MM-DD')
          }
        }).then(function (_ref) {
          var pricePointHeaders = _ref.pricePointHeaders;
          return pricePointHeaders;
        }) : [],
        inventory: this.ajax.request('/rest/v1/analyticEntries', {
          data: Ember.merge({
            contenttype: 'INVENTORY',
            analytictype: 'COUNT',
            subcontenttype: 'PHYSICAL,OVERSELL,DO_NOT_SELL,CONSUMED,OUT_OF_ORDER,BLOCKED,PICKED_UP,YIELDABLE',
            action: 'STATISTIC',
            datatype: 'VALUE',
            rollupstatus: 'DETAIL',
            fields: 'id,beginDate,endDate,contentType,subContentType,dataValue'
          }, template)
        }).then(function (_ref2) {
          var _ref2$analyticEntries = _ref2.analyticEntries,
              analyticEntries = _ref2$analyticEntries === undefined ? [] : _ref2$analyticEntries;
          return analyticEntries;
        }),
        comments: this.store.query('comment', {
          commentType: 'REVENUE_MANAGEMENT',
          fkReference: 'CUSTOMER',
          fkID: customerId,
          beginDate: date.format(),
          endDate: date.clone().add(timespan, 'days').format(),
          customerID: customerId
        }),
        searchedDates: { startDate: date, endDate: date.clone().add(timespan, 'days') }
      };

      return Ember.RSVP.hash(promises);
    },
    _refreshRuleCalendars: function _refreshRuleCalendars(beginSlice) {
      var _this3 = this;

      var endSlice = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : beginSlice;


      // request changed dates
      var beginDate = (0, _moment.default)(beginSlice);
      var endDate = (0, _moment.default)(endSlice).isBefore(this.controller.get('endDate')) ? (0, _moment.default)(endSlice) : this.controller.get('endDate');

      // If we are outside the scope of what is visible on the screen just return the single day to populate for a copy
      if (endDate.isBefore(beginDate)) {
        endDate = (0, _moment.default)(beginDate);
      }

      var tz = this.tz.get('customer');

      // get price point rule
      var ruleId = this.controller.get('ruleSet').get('id');

      return this.store.query('rule-calendar', {
        begin: beginDate.format(),
        end: endDate.format(),
        customerID: this.modelFor('customer').get('id'),
        ruleType: 'PRICE'
      }).then(function (results) {
        // Filter to just the price point rule
        results = results.filterBy('rule.id', ruleId);
        results.forEach(function (r) {

          var date = (0, _moment.default)(Ember.get(r, 'ruleDate')).tz(tz);

          var key = date.format();

          // convert to plain JSON
          r = JSON.parse(JSON.stringify(r));

          _this3.controller._updateCalendar(Ember.get(r, 'children'), key, false);
        });
      }).catch(function () /*error*/{});
    },


    actions: {
      requestCopy: function requestCopy(data, rules, complete) {
        var _this4 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var request = {
          customerID: this.modelFor('customer').get('id'),
          day1: data.day0 ? 'Y' : 'N',
          day2: data.day1 ? 'Y' : 'N',
          day3: data.day2 ? 'Y' : 'N',
          day4: data.day3 ? 'Y' : 'N',
          day5: data.day4 ? 'Y' : 'N',
          day6: data.day5 ? 'Y' : 'N',
          day7: data.day6 ? 'Y' : 'N',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType'),
          beginDate: _moment.default.tz(data.beginSlice, tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          endDate: _moment.default.tz(data.endSlice, tz).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          children: rules
        };

        var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');

        if (pricePoints.length === 0) {
          complete(false);
          this.send('flash', this.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
          return;
        }

        return this.ajax.post('/rest/v1/ruleCalendarRequests', {
          data: JSON.stringify(request),
          contentType: 'application/json'
        }).then(function () /*responses*/{
          // complete and close the modal
          complete(true);

          _this4._refreshRuleCalendars(data.beginSlice, data.endSlice);
        }).catch(function (error) {
          _this4.send('flash', error, 'danger');
          complete(false);
        });
      },
      requestTimeOfDay: function requestTimeOfDay(data, rules, complete) {
        var _this5 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var request = {
          customerID: this.modelFor('customer').get('id'),
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType'),
          beginDate: _moment.default.tz(data.date, tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          endDate: _moment.default.tz(data.date, tz).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          children: rules
        };

        var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');

        if (pricePoints.length === 0) {
          complete(false);
          this.send('flash', this.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
          return;
        }

        return this.ajax.post('/rest/v1/ruleCalendarRequests', {
          data: JSON.stringify(request),
          contentType: 'application/json'
        }).then(function () /*responses*/{
          // complete and close the modal
          complete(true);
        }).catch(function (error) {
          _this5.send('flash', error, 'danger');

          complete(false);
        });
      },
      requestFreeze: function requestFreeze(date, rules, complete) {
        var _this6 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var request = {
          customerID: this.modelFor('customer').get('id'),
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType'),
          beginDate: _moment.default.tz(date, tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          endDate: _moment.default.tz(date, tz).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
          children: rules.map(function (r) {
            return JSON.parse(JSON.stringify(r));
          })
        };

        var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');

        if (pricePoints.length === 0) {
          complete(false);
          this.send('flash', this.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
          return;
        }

        return this.ajax.post('/rest/v1/ruleCalendarRequests', {
          data: JSON.stringify(request),
          contentType: 'application/json'
        }).then(function () /*responses*/{
          // complete and close the modal
          complete(true);
        }).catch(function (error) {
          _this6.send('flash', error, 'danger');
          complete(false);
        });
      },
      requestUpdate: function requestUpdate(calendars) {
        var _this7 = this;

        var ruleSet = this.controller.get('ruleSet');
        var tz = this.tz.get('customer');
        var requestTemplate = {
          customerID: this.modelFor('customer').get('id'),
          day1: 'Y',
          day2: 'Y',
          day3: 'Y',
          day4: 'Y',
          day5: 'Y',
          day6: 'Y',
          day7: 'Y',
          ruleID: ruleSet.get('id'),
          ruleType: ruleSet.get('ruleType')
        };

        var promises = calendars.map(function (c) {
          var ruleDate = _moment.default.tz(Ember.get(c, 'ruleDate'), tz);

          var request = Ember.assign({}, requestTemplate, {
            beginDate: ruleDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            endDate: ruleDate.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
            children: Ember.get(c, 'children')
          });

          var pricePoints = request.children.rejectBy('externalReference', 'EVENT_TIME').rejectBy('externalReference', 'FREEZE').rejectBy('externalReference', 'FUTURE_FREEZE');

          if (pricePoints.length === 0) {
            _this7.send('flash', _this7.i18n.t('customer.performance.matrix-pricing.messages.unexpected_error'), 'danger');
            return;
          }

          return _this7.ajax.post('/rest/v1/ruleCalendarRequests', {
            data: JSON.stringify(request),
            contentType: 'application/json'
          }).then(function () {
            return Ember.set(c, 'isDirty', false);
          });
        });

        Ember.RSVP.all(promises).then(function () /* responses */{
          _this7.send('flash', _this7.i18n.t('customer.performance.matrix-pricing.messages.save_success'), 'success');
        }, function (error) {
          _this7.send('flash', error, 'danger');
        });
      },
      loadNext: function loadNext(timespan, recenterPage) {
        var _this8 = this;

        if (this.controller.get('scrollingPaused')) {
          return;
        }

        this.controller.set('scrollingPaused', true);

        var date = this.controller.get('endDate').clone().add(1, 'days');
        timespan = timespan || 7;
        var ruleId = this.controller.get('ruleSet.id');
        this._requestData(date, timespan, ruleId).then(function (results) {
          _this8.controller.get('model.calendars').addObjects(results.calendars);
          _this8.controller.get('model.calendarHeaders').addObjects(results.calendarHeaders);
          _this8.controller.get('model.autoAllocationCalendars').addObjects(results.autoAllocationCalendars);
          _this8.controller.get('model.inventory').addObjects(results.inventory);
          _this8.controller._hijackModel(results, date, timespan);
          _this8.controller.set('endDate', date.clone().add(timespan, 'days'));
          Ember.run.schedule('afterRender', _this8, function () {
            _this8.controller.set('scrollingPaused', false);
            if (recenterPage) {
              _this8.get('table').trigger('recenterTable');
            }
            _this8.get('table').trigger('inactivateTable');
          });
        }, function () {
          _this8.controller.set('scrollingPaused', false);
        });
      },
      loadPrevious: function loadPrevious(timespan, recenterPage) {
        var _this9 = this;

        if (this.controller.get('scrollingPaused')) {
          return;
        }

        this.controller.set('scrollingPaused', true);

        timespan = timespan || 7;
        var date = this.controller.get('beginDate').clone().subtract(timespan + 1, 'days');
        var ruleId = this.controller.get('ruleSet.id');
        this._requestData(date, timespan, ruleId).then(function (results) {
          _this9.controller.get('model.calendars').addObjects(results.calendars);
          _this9.controller.get('model.calendarHeaders').addObjects(results.calendarHeaders);
          _this9.controller.get('model.autoAllocationCalendars').addObjects(results.autoAllocationCalendars);
          _this9.controller.get('model.inventory').addObjects(results.inventory);
          _this9.controller._hijackModel(results, date, timespan, true);
          _this9.controller.set('beginDate', date);
          Ember.run.schedule('afterRender', _this9, function () {
            _this9.controller.set('scrollingPaused', false);
            if (recenterPage) {
              _this9.get('table').trigger('recenterTable');
            }
            _this9.get('table').trigger('inactivateTable');
          });
        }, function () {
          _this9.controller.set('scrollingPaused', false);
        });
      },
      confirmationResponse: function confirmationResponse(response) {
        this.set('_waitForConfirm', false);
        if (response) {
          var transition = this.get('_transition');
          // Need to force willTransition to allow the transition
          this.set('_forceTransition', true);

          transition.retry();
        } else if (this.get('_resetDate')) {
          // Set startDate back to where it was
          var controller = this.controllerFor('customer.performance.matrix-pricing');
          if (controller) {
            controller.set('startDate', this.get('_selectedDate'));
            // Reverting the startDate causes beforeModel will be called again, so we need to abort when we come back in
            this.set('_abortTransition', true);
            this.set('_resetDate', false);
          }
        }
      },
      willTransition: function willTransition(transition) {
        var _this10 = this;

        var controller = this.controllerFor('customer.performance.matrix-pricing');

        if (this.get('_waitForConfirm')) {
          return;
        } else if (this.get('_forceTransition')) {
          // Clear flag for next time in and return unless we are coming back into the same page on a date change
          if (transition.targetName !== 'customer.performance.matrix-pricing') {
            this.set('_forceTransition', false);
          }

          return;
        } else if (controller && !controller.get('cannotPersist') && transition.targetName !== 'customer.performance.matrix-pricing') {

          // TODO: FIXME - This is a hack because beforeModel is called when we try to navigate to the dashboard
          // and if we don't abort again there, it will refresh the page
          if (transition.targetName === 'customer.index') {
            this.set('_abortTransition', true);
          }

          this.set('_waitForConfirm', true);

          // Store the transition for later, should we need to continue
          this.set('_transition', transition);
          // Abort the transition, and then launch the confirmation modal
          transition.abort();

          this.dialog.showConfirmation({
            titleText: this.i18n.t('customer.performance.matrix-pricing.messages.discard_edits'),
            messageText: this.i18n.t('customer.performance.matrix-pricing.messages.unsaved_data') + ' ' + this.i18n.t('customer.performance.matrix-pricing.messages.proceed'),
            confirmText: this.i18n.t('customer.performance.matrix-pricing.messages.continue_nav'),
            cancelText: this.i18n.t('customer.performance.matrix-pricing.messages.cancel_nav'),
            onConfirm: function onConfirm() {
              _this10.send('confirmationResponse', true);
              _this10.dialog.close();
            },
            onCancel: function onCancel() {
              _this10.send('confirmationResponse', false);
              _this10.dialog.close();
            }
          });
        }
      }
    }
  });
});