define('ux-pcc/components/yield-manager/cards/base-card', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajax: Ember.inject.service(),

    loading: true,
    tz: null,
    rollupStatus: 'DETAIL',
    model: null,
    data: null,
    selectedDate: null,
    currencyCode: 'USD',
    currentChartRow: null,

    dataFields: Ember.computed('model.gross', 'currentChartRow.graphKey', function () {
      var grossFlag = this.get('model.gross');
      var graphKey = this.get('currentChartRow.graphKey');
      switch (graphKey) {
        case 'usage':
          return [{ key: 'usage', label: this.get('i18n').t('yield-manager.actuals'), lineClasses: '0' }, { key: 'stlyUsage', label: this.get('i18n').t('yield-manager.actuals'), lineClasses: '0 muted' }];
        case 'noshowusage':
          return [{ key: 'noshowusage', label: this.get('i18n').t('yield-manager.net'), lineClasses: '1' }, { key: 'stlyNoshowusage', label: this.get('i18n').t('yield-manager.actuals'), lineClasses: '1 muted' }];
        case 'yield':
          return [{ key: grossFlag ? 'grossYield' : 'baseYield', label: this.get('i18n').t('yield-manager.yield'), lineClasses: '2' }, { key: grossFlag ? 'stlyGrossYield' : 'stlyBaseYield', label: this.get('i18n').t('yield-manager.yield'), lineClasses: '2 muted' }];
        case 'revenue':
          return [{ key: grossFlag ? 'gross' : 'base', label: this.get('i18n').t('yield-manager.revenue'), format: 'currency', lineClasses: '3' }, { key: grossFlag ? 'stlyGross' : 'stlyBase', label: this.get('i18n').t('yield-manager.revenue'), format: 'currency', lineClasses: '3 muted' }];
      }
      return [];
    }),

    dataProvider: Ember.computed('data', function () {
      var mapped = this.get('data');
      if (!mapped) {
        return null;
      }

      var tz = this.get('tz');
      var dateSort = function dateSort(o1, o2) {
        var date1 = _moment.default.tz(o1, tz);
        var date2 = _moment.default.tz(o2, tz);

        return date1.isBefore(date2) ? -1 : date1.isAfter(date2) ? 1 : 0;
      };

      return Object.keys(mapped).sort(dateSort).map(function (key) {
        return mapped[key];
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('customerID')) {
        this._fetchData();
      }
    },


    _init: Ember.observer('customerID', 'view', 'selectedDate', function () {
      if (this.get('customerID')) {
        Ember.run.once(this, '_fetchData');
      }
    }),

    actions: {
      rowClick: function rowClick(row) {
        if (row.graphKey) {
          this.set('currentChartRow', row);
        }
      }
    }

  });
});