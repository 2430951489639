define('ux-pcc/helpers/format-error', ['exports', 'ux-components/helpers/format-error'], function (exports, _formatError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formatError.default;
    }
  });
  Object.defineProperty(exports, 'formatError', {
    enumerable: true,
    get: function () {
      return _formatError.formatError;
    }
  });
});