define('ux-pcc/controllers/alerts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['alertType', 'alertStatus', 'startDate', 'endDate', 'viewBy'],

    alertStatus: 'current',
    propertyId: '',
    tag: '',
    viewBy: 'newest',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    alertsChanged: Ember.observer('model', 'customer.children', function () {

      var alerts = this.model || [];
      alerts.forEach(function (alert /*, index, enumerable*/) {
        switch (alert.get('alertType')) {
          case 'NOTIFY_ALERT':
            alert.set('alertTypePriority', 1);
            break;
          case 'NOTIFY_WARN':
            alert.set('alertTypePriority', 2);
            break;
          case 'NOTIFY_INFO':
            alert.set('alertTypePriority', 3);
            break;
        }
      });
    })
  });
});