define('ux-pcc/controllers/customer/performance/stayover', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['startDate'],

    pickerOptions: Ember.computed('i18n.locale', function () {
      return {
        locale: _moment.default.locale(),
        useCurrent: false
      };
    }),
    startDate: null,

    groupings: Ember.computed(function () {
      return [{ start: 0, end: 4, label: this.get('i18n').t('customer.performance.stayover.12am') }, { start: 5, end: 11, label: this.get('i18n').t('customer.performance.stayover.5am') }, { start: 12, end: 14, label: this.get('i18n').t('customer.performance.stayover.12pm') }, { start: 15, end: 17, label: this.get('i18n').t('customer.performance.stayover.3pm') }, { start: 18, end: 20, label: this.get('i18n').t('customer.performance.stayover.6pm') }, { start: 21, end: 23, label: this.get('i18n').t('customer.performance.stayover.9pm') }, { start: 24, end: 28, label: this.get('i18n').t('customer.performance.stayover.12_plus') }];
    }),

    _hours: function _hours() {
      var hours = [];
      var formattedTime = void 0;
      for (var i = 0; i < 28; i++) {
        //fill in all of the hours
        formattedTime = (0, _moment.default)().startOf('day').add(i, "hours").format("hA"); //give the time in format X AM/PM
        if (i > 23) {
          formattedTime = formattedTime + "+";
        }
        hours.push(formattedTime);
      }
      return hours;
    },


    changedData: Ember.observer('model', function () {
      var _this = this;

      var entries = this.get('model');
      var tz = this.tz.get('customer');
      var dateToday = _moment.default.tz(tz).startOf("day");

      var headerCellHTML = function headerCellHTML(data) {
        return '<div class="performance-inventory-header ' + (data.today ? 'performance-inventory-header--today' : '') + '">\n          <div class="performance-inventory-header__date-section">\n            <div class="performance-inventory-header__long-date">\n              <span class="performance-inventory-header__dow">' + data.date.format('dddd') + '</span>\n              <span>' + data.date.format('MMM D, YYYY') + '</span>\n            </div>\n            <div class="performance-inventory-header__short-date">\n              <span class="performance-inventory-header__dow">' + data.date.format('ddd') + '</span>\n              <span>' + data.date.format('M/D/YY') + '</span>\n            </div>\n          </div>\n          <div class="performance-inventory-cell">\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.inhouse') + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.extending') + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.total') + '</div>\n            </div>\n          </div>\n        </div>';
      };

      var headerDividedCellHTML = function headerDividedCellHTML(data) {
        return '<div class="performance-inventory-header stayover__wrapper ' + (data.today ? 'performance-inventory-header--today' : '') + '">\n          <div class="performance-inventory-header__date-section stayover__header">\n           <div class="performance-inventory-header__long-date date-section stayover__header--labels">\n             <div class="stayover__header--labels_item">' + _this.get('i18n').t('customer.performance.stayover.previous_four_week_avg') + '</div>\n           </div>\n          </div>\n          <div class="performance-inventory-cell">\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.inhouse') + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.extending') + '</div>\n            </div>\n            <div class="performance-inventory-cell__value">\n              <div>' + _this.get('i18n').t('customer.performance.stayover.total') + '</div>\n            </div>\n          </div>\n        </div>';
      };

      var tableCellHTML = function tableCellHTML(data) {
        return '<div class="performance-inventory-cell">\n          <div class="performance-inventory-cell__value">\n            <div>' + (data.baseCount ? data.baseCount : '&mdash;') + '</div>\n          </div>\n          <div class="performance-inventory-cell__value">\n            <div>' + (data.extensionCount ? data.extensionCount : '&mdash;') + '</div>\n          </div>\n          <div class="performance-inventory-cell__value">\n            <div>' + (data.total ? data.total : '&mdash;') + '</div>\n          </div>\n        </div>';
      };

      var createRow = function createRow(label, name, className) {
        var classNames = "";
        var values = {};
        entries.analytics.forEach(function (data, index) {
          var date = _moment.default.tz(data.baseDate, tz);
          values[date.format()] = { value: tableCellHTML({}), classNames: ['col-' + index] };
        });
        if (className) {
          classNames = className;
        }

        return { label: label, name: name, classNames: classNames, values: values, children: [] };
      };

      var createGroup = function createGroup(data) {
        // create total group/row
        var totalGroup = {
          label: _this.get('i18n').t('customer.performance.stayover.total'),
          labelAsCell: true,
          classNames: ['active'],
          rows: [createRow('', _this.get('i18n').t('customer.performance.stayover.total'))]
        };

        var groups = [];

        var totalValues = {};

        data.forEach(function (item) {
          var date = _moment.default.tz(item.baseDate, tz);
          totalValues[date.format()] = {
            baseCount: 0,
            extensionCount: 0,
            total: 0
          };
        });
        // create start of day group/row
        var startOfDayRow = createRow('', _this.get('i18n').t('customer.performance.stayover.start_of_day'));
        var startOfDayGroup = {
          label: _this.get('i18n').t('customer.performance.stayover.start_of_day'),
          labelAsCell: true,
          classNames: ["hidden"],
          rows: [startOfDayRow]
        };
        groups.push(startOfDayGroup);
        var groupings = _this.get('groupings');
        // evaluate each grouping
        groupings.forEach(function (grouping) {
          var group = {
            label: grouping.label,
            labelAsCell: true,
            classNames: ["hidden"],
            rows: []
          };
          var groupRow = createRow(grouping.label, grouping.label);
          group.rows.push(groupRow);
          groups.push(group);
          var groupValues = {};
          // create group/row for each hour
          _this._hours().forEach(function (hour, index) {
            // only process if hour is within the correct grouping
            if (index >= grouping.start && index <= grouping.end) {
              // create row for hour and add to group
              var dataRow = createRow(hour, hour);
              groupRow.children.push(dataRow);

              data.forEach(function (item, analyticIndex) {
                var date = _moment.default.tz(item.baseDate, tz);
                var hourData = (item.extensions || []).findBy('hour', hour);
                if (!hourData) {
                  hourData = {
                    baseCount: 0,
                    extensionCount: 0,
                    total: 0
                  };
                }
                var formattedDate = date.format();
                if (!groupValues[date.format()]) {
                  groupValues[date.format()] = {
                    baseCount: 0,
                    extensionCount: 0,
                    total: 0
                  };
                }
                // add start of day values to total values (only once)
                if (index === 0) {
                  var startOfDay = (item.extensions || []).findBy('hour', 'StartOfDay');
                  if (!startOfDay) {
                    startOfDay = {
                      baseCount: 0,
                      extensionCount: 0,
                      total: 0
                    };
                  }
                  startOfDayGroup.rows[0].values[formattedDate] = {
                    value: tableCellHTML({
                      baseCount: startOfDay.baseCount,
                      extensionCount: startOfDay.extensionCount,
                      total: startOfDay.total
                    }),
                    classNames: ['col-' + analyticIndex]
                  };
                  totalValues[formattedDate].baseCount += parseInt(startOfDay.baseCount);
                  totalValues[formattedDate].extensionCount += parseInt(startOfDay.extensionCount);
                  totalValues[formattedDate].total = parseInt(startOfDay.total);
                }
                dataRow.values[formattedDate] = { value: tableCellHTML({
                    baseCount: hourData.baseCount,
                    extensionCount: hourData.extensionCount,
                    total: hourData.total
                  }), classNames: ['col-' + analyticIndex] };
                // add hour values to grouping values
                if (groupValues[formattedDate]) {
                  groupValues[formattedDate].baseCount += parseInt(hourData.baseCount);
                  groupValues[formattedDate].extensionCount += parseInt(hourData.extensionCount);
                  groupValues[formattedDate].total += parseInt(hourData.total);
                  group.rows[0].values[formattedDate] = {
                    value: tableCellHTML(groupValues[formattedDate]),
                    classNames: ['col-' + analyticIndex]
                  };
                }
                // add hour values to total values
                if (totalValues[formattedDate]) {
                  totalValues[formattedDate].baseCount += parseInt(hourData.baseCount);
                  totalValues[formattedDate].extensionCount += parseInt(hourData.extensionCount);
                  totalValues[formattedDate].total += parseInt(hourData.total);
                  totalGroup.rows[0].values[formattedDate] = {
                    value: tableCellHTML(totalValues[formattedDate]),
                    classNames: ['col-' + analyticIndex]
                  };
                }
              });
            }
          });
        });
        // add total group
        groups.push(totalGroup);

        // add alternating color class to rows
        groups.forEach(function (row, index) {
          if (index % 2 === 1) {
            groups[index].rows.forEach(function (row) {
              row.classNames += ' performance-inventory-row--alternate';
            });
          }
        });

        return groups;
      };

      var columns = [];

      // create columns
      entries.analytics.forEach(function (data, index) {
        var date = _moment.default.tz(data.baseDate, tz);
        var isToday = date.valueOf() === dateToday.valueOf();
        // for first 2 columns, show dow header; after that show custom column
        var column = {
          key: date.format(),
          classNames: 'col-' + index,
          label: index < 2 ? headerCellHTML({
            date: date.clone(),
            today: isToday
          }) : headerDividedCellHTML({
            date: date.clone().format('MM/DD/YYYY'),
            today: isToday
          })
        };

        columns.push(column);
      });

      this.set('tableData', {
        groups: createGroup(entries.analytics),
        columns: columns
      });
    }),
    _openDrawers: function _openDrawers(row) {
      var linkedRow = (this.get('tableData.groups') || []).findBy('label', row.name);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', true);
      }
    },
    _closeDrawers: function _closeDrawers(row) {
      var linkedRow = (this.get('tableData.groups') || []).findBy('label', row.name);
      if (linkedRow) {
        Ember.set(linkedRow, 'isExpanded', false);
      }
    },


    actions: {
      selectChildren: function selectChildren(row) {
        this._openDrawers(row);
      },
      hideChildren: function hideChildren(row) {
        this._closeDrawers(row);
      }
    }
  });
});