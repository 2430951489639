define('ux-pcc/components/rules-simple/tax-rule-condition', ['exports', 'ember-changeset', 'ember-changeset-validations', 'ember-changeset-validations/validators'], function (exports, _emberChangeset, _emberChangesetValidations, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['rules-simple-condition'],
    classNameBindings: ['isReadOnly:rules-simple-condition__read-only'],

    store: Ember.inject.service(),

    onDelete: function onDelete() {},
    registerChangeset: function registerChangeset() {},

    rule: null,
    ruleCondition: null,
    index: null,

    isReadOnly: Ember.computed.equal('mode', 'READONLY'),

    displayExclusions: false,

    selectedExclusionCategory: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (this.mode === 'NEW') {
        this.ruleCondition.amount = 0;
        this.ruleCondition.amountRule = 'FIXED_AMOUNT';
        this.ruleCondition.calculationRule = 'AMOUNT_PERCENT';
        this.ruleCondition.ruleMethod = 'RULE';
        this.ruleCondition.calcWithRunningTotalYN = false;
        this.ruleCondition.day1 = 'Y';
        this.ruleCondition.day2 = 'Y';
        this.ruleCondition.day3 = 'Y';
        this.ruleCondition.day4 = 'Y';
        this.ruleCondition.day5 = 'Y';
        this.ruleCondition.day6 = 'Y';
        this.ruleCondition.day7 = 'Y';
        this.ruleCondition.children.push({
          ruleMethod: 'FILTER',
          filterType: 'ALL',
          children: [{
            ruleMethod: 'FILTER',
            filterType: 'FIELD_WHERE',
            filterField: 'PRODUCT_TEMPLATE',
            filterCondition: 'EQ',
            filterValue: '10a00000-0000-0000-0000-000000000000'
          }, {
            ruleMethod: 'FILTER',
            filterType: 'ALL',
            children: []
          }]
        });

        this.set('selectedExclusionCategory', 'PRODUCT_EXTERNAL_REFERENCE');
      } else {
        var hasExclusions = (this.exclusionCondition.children || []).length > 0;

        if (hasExclusions) {
          this.set('displayExclusions', true);
          this.set('selectedExclusionCategory', this.exclusionCondition.children.firstObject.filterField);
        } else {
          this.exclusionCondition.children = [];
          this.set('selectedExclusionCategory', 'PRODUCT_EXTERNAL_REFERENCE');
        }
      }

      this.registerChangeset('productTemplateChangeset' + this.index, this.templateChangeset);
      this.registerChangeset('ruleConditionChangeset' + this.index, this.ruleConditionChangeset);
      this.registerChangeset('exclusionConditionChangeset' + this.index, this.exclusionConditionChangeset);
    },


    _exclusionChangesetObserver: Ember.observer('exclusionConditionChangeset', function () {
      if (!this.displayExclusions) {
        this.exclusionConditionChangeset.set('children', []);
      }

      this.registerChangeset('exclusionConditionChangeset' + this.index, this.exclusionConditionChangeset);
    }),

    allCondition: Ember.computed('ruleCondition.children.[]', function () {
      return this.ruleCondition.children.findBy('filterType', 'ALL');
    }),

    productTemplateRule: Ember.computed('allCondition.children.[]', function () {
      return this.allCondition.children.findBy('filterField', 'PRODUCT_TEMPLATE');
    }),

    productTemplateOptions: Ember.computed(function () {
      var _this = this;

      return this.store.findAll('product-template').then(function (templates) {
        if (!_this.productTemplateRule.filterValue) {
          _this.set('productTemplateRule.filterValue', templates.get('firstObject').id);
        }

        return templates;
      });
    }),

    amountAndCalculationOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.tax-options.sale'),
        value: 'FIXED_AMOUNT'
      }, {
        label: this.i18n.t('rules-simple.tax-options.first_period'),
        value: 'FIRST_SLICE'
      }, {
        label: this.i18n.t('rules-simple.tax-options.total'),
        value: 'TOTAL'
      }, {
        label: this.i18n.t('rules-simple.tax-options.flat'),
        value: 'FLAT_PRICE'
      }];
    }),

    templateChangeset: Ember.computed('productTemplateRule', function () {
      var validations = {
        filterValue: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.productTemplateRule, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    ruleConditionChangeset: Ember.computed('ruleCondition', function () {
      var validations = {
        amount: (0, _validators.validateNumber)({ allowBlank: false, gte: 0, message: this.i18n.t('rules-simple.validation.invalid_number') }),
        amountRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true }),
        calculationRule: (0, _validators.validatePresence)({ presence: true, ignoreBlank: true })
      };

      return new _emberChangeset.default(this.ruleCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    exclusionCondition: Ember.computed('allCondition.children.[]', function () {
      return this.allCondition.children.findBy('filterType', 'ALL');
    }),

    categoryValuesMatcher: function categoryValuesMatcher(option, searchTerm) {
      var searchLower = searchTerm.toLowerCase();
      var shortLower = option.shortDescription.toLowerCase();
      var externalLower = option.externalReference.toLowerCase();
      return shortLower.includes(searchLower) || externalLower.includes(searchLower) ? 1 : -1;
    },


    categoryOptions: Ember.computed('i18n.locale', function () {
      return [{
        label: this.i18n.t('rules-simple.model-options.product_reference'),
        value: 'PRODUCT_EXTERNAL_REFERENCE'
      }, {
        label: this.i18n.t('rules-simple.model-options.price_reference'),
        value: 'PRICE_EXTERNAL_REFERENCE'
      }];
    }),

    productOptions: Ember.computed('customer.id', function () {
      return this.store.query('product', {
        customerID: this.customer.id,
        productUsageType: 'AVAILABILITY',
        fields: 'id,shortDescription,externalReference'
      });
    }),

    priceOptions: Ember.computed('customer.id', function () {
      return this.store.query('price', {
        customerID: this.customer.id,
        fields: 'id,shortDescription,parentID,externalReference'
      });
    }),

    exclusionOptions: Ember.computed('selectedExclusionCategory', 'displayExclusions', function () {
      switch (this.selectedExclusionCategory) {
        case 'PRODUCT_EXTERNAL_REFERENCE':
          return this.productOptions;
        case 'PRICE_EXTERNAL_REFERENCE':
          return this.priceOptions;
      }
    }),

    mapOptions: function mapOptions(options) {
      return this.exclusionConditionChangeset.get('children').map(function (r) {
        return options.findBy('id', r.filterValue);
      });
    },


    selectedExclusionValues: Ember.computed('exclusionConditionChangeset.children.@each.filterValue', 'selectedExclusionCategory', 'exclusionOptions', function () {
      var _this2 = this;

      if (this.exclusionOptions && typeof this.exclusionOptions.then === 'function') {
        return this.exclusionOptions.then(function (options) {
          return _this2.mapOptions(options);
        });
      } else if (this.exclusionOptions) {
        return this.mapOptions(this.exclusionOptions);
      }
    }),

    selectedExclusionValuesString: Ember.computed('selectedExclusionValues.[]', 'selectedExclusionCategory', function () {
      if (this.selectedExclusionValues && typeof this.selectedExclusionValues.then === 'function') {
        return this.selectedExclusionValues.then(function (values) {
          return values.mapBy('externalReference').join(', ');
        });
      } else if (this.selectedExclusionValues) {
        return this.selectedExclusionValues.mapBy('externalReference').join(', ');
      }
    }),

    exclusionConditionChangeset: Ember.computed('exclusionCondition', 'displayExclusions', 'i18n.locale', function () {
      var obj = { message: this.i18n.t('rules-simple.validation.invalid_selection') };

      if (this.displayExclusions) {
        obj.min = 1;
      } else {
        obj.max = 0;
      }

      var validations = { children: (0, _validators.validateLength)(obj) };

      return new _emberChangeset.default(this.exclusionCondition, (0, _emberChangesetValidations.default)(validations), validations);
    }),

    actions: {
      deleteCondition: function deleteCondition() {
        this.onDelete(this.index);
      },
      updateSelectedProductTemplate: function updateSelectedProductTemplate(productTemplateID) {
        this.templateChangeset.set('filterValue', productTemplateID);
      },
      updateSelectedAmountRule: function updateSelectedAmountRule(amountRule) {
        this.ruleConditionChangeset.set('calculationRule', amountRule === 'FLAT_PRICE' ? 'AMOUNT_ADD' : 'AMOUNT_PERCENT');
        this.ruleConditionChangeset.set('amountRule', amountRule);
      },
      updateSelectedExclusionCategory: function updateSelectedExclusionCategory(category) {
        if (this.selectedExclusionCategory !== category) {
          this.exclusionConditionChangeset.set('children', []);
        }

        this.set('selectedExclusionCategory', category);
      },
      updateSelectedExclusionValues: function updateSelectedExclusionValues(values) {
        var _this3 = this;

        var newValues = values.mapBy('id');

        // If there were de-selections, remove associated rules
        this.exclusionConditionChangeset.set('children', this.exclusionConditionChangeset.get('children').reject(function (c) {
          return !newValues.includes(c.filterValue);
        }));

        // Add rules for new selections
        newValues.forEach(function (v) {
          if (!_this3.exclusionConditionChangeset.get('children').findBy('filterValue', v)) {
            _this3.exclusionConditionChangeset.get('children').pushObject({
              ruleMethod: 'FILTER',
              filterType: 'FIELD_WHERE',
              filterField: _this3.selectedExclusionCategory,
              filterCondition: 'NOT_EQ',
              filterValue: v
            });

            // Manually re-validate to remove validation warning message
            _this3.exclusionConditionChangeset.validate();
          }
        });
      }
    }

  });
});