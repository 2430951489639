define('ux-pcc/services/permission', ['exports', 'ux-components/services/permission'], function (exports, _permission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _permission.default;
    }
  });
});