define('ux-pcc/routes/reservation/view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var reservation = this.modelFor('reservation').reservation;
      return Ember.RSVP.hash({
        histories: this.store.query('reservations-history', { reservationID: reservation.get('id'), include: 'all' }),
        reservation: reservation,
        destinationCustomer: this.store.findRecord('customer', Ember.get(reservation, 'destinationCustomer.id'), {
          include: 'settings,attributes',
          reload: true
        })
      });
    },
    afterModel: function afterModel(model) {
      return Ember.RSVP.hash({
        customerSettings: model.customerSettings,
        reservation: model.reservation,
        histories: Ember.RSVP.all(model.histories.getEach('lastModifiedBy'), "ReservationHistory: Resolving user relationships")
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model.histories);

      // ember data ignores 'reload:true' if 'include:' is also passed in (or multiple params?)
      if (Ember.get(model, 'destinationCustomer.attributes.length') === 0) {
        this.store.query('attribute', {
          fkReference: 'CUSTOMER',
          fkID: Ember.get(model, 'reservation.destinationCustomer.id')
        }).then(function (attributes) {
          return controller.set('attributes', attributes);
        });
      } else {
        controller.set('attributes', Ember.get(model, 'destinationCustomer.attributes'));
      }

      if (Ember.get(model, 'destinationCustomer.settings.length') === 0) {
        this.store.query('setting', {
          fkReference: 'CUSTOMER',
          fkID: Ember.get(model, 'reservation.destinationCustomer.id')
        }).then(function (settings) {
          return controller.set('settings', settings);
        });
      } else {
        controller.set('settings', Ember.get(model, 'destinationCustomer.settings'));
      }

      controller.set('customer', model.reservation.get('destinationCustomer'));
      controller.set('mergedDocuments', model.reservation.get('documents'));
      // controller.set('mergedPayments', model.reservation.get('payments').rejectBy('status', 'INACTIVATED'));
      controller.set('mergedAuthorizations', model.reservation.get('authorizations'));
      controller.set('begin', model.histories.get('lastObject.lastModified'));
      controller.set('end', model.histories.get('firstObject.lastModified'));
      controller.set('userDefinedFields', this.modelFor('reservation').userDefinedFields);
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
        return true;
      }
    }
  });
});