define('ux-pcc/models/dashboard-view', ['exports', 'ux-data/models/dashboard-view'], function (exports, _dashboardView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dashboardView.default;
    }
  });
});