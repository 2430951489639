define('ux-pcc/templates/modals/product-calendar-override', ['exports', 'ux-components/templates/modals/product-calendar-override'], function (exports, _productCalendarOverride) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _productCalendarOverride.default;
    }
  });
});