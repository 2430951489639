define('ux-pcc/controllers/yield-manager/dashboard/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    parentController: Ember.inject.controller('yield-manager.dashboard'),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    dateRange: Ember.computed.alias('parentController.dateRange'),
    selectedDate: Ember.computed.alias('parentController.selectedDate'),
    views: Ember.computed.alias('parentController.views'),
    rollupStatus: Ember.computed('customer.folderYN', function () {
      return this.get('customer.folderYN') ? 'ROLLUP' : 'DETAIL';
    }),

    currencyCode: Ember.computed(function () {
      var model = this.get("customer.settings").findBy("settingCode", "DEFAULT_CURRENCY");
      if (model && model.get("settingValue")) {
        return model.get("settingValue");
      } else {
        return "USD";
      }
    }),

    currentSlide: 0,
    resultsPerPage: null,
    pages: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('pages', {});
    },


    _mobileCardSelector: Ember.observer('parentController.selectedSlide', function () {
      var _this = this;

      var pages = this.get('pages');
      var keys = Object.keys(pages);
      var selectedId = this.get('parentController.selectedSlide');
      keys.forEach(function (key, index) {
        var view = pages[key].findBy('id', selectedId);
        if (view) {
          _this.set('currentSlide', index);
          return;
        }
      });
    }),

    // Add observer to prevent requery on all screen size adjustments
    _pageSizeObserver: Ember.on('init', Ember.observer('deviceType.{mobile,tablet,tabletLandscape,desktop}', function () {
      var _this2 = this;

      Ember.run.once(function () {
        var currentResultsPerPage = _this2.get('resultsPerPage');

        if (_this2.get('deviceType.mobile') && currentResultsPerPage !== 1) {
          _this2.set('resultsPerPage', 1);
        } else if (_this2.get('deviceType.tablet') && currentResultsPerPage !== 4) {
          _this2.set('resultsPerPage', 4);
        } else if (_this2.get('deviceType.tabletLandscape') && currentResultsPerPage !== 3) {
          _this2.set('resultsPerPage', 3);
        } else if (_this2.get('deviceType.desktop') && !_this2.get('deviceType.tabletLandscape') && currentResultsPerPage) {
          _this2.set('resultsPerPage', null);
        }
      });
    })),

    _menuObserver: Ember.on('init', Ember.observer('deviceType.{tablet,tabletLandscape}', function () {
      var _this3 = this;

      if (this.get('deviceType.tablet') || this.get('deviceType.tabletLandscape')) {
        Ember.$(window).on("resize.menu-toggle", function () {
          if (_this3.get('yieldCardsSwiper')) {
            _this3.get('yieldCardsSwiper').forceUpdate();
          }
        });
      }
    })),

    enablePager: Ember.computed('views.length', 'resultsPerPage', function () {
      return this.get('resultsPerPage') && this.get('views.length') > this.get('resultsPerPage');
    }),

    pagedResults: Ember.computed('pages.length', 'currentPage', function () {
      return this.get('pages')['page' + this.get('currentPage')];
    }),

    screens: Ember.computed('pages.length', function () {
      var keys = Object.keys(this.get('pages'));
      return keys && keys.length > 0 ? keys : null;
    }),

    _pageSplitter: Ember.on('init', Ember.observer('views', 'views.length', 'resultsPerPage', function () {
      // ignore any default MBS views
      var views = (this.get('views') || []).rejectBy('DATA_TYPE', 'MBS');
      var resultsPerPage = this.get('resultsPerPage');
      if (resultsPerPage) {
        var pages = { "page0": [] };
        var pageIndex = 0;
        views.forEach(function (view) {
          if (resultsPerPage && pages['page' + pageIndex].length === resultsPerPage) {
            pageIndex++;
            pages['page' + pageIndex] = [];
          }
          pages['page' + pageIndex].push(view);
        });

        this.set('pages', pages);
      } else {
        this.set('pages', { "page0": views });
      }
    })),

    actions: {
      flashMessage: function flashMessage(message, type) {
        this.send('flash', message, type);
      },
      swiped: function swiped() {
        if (this.get('deviceType.mobile')) {
          var outerPagination = Ember.$('#yield-manager__paging > .swiper-pagination');
          if (outerPagination && outerPagination.html() && outerPagination.html().length > 0) {
            Ember.$('.mobile-paginator').html(outerPagination.html());
          }
        }
      }
    }
  });
});