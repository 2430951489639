define('ux-pcc/components/coming-soon', ['exports', 'ux-components/components/coming-soon'], function (exports, _comingSoon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _comingSoon.default;
    }
  });
});