define('ux-pcc/controllers/dashboard-views', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    dialog: Ember.inject.service(),
    ajax: Ember.inject.service(),
    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),
    yieldManager: Ember.inject.controller('yield-manager'),
    yieldManagerDashboard: Ember.inject.controller('yield-manager.dashboard'),
    isMobile: Ember.computed.bool('deviceType.mobile'),
    isSaving: true,
    isLoading: Ember.computed.readOnly('isSaving'),
    submitError: "",
    dragID: null,
    isShowList: true,
    selectedView: null,
    activeFormGroup: null,
    hideSubChannels: false,
    isCopying: false,
    viewOnly: Ember.computed.readOnly('model.viewOnly'),
    showCopy: Ember.computed('viewOnly', 'selectedView.id', function () {
      return !this.get('viewOnly') && this.get('selectedView.id');
    }),

    activeFormGroupNotMobile: Ember.computed('activeFormGroup', 'isMobile', 'viewOnly', function () {
      return this.get('activeFormGroup') && !this.get('isMobile') && !this.get('viewOnly');
    }),
    showViewCategoriesPanel: Ember.computed('activeFormGroup', 'activeFormGroupNotMobile', function () {
      return !this.get('activeFormGroup') || this.get('activeFormGroupNotMobile');
    }),
    highlightDisplayGroup: Ember.computed('activeFormGroup', function () {
      return this.get('activeFormGroup') === 'display' || this.get('activeFormGroup') === 'dataType';
    }),
    highlightDateGroup: Ember.computed('activeFormGroup', function () {
      return this.get('activeFormGroup') === 'dates' || this.get('activeFormGroup') === 'reportingTimePeriod';
    }),
    highlightOrganizationGroup: Ember.computed('activeFormGroup', function () {
      return this.get('activeFormGroup') === 'organization' || this.get('activeFormGroup') === 'customerLevels' || this.get('activeFormGroup') === 'levels' || this.get('activeFormGroup') === 'brands' || this.get('activeFormGroup') === 'accounts';
    }),
    highlightChannelGroup: Ember.computed('activeFormGroup', function () {
      return this.get('activeFormGroup') === 'channels' || this.get('activeFormGroup') === 'channel' || this.get('activeFormGroup') === 'sub-channels';
    }),
    agreementPlaceholder: Ember.computed(function () {
      return this.get('i18n').t('dashboard-views.form.agreement_placeholder');
    }),
    activeParam: null,

    activeDisplayFormGroup: Ember.computed('activeFormGroup', 'viewOnly', function () {
      return this.get('viewOnly') || this.get('activeFormGroup') === 'display';
    }),
    activeDatesFormGroup: Ember.computed('activeFormGroup', 'viewOnly', function () {
      return this.get('viewOnly') || this.get('activeFormGroup') === 'dates';
    }),
    activeOrganizationFormGroup: Ember.computed('activeFormGroup', 'viewOnly', function () {
      return this.get('viewOnly') || this.get('activeFormGroup') === 'organization';
    }),
    activeChannelsFormGroup: Ember.computed('activeFormGroup', 'viewOnly', function () {
      return this.get('viewOnly') || this.get('activeFormGroup') === 'channels';
    }),

    statuses: null,
    typeaheadOptions: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedView', null);
      this.set('isShowList', true);
      this.set('typeaheadOptions', { highlight: true, autoselect: true });
      this.set('statuses', [Ember.Object.create({
        "codeType": "RESERVATION_TYPE",
        "codeValue": "BOOK",
        "externalReference": "BOOK",
        "shortDescription": "Reservations",
        "longDescription": "Reservations",
        "orderBy": 0
      }), Ember.Object.create({
        "codeType": "RESERVATION_TYPE",
        "codeValue": "CANCEL",
        "externalReference": "CANCEL",
        "shortDescription": "Cancels",
        "longDescription": "Cancels",
        "orderBy": 10
      }), Ember.Object.create({
        "codeType": "RESERVATION_TYPE",
        "codeValue": "NO_SHOW",
        "externalReference": "NO_SHOW",
        "shortDescription": "No Shows",
        "longDescription": "No Shows",
        "orderBy": 20
      }), Ember.Object.create({
        "codeType": "RESERVATION_TYPE",
        "codeValue": "WALK",
        "externalReference": "TURN_DOWN",
        "shortDescription": "Turn Downs",
        "longDescription": "Turn Downs",
        "orderBy": 30
      })]);
      if (!this.get("isMobile")) {
        //set active form group to open right panel on tablet/desktop
        this.set('activeFormGroup', 'display');
      }
      // allow ember-drag-drop dragHandle option to work in ios
      Ember.run.scheduleOnce('afterRender', function () {
        Ember.$('.dragHandle').on('touchstart', function (e) {
          Ember.$(e.target).trigger('mouseover');
        });
      });
    },


    selectedParsedView: null,

    _setSelectedParsedView: function _setSelectedParsedView() {
      var _this = this;

      // parse selected view for better access to params
      var selectedView = this.get('selectedView');
      var selectedParsedView = {};
      if (selectedView) {
        selectedParsedView = {
          name: selectedView.get('name')
        };
        if (selectedView.get('dashboardViewParameters') && selectedView.get('dashboardViewParameters').length > 0) {
          var channelParameter = selectedView.get('dashboardViewParameters').findBy('name', 'CHANNEL');
          var channels = this.get('yieldManager.channels') || [];
          var channel = channelParameter ? channels.findBy('channelCode', channelParameter.value) : null;
          selectedView.get('dashboardViewParameters').forEach(function (param) {
            selectedParsedView[param.name] = param.value || "";
            if (param.name === 'DATA_TYPE') {
              selectedParsedView["dataTypeLabel"] = _this.dataTypeLabel(param.value);
            }
            if (param.name === 'REPORTING_TIME_PERIOD') {
              var reportingTimePeriods = _this.get('yieldManager.reportingTimePeriods') || [];
              var code = reportingTimePeriods.findBy('codeValue', param.value);
              selectedParsedView["reportingTimePeriodLabel"] = code ? code.get('shortDescription') : "";
            }
            if (param.name === 'DAY_OF_WEEK') {
              var dow = _this.get('daysOfWeek').findBy('value', param.value);
              selectedParsedView["dayOfWeekLabel"] = dow ? dow.description : _this.get('i18n').t('dashboard-views.form.all');
            }
            if (param.name === 'LEVEL_HIERARCHY') {
              var customerLevels = _this.get('yieldManager.customerLevels') || [];
              var _code = customerLevels.findBy('codeValue', param.value);
              selectedParsedView["customerLevelLabel"] = _code ? _code.get('shortDescription') : "";
            }
            if (param.name === 'LEVEL') {
              if (!param.value || param.value === "") {
                selectedParsedView["levelLabel"] = _this.get('i18n').t('dashboard-views.form.none');
                selectedParsedView["levelList"] = [];
              } else {
                var levels = param.value.split('|');
                selectedParsedView["levelList"] = levels;
                selectedParsedView["levelLabel"] = _this.customerLabel(levels, 'levels');
              }
            }
            if (param.name === 'BRAND_CODE') {
              if (!param.value || param.value === "") {
                selectedParsedView["brandLabel"] = _this.get('i18n').t('dashboard-views.form.all_brands');
                selectedParsedView["brandList"] = [];
              } else {
                var brands = param.value.split('|');
                selectedParsedView["brandList"] = brands;
                selectedParsedView["brandLabel"] = _this.codeLabel(brands, 'brands');
              }
            }
            if (param.name === 'AGREEMENT') {
              if (!param.value || param.value === "") {
                selectedParsedView["accountLabel"] = _this.get('i18n').t('dashboard-views.form.all_accounts');
                selectedParsedView["accountList"] = [];
                _this.set('accounts', []);
              } else {
                var accounts = param.value.split('|');
                _this._queryAgreements(accounts);
                selectedParsedView["accountList"] = accounts;
              }
            }
            if (param.name === 'CHANNEL') {
              if (!param.value || param.value === "") {
                selectedParsedView["channelLabel"] = _this.get('i18n').t('dashboard-views.form.all');
                selectedParsedView["channelChildren"] = [];
                selectedParsedView["channelsList"] = [];
                _this.set('hideSubChannels', false);
              } else {
                var _channels = param.value.split('|');
                if (_channels.length > 1) {
                  _this.set('hideSubChannels', true);
                } else {
                  _this.set('hideSubChannels', false);
                }
                // some channel description are capitalized and some are uppercase
                selectedParsedView["channelLabel"] = channel ? channel.get('shortDescription').toLowerCase().capitalize() : _this.channelLabel(_channels, _this.get('yieldManager.channels'));
                selectedParsedView["channelChildren"] = channel ? channel.get('children').sortBy('shortDescription') : [];
                selectedParsedView["channelsList"] = _channels || [];
              }
            }
            if (param.name === 'SUB_CHANNEL') {
              if (!param.value || param.value === "") {
                selectedParsedView["subChannelsLabel"] = _this.get('i18n').t('dashboard-views.form.all');
                selectedParsedView["subChannelsList"] = [];
              } else {
                var subChannels = param.value.split('|');
                selectedParsedView["subChannelsList"] = subChannels;
                selectedParsedView["subChannelsLabel"] = _this.subChannelLabel(subChannels, channel);
              }
            }
            if (param.name === 'PRICE_CODE') {
              if (!param.value || param.value === "") {
                selectedParsedView["rentalTypesLabel"] = _this.get('i18n').t('dashboard-views.form.all_rental_types');
                selectedParsedView["rentalTypesList"] = [];
              } else {
                var rentalTypes = param.value.split('|');
                selectedParsedView["rentalTypesList"] = rentalTypes;
                selectedParsedView["rentalTypesLabel"] = _this.codeLabel(rentalTypes, 'prices');
              }
            }
            if (param.name === 'SOURCE_LOCATION_CODE') {
              if (!param.value || param.value === "") {
                selectedParsedView["sourceLocationList"] = [];
              } else {
                selectedParsedView["sourceLocationList"] = param.value.split('|');
              }
            }
            if (param.name === 'DESTINATION_STATUS') {
              if (!param.value || param.value === "") {
                selectedParsedView["statusLabel"] = _this.get('i18n').t('dashboard-views.form.none');
                selectedParsedView["statusList"] = [];
              } else {
                var types = param.value.split('|');
                selectedParsedView["statusList"] = types;
                selectedParsedView["statusLabel"] = _this.codeLabel(types, 'statuses');
              }
            }
            if (param.name === 'PRODUCT_CODE') {
              if (!param.value || param.value === "") {
                selectedParsedView["productsLabel"] = _this.get('i18n').t('dashboard-views.form.all_products');
                selectedParsedView["productsList"] = [];
              } else {
                var products = param.value.split('|');
                selectedParsedView["productsList"] = products;
                selectedParsedView["productsLabel"] = _this.productLabel(products);
              }
            }
          });
        }
      }
      this.set('selectedParsedView', selectedParsedView);
    },


    selectedParsedViewObserver: Ember.observer('selectedView', 'activeParam', function () {
      Ember.run.once(this, '_setSelectedParsedView');
    }),

    dataTypeLabel: function dataTypeLabel(value) {
      switch (value) {
        case 'YMCD':
          return this.get('i18n').t('dashboard-views.form.ymcd');
        case 'YICD':
          return this.get('i18n').t('dashboard-views.form.yicd');
        case 'YMTD':
          return this.get('i18n').t('dashboard-views.form.ymtd');
        case 'PRODUCT':
          return this.get('i18n').t('dashboard-views.form.product_types');
        case 'CHANNEL':
          return this.get('i18n').t('dashboard-views.form.channels');
        case 'PRICE':
          return this.get('i18n').t('dashboard-views.form.prices');
        default:
          return this.get('i18n').t('dashboard-views.form.ymcd');
      }
    },
    customerLabel: function customerLabel(customers, type) {
      var list = this.get('yieldManager.' + type);
      if (!list) {
        list = this.get(type) || [];
      }

      var label = "";
      customers.forEach(function (customer, index) {
        var item = list.findBy('id', customer);
        if (item) {
          if (index > 0) {
            label += ", " + item.get('name');
          } else {
            label = item.get('name');
          }
        }
      });
      return label;
    },
    agreementLabel: function agreementLabel(agreements, type) {
      var list = this.get(type) || [];

      var label = "";
      agreements.forEach(function (agreement, index) {
        var item = list.findBy('id', agreement);
        if (item) {
          if (index > 0) {
            label += ", " + item.get('shortDescription');
          } else {
            label = item.get('shortDescription');
          }
        }
      });
      return label;
    },
    subChannelLabel: function subChannelLabel(channels, parentChannel) {
      var list = parentChannel ? parentChannel.get('children') || [] : [];

      var label = "";
      channels.forEach(function (channel, index) {
        var item = list.findBy('channelCode', channel);
        if (item) {
          var description = item.get('shortDescription');
          if (index > 0) {
            // some channel description are capitalized and some are all uppercase
            label += description ? ", " + description.toLowerCase().capitalize() : "";
          } else {
            label = description ? description.toLowerCase().capitalize() : "";
          }
        }
      });
      return label;
    },
    channelLabel: function channelLabel(channels, channelList) {
      var list = channelList || [];

      var label = "";
      channels.forEach(function (channel, index) {
        var item = list.findBy('channelCode', channel);
        if (item) {
          var description = item.get('shortDescription');
          if (index > 0) {
            // some channel description are capitalized and some are all uppercase
            label += description ? ", " + description.toLowerCase().capitalize() : "";
          } else {
            label = description ? description.toLowerCase().capitalize() : "";
          }
        }
      });
      return label;
    },
    codeLabel: function codeLabel(codes, type) {
      var list = this.get('yieldManager.' + type);
      if (!list) {
        list = this.get(type) || [];
      }
      var label = "";
      codes.forEach(function (code, index) {
        var item = list.findBy('codeValue', code) || list.findBy('externalReference', code);
        if (item) {
          var description = item.get('shortDescription');
          if (index > 0) {
            label += description ? ", " + description : "";
          } else {
            label = description ? description : "";
          }
        }
      });
      return label;
    },
    productLabel: function productLabel(products) {
      var list = this.get('products') || [];

      var label = "";
      products.forEach(function (product, index) {
        var item = list.findBy('externalReference', product);
        if (item) {
          var description = item.shortDescription;
          if (index > 0 && label.length > 0) {
            label += description ? ", " + description : "";
          } else {
            label = description ? description : "";
          }
        }
      });
      return label;
    },

    index: 0,

    _editViewObserver: Ember.observer('model.selectedView', function () {
      if (this.get('model.selectedView')) {
        this.set('selectedView', this.get('model.selectedView'));
        this.set('originalParameters', JSON.parse(JSON.stringify(this.get('model.selectedView.dashboardViewParameters'))));
        this.set('isShowList', false);
        this.set('activeParam', null);
      }
    }),

    _selectedViewObserver: Ember.observer('selectedView', 'isMobile', function () {
      if (!this.get("isMobile")) {
        //set active form group to open right panel on tablet/desktop
        this.set('activeFormGroup', 'display');
      }
      if (this.get('selectedView')) {
        var level = this.get('selectedView.dashboardViewParameters').findBy('name', "LEVEL_HIERARCHY");
        var selectedLevels = this.get('selectedView.dashboardViewParameters').findBy('name', "LEVEL");
        if (level && selectedLevels && selectedLevels.value) {
          this.set('levels', this.get('yieldManager.' + this._levelType(level.value)));
          this._queryProducts(level.value, selectedLevels.value);
          var array = selectedLevels.value.split("|");
          this.set('selectedParsedView.levelList', array);
          this.set('selectedParsedView.levelLabel', this.customerLabel(array, 'levels'));
        }
      }
    }),

    _levelType: function _levelType(type) {
      switch (type) {
        case 'LEVEL_COUNTRY':
          return 'countries';
        case 'LEVEL_GROUP':
          return 'groups';
        case 'LEVEL_SVP':
          return 'svps';
        case 'LEVEL_REGION':
          return 'regions';
        case 'LEVEL_SUBREGION':
          return 'subregions';
        case 'LEVEL_AREA':
          return 'areas';
        case 'LEVEL_CITY':
          return 'cities';
        case 'LEVEL_STATION':
          return 'stations';
        case 'LEVEL_UDA':
          return 'udas';
        case 'LEVEL_POD':
          return 'pods';
        default:
          return 'countries';
      }
    },


    daysOfWeek: Ember.computed(function () {
      return [{ value: "MONDAY", description: this.get('i18n').t('dashboard-views.form.monday') }, { value: "TUESDAY", description: this.get('i18n').t('dashboard-views.form.tuesday') }, { value: "WEDNESDAY", description: this.get('i18n').t('dashboard-views.form.wednesday') }, { value: "THURSDAY", description: this.get('i18n').t('dashboard-views.form.thursday') }, { value: "FRIDAY", description: this.get('i18n').t('dashboard-views.form.friday') }, { value: "SATURDAY", description: this.get('i18n').t('dashboard-views.form.saturday') }, { value: "SUNDAY", description: this.get('i18n').t('dashboard-views.form.sunday') }];
    }),

    typeaheadData: Ember.computed(function () {
      var _this2 = this;

      var customer = this.get('customer');
      var agreements = new window.Bloodhound({
        datumTokenizer: function datumTokenizer(d) {
          return d.tokens;
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
          url: '/rest/v1/search?q=%QUERY*&scope=agreements&summary=false&affiliateID=' + customer.get('affiliateCustomer.id') + '&limit=5',
          filter: function filter(_ref) {
            var _ref$agreementSearche = _ref.agreementSearches,
                agreementSearches = _ref$agreementSearche === undefined ? [] : _ref$agreementSearche;
            return agreementSearches;
          },
          prepare: function prepare(query, settings) {
            settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
            settings.beforeSend = function (jqXHR) {
              return jqXHR.setRequestHeader('Authorization', 'Bearer ' + _this2.get('session.data.authenticated.token'));
            };
            return settings;
          }
        }
      });

      return {
        name: 'agreements',
        displayKey: 'name',
        source: agreements,
        templates: {
          empty: '<div class="menu-header__search-no-results">' + this.get('i18n').t('dashboard-views.no_agreements') + '</div>',
          suggestion: function suggestion(model) {
            return '<div class="menu-header__search-item-section-wrapper">\n                                <div class="menu-header__search-item-section">\n                                  ' + model.shortDescription + '\n                                </div>\n                              </div>';
          }
        }
      };
    }),

    _queryCustomerLevels: function _queryCustomerLevels(level, levelsParam) {
      var levels = this.get('yieldManager.' + this._levelType(level));
      this.set('levels', levels);
      if (!levelsParam.value) {
        levelsParam.value = levels.get('firstObject') ? levels.get('firstObject.id') : "";
        this.set('selectedParsedView.levelList', [levelsParam.value]);
        this.set('selectedParsedView.levelLabel', this.customerLabel([levelsParam.value], 'levels'));
      }
    },
    _queryProducts: function _queryProducts(levelType, levels) {
      var _this3 = this;

      if (levelType && levels) {
        this.get('ajax').request('/rest/v1/dashboardViews/products', {
          data: {
            customers: levels,
            fields: 'id,shortDescription,externalReference'
          }
        }).then(function (_ref2) {
          var _ref2$lists = _ref2.lists,
              products = _ref2$lists === undefined ? [] : _ref2$lists;

          _this3.set('products', products.sortBy('shortDescription'));
          if (!Ember.isEmpty(_this3.get('selectedParsedView.productsList'))) {
            _this3.set('selectedParsedView.productsLabel', _this3.productLabel(_this3.get('selectedParsedView.productsList')));
          }
        });
      }
    },
    _queryAgreements: function _queryAgreements(agreements) {
      var _this4 = this;

      var agreementList = [];
      var promises = [];
      agreements.forEach(function (agreement) {
        promises.push(_this4.get('store').query('agreement', agreement).then(function (record) {
          agreementList.addObject(record);
        }));
      });

      Ember.RSVP.all(promises).then(function () /* responses */{
        _this4.set('accounts', agreementList);
        _this4.set('selectedParsedView.accountLabel', _this4.agreementLabel(agreements, 'accounts'));
      });
    },


    isSaveDisabled: Ember.computed.not('selectedView.name'),

    _submitObserver: Ember.observer('isSaveDisabled', function () {
      if (!this.get('isSaveDisabled')) {
        this.set('submitError', "");
      }
    }),

    actions: {
      dragStartAction: function dragStartAction(object) {
        this.set('dragID', object.get('id'));
      },
      dragEndAction: function dragEndAction() {
        this.set('dragID', null);
      },
      removeDashboardModal: function removeDashboardModal() {
        this.set('isShowList', true);
        if (this.get('selectedView')) {
          this.get('selectedView').rollbackAttributes();
          this.set('selectedView', null);
        }
        this.set('submitError', "");
        this.send('closeModal');
      },
      sortEndAction: function sortEndAction() {
        var _this5 = this;

        // save reordered list
        this.set('isSaving', true);

        // build json array to save
        var model = [];
        this.get('model.views').forEach(function (item) {
          model.push(item.serialize());
        });
        this.get('ajax').put("/rest/v1/dashboardViews", {
          contentType: 'application/json',
          data: JSON.stringify(model)
        }).then(function () {
          _this5.get('yieldManagerDashboard').send('refresh');
        });

        this.set('isSaving', false);
      },
      setActiveFormGroup: function setActiveFormGroup(formGroup) {
        if (this.get('viewOnly')) {
          return;
        }

        this.set('activeFormGroup', formGroup);
        // scroll to top of modal
        if (this.get('deviceType.mobile')) {
          Ember.$('.dashboard-view-form').animate({ scrollTop: 0 });
        } else {
          Ember.$('.dashboard-view-form__right-panel').animate({ scrollTop: 0 });
        }
      },
      selectDashboard: function selectDashboard(view) {
        this.send('closeModal');
        this.set('isShowList', true);
        this.set('selectedView', null);

        if (this.get('deviceType.mobile')) {
          this.get('yieldManagerDashboard').send('setSlide', view.id);
        } else {
          this.transitionToRoute('yield-manager.dashboard.view', view.id);
        }
      },
      add: function add() {
        this.set('isShowList', false);
        this.set('activeParam', null);
        this.set('submitError', "");
        this.set('originalParameters', []);
        this.set('levels', this.get('yieldManager.countries') || []);
        var level = this.get('levels') ? this.get('levels')[0] : null;
        // setup default params
        var model = {
          name: "",
          dashboardViewParameters: Ember.Object.create({
            "DATA_TYPE": "YMCD",
            "DISPLAY": "FULL",
            "TM_VS_GROSS": "GROSS",
            "DATE_SYNC": "MONTH",
            "DATA_VIEW": "ACTUAL",
            "DATE_RANGE": "1",
            "DATE_COMPARE": "YEAR",
            "DAY_OF_WEEK": "",
            "REPORTING_TIME_PERIOD": "",
            "LEVEL_HIERARCHY": "LEVEL_COUNTRY",
            "LEVEL": level ? level.get('id') : "",
            "CORPORATE": "CORPORATE",
            "BRAND_CODE": "",
            "AGREEMENT": "",
            "CHANNEL": "",
            "SUB_CHANNEL": "",
            "DESTINATION_STATUS": "BOOK",
            "PRICE_CODE": "",
            "RETURN_TYPE": "ALL",
            "PRODUCT_CODE": "",
            "SOURCE_LOCATION_CODE": "RESERVATION",
            "FULFILLMENT_STATUS": ""
          })
        };
        model.orderBy = this.get('model.views.length');
        var newModel = this.store.createRecord('dashboard-view', model);
        this.set('selectedView', newModel);
        this.set('isCopying', false);
      },
      edit: function edit(item) {
        this.set('isShowList', false);
        this.set('selectedView', item);
        this.set('activeParam', null);
        //keep track of original parameters, in case of a cancel with edited params (rollback attributes is insufficient)
        this.set('originalParameters', JSON.parse(JSON.stringify(item.get('dashboardViewParameters'))));
        this.set('isCopying', false);
      },
      confirmDelete: function confirmDelete(item) {
        var _this6 = this;

        this.get('dialog').showConfirmation({
          titleText: this.get('i18n').t('dashboard-views.delete_modal.title'),
          messageText: this.get('i18n').t('dashboard-views.delete_modal.message'),
          confirmText: this.get('i18n').t('dashboard-views.delete_modal.confirm'),
          cancelText: this.get('i18n').t('dashboard-views.delete_modal.cancel'),
          onConfirm: function onConfirm() {
            item.destroyRecord().then(function () {
              _this6.get('dialog').showSuccess();
              _this6.get('model.views').removeObject(item);
              _this6.store.unloadRecord(item);
              _this6.transitionToRoute('yield-manager.dashboard');
            }, function () {
              _this6.get('dialog').showError();
            });
          },
          onCancel: function onCancel() {
            _this6.get('dialog').close();
          }
        });
      },
      save: function save() {
        var _this7 = this;

        // save record and return to list
        if (!this.get('selectedView.id')) {
          if (!this.get('isSaveDisabled')) {
            if (this.get('selectedView.dashboardViewParameters') && this.get('selectedView.name')) {
              // post single view to add to setting list
              var selectedView = this.get('selectedView');
              this.get('ajax').post("/rest/v1/dashboardViews/single", {
                contentType: 'application/json',
                data: JSON.stringify(selectedView.serialize())
              }).then(function (item) {
                _this7.get('yieldManagerDashboard').send('refresh');
                // unload unsaved record (since ajax is used to post rather than .save(), duplicate records can exist in the store otherwise)
                _this7.store.unloadRecord(selectedView);
                // find newly saved record and add to list
                _this7.store.findRecord('dashboard-view', item.dashboardView.id).then(function (record) {
                  _this7.get('model.views').addObject(record);
                });
                _this7.get('dialog').showSuccess();
                _this7.set('isShowList', true);
                _this7.set('selectedView', null);
                _this7.set('isCopying', false);
                _this7.set('originalParameters', []);
              }, function () {
                _this7.get('dialog').showError();
              });
            }
          } else {
            this.set('submitError', this.get('i18n').t('dashboard-views.form.name_error'));
          }
        } else {
          // build json array to save
          var model = [];
          this.get('model.views').forEach(function (item) {
            model.push(item.serialize());
          });
          this.get('ajax').put("/rest/v1/dashboardViews", {
            contentType: 'application/json',
            data: JSON.stringify(model)
          }).then(function () {
            _this7.get('yieldManagerDashboard').send('refresh');
            _this7.get('dialog').showSuccess();
            _this7.set('isShowList', true);
            _this7.set('selectedView', null);
            _this7.set('model.selectedView', null);
            _this7.set('isCopying', false);
            _this7.set('originalParameters', []);
          }, function () {
            _this7.get('dialog').showError();
          });
        }
      },
      cancel: function cancel() {
        this.set('isShowList', true);
        this.set('submitError', "");
        this.get('selectedView').rollbackAttributes();
        //reset params to previously saved results
        if (this.get('selectedView.dashboardViewParameters')) {
          this.set('selectedView.dashboardViewParameters', this.get('originalParameters'));
        }
        this.set('originalParameters', []);
        this.set('selectedView', null);
        this.set('model.selectedView', null);
        this.set('activeParam', null);
        this.set('isCopying', false);
      },
      selectDashboardParam: function selectDashboardParam(param, value) {
        if (this.get('viewOnly')) {
          return;
        }

        var params = this.get('selectedView');
        var selectedParam = params.get('dashboardViewParameters').findBy('name', param);
        var brandParam = null;
        if (param === 'BRAND_CODE') {
          brandParam = params.get('dashboardViewParameters').findBy('name', 'BRAND_CODE');
          if (!brandParam) {
            brandParam = params.get('dashboardViewParameters').addObject({ name: 'BRAND_CODE', value: "" });
          }
          brandParam.value = "";
        }
        if (selectedParam) {
          selectedParam.value = value;
          this.set('activeParam', selectedParam.value);
          if (param === 'DATA_TYPE') {
            // reset day of week param
            var dayOfWeek = params.get('dashboardViewParameters').findBy('name', 'DAY_OF_WEEK');
            if (!dayOfWeek) {
              params.get('dashboardViewParameters').addObject({ name: "DAY_OF_WEEK", value: "" });
            } else {
              dayOfWeek.value = "";
            }
            var sourceLocationCode = params.get('dashboardViewParameters').findBy('name', 'SOURCE_LOCATION_CODE');
            if (!sourceLocationCode) {
              params.get('dashboardViewParameters').addObject({ name: "SOURCE_LOCATION_CODE", value: "RESERVATION" });
            } else {
              sourceLocationCode.value = "RESERVATION";
            }
            var fulfillmentStatus = params.get('dashboardViewParameters').findBy('name', 'FULFILLMENT_STATUS');
            if (!fulfillmentStatus) {
              params.get('dashboardViewParameters').addObject({ name: "FULFILLMENT_STATUS", value: "" });
            } else {
              fulfillmentStatus.value = "";
            }
            var destinationStatus = params.get('dashboardViewParameters').findBy('name', 'DESTINATION_STATUS');
            if (!destinationStatus) {
              params.get('dashboardViewParameters').addObject({ name: "DESTINATION_STATUS", value: "BOOK" });
            } else {
              destinationStatus.value = "BOOK";
            }
            var reportingTimePeriod = params.get('dashboardViewParameters').findBy('name', 'REPORTING_TIME_PERIOD');
            var display = params.get('dashboardViewParameters').findBy('name', 'DISPLAY');
            if (value === 'YMTD') {
              reportingTimePeriod.value = this.get('yieldManager.reportingTimePeriods.firstObject.codeValue');
              display.value = "FULL";
            } else if (value === 'YICD') {
              reportingTimePeriod.value = "";
              display.value = "";
            } else {
              reportingTimePeriod.value = "";
              display.value = "FULL";
            }
          }
          if (param === 'FULFILLMENT_STATUS') {
            var _sourceLocationCode = params.get('dashboardViewParameters').findBy('name', 'SOURCE_LOCATION_CODE');
            _sourceLocationCode.value = "";
            var _destinationStatus = params.get('dashboardViewParameters').findBy('name', 'DESTINATION_STATUS');
            _destinationStatus.value = "";
          }
          if (param === 'LEVEL_HIERARCHY') {
            var levels = params.get('dashboardViewParameters').findBy('name', 'LEVEL');
            if (!levels) {
              params.get('dashboardViewParameters').addObject({ name: "LEVEL", value: "" });
            } else {
              levels.value = "";
            }
            this._queryCustomerLevels(value, levels);
          }
          if (param === 'CHANNEL') {
            //if channel has changed, remove existing subChannels
            var subChannels = params.get('dashboardViewParameters').findBy('name', 'SUB_CHANNEL');
            subChannels.value = "";
          }
        }
      },
      selectDateRangeParam: function selectDateRangeParam(value) {
        var params = this.get('selectedView');
        var selectedParam = params.get('dashboardViewParameters').findBy('name', 'DATE_RANGE');
        if (selectedParam) {
          selectedParam.value = value;
          this.set('activeParam', selectedParam.value);
        }
      },
      selectDashboardParamsArray: function selectDashboardParamsArray(param, value, list) {
        var params = this.get('selectedView');
        var selectedParam = params.get('dashboardViewParameters').findBy('name', param);
        var brandParam = null;
        if (param === 'BRAND_CODE') {
          brandParam = params.get('dashboardViewParameters').findBy('name', 'BRAND_CODE');
          if (!brandParam) {
            brandParam = params.get('dashboardViewParameters').addObject({ name: 'BRAND_CODE', value: "" });
          }
          brandParam.value = "";
        }
        if (selectedParam) {
          if (!value || !selectedParam.value) {
            // clear array
            selectedParam.value = value;
            if (param === 'BRAND') {
              var code = list.findBy('externalReference', value).get('codeValue');
              brandParam.value = code;
            }
            if (param === 'AGREEMENT') {
              this.set('accounts', []);
            }
          } else {
            var array = !selectedParam.value ? [] : selectedParam.value.split('|');
            var includes = false;
            // IE doesn't support String includes
            if (!String.prototype.includes) {
              String.prototype.includes = function () {
                'use strict';

                includes = this.indexOf(array, value) !== -1;
              };
            } else {
              includes = array.includes(value);
            }
            if (includes) {
              // remove object from array
              var index = array.indexOf(value);
              array.splice(index, 1);
              selectedParam.value = array.join("|");
            } else {
              // add object to array
              array.addObject(value);
              selectedParam.value += array.length === 0 ? value : "|" + value;
            }
            if (param !== 'LEVEL' && param !== 'AGREEMENT') {
              //if all items in list are selected, reset selection to "all"
              if (list && array.length === list.length) {
                selectedParam.value = "";
              }
            }
            if (param === 'BRAND') {
              array.forEach(function (brand, index2) {
                var code = list.findBy('externalReference', brand).get('codeValue');
                brandParam.value += index2 === 0 ? code : "|" + code;
              });
            }
          }
          this.set('activeParam', selectedParam.value);
        }
        if (param === 'LEVEL') {
          var levelType = params.get('dashboardViewParameters').findBy('name', 'LEVEL_HIERARCHY');
          this._queryProducts(levelType.value, selectedParam.value);
        }
        if (param === 'SOURCE_LOCATION_CODE') {
          if (selectedParam.value === 'RESERVATION|TICKET' || selectedParam.value === 'TICKET|RESERVATION') {
            selectedParam.value = "";
          }
        }
        if (param === 'DESTINATION_STATUS' || list === 'RESERVATION') {
          var fulfillmentStatus = params.get('dashboardViewParameters').findBy('name', 'FULFILLMENT_STATUS');
          fulfillmentStatus.value = "";
        }
        if (param === 'CHANNEL') {
          //if channel has changed, remove existing subChannels
          var subChannels = params.get('dashboardViewParameters').findBy('name', 'SUB_CHANNEL');
          subChannels.value = "";
        }
      },
      itemSelected: function itemSelected(item) {
        var accounts = this.get('accounts') || [];
        if (!accounts.findBy('id', item.get('id'))) {
          accounts.addObject(item);
        }
        this.send('selectDashboardParamsArray', 'AGREEMENT', item.get('id'), []);
      },
      copy: function copy() {
        this.set('isCopying', true);
        // copy view
        var modelToCopy = JSON.parse(JSON.stringify(this.get('selectedView')));
        modelToCopy.name += " " + this.get('i18n').t('dashboard-views.form.copy_name');
        modelToCopy.orderBy = this.get('model.views.length');
        // create new record
        var newModel = this.store.createRecord('dashboard-view', modelToCopy);
        // rollback any changes to original view
        this.get('selectedView').rollbackAttributes();
        if (this.get('selectedView.dashboardViewParameters')) {
          this.set('selectedView.dashboardViewParameters', this.get('originalParameters'));
        }
        // set new record as selected view
        this.set('selectedView', newModel);
        // click into name input
        Ember.$('.editable-text').trigger('click');
      },
      editMode: function editMode() {
        this.set('model.viewOnly', false);
      }
    }
  });
});