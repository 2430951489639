define('ux-pcc/mixins/route-alias-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({

    menu: Ember.inject.service(),

    aliasRouteName: null,
    aliasRoutePathParameters: [],
    aliasRouteQueryParameters: {},

    init: function init() {
      this._super.apply(this, arguments);

      //TODO: routeName isn't set when init() is called
      if (this.get('routeName')) {
        this.get('menu').registerRouteAlias(this.get('routeName'), this.get('aliasRouteName'));

        console.info('route-alias-mixin extended by \'' + this.get('routeName') + '\' alias set up for ' + this.get('aliasRouteName'));
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('aliasRouteName')) {
        this.replaceWith.apply(this, [this.get('aliasRouteName')].concat(_toConsumableArray(this.get('aliasRoutePathParameters')), [{ queryParams: this.get('aliasRouteQueryParameters') }]));
      } else {
        console.warn('route-alias-mixin extended by \'' + this.get('routeName') + '\' attempted to transition, but aliasRouteName property was not defined');
      }
    }
  });
});