define('ux-pcc/components/yield-manager/cards/opportunity-card', ['exports', 'accounting'], function (exports, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['opportunity-card'],

    onNavigateToLevel: function onNavigateToLevel() {},

    oppLevel: Ember.computed('opportunityLevel', function () {
      // return (this.get('opportunityLevel')||'').toLowerCase();
      return 'station';
    }),

    level: Ember.computed('oppLevel', function () {
      return this.get('i18n').t('yield-manager.analyze.opportunities.header.' + this.get('oppLevel'));
    }),

    name: Ember.computed('data', 'oppLevel', function () {
      // return this.get(`data.station.value`);
      // TODO: get from the correct level
      var level = this.get('oppLevel');
      if (level) {
        return this.get('data.' + level + '.value');
      }
      return 'NA';
    }),

    days: Ember.computed('data.change_cd_pct.value', function () {
      return (0, _accounting.unformat)(this.get('data.change_cd_pct.value'));
    }),
    yield: Ember.computed('data.change_yield_pct.value', function () {
      return (0, _accounting.unformat)(this.get('data.change_yield_pct.value'));
    }),
    revenue: Ember.computed('data.change_revenue_pct.value', function () {
      return (0, _accounting.unformat)(this.get('data.change_revenue_pct.value'));
    }),

    actions: {
      navigateToLevel: function navigateToLevel() {
        this.get('onNavigateToLevel')('LEVEL_STATION', this.get('data.station.value'));
      }
    }

  });
});