define('ux-pcc/authenticators/saml2', ['exports', 'ux-data/authenticators/saml2'], function (exports, _saml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _saml.default;
    }
  });
});