define('ux-pcc/components/charts/year-over-year', ['exports', 'moment', 'ux-pcc/components/charts/chart-basic', 'ux-pcc/helpers/wrap-negative', 'ux-pcc/utils/date-formatter', 'ux-pcc/utils/charts/mark-today', 'ux-components/utils/format-currency'], function (exports, _moment, _chartBasic, _wrapNegative, _dateFormatter, _markToday, _formatCurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _chartBasic.default.extend({

    classNames: ['year-over-year'],

    sliceResolution: 'day',
    enableExport: false,
    exportPosition: 'top-left',

    _addedTitle: Ember.computed('i18n.locale', 'addedTitle', function () {
      return this.get('addedTitle') || this.get('i18n').t('charts.year-over-year.default_added_title');
    }),

    currencyFormatOptions: null,

    cumulativePrecision: 2,

    _cumulativeTitle: Ember.computed('i18n.locale', 'cumulativeTitle', function () {
      return this.get('cumulativeTitle') || this.get('i18n').t('charts.year-over-year.default_cumulative_title');
    }),

    _legendObserver: Ember.observer('addPY', 'addCY', 'cumPY', 'cumCY', function () {
      this._createChart();
    }),

    dataTransform: Ember.computed('sliceResolution', 'deviceType.mobile', function () {
      var _this = this;

      var settings = this.get('customer.settings');
      return function (data) {
        var today = _moment.default.tz(_this.get('tz.customer')).startOf('day');
        data.forEach(function (d) {
          d.moment = _moment.default.tz(d.slice, _this.get('tz.customer'));

          if (d.moment.isSame(today, _this.get('sliceResolution'))) {
            d.dateClass = 'today';
          }

          if (d.moment.isAfter(_moment.default.tz(_this.get('tz.customer')).endOf('week'))) {
            delete d.cumCurrentYear;
            delete d.addedCurrentYear;
          }

          d.formattedSlice = d.moment.format('ddd') + '<br>' + (0, _dateFormatter.dateFormatter)(d.moment, { year: false, settings: settings });
          d.addedPriorYearClass = d.addedPriorYear < 0 ? 'negative-value-class' : '';
          d.addedCurrentYearClass = d.addedCurrentYear < 0 ? 'negative-value-class' : '';
        });

        return _this.get('deviceType.mobile') ? data.slice(data.length / 2) : data;
      };
    }),

    markDay: Ember.computed('data', function () {
      return ((this.get('data') || []).findBy('dateClass', 'today') || {}).formattedSlice;
    }),

    _styleBalloon: function _styleBalloon(item, currentGraph) {
      var _this2 = this;

      // Don't attempt to generate balloon if translation object is missing
      // Can occur if navigating away from page with chart on it
      if (!this.get('i18n')) {
        return;
      }

      var currentWeekEnd = _moment.default.tz(this.get('tz.customer')).endOf('week');

      if ((currentGraph === 'addCY' || currentGraph === 'addPY' || currentGraph === 'cumCY') && this.get('cumPY')) {
        return '';
      }

      if ((currentGraph === 'addCY' || currentGraph === 'cumCY' || currentGraph === 'cumPY') && !this.get('cumPY') && this.get('addPY')) {
        return '';
      }

      if ((currentGraph === 'addCY' || currentGraph === 'addPY' || currentGraph === 'cumPY') && !this.get('cumPY') && !this.get('addPY') && this.get('cumCY')) {
        return '';
      }

      var _generateHTML = function _generateHTML(label, classStyle, value) {
        return '<div>\n                <span class=\'bubble-label\'>' + label + ': </span>\n                <span class=\'bubble-value ' + classStyle + '\'>' + value + '</span>\n              </div>';
      };

      var formatValue = function formatValue(value, cfo) {
        if (cfo) {
          return (0, _wrapNegative.wrapNegative)((0, _formatCurrency.default)(value, _this2.get('currencyCode'), { hash: cfo }));
        } else {
          return (0, _wrapNegative.wrapNegative)(value, { precision: _this2.get('precision') });
        }
      };

      var currencyFormatOptions = this.get('currencyFormatOptions');
      var displayHTML = _generateHTML(this.get('i18n').t('charts.year-over-year.week_end'), '', (0, _dateFormatter.dateFormatter)(item.dataContext.moment, { year: false, settings: this.get('customer.settings') }));

      if (this.get('addCY') && item.dataContext.moment.isSameOrBefore(currentWeekEnd, 'day')) {
        var label = this.get('i18n').t('charts.year-over-year.added_current_year');
        var value = item.dataContext.addedCurrentYear;
        var style = value < 0 ? 'negative-value-class' : '';
        value = formatValue(value, currencyFormatOptions);
        displayHTML += _generateHTML(label, style, value);
      }

      if (this.get('addPY')) {
        var _label = this.get('i18n').t('charts.year-over-year.added_prior_year');
        var _value = item.dataContext.addedPriorYear;
        var _style = _value < 0 ? 'negative-value-class' : '';
        _value = formatValue(_value, currencyFormatOptions);
        displayHTML += _generateHTML(_label, _style, _value);
      }

      if (this.get('cumCY') && item.dataContext.moment.isSameOrBefore(currentWeekEnd, 'day')) {
        var _label2 = this.get('i18n').t('charts.year-over-year.cumulative_current_year');
        var _value2 = item.dataContext.cumCurrentYear;
        var _style2 = _value2 < 0 ? 'negative-value-class' : '';
        _value2 = formatValue(_value2, currencyFormatOptions);
        displayHTML += _generateHTML(_label2, _style2, _value2);
      }

      if (this.get('cumPY')) {
        var _label3 = this.get('i18n').t('charts.year-over-year.cumulative_prior_year');
        var _value3 = item.dataContext.cumPriorYear;
        var _style3 = _value3 < 0 ? 'negative-value-class' : '';
        _value3 = formatValue(_value3, currencyFormatOptions);
        displayHTML += _generateHTML(_label3, _style3, _value3);
      }

      if (item.dataContext.cumPriorYearToDate) {
        var _label4 = this.get('i18n').t('charts.year-over-year.cumulative_prior_year_this_week_full_span');
        var _value4 = item.dataContext.cumPriorYearToDate;
        var _style4 = _value4 < 0 ? 'chart-balloon-negative' : '';
        _value4 = formatValue(_value4, currencyFormatOptions);
        displayHTML += _generateHTML(_label4, _style4, _value4);
      }

      if (item.dataContext.addPriorYearToDate && this.get('addPY')) {
        var _label5 = this.get('i18n').t('charts.year-over-year.added_prior_year_to_date');
        var _value5 = item.dataContext.addPriorYearToDate;
        var _style5 = _value5 < 0 ? 'chart-balloon-negative' : '';
        _value5 = formatValue(_value5, currencyFormatOptions);
        displayHTML += _generateHTML(_label5, _style5, _value5);
      }

      if ((this.get('cumCY') || this.get('cumPY')) && item.dataContext.moment.isSameOrBefore(currentWeekEnd, 'day')) {
        var _label6 = this.get('i18n').t('charts.year-over-year.cumulative_variance');
        var _value6 = item.dataContext.cumPctVariance;
        var _style6 = _value6 < 0 ? 'negative-value-class' : '';
        _value6 = (0, _wrapNegative.wrapNegative)(_value6, { precision: 1, suffix: '%' });
        displayHTML += _generateHTML(_label6, _style6, _value6);
      }

      if ((this.get('addCY') || this.get('addPY')) && item.dataContext.moment.isSameOrBefore(currentWeekEnd, 'day')) {
        var _label7 = this.get('i18n').t('charts.year-over-year.added_variance');
        var _value7 = item.dataContext.addPctVariance;
        var _style7 = _value7 < 0 ? 'negative-value-class' : '';
        _value7 = (0, _wrapNegative.wrapNegative)(_value7, { precision: 1, suffix: '%' });
        displayHTML += _generateHTML(_label7, _style7, _value7);
      }

      return displayHTML;
    },
    _config: function _config() {
      var _this3 = this;

      if (this.get('_data.length') <= 0) {
        return;
      }

      var data = this.get('_data');

      var generateGraphs = function generateGraphs() {
        var graphs = [];
        if (_this3.get('_data.length') > 0) {

          // Added (Current Year)
          if (_this3.get('addCY')) {
            graphs.push({
              title: _this3.get('i18n').t('charts.year-over-year.added_current_year'),
              fillAlphas: 1,
              lineAlpha: 1,
              type: 'column',
              clustered: true,
              id: 'added-days chart-color--' + _this3.get('colorClass'),
              valueField: 'addedCurrentYear',
              valueAxis: 'added',
              useLineColorForBulletBorder: true,
              classNameField: 'addedCurrentYearClass',
              balloonFunction: function balloonFunction(item) {
                return _this3._styleBalloon(item, 'addCY');
              },
              balloon: { fillAlpha: 1 }
            });
          }

          // Added (Prior Year)
          if (_this3.get('addPY')) {
            graphs.push({
              title: _this3.get('i18n').t('charts.year-over-year.added_prior_year'),
              fillAlphas: 1,
              lineAlpha: 1,
              type: 'column',
              clustered: true,
              id: 'added-days chart-color--' + _this3.get('colorClass') + '-compare',
              valueField: 'addedPriorYear',
              valueAxis: 'added',
              useLineColorForBulletBorder: true,
              classNameField: 'addedPriorYearClass',
              balloonFunction: function balloonFunction(item) {
                return _this3._styleBalloon(item, 'addPY');
              },
              balloon: { fillAlpha: 1 }
            });
          }

          if (_this3.get('includeCumulative')) {

            // Cum (Current Year)
            if (_this3.get('cumCY')) {
              graphs.push({
                title: _this3.get('i18n').t('charts.year-over-year.cumulative_current_year'),
                fillAlphas: 0,
                type: 'line',
                bullet: 'square',
                bulletSize: 5,
                bulletBorderAlpha: 1,
                bulletAlpha: 1,
                classNameField: 'bulletClass',
                useLineColorForBulletBorder: true,
                lineThickness: 2,
                id: 'cum-days chart-color--' + _this3.get('colorClass') + ' compare-shade',
                valueField: 'cumCurrentYear',
                valueAxis: 'cum',
                clustered: true,
                balloonFunction: function balloonFunction(item) {
                  return _this3._styleBalloon(item, 'cumCY');
                },
                balloon: { fillAlpha: 1 }
              });
            }

            // Cum (Prior Year)
            if (_this3.get('cumPY')) {
              graphs.push({
                title: _this3.get('i18n').t('charts.year-over-year.cumulative_prior_year'),
                fillAlphas: 0,
                type: 'line',
                bullet: 'round',
                bulletSize: 5,
                bulletBorderAlpha: 1,
                bulletAlpha: 1,
                classNameField: 'bulletClass',
                useLineColorForBulletBorder: true,
                lineThickness: 2,
                id: 'cum-days chart-color--' + _this3.get('colorClass') + '-compare compare-shade',
                valueField: 'cumPriorYear',
                valueAxis: 'cum',
                clustered: true,
                balloonFunction: function balloonFunction(item) {
                  return _this3._styleBalloon(item, 'cumPY');
                },
                balloon: { fillAlpha: 1 }
              });
            }
          }
        }

        return graphs;
      };

      // Find the minimum of the values displayed in the graph
      var min = Number.MAX_VALUE;
      data.forEach(function (item) {
        if (item.hasOwnProperty('cumCurrentYear')) {
          min = Math.min(min, item.cumCurrentYear);
        }

        if (item.hasOwnProperty('cumPriorYear')) {
          min = Math.min(min, item.cumPriorYear);
        }

        if (item.hasOwnProperty('addedCurrentYear')) {
          min = Math.min(min, item.addedCurrentYear);
        }

        if (item.hasOwnProperty('addedPriorYear')) {
          min = Math.min(min, item.addedPriorYear);
        }
      });

      var valueAxes = [{
        id: 'added',
        autoGridCount: true,
        gridAlpha: 0,
        axisAlpha: 0,
        position: 'right',
        title: this.get('_addedTitle'),
        usePrefixes: true,
        strictMinMax: min >= 0, // If the minimum is above 0, force amcharts to make the axis minimum 0 to display the 0 line
        minimum: min >= 0 ? 0 : min, // Otherwise, the minimum will be below 0, and the 0 line will be displayed anyway
        guides: [{
          id: 'zero-marker',
          value: 0,
          lineAlpha: 1,
          lineThickness: 3,
          above: true,
          inside: true,
          fillColor: '#000000',
          lineColor: '#000000'
        }]
      }];

      if (this.get('includeCumulative')) {
        //loop over the values to determine the maximums in order to set the multiplier
        var cumMax = 0.1;
        var addedMax = 0.1;
        var multiplier = 1;

        data.forEach(function (item) {
          if (_this3.get('cumCY') && item.hasOwnProperty('cumCurrentYear')) {
            cumMax = Math.max(cumMax, item.cumCurrentYear);
          }

          if (_this3.get('cumPY') && item.hasOwnProperty('cumPriorYear')) {
            cumMax = Math.max(cumMax, item.cumPriorYear);
          }

          if (_this3.get('addCY') && item.hasOwnProperty('addedCurrentYear')) {
            addedMax = Math.max(addedMax, item.addedCurrentYear);
          }

          if (_this3.get('addPY') && item.hasOwnProperty('addedPriorYear')) {
            addedMax = Math.max(addedMax, item.addedPriorYear);
          }
        });

        multiplier = Math.ceil(cumMax / addedMax);

        var cumValueAxis = {
          id: 'cum',
          autoGridCount: true,
          gridAlpha: 0.1,
          axisAlpha: 0,
          position: 'left',
          title: this.get('_cumulativeTitle'),
          usePrefixes: true,
          strictMinMax: min >= 0, // If the minimum is above 0, force amcharts to make the axis minimum 0 to display the 0 line
          minimum: min >= 0 ? 0 : min // Otherwise, the minimum will be below 0, and the 0 line will be displayed anyway
        };

        if (this.get('addCY') || this.get('addPY')) {
          cumValueAxis.synchronizationMultiplier = multiplier;
          cumValueAxis.synchronizeWith = 'added';
        }

        valueAxes.push(cumValueAxis);
      }

      var guides = [];

      if (this.get('markDay')) {
        guides.push({
          id: 'today',
          category: this.get('markDay'),
          lineAlpha: 0.3,
          lineThickness: 2,
          boldLabel: true,
          inside: true,
          labelRotation: 90,
          above: true
        }, {
          category: this.get('markDay'),
          toCategory: this.get('markDay'),
          fillAlpha: 0.2,
          fillColor: '#888888',
          lineAlpha: 0,
          labelRotation: 90,
          inside: true,
          lineThickness: 2,
          expand: true
        });
      }

      var config = {
        hideCredits: true,
        type: 'serial',
        addClassNames: true,
        categoryField: 'formattedSlice',
        startDuration: 0,
        sequencedAnimation: false,
        panEventsEnabled: false,
        trendLines: [],
        allLabels: [],
        balloon: {},
        titles: [],
        graphs: generateGraphs(),
        valueAxes: valueAxes,
        categoryAxis: {
          gridAlpha: 0,
          axisAlpha: 0,
          gridPosition: 'start',
          color: '#505558',
          parseDates: false,
          classNameField: 'dateClass'
        },
        guides: guides,
        chartCursor: {
          balloonPointerOrientation: 'horizontal',
          zoomable: false,
          selectWithoutZooming: true,
          fullWidth: false,
          enabled: true,
          cursorAlpha: 0,
          cursorColor: '#4D96CB',
          color: '#F3FAFF',
          categoryBalloonEnabled: true,
          categoryBalloonFunction: function categoryBalloonFunction(text) {
            var hovered = (_this3.get('data') || []).findBy('formattedSlice', text);
            if (hovered && hovered.formattedSlice === ((_this3.get('data') || []).findBy('dateClass', 'today') || {}).formattedSlice) {
              var now = _moment.default.tz(_this3.get('tz.customer'));
              return _this3.get('i18n').t('charts.year-over-year.today') + ': ' + now.format('ddd') + ' ' + (0, _dateFormatter.dateFormatter)(now, { year: false, settings: _this3.get('customer.settings') });
            }
            return ' ';
          }
        },
        language: this.get('i18n.locale'),
        dataProvider: data
      };

      if (this.get('addPY')) {
        config.graphs.push({
          columnWidth: 0.4,
          lineColor: '#000000',
          lineThickness: 4,
          noStepRisers: true,
          stackable: false,
          type: 'step',
          valueField: 'addPriorYearToDate',
          valueAxis: 'added',
          pointPosition: 'end',
          periodSpan: 0.7,
          balloonText: ''
        });
      }

      if (this.get('enableExport')) {
        var settings = this.get('customer.settings');
        var now = (0, _moment.default)();
        config.export = {
          enabled: true,
          libs: { autoLoad: false },
          position: this.get('exportPosition'),
          fileName: this.get('filePrefix') + '_' + (0, _dateFormatter.dateFormatter)(now, { settings: settings }) + '_' + now.format('HHmmss'),
          menu: [{
            class: 'export-main',
            menu: [{
              label: this.get('i18n').t('charts.save_as').toString(),
              menu: ['JSON']
            }]
          }]
        };
      }

      if (this.get('title')) {
        config.titles.push({ text: this.get('title') });
      }

      return config;
    },
    _createChart: function _createChart() {
      var chart = this._super();
      var element = this.get('element');

      if (chart) {
        (0, _markToday.chartsMarkToday)(element, 3);
        chart.addListener('drawn', function () {
          return (0, _markToday.chartsMarkToday)(element, 3);
        });
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        return chart.validateData();
      }, 0);
    }
  });
});