define('ux-pcc/controllers/affiliates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['view'],
    view: 'property',

    customerController: Ember.inject.controller('customer'),
    customer: Ember.computed.readOnly('customerController.model'),

    isAffiliate: Ember.computed.equal('customer.customerType', "AFFILIATE")

  });
});